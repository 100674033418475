import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { useParams } from "react-router-dom";

interface Props {
    setModalVisibleComodidades: (modalVisibleComodidades: boolean) => void;
    modalVisibleComodidades: boolean;
}

interface comfort {
    id: number;
    nombre: string;
    icono: string;
    habilitado: string;
    fecha_registro: string;
}

export const ModalEditDescripcion1 = ({
    setModalVisibleComodidades,
    modalVisibleComodidades,
}: Props) => {
    const {
        descripcion_1,
        titulo_descripcion_1,
        casa_comodidades,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );

    const [saving, setSaving] = useState<boolean>(false);

    const [input, setInput] = useState({
        descripcion_1,
        titulo_descripcion_1,
    });

    const handleSubmit = async () => {
        setSaving(true);

        try {
            const data = await fetchAxiosToken({
                url: `/casa/putDescripcion1/${casa_id}`,
                method: "put",
                body: input,
            });

            try {
                dispatchDominio();
                setTimeout(() => {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        title: "Actualizado",
                        text: "Descripcion actualizada",
                        icon: "success",
                        confirmButtonText: "Ok",
                    });
                    setSaving(false);
                    setModalVisibleComodidades(false);
                }, 2000);
            } catch (error: any) {
                console.log(error.response);
            }
        } catch (error: any) {
            console.log(error.response);
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleComodidades}
            setModalVisible={setModalVisibleComodidades}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-base font-medium">Descripción principal</h2>
                <hr className="w-full bg-gray-300" />

                <div className="w-full mt-3 text-left">
                    <header className="2xl:mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                        Titulo:
                    </header>
                </div>
                <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                    <input
                        onChange={(e) =>
                            setInput({
                                ...input,
                                titulo_descripcion_1: e.target.value,
                            })
                        }
                        type="text"
                        value={input.titulo_descripcion_1 || ""}
                        placeholder="Titulo"
                        className="w-full 2xl:my-3 my-2.5 2xl:text-base text-sm bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400"
                    />
                </div>
                <div className="w-full mt-1 text-left 2xl:mt-3">
                    <header className="2xl:mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                        Descripcion:
                    </header>
                </div>
                <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                    <textarea
                        onChange={(e) =>
                            setInput({
                                ...input,
                                descripcion_1: e.target.value,
                            })
                        }
                        value={input.descripcion_1 || ""}
                        rows={10}
                        placeholder="Descripcion"
                        className="w-full 2xl:my-3 my-2.5 2xl:text-base text-sm bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400"
                    />
                </div>

                <div className="flex flex-col gap-4 mt-2 2xl:mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105  2xl:text-base text-sm">
                            Guardar cambios
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
