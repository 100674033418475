import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import useCurrencyPrices from "../../hooks/useCurrencyPrices";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";

import { ModalEditDescripcion2 } from "../modalEdit/ModalEditDescripcion2";
import { ModalEditComforts } from "../modalEdit/ModalEditComforts";

interface Props {
    toggleButton: boolean;
}

interface Input {
    descripcion_2: string;
    titulo_descripcion_2: string;
}
export const Descripcion = ({ toggleButton }: Props) => {
    const {
        descripcion_2,
        titulo_descripcion_2,
        casa_comodidades,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);

    const [saving, setSaving] = useState<boolean>(false);
    const [input, setInput] = useState<Input>({
        descripcion_2: descripcion_2,
        titulo_descripcion_2: titulo_descripcion_2,
    });

    const [modalVisibleDescripcion, setModalVisibleDescripcion] =
        useState<boolean>(false);

    const [modalVisibleComodidades, setModalVisibleComodidades] =
        useState<boolean>(false);

    useEffect(() => {
        setInput({
            descripcion_2: descripcion_2,
            titulo_descripcion_2: titulo_descripcion_2,
        });
    }, [descripcion_2]);

    return (
        <div>
            <div className="justify-between w-11/12 md:w-10/12 gap-10 mx-auto md:mb-5 2xl:mb-10 sm:flex">
                {modalVisibleDescripcion && (
                    <ModalEditDescripcion2
                        setModalVisibleDescripcion={setModalVisibleDescripcion}
                        modalVisibleDescripcion={modalVisibleDescripcion}
                    />
                )}

                {modalVisibleComodidades && (
                    <ModalEditComforts
                        setModalVisibleComodidades={setModalVisibleComodidades}
                        modalVisibleComodidades={modalVisibleComodidades}
                    />
                )}

                <div className="flex flex-col w-full md:w-1/3 bg-[#f2f5ff] rounded-lg p-5 lg:mx-5">
                    <div className="flex items-center justify-center mb-4 lg:justify-start">
                        <p className="text-[#029bfc] lg:text-left text-lg md:text-xl 2xl:text-2xl font-bold ">
                            Servicios
                        </p>

                        {toggleButton && (
                            <div
                                onClick={() => setModalVisibleComodidades(true)}
                                title="editar servicios"
                                className="flex flex-col items-center px-2 py-1 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                            >
                                <svg
                                    className="w-8 h-8 text-[#1C4CBB]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                    <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                    />
                                </svg>
                                <div className="text-xs text-[#1C4CBB] text-center">
                                    Editar Servicios
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="max-h-[270px] overflow-y-auto">
                        {casa_comodidades.map((servicio) => (
                            <div>
                                <div
                                    className="flex items-center p-2 gap-7 border-t border-[#999999] min-h-[64px] xs:min-h-0 "
                                    key={servicio.id}
                                >
                                    <img
                                        className="w-8 h-8 object-contain"
                                        src={
                                            `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/comodidad/getImage/` +
                                            servicio.comodidade.icono
                                        }
                                        alt=""
                                    />
                                    <div className="font-medium text-gray-700 md:text-base text-sm">
                                        {servicio.comodidade.nombre}
                                    </div>
                                </div>
                                {/* <hr className="h-px my-2 bg-[#999999] border-0"></hr> */}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex w-full mt-5 md:w-2/3 lg:mx-5 md:mt-0">
                    <div className="flex flex-col mx-auto">
                        <div className="flex items-center">
                            <p className="text-[#029bfc] 2xl:text-xl lg:text-lg text-base font-semibold mt-2 uppercase text-center md:text-left">
                                {titulo_descripcion_2
                                    ? titulo_descripcion_2
                                    : "Descripción general"}
                            </p>

                            {toggleButton && (
                                <div
                                    onClick={() =>
                                        setModalVisibleDescripcion(true)
                                    }
                                    title="editar descripcion"
                                    className="flex flex-col items-center px-2 py-1 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                                >
                                    <svg
                                        className="w-8 h-8 text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                        <line
                                            x1="13.5"
                                            y1="6.5"
                                            x2="17.5"
                                            y2="10.5"
                                        />
                                    </svg>
                                    <div className="text-xs text-[#1C4CBB] text-center">
                                        Editar Descripción
                                    </div>
                                </div>
                            )}
                        </div>

                        <hr className="h-px 2xl:my-4 my-3  border-[#029bfc] border-2"></hr>
                        {descripcion_2 ? (
                            <div
                                className="mt-3 md:text-base text-sm  text-[#4D4D4D] text-justify"
                                dangerouslySetInnerHTML={{
                                    __html: descripcion_2.replace(
                                        /\n/g,
                                        "<br>"
                                    ),
                                }}
                            ></div>
                        ) : (
                            <p className="mt-3 md:text-base text-sm  text-[#4D4D4D] ">
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit, sed diam nonummy nibh euismod
                                tincidunt ut laoreet dolore magna aliquam erat
                                volutpat. Ut wisi enim ad minim veniam, quis
                                nostrud Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit, sed diam nonummy
                                <br></br>
                                <br></br>
                                Ut wisi enim ad minim veniam, quis nostrud
                                exerci tation ullamcorper suscipit lobortis nisl
                                ut aliquip ex ea commodo consequat.
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
