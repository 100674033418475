import React from "react";
import XLSX from "xlsx";

export interface ItemInterface {
    id: number;
    id_cliente: number;
    tipo: string;
    id_chat: number;
    id_pedidos: number;
    proceso: string;
    chat: Chat;
    pedido: Pedido;
    motivo_perdida: string;
    usuario: Usuario;
}

export interface Chat {
    id: number;
    chats_usuarios: ChatsUsuario[];
}

export interface ChatsUsuario {
    id: number;
    id_chat: number;
    id_usuario: number;
    tipo: string;
    cliente: string;
    correo: string;
    usuario: Usuario;
}

export interface Usuario {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}
export interface Pedido {
    id: number;
    id_cliente: number;
    id_casa: number;
    costo_alquiler: string;
    descuento_estadia: string;
    descuento_cupon: string;
    costo_servicios_adicionales: string;
    costo_total: string;
    pagado: string;
    saldo: string;
    habilitado: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    fecha_registro: string;
    Cliente: Usuario;
}

export interface Estado {
    nombre: string;
    id: number;
}

interface Props {
    colum1: ItemInterface[];
    colum2: ItemInterface[];
    colum3: ItemInterface[];
    colum4: ItemInterface[];
    colum5: ItemInterface[];
    colum6: ItemInterface[];
    colum7: ItemInterface[];
}
const Export = ({
    colum1,
    colum2,
    colum3,
    colum4,
    colum5,
    colum6,
    colum7,
}: Props) => {
    const handleDownload = () => {
        // Crear los datos que se descargarán
        console.log(colum3);
        const data1 = [
            [
                "ID",
                "Proceso",
                "Tipo",
                "Nombre",
                "Apellido",
                "Fecha de reserva",
                "Monto",
            ],
            ...colum1.map((item) => {
                if (item.tipo === "chat") {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item.id_cliente !== 0
                            ? `${item.usuario.nombre}`
                            : `${item.chat.chats_usuarios[0].cliente}`,
                        item.id_cliente !== 0 ? `${item.usuario.apellido}` : ``,
                        "",
                        "",
                    ];
                } else {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item?.pedido.Cliente.nombre ?? "",
                        item?.pedido.Cliente.apellido ?? "",

                        item?.pedido.fecha_inicio ?? "",
                        `${item?.pedido.costo_total} ${item?.pedido.tipo_moneda}` ??
                            "",
                    ];
                }
            }),
        ];
        const data2 = [
            [
                "ID",
                "Proceso",
                "Tipo",
                "Nombre",
                "Apellido",
                "Fecha de reserva",
                "Monto",
            ],
            ...colum2.map((item) => {
                if (item.tipo === "chat") {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item.id_cliente !== 0
                            ? `${item.usuario.nombre}`
                            : `${item.chat.chats_usuarios[0].cliente}`,
                        item.id_cliente !== 0 ? `${item.usuario.apellido}` : ``,
                        "",
                        "",
                    ];
                } else {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item?.pedido.Cliente.nombre ?? "",
                        item?.pedido.Cliente.apellido ?? "",

                        item?.pedido.fecha_inicio ?? "",
                        `${item?.pedido.costo_total} ${item?.pedido.tipo_moneda}` ??
                            "",
                    ];
                }
            }),
        ];
        const data3 = [
            [
                "ID",
                "Proceso",
                "Tipo",
                "Nombre",
                "Apellido",
                "Fecha de reserva",
                "Monto",
            ],
            ...colum3.map((item) => {
                if (item.tipo === "chat") {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item.id_cliente !== 0
                            ? `${item.usuario.nombre}`
                            : `${item.chat.chats_usuarios[0].cliente}`,
                        item.id_cliente !== 0 ? `${item.usuario.apellido}` : ``,
                        "",
                        "",
                    ];
                } else {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item?.pedido?.Cliente.nombre ?? "",
                        item?.pedido?.Cliente.apellido ?? "",

                        item?.pedido?.fecha_inicio ?? "",
                        `${item?.pedido?.costo_total} ${item?.pedido?.tipo_moneda}` ??
                            "",
                    ];
                }
            }),
        ];
        const data4 = [
            [
                "ID",
                "Proceso",
                "Tipo",
                "Nombre",
                "Apellido",
                "Fecha de reserva",
                "Monto",
            ],
            ...colum4.map((item) => {
                if (item.tipo === "chat") {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item.id_cliente !== 0
                            ? `${item.usuario.nombre}`
                            : `${item.chat.chats_usuarios[0].cliente}`,
                        item.id_cliente !== 0 ? `${item.usuario.apellido}` : ``,
                        "",
                        "",
                    ];
                } else {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item?.pedido.Cliente.nombre ?? "",
                        item?.pedido.Cliente.apellido ?? "",

                        item?.pedido.fecha_inicio ?? "",
                        `${item?.pedido.costo_total} ${item?.pedido.tipo_moneda}` ??
                            "",
                    ];
                }
            }),
        ];
        const data5 = [
            [
                "ID",
                "Proceso",
                "Tipo",
                "Nombre",
                "Apellido",
                "Fecha de reserva",
                "Monto",
            ],
            ...colum5.map((item) => {
                if (item.tipo === "chat") {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item.id_cliente !== 0
                            ? `${item.usuario.nombre}`
                            : `${item.chat.chats_usuarios[0].cliente}`,
                        item.id_cliente !== 0 ? `${item.usuario.apellido}` : ``,
                        "",
                        "",
                    ];
                } else {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item?.pedido.Cliente.nombre ?? "",
                        item?.pedido.Cliente.apellido ?? "",

                        item?.pedido.fecha_inicio ?? "",
                        `${item?.pedido.costo_total} ${item?.pedido.tipo_moneda}` ??
                            "",
                    ];
                }
            }),
        ];
        const data6 = [
            [
                "ID",
                "Proceso",
                "Tipo",
                "Nombre",
                "Apellido",
                "Fecha de reserva",
                "Monto",
                "Motivo de perdida",
            ],
            ...colum6.map((item) => {
                if (item.tipo === "chat") {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item.id_cliente !== 0
                            ? `${item.usuario.nombre}`
                            : `${item.chat.chats_usuarios[0].cliente}`,
                        item.id_cliente !== 0 ? `${item.usuario.apellido}` : ``,
                        "",
                        "",
                        item?.motivo_perdida,
                    ];
                } else {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item?.pedido.Cliente.nombre ?? "",
                        item?.pedido.Cliente.apellido ?? "",

                        item?.pedido.fecha_inicio ?? "",
                        `${item?.pedido.costo_total} ${item?.pedido.tipo_moneda}` ??
                            "",
                        item?.motivo_perdida,
                    ];
                }
            }),
        ];
        const data7 = [
            [
                "ID",
                "Proceso",
                "Tipo",
                "Nombre",
                "Apellido",
                "Fecha de reserva",
                "Monto",
            ],
            ...colum5.map((item) => {
                if (item.tipo === "chat") {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item.id_cliente !== 0
                            ? `${item.usuario.nombre}`
                            : `${item.chat.chats_usuarios[0].cliente}`,
                        item.id_cliente !== 0 ? `${item.usuario.apellido}` : ``,
                        "",
                        "",
                    ];
                } else {
                    return [
                        item?.id ?? "",
                        item?.proceso ?? "",
                        item?.tipo ?? "",
                        item?.pedido.Cliente.nombre ?? "",
                        item?.pedido.Cliente.apellido ?? "",

                        item?.pedido.fecha_inicio ?? "",
                        `${item?.pedido.costo_total} ${item?.pedido.tipo_moneda}` ??
                            "",
                    ];
                }
            }),
        ];
        // Crear una hoja de cálculo

        const workbook = XLSX.utils.book_new();
        const worksheet1 = XLSX.utils.aoa_to_sheet(data1);
        const worksheet2 = XLSX.utils.aoa_to_sheet(data2);
        const worksheet3 = XLSX.utils.aoa_to_sheet(data3);
        const worksheet4 = XLSX.utils.aoa_to_sheet(data4);
        const worksheet5 = XLSX.utils.aoa_to_sheet(data5);
        const worksheet6 = XLSX.utils.aoa_to_sheet(data6);
        const worksheet7 = XLSX.utils.aoa_to_sheet(data7);

        // Agregar la hoja de cálculo al libro
        XLSX.utils.book_append_sheet(
            workbook,
            worksheet1,
            "Primer seguimiento"
        );
        XLSX.utils.book_append_sheet(
            workbook,
            worksheet2,
            "Segundo seguimiento"
        );
        XLSX.utils.book_append_sheet(workbook, worksheet3, "Propuesta enviada");
        XLSX.utils.book_append_sheet(workbook, worksheet4, "Negociación");
        XLSX.utils.book_append_sheet(workbook, worksheet5, "Ganados");
        XLSX.utils.book_append_sheet(workbook, worksheet6, "Perdidos");
        XLSX.utils.book_append_sheet(
            workbook,
            worksheet7,
            "Seguimiento a futuro"
        );

        // Generar el archivo de Excel
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });

        // Crear un objeto Blob a partir del buffer
        const blob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Crear un enlace para descargar el archivo
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "CRM_CLIENTES.xlsx";
        link.click();

        // Liberar el objeto Blob y el enlace
        URL.revokeObjectURL(url);
    };

    return (
        <button
            className="flex items-center gap-1 p-2  ml-2 border-2 rounded-lg border-[#CCCCCC] text-[#808080] hover:scale-105"
            onClick={handleDownload}>
            Descargar en Excel
        </button>
    );
};

export default Export;
