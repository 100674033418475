import React, { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { useAppSelector } from "../../../hooks/hooks";
import { NavbarAdministrativa } from "../NavbarAdministrativa";
import { NavbarAdministrativaMobile } from "../NavbarAdministrativaMobile";

const WhatsappNotificaciones = () => {
    const { socket } = useAppSelector((state) => state.activarSocket);
    const [qrCode, setQrCode] = useState<string | null>(null);

    useEffect(() => {
        socket?.on("qr_update", (data) => {
            setQrCode(data.qr);
        });
    }, []);

    return (
        <NavbarAdministrativa titulo="WhatsApp">
            <div className="flex h-[80vh]  justify-center items-center">
                <div className="bg-[#128C7E] p-5 rounded-md">
                    <div className="text-center font-semibold text-white my-1">
                        WhatsApp notificaciones
                    </div>
                    <div className="bg-white">
                        {qrCode ? (
                            <img
                                className=" h-52 w-52"
                                src={qrCode}
                                alt="QR Code"
                            />
                        ) : (
                            <p>Loading QR code...</p>
                        )}
                    </div>

                    <div className="text-center font-semibold text-white my-1">
                        Por favor escanee el QR
                    </div>
                </div>
            </div>

            <NavbarAdministrativaMobile />
        </NavbarAdministrativa>
    );
};

export default WhatsappNotificaciones;
