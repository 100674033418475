import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";

import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";

interface Props {
    modalVisibleTiempoRespuesta: boolean;
    setModalVisibleTiempoRespuesta: (modalVisibleComodidades: boolean) => void;
}

const arrays_tiempo_respuesta = [
    "15 Minutos",
    "30 Minutos",
    "1 Hora",
    "2 Horas",
    "5 Horas",
    "12 Horas",
    "24 Horas",
];

interface comfort {
    id: number;
    nombre: string;
    icono: string;
    habilitado: string;
    fecha_registro: string;
}

export const ModalTiempoRespuesta = ({
    modalVisibleTiempoRespuesta,
    setModalVisibleTiempoRespuesta,
}: Props) => {
    const { tiempo_respuesta, id: casa_id } = useAppSelector(
        (state) => state.landing_house.house
    );
    const dispatchDominio = useDispatchDominio();

    const [saving, setSaving] = useState<boolean>(false);
    const [selected, setSelected] = useState<string>(tiempo_respuesta);

    const [input, setInput] = useState({
        tiempo_respuesta,
    });

    const handleInput = (tiempo: string) => {
        console.log(tiempo);
        setInput({
            tiempo_respuesta: tiempo,
        });

        setSelected(tiempo);
    };

    const handleSubmit = async () => {
        setSaving(true);

        try {
            const data = await fetchAxiosToken({
                url: `/casa/updateTiempoRespuesta/${casa_id}`,
                method: "put",
                body: input,
            });

            try {
                dispatchDominio();
                setTimeout(() => {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        title: "Actualizado",
                        text: "Descripcion actualizada",
                        icon: "success",
                        confirmButtonText: "Ok",
                    });
                    setSaving(false);
                    setModalVisibleTiempoRespuesta(false);
                }, 2000);
            } catch (error: any) {
                console.log(error.response);
            }
        } catch (error: any) {
            console.log(error.response);
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleTiempoRespuesta}
            setModalVisible={setModalVisibleTiempoRespuesta}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-base font-medium">
                    Tiempo de respuesta del chat
                </h2>
                <hr className="w-full bg-gray-300" />
                <div className="flex flex-col justify-center items-center gap-4 w-4/5">
                    {arrays_tiempo_respuesta.map((tiempo, index) => (
                        <div
                            onClick={() => handleInput(tiempo)}
                            className="w-full"
                            key={index}>
                            <div
                                className={`flex justify-center items-center ${
                                    selected === tiempo
                                        ? "bg-gray-300"
                                        : "bg-gray-100"
                                } border-2 border-gray-400 p-4 rounded-xl w-full hover:scale-105 hover:bg-gray-300 cursor-pointer`}>
                                {tiempo}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col gap-4 mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                            Guardar cambios
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
