export const funcionalidades = [
    {
        group: "Presentación de la Propiedad",
        content: [
            {
                title: "Landing pesonalizada",
                content: {
                    basico: "2 opciones",
                    estandar: "4 opciones",
                    premium: "4 opciones",
                },
            },
            {
                title: "Edición personalizada desde la landing Web",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Contar con un dominio para tu página web",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Sección web con las reseñas de los clientes",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Sección web con las caracteristicas de tu casa",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "E-mail resumen para el propietario y cliente",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Sección web con novedades y eventos",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
        ],
    },

    {
        group: "Configuración y Gestión de la Propiedad",
        content: [
            {
                title: "Contar con un calendario de reservas",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Bloqueo de calendario con noches no disponibles",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Configurar disponibilidad mínima por noche",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Configurar disponibilidad máxima por noches",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Tarifas adicionales (Limpieza, guardia, mantenimiento, otros)",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Rango de precios cada noche por huéspedes",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Precios dinámicos por fechas especificas/eventos",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Precios dinámicos por días de la semana",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Descuento por tiempo de estadía",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Creación de cupones por descuento",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Envío de promociones y descuentos vía e-mail",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Módulo de chat con clientes",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Módulo de CRM para gestionar clientes",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Módulo de contactos e información de clientes",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Propuesta de alquileres por cliente personalizada",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Creación de notas por clientes",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Notificaciones en tiempo real (reservas, chats, reseñas)",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Envío de correos electrónicos",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
        ],
    },

    {
        group: "Pagos y Reserva",
        content: [
            {
                title: "Pagos vía transferencia",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Pasarela de pagos (Stripe, Mercado Pago)",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
        ],
    },

    {
        group: "Contable y Legal",
        content: [
            {
                title: "Integración de facturación electrónica (Emitir boleta o factura)",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
        ],
    },
    /* {
        group: "Marketing y RRSS",
        content: [
            {
                title: "Gestión y/o creación de Facebook",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            {
                title: "Gestión y/o creación de Instagram",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            {
                title: "Gestión y/o creación de TikTok",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            {
                title: "Seguimiento de clientes en RR.SS. y chat de la web",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
        ],
    }, */
    {
        group: "Canales y Conversaciones",
        content: [
            {
                title: "Creación de multiusuarios",
                content: {
                    basico: "No",
                    estandar: "+1 usuario",
                    premium: "+2 usuarios",
                },
            },
            {
                title: "Recordatorio por WhatsApp 1 dia y 3 días antes de estadía",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            {
                title: "Recordatorio por WhatsApp para dejar su reseña de la estadía",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            {
                title: "Chatbot para el chat interno",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            /*  {
                title: "WhatsApp PopUp",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            }, */
        ],
    },

    {
        group: "Integración con Plataforma y Reserva",
        content: [
            {
                title: "Integración de calendario con Airbnb y Booking.com",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            {
                title: "Integración con Calendario de tripadvisor",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            {
                title: "Integración con Google Calendar",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            {
                title: "Integración con calendario de BlueCorner",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
        ],
    },
    {
        group: "Soporte Técnico y Feedback",
        content: [
            {
                title: "Chat de soporte o asistencia personalizada",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Encuesta de satisfacción de cliente por reserva",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            /*  {
                title: "Contenido promocional para las redes sociales",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            {
                title: "Asistente para la administración de su propiedad",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            {
                title: "Asesoria comercial para incrementar alquileres",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            {
                title: "Asesoria en pauta digital",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
            {
                title: "Asesoria comercial legal y financiera sobre los alquileres",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },

            {
                title: "Analitica del desempeño de la web",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            }, */
        ],
    },
];
