import { createSlice } from "@reduxjs/toolkit";
import { infoCasa } from "../../designs/calendario-reserva/typeCasa";
import { io, Socket } from "socket.io-client";

interface InitialState {
    socket:null|Socket // Nuevo parámetro para controlar la visibilidad
}

const INITIAL_STATE:InitialState = {
   
    socket: null, // Valor inicial: oculto
};
const socketServer = process.env.REACT_APP_PROXY_ARCHIVOS || "";

export const activarSocket = createSlice({
    name: "activar_socket",
    initialState: INITIAL_STATE,
    reducers: {
     
       activar_socket: (state,action) => {
        console.log(action.payload,socketServer)
            return {
               
                socket: io(socketServer, {
                        auth: {
                            token: JSON.stringify({
                                room_id: 0,
                                user_id: action.payload.id_usuario,
                            }),
                        },
                    }), // Mostrar información
            };
        },
      
    },
});

// Action creators
export const {activar_socket} =
    activarSocket.actions;

export default activarSocket.reducer;

