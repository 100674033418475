import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";

interface Props {
    setModalVisibleNota: (modalVisibleNota: number) => void;
    modalVisibleNota: number;
    setEstado?: (s: null) => void;
}

export const ModalPerdido = ({
    setModalVisibleNota,
    modalVisibleNota,
    setEstado,
}: Props) => {
    const { id: idUsuario } = useAppSelector((state) => state.usuario_logeado);

    const { id } = useParams();
    const [input, setInput] = useState("Otro");
    const razon = [
        "Otros",
        "Motivos Sin Concretar",
        "Características de la propiedad",
        "Competencia",
        "Desinterés",
        "Disponibilidad fechas",
        "Fuerza mayor",
        "Precio",
        "Sin respuesta",
    ];

    const [saving, setSaving] = useState<boolean>(false);

    const handleInputSelectChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        console.log(e.target.value);
        setInput(e.target.value);
    };

    const handleSubmit = async () => {
        const json = await fetchAxiosToken({
            url: `/crm/updateProcess/${modalVisibleNota}`,
            method: "put",
            body: {
                proceso: "Perdidos",
                motivo_perdida: input,
            },
        });
        console.log(json);
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            // position: "top-end",
            icon: "success",
            title: "Guardado con exito",

            showConfirmButton: true,
            // timer: 2000,
        });
        setEstado && setEstado(null);
        setModalVisibleNota(0);
    };

    return (
        <div className={`relative items-center justify-center z-[999]`}>
            <div
                className={`${"opacity-100 top-0 left-0"} delay-200 transition-opacity ease-out duration-500 fixed flex w-full h-full p-2 xs:p-6 bg-gray-900 bg-opacity-80`}>
                <div className="flex items-center justify-center w-full px-1 py-3">
                    <div
                        style={{ maxWidth: `20rem` }}
                        className={` relative transition-transform ease-in duration-500 block flex-col items-center justify-center py-16 bg-white rounded-lg dark:bg-white xl:py-12 w-[40rem] h-[calc(100vh-30vh)] overflow-y-auto`}>
                        {/* <button
                                onClick={() => handleClose()}
                                className="absolute text-gray-800 dark:text-gray-400 top-3 right-1 md:top-8 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                                <svg
                                    className="rounded-full hover:bg-gray-600 hover:text-white"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18 6L6 18"
                                        stroke="currentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M6 6L18 18"
                                        stroke="currentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button> */}
                        <div className="flex flex-col items-center max-w-sm gap-4 p-4 mx-auto ">
                            <h2 className="text-base font-medium">
                                Razon de la perdida
                            </h2>
                            <hr className="w-full bg-gray-300" />

                            <div>
                                <div className="w-full my-1 text-left">
                                    <header className="mb-3 pl-1 text-[#3C3C3C]  text-base">
                                        Seleccione una:
                                    </header>
                                </div>
                                <div className="w-full px-4 rounded-lg ">
                                    <select
                                        onChange={handleInputSelectChange}
                                        name={input}
                                        value={input}
                                        className="w-full p-2 py-[9px] mt-1 border rounded-xl">
                                        {razon.map((razon, i) => {
                                            return (
                                                <option
                                                    key={i}
                                                    className="bg-transparent"
                                                    value={razon}>
                                                    {" "}
                                                    {razon}{" "}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="flex flex-col gap-4 mt-8">
                                {saving ? (
                                    <Spinner />
                                ) : (
                                    <button
                                        onClick={() => handleSubmit()}
                                        className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                                        Crear
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
