import React, { useState } from "react";
import axios from "axios";

import Swal from "sweetalert2";
import useHeaders from "../../../hooks/useHeaders";

interface createComfort {
    create: boolean;
    setcreate: (cerrar: boolean) => void;
}

const ModalCreatename = ({ create, setcreate }: createComfort) => {
    const [cargando, SetCargando] = useState<boolean>(false);
    const [nombre, setNombre] = useState<string>("");
    const { headers } = useHeaders();

    const handleSubmit = async () => {
        if (nombre === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error!",
                text: "la etiqueta debe tener un nombre",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else {
            SetCargando(true);
            const body = {
                nombre,
            };

            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/etiquetas_blogs/postEtiquetaBlog`,
                    body,
                    { headers }
                );

                if (data.message === "registro exitoso") {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        icon: "success",
                        title: "Etiqueta del blog guardada con exito!",
                        showConfirmButton: false,
                        timer: 2000,
                    });

                    setNombre("");
                    SetCargando(false);
                    setcreate(false);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto sm:py-48">
                    <div className="relative flex flex-col items-center px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 ">
                        <div className="w-full my-3 text-left">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base md:text-xl font-bold ">
                                Agregar etiqueta del blog
                            </header>
                        </div>
                        <div className="w-full my-1 text-left">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base md:text-xl">
                                Nombre de la etiqueta
                            </header>
                        </div>
                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                            <input
                                onChange={(e) => setNombre(e.target.value)}
                                type="text"
                                value={nombre}
                                placeholder="Ej: Horno de barro"
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400"
                            />
                        </div>

                        <div className="w-full ">
                            <div className="mx-auto overflow-hidden bg-white rounded-lg ">
                                <div className="md:flex">
                                    <div className="w-full mt-4">
                                        <div className="pb-3 text-center ">
                                            <button
                                                onClick={() => handleSubmit()}
                                                disabled={cargando}
                                                className="w-full p-3 text-center text-white border rounded-md border-blue-blueFrom bg-blue-blueFrom hover:bg-white hover:text-blue-blueFrom disabled:bg-blue-blueFrom">
                                                {cargando
                                                    ? "Creando..."
                                                    : "Crear"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={() => setcreate(false)}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCreatename;
