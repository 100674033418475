import React, { useEffect, useState } from "react";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { ButtonRegresar } from "../../../components/ButtonRegresar";

import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import FormRespuesta from "./FormRespuesta";
import ToggleButton from "../../../components/helpers/ToggleButton";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { set_landing_house } from "../../../redux/reducers/landingReducer";

interface respuesta_bot {
    id: number;
    id_submenu_bots: number;
    respuesta: string;
    banner_respuesta: string;
}

const INITIAL_RESPUESTA: respuesta_bot = {
    id: 0,
    id_submenu_bots: 0,
    respuesta: "",
    banner_respuesta: "",
};

interface SelectedFile {
    img: File | null;
}

interface submenu_bot {
    id: number;
    id_respuestas_bots: number;
    respuesta: string;
}

interface submenu_bots {
    loading: boolean;
    submenu_bots: submenu_bot[];
}

const Chatbot = () => {
    const [isOpenMensajeInicial, setIsOpenMensajeInicial] =
        useState<boolean>(true);

    const [selectedFile, setSelectedFile] = useState<SelectedFile>({
        img: null,
    });
    const dispatch = useAppDispatch();
    const casa = useAppSelector((state) => state.landing_house);

    const [toggleButton, setToggleButton] = useState<boolean | null>(null);
    const [isReadyToggle, setIsReadyToggleButton] = useState<boolean>(false);

    const [isExistRespuesta, setIsExistRespuesta] = useState<boolean>(false);

    const [inputRespuesta, setInputRespuesta] =
        useState<respuesta_bot>(INITIAL_RESPUESTA);

    const [submenuBots, setSubmenuBots] = useState<submenu_bots>({
        loading: false,
        submenu_bots: [],
    });

    useEffect(() => {
        getData();
    }, []);

    const dispatchDominio = () => {
        fetchAxiosNoToken({
            url: `/casa/getOne/dominio`,
        })
            .then((res) => {
                console.log(res);
                dispatch(set_landing_house(res));
            })
            .catch((err) => {
                console.log(err);
                // dispatch(set_landing_house(null));
            });
    };

    useEffect(() => {
        const updateIsChatBot = async () => {
            const json = await fetchAxiosToken({
                url: `/casa/updateIsChatbot/${casa.house.id}`,
                method: "put",
                body: {
                    isChatbot: toggleButton ? "Si" : "No",
                },
            });
            dispatchDominio();
        };
        if (isReadyToggle) {
            updateIsChatBot();
        }
    }, [toggleButton]);

    const getData = async () => {
        setSelectedFile({ img: null });
        const data = await fetchAxiosToken({
            url: `/respuestas_bot/getAll`,
            method: "get",
        });

        if (data?.id) {
            setInputRespuesta({
                id: data?.id,
                respuesta: data?.respuesta,
                id_submenu_bots: data.id_submenu_bots,
                banner_respuesta: data.imagen,
            });

            setIsExistRespuesta(true);

            if (data.subrespuestas) {
                setSubmenuBots({
                    loading: false,
                    submenu_bots: data.subrespuestas,
                });
            }
        }

        const dataHouse = await fetchAxiosNoToken({
            url: `/casa/getOne/dominio`,
        });

        setToggleButton(dataHouse.isChatbot === "Si" ? true : false);
        setIsReadyToggleButton(true);
    };

    return (
        <div className="flex">
            <Navbar titulo="Chatbot">
                <div className="flex flex-col items-center justify-end w-full sm:mb-0">
                    <div className="flex flex-col w-11/12 gap-4 mx-auto mt-12 max-w-7xl lg:grid-cols-2 right-2">
                        <div>
                            <div className=" text-[#4D4D4D] flex justify-between items-start">
                                <ButtonRegresar
                                    className="-ml-2 "
                                    arrowColor="black"
                                    textColor="black"
                                />
                                <div className="text-center">
                                    <p className="text-xl font-bold 2xl:text-2xl ">
                                        Chatbot
                                    </p>
                                    <p className="mt-6 text-sm 2xl:text-base">
                                        Configura tu chatbot.
                                    </p>
                                    <p className="mt-4 text-sm 2xl:text-base">
                                        Aca puedes configurar que respuestas
                                        podrá dar tu chatbot.
                                    </p>
                                </div>
                                <div className="flex justify-end">
                                    <div className="relative z-10 flex items-center justify-center ">
                                        <span className="text-[#053465] font-medium mx-2">
                                            {toggleButton
                                                ? "Chatbot habilitado"
                                                : "Chatbot deshabilitado"}
                                        </span>
                                    </div>

                                    {toggleButton !== null && (
                                        <ToggleButton
                                            isToggled={toggleButton}
                                            onChangeToggle={(e) =>
                                                setToggleButton(e)
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>{" "}
                        <div
                            className={`border-2 border-blue-100  rounded-md p-2 ${
                                toggleButton
                                    ? ""
                                    : "opacity-50 pointer-events-none "
                            }  `}
                        >
                            <div
                                onClick={() =>
                                    setIsOpenMensajeInicial(
                                        !isOpenMensajeInicial
                                    )
                                }
                                className="p-3 shadow-md w-full 2xl:text-base text-sm flex gap-2 bg-white cursor-pointer mx-auto rounded-md"
                            >
                                {!isOpenMensajeInicial ? (
                                    <svg
                                        className="h-8 w-8"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <polyline points="6 9 12 15 18 9" />
                                    </svg>
                                ) : (
                                    <svg
                                        className="h-8 w-8 "
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <polyline points="6 15 12 9 18 15" />
                                    </svg>
                                )}{" "}
                                Respuesta Inicial
                            </div>
                            {isOpenMensajeInicial && (
                                <FormRespuesta
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    inputRespuesta={inputRespuesta}
                                    setInputRespuesta={setInputRespuesta}
                                    isExistRespuesta={isExistRespuesta}
                                    getData={getData}
                                    submenuBots={submenuBots}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Navbar>
            <NavbarMobile />
        </div>
    );
};

export default Chatbot;
