import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import Spinner from "../../../components/helpers/Spinner";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { verifyToken } from "../../../redux/reducers/authReducer";
import { getHouse } from "../../../redux/reducers/onboardingReducer";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import axios from "axios";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { useParams } from "react-router-dom";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";

interface EstadiaMinima {
    id: number;
    id_casa: number;
    fecha_inicio: string;
    fecha_final: string;
    estadia_minima: number | string;
}

export const UrlName = () => {
    let token = localStorage.getItem("token");
    const {
        id: id_casa,
        estadia_minima,
        estadia_maxima,
        id_propietario,
        dominio,
    } = useAppSelector((state) => state.landing_house.house);
    const dispatch = useAppDispatch();
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const [loading, setLoading] = useState(false);

    const [disponible, setDisponible] = useState("");
    const [dominionew, setDominioNew] = useState(dominio);

    const [disponibilidadMinima, setDisponibilidadMinima] = useState<
        number | null
    >(estadia_minima);
    const [disponibilidadMaxima, setDisponibilidadMaxima] = useState<
        number | null
    >(estadia_maxima);

    const debounceInput = useRef<number | null>(null);

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("inpur");
        if (e.target.name === "grupo_trabajo") {
            if (debounceInput.current !== null)
                clearTimeout(debounceInput.current);
            if (e.target.value === "") {
                setDisponible("");
            }
            debounceInput.current = window.setTimeout(async () => {
                try {
                    const { data } = await axios.get(
                        `${process.env.REACT_APP_PROXY}/casa/isExistPutDominio/${e.target.value}/${id_casa}`
                    );
                    console.log(data);
                    setDisponible(data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }, 500);
        }
        setDominioNew(e.target.value);
    };

    const putMaximaBase = async () => {
        const regDominio = new RegExp("^[a-z0-9-]+$");

        if (!dominionew || dominionew.length < 5) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "El dominionew debe tener al menos 5 caracteres",

                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        } else if (!regDominio.test(dominionew || "")) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "El dominionew no puede tener espacio, aceptos, mayusculas ni caracteres especiales",

                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        } else if (disponible !== "No") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "El dominio no esta disponible , intente con otro",

                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }

        setLoading(true);
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putDominio/${id_casa}`,
                method: "put",
                body: {
                    dominio: dominionew,
                },
            });
            console.log(data);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Actualizado con exito",

                icon: "success",
                confirmButtonText: "Ok",
            });
            window.open(
                `http://${dominionew}.${process.env.REACT_APP_NOMBRE_DOMINIO_ROOT}/dashboard/bienvenidalogin/${token}/`,
                "_self"
            );
        } catch (error: any) {
            console.log(error.response);
        }
    };

    console.log({ disponibilidadMaxima, disponibilidadMinima });

    useEffect(() => {
        if (estadia_maxima || estadia_minima) {
            setDisponibilidadMaxima(estadia_maxima);
            setDisponibilidadMinima(estadia_minima);
        }
    }, [estadia_maxima, estadia_minima]);

    const render = () => {
        return (
            <div className="flex">
                <div className="flex flex-col items-center justify-end w-full mt-12 sm:mb-0">
                    <div className="grid w-11/12 gap-4 mx-auto max-w-7xl lg:grid-cols-2 right-2">
                        <div className=" text-[#4D4D4D]">
                            <ButtonRegresar
                                // url={`/dashboard/onboarding/alojamiento`}
                                className="-ml-2 "
                                arrowColor="black"
                                textColor="black"
                            />
                            <p className="text-xl font-bold 2xl:text-2xl ">
                                URL de mi página
                            </p>
                            <p className="mt-6 text-sm 2xl:text-base">
                                La URL es cómo las personas pueden encontrar tu
                                sitio web. Modificar este parámetro puede hacer
                                que las personas no te encuentren si tienen la
                                URL anterior
                            </p>
                        </div>
                        <div>
                            <div className="w-full max-w-[464px] p-4   border border-[#CCCCCC] rounded-lg shadow-lg mb-6 2xl:text-base text-sm">
                                <div>
                                    <p className="text-[#4D4D4D] font-light text-center">
                                        {" "}
                                        URL actual
                                    </p>
                                </div>
                                <div className="flex items-center mt-2  font-light text-[#4D4D4D] w-full">
                                    <div className="grid grid-cols-2 xs:grid-cols-[60%,40%] gap-2 place-content-around ">
                                        <div>
                                            <div className="relative flex items-center border border-[#E9E9E9] rounded-lg">
                                                <input
                                                    type="text"
                                                    placeholder="casa-linda"
                                                    onChange={handleInputChange}
                                                    name="grupo_trabajo"
                                                    className="w-10/12 px-2 py-2 bg-transparent rounded-lg outline-none placeholder:text-gray-400"
                                                    value={dominionew || ""}
                                                />
                                                {disponible !== "" && (
                                                    <p
                                                        className={`ml-2 ${
                                                            disponible === "Si"
                                                                ? "text-red-600"
                                                                : "text-green-600"
                                                        } text-xs font-semibold`}>
                                                        {disponible === "No"
                                                            ? "URL disponible"
                                                            : "URL no disponible"}
                                                    </p>
                                                )}
                                            </div>
                                            <span
                                                className={`${
                                                    !dominionew
                                                        ? "opacity-0"
                                                        : "opacity-100"
                                                } transition-opacity ease-in-out duration-1000 mt-2 flex text-sm ml-1 text-gray-600`}>
                                                Tu dominio final será:{" "}
                                                {`https://${dominionew}.blustay.io`}
                                            </span>
                                            <span
                                                className={`${
                                                    !dominionew
                                                        ? "opacity-0"
                                                        : "opacity-100"
                                                } transition-opacity ease-in-out duration-1000  flex text-sm ml-1 text-[#1C4CBB]`}>
                                                Puedes usar ("a-z","0-9" y "-")
                                            </span>
                                        </div>
                                        <div className="flex items-start justify-center ml-4">
                                            <button
                                                disabled={loading}
                                                onClick={() => putMaximaBase()}
                                                className="py-2 font-medium text-white rounded-lg uppercase px-6 bg-[#029BFC] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return tipo_usuario === 3 || tipo_usuario === 4 ? (
        <Navbar titulo="URL">
            {render()}
            <NavbarMobile />
        </Navbar>
    ) : tipo_usuario === 6 ? (
        <NavbarBroker titulo="URL">
            {render()}
            <NavbarMobileBroker />
        </NavbarBroker>
    ) : null;
};
