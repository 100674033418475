const useCurrencyPrices = () => {
    const SOLES_PRICE = 4.1;
    const EUR_PRICE = 0.91;

    const SELECTED_CURRENCY =
        localStorage.getItem("selected_currency") || "USD";

    return { SOLES_PRICE, SELECTED_CURRENCY, EUR_PRICE };
};

export default useCurrencyPrices;
