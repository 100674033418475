import { useState } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { ModalEditComforts } from "../modalEdit/ModalEditComforts";
import { ModalGaleriaImagenes } from "../modalEdit/ModalGaleriaImagenes";
import { ModalEditDescripcion1 } from "../modalEdit/ModalEditDescripcion1";

interface Props {
    toggleButton: boolean;
}
const Imagenes = ({ toggleButton }: Props) => {
    const {
        id_propietario,
        casa_comodidades,
        id: casa_id,
        banner,
        casa_imagenes,
        titulo_descripcion_1,
        descripcion_1,
    } = useAppSelector((state) => state.landing_house.house);
    const casa = useAppSelector((state) => state.landing_house.house);

    const [modalImagesVisible, setModalImagesVisible] = useState(false);

    const [modalVisibleComodidades, setModalVisibleComodidades] =
        useState<boolean>(false);

    console.log(casa);

    return (
        <>
            <div className="flex items-center justify-center ">
                {modalVisibleComodidades && (
                    <ModalEditDescripcion1
                        setModalVisibleComodidades={setModalVisibleComodidades}
                        modalVisibleComodidades={modalVisibleComodidades}
                    />
                )}
                {modalImagesVisible && (
                    <ModalGaleriaImagenes
                        imagen_principal={banner}
                        imagenes={casa_imagenes}
                        modalVisible={modalImagesVisible}
                        setModalVisible={setModalImagesVisible}
                    />
                )}
                <div className={"flex justify-center px-5"}></div>
            </div>
            <div className="grid justify-start items-center md818:grid-cols-[60%,40%] gap-6 md:mt-5 2xl:mt-10 2xl:mb-14 md:mb-10">
                <div className="grid gap-2 md818:pr-5">
                    <div>
                        <img
                            src={
                                typeof banner === "string"
                                    ? `${process.env.REACT_APP_PROXY}/casa/getImage/${casa_imagenes[0]?.imagen}`
                                    : URL.createObjectURL(banner)
                            }
                            className="object-cover w-full h-56 xs:h-60 sm:h-72 md818:h-80  max-h-[420px]"
                            alt={`imagen principal`}
                        />
                    </div>
                    <div className="grid grid-cols-4 gap-2">
                        <img
                            src={
                                typeof banner === "string"
                                    ? `${process.env.REACT_APP_PROXY}/casa/getImage/${casa_imagenes[1]?.imagen}`
                                    : URL.createObjectURL(banner)
                            }
                            className="object-cover w-full h-14 xs:h-16 sm:h-24 md818:h-28  max-h-28"
                            alt={`imagen 1`}
                        />
                        <img
                            src={
                                typeof banner === "string"
                                    ? `${process.env.REACT_APP_PROXY}/casa/getImage/${casa_imagenes[2]?.imagen}`
                                    : URL.createObjectURL(banner)
                            }
                            className="object-cover w-full h-14 xs:h-16 sm:h-24 md818:h-28  max-h-28"
                            alt={`imagen 2`}
                        />
                        <img
                            src={
                                typeof banner === "string"
                                    ? `${process.env.REACT_APP_PROXY}/casa/getImage/${casa_imagenes[3]?.imagen}`
                                    : URL.createObjectURL(banner)
                            }
                            className="object-cover w-full h-14 xs:h-16 sm:h-24 md818:h-28  max-h-28"
                            alt={`imagen 3`}
                        />
                        <div
                            onClick={() => setModalImagesVisible(true)}
                            className="flex flex-col items-center justify-center text-[#1C4CBB] font-semibold text-sm sm:text-lg cursor-pointer hover:scale-105"
                        >
                            <p>Ver más</p>
                            <p className="flex items-center">
                                imágenes{" "}
                                <span>
                                    <svg
                                        className="sm:h-6 sm:w-6 w-4 h-4 text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <polyline points="9 6 15 12 9 18" />
                                    </svg>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mx-5">
                    <div className="flex items-center justify-center ">
                        <div className="w-full mt-1 md:mt-4 text-center xs:text-left xs:mt-0">
                            <p className="text-[#1C4CBB] text-lg md:text-xl 2xl:text-2xl">
                                DISFRUTA TU ESTANCIA
                            </p>
                        </div>

                        {toggleButton && (
                            <div
                                onClick={() => setModalVisibleComodidades(true)}
                                title="editar descripcion"
                                className="flex flex-col items-center px-2 py-1 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                            >
                                <svg
                                    className="w-8 h-8 text-[#1C4CBB]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                    <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                    />
                                </svg>
                                <div className="text-xs text-[#1C4CBB] text-center">
                                    Editar Descripción
                                </div>
                            </div>
                        )}
                    </div>
                    <p className="text-[#1C4CBB] text-xl lg:text-3xl 2xl:text-4xl font-semibold mt-2 uppercase text-center xs:text-left">
                        {titulo_descripcion_1
                            ? titulo_descripcion_1
                            : "EL MEJOR RESORT FUERA DE LA CIUDAD"}
                    </p>

                    {descripcion_1 ? (
                        <div
                            className="mt-3 text-sm md:text-base 2xl:text-lg text-[#4D4D4D] text-justify"
                            dangerouslySetInnerHTML={{
                                __html: descripcion_1.replace(/\n/g, "<br>"),
                            }}
                        ></div>
                    ) : (
                        <p className="mt-3 text-[#4D4D4D] text-sm md:text-base 2xl:text-lg text-justify">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit, sed diam nonummy nibh euismod tincidunt ut
                            laoreet dolore magna aliquam erat volutpat. Ut wisi
                            enim ad minim veniam, quis nostrud Lorem ipsum dolor
                            sit amet, consectetuer adipiscing elit, sed diam
                            nonummy
                            <br></br>
                            <br></br>
                            Ut wisi enim ad minim veniam, quis nostrud exerci
                            tation ullamcorper suscipit lobortis nisl ut aliquip
                            ex ea commodo consequat.
                        </p>
                    )}
                </div>
            </div>
        </>
    );
};

export default Imagenes;
