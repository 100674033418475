import React from "react";
import { Navbar } from "./Navbar";
import { NavbarMobile } from "./NavbarMobile";
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";

export const RedesSociales = () => {
    return (
        <div>
            <Navbar titulo="Redes sociales">
                <div className="w-full">
                    <div className="flex items-center justify-between w-full px-6 mx-auto ">
                        <h1 className="text-4xl  py-10 text-center text-[#3C3C3C]">
                            Compartir en redes sociales
                        </h1>
                    </div>

                    <div className="flex justify-center">
                        <FacebookShareButton
                            quote="estoy estoy compartiendoasasasasasas"
                            title="esto es titulo"
                            className="mr-4"
                            url={`https://www.bluecorner.com.pe/blog/35`}>
                            <FacebookIcon size={60} />
                        </FacebookShareButton>
                        <WhatsappShareButton
                            title="esto se comparte"
                            className="mx-3 xs:mx-4"
                            url={`https://www.bluecorner.com.pe/blog/35`}>
                            <WhatsappIcon size={60} />
                        </WhatsappShareButton>
                        <TwitterShareButton
                            className=" xs:mx-4"
                            url={`https://www.bluecorner.com.pe/blog/35`}>
                            <TwitterIcon size={60} />
                        </TwitterShareButton>
                    </div>
                </div>
            </Navbar>
            <NavbarMobile />
        </div>
    );
};
