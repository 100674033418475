import { useState } from "react";
import { NavbarsAvatar } from "../../../components/NavbarsAvatar";
import { useAppSelector } from "../../../hooks/hooks";
import { Notificaiones } from "../../modalEdit/Notificaiones";
import { ElegirMoneda } from "../../../components/ElegirMoneda";

interface Props {
    className: string;
    titles: { id: number; title: string; url: string }[];
    handleSmoothScroll: (title: string) => void;
}

const BurguerMenu = ({ className, titles, handleSmoothScroll }: Props) => {
    const [burguerMenuVisible, setBurguerMenuVisible] =
        useState<boolean>(false);
    const casa = useAppSelector((state) => state.landing_house);
    const { casa_actividades } = useAppSelector(
        (state) => state.landing_house.house
    );

    return (
        <div className="relative">
            <div
                className={`${className} flex justify-between p-3 items-center bg-[#1C4CBB] shadow-md px-5`}>
                <div className="flex items-center gap-2">
                    <svg
                        onClick={() =>
                            setBurguerMenuVisible(!burguerMenuVisible)
                        }
                        className="text-white cursor-pointer h-7 w-7"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1="4" y1="6" x2="20" y2="6" />
                        <line x1="4" y1="12" x2="20" y2="12" />
                        <line x1="4" y1="18" x2="20" y2="18" />
                    </svg>
                    <span className="hidden font-medium text-white uppercase select-none xs:flex">
                        {casa.house.nombre}
                    </span>
                </div>

                <div className="flex items-center justify-between gap-2">
                    <Notificaiones />

                    <ElegirMoneda />
                    <NavbarsAvatar />
                </div>
            </div>
            {/* <div
    className={`${
        burguerMenuVisible ? "w-full xs:w-1/2 md:w-0 p-3" : "w-0"
    } transition-all ease-out duration-700 h-screen absolute bg-[#1C4CBB] top-0 left-0 border flex gap-4 md:hidden`}>
    <svg
        onClick={() => setBurguerMenuVisible(false)}
        className="absolute text-white cursor-pointer h-7 w-7 top-3 right-3 md:hidden"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round">
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
    <div className="flex flex-col w-full gap-5 mt-10">
        {titles.map((title) => (
            <div
                key={title.id}
                onClick={() => handleSmoothScroll(title.title)}>
                <span className="font-medium text-white capitalize cursor-pointer">
                    {title.title}
                </span>
                <hr className="mt-2" />
            </div>
        ))}
    </div>
</div> */}
            <div
                className={`flex md:hidden h-screen bg-[#1C4CBB] border transition-all ease-out duration-1000 absolute ${
                    burguerMenuVisible
                        ? "left-0 xs:w-1/2 md:w-0 p-3 w-full"
                        : "-left-full"
                }`}>
                <svg
                    onClick={() => setBurguerMenuVisible(false)}
                    className="absolute text-white cursor-pointer h-7 w-7 top-3 right-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                <div className="flex flex-col w-full gap-5 mt-10">
                    {titles.map((title) => (
                        <div
                            className={` ${
                                !casa_actividades.length &&
                                title.title === "actividades" &&
                                "hidden"
                            }`}
                            key={title.id}
                            onClick={() => (
                                handleSmoothScroll(title.url),
                                setBurguerMenuVisible(!burguerMenuVisible)
                            )}>
                            <span
                                className={`font-medium text-white capitalize cursor-pointer`}>
                                {title.title}
                            </span>
                            <hr className="mt-2" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BurguerMenu;
