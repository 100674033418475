import React, { useState, useEffect } from "react";
import { InputDate } from "../../../../components/InputDate";
import ResponsiveModalAbsolute from "../../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../../hooks/hooks";
import { DiaNoDisponible } from "./PreciosAvanzados";
import BotonInformacion from "../../../../components/helpers/BotonInformacion";
import Swal from "sweetalert2";

const DAYS = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
];

interface ModalProps {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    getAllDias: () => void;
    selectedDay: DiaNoDisponible | null;
}

type errorObject = { [key: string]: string };

export const ModalEditarDia = ({
    modalVisible,
    setModalVisible,
    getAllDias,
    selectedDay,
}: ModalProps) => {
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState<DiaNoDisponible>({
        tipo: "Fechas",
        fecha_inicio: "",
        fecha_final: "",
        dia_inicio: "Lunes",
        dia_final: "Lunes",
        precio: 0,
    });
    const { id: id_casa, tipo_moneda } = useAppSelector(
        (state) => state.landing_house.house
    );
    const [errors, setErrors] = useState<DiaNoDisponible>({});
    const [pasos, setPasos] = useState<Number>(1);
    useEffect(() => {
        if (selectedDay) {
            setInput({
                ...selectedDay,
            });
        }
    }, [selectedDay]);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const validateInput = (form: DiaNoDisponible) => {
        let errores: errorObject = {};

        if (pasos === 3) {
            if (form.precio !== undefined && form.precio < 1) {
                errores.precio = "Debe ingresar un precio total";
            }
        } else {
            if (pasos === 2 && input.tipo === "Fechas") {
                if (!form.fecha_inicio) {
                    errores.fecha_inicio = "Debe ingresar una fecha válida";
                } else if (!form.fecha_final) {
                    errores.fecha_final = "Debe ingresar una fecha válida";
                } else if (
                    new Date(form.fecha_inicio) > new Date(form.fecha_final)
                ) {
                    errores.fecha_final =
                        "Fecha de inicio no puede ser mayor a la fecha final";
                }
            }
        }

        return errores;
    };

    const handleSubmit = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            if (pasos === 1) setPasos(2);
            if (pasos === 2) setPasos(3);
            if (pasos === 3) {
                try {
                    setLoading(true);
                    const data = await fetchAxiosToken({
                        url: `/precios_variables_semanas/update/${selectedDay?.id}`,
                        body: input,
                        method: "put",
                    });

                    setLoading(false);
                    setModalVisible(false);
                    setInput({
                        tipo: "Fechas",
                        fecha_inicio: "",
                        fecha_final: "",
                        dia_inicio: "Lunes",
                        dia_final: "Lunes",
                        precio: 0,
                    });
                    setErrors({});
                    getAllDias();
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        title: "Actualizado con exito",

                        icon: "success",
                        confirmButtonText: "Ok",
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            onClose={() => {
                setErrors({});
                setInput({
                    tipo: "Fechas",
                    fecha_inicio: "",
                    fecha_final: "",
                    dia_inicio: "Lunes",
                    dia_final: "Lunes",
                    precio: 0,
                });
                setPasos(1);
            }}>
            <div className="flex flex-col items-center gap-2 p-6">
                <div className="relative flex items-center justify-center">
                    <h1 className="mr-1 2xl:text-lg text-base font-medium">
                        Editar tarifa
                    </h1>
                    <BotonInformacion text="Ingresa las noches de la semana o fechas donde cobraras un monto fijo" />
                </div>

                {pasos === 1 && (
                    <>
                        <div className="text-sm text-blue-400 max-w-xs ">
                            Primeramente ingresa los días de la semana que
                            deseas hacer la tarifa.
                        </div>
                        <label className="flex w-full max-w-xs ml-2 text-sm font-medium">
                            Día de la semana inicial
                        </label>
                        <select
                            value={input.dia_inicio}
                            name="dia_inicio"
                            onChange={handleInputChange}
                            className="w-full max-w-xs  2xl:p-3 p-2 border rounded-lg shadow-md placeholder:italic">
                            {DAYS.map((day) => (
                                <option key={day} value={day}>
                                    {day}
                                </option>
                            ))}
                        </select>
                        {errors.dia_inicio && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.dia_inicio}
                            </p>
                        )}

                        <label className="flex w-full max-w-xs ml-2 text-sm font-medium">
                            Día de la semana final
                        </label>
                        <select
                            value={input.dia_final}
                            name="dia_final"
                            onChange={handleInputChange}
                            className="w-full max-w-xs  2xl:p-3 p-2 border rounded-lg shadow-md placeholder:italic">
                            {DAYS.map((day) => (
                                <option key={day} value={day}>
                                    {day}
                                </option>
                            ))}
                        </select>
                        {errors.dia_final && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.dia_final}
                            </p>
                        )}
                    </>
                )}

                {pasos === 2 && (
                    <>
                        <div
                            onClick={() => setPasos(1)}
                            className={`text-black flex w-full max-w-xs py-5 items-center 2xl:text-base text-sm  cursor-pointer hover:underline hover:scale-105`}>
                            <svg
                                className="w-5 h-5 mt-0.5 text-black"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <polyline points="15 18 9 12 15 6" />
                            </svg>
                            <span className="mx-2">Regresar</span>
                        </div>
                        <div className="text-sm text-blue-400 max-w-xs">
                            Ahora selecciona si la tarifa de días de la semana
                            lo deseas cobrar siempre o por una temporada
                            especial.
                        </div>
                        <label className="flex w-full max-w-xs ml-2 text-sm font-medium">
                            Tipo
                        </label>
                        <select
                            value={input.tipo}
                            name="tipo"
                            onChange={handleInputChange}
                            className="w-full max-w-xs 2xl:p-3 p-2 border rounded-lg shadow-md placeholder:italic">
                            <option value={"Fechas"}>Por temporada</option>
                            <option value={"Siempre"}>Siempre</option>
                        </select>
                    </>
                )}

                {input.tipo === "Fechas" && pasos === 2 ? (
                    <>
                        <label className="flex w-full max-w-xs ml-2 text-sm font-medium">
                            Fecha inicial
                        </label>
                        <InputDate
                            selectedDay={input.fecha_inicio}
                            onChange={(date) =>
                                setInput({
                                    ...input,
                                    fecha_inicio: date,
                                })
                            }
                            className="flex max-w-xs  2xl:p-3 p-2 mx-auto border rounded-lg shadow-md placeholder:italic"
                            placeholderText="21/02/2023"
                        />
                        {errors.fecha_inicio && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.fecha_inicio}
                            </p>
                        )}
                        <label className="flex w-full max-w-xs ml-2 text-sm font-medium">
                            Fecha final
                        </label>
                        <InputDate
                            selectedDay={input.fecha_final}
                            onChange={(date) =>
                                setInput({
                                    ...input,
                                    fecha_final: date,
                                })
                            }
                            className="flex max-w-xs  2xl:p-3 p-2 mx-auto border rounded-lg shadow-md placeholder:italic"
                            placeholderText="21/02/2023"
                        />
                        {errors.fecha_final && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.fecha_final}
                            </p>
                        )}
                    </>
                ) : (
                    <></>
                )}

                {pasos === 3 && (
                    <>
                        <div
                            onClick={() => setPasos(2)}
                            className={`text-black flex w-full max-w-xs py-5 items-center 2xl:text-base text-sm  cursor-pointer hover:underline hover:scale-105`}>
                            <svg
                                className="w-5 h-5 mt-0.5 text-black"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <polyline points="15 18 9 12 15 6" />
                            </svg>
                            <span className="mx-2">Regresar</span>
                        </div>
                        <div className="text-sm text-blue-400 max-w-xs ">
                            Ahora selecciona el precio total que se cobrara
                            entre los días de la semana que seleccionaste
                        </div>
                        <label className="flex w-full max-w-xs ml-2 text-sm font-medium">
                            Precio total
                        </label>
                        <div className="flex items-center w-full max-w-xs 2xl:p-3 p-2 border rounded-lg shadow-md placeholder:italic">
                            <input
                                value={input.precio}
                                name="precio"
                                type="number"
                                onChange={handleInputChange}
                                className="w-full outline-none resize-none "
                            />
                            <p>{tipo_moneda}</p>
                        </div>
                        {errors.precio && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.precio}
                            </p>
                        )}
                    </>
                )}

                <button
                    disabled={loading}
                    onClick={() => handleSubmit()}
                    className="p-3 mt-6 font-medium text-white border rounded-lg bg-[#1C4CBB] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default">
                    {Number(pasos) < 4
                        ? "Siguiente"
                        : "Guardar tarifa especial"}
                </button>
            </div>
        </ResponsiveModalAbsolute>
    );
};
