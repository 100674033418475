import { infoCasa } from "../designs/calendario-reserva/typeCasa";
import { DateIsBetween2Dates } from "../helpers/DateIsBetween2Dates";
import { transformDatesToSlash } from "../helpers/transformDatesToSlash";
import { set_precio_personas_adicionales } from "../redux/reducers/precioReservaReducer";
import { useAppDispatch, useAppSelector } from "./hooks";

interface Props {
    huespedes: {
        adultos: number;
        ninos: number;
        mascotas: number;
    };
    infoCasa: infoCasa;
}

export const usePrecioPersonasAdicionales = ({
    huespedes,
    infoCasa,
}: Props) => {
    let { personas_adicionales } = infoCasa;
    const total_huespedes = huespedes.adultos + huespedes.ninos;
    const dispatch = useAppDispatch();
    const { dates_selected } = useAppSelector((state) => state.precios_reserva);

    const matchPersonasAdicionalesFecha = personas_adicionales.find(
        (rango_persona) => {
            if (
                total_huespedes >= rango_persona.rango_inicio &&
                total_huespedes <= rango_persona.rango_final &&
                rango_persona.tipo === "Fecha"
            ) {
                //Transformo las fechas de la base de datos a objetos new Date()
                const nueva_fecha_inicio = new Date(
                    transformDatesToSlash({ date: rango_persona.fecha_inicio })
                );
                const nueva_fecha_final = new Date(
                    transformDatesToSlash({ date: rango_persona.fecha_final })
                );

                if (
                    dates_selected.allDates.some((dateSelected) => {
                        if (
                            DateIsBetween2Dates({
                                fecha_evaluada: dateSelected,
                                fecha_inicio: nueva_fecha_inicio,
                                fecha_final: nueva_fecha_final,
                            })
                        )
                            return true;
                    })
                ) {
                    return rango_persona;
                }
            }
        }
    );

    //Si en matchNights encuentro uno por fechas lo uso, sino intento encontrar uno por tipo "Siempre"
    if (matchPersonasAdicionalesFecha) {
        dispatch(
            set_precio_personas_adicionales(
                total_huespedes * Number(matchPersonasAdicionalesFecha.precio)
            )
        );
    } else {
        const matchPersonasAdicionalesSiempre = personas_adicionales.find(
            (rango_persona) =>
                total_huespedes >= rango_persona.rango_inicio &&
                total_huespedes <= rango_persona.rango_final &&
                rango_persona.tipo === "Siempre"
        );

        if (matchPersonasAdicionalesSiempre) {
            dispatch(
                set_precio_personas_adicionales(
                    total_huespedes *
                        Number(matchPersonasAdicionalesSiempre.precio)
                )
            );
        } else {
            dispatch(set_precio_personas_adicionales(0));
        }
    }
};
