import React, { ChangeEvent, ReactNode, useState } from "react";

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";

import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { InputDate } from "../../../components/InputDate";
import { getHouse } from "../../../redux/reducers/onboardingReducer";
import ModalPromoCreada from "./ModalPromoCreada";
import ToggleButton from "../../../components/helpers/ToggleButton";

interface Input {
    id_casa: number | null;

    descuento: string;
    fecha_expiracion: string;
    correo: string;
}

interface ModalProps {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
}

export const ModalNewCoupon = ({
    modalVisible,
    setModalVisible,
}: ModalProps) => {
    registerLocale("es", es);
    const dispatch = useAppDispatch();
    const [modalPromoCreada, setModalPromoCreada] = useState<boolean>(false);
    const {
        id: id_casa,
        id_propietario,
        tipo_moneda,
    } = useAppSelector((state) => state.landing_house.house);
    const [cupon, setCupon] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState<Input>({
        id_casa: id_casa,
        descuento: "",
        fecha_expiracion: "",
        correo: "",
    });
    const [enviarCorreos, setEnviarCorreos] = useState<string>("option1");
    const [correos, setCorreos] = useState<string[]>([]);

    const handleAgregarCorreo = () => {
        const correo = input.correo.trim();
        if (correo === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar un correo válido",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }

        if (correos.includes(correo)) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "El correo ya ha sido agregado",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }

        if (correos.length >= 20) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "No se pueden agregar más de 20 correos",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }

        setCorreos([...correos, correo]);
        setInput({ ...input, correo: "" });
    };

    const handleEliminarCorreo = (correo: string) => {
        const nuevosCorreos = correos.filter((c) => c !== correo);
        setCorreos(nuevosCorreos);
    };

    const handleSubmit = async () => {
        if (input.fecha_expiracion === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes seleccionar la fecha de expiracion ",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }

        if (input.descuento === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar el valor a descontar",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }

        try {
            setLoading(true);
            const data = await fetchAxiosToken({
                url: `/descuento_cupones`,
                body: {
                    ...input,
                    correos: correos,
                    enviarCorreos,
                },
                method: "post",
            });

            console.log(data);
            setModalPromoCreada(true);
            setCupon(data.data.cupon);

            // Swal.fire({  confirmButtonColor: "#029BFC",
            // iconColor: "#029BFC",
            //     title: "Creado con exito",

            //     icon: "success",
            //     confirmButtonText: "Ok",
            // });
            // setModalVisible(false);
            setLoading(false);
        } catch (error: any) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: `${error.response.data.error}`,
                icon: "error",
                confirmButtonText: "Ok",
            });
            setLoading(false);
        }
    };

    const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEnviarCorreos(event.target.value);
    };

    return (
        <>
            <ResponsiveModalAbsolute
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}>
                {modalPromoCreada && cupon && (
                    <ModalPromoCreada
                        setModalPadre={setModalVisible}
                        setModalVisible={setModalPromoCreada}
                        cupon={cupon}
                        cuponBlue={true}
                    />
                )}
                <h1 className="text-base font-medium text-center 2xl:text-lg">
                    Nuevo Cupon
                </h1>
                <div className="flex flex-col max-w-md gap-4 p-4 mx-auto ">
                    <div className="flex flex-col items-center justify-center">
                        <>
                            <hr className="w-full my-4 bg-gray-300" />
                            <div className="w-full">
                                <h2 className="text-sm font-medium text-left 2xl:text-base">
                                    Fecha de expiración
                                </h2>
                                <div className="flex items-center w-full mt-2">
                                    <label className="text-base font-medium 2xl:text-lg ">
                                        Fecha:
                                    </label>
                                    <InputDate
                                        className="w-8/12 p-2 ml-6 bg-white border rounded-lg cursor-pointer 2xl:text-base text-sm 2xl:py-2 py-1.5"
                                        selectedDay={input.fecha_expiracion}
                                        placeholderText="Seleccionar fecha"
                                        onChange={(date) =>
                                            setInput({
                                                ...input,
                                                fecha_expiracion: date
                                                    ? date
                                                    : "",
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </>

                        <hr className="w-full my-4 bg-gray-300" />
                        <div className="w-full">
                            <h2 className="text-sm font-medium text-left 2xl:text-base">
                                Descuento
                            </h2>
                            <div className="flex items-center w-full mt-2">
                                <label className="text-base font-medium 2xl:text-lg ">
                                    Valor: ({tipo_moneda})
                                </label>

                                <div className=" w-28  ml-2 pl-[1px]  xs:px-2 rounded-lg py-2 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400 flex">
                                    <p>-</p>
                                    <input
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                descuento: e.target.value,
                                            })
                                        }
                                        autoComplete="off"
                                        className="w-full px-1 text-sm rounded-lg outline-none 2xl:text-base"
                                        placeholder=" 50"
                                        value={input.descuento}
                                        name="descuento_propietario"
                                        type="number"></input>
                                </div>
                            </div>

                            <div className="flex items-center gap-4 mt-2 text-sm cursor-pointer 2xl:text-base">
                                <input
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:bg-blue-500 "
                                    type="radio"
                                    value="option1"
                                    checked={enviarCorreos === "option1"}
                                    onChange={handleOptionChange}
                                />
                                <div className="text-sm 2xl:text-base">
                                    Deseo enviar este cupon a todos mis
                                    contactos
                                </div>
                            </div>

                            <div className="flex items-center gap-4 mt-2 text-sm cursor-pointer 2xl:text-base">
                                <input
                                    type="radio"
                                    value="option2"
                                    checked={enviarCorreos === "option2"}
                                    onChange={handleOptionChange}
                                />
                                <p>Enviar a contactos que yo elija</p>
                            </div>
                            {enviarCorreos === "option2" && (
                                <div>
                                    <p className="mt-2 text-sm font-medium text-left 2xl:text-base">
                                        Correo:
                                    </p>
                                    <div className="flex items-center">
                                        <input
                                            type="text"
                                            className="w-8/12 bg-white border rounded-lg 2xl:text-base text-sm 2xl:py-2 py-1.5"
                                            placeholder="blu@gmail.com"
                                            value={input.correo}
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    correo: e.target.value,
                                                })
                                            }
                                        />
                                        <button
                                            onClick={handleAgregarCorreo}
                                            title="Agregar"
                                            className="flex items-center px-2 2xl:text-base text-sm 2xl:py-2 py-1.5 border rounded-lg cursor-pointer opacity-80 bg-blue-blueFrom hover:opacity-100 mx-1 text-white">
                                            Agregar
                                        </button>
                                    </div>
                                    <p className="mt-2 font-serif text-sm font-medium text-gray-400">
                                        puedes enviar hasta a 50 usuarios
                                    </p>
                                    <div>
                                        <p className="mt-2 text-sm font-medium text-left 2xl:text-base">
                                            Correos agregados:
                                        </p>
                                        {correos.length === 0 ? (
                                            <p className="mt-2 font-serif text-sm font-medium text-gray-400">
                                                No se han agregado correos
                                            </p>
                                        ) : (
                                            <ul className="mt-2 space-y-1 overflow-y-auto max-h-20">
                                                {correos.map((correo) => (
                                                    <li
                                                        key={correo}
                                                        className="flex items-center">
                                                        <p>{correo}</p>

                                                        <svg
                                                            onClick={() =>
                                                                handleEliminarCorreo(
                                                                    correo
                                                                )
                                                            }
                                                            className="w-5 h-5 ml-1 text-red-500 cursor-pointer hover:scale-105"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round">
                                                            {" "}
                                                            <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />{" "}
                                                            <line
                                                                x1="15"
                                                                y1="9"
                                                                x2="9"
                                                                y2="15"
                                                            />{" "}
                                                            <line
                                                                x1="9"
                                                                y1="9"
                                                                x2="15"
                                                                y2="15"
                                                            />
                                                        </svg>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <button
                        disabled={loading}
                        onClick={() => handleSubmit()}
                        className="p-3 mt-6 font-medium text-white border rounded-lg bg-[#029BFC] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default 2xl:text-base text-sm">
                        Crear cupon
                    </button>
                </div>
            </ResponsiveModalAbsolute>
        </>
    );
};
