import { useState, useEffect } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { ModalEditRooms } from "../modalEdit/ModalEditRooms";
import { Habitacion } from "../../pages/onboardingPropietario/habitaciones/Habitaciones";
import Lightbox from "react-18-image-lightbox";

interface Props {
    toggleButton: boolean;
}

const Habitaciones = ({ toggleButton }: Props) => {
    const {
        id_propietario,
        casa_habitaciones,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);
    const [modalVisibleRooms, setModalVisibleRooms] = useState<boolean>(false);
    const [showDescription, setShowDescription] = useState<number | null>(null);
    const [photos, setPhotos] = useState([
        {
            src: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${casa_habitaciones[0]?.imagen}`,
            original: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${casa_habitaciones[0]?.imagen}`,
            width: 400,
            height: 200,
        },
    ]);
    const [index, setIndex] = useState(-1);

    const currentImage = photos?.[index];
    const nextIndex = (index + 1) % photos?.length;
    const nextImage = photos?.[nextIndex] || currentImage;
    const prevIndex = (index + photos?.length - 1) % photos?.length;
    const prevImage = photos?.[prevIndex] || currentImage;

    const handleClick = (index: any) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    useEffect(() => {
        let imagenesTotal: any = photos.length ? [...photos] : [];
        casa_habitaciones?.map((imagen) => {
            imagenesTotal.push({
                src: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${imagen.imagen}`,
                original: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${imagen.imagen}`,

                height: 200,
            });
        });

        setPhotos(imagenesTotal);
    }, [casa_habitaciones]);
    return (
        <>
            <div className="flex flex-col items-center justify-center ">
                {!!currentImage && (
                    <Lightbox
                        mainSrc={currentImage.original}
                        mainSrcThumbnail={currentImage.src}
                        nextSrc={nextImage.original}
                        nextSrcThumbnail={nextImage.src}
                        prevSrc={prevImage.original}
                        prevSrcThumbnail={prevImage.src}
                        onCloseRequest={handleClose}
                        onMovePrevRequest={handleMovePrev}
                        onMoveNextRequest={handleMoveNext}
                    />
                )}
                {modalVisibleRooms && (
                    <ModalEditRooms
                        modalVisibleRooms={modalVisibleRooms}
                        setModalVisibleRooms={setModalVisibleRooms}
                    />
                )}
                {toggleButton && (
                    <>
                        <hr className="w-full border-gray-300" />
                        <div className="flex items-center justify-center gap-2">
                            <p className="text-[#1C4CBB] text-xl md:text-3xl font-semibold">
                                {" "}
                                Agregar habitaciones
                            </p>
                            <div
                                onClick={() => setModalVisibleRooms(true)}
                                title="editar habitaciones"
                                className="flex flex-col items-center px-2 py-1 my-5 mr-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                            >
                                <svg
                                    className="w-10 h-10 text-[#1C4CBB]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                    <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                    />
                                </svg>
                                <div className="text-xs text-[#1C4CBB] text-center">
                                    Editar Habitaciones
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="flex flex-row flex-wrap justify-center w-full gap-2 md:mb-10 2xl:mb-20 ">
                {casa_habitaciones?.map((habitacion, index) => (
                    <div
                        key={habitacion.id}
                        className="relative flex flex-col items-center w-3/4 sm:w-1/3 lg:w-1/4"
                    >
                        <div className="mb-3 text-base md:text-lg text-gray-600 mt-5 md:mt-0">
                            {habitacion.habitacion}
                        </div>
                        <div
                            onClick={() => handleClick(index + 1)}
                            className="relative w-full cursor-pointer h-56 md:h-60"
                        >
                            <img
                                src={`${process.env.REACT_APP_PROXY}/casa_habitaciones/getImage/${habitacion.imagen}`}
                                alt={habitacion.habitacion}
                                className="object-cover w-full h-full rounded-lg "
                            />
                            <div className="absolute bottom-0 w-full rounded-lg h-1/3 bg-gradient-to-b from-transparent to-zinc-900 "></div>
                            <div className="absolute z-20 flex items-end justify-center w-full h-full bottom-3">
                                <div className="flex flex-col w-1/2 mx-5">
                                    <div className="mt-1 text-xs text-white lg:text-base">
                                        {habitacion?.descripcion}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Habitaciones;
