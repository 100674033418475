import React, { ReactNode, useState } from "react";
import ResponsiveModalAbsolute from "../../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../../hooks/hooks";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { InputDate } from "../../../../components/InputDate";

interface Input {
    id_casa: number | null;
    rango_inicio: string;
    rango_final: string;
    precio: string;
    tipo: string;
    fecha_inicio: string;
    fecha_final: string;
    id: number;
}

interface PersonasAdicionales {
    fecha_final: string;
    fecha_inicio: string;
    id: number;
    id_casa: number;
    precio: string;
    rango_final: number;
    rango_inicio: number;
    tipo: string;
}

interface ModalProps {
    personaSelect: PersonasAdicionales;
    setPersonaSelect: (s: PersonasAdicionales | null) => void;
    modalVisibleEdit: boolean;
    setModalVisibleEdit: (s: boolean) => void;
}

type errorObject = { [key: string]: string };

export const ModalEditPeople = ({
    personaSelect,
    setPersonaSelect,
    modalVisibleEdit,
    setModalVisibleEdit,
}: ModalProps) => {
    registerLocale("es", es);

    const { id: id_casa, tipo_moneda } = useAppSelector(
        (state) => state.landing_house.house
    );

    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState<Input>({
        id_casa: personaSelect.id_casa,
        rango_inicio: `${personaSelect.rango_inicio}`,
        rango_final: `${personaSelect.rango_final}`,
        precio: personaSelect.precio,
        tipo: personaSelect.tipo,
        fecha_inicio:
            personaSelect.tipo === "Siempre" ? "" : personaSelect.fecha_inicio,
        fecha_final:
            personaSelect.tipo === "Siempre" ? "" : personaSelect.fecha_final,
        id: personaSelect.id,
    });

    const handleSubmit = async () => {
        if (input.precio === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar un precio adicional ",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }

        if (input.rango_inicio === "" || input.rango_final === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar la cantidad de personas (Desde y Hasta) ",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }

        if (
            input.tipo === "Fecha" &&
            (input.fecha_inicio === "" || input.fecha_final === "")
        ) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar el rango de fechas",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }

        try {
            setLoading(true);
            const data = await fetchAxiosToken({
                url: `/personas_adicionales/put/${input.id}`,
                body: {
                    ...input,
                    rango_inicio: Number(input.rango_inicio),
                    rango_final: Number(input.rango_final),
                },
                method: "put",
            });

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Actualizado con exito",

                icon: "success",
                confirmButtonText: "Ok",
            });
            setPersonaSelect(null);
            setModalVisibleEdit(false);
            setLoading(false);
        } catch (error: any) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: `${error.response.data.error}`,
                icon: "error",
                confirmButtonText: "Ok",
            });
            setLoading(false);
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleEdit}
            setModalVisible={setModalVisibleEdit}>
            <h1 className="2xl:text-lg text-base font-medium text-center">
                Editar personas adicionales
            </h1>
            <div className="flex flex-col max-w-md gap-4 p-4 mx-auto ">
                <div className="flex flex-col items-center justify-center">
                    <div className="w-full">
                        <label className="2xl:text-lg text-base font-medium ">
                            Por:
                        </label>
                        <select
                            name="tipo_estadia"
                            onChange={(e) =>
                                setInput({
                                    ...input,
                                    tipo: e.target.value,
                                })
                            }
                            className="w-8/12 ml-2  pl-1 xs:px-4 rounded-lg 2xl:py-2 py-1.5 2xl:text-base text-sm bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400 cursor-pointer"
                            value={input.tipo}>
                            <option value="Siempre">Siempre</option>
                            <option value="Fecha">Fecha</option>
                        </select>
                    </div>

                    {input.tipo === "Fecha" && (
                        <>
                            <hr className="w-full my-4 bg-gray-300" />
                            <div className="w-full">
                                <h2 className="2xl:text-base text-sm font-medium text-left">
                                    Fechas
                                </h2>
                                <div className="flex items-center w-full mt-2">
                                    <label className="2xl:text-lg text-base font-medium ">
                                        Inicial:
                                    </label>
                                    <InputDate
                                        className="w-8/12 p-2 ml-6 bg-white border rounded-lg cursor-pointer 2xl:py-2 py-1.5 2xl:text-base text-sm"
                                        selectedDay={input.fecha_inicio}
                                        placeholderText="Seleccionar fecha"
                                        onChange={(date) =>
                                            setInput({
                                                ...input,
                                                fecha_inicio: date ? date : "",
                                            })
                                        }
                                    />
                                </div>
                                <div className="flex items-center w-full mt-2">
                                    <label className="mr-2 2xl:text-lg text-base font-medium">
                                        Final:
                                    </label>

                                    <InputDate
                                        className="w-8/12 p-2 ml-6 bg-white border rounded-lg cursor-pointer 2xl:py-2 py-1.5 2xl:text-base text-sm"
                                        selectedDay={input.fecha_final}
                                        placeholderText="Seleccionar fecha"
                                        onChange={(date) =>
                                            setInput({
                                                ...input,
                                                fecha_final: date ? date : "",
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <hr className="w-full my-4 bg-gray-300" />
                    <div className="w-full">
                        <h2 className="2xl:text-base text-sm font-medium text-left">
                            Cantidad de personas
                        </h2>
                        <div className="flex items-center w-full mt-2">
                            <label className="2xl:text-lg text-base font-medium ">
                                Desde:
                            </label>
                            <input
                                type="number"
                                placeholder="Personas"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        rango_inicio: e.target.value,
                                    })
                                }
                                className=" w-20 xs:w-24 ml-2 pl-[1px]  xs:px-2 rounded-lg py-2 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400 2xl:py-2 py-1.5 2xl:text-base text-sm"
                                value={input.rango_inicio}
                            />
                            <label className="ml-2 2xl:text-lg text-base font-medium ">
                                Hasta:
                            </label>
                            <input
                                type="number"
                                placeholder="Personas"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        rango_final: e.target.value,
                                    })
                                }
                                className="  w-20 xs:w-24 ml-2 pl-[1px] xs:px-2 rounded-lg 2xl:py-2 py-1.5 2xl:text-base text-sm bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400"
                                value={input.rango_final}
                            />
                        </div>
                    </div>

                    <hr className="w-full my-4 bg-gray-300" />
                    <div className="w-full">
                        <h2 className="2xl:text-base text-sm font-medium text-left">
                            Costo
                        </h2>
                        <div className="items-center w-full mt-2 xs:flex">
                            <label className="font-medium 2xl:text-base text-sm ">
                                Precio adicional por noche ({tipo_moneda}):
                            </label>
                            <input
                                type="number"
                                placeholder="50"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        precio: e.target.value,
                                    })
                                }
                                className=" w-8/12 xs:w-16 mt-1 xs:ml-2 px-2 rounded-lg 2xl:py-2 py-1.5 2xl:text-base text-sm bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400"
                                value={input.precio}
                            />
                        </div>
                    </div>
                </div>

                <button
                    disabled={loading}
                    onClick={() => handleSubmit()}
                    className="p-3 mt-6 font-medium text-white border rounded-lg bg-[#029BFC] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default 2xl:text-base text-sm">
                    Actualizar personas adicionales
                </button>
            </div>
        </ResponsiveModalAbsolute>
    );
};
