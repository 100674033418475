import { useDispatch } from "react-redux";
import { fetchAxiosNoToken } from "../helpers/fetchAxiosNoToken";
import { set_corredor } from "../redux/reducers/corredorReducer";

export const useDispatchDominioCorredor = () => {
    const dispatch = useDispatch();

    const dispatchDominio = () => {
        fetchAxiosNoToken({
            url: `/corredor/getOneCorredor/subdomain`,
        })
            .then((res) => {
                console.log(res);
                dispatch(set_corredor(res));
            })
            .catch((err) => {
                console.log(err);
                // dispatch(set_landing_house(null));
            });
    };

    return dispatchDominio;
};
