import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { ChatCliente } from "../../pages/dashboardClientes/chat/ChatCliente";
import { boolean, number } from "yargs";
import Swal from "sweetalert2";
import { async } from "q";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";

export interface UsersChat {
    id: number;
    id_chat: number;
    id_usuario: number;
    tipo: string;
    cliente: string;
    correo: string;
    usuario: Usuario;
}

export interface Usuario {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

interface mensaje {
    id_cliente: number;
    titulo: string;
    cliente: string;
    correo: string;
}

export interface chatInvitado {
    id: number;
    id_cliente: number;
    id_propietario: number;
    titulo: string;
    id_respuestas_bot: string;
    estado: string;
    fecha_registro: string;
    cliente: string;
    correo: string;
    codigo: string;
    usersChat: UsersChat[];
}

export const ModalChatInvitado = ({
    setAbrirChat,
    abrirChat,
}: {
    setAbrirChat: (e: boolean) => void;
    abrirChat: boolean;
}) => {
    const { id: id_usuario } = useAppSelector((state) => state.usuario_logeado);
    const [mensaje, setMensaje] = useState<mensaje>({
        id_cliente: 0,
        titulo: "",
        cliente: "",
        correo: "",
    });
    const [cargando, setCargando] = useState(false);
    const [codigoChat, setCodigoChat] = useState(
        localStorage.getItem("chatCodigo") || ""
    );
    const [chatInvitado, setChatInvitado] = useState<chatInvitado | null>(null);

    const getCodigoChat = async (codigo: string) => {
        const dataCodigo = await fetchAxiosNoToken({
            url: `/chat/getByCodigo/${codigo}`,
            method: "get",
        });
        console.log(dataCodigo);
        setChatInvitado(dataCodigo);
        setCargando(false);
    };

    useEffect(() => {
        if (localStorage.getItem("chatCodigo")) {
            let codigo = localStorage.getItem("chatCodigo") || "";
            getCodigoChat(codigo);
        }
    }, []);

    const iniciarChat = async () => {
        if (mensaje.cliente === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar tu nombre para iniciar el chat ",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }
        if (mensaje.correo === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar tu correo para iniciar el chat ",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }
        if (mensaje.titulo === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar un titulo para iniciar el chat ",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }
        setCargando(true);

        const data = await fetchAxiosNoToken({
            url: `/chat`,
            method: "post",
            body: mensaje,
        });

        localStorage.setItem("chatCodigo", data.data.codigo);

        const dataCodigo = await fetchAxiosNoToken({
            url: `/chat/getByCodigo/${data.data.codigo}`,
            method: "get",
        });
        setChatInvitado(dataCodigo);
        setCargando(false);
        setCodigoChat(data.data.codigo);
    };

    return (
        <div>
            {chatInvitado ? (
                <div className=" w-80">
                    {
                        <ChatCliente
                            landing={abrirChat}
                            setLanding={setAbrirChat}
                            chatInvitado={chatInvitado}
                        />
                    }
                </div>
            ) : (
                <div className={`flex flex-col  w-80   bg-white`}>
                    <div
                        onClick={() => setAbrirChat(false)}
                        className="flex justify-end mt-2"
                    >
                        <p className="px-2 py-1 text-gray-400 border border-gray-400 rounded-full cursor-pointer hover:scale-105 ">
                            <svg
                                className="w-4 h-4 text-black"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <line x1="18" y1="6" x2="6" y2="18" />{" "}
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </p>
                    </div>
                    <p className="mb-2 text-lg text-center ">Iniciar chat</p>
                    <hr></hr>
                    <div className="flex flex-col gap-2 px-4 mx-auto mt-2 w-80">
                        <label className="mx-auto">
                            Nombre <span className="text-red-500 ">*</span>
                        </label>
                        <input
                            type={"text"}
                            className="p-3 border border-gray-300 rounded-lg "
                            name="comentarios"
                            placeholder="Jose Perez"
                            value={mensaje?.cliente}
                            onChange={(e) =>
                                setMensaje({
                                    ...mensaje,
                                    cliente: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col gap-2 px-4 mx-auto w-80">
                        <label className="mx-auto">
                            Correo <span className="text-red-500">*</span>
                        </label>
                        <input
                            type={"text"}
                            className="p-3 border border-gray-300 rounded-lg "
                            placeholder="joseperez@gmail.com"
                            value={mensaje?.correo}
                            onChange={(e) =>
                                setMensaje({
                                    ...mensaje,
                                    correo: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col gap-2 px-4 mx-auto mb-3 w-80">
                        <label className="mx-auto">
                            Titulo del chat{" "}
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type={"text"}
                            className="p-3 border border-gray-300 rounded-lg "
                            name="comentarios"
                            placeholder="alquiler de casa por semana santa"
                            value={mensaje?.titulo}
                            onChange={(e) =>
                                setMensaje({
                                    ...mensaje,
                                    titulo: e.target.value,
                                })
                            }
                        />
                    </div>
                    <button
                        onClick={() => iniciarChat()}
                        disabled={cargando}
                        className="px-3 py-2 mx-auto mb-2 text-white bg-gray-400 rounded-lg w-28 hover:scale-105 disabled:cursor-not-allowed"
                    >
                        {cargando ? "Cargando..." : "Empezar"}
                    </button>
                </div>
            )}
        </div>
    );
};
