import { useState } from "react";
import { NavbarsAvatar } from "../../components/NavbarsAvatar";
import { useAppSelector } from "../../hooks/hooks";
import { Notificaiones } from "../../designs/modalEdit/Notificaiones";
import { ElegirMoneda } from "../../components/ElegirMoneda";

interface Props {
    className: string;
}

const BurguerMenuCorredor = ({ className }: Props) => {
    const [burguerMenuVisible, setBurguerMenuVisible] =
        useState<boolean>(false);
    const corredor = useAppSelector((state) => state.corredor);

    return (
        <div className="relative">
            <div
                className={`${className} flex justify-between p-3 items-center bg-[#1C4CBB] shadow-md px-5`}
            >
                <div className="flex items-center gap-2">
                    <span className=" font-medium text-white uppercase text-xs flex">
                        {corredor.nombre} {corredor.apellido}
                    </span>
                </div>

                <div className="flex items-center justify-between gap-2">
                    <Notificaiones />

                    <ElegirMoneda />
                    <NavbarsAvatar />
                </div>
            </div>
        </div>
    );
};

export default BurguerMenuCorredor;
