import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import { number } from "yargs";
import ToggleButton from "../../../components/helpers/ToggleButton";

interface Props {
    idNota: number;
    nota: string;
    asunto: string;
    setModalVisibleEditarNota: (modalVisibleEditarNota: boolean) => void;
    modalVisibleEditarNota: boolean;
}

export const ModalEditarNota = ({
    idNota,
    nota,
    asunto,
    setModalVisibleEditarNota,
    modalVisibleEditarNota,
}: Props) => {
    const { id: idUsuario } = useAppSelector((state) => state.usuario_logeado);
    const [toggleButton, setToggleButton] = useState(false);

    const { id } = useParams();
    const [input, setInput] = useState({
        asunto: asunto,
        nota: nota,
    });

    const [saving, setSaving] = useState<boolean>(false);

    const handleSubmit = async () => {
        if (input.asunto === "" || input.nota === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: "Debes agregar un asunto y una nota",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }

        const json = await fetchAxiosToken({
            url: `/crm_notas_soporte/updateNota/${idNota}`,
            method: "put",
            body: {
                asunto: input.asunto,
                nota: input.nota,
            },
        });
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            // position: "top-end",
            icon: "success",
            title: "Nota actualizada con exito",

            showConfirmButton: true,
            // timer: 2000,
        });
        setModalVisibleEditarNota(false);
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleEditarNota}
            setModalVisible={setModalVisibleEditarNota}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-base font-medium">Editar Nota</h2>
                <hr className="w-full bg-gray-300" />
                <div className="flex justify-end w-10/12 mt-3">
                    <p>
                        <span className="text-[#053465] font-medium mr-2">
                            Editar
                        </span>
                    </p>
                    <ToggleButton
                        isToggled={false}
                        onChangeToggle={(e) => setToggleButton(e)}
                    />
                </div>

                <div>
                    <div className="w-full my-1 text-left">
                        <header className="mb-3 pl-1 text-[#3C3C3C]  text-base">
                            Asunto:
                        </header>
                    </div>
                    <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                        <input
                            onChange={(e) =>
                                setInput({
                                    ...input,
                                    asunto: e.target.value,
                                })
                            }
                            type="text"
                            value={input.asunto}
                            placeholder="Asunto"
                            disabled={!toggleButton}
                            className="w-full my-3 bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400 disabled:cursor-not-allowed"
                        />
                    </div>
                    <div className="w-full my-1 text-left">
                        <header className="mb-3 pl-1 text-[#3C3C3C]  text-base">
                            Nota:
                        </header>
                    </div>
                    <div className="w-full px-2  rounded-lg border border-[#E9E9E9]  ">
                        <textarea
                            onChange={(e) =>
                                setInput({
                                    ...input,
                                    nota: e.target.value,
                                })
                            }
                            value={input.nota}
                            disabled={!toggleButton}
                            placeholder="Nota"
                            className="w-full my-1 min-h-[80px] bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400 disabled:cursor-not-allowed"
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-4 mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            disabled={!toggleButton}
                            onClick={() => handleSubmit()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 disabled:cursor-not-allowed disabled:from-slate-400 disabled:to-slate-300">
                            Actualiza
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
