import React from "react";
import { useNavigate } from "react-router";

interface Props {
    className?: string;
    arrowColor?: string;
    textColor?: string;
    url?: string | null;
}

export const ButtonRegresar = ({
    className,
    arrowColor,
    textColor,
    url = null,
}: Props) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        if (!url) {
            navigate(-1);
        } else {
            navigate(`${url}`);
        }
    };

    return (
        <div
            style={{ color: textColor }}
            className={`${className} text-black flex w-max 2xl:py-5 py-1 items-center text-lg cursor-pointer hover:underline hover:scale-105`}
            onClick={() => handleGoBack()}
        >
            <svg
                style={{ color: arrowColor }}
                className="w-5 h-5 mt-0.5 text-black"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <polyline points="15 18 9 12 15 6" />
            </svg>
            <span className="mx-2">Regresar</span>
        </div>
    );
};
