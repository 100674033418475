import { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { ModalEditLocation } from "../modalEdit/ModalEditLocation";
import { useAppSelector } from "../../hooks/hooks";

interface Coords {
    toggleButton: boolean;
}

const Mapa = ({ toggleButton }: Coords) => {
    const { nombre, latitud, longitud, ciudad, departamento, pais } =
        useAppSelector((state) => state.landing_house.house);
    const [modalVisibleLocation, setModalVisibleLocation] =
        useState<boolean>(false);
    const [preCoordinates, setPreCoordinates] = useState({
        lat: Number(latitud),
        lng: Number(longitud),
        zoom: 15,
    });

    useEffect(() => {
        if (latitud && longitud) {
            setPreCoordinates({
                lat: Number(latitud),
                lng: Number(longitud),
                zoom: 15,
            });
        }
    }, [latitud, longitud]);

    return (
        <div>
            {modalVisibleLocation && (
                <ModalEditLocation
                    modalVisibleLocation={modalVisibleLocation}
                    setModalVisibleLocation={setModalVisibleLocation}
                />
            )}
            <div className="relative p-1 ">
                <div className="flex items-center justify-between mx-2 mb-2">
                    <div className="flex items-center mx-auto md:mx-0">
                        <p className="text-[#029BFC] font-bold text-lg lg:text-2xl 2xl:text-3xl">
                            Mapa de Ubicación
                        </p>
                        {toggleButton && (
                            <div
                                onClick={() => setModalVisibleLocation(true)}
                                className="h-[2.5rem]">
                                <div className="flex justify-start ">
                                    <div
                                        title="editar direccion"
                                        className="flex flex-col items-center px-2 py-1 ml-6 rounded-lg cursor-pointer -mt-7 opacity-90 bg-slate-200 hover:opacity-100">
                                        <svg
                                            className="w-12 h-12 text-[#1C4CBB]"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            {" "}
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                            />{" "}
                                            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                            <line
                                                x1="13.5"
                                                y1="6.5"
                                                x2="17.5"
                                                y2="10.5"
                                            />
                                        </svg>
                                        <div className="text-xs text-[#1C4CBB] text-center">
                                            Editar Mapa
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <p className="hidden md:flex md:text-xl xl:text-2xl 2xl:text-3xl font-bold  w-fit text-[#1c4cbb] capitalize">
                        {ciudad}, {departamento}, {pais}
                    </p>
                </div>
                <div className="relative p-1 2xl:mb-6 h-80 lg:h-[400px] 2xl:h-[580px] mx-auto w-full mt-3">
                    <LoadScript
                        googleMapsApiKey={
                            process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""
                        }>
                        <GoogleMap
                            mapContainerStyle={{
                                height: "100%",
                                width: "100%",
                                borderRadius: "10px",
                            }}
                            center={preCoordinates}
                            zoom={preCoordinates.zoom}
                            // onClick={(e) =>
                            //     setPreCoordinates({
                            //         ...preCoordinates,
                            //         lat: e.latLng.lat(),
                            //         lng: e.latLng.lng(),
                            //     })
                            // }
                        >
                            <Marker
                                position={{
                                    lat: Number(latitud),
                                    lng: Number(longitud),
                                }}
                                // label={nombre}
                            />
                        </GoogleMap>
                    </LoadScript>
                </div>

                <p className="mx-auto flex md:hidden md:text-xl xl:text-2xl 2xl:text-3xl font-bold  w-fit text-[#1c4cbb] uppercase">
                    {ciudad}, {departamento}, {pais}
                </p>
            </div>
        </div>
    );
};

export default Mapa;
