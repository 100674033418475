import React, { useState, useEffect } from "react";
import Spinner from "../../../components/helpers/Spinner";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import { NavbarAdministrativa } from "../NavbarAdministrativa";
import { NavbarAdministrativaMobile } from "../NavbarAdministrativaMobile";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import ModalEditUser from "./ModalEditrUser";
import ModalCreateUser from "./ModalCreateUsers";
import DinamicSearchBar from "../../../components/DinamicSearchBar";

interface allUsers {
    loading: boolean;
    total: number;
    users: user[];
}

interface user {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    celular: string;
    avatar: string;
    password: string;
    tipo_usuario: number;
    habilitado: string;
    grupo_trabajo: string | null;
    fecha_registro: string;
}

export interface getUsers {
    pagina?: number;
    habilitado?: boolean;
    query?: string;
}

interface editUser {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
}

export const Users = () => {
    const { headers } = useHeaders();
    const [users, setUsers] = useState<allUsers>({
        loading: true,
        total: 0,
        users: [],
    });
    const [enabled, setenabled] = useState<boolean>(true);
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [modalVisible, setModalVisible] = useState(false);
    const [refreshUsers, setRefreshUsers] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [userSelected, setUserSelected] = useState<editUser | null>(null);
    const [modalEnabled, setModalEnabled] = useState<boolean>(false);

    const getUsers = async ({ habilitado, pagina, query }: getUsers) => {
        setUsers({
            loading: true,
            total: 0,
            users: [],
        });

        try {
            const data = await fetchAxiosToken({
                url: `/administrador/getAll/${pagina}/${
                    habilitado ? "Si" : "No"
                }?query=${query}`,
            });

            setUsers({
                loading: false,
                total: data.count,
                users: data.rows,
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getUsers({ pagina: pageNumber, habilitado: enabled, query: "" });
    }, [refreshUsers, enabled]);

    const handleEdit = async (user: user) => {
        setUserSelected(user);
        setModalEditVisible(true);
    };

    const handleDelete = (user_id: number) => {
        try {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: `¿Seguro que quieres eliminar el usuario?`,
                showCancelButton: true,

                cancelButtonColor: "#d33",
                icon: "warning",
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const { data } = await fetchAxiosToken({
                        url: `/administrador/delete/${user_id}/No`,
                        method: "delete",
                    });
                    setRefreshUsers((prev) => !prev);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleEnable = async (user_id: number) => {
        try {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: `¿Seguro que quieres habilitar el usuario?`,
                showCancelButton: true,

                cancelButtonColor: "#d33",
                icon: "warning",
                confirmButtonText: "Habilitar",
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const { data } = await fetchAxiosToken({
                        url: `/administrador/delete/${user_id}/Si`,
                        method: "delete",
                    });
                    setRefreshUsers((prev) => !prev);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NavbarAdministrativa titulo="Usuarios">
            <ModalCreateUser
                setRefreshUsers={setRefreshUsers}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
            />
            <ModalEditUser
                setRefreshUsers={setRefreshUsers}
                userSelected={userSelected}
                modalVisible={modalEditVisible}
                setModalVisible={setModalEditVisible}
            />
            <div className="w-full">
                <div className="flex items-baseline justify-end w-full px-6 mx-auto ">
                    {/* <h1 className="text-4xl py-10 text-[#3C3C3C]">
                        Usuarios administrativos
                    </h1> */}
                    <div className="mt-4 md:ml-10">
                        <button
                            onClick={() => setModalVisible(true)}
                            className="flex items-center px-3 py-2 mx-auto text-lg border rounded-lg text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:text-white">
                            <svg
                                className="w-5 h-5 text-blue-blueFrom group-hover:text-white"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                <line x1="5" y1="12" x2="19" y2="12" />
                            </svg>
                            <span className="items-center hidden text-base sm:block ">
                                Nuevo usuario
                            </span>
                        </button>
                    </div>
                </div>
                <div className="flex items-center pr-6 mx-auto ">
                    <DinamicSearchBar
                        enabled={enabled}
                        callbackFunction={getUsers}
                    />
                    <div
                        onMouseOut={() => setModalEnabled(false)}
                        onMouseOver={() => setModalEnabled(true)}
                        className="relative">
                        <button className="w-8 h-8 xs:w-[51px] xs:h-[51px] flex items-center justify-center px-1 py-1  text-blue-blueFrom border-2 border-blue-500 rounded-lg group hover:bg-blue-blueFrom hover:text-white">
                            <svg
                                className="w-6 h-6 text-blue-blueFrom xs:w-8 xs:h-8 group-hover:text-white"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                />
                            </svg>
                        </button>
                        <div
                            className={
                                modalEnabled
                                    ? "absolute top-8 xs:top-[50px] w-36 z-10 pt-2 right-0 "
                                    : "hidden"
                            }>
                            <div
                                className={
                                    modalEnabled
                                        ? "block bg-white top-16 md723:top-20 py-2 rounded-lg shadow-md"
                                        : "hidden"
                                }>
                                <div
                                    onClick={() => (
                                        setModalEnabled(false),
                                        setenabled(!enabled)
                                    )}
                                    className=" w-32 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start pl-4 ">
                                    {!enabled
                                        ? `Habilitados`
                                        : `Deshabilitadas`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="flex justify-center items-center lg:py-[10px] ">
                    <div className="w-full px-11">
                        <div className="flex flex-wrap -mx-4">
                            <div className="w-full ">
                                <div className="flex flex-col">
                                    {!users.loading ? (
                                        <div className="w-full">
                                            <table className="relative table mt-14">
                                                <thead>
                                                    <tr className="text-start">
                                                        <th className="w-full text-start lg:w-4/12">
                                                            Nombre
                                                        </th>
                                                        <th className="hidden w-4/12 lg:table-cell text-start">
                                                            Correo
                                                        </th>
                                                        <th className="hidden w-full lg:table-cell text-start">
                                                            Celular
                                                        </th>
                                                        <th
                                                            colSpan={2}
                                                            className="w-full text-end lg:text-start">
                                                            Acciones
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {users.users.map((user) => (
                                                        <tr
                                                            key={user.id}
                                                            className="border-t cursor-pointer hover:bg-gray-300"
                                                            onClick={() =>
                                                                handleEdit(user)
                                                            }>
                                                            <td>
                                                                <div className="flex items-center py-3 ">
                                                                    {`${user.nombre} ${user.apellido}`}
                                                                </div>
                                                            </td>
                                                            <td className="hidden lg:table-cell">
                                                                <div className="items-center cursor-pointer">
                                                                    {
                                                                        user.correo
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="hidden lg:table-cell">
                                                                <div className="items-center cursor-pointer">
                                                                    {
                                                                        user.celular
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {enabled ? (
                                                                    <div className="flex items-center justify-start w-full cursor-pointer lg:justify-end">
                                                                        <button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                handleEdit(
                                                                                    user
                                                                                );
                                                                            }}
                                                                            title="Editar"
                                                                            className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:white">
                                                                            <svg
                                                                                className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth="2"
                                                                                stroke="currentColor"
                                                                                fill="none"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round">
                                                                                <path
                                                                                    stroke="none"
                                                                                    d="M0 0h24v24H0z"
                                                                                />
                                                                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                                <line
                                                                                    x1="16"
                                                                                    y1="5"
                                                                                    x2="19"
                                                                                    y2="8"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                        <button
                                                                            className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:text-white"
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                handleDelete(
                                                                                    user.id
                                                                                );
                                                                            }}
                                                                            title="Eliminar">
                                                                            <svg
                                                                                className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth="2"
                                                                                stroke="currentColor"
                                                                                fill="none"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round">
                                                                                {" "}
                                                                                <path
                                                                                    stroke="none"
                                                                                    d="M0 0h24v24H0z"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="4"
                                                                                    y1="7"
                                                                                    x2="20"
                                                                                    y2="7"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="10"
                                                                                    y1="11"
                                                                                    x2="10"
                                                                                    y2="17"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="14"
                                                                                    y1="11"
                                                                                    x2="14"
                                                                                    y2="17"
                                                                                />{" "}
                                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                handleEnable(
                                                                                    user.id
                                                                                );
                                                                            }}
                                                                            title="Habilitar"
                                                                            className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:white">
                                                                            <svg
                                                                                className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round">
                                                                                {" "}
                                                                                <polyline points="17 1 21 5 17 9" />{" "}
                                                                                <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                <polyline points="7 23 3 19 7 15" />{" "}
                                                                                <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <Spinner />
                                    )}
                                </div>

                                {/* <div className="container flex items-center justify-between p-1 mx-auto">
                                <Paginado
                                    elementsPerPage={ATTRIBUTES_PER_PAGE}
                                    cantTotalElements={attributes.total}
                                    pagination={pagination}
                                />
                            </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <NavbarAdministrativaMobile />
        </NavbarAdministrativa>
    );
};
