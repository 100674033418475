import React, { useState, useEffect } from "react";
import Spinner from "../../../components/helpers/Spinner";
import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import { NavbarAdministrativa } from "../NavbarAdministrativa";
import { NavbarAdministrativaMobile } from "../NavbarAdministrativaMobile";
import { Paginado } from "../../../components/Paginado";
import SearchBar from "./SearchBar";
import { useNavigate, useParams } from "react-router-dom";
// import ModalUsuariosCasa from "./ModalUsuariosCasa";

interface getComfort {
    pagina: number;
    habilitado: boolean;
    query: string;
}

export interface Pedidos {
    id: number;
    id_cliente: number;
    id_casa: number;
    costo_alquiler: string;
    descuento_estadia: string;
    descuento_cupon: string;
    costo_servicios_adicionales: string;
    costo_total: string;
    pagado: string;
    saldo: string;
    habilitado: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    fecha_registro: string;
    adultos: string;
    ninos: string;
    casa: Casa;
    Cliente: Cliente;
}

export interface Casa {
    id: number;
    nombre: string;
    banner: string;
    Propietario: Propietario;
}

export interface Propietario {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

export interface Cliente {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

export const PedidosDashboard = () => {
    const { headers } = useHeaders();
    const { id, nombre } = useParams();
    const [comforts, setComforts] = useState<Pedidos[]>([]);
    const [comfortsTotal, setComfortsTotal] = useState<number>(0);
    const [enabled, setenabled] = useState<boolean>(true);
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [create, setcreate] = useState<boolean>(false);
    const [modalEnabled, setModalEnabled] = useState<boolean>(false);
    // const [comfortEdit, setComfortEdit] = useState<UsuariosCasa>();
    const [cargando, setCargando] = useState<boolean>(false);
    const COMFORTS_PER_PAGE = 20;
    const [modalUsuarioVisible, setModalUsuarioVisible] =
        useState<boolean>(false);
    const navigate = useNavigate();

    const getPedidos = async ({ pagina, habilitado, query }: getComfort) => {
        try {
            const { data } = await axios.get(
                `${
                    process.env.REACT_APP_PROXY
                }/pedidos/getAllByAdmin/${pagina}/${
                    habilitado ? "Si" : "No"
                }/${id}?cliente=${query}`,
                { headers }
            );
            console.log(data);
            setComforts(data.rows);
            setComfortsTotal(data.count);
            setCargando(true);
        } catch (error) {
            console.log(error);
        }
    };

    const handleEnable = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará la comodidad a habilitados!",
            icon: "warning",
            showCancelButton: true,
    
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(
                        `${process.env.REACT_APP_PROXY}/comodidad/delete/${id}/Si`,
                        { headers }
                    );
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Comodidad restaurada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getPedidos({
                        pagina: pageNumber,
                        habilitado: enabled,
                        query: "",
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleDelete = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará la comodidad a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
         
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(
                        `${process.env.REACT_APP_PROXY}/comodidad/delete/${id}/No`,
                        { headers }
                    );
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Comodidad elimidada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getPedidos({
                        pagina: pageNumber,
                        habilitado: enabled,
                        query: "",
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    // const handleEdit = async (subdomain: string) => {
    //     try {
    //         const { data } = await axios.get(
    //             `${process.env.REACT_APP_PROXY}/usuarios/getUsuariosByCasa/${subdomain}`,
    //             { headers }
    //         );
    //             console.log(data)
    //         setComfortEdit(data);
    //         setModalUsuarioVisible(true)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const pagination = (pageNumber: number) => {
        setpageNumber(pageNumber);
        getPedidos({ pagina: pageNumber, habilitado: enabled, query: "" });
    };

    useEffect(() => {
        getPedidos({ pagina: pageNumber, habilitado: enabled, query: "" });
    }, [enabled, create, id]);

    const botonVolver = () => {
        window.history.back();
    };

    return (
        <div>
            <div className="absolute z-[999] flex items-center justify-center w-full text-center">
                {/* {modalUsuarioVisible && comfortEdit!== undefined   && 
                    <ModalUsuariosCasa
                    modalVisible={modalUsuarioVisible}
                        setModalVisible={setModalUsuarioVisible}
                        usuariosCasa={comfortEdit}
                        setUsuariosCasa={setComfortEdit}
                    />
                } */}
            </div>
            <NavbarAdministrativa titulo="Pedidos">
                <div className="relative w-full">
                    <div
                        className={
                            "flex items-center text-lg cursor-pointer pt-1 px-2 absolute top-1 left-1 "
                        }
                        onClick={() => {
                            botonVolver();
                        }}>
                        <svg
                            className="w-6 h-6 -ml-2 text-black"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <polyline points="15 18 9 12 15 6" />
                        </svg>
                        <span className="mx-1">Regresar</span>
                    </div>
                    <div className="flex items-center justify-between w-full px-6 mx-auto ">
                        <h1 className="text-4xl  py-14 text-center text-[#3C3C3C]">
                            Pedidos de {nombre}
                        </h1>
                        {/* <div
                            onClick={() => setcreate(true)}
                            className="mt-4 md:ml-10">
                            <button className="flex items-center px-3 py-2 mx-auto text-lg border rounded-lg text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:text-white">
                                <svg
                                    className="w-5 h-5 text-blue-blueFrom group-hover:text-white"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                                <span className="items-center hidden text-base sm:block ">
                                    Nueva casa
                                </span>
                            </button>
                        </div> */}
                    </div>
                    <div className="flex items-center justify-end pr-6 mx-auto sm:px-6">
                        <SearchBar getComforts={getPedidos} enabled={enabled} />
                        <div
                            onMouseOut={() => setModalEnabled(false)}
                            onMouseOver={() => setModalEnabled(true)}
                            className="relative">
                            <button className="w-8 h-8 xs:w-[51px] xs:h-[51px] flex items-center justify-center px-1 py-1  text-blue-blueFrom border-2 border-blue-500 rounded-lg group hover:bg-blue-blueFrom hover:text-white">
                                <svg
                                    className="w-6 h-6 text-blue-blueFrom xs:w-8 xs:h-8 group-hover:text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                    />
                                </svg>
                            </button>
                            <div
                                className={
                                    modalEnabled
                                        ? "absolute top-8 xs:top-[50px]  w-36 z-10  pt-2 right-0 "
                                        : "hidden"
                                }>
                                <div
                                    className={
                                        modalEnabled
                                            ? "block bg-white  top-16 md723:top-20 py-2 rounded-lg shadow-md"
                                            : "hidden"
                                    }>
                                    <div
                                        onClick={() => (
                                            setModalEnabled(false),
                                            setenabled(!enabled)
                                        )}
                                        className=" w-32 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start pl-4 ">
                                        {!enabled
                                            ? `Habilitados`
                                            : `Deshabilitadas`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="flex justify-center items-center lg:py-[10px] ">
                        <div className="w-full px-11">
                            <div className="flex flex-wrap -mx-4">
                                <div className="w-full ">
                                    <div className="flex flex-col">
                                        {cargando ? (
                                            <div className="w-full ">
                                                <table className="w-full overflow-x-auto mt-14">
                                                    <thead className="text-left">
                                                        {comforts.length ? (
                                                            <tr>
                                                                <th className="w-full min-w-[10rem] md:w-4/12 ">
                                                                    Nombre
                                                                    cliente
                                                                </th>
                                                                <th className="w-full min-w-[10rem] md:w-4/12 hidden lg:table-cell">
                                                                    Inicio
                                                                </th>
                                                                <th className="w-full min-w-[10rem] md:w-4/12 hidden lg:table-cell">
                                                                    Final
                                                                </th>
                                                                <th className="w-full min-w-[10rem] md:w-4/12 hidden sm:table-cell">
                                                                    Costo total
                                                                </th>

                                                                <th className="w-1/12 text-right min-w-[8rem] md:table-cell pr-10">
                                                                    Acciones
                                                                </th>
                                                            </tr>
                                                        ) : (
                                                            <p className="text-xl font-medium text-center">
                                                                Aun no tiene
                                                                pedidos
                                                            </p>
                                                        )}
                                                    </thead>
                                                    <tbody className="">
                                                        {comforts?.map(
                                                            (comforts, i) => (
                                                                <tr
                                                                    key={
                                                                        comforts.id
                                                                    }
                                                                    className="w-full border-t ">
                                                                    <td>
                                                                        <div
                                                                            className="flex items-center py-3 cursor-pointer"
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `/dashboard-admin/pedido-detalle/${comforts.id}`
                                                                                )
                                                                            }>
                                                                            {/* <img
                                                                                src={
                                                                                    `${process.env.REACT_APP_PROXY}/casa/getImage/` +
                                                                                    comforts?.banner
                                                                                }
                                                                                alt="icono"
                                                                                className="object-cover w-10 h-10 rounded"
                                                                            /> */}
                                                                            <p className="text-sm text-gray-400">{`# ${
                                                                                pageNumber ===
                                                                                1
                                                                                    ? i +
                                                                                      1
                                                                                    : pageNumber *
                                                                                          25 -
                                                                                      25 +
                                                                                      i +
                                                                                      1
                                                                            }`}</p>
                                                                            <h1 className="mx-3 text-[#3C3C3C] text-base">
                                                                                {
                                                                                    comforts
                                                                                        ?.Cliente
                                                                                        .nombre
                                                                                }{" "}
                                                                                {
                                                                                    comforts
                                                                                        ?.Cliente
                                                                                        .apellido
                                                                                }
                                                                            </h1>
                                                                        </div>
                                                                    </td>
                                                                    <td className="hidden lg:table-cell">
                                                                        <div
                                                                            className="flex items-center py-3 cursor-pointer"
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `/dashboard-admin/pedido-detalle/${comforts.id}`
                                                                                )
                                                                            }>
                                                                            <h1 className=" text-[#3C3C3C] text-base">
                                                                                {
                                                                                    comforts?.fecha_inicio
                                                                                }
                                                                            </h1>
                                                                        </div>
                                                                    </td>
                                                                    <td className="hidden lg:table-cell">
                                                                        <div
                                                                            className="flex items-center py-3 cursor-pointer"
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `/dashboard-admin/pedido-detalle/${comforts.id}`
                                                                                )
                                                                            }>
                                                                            <h1 className="text-[#3C3C3C] text-base">
                                                                                {
                                                                                    comforts?.fecha_final
                                                                                }
                                                                            </h1>
                                                                        </div>
                                                                    </td>
                                                                    <td className="hidden sm:table-cell">
                                                                        <div
                                                                            className="flex items-center py-3 cursor-pointer"
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `/dashboard-admin/pedido-detalle/${comforts.id}`
                                                                                )
                                                                            }>
                                                                            <h1 className="text-[#3C3C3C] text-base">
                                                                                {
                                                                                    comforts?.costo_total
                                                                                }{" "}
                                                                                {
                                                                                    comforts.tipo_moneda
                                                                                }
                                                                            </h1>
                                                                        </div>
                                                                    </td>

                                                                    <td
                                                                        className={
                                                                            enabled
                                                                                ? " md:flex justify-center py-5 px-2"
                                                                                : " md:flex  justify-center pl-7 md:justify-end py-5 md:pr-10 "
                                                                        }>
                                                                        {enabled ? (
                                                                            <div className="flex items-center justify-center">
                                                                                <div
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded cursor-pointer text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:text-white"
                                                                                    onClick={() =>
                                                                                        navigate(
                                                                                            `/dashboard-admin/pedido-detalle/${comforts.id}`
                                                                                        )
                                                                                    }
                                                                                    title="Detalle">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="2"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />{" "}
                                                                                        <polyline points="14 2 14 8 20 8" />{" "}
                                                                                        <line
                                                                                            x1="16"
                                                                                            y1="13"
                                                                                            x2="8"
                                                                                            y2="13"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="16"
                                                                                            y1="17"
                                                                                            x2="8"
                                                                                            y2="17"
                                                                                        />{" "}
                                                                                        <polyline points="10 9 9 9 8 9" />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleEnable(
                                                                                            comforts.id
                                                                                        )
                                                                                    }
                                                                                    title="Habilitar"
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:white">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="2"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <polyline points="17 1 21 5 17 9" />{" "}
                                                                                        <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                        <polyline points="7 23 3 19 7 15" />{" "}
                                                                                        <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <Spinner />
                                        )}
                                    </div>

                                    <div className="container flex items-center justify-between p-1 mx-auto">
                                        <Paginado
                                            elementsPerPage={COMFORTS_PER_PAGE}
                                            cantTotalElements={comfortsTotal}
                                            pagination={pagination}
                                            actualPage={pageNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <NavbarAdministrativaMobile />
            </NavbarAdministrativa>
        </div>
    );
};
