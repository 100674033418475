import React, { useState } from "react";
import { ModalAgregarCupon } from "./ModalAgregarCupon";
import useCurrencyPrices from "../../hooks/useCurrencyPrices";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import Swal from "sweetalert2";
import { usePriceToSelectedCurrency } from "../../hooks/usePriceToSelectedCurrency";

interface Props {
    setCupon: (e: number) => void;
    cupon: number;
}
export const ModalCupon = ({ setCupon, cupon }: Props) => {
    const [loading, setLoading] = useState(false);
    const { SELECTED_CURRENCY } = useCurrencyPrices();
    const [cuponIngresado, setCuponIngresado] = useState(false);
    const [agregarCupon, setAgregarCupon] = useState(false);
    const [input, setInput] = useState("");
    const { price_to_currency } = usePriceToSelectedCurrency();
    const vavlidarCupon = async () => {
        console.log(input);

        if (input.trim() === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: `Ingrese un cupon valido`,
                showConfirmButton: true,
                timer: 3000,
            });
            return;
        }
        setLoading(true);

        try {
            const data = await fetchAxiosToken({
                url: `/descuento_cupones/getByCupon/${input}`,
            });
            console.log(data);
            setCupon(Number(price_to_currency(data.descuento)));
            setCuponIngresado(true);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                position: "top-end",
                icon: "success",
                title: `Cupon aplicado con exito`,
                showConfirmButton: true,
                timer: 1500,
            });
        } catch (error: any) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: `${error.response.data.message}`,
                showConfirmButton: true,
                timer: 3000,
            });
        }
        setLoading(false);
    };

    return (
        <div className="w-full ">
            {agregarCupon ? (
                cuponIngresado ? (
                    <div className="flex justify-between w-full text-green-500">
                        <p className="underline">Descuento por cupon: </p>
                        <p className="font-medium">
                            - {cupon} {SELECTED_CURRENCY}
                        </p>
                    </div>
                ) : (
                    <div className="flex items-center justify-between w-full ">
                        <div className="flex items-center">
                            <p className="underline text-[#1C4CBB] font-semibold">
                                Cupon:{" "}
                            </p>
                            <input
                                type="text"
                                className="px-2 py-1 mx-2 mt-1 border rounded-lg "
                                onChange={(e) => setInput(e.target.value)}
                            />
                        </div>
                        <button
                            disabled={loading}
                            onClick={() => vavlidarCupon()}
                            className="px-2 py-1 font-medium text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 disabled:from-gray-300 disabled:to-gray-200">
                            Aplicar
                        </button>
                    </div>
                )
            ) : (
                <div>
                    <p className="flex items-center w-full">
                        <svg
                            className="flex w-4 h-4 mr-2 text-black"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                            />
                        </svg>
                        <span className="text-sm">
                            ¿Tienes un cupón?
                            <span
                                className={` text-blue-blueFrom cursor-pointer mx-1`}
                                onClick={() => setAgregarCupon(true)}>
                                Haz click aquí
                            </span>
                        </span>
                    </p>
                </div>
            )}
        </div>
    );
};
