import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useAppSelector } from "../hooks/hooks";
import jwt_decode from "jwt-decode";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface RegisterForm {
    nombre?: string;
    apellido?: string;
    celular?: string;
    correo?: string;
    password?: string;
    password2?: string;
    dominio?: string;
}

type errorObject = { [key: string]: string };

const INITIAL_STATE = {
    nombre: "",
    apellido: "",
    correo: "",
    password: "",
    password2: "",
    celular: "",
};

interface Props {
    tipo_usuario: number;
}

export const ClientRegister = ({ tipo_usuario }: Props) => {
    const [input, setInput] = useState<RegisterForm>(INITIAL_STATE);
    const [showPass, setShowPass] = useState<boolean>(false);
    const [errors, setErrors] = useState<RegisterForm>({});

    const { house } = useAppSelector((state) => state.landing_house);

    const corredor = useAppSelector((state) => state.corredor);

    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const validateInput = (form: RegisterForm) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        let errores: errorObject = {};

        if (!form.nombre) {
            errores.nombre = "Debe ingresar un nombre válido";
        } else if (!form.apellido) {
            errores.apellido = "Debe ingresar un apellido válido";
        } else if (!regExpEmail.test(form.correo || "")) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.celular) {
            errores.celular = "Debe ingresar un celular válido";
        } else if (!form.password) {
            errores.password = "Debe ingresar una contraseña";
        } else if (form.password !== form.password2) {
            errores.password2 = "Las contraseñas deben coincidir";
        }

        return errores;
    };

    const handleRegister = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            try {
                const body = {
                    ...input,
                    id_casa: tipo_usuario === 3 ? house?.id : 0,
                    id_corredor: tipo_usuario === 6 ? corredor?.id : 0,
                    tipo_usuario: tipo_usuario === 3 ? 5 : 7,
                    tipo_logueo: "Correo",
                };

                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/clientes/signUp`,
                    body
                );
                console.log(data);

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Felicidades te has registrado correctamente!",
                    text: "Haz en el botón debajo para navegar a la pantalla de login",
                    icon: "success",

                    confirmButtonText: "Ir al login",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/login");
                    }
                });
            } catch (error: any) {
                console.log(error.response.data.error);

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    text: error.response.data.error,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
        }
    };

    const googleDatos = async (data: any) => {
        console.log(data);
        const credencialToken: any = jwt_decode(data.credential);
        console.log(credencialToken);

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/clientes/signUp`,
                {
                    tipo_logueo: "Google",
                    nombre: credencialToken.given_name,
                    apellido: credencialToken.family_name,
                    correo: credencialToken.email,
                    password: "",
                    celular: "",
                }
            );

            console.log(data);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Felicidades te has registrado correctamente!",
                text: "Haz en el botón debajo para navegar a la pantalla de login",
                icon: "success",

                confirmButtonText: "Ir al login",
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/login");
                }
            });
        } catch (error: any) {
            console.log(error.response.data.error);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                text: error.response.data.error,
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    };

    const responseFacebook = async (data: any) => {
        console.log(data);
        // let nombre = data.name?.split(" ");

        // if (nombre) {
        //     const json = await axios.post(
        //         `${process.env.REACT_APP_PROXY}${
        //             register.tipo_usuario === "Cliente"
        //                 ? "/cliente/"
        //                 : "/propietario/"
        //         }`,
        //         {
        //             correo: data.email,
        //             nombre: nombre[0],
        //             apellido_paterno: "",
        //             tipo_usuario: register.tipo_usuario === "Vendedor" ? 3 : 5,
        //             tipo_logueo: "Facebook",
        //             password: "",
        //             apellido_materno: "",
        //             tipo_documentacion: "",
        //             dni: "",
        //             direccion: "",
        //             celular: "",
        //         }
        //     );

        //     if (json.data.message === "logeo exitoso") {
        //         dispatch(loginUser(null, null, null, json.data));
        //     } else {
        //         Swal.fire({  confirmButtonColor: "#029BFC",
        // iconColor: "#029BFC",
        //             icon: "error",
        //             title: "Error",
        //             text: `${json.data.title}`,
        //         });

        //         setRegister({
        //             correo: "",
        //             password: "",
        //             password2: "",
        //             nombre: "",
        //             apellido_paterno: "",
        //             apellido_materno: "",
        //             tipo_documentacion: "",
        //             dni: "",
        //             direccion: "",
        //             celular: "",
        //             tipo_usuario: "",
        //             tipo_logueo: "Correo",
        //         });
        //         setMedioDeRegistro("");
        //     }
        // }
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center h-screen p-4 mt-10">
                <div
                    className={
                        "flex items-center text-lg cursor-pointer pt-4 px-4 absolute top-3 left-3 "
                    }
                    onClick={() => {
                        navigate("/login");
                    }}
                >
                    <svg
                        className="w-6 h-6 -ml-2 text-black"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        {" "}
                        <polyline points="15 18 9 12 15 6" />
                    </svg>
                    <span className="mx-1">Regresar</span>
                </div>
                <div className="flex flex-col gap-2 text-center">
                    <h1 className="text-xl font-bold lg:text-3xl">
                        Cuenta nueva
                    </h1>
                    <p className="font-medium">Regístrate con</p>
                    {/* <div>
                        <div className="flex items-center justify-center mx-auto my-3">
            <GoogleLogin
                size="medium"
                shape="pill"
                width="260"
                theme="filled_blue"
             
                onSuccess={googleDatos}
                onError={() => {
                    console.log("Login Failed");
                }}
            />
        </div>
         <div>
             <FacebookLogin
                appId="515412373874363"
                fields="name,email"
         
                 onSuccess={responseFacebook}
                    style={
                        {
                            backgroundColor:"#1A73E8",
                            width:"260px",
                            paddingRight:"20px",
                            paddingLeft:"20px",
                            paddingBottom:"6px",
                            paddingTop:"6px",
                            color:"white",
                            borderRadius:"1rem"
                            
                            
                        }
                    }
             
                    children={"Iniciar sesión con Facebook"}
            />
        </div>
                    </div>

                    <p className="mx-auto my-4 text-xl text-gray-500">o </p> */}
                    <p className="font-medium">
                        Regístrate con tu correo electrónico para poder generar
                        tu reserva
                    </p>
                </div>
                <div className="mt-8 w-full md:w-1/2 md:max-w-[468px]">
                    <label className="text-sm font-medium">Nombre</label>
                    <input
                        value={input.nombre}
                        onChange={handleInputChange}
                        name="nombre"
                        type={"text"}
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        placeholder="nombre"
                    />
                    {errors.nombre && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.nombre}
                        </p>
                    )}
                    <label className="text-sm font-medium">Apellido</label>
                    <input
                        value={input.apellido}
                        onChange={handleInputChange}
                        name="apellido"
                        type={"text"}
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        placeholder="apellido"
                    />
                    {errors.apellido && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.apellido}
                        </p>
                    )}

                    <div className="w-full max-w-xl mt-1">
                        <label className="font-medium">Celular</label>

                        <PhoneInput
                            country={"pe"}
                            value={input.celular}
                            onChange={(celular) =>
                                setInput({
                                    ...input,
                                    celular,
                                })
                            }
                            inputStyle={{
                                width: "100%",
                                padding: "1.5rem 3rem",
                                border: "1px solid #e2e8f0",
                                borderRadius: "10px",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                fontStyle: "italic",
                            }}
                        />
                    </div>

                    {errors.celular && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.celular}
                        </p>
                    )}
                    <label className="text-sm font-medium">
                        Correo electrónico
                    </label>
                    <input
                        value={input.correo}
                        onChange={handleInputChange}
                        name="correo"
                        type={"email"}
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        placeholder="micuenta@gmail.com"
                    />
                    {errors.correo && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.correo}
                        </p>
                    )}
                    <>
                        <label className="flex mt-4 text-sm font-medium">
                            Contraseña
                        </label>
                        <div className="relative flex flex-col justify-center">
                            <input
                                value={input.password}
                                onChange={handleInputChange}
                                name="password"
                                type={showPass ? "text" : "password"}
                                maxLength={20}
                                className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                                placeholder="contraseña"
                            />
                            <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                                {showPass ? (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                            />
                                        </svg>
                                    </>
                                )}
                            </span>
                            {errors.password && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.password}
                                </p>
                            )}
                        </div>
                    </>

                    <>
                        <label className="flex mt-4 text-sm font-medium">
                            Repetir contraseña
                        </label>
                        <div className="relative flex flex-col justify-center">
                            <input
                                value={input.password2}
                                onChange={handleInputChange}
                                name="password2"
                                type={showPass ? "text" : "password"}
                                maxLength={20}
                                className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                                placeholder="repetir contraseña"
                            />
                            <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                                {showPass ? (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                            />
                                        </svg>
                                    </>
                                )}
                            </span>
                            {errors.password2 && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.password2}
                                </p>
                            )}
                        </div>
                    </>
                </div>
                <div className="flex flex-col gap-4 mt-8">
                    <button
                        onClick={() => handleRegister()}
                        className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105"
                    >
                        Comenzar
                    </button>
                    <span>
                        Si ya te has registrado,{" "}
                        <span
                            onClick={() => navigate("/login")}
                            className="text-blue-600 cursor-pointer hover:underline"
                        >
                            <span className="font-medium">haz click aquí.</span>
                        </span>
                    </span>
                </div>
            </div>
        </>
    );
};
