import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

interface Props {
    onChange: (date: string | undefined) => void;
    selectedDay: string | undefined;
    className?: string;
    placeholderText?: string;
}

export const InputDate = ({
    onChange,
    selectedDay,
    className,
    placeholderText,
}: Props) => {
    registerLocale("es", es);

    const selectedDayToDate = (selectedDay: string | undefined) => {
        if (selectedDay) {
            if (selectedDay === "0000-00-00") return null;
            const final_date = selectedDay.replaceAll("-", "/");
            return new Date(final_date);
        }
        return null;
    };

    const handleChange = (date: Date | null) => {
        const dateTo_YYYY_MM_DD = date?.toLocaleDateString("zh-Hans-CN");
        //ReplaceAll for all the "/" ocurrences
        const replaceSlashs = dateTo_YYYY_MM_DD?.replaceAll("/", "-");

        onChange(replaceSlashs);
    };

    return (
        <DatePicker
            className={`w-full p-3 bg-white border rounded-lg cursor-pointer ${className}`}
            placeholderText={placeholderText || "Seleccionar fecha"}
            locale="es"
            autoComplete="off"
            dateFormat="dd/MM/yyyy"
            selected={selectedDayToDate(selectedDay)}
            onChange={(date) => handleChange(date)}
        />
    );
};
