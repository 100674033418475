import { createSlice } from "@reduxjs/toolkit";
import { infoCasa } from "../../designs/calendario-reserva/typeCasa";

interface InitialState {
    house: infoCasa;
    checking: boolean;
    mostrar: boolean; // Nuevo parámetro para controlar la visibilidad
    currency: string;
}

const INITIAL_STATE = {
    currency: "",
    mostrar: false, // Valor inicial: oculto
};

export const informacion = createSlice({
    name: "landing_house",
    initialState: INITIAL_STATE,
    reducers: {
        mostrar_informacion: (state) => {
            return {
                ...state,
                mostrar: true, // Mostrar información
            };
        },
        ocultar_informacion: (state) => {
            return {
                ...state,
                mostrar: false, // Ocultar información
            };
        },
        currencyPage: (state, action) => {
            return {
                ...state,
                currency: action.payload,
            };
        },
    },
});

// Action creators
export const { ocultar_informacion, mostrar_informacion, currencyPage } =
    informacion.actions;

export default informacion.reducer;
