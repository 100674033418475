import axios from "axios";

interface Props {
    url: string;
    method?: "get" | "post" | "put" | "delete";
    body?: Object;
}

export const fetchAxiosBlueCornerNoToken = async ({ url, method, body }: Props) => {
    const BASE_URL = process.env.REACT_APP_API_BLUECORNER;
  
    const { data } = await axios({
        method: method ? method : "get",
        url: `${BASE_URL}${url}`,
        data: body ? body : "",
    });

    return data;
};
