import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { useAppSelector } from "../../hooks/hooks";
import { set_landing_house } from "../../redux/reducers/landingReducer";
import SpinnerVerifyToken from "../../routes/SpinnerVerifyToken";
import { LandingElegida } from "./LandingElegida";

export interface IsExist {
    is_exist: string;
    tipo_usuario: number;
}

export const HomeSubdominio = () => {
    const { house, checking } = useAppSelector((state) => state.landing_house);

    const [tenantExists, setTenantExists] = useState<IsExist>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const getIfTenantExists = async () => {
        try {
            const data = await fetchAxiosNoToken({
                url: `/casa/isExist/`,
            });

            setTenantExists(data);
        } catch (error) {
            console.log(error);
        }
    };

    const getHouseFromTenant = async () => {
        try {
            if (id) {
                const data = await fetchAxiosNoToken({
                    url: `/casa/getOne/id/${id}`,
                });

                dispatch(set_landing_house(data));
            } else {
                const data = await fetchAxiosNoToken({
                    url: `/casa/getOne/dominio`,
                });

                dispatch(set_landing_house(data));
            }
        } catch (error) {
            console.log(error);
            dispatch(set_landing_house(null));
        }
    };

    useEffect(() => {
        getHouseFromTenant();
    }, []);

    useEffect(() => {
        getIfTenantExists();
    }, []);

    if (checking) {
        return <SpinnerVerifyToken />;
    }
    if (!house?.id && tenantExists?.is_exist === "No") {
        return (
            <div className="flex items-center justify-center h-screen p-4">
                <div className="max-w-md font-medium text-center">
                    No existe un alojamiento asociado al subdominio
                    <button
                        onClick={() => {
                            window.open(
                                `${process.env.REACT_APP_FRONT_ROOT_URL}`,
                                "_self"
                            );
                        }}
                        className="mx-1 text-blue-600 hover:underline">
                        regresar a BLU.
                    </button>
                </div>
            </div>
        );
    }
    if (
        tenantExists?.is_exist === "Si" &&
        (!house?.id || house?.terminado === "No")
    )
        return (
            <div className="flex items-center justify-center h-screen p-4">
                <div className="max-w-md font-medium text-center">
                    Aún no se ha terminado de crear un alojamiento asociado al
                    subdominio, puedes intentar
                    <button
                        onClick={() => {
                            // const url = url.mat
                            window.open(
                                `${process.env.REACT_APP_FRONT_ROOT_URL}`,
                                "_self"
                            );
                        }}
                        className="mx-1 text-blue-600 hover:underline">
                        Regresar a BLU
                    </button>
                    <span>o</span>
                    <button
                        onClick={() => {
                            navigate("/login");
                        }}
                        className="mx-1 text-blue-600 hover:underline">
                        Ir al login
                    </button>
                    <span>y continuar tu onboarding.</span>
                </div>
            </div>
        );
    return (
        <div>
            <LandingElegida />
        </div>
    );
};
