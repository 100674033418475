import React, { ReactNode, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import logo from "../../img/logoPNGalerta.png";
interface Input {
    nombre: string;
    celular: string;
    correo: string;
    costoNoche: string;
    costoNocheMoneda: string;
    diasAlquiladas: string;
    cargado: boolean;
    etiqueta: string;
    apellido: string;
}

interface ModalProps {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    input: Input;
    setInput: (i: Input) => void;
}

export const ModalCalculadora = ({
    modalVisible,
    setModalVisible,
    input,
    setInput,
}: ModalProps) => {
    registerLocale("es", es);

    const [modalPromoCreada, setModalPromoCreada] = useState<boolean>(false);

    const [cupon, setCupon] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);

    const [correos, setCorreos] = useState<string[]>([]);
    const [permiso, setPermiso] = useState(false);
    const [permisoNovedades, setPermisoNovedades] = useState(false);
    useEffect(() => {
        setInput({
            ...input,
            cargado: false,
        });
    }, []);

    const handleSubmit = async () => {
        if (permiso === false) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes los terminos y condiciones",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }
        if (input.celular === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar tu numero de celular",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }
        if (input.celular.length < 7) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar un numero de celular valido",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }
        if (input.nombre === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar tu nombre",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }
        if (input.correo === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar tu correo",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }

        try {
            setLoading(true);
            console.log(input);
            const data = await fetchAxiosNoToken({
                url: `/home/postSimulador`,
                body: input,
                method: "post",
            });

            setInput({
                ...input,
                cargado: true,
            });
            setModalVisible(false);

            setLoading(false);
        } catch (error: any) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: `${error.response.data.error}`,
                icon: "error",
            });
            setLoading(false);
        }
    };

    return (
        <>
            <ResponsiveModalAbsolute
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}>
                <h3 className="text-lg font-bold text-center text-[#1C4CBB]">
                    Completa tus datos
                </h3>
                <div className="flex flex-col max-w-md gap-4 p-4 mx-auto ">
                    <img src={logo} className="w-64 mx-auto h-52" />
                    <div className="flex flex-col items-center justify-center">
                        <div className="w-full">
                            {/* <h2 className="text-base font-medium text-left">nombre</h2> */}
                            <div className="flex flex-col w-full mt-2">
                                <label className="pb-2 pl-3 text-sm font-medium text-gray-600">
                                    Nombre
                                </label>
                                <div className=" w-full  ml-2 pl-[1px]   rounded-lg p-3 bg-transparent border border-[#1C4CBB]  outline-none focus:outline-none placeholder:text-gray-400 flex">
                                    <input
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                nombre: e.target.value,
                                            })
                                        }
                                        autoComplete="off"
                                        className="w-full px-3 rounded-lg outline-none"
                                        placeholder=" Nombre"
                                        value={input.nombre}
                                        name="descuento_propietario"
                                        type="text"></input>
                                </div>
                            </div>
                            <div className="flex flex-col w-full my-6">
                                <label className="pb-2 pl-3 text-sm font-medium text-gray-600">
                                    Correo
                                </label>
                                <div className=" w-full  ml-2 pl-[1px]   rounded-lg p-3 bg-transparent border border-[#1C4CBB]  outline-none focus:outline-none placeholder:text-gray-400 flex">
                                    <input
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                correo: e.target.value,
                                            })
                                        }
                                        autoComplete="off"
                                        className="w-full px-3 rounded-lg outline-none"
                                        placeholder=" Correo"
                                        value={input.correo}
                                        name="descuento_propietario"
                                        type="email"></input>
                                </div>
                            </div>
                            <div className="flex flex-col w-full mt-2">
                                <label className="pb-2 pl-3 text-sm font-medium text-gray-600">
                                    Celular
                                </label>
                                <div className=" w-full  ml-2 pl-[1px]   rounded-lg p-3 bg-transparent border border-[#1C4CBB]  outline-none focus:outline-none placeholder:text-gray-400 flex">
                                    <input
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                celular: e.target.value,
                                            })
                                        }
                                        autoComplete="off"
                                        className="w-full px-3 rounded-lg outline-none"
                                        placeholder=" Celular"
                                        value={input.celular}
                                        name="descuento_propietario"
                                        type="number"></input>
                                </div>
                            </div>
                            <div className="flex justify-center w-full gap-2 mt-6">
                                <div
                                    onClick={() => setPermiso(!permiso)}
                                    className={`border border-[#1C4CBB] w-6 h-6 rounded-md cursor-pointer ${
                                        permiso ? "bg-[#1C4CBB]" : ""
                                    }`}></div>
                                <p>
                                    Acepto los terminos y condiciones de Blustay{" "}
                                    <span className="text-red-500">*</span>
                                </p>
                            </div>
                            <div className="flex justify-center w-full gap-2 mt-6">
                                <div
                                    onClick={() =>
                                        setPermisoNovedades(!permisoNovedades)
                                    }
                                    className={`border border-[#1C4CBB] w-6 h-6 rounded-md cursor-pointer ${
                                        permisoNovedades ? "bg-[#1C4CBB]" : ""
                                    }`}></div>
                                <p>
                                    Acepto recibir otras comunicaciones de
                                    Blustay
                                </p>
                            </div>
                        </div>
                    </div>

                    <button
                        disabled={loading}
                        onClick={() => handleSubmit()}
                        className="p-3 mt-4 font-medium text-white border rounded-lg bg-[#029BFC] border-[#029BFC] active:translate-y-1 disabled:bg-gray-400 disabled:cursor-default">
                        {loading ? "Procesando.." : "Ver mis resultados"}
                    </button>
                </div>
            </ResponsiveModalAbsolute>
        </>
    );
};
