import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchAxiosToken } from '../../helpers/fetchAxiosToken';

interface Props {
  llavePublica: string;
  costo: number;
  tipo_moneda: string;
  tipo_pago: string;
  id_cliente:number
}

export const MercadoPago = ({ llavePublica, costo, tipo_moneda, tipo_pago,id_cliente }: Props) => {
    console.log(id_cliente)
  const [scriptLoaded, setScriptLoaded] = useState(false);
 const {id_reserva} = useParams()
  useEffect(() => {
    const fetchCheckout = async () => {
      const data = await fetchAxiosToken({
        url: `/mercadopago/checkout`,
        method: `post`,
        body: {
          user: {
            nombre: "ludwig",
            apellido: "driet",
            email: "maria@gmail.com"
          },
          pago: costo,
          tipo_moneda: tipo_moneda,
          id_pedido:id_reserva,
          tipo_pago:tipo_pago,
          id_cliente:id_cliente
        },
      });

      if (data.global) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://sdk.mercadopago.com/js/v2';
        script.setAttribute('data-preference-id', data.global);

        script.addEventListener('load', () => {
          const mp = new window.MercadoPago(llavePublica, {
            locale: 'es-PE',
            
          });

          mp.checkout({
            preference: {
              id: data.global
            },
            render: {
              container: '.cho-container',
              label: 'Pagar',
            },
            redirectMode: 'self',
          
          });
        });

        document.body.appendChild(script);
        setScriptLoaded(true);
      }
    }

    fetchCheckout();

    return () => {
      const script = document.querySelector('script[src="https://sdk.mercadopago.com/js/v2"]');
      const container = document.querySelector('.cho-container');
      if (script) {
        script.remove();
      }
      if (container) {
        container.innerHTML = '';
      }
    }
  }, [costo]);

  return (
    <div className="cho-container "></div>
  );
}
