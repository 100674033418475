import React, { useEffect, useState } from "react";
import FormRespuesta from "./FormRespuesta";
import ModalEditSubMenu from "./ModalEditSubMenu";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import Swal from "sweetalert2";

interface respuesta_bot {
    id: number;
    id_submenu_bots: number;
    respuesta: string;
    banner_respuesta: string;
}

interface submenu_bot {
    id: number;
    id_respuestas_bots: number;
    respuesta: string;
    subrespuestas: respuesta_bot;
}

interface submenu_bots {
    loading: boolean;
    submenu_bots: submenu_bot[];
}

interface Props {
    submenu: any;
    getData: () => Promise<void>;
}

interface SelectedFile {
    img: File | null;
}

const FormSubMenu = ({ submenu, getData }: Props) => {
    const [isOpenMensajeInicial, setIsOpenMensajeInicial] =
        useState<boolean>(false);

    const [isModalSubmenu, setIsModalSubMenu] = useState<boolean>(false);

    const [selectedFile, setSelectedFile] = useState<SelectedFile>({
        img: null,
    });

    const [inputRespuesta, setInputRespuesta] = useState<respuesta_bot>({
        id: 0,
        id_submenu_bots: submenu.id,
        respuesta: "",
        banner_respuesta: "",
    });

    const [isExistRespuesta, setIsExistRespuesta] = useState<boolean>(false);

    const [submenuBots, setSubmenuBots] = useState<submenu_bots>({
        loading: false,
        submenu_bots: [],
    });

    const updateData = async () => {
        console.log(submenu?.subrespuestas);
        setSelectedFile({ img: null });
        if (submenu?.subrespuestas?.id) {
            setInputRespuesta({
                id: submenu.subrespuestas.id,
                respuesta: submenu.subrespuestas?.respuesta,
                id_submenu_bots: submenu.subrespuestas.id_submenu_bots,
                banner_respuesta: submenu.subrespuestas.imagen,
            });

            setIsExistRespuesta(true);

            if (submenu.subrespuestas.subrespuestas) {
                setSubmenuBots({
                    loading: false,
                    submenu_bots: submenu.subrespuestas.subrespuestas,
                });
            }
        } else {
            setInputRespuesta({
                id: 0,
                id_submenu_bots: submenu.id,
                respuesta: "",
                banner_respuesta: "",
            });
            setIsExistRespuesta(false);
        }
    };

    useEffect(() => {
        updateData();
    }, [submenu]);

    const hanldeDelete = async () => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quiere eliminar la opción?",
            text: "Esta acción no se puede revertir",
            icon: "warning",
            showCancelButton: true,

            cancelButtonColor: "#d33",
            confirmButtonText: "¡Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await fetchAxiosToken({
                        url: `/respuestas_bot/deleteSubmenu/${submenu.id}`,
                        method: "delete",
                    });

                    if (data.success) {
                        Swal.fire({
                            confirmButtonColor: "#029BFC",
                            iconColor: "#029BFC",
                            // position: "top-end",
                            icon: "success",
                            title: "Opción eliminada",
                            confirmButtonText: "Ok",
                        });
                        getData();
                    } else {
                        Swal.fire({
                            confirmButtonColor: "#029BFC",
                            iconColor: "#029BFC",
                            // position: "top-end",
                            icon: "error",
                            text: `${data.message}`,
                            confirmButtonText: "Ok",
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    return (
        <div
            key={submenu.id}
            className="border-2 border-blue-100  rounded-md p-2  "
        >
            {isModalSubmenu && (
                <ModalEditSubMenu
                    modalVisible={isModalSubmenu}
                    setModalVisible={setIsModalSubMenu}
                    submenu={submenu}
                    getData={getData}
                />
            )}
            <div className="flex justify-between items-center p-3 shadow-md w-full 2xl:text-base text-sm gap-2 bg-white cursor-pointer mx-auto rounded-md">
                <div
                    onClick={() =>
                        setIsOpenMensajeInicial(!isOpenMensajeInicial)
                    }
                    className="flex justify-center items-center"
                >
                    {!isOpenMensajeInicial ? (
                        <svg
                            className="h-8 w-8"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <polyline points="6 9 12 15 18 9" />
                        </svg>
                    ) : (
                        <svg
                            className="h-8 w-8 "
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <polyline points="6 15 12 9 18 15" />
                        </svg>
                    )}{" "}
                    {submenu.respuesta}
                </div>
                <div className="flex justify-center items-center gap-2">
                    <div
                        onClick={() => setIsModalSubMenu(true)}
                        className="w-8 h-8 rounded-md hover:scale-105 flex justify-center items-center cursor-pointer bg-blue-blueFrom text-white"
                    >
                        <svg
                            className="h-5 w-5 text-white "
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                            <line x1="16" y1="5" x2="19" y2="8" />
                        </svg>
                    </div>
                    <div
                        onClick={() => hanldeDelete()}
                        className="w-8 h-8 rounded-md hover:scale-105 flex justify-center items-center cursor-pointer bg-blue-blueFrom text-white"
                    >
                        <svg
                            className="h-5 w-5 text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                        </svg>
                    </div>
                </div>
            </div>

            {isOpenMensajeInicial && (
                <FormRespuesta
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    inputRespuesta={inputRespuesta}
                    setInputRespuesta={setInputRespuesta}
                    isExistRespuesta={isExistRespuesta}
                    getData={getData}
                    submenuBots={submenuBots}
                />
            )}
        </div>
    );
};

export default FormSubMenu;
