import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

import axios from "axios";
import { useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";

import Spinner from "../../components/helpers/Spinner";
import { useAppSelector } from "../../hooks/hooks";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import ToggleButton from "../../components/helpers/ToggleButton";

interface Props {
    casaId: number;
    espacio: Espacios;
    setEspaciosEdit: (s: Espacios | null) => void;
}
export interface Espacios {
    id: number;
    id_casa: number;
    nombre: string;
    orden: number;
    imagenes: EspaciosImagenes[];
}

export interface EspaciosImagenes {
    id: number;
    id_casa: number;
    imagen: string;
    id_espacio_imagen: number;
    orden: number;
}

const ModalEditEspacio = ({ casaId, setEspaciosEdit, espacio }: Props) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [toggleButton, setToggleButton] = useState(false);
    const [espacioTranferir, setEspaciosTransferir] = useState<Espacios | null>(
        null
    );
    const [espaciosId, setEspaciosId] = useState<Espacios | null>(espacio);
    const [espaciosImagenes, setEspaciosImagenes] = useState<
        EspaciosImagenes[]
    >(espacio.imagenes);
    const [tituloEspacio, setTituloEspacio] = useState(espacio?.nombre ?? "");
    const [nombreEspacio, setNombreEspacio] = useState("");
    const [loading, setLoading] = useState(false);
    const [espacios, setEspacios] = useState<Espacios[]>([]);
    const [saving, setSaving] = useState(false);
    const [editarTitulo, setEditarTitulo] = useState(false);
    const [selectedImageIds, setSelectedImageIds] = useState<number[]>([]);

    useEffect(() => {
        cargarEtiquetas();
    }, []);

    const cargarEtiquetas = async () => {
        const data = await fetchAxiosToken({
            url: `/espacios_imagenes/getAll/${casaId}`,
            method: "get",
        });

        console.log("cargarEtiquetas", data);
        setEspacios(data);
    };

    const handleImageCheckboxChange = (imageId: number) => {
        const isSelected = selectedImageIds.includes(imageId);

        setSelectedImageIds((prevIds) =>
            isSelected
                ? prevIds.filter((id) => id !== imageId)
                : [...prevIds, imageId]
        );
    };

    const getOne = async () => {
        const dataGetOne = await axios.get(
            `${process.env.REACT_APP_PROXY}/espacios_imagenes/getOne/${
                espaciosId && espaciosId.id
            }`,
            { headers }
        );
        console.log("dataGetOne", dataGetOne);
        setEspaciosImagenes(dataGetOne.data.imagenes);
        setLoading(false);
    };

    const multiLoadFile = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const form = new FormData();

        setLoading(true);
        form.append("id_casa", `${casaId}`);
        form.append("id_espacio_imagen", `${espaciosId && espaciosId.id}`);
        if (event.target.files) {
            if (event.target.files.length > 0) {
                for (let i = 0; i < event.target.files.length; i++) {
                    form.append("imagenes_casa", event.target.files[i]);
                }
            }
        }

        try {
            const data = await fetchAxiosToken({
                url: `/casa_imagenes/`,
                method: "post",
                body: form,
            });
        } catch (error: any) {
            console.log(error.response);
        }
        getOne();
        setLoading(false);
    };

    const handleDelete = async (id: number) => {
        setLoading(true);

        const json = await axios.delete(
            `${process.env.REACT_APP_PROXY}/casa_imagenes/delete/${id}`,
            {
                headers: headers,
            }
        );

        getOne();
    };
    const handleGuardar = async () => {
        let array_orden: number[] = [];
        espaciosImagenes.forEach((imagen) => {
            array_orden.push(imagen.id);
        });
        console.log(array_orden);

        const data = await fetchAxiosToken({
            url: `/casa_imagenes/updateOrden`,
            method: "put",
            body: { array_imagenes: array_orden },
        });

        console.log(data);
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "Espacio Guardado",
            icon: "success",

            confirmButtonText: "ok!",
        });
        setEspaciosEdit(null);
    };

    const actualizarTitulo = async () => {
        if (tituloEspacio.trim() === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Debes agregar el nombre del espacio",
                icon: "warning",

                confirmButtonText: "Completar!",
            });

            return;
        }
        try {
            const dataGetOne = await axios.put(
                `${process.env.REACT_APP_PROXY}/espacios_imagenes/update/${
                    espaciosId && espaciosId.id
                }`,
                {
                    nombre: tituloEspacio,
                },
                { headers }
            );
            console.log(dataGetOne);
            getOne();
        } catch (error) {
            console.log(error);
        }
    };
    const handleTransferirFotos = async () => {
        if (!espacioTranferir) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Debes agregar el espacio a donde se transferiran",
                icon: "warning",

                confirmButtonText: "Completar!",
            });

            return;
        }
        console.log({
            id_espacio_imagen: espacioTranferir?.id,
            array_imagenes: selectedImageIds,
        });
        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa_imagenes/updateEspacios`,
                {
                    id_espacio_imagen: espacioTranferir?.id,
                    array_imagenes: selectedImageIds,
                },
                { headers }
            );
            console.log(data);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Imagenes transferidas con exito!",
                icon: "success",

                confirmButtonText: "ok!",
            });
            getOne();

            setSelectedImageIds([]);
        } catch (error) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error al transferir imagenes",
                icon: "warning",

                confirmButtonText: "ok!",
            });
        }
    };
    return (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-80">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-lg">
                <div className="relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg p-10 xs:w-auto dark:bg-white md:px-10  h-[calc(100vh-15vh)] overflow-y-auto ">
                    <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                        <h2 className="text-base font-medium">
                            Editar espacio
                        </h2>
                        <hr className="w-full bg-gray-300" />

                        <div className="flex items-start justify-between w-full gap-1 text-sm 2xl:text-base">
                            <div className="flex items-center">
                                <p className="mr-2 font-bold">
                                    {editarTitulo ? (
                                        <input
                                            onChange={(e) =>
                                                setTituloEspacio(e.target.value)
                                            }
                                            value={tituloEspacio}
                                            type="string"
                                            className="w-full pl-1 border rounded-md"
                                        />
                                    ) : (
                                        <h2 className="mx-1 mt-1 font-medium lg:mt-0">
                                            {tituloEspacio}
                                        </h2>
                                    )}{" "}
                                </p>
                                {editarTitulo ? (
                                    <button
                                        onClick={() => (
                                            actualizarTitulo(),
                                            setEditarTitulo((prev) => !prev)
                                        )}
                                        title="Editar precio"
                                        className="flex items-center px-2 py-1.5 border rounded-lg cursor-pointer opacity-80 bg-[#E6E6E6] hover:opacity-100 mx-1"
                                    >
                                        <svg
                                            className="w-3 h-3 text-blue-700"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5 13l4 4L19 7"
                                            />
                                        </svg>
                                    </button>
                                ) : (
                                    <button
                                        onClick={() =>
                                            setEditarTitulo((prev) => !prev)
                                        }
                                        title="Editar precio"
                                        className="flex items-center px-2 py-1.5 border rounded-lg cursor-pointer opacity-80 bg-[#E6E6E6] hover:opacity-100 mr-2"
                                    >
                                        <svg
                                            className="w-3 h-3"
                                            id="Capa_2"
                                            data-name="Capa 2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 100 99.97"
                                        >
                                            <g id="Testimonios">
                                                <g>
                                                    <path d="m.01,95.86c1.03-2.98,1.79-6.04,2.66-9.06,1.65-5.68,3.25-11.36,4.88-17.05.35-1.21.41-1.22,1.3-.33,7.26,7.26,14.53,14.52,21.79,21.79.86.86.85.88-.32,1.21-8.47,2.41-16.93,4.82-25.39,7.23-.28.08-.54.21-.82.31-1.24,0-2.47-.02-3.71.01-.34,0-.41-.06-.4-.4.03-1.23.01-2.47.01-3.71Z" />
                                                    <path d="m36.11,88.77c-.31-.02-.47-.25-.65-.43-7.95-7.95-15.9-15.91-23.87-23.84-.72-.72-.2-1.05.23-1.48,8.48-8.48,16.96-16.96,25.44-25.44,7.95-7.95,15.9-15.9,23.86-23.85q.95-.95,1.88-.02c7.77,7.77,15.53,15.55,23.32,23.29.73.73.64,1.1-.03,1.77-16.45,16.42-32.87,32.86-49.3,49.29-.27.27-.49.61-.89.71Z" />
                                                    <path d="m78.52,0c2.53-.02,4.61.81,6.3,2.48,4.23,4.17,8.46,8.36,12.61,12.61,3.32,3.4,3.45,8.57.29,12.11-1.94,2.18-4.09,4.17-6.13,6.27-.61.63-.95.17-1.34-.22-4.55-4.55-9.1-9.1-13.65-13.65-3.29-3.29-6.56-6.58-9.87-9.84-.63-.62-.66-1.02,0-1.64,1.85-1.78,3.63-3.62,5.46-5.43C73.97.92,76.1-.04,78.52,0Z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                )}
                            </div>
                            <div>
                                <div className="flex items-center">
                                    <p className="mr-2">
                                        {!toggleButton
                                            ? "Transferir fotos de espacio"
                                            : "Seleccionar fotos"}
                                    </p>
                                    <ToggleButton
                                        isToggled={toggleButton}
                                        onChangeToggle={(e) =>
                                            setToggleButton(e)
                                        }
                                    />
                                </div>
                                {toggleButton && espacios !== null && (
                                    <>
                                        <div className="flex items-center gap-1">
                                            <p className="font-semibold">
                                                Espacio:
                                            </p>
                                            <div className=" px-2 rounded-lg border border-[#E9E9E9] select-none mt-2">
                                                <select
                                                    name="tipoVariante"
                                                    className=" my-1 bg-transparent border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C]"
                                                    value={
                                                        espacioTranferir?.id ||
                                                        ""
                                                    }
                                                    onChange={(e) => {
                                                        const selectedId =
                                                            e.target.value;
                                                        const selectedSpace =
                                                            espacios.find(
                                                                (espacio) =>
                                                                    espacio.id ===
                                                                    parseInt(
                                                                        selectedId
                                                                    )
                                                            );

                                                        selectedSpace &&
                                                            setEspaciosTransferir(
                                                                selectedSpace
                                                            );
                                                    }}
                                                >
                                                    {espacios?.map(
                                                        (espacio) => (
                                                            <option
                                                                value={
                                                                    espacio.id
                                                                }
                                                                key={espacio.id}
                                                            >
                                                                {espacio.nombre}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() =>
                                                handleTransferirFotos()
                                            }
                                            disabled={
                                                selectedImageIds.length === 0
                                            }
                                            className={`p-3 px-5 text-white border rounded-lg mx-auto mt-2 ${
                                                selectedImageIds.length === 0
                                                    ? "bg-gray-300 cursor-not-allowed"
                                                    : "bg-[#1C4CBB] hover:scale-105"
                                            }`}
                                        >
                                            Transferir fotos (
                                            {selectedImageIds.length})
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                        {espaciosId?.nombre && !loading && (
                            <div>
                                <div className="mt-4 relative flex justify-center h-44 w-full 2xl:h-52 border border-[#E9E9E9] rounded-lg hover:cursor-pointer">
                                    <div className="absolute">
                                        <div className="flex flex-col w-full mx-auto ">
                                            <div className="relative top-4 ">
                                                <span className="block mt-16 text-base font-semibold text-center ">
                                                    Agrega o arrastra una foto
                                                    aquí
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        accept="image/*"
                                        multiple
                                        type="file"
                                        className="w-full h-full opacity-0 cursor-pointer"
                                        onChange={multiLoadFile}
                                    />
                                </div>

                                <ReactSortable
                                    handle=".handle"
                                    className="flex flex-wrap gap-2 lg:grid lg:grid-cols-2 "
                                    group="groupName"
                                    animation={200}
                                    delay={2}
                                    list={espaciosImagenes}
                                    setList={setEspaciosImagenes}
                                >
                                    {espaciosImagenes?.map((image, index) => (
                                        <div
                                            key={index}
                                            className={`relative w-full mt-4 border rounded-lg handle cursor-move`}
                                        >
                                            {toggleButton &&
                                                espacios !== null && (
                                                    <input
                                                        type="checkbox"
                                                        onChange={() =>
                                                            handleImageCheckboxChange(
                                                                image.id
                                                            )
                                                        }
                                                        checked={selectedImageIds.includes(
                                                            image.id
                                                        )}
                                                        className="absolute z-10 w-5 h-5 cursor-pointer top-2 left-2"
                                                    />
                                                )}
                                            <img
                                                src={`${process.env.REACT_APP_PROXY}/casa/getImage/${image.imagen}`}
                                                className={` object-cover w-full mx-auto rounded-lg 2xl:h-52 h-48 `}
                                                alt="imagenes"
                                            />
                                            <div
                                                onClick={() =>
                                                    handleDelete(image.id)
                                                }
                                                className="absolute rounded-md cursor-pointer top-1 right-1 bg-slate-200 hover:scale-105 "
                                            >
                                                <svg
                                                    className="w-6 h-6 text-blue-500"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="9"
                                                    />{" "}
                                                    <path d="M10 10l4 4m0 -4l-4 4" />
                                                </svg>
                                            </div>
                                        </div>
                                    ))}
                                </ReactSortable>
                            </div>
                        )}
                        {loading && <div>{<Spinner />}</div>}
                        <div className="flex flex-col gap-4 mt-2 2xl:mt-8">
                            {saving ? (
                                <Spinner />
                            ) : (
                                <button
                                    onClick={() => handleGuardar()}
                                    className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 2xl:text-base text-sm"
                                >
                                    Subir imagenes
                                </button>
                            )}
                        </div>
                    </div>
                    <button
                        onClick={() => setEspaciosEdit(null)}
                        className="absolute text-gray-800 dark:text-gray-400 top-1 right-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                        aria-label="close"
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18 6L6 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6 6L18 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>{" "}
            </div>{" "}
        </div>
    );
};

export default ModalEditEspacio;
