import React, { useState } from "react";
import imagen5 from "../../img/landing_comprimidas/imagen-landing-5.webp";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import logo from "../../img/logoPNGalerta.png";

export const Contacto = () => {
    const { tags } = useParams();
    const [input, setInput] = useState({
        nombre: "",
        apellido: "",
        celular: "",
        correo: "",
        mensaje: "",
        etiqueta: tags ? tags : "6",
    });
    const [saving, setSaving] = useState<boolean>(false);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setInput({
            ...input,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async () => {
        if (
            !input.nombre ||
            !input.apellido ||
            !input.celular ||
            !input.correo ||
            !input.mensaje
        ) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Campos incompletos",
                text: "Por favor, completa todos los campos.",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
            return;
        }

        try {
            setSaving(true);
            const data = await fetchAxiosNoToken({
                url: `/home/postContact`,
                method: "post",
                body: input,
            });

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Mensaje enviado!",
                imageUrl: `${logo}`,
                imageWidth: 300,
                imageHeight: 300,
                text: "En breve nos pondremos en contacto",
                // icon: "success",
                confirmButtonText: "Aceptar",
            });
            setSaving(false);
            setInput({
                nombre: "",
                apellido: "",
                celular: "",
                correo: "",
                mensaje: "",
                etiqueta: tags ? tags : "6",
            });
        } catch (error) {
            console.log(error);
            setSaving(true);
        }
    };
    console.log({ tags });
    return (
        <div>
            <div className="z-30 flex flex-col items-center justify-center h-auto  bg-[#0E1928]">
                <div className="z-30 w-5/6 md:w-4/5 xl:w-3/4">
                    {/* <img
                        className="object-cover w-full h-full rounded-xl"
                        src={imagen5}
                        alt="Imagen de fondo"
                    /> */}
                    {/* <div className="absolute top-0 left-0 w-full h-full bg-[#029bfc] opacity-80 z-30 rounded-2xl"></div> */}
                    <div className="top-0 left-0 z-40 flex flex-col w-full h-full px-5 py-5 items center md:px-8 md:p-10 lg:px-20 ">
                        <div className="items-center text-xl font-bold text-center text-white md:text-3xl lg:text-4xl">
                            Contáctenos
                        </div>
                        <div className="flex flex-col items-center justify-between gap-6 mt-2 lg:flex-row 2xl:px-20">
                            <div className="w-full gap-2">
                                <p className="pl-4 text-white">Nombre</p>
                                <input
                                    onChange={handleInputChange}
                                    type="text"
                                    name="nombre"
                                    className=" p-3 md:p-4 mt-2 placeholder-[#C0D345] bg-[#C0D345]/10 text-white placeholder:text-base  placeholder:px-2 md:placeholder:px-3  w-full outline-none  rounded-2xl "
                                    placeholder="Colocar nombres"
                                />
                            </div>
                            <div className="w-full gap-2">
                                <p className="pl-4 text-white">Apellido</p>

                                <input
                                    onChange={handleInputChange}
                                    type="text"
                                    name="apellido"
                                    className=" p-3 md:p-4 mt-2 placeholder-[#C0D345] bg-[#C0D345]/10 text-white placeholder:text-base  placeholder:px-2 md:placeholder:px-3  w-full outline-none  rounded-2xl "
                                    placeholder="Colocar apellidos"
                                />
                            </div>
                        </div>

                        <div className="flex flex-col items-center justify-between gap-6 mt-2 lg:flex-row 2xl:px-20">
                            <div className="w-full gap-2">
                                <p className="pl-4 text-white">Correo</p>
                                <input
                                    onChange={handleInputChange}
                                    type="text"
                                    name="correo"
                                    className=" p-3 md:p-4 mt-2 placeholder-[#C0D345] bg-[#C0D345]/10 text-white placeholder:text-base  placeholder:px-2 md:placeholder:px-3  w-full outline-none  rounded-2xl "
                                    placeholder="Colocar correo"
                                />
                            </div>
                            <div className="w-full gap-2">
                                <p className="pl-4 text-white">Celular</p>

                                <input
                                    onChange={handleInputChange}
                                    type="text"
                                    name="celular"
                                    className=" p-3 md:p-4 mt-2 placeholder-[#C0D345] bg-[#C0D345]/10 text-white placeholder:text-base  placeholder:px-2 md:placeholder:px-3  w-full outline-none  rounded-2xl "
                                    placeholder="+51 "
                                />
                            </div>
                        </div>

                        <div className="flex flex-col mt-6 2xl:px-20">
                            <p className="pl-4 text-white">Mensaje</p>
                            <textarea
                                onChange={handleInputChange}
                                name="mensaje"
                                className=" p-3 md:p-4 rounded-lg placeholder-[#C0D345] bg-[#C0D345]/10 text-white placeholder:text-base  placeholder:px-2 md:placeholder:px-3  w-full mt-4 outline-none"
                                placeholder="Introducir mensaje"
                                rows={5}
                            />
                        </div>

                        <div className="flex items-center justify-center mt-6 md:px-5 2xl:px-24">
                            <button
                                onClick={() => handleSubmit()}
                                disabled={saving}
                                className=" mb-4 md:mb-8 bg-[#C0D345] rounded-3xl py-1 px-16 font-bold md:px-20 text-[#0E1928] gap-2 text-lg flex items-center">
                                {" "}
                                {saving ? "Enviando.." : "Enviar"}{" "}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
