import React, { useState, useEffect } from "react";
import Spinner from "../../../components/helpers/Spinner";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import { ModalEditHab } from "../../onboardingPropietario/habitaciones/ModalEditHab";
import { ModalEditVariablePrice } from "./ModalEditVariablePrice";
import { fetchAxiosBlueCornerNoToken } from "../../../helpers/fetchAxiosBlueCornerNoToken";

import iconoBluecorner from "../../../img/bluecornericono.png";
import iconoAirbnb from "../../../img/airbnb-logo.png";
import iconoBooking from "../../../img/booking-logo.png";
import iconoTripadvisor from "../../../img/tripadvisor-logo.png";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import { useNavigate } from "react-router-dom";
import { ModalAlertDesbloqueo } from "./ModalAlertDesbloqueo";

const fechas = {
    precio_base: "300",
    moneda: "USD",
    precios_variables: [
        {
            fecha: "01/01/2023",
            precio: "500",
        },
        {
            fecha: "08/01/2023",
            precio: "500",
        },
        {
            fecha: "31/01/2023",
            precio: "800",
        },
        {
            fecha: "10/02/2023",
            precio: "400",
        },
    ],
    reservas: ["05/01/2023", "01/02/2023", "21/04/2023"],
};

interface Precio {
    precio: string;
    moneda: string;
}

interface VariablePrice {
    id: number;
    id_casa: number;
    fecha: string;
    precio: string;
}

interface NoDisponible {
    dia_final: string;
    dia_inicio: string;
    fecha_final: string;
    fecha_inicio: string;
    id: number;
    id_casa: string;
    tipo: string;
}

interface Fechas {
    fecha: string;
    motivo: string;
}
export interface Selected {
    price: string;
    date: string;
    fechaNoDisponible: Fechas | false;
}

interface Dias {
    dia: number;
    motivo: string;
    fecha_inicio: string;
    fecha_final: string;
}

export interface Reservas {
    id_pedido: number;
    fecha: string;
}

export const CalendarioListado = () => {
    const navigate = useNavigate();
    const { id, tipo_usuario, nombre } = useAppSelector(
        (state) => state.landing_house.house
    );
    const [date, setDate] = useState(new Date());
    const [modalVisiblePrice, setModalVisiblePrice] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [precioBase, setPrecioBase] = useState<Precio>({
        precio: "",
        moneda: "",
    });
    const [variablePrice, setVariablePrice] = useState<VariablePrice[]>([]);
    const [arrayDias, setArrayDias] = useState<Dias[]>([]);
    const [arrayFechas, setArrayFechas] = useState<Fechas[]>([]);
    const [arrayDiasBluecorner, setArrayDiasBluecorner] = useState<number[]>(
        []
    );
    const [arrayMesBluecorner, setArrayMesBluecorner] = useState<number[]>([]);
    const [arrayPedidos, setArrayPedidos] = useState<Reservas[]>([]);

    const [arrayFechasBluecorner, setArrayFechasBluecorner] = useState<
        string[]
    >([]);

    const [arrayFechasAirBnb, setArrayFechasAirBnb] = useState<string[]>([]);
    const [arrayFechasBooking, setArrayFechasBooking] = useState<string[]>([]);
    const [arrayFechasTripadvisor, setArrayFechasTripadvisor] = useState<
        string[]
    >([]);

    const [arrayPedidosBluecorner, setArrayPedidosBluecorner] = useState<
        string[]
    >([]);

    const [selected, setSelected] = useState<Selected>({
        date: "",
        price: "",
        fechaNoDisponible: false,
    });
    const [modalDesbloqueo, setModalDesbloqueo] = useState(false);

    const {
        id: id_casa,
        clave,
        id_airbnb,
        id_booking,
        id_tripadvisor,
        plan,
    } = useAppSelector((state) => state.landing_house.house);

    const propietario = useAppSelector((state) => state.usuario_logeado);

    function bgColorPorMotivo(motivo: string): string {
        const colores: { [key: string]: string } = {
            Airbnb: "#EC7063",
            Booking: "#5DADE2",
            Alquilado: "#48C9B0",
            "En mantenimiento": "#F4D03F",
            "Evento privado": "#A569BD",
            Otro: "#566573",
        };

        return colores[motivo] || "#000000";
    }

    const handleClick = (direction: "previous" | "next") => {
        const newDate = new Date(date);
        if (direction === "previous") {
            newDate.setMonth(newDate.getMonth() - 1);
        } else {
            newDate.setMonth(newDate.getMonth() + 1);
        }
        setDate(newDate);
    };

    const getOne = async () => {
        try {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/id/${id_casa}`,
            });
            console.log(data);
            setVariablePrice(data.precios_variables);
            setArrayDias(data.disponibilidades.arrayDias);
            setArrayFechas(data.disponibilidades.arrayFechas);
            setArrayPedidos(data.pedidos.arrayFechasPedido);
            setPrecioBase({
                precio: data.precio_base,
                moneda: data.tipo_moneda,
            });

            //bluecorner
            if (clave || id_airbnb || id_booking || id_tripadvisor) {
                // if (false) {
                const dataExterna = await fetchAxiosToken({
                    url: `/casa/getAvailabilityFromApiExternals/${id_casa}`,
                });
                console.log(dataExterna);
                setArrayDiasBluecorner(dataExterna.disponibilidadBC.arrayDias);
                setArrayMesBluecorner(dataExterna.disponibilidadBC.arrayMeses);
                setArrayFechasBluecorner(
                    dataExterna.disponibilidadBC.arrayFechas
                );
                setArrayPedidosBluecorner(dataExterna.disponibilidadBC.pedidos);
                setArrayFechasAirBnb(
                    dataExterna.disponibilidadAirBnb.arrayFechas
                );
                setArrayFechasBooking(
                    dataExterna.disponibilidadBooking.arrayFechas
                );
                setArrayFechasTripadvisor(
                    dataExterna.disponibilidadTripAdvisor.arrayFechas
                );
            }

            setLoading(true);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const daysPast = (year: number, month: number, day: number) => {
        const today = new Date(); // Obtener la fecha actual
        const dateToCompare = new Date(year, month - 1, day);

        return dateToCompare.setHours(0, 0, 0, 0) >= today.setHours(0, 0, 0, 0);
    };

    useEffect(() => {
        if (id_casa) {
            setLoading(false);

            getOne();
        }
    }, [id_casa]);

    useEffect(() => {
        if (!modalVisiblePrice) {
            getOne();
            setSelected({
                date: "",
                price: "",
                fechaNoDisponible: false,
            });
        }
    }, [modalVisiblePrice]);

    const navegar = (id: number) => {
        window.open(`/dashboard/reservas/${id}`, "_blank");
    };
    console.log("arrayFechas", arrayFechas);
    const renderCalendar = () => {
        const currentMonth = date.getMonth();

        const currentYear = date.getFullYear();
        const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
        const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
        const monthDays = lastDayOfMonth.getDate();
        const monthName = new Intl.DateTimeFormat("es-ES", {
            month: "long",
        }).format(date);
        const days = [
            "Lunes",
            "Martes",
            "Miercoles",
            "Jueves",
            "Viernes",
            "Sabado",
            "Domingo",
        ];
        const dayOfWeek = firstDayOfMonth.getUTCDay();
        let calendar = [];
        let week = [];

        const dayTransform = [6, 0, 1, 2, 3, 4, 5];
        const monthTransform = [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
        ];

        for (let i = 0; i < dayTransform[dayOfWeek]; i++) {
            week.push(<td key={i + 60} className="border bg-gray-50"></td>);
        }

        for (let i = 1; i <= monthDays; i++) {
            if (week.length === 7) {
                calendar.push(
                    <tr key={i} className="">
                        {week}
                    </tr>
                );
                week = [];
            }
            let price = variablePrice.find(
                (x) =>
                    x.fecha ===
                    `${currentYear}-${monthTransform[currentMonth]}-${
                        i > 9 ? i : "0" + i
                    }`
            );

            let reservation = arrayPedidos.find(
                (x) =>
                    x.fecha ===
                    `${currentYear}-${monthTransform[currentMonth]}-${
                        i > 9 ? i : "0" + i
                    }`
            );

            let fechaNoDisponible = arrayFechas.find(
                (x) =>
                    x.fecha ===
                    `${currentYear}-${monthTransform[currentMonth]}-${
                        i > 9 ? i : "0" + i
                    }`
            );

            let reservationBC = arrayPedidosBluecorner.find(
                (x) =>
                    x ===
                    `${currentYear}-${monthTransform[currentMonth]}-${
                        i > 9 ? i : "0" + i
                    }`
            );

            let weekday = new Date(
                `${currentYear}-${monthTransform[currentMonth]}-${
                    i > 9 ? i : "0" + i
                }`
            ).getUTCDay();

            let diaNoDisponible = arrayDias.find(
                (dia) =>
                    dia.dia === weekday &&
                    new Date(
                        `${currentYear}-${monthTransform[currentMonth]}-${
                            i > 9 ? i : "0" + i
                        }`
                    ) <= new Date(dia.fecha_final) &&
                    new Date(
                        `${currentYear}-${monthTransform[currentMonth]}-${
                            i > 9 ? i : "0" + i
                        }`
                    ) >= new Date(dia.fecha_inicio)
            );

            week.push(
                <td
                    onClick={() =>
                        reservation
                            ? navegar(reservation.id_pedido)
                            : arrayFechas.some(
                                  (fecha) =>
                                      fecha.fecha ===
                                      `${currentYear}-${
                                          monthTransform[currentMonth]
                                      }-${i > 9 ? i : "0" + i}`
                              )
                            ? setModalDesbloqueo(true)
                            : true &&
                              daysPast(
                                  currentYear,
                                  Number(monthTransform[currentMonth]),
                                  Number(i)
                              ) &&
                              !(
                                  (
                                      arrayDiasBluecorner.includes(weekday) ||
                                      arrayMesBluecorner.includes(
                                          currentMonth
                                      ) ||
                                      arrayDias.some(
                                          (dia) =>
                                              dia.dia === weekday &&
                                              new Date(
                                                  `${currentYear}-${
                                                      monthTransform[
                                                          currentMonth
                                                      ]
                                                  }-${i > 9 ? i : "0" + i}`
                                              ) <= new Date(dia.fecha_final) &&
                                              new Date(
                                                  `${currentYear}-${
                                                      monthTransform[
                                                          currentMonth
                                                      ]
                                                  }-${i > 9 ? i : "0" + i}`
                                              ) >= new Date(dia.fecha_inicio)
                                      ) ||
                                      arrayFechasBluecorner.includes(
                                          `${currentYear}-${
                                              monthTransform[currentMonth]
                                          }-${i > 9 ? i : "0" + i}`
                                      ) ||
                                      arrayPedidosBluecorner.includes(
                                          `${currentYear}-${
                                              monthTransform[currentMonth]
                                          }-${i > 9 ? i : "0" + i}`
                                      ) ||
                                      arrayFechasAirBnb.includes(
                                          `${currentYear}-${
                                              monthTransform[currentMonth]
                                          }-${i > 9 ? i : "0" + i}`
                                      ) ||
                                      arrayFechasBooking.includes(
                                          `${currentYear}-${
                                              monthTransform[currentMonth]
                                          }-${i > 9 ? i : "0" + i}`
                                      ) ||
                                      arrayFechasTripadvisor.includes(
                                          `${currentYear}-${
                                              monthTransform[currentMonth]
                                          }-${i > 9 ? i : "0" + i}`
                                      )
                                  ) /* ||
                                      arrayFechas.some(
                                          (fecha) =>
                                              fecha.fecha ===
                                              `${currentYear}-${
                                                  monthTransform[currentMonth]
                                              }-${i > 9 ? i : "0" + i}`
                                      ) */ //borrar aca verlo con ludwig
                              ) &&
                              (setSelected({
                                  price: price
                                      ? price.precio
                                      : precioBase.precio,
                                  date: `${currentYear}-${
                                      monthTransform[currentMonth]
                                  }-${i > 9 ? i : "0" + i}`,
                                  fechaNoDisponible: fechaNoDisponible
                                      ? fechaNoDisponible
                                      : false,
                              }),
                              setModalVisiblePrice(true))
                    }
                    key={i}
                    className={`py-2  border  md818:p-4  group ${
                        daysPast(
                            currentYear,
                            Number(monthTransform[currentMonth]),
                            Number(i)
                        ) &&
                        !(
                            arrayDiasBluecorner.includes(weekday) ||
                            arrayMesBluecorner.includes(currentMonth) ||
                            arrayDias.some(
                                (dia) =>
                                    dia.dia === weekday &&
                                    new Date(
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                    ) <= new Date(dia.fecha_final) &&
                                    new Date(
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                    ) >= new Date(dia.fecha_inicio)
                            ) ||
                            arrayFechasBluecorner.includes(
                                `${currentYear}-${
                                    monthTransform[currentMonth]
                                }-${i > 9 ? i : "0" + i}`
                            ) ||
                            arrayPedidosBluecorner.includes(
                                `${currentYear}-${
                                    monthTransform[currentMonth]
                                }-${i > 9 ? i : "0" + i}`
                            ) ||
                            arrayFechasAirBnb.includes(
                                `${currentYear}-${
                                    monthTransform[currentMonth]
                                }-${i > 9 ? i : "0" + i}`
                            ) ||
                            arrayFechasBooking.includes(
                                `${currentYear}-${
                                    monthTransform[currentMonth]
                                }-${i > 9 ? i : "0" + i}`
                            ) ||
                            arrayFechasTripadvisor.includes(
                                `${currentYear}-${
                                    monthTransform[currentMonth]
                                }-${i > 9 ? i : "0" + i}`
                            ) ||
                            arrayFechas.some(
                                (fecha) =>
                                    fecha.fecha ===
                                    `${currentYear}-${
                                        monthTransform[currentMonth]
                                    }-${i > 9 ? i : "0" + i}`
                            )
                        )
                            ? `  ${
                                  !reservation
                                      ? "cursor-pointer bg-[#029BFC] bg-opacity-20"
                                      : "cursor-default"
                              }`
                            : reservation
                            ? "  cursor-pointer"
                            : arrayFechas.some(
                                  (fecha) =>
                                      fecha.fecha ===
                                      `${currentYear}-${
                                          monthTransform[currentMonth]
                                      }-${i > 9 ? i : "0" + i}`
                              )
                            ? "cursor-pointer"
                            : " cursor-not-allowed "
                    }`}>
                    <div className="relative">
                        <div className="flex items-center justify-between ">
                            {!reservation &&
                            (reservationBC ||
                                arrayFechasBluecorner.includes(
                                    `${currentYear}-${
                                        monthTransform[currentMonth]
                                    }-${i > 9 ? i : "0" + i}`
                                ) ||
                                arrayPedidosBluecorner.includes(
                                    `${currentYear}-${
                                        monthTransform[currentMonth]
                                    }-${i > 9 ? i : "0" + i}`
                                ) ||
                                arrayFechasAirBnb.includes(
                                    `${currentYear}-${
                                        monthTransform[currentMonth]
                                    }-${i > 9 ? i : "0" + i}`
                                ) ||
                                arrayFechasBooking.includes(
                                    `${currentYear}-${
                                        monthTransform[currentMonth]
                                    }-${i > 9 ? i : "0" + i}`
                                ) ||
                                arrayFechasTripadvisor.includes(
                                    `${currentYear}-${
                                        monthTransform[currentMonth]
                                    }-${i > 9 ? i : "0" + i}`
                                ) ||
                                arrayDiasBluecorner.includes(weekday) ||
                                arrayMesBluecorner.includes(currentMonth)) ? (
                                <div className="flex ">
                                    {(arrayDiasBluecorner.includes(weekday) ||
                                        arrayFechasBluecorner.includes(
                                            `${currentYear}-${
                                                monthTransform[currentMonth]
                                            }-${i > 9 ? i : "0" + i}`
                                        ) ||
                                        arrayMesBluecorner.includes(
                                            currentMonth
                                        ) ||
                                        arrayPedidosBluecorner.includes(
                                            `${currentYear}-${
                                                monthTransform[currentMonth]
                                            }-${i > 9 ? i : "0" + i}`
                                        )) && (
                                        <img
                                            alt="logo bluecorner"
                                            src={iconoBluecorner}
                                            className="w-2 h-2 xs:w-4 xs:h-4"
                                        />
                                    )}

                                    {arrayFechasAirBnb.includes(
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                    ) && (
                                        <img
                                            alt="logo airbnb"
                                            src={iconoAirbnb}
                                            className="w-2 h-2 mr-[3px] xs:w-4 xs:h-4"
                                        />
                                    )}
                                    {arrayFechasBooking.includes(
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                    ) && (
                                        <img
                                            alt="logo booking"
                                            src={iconoBooking}
                                            className="w-2 h-2 rounded-full xs:w-4 xs:h-4"
                                        />
                                    )}
                                    {arrayFechasTripadvisor.includes(
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                    ) && (
                                        <img
                                            alt="logo tripadvisor"
                                            src={iconoTripadvisor}
                                            className="w-2 h-2 rounded-full xs:w-4 xs:h-4"
                                        />
                                    )}
                                </div>
                            ) : reservation ? (
                                <div className=""></div>
                            ) : fechaNoDisponible !== undefined ? (
                                <div className="flex items-center">
                                    <div
                                        style={{
                                            backgroundColor: bgColorPorMotivo(
                                                fechaNoDisponible.motivo
                                            ),
                                        }}
                                        className="w-2 h-2 rounded-full "></div>
                                    <p className="ml-1 text-xs">
                                        {fechaNoDisponible.motivo}
                                    </p>
                                </div>
                            ) : diaNoDisponible !== undefined ? (
                                <div className="flex items-center">
                                    <div
                                        style={{
                                            backgroundColor: bgColorPorMotivo(
                                                diaNoDisponible.motivo
                                            ),
                                        }}
                                        className="w-2 h-2 rounded-full "></div>
                                    <p className="ml-1 text-xs">
                                        {diaNoDisponible.motivo}
                                    </p>
                                </div>
                            ) : (
                                <div className=""></div>
                            )}

                            <p className="text-[8px] text-right xs:text-sm lg:text-lg lg1170:text-xl ">
                                {i}
                            </p>
                        </div>
                        <p className="text-[8px] text-center  xs:text-xs  md:text-base 2xl:text-x ">
                            {price ? price.precio : precioBase.precio}
                            <span className="ml-1 text-[5px]   md:text-base 2xl:text-xl ">
                                {precioBase.moneda}
                            </span>
                        </p>
                        {(reservation || reservationBC) && (
                            <p className="xs:px-2 xs:py-1 text-[8px] md:text-[10px] 2xl:text-xs text-center xs:rounded-md text-[#808080]  bg-[#E6E6E6] text-opacity-90 relative top-1 group-hover:text-white">
                                {reservationBC
                                    ? "Reservado"
                                    : `Reservado #${reservation?.id_pedido}`}
                            </p>
                        )}

                        {(arrayDiasBluecorner.includes(weekday) ||
                            arrayMesBluecorner.includes(currentMonth) ||
                            arrayDias.some(
                                (dia) =>
                                    dia.dia === weekday &&
                                    new Date(
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                    ) <= new Date(dia.fecha_final) &&
                                    new Date(
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                    ) >= new Date(dia.fecha_inicio)
                            ) ||
                            arrayFechasBluecorner.includes(
                                `${currentYear}-${
                                    monthTransform[currentMonth]
                                }-${i > 9 ? i : "0" + i}`
                            ) ||
                            arrayPedidosBluecorner.includes(
                                `${currentYear}-${
                                    monthTransform[currentMonth]
                                }-${i > 9 ? i : "0" + i}`
                            ) ||
                            arrayFechasAirBnb.includes(
                                `${currentYear}-${
                                    monthTransform[currentMonth]
                                }-${i > 9 ? i : "0" + i}`
                            ) ||
                            arrayFechasBooking.includes(
                                `${currentYear}-${
                                    monthTransform[currentMonth]
                                }-${i > 9 ? i : "0" + i}`
                            ) ||
                            arrayFechasTripadvisor.includes(
                                `${currentYear}-${
                                    monthTransform[currentMonth]
                                }-${i > 9 ? i : "0" + i}`
                            ) ||
                            arrayFechas.some(
                                (fecha) =>
                                    fecha.fecha ===
                                    `${currentYear}-${
                                        monthTransform[currentMonth]
                                    }-${i > 9 ? i : "0" + i}`
                            )) &&
                            !reservation &&
                            !reservationBC && (
                                <p className="xs:px-2 xs:py-[2px] text-[5px] xs:text-[8px] lg:text-xs text-center xs:rounded-lg bg-white  text-gray-400 text-opacity-90 relative top-1 group-hover:text-white">
                                    {arrayDiasBluecorner.includes(weekday) ||
                                    arrayMesBluecorner.includes(currentMonth) ||
                                    arrayFechasBluecorner.includes(
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                    ) ||
                                    arrayPedidosBluecorner.includes(
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                    ) ||
                                    arrayFechasAirBnb.includes(
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                    ) ||
                                    arrayFechasBooking.includes(
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                    ) ||
                                    arrayFechasTripadvisor.includes(
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                    ) ? (
                                        <div className="flex items-center bg-[#FF6969] text-white justify-center xs:rounded-md">
                                            <span>no disponible</span>
                                            {/* <img
                                                alt="logo bluecorner"
                                                src={iconoBluecorner}
                                                className="w-4 h-4 xs:w-6 xs:h-6"
                                            /> */}
                                        </div>
                                    ) : (
                                        <p className="py-[3px] bg-[#FF6969] text-white xs:rounded-md">
                                            {" "}
                                            no disponible
                                        </p>
                                    )}
                                </p>
                            )}

                        {!reservation &&
                            daysPast(
                                currentYear,
                                Number(monthTransform[currentMonth]),
                                Number(i)
                            ) &&
                            !(
                                arrayDiasBluecorner.includes(weekday) ||
                                arrayMesBluecorner.includes(currentMonth) ||
                                arrayDias.some(
                                    (dia) =>
                                        dia.dia === weekday &&
                                        new Date(
                                            `${currentYear}-${
                                                monthTransform[currentMonth]
                                            }-${i > 9 ? i : "0" + i}`
                                        ) <= new Date(dia.fecha_final) &&
                                        new Date(
                                            `${currentYear}-${
                                                monthTransform[currentMonth]
                                            }-${i > 9 ? i : "0" + i}`
                                        ) >= new Date(dia.fecha_inicio)
                                ) ||
                                arrayFechasBluecorner.includes(
                                    `${currentYear}-${
                                        monthTransform[currentMonth]
                                    }-${i > 9 ? i : "0" + i}`
                                ) ||
                                arrayPedidosBluecorner.includes(
                                    `${currentYear}-${
                                        monthTransform[currentMonth]
                                    }-${i > 9 ? i : "0" + i}`
                                ) ||
                                arrayFechasAirBnb.includes(
                                    `${currentYear}-${
                                        monthTransform[currentMonth]
                                    }-${i > 9 ? i : "0" + i}`
                                ) ||
                                arrayFechasBooking.includes(
                                    `${currentYear}-${
                                        monthTransform[currentMonth]
                                    }-${i > 9 ? i : "0" + i}`
                                ) ||
                                arrayFechasTripadvisor.includes(
                                    `${currentYear}-${
                                        monthTransform[currentMonth]
                                    }-${i > 9 ? i : "0" + i}`
                                ) ||
                                arrayFechas.some(
                                    (fecha) =>
                                        fecha.fecha ===
                                        `${currentYear}-${
                                            monthTransform[currentMonth]
                                        }-${i > 9 ? i : "0" + i}`
                                )
                            ) && (
                                <p className="xs:px-2 xs:py-1 text-[8px] xs:text-xs text-center xs:rounded-md text-[#808080]  bg-white text-opacity-90 relative top-1 group-hover:text-white">
                                    Disponible
                                </p>
                            )}
                    </div>
                </td>
            );
        }

        if (week.length) {
            for (let i = week.length; i < 7; i++) {
                week.push(<td key={i + 40} className="border bg-gray-50"></td>);
            }

            calendar.push(<tr key={"key"}>{week}</tr>);
        }

        return (
            <>
                <div className="flex flex-row justify-between w-full mt-10 mb-4 ">
                    <div className="flex items-center justify-center">
                        <div className="relative flex items-center justify-center 2xl:mr-8 md:mr-6">
                            <p className=" text-base lg:text-2xl 2xl:text-3xl font-bold text-[#029BFC]  pl-1 xs:pl-5">
                                {" "}
                                {monthName.toLocaleUpperCase()} {currentYear}
                            </p>
                            <BotonInformacion text="Aca puedes colocar tus costos por noches y ver las noches reservadas y no disponibles" />
                        </div>

                        <div
                            onClick={() => handleClick("previous")}
                            className="px-1 py-1 border-2 border-[#029BFC] transition ease-in rounded-lg cursor-pointer hover:scale-105 mr-3 2xl:mr-6">
                            <svg
                                className="2xl:h-6 2xl:w-6 w-4 h-4 text-[#029BFC]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <polyline points="15 6 9 12 15 18" />
                            </svg>
                        </div>

                        <div
                            onClick={() => handleClick("next")}
                            className="px-1 py-1 border-2 border-[#029BFC] transition ease-in rounded-lg cursor-pointer hover:scale-105">
                            <svg
                                className="2xl:h-6 2xl:w-6 w-4 h-4 text-[#029BFC]"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <polyline points="9 18 15 12 9 6" />
                            </svg>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                            onClick={() =>
                                navigate("/dashboard/noche-no-disponibles")
                            }
                            className="flex justify-end 2xl:p-3 lg:p-2.5 p-2 2xl:m-2 lg:m-1.5 m-1 border-2 border-[#CCCCCC] rounded-md  hover:text-white text-[#4D4D4D] hover:bg-[#029BFC] hover:border-[#029BFC] 2xl:text-base text-xs">
                            Días no disponibles
                        </button>

                        {tipo_usuario !== 6 && (
                            <button
                                onClick={() =>
                                    navigate("/dashboard/precios-avanzados")
                                }
                                className="flex justify-end 2xl:p-3 lg:p-2.5 p-2 2xl:m-2 lg:m-1.5 m-1 border-2 border-[#CCCCCC] rounded-md  hover:text-white text-[#4D4D4D] hover:bg-[#029BFC] hover:border-[#029BFC] 2xl:text-base text-xs">
                                Tarifas por días de la semana
                            </button>
                        )}
                    </div>
                </div>

                <table className="w-full px-2 table-auto">
                    <thead>
                        <tr>
                            {days.map((day, index) => (
                                <th
                                    key={day}
                                    className=" text-center lg1170:p-2 text-sm md:text-xl 2xl:text-2xl text-[#029BFC] uppercase">
                                    {day.slice(0, 3)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="">{calendar}</tbody>
                </table>
            </>
        );
    };

    return (
        <div className="w-full px-2 mx-auto ">
            {selected.price !== "" && (
                <ModalEditVariablePrice
                    idCasa={id_casa || 0}
                    modalVisiblePrice={modalVisiblePrice}
                    setModalVisiblePrice={setModalVisiblePrice}
                    moneda={precioBase.moneda}
                    setVariablePrice={setVariablePrice}
                    selected={selected}
                />
            )}
            {modalDesbloqueo && (
                <ModalAlertDesbloqueo
                    modalVisiblePrice={modalDesbloqueo}
                    setModalVisiblePrice={setModalDesbloqueo}
                />
            )}
            {loading ? (
                renderCalendar()
            ) : (
                <div className="flex-col items-center justify-center">
                    <div className="flex items-center justify-center mx-auto w-28 h-28">
                        <Spinner width={28} />
                    </div>
                    <div className="mt-5 text-xl text-center">
                        Por favor, aguarde mientras cargamos los datos.
                    </div>
                </div>
            )}
        </div>
    );
};
