import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ItemInterface, Estado } from "./CRM";
import { useAppSelector } from "../../../hooks/hooks";

interface AccordionProps {
    column1: ItemInterface[];
    column2: ItemInterface[];
    column3: ItemInterface[];
    column4: ItemInterface[];
    column5: ItemInterface[];
    column6: ItemInterface[];
    column7: ItemInterface[];

    handleEnable: (n: number) => void;
    modalEliminar: ModalEliminar;
    setModalEliminar: (s: ModalEliminar) => void;
    setEstadoEdit: (s: Estado | null) => void;
}

interface ModalEliminar {
    visible: boolean;
    index: number;
}

const Accordion: React.FC<AccordionProps> = ({
    column1,
    column2,
    column3,
    column4,
    column5,
    column6,
    column7,
    handleEnable,
    modalEliminar,
    setModalEliminar,
    setEstadoEdit,
}) => {
    const [activeAccordion, setActiveAccordion] = useState<number | null>(null);
    const navigate = useNavigate();
    const handleAccordionClick = (index: number) => {
        setActiveAccordion((prevIndex) => (prevIndex === index ? null : index));
    };

    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);

    const handleDetalle = (item: ItemInterface) => {
        if (tipo_usuario === 3 || tipo_usuario === 4) {
            navigate(`/dashboard/crm/${item.id}`);
        }

        if (tipo_usuario === 6) {
            navigate(`/dashboard-corredor/crm/${item.id}`);
        }
    };

    const itemCRM = (item: ItemInterface) => {
        return (
            <div
                key={item.id}
                className="flex flex-col border border-gray-200 rounded-lg  2xl:p-2 p-1.5 mt-3"
            >
                <div className="relative flex items-center justify-between   gap-2 ">
                    <div
                        onClick={() => handleDetalle(item)}
                        className="flex items-center gap-2 cursor-pointer hover:scale-105"
                    >
                        <img
                            className="object-cover w-8 h-8 border rounded-full"
                            src={`${
                                process.env.REACT_APP_PROXY
                            }/usuarios/getImage/${
                                item.id_cliente !== 0
                                    ? item.usuario?.avatar
                                    : "sinImagen.png"
                            }`}
                            alt="avatar"
                        />
                        <div className="2xl:text-base text-sm">
                            {item.id_cliente !== 0
                                ? `${item.usuario.nombre} ${item.usuario.apellido}`
                                : `${item.chat.chats_usuarios[0].cliente}`}
                        </div>
                        <span className="text-xs text-gray-500">
                            Tipo : # {item.tipo}
                        </span>
                    </div>

                    <div
                        onMouseLeave={() =>
                            setModalEliminar({
                                visible: false,
                                index: 0,
                            })
                        }
                        className="bg-red-500"
                    >
                        <span
                            onClick={() =>
                                setModalEliminar({
                                    visible: true,
                                    index:
                                        item.id === modalEliminar.index
                                            ? 0
                                            : item.id,
                                })
                            }
                            className={`absolute right-3 top-3.5  cursor-pointer `}
                            aria-label="close"
                        >
                            <svg
                                className="w-5 h-5 text-black"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <circle cx="12" cy="12" r="1" />{" "}
                                <circle cx="12" cy="5" r="1" />{" "}
                                <circle cx="12" cy="19" r="1" />
                            </svg>
                        </span>
                        <div
                            className={
                                modalEliminar.visible === true &&
                                modalEliminar.index === item.id
                                    ? "absolute bg-white  right-1 top-8 border rounded-lg  p-2 z-10"
                                    : "hidden"
                            }
                        >
                            <p
                                onClick={() =>
                                    navigate(`/dashboard/crm/${item.id}`)
                                }
                                className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer"
                            >
                                Detalle
                            </p>

                            <p
                                onClick={() => (
                                    setModalEliminar({
                                        visible: false,
                                        index: 0,
                                    }),
                                    setEstadoEdit({
                                        id: item.id,
                                        nombre: "Primer seguimiento",
                                    })
                                )}
                                className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer"
                            >
                                Actualizar
                            </p>

                            <p
                                onClick={() => (
                                    setModalEliminar({
                                        visible: false,
                                        index: 0,
                                    }),
                                    handleEnable(item.id)
                                )}
                                className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer"
                            >
                                Eliminar
                            </p>
                        </div>
                    </div>
                </div>

                <div className=" flex  gap-1 mt-3 mx-10">
                    <div className="h-3 w-3 rounded-full bg-[#179BD8]"></div>
                    <span className="text-xs text-gray-700">
                        <b>Casa :</b> # {item.casa.nombre}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className="flex-col hidden w-11/12 mx-auto mt-6 lg:flex max-w-7xl">
            <div className="w-full my-1">
                <div
                    className={`2xl:text-lg text-base font-bold cursor-pointer p-2 rounded-md border-2 flex items-center  ${
                        activeAccordion === 0
                            ? "bg-slate-200 hover:bg-gray-100"
                            : "bg-gray-100 hover:bg-gray-50"
                    }`}
                    onClick={() => handleAccordionClick(0)}
                >
                    {activeAccordion !== 0 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Primer seguimiento ({column1.length})
                </div>
                {activeAccordion === 0 && column1.map((item) => itemCRM(item))}
            </div>
            <div className="w-full my-1">
                <div
                    className={`2xl:text-lg text-base font-bold cursor-pointer p-2 rounded-md border-2 flex items-center ${
                        activeAccordion === 1
                            ? "bg-slate-200 hover:bg-gray-100"
                            : "bg-gray-100 hover:bg-gray-200"
                    }`}
                    onClick={() => handleAccordionClick(1)}
                >
                    {activeAccordion !== 1 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Segundo seguimiento ({column2.length})
                </div>
                {activeAccordion === 1 && column2.map((item) => itemCRM(item))}
            </div>
            <div className="w-full my-1">
                <div
                    className={`2xl:text-lg text-base font-bold cursor-pointer p-2 rounded-md border-2 flex items-center  ${
                        activeAccordion === 2
                            ? "bg-slate-200 hover:bg-gray-100"
                            : "bg-gray-100 hover:bg-gray-200"
                    }`}
                    onClick={() => handleAccordionClick(2)}
                >
                    {activeAccordion !== 2 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Propuesta enviada ({column3.length})
                </div>
                {activeAccordion === 2 && column3.map((item) => itemCRM(item))}
            </div>
            <div className="w-full my-1">
                <div
                    className={`2xl:text-lg text-base font-bold cursor-pointer p-2 rounded-md border-2 flex items-center  ${
                        activeAccordion === 1
                            ? "bg-slate-200 hover:bg-gray-100"
                            : "bg-gray-100 hover:bg-gray-200"
                    }`}
                    onClick={() => handleAccordionClick(3)}
                >
                    {activeAccordion !== 3 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Negociación ({column4.length})
                </div>
                {activeAccordion === 3 && column4.map((item) => itemCRM(item))}
            </div>
            <div className="w-full my-1">
                <div
                    className={`2xl:text-lg text-base font-bold cursor-pointer p-2 rounded-md border-2 flex items-center  ${
                        activeAccordion === 4
                            ? "bg-slate-200 hover:bg-gray-100"
                            : "bg-gray-100 hover:bg-gray-200"
                    }`}
                    onClick={() => handleAccordionClick(4)}
                >
                    {activeAccordion !== 4 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Ganados ({column5.length})
                </div>
                {activeAccordion === 4 && column5.map((item) => itemCRM(item))}
            </div>
            <div className="w-full my-1">
                <div
                    className={`2xl:text-lg text-base font-bold cursor-pointer p-2 rounded-md border-2 flex items-center  ${
                        activeAccordion === 5
                            ? "bg-slate-200 hover:bg-gray-100"
                            : "bg-gray-100 hover:bg-gray-200"
                    }`}
                    onClick={() => handleAccordionClick(5)}
                >
                    {activeAccordion !== 5 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Perdidos ({column6.length})
                </div>
                {activeAccordion === 5 && column6.map((item) => itemCRM(item))}
            </div>
            <div className="w-full my-1">
                <div
                    className={`2xl:text-lg text-base font-bold cursor-pointer p-2 rounded-md border-2 flex items-center  ${
                        activeAccordion === 6
                            ? "bg-slate-200 hover:bg-gray-100"
                            : "bg-gray-100 hover:bg-gray-200"
                    }`}
                    onClick={() => handleAccordionClick(6)}
                >
                    {activeAccordion !== 6 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Seguimiento a futuro ({column7.length})
                </div>
                {activeAccordion === 6 && column7.map((item) => itemCRM(item))}
            </div>
        </div>
    );
};

export default Accordion;
