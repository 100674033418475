import { useState, useEffect } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { ModalEditComforts } from "../modalEdit/ModalEditComforts";
import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { ModalEditEvents } from "../modalEdit/ModalEditEvents";
interface Props {
    toggleButton: boolean;
}

const Eventos = ({ toggleButton }: Props) => {
    const [modalVisibleEvents, setModalVisibleEvents] =
        useState<boolean>(false);

    const {
        id_propietario,
        casa_actividades,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);
    console.log("casa_actividades", casa_actividades);
    return (
        <div className="mt-5 2xl:mt-10">
            {modalVisibleEvents && (
                <ModalEditEvents
                    setModalVisibleEvents={setModalVisibleEvents}
                    modalVisibleEvents={modalVisibleEvents}
                />
            )}
            {(casa_actividades.length || toggleButton) && (
                <>
                    <div className="flex items-center justify-center">
                        <div className="flex justify-center items-center text-lg lg:text-2xl 2xl:text-3xl font-bold text-[#1c4cbb] ">
                            Novedades y Eventos
                        </div>
                        <div>
                            {toggleButton && (
                                <div
                                    onClick={() => setModalVisibleEvents(true)}
                                    title="editar actividades"
                                    className="flex flex-col items-center px-2 py-1 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100 "
                                >
                                    <svg
                                        className="w-8 h-8 text-[#1c4cbb]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                        <line
                                            x1="13.5"
                                            y1="6.5"
                                            x2="17.5"
                                            y2="10.5"
                                        />
                                    </svg>
                                    <div className="text-xs text-[#1C4CBB] text-center">
                                        Editar Eventos
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center justify-center mx-10 mt-2 md:mt-5  text-center md:text-justify text-gray-500 text-sm md:text-base 2xl:text-lg">
                        ¿Qué está pasando? Mantente al tanto de nuestras últimas
                        noticias, eventos y lanzamientos emocionantes aquí
                    </div>
                </>
            )}

            {casa_actividades.length > 2 ? (
                <div className="mx-auto w-[250px] md:w-[500px] lg:w-[750px] mt-5 md:mt-14">
                    <Swiper
                        modules={[Navigation, Autoplay, EffectFade, Pagination]}
                        slidesPerView={1}
                        spaceBetween={0}
                        className=" h-[550px] "
                        autoplay={{ delay: 5000 }}
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 0,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 0,
                            },
                            1500: {
                                slidesPerView: 3,
                                spaceBetween: 0,
                            },
                        }}
                        loop
                        pagination={{ clickable: true }}
                    >
                        {casa_actividades.map((actividad, index) => (
                            <SwiperSlide key={index}>
                                <div
                                    className={`flex ${
                                        casa_actividades.length % 2 === 1
                                            ? "flex-col-reverse"
                                            : index % 2 === 0
                                            ? "flex-col"
                                            : "flex-col-reverse"
                                    }  h-[500px] w-[250px]`}
                                >
                                    <div className="h-[250px] w-[250px] bg-[#1c4cbb] p-5">
                                        <div className="text-lg font-bold text-white">
                                            {actividad.actividad}
                                        </div>
                                        <div className="text-sm text-white ">
                                            {actividad.descripcion}
                                        </div>
                                    </div>
                                    <div className="h-[250px] w-[250px]">
                                        <img
                                            src={`${process.env.REACT_APP_PROXY}/casa_actividades/getImage/${actividad.imagen}`}
                                            className="object-cover w-full h-full"
                                            alt={`slide ${index + 1}`}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : (
                <div className="mx-auto w-[250px] md:w-[500px] lg:w-[750px] mt-14 flex gap-4 justify-center flex-col xs:flex-row">
                    {casa_actividades.map((actividad, index) => (
                        <div
                            key={index}
                            className={`flex ${
                                casa_actividades.length % 2 === 1
                                    ? "flex-col-reverse"
                                    : index % 2 === 0
                                    ? "flex-col"
                                    : "flex-col-reverse"
                            }  h-[500px] w-[250px]`}
                        >
                            <div className="h-[250px] w-[250px] bg-[#1c4cbb] p-5">
                                <div className="text-lg font-bold text-white">
                                    {actividad.actividad}
                                </div>
                                <div className="text-sm text-white ">
                                    {actividad.descripcion}
                                </div>
                            </div>
                            <div className="h-[250px] w-[250px]">
                                <img
                                    src={`${process.env.REACT_APP_PROXY}/casa_actividades/getImage/${actividad.imagen}`}
                                    className="object-cover w-full h-full"
                                    alt={`slide ${index + 1}`}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Eventos;
