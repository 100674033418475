import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { ShowDateToUser } from "../../../components/ShowDateToUser";

export interface Contactos {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    celular: string;
    avatar: string;
    tipo_contacto: string;
    fecha_ultima_reserva: string;
    id_pedido: number;
    etapa_crm: string;
    id_crm: number;
}

interface Props {
    contactoSelect: Contactos;
    modalVisible: boolean;
    setContactoSelect: (contactoSelect: Contactos) => void;
    setModalVisible: (b: boolean) => void;
}

interface RegisterForm {
    nombre?: string;
    apellido?: string;
    correo?: string;
}

type errorObject = { [key: string]: string };

const ModalContacto = ({
    modalVisible,
    setContactoSelect,
    contactoSelect,
    setModalVisible,
}: Props) => {
    const [errors, setErrors] = useState<RegisterForm>({});
    const [contacto, setContacto] = useState<Contactos>(contactoSelect);

    console.log(contacto);
    const navegar = (id: number, tipo: string) => {
        window.open(
            `/dashboard/${tipo === "pedido" ? "reservas" : "crm"}/${id}`,
            "_blank"
        );
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto md:p-10">
                <h2 className="text-base font-medium">Detalle de contacto</h2>
                <label className="font-medium ">{contacto.tipo_contacto}</label>
                <div className="w-full text-center ">
                    <div className="flex justify-around gap-2">
                        <div>
                            <label className="ml-1 text-lg font-semibold">
                                Nombre
                            </label>

                            <p className="w-full pt-2 ">{contacto?.nombre}</p>
                        </div>
                        <div>
                            <label className="ml-1 text-lg font-semibold">
                                Apellido
                            </label>

                            <p className="w-full pt-2 ">{contacto?.apellido}</p>
                        </div>
                    </div>
                </div>

                <div className="w-full text-center">
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <label className="text-lg font-semibold ">
                                Correo
                            </label>

                            <p className="w-full pt-2 ">{contacto?.correo}</p>
                        </div>
                        <div>
                            <label className="text-lg font-semibold ">
                                Telefono
                            </label>

                            <p className="w-full pt-2 ">{contacto?.celular}</p>
                        </div>
                    </div>
                </div>

                <div className="w-full text-center">
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <label className="text-lg font-semibold ">
                                Ultima reserva
                            </label>

                            <div className="flex items-center justify-center pt-2">
                                <ShowDateToUser
                                    date={contacto.fecha_ultima_reserva}
                                />

                                {contacto.fecha_ultima_reserva && (
                                    <svg
                                        onClick={() =>
                                            navegar(
                                                contacto.id_pedido,
                                                "pedido"
                                            )
                                        }
                                        className="w-6 h-6 cursor-pointer text-blue-blueFrom hover:scale-110 "
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                        />
                                    </svg>
                                )}
                            </div>
                        </div>
                        <div>
                            <label className="text-lg font-semibold ">
                                Etapa del CRM
                            </label>

                            <p
                                onClick={() =>
                                    contacto.etapa_crm.length &&
                                    navegar(contacto.id_crm, "crm")
                                }
                                className={` pt-2 ${
                                    contacto.etapa_crm.length &&
                                    "cursor-pointer hover:scale-105"
                                }`}>
                                {contacto.etapa_crm}
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="w-full">
                    <label className="text-lg font-semibold ">
                        Correo electrónico
                    </label>
                 
                    <p className="w-full pt-2 ">{propietario?.correo}</p>
                   
                </div> */}

                {/* <div className="flex flex-col gap-4 mt-8">
                    <button
                        onClick={() => handleSubmit()}
                        className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                        Guardar cambios
                    </button>
                </div> */}
            </div>
        </ResponsiveModalAbsolute>
    );
};

export default ModalContacto;
