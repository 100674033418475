import React, { useEffect, useState } from "react";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { putRespuesta } from "../../../../../api/controllers/respuestas_bot.controller";
import Swal from "sweetalert2";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

interface ModalProps {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    id_respuestas_bots: number;
    getData: () => Promise<void>;
}

interface Input {
    id: number;
    id_respuestas_bots: number;
    respuesta: string;
}

const ModalSubMenu = ({
    modalVisible,
    setModalVisible,
    id_respuestas_bots,
    getData,
}: ModalProps) => {
    const [loading, setLoading] = useState(false);

    const [input, setInput] = useState<Input>({
        id: 0,
        id_respuestas_bots: 0,
        respuesta: "",
    });

    useEffect(() => {
        setInput({
            ...input,
            id_respuestas_bots: id_respuestas_bots,
            respuesta: "",
        });
    }, [modalVisible]);

    const handleSubmit = async () => {
        if (input.respuesta === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "La opción no puede estar vacía ",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }

        try {
            setLoading(true);
            const data = await fetchAxiosToken({
                url: `/respuestas_bot/postSubMenu`,
                body: input,
                method: "post",
            });

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Creado con éxito",

                icon: "success",
                confirmButtonText: "Ok",
            });

            setModalVisible(false);
            getData();

            setLoading(false);
        } catch (error: any) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: `${error.response.data.error}`,
                icon: "error",
                confirmButtonText: "Ok",
            });
            setLoading(false);
        }
    };

    return (
        <>
            <ResponsiveModalAbsolute
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
            >
                {" "}
                <h1 className="text-base font-medium text-center 2xl:text-lg">
                    Opciones de respuesta
                </h1>
                <div className="flex flex-col max-w-md gap-4 p-4 mx-auto ">
                    <div className="flex flex-col items-center justify-center">
                        <>
                            <hr className="w-full my-4 bg-gray-300" />
                            <div className="w-full">
                                <h2 className="text-sm font-medium text-left 2xl:text-base">
                                    Respuesta
                                </h2>
                                <div className="flex items-center w-full mt-2">
                                    <label className="text-base font-medium 2xl:text-lg ">
                                        Opción:
                                    </label>
                                    <input
                                        className="w-8/12 p-2 ml-6 bg-white border rounded-lg 2xl:text-base text-sm 2xl:py-2 py-1.5"
                                        placeholder="Ingresa una opción"
                                        value={input.respuesta}
                                        name="respuesta"
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                respuesta: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </>

                        <hr className="w-full my-4 bg-gray-300" />
                    </div>

                    <button
                        disabled={loading}
                        onClick={() => handleSubmit()}
                        className="p-3 mt-2 font-medium text-white border rounded-lg bg-[#029BFC] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default 2xl:text-base text-sm"
                    >
                        Crear opción de respuesta
                    </button>
                </div>
            </ResponsiveModalAbsolute>
        </>
    );
};

export default ModalSubMenu;
