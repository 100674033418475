import { useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { CalendarioReserva } from "./CalendarioReserva";
import getEveryDayBetween2Dates from "../../../helpers/getEveryDayBetween2Dates";

interface Props {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    toggleButton: boolean;
    input: Input;
}

interface Input {
    adultos: number;
    ninos: number;
    fecha_inicio: string;
    fecha_final: string;
}

const ModalReserva = ({
    modalVisible,
    setModalVisible,
    toggleButton,
    input,
}: Props) => {
    const {
        banner,
        whatsapp: whatsappRedux,
        id: casa_id,
        id_propietario,
        nombre,
        estadia_minima,
    } = useAppSelector((state) => state.landing_house.house);

    const array_dias_reserva = getEveryDayBetween2Dates({
        array_fechas: [
            new Date(input.fecha_inicio),
            new Date(input.fecha_final),
        ],
    });

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            max_width={30}
        >
            <div className="flex flex-col items-center mt-2">
                {
                    <div className="w-full p-3 mx-2 mb-4 font-medium text-center border-[#1C4CBB] border-b-2 ">
                        <p className="text-[#1C4CBB] font-bold uppercase text-xl">
                            RESERVAR {nombre}
                        </p>
                        <p className="text-xs text-[#1C4CBB]">
                            Debe reservar al menos {estadia_minima}{" "}
                            {estadia_minima === 1 ? "noche" : "noches"}
                        </p>
                    </div>
                }
                <div className="px-1 xs:px-3">
                    <CalendarioReserva
                        toggleButton={toggleButton}
                        className="flex justify-center p-3"
                        input={input}
                    />
                    <div className="px-3 mt-8 text-center">
                        <div className="flex justify-center"></div>
                    </div>
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};

export default ModalReserva;
