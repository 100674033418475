import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { ModalCrearNota } from "./ModalCrearNota";
import { ModalEditarNota } from "./ModalEditarNota";

export interface Nota {
    id: number;
    id_crm: number;
    id_usuario: number;
    asunto: string;
    nota: string;
    fecha_registro: string;
    usuario: Usuario;
}

export interface Usuario {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    avatar: string;
}

export const CRMNota = () => {
    const [tabSelected, setTabSelected] = useState("");

    const [modalCrearNota, setModalCrearNota] = useState(false);
    const [modalVisibleNota, setModalVisibleNota] = useState(false);
    const [modalVisibleEditarNota, setModalVisibleEditarNota] = useState(false);
    const [notaEdit, setNotaEdit] = useState({
        nota: "",
        asunto: "",
        idNota: 0,
    });
    const [nota, setNota] = useState<Nota[]>([]);
    const { id } = useParams();

    const getNota = async (id: string) => {
        const json = await fetchAxiosToken({
            url: `/crm_notas_soporte/getAll/${id}`,
            method: "get",
        });
        console.log(json);
        setNota(json);
    };

    const eliminarNota = async (idNota: number) => {
        const json = await fetchAxiosToken({
            url: `/crm_notas_soporte/deleteNota/${idNota}`,
            method: "delete",
        });
        console.log(json);

        getNota(`${id}`);

        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            // position: "top-end",
            icon: "success",
            title: "Nota eliminada",

            showConfirmButton: true,
            // timer: 2000,
        });
    };

    useEffect(() => {
        if (id) {
            getNota(`${id}`);
        }
    }, [modalVisibleNota, modalVisibleEditarNota]);

    return (
        <div className="h-[86vh] border-x border-t">
            {/* {modalPlanificarActividad && (
                <ModalPlanificarActividad
                    empresa={empresa}
                    modalVisible={modalPlanificarActividad}
                    setModalVisible={setModalPlanificarActividad}
                    setRefreshActividades={setRefreshActividades}
                />
            )}
            {modalCrearNota && (
                <ModalCreateNota
                    empresa={empresa}
                    modalVisible={modalCrearNota}
                    setModalVisible={setModalCrearNota}
                    setRefreshActividades={setRefreshActividades}
                />
            )}
            {modalEnviarMensaje && (
                <EnviarMensaje
                    empresa={empresa}
                    modalVisible={modalEnviarMensaje}
                    setModalVisible={setModalEnviarMensaje}
                    setRefreshActividades={setRefreshActividades}
                />
            )} */}
            {modalVisibleNota && (
                <ModalCrearNota
                    modalVisibleNota={modalVisibleNota}
                    setModalVisibleNota={setModalVisibleNota}
                />
            )}
            {modalVisibleEditarNota && notaEdit.idNota !== 0 && (
                <ModalEditarNota
                    modalVisibleEditarNota={modalVisibleEditarNota}
                    setModalVisibleEditarNota={setModalVisibleEditarNota}
                    asunto={notaEdit.asunto}
                    idNota={notaEdit.idNota}
                    nota={notaEdit.nota}
                />
            )}
            <div className="flex justify-between p-2 gap-0.5">
                {/* <span
                    onClick={() => {
                        setTabSelected("Enviar mensaje");
                        setModalEnviarMensaje(!modalEnviarMensaje);
                    }}
                    className={`${
                        tabSelected === "Enviar mensaje" &&
                        "bg-black bg-opacity-50 text-white"
                    } py-2 px-1 flex-grow text-center border cursor-pointer`}>
                    Enviar mensaje
                </span> */}
                <div
                    onClick={() => {
                        setModalVisibleNota(true);
                    }}
                    className={`${
                        tabSelected === "Nota" &&
                        "bg-black bg-opacity-50 text-white"
                    } py-2 px-1 flex-grow text-center border cursor-pointer`}>
                    <div className="relative z-20 flex items-center justify-center">
                        <p className="mr-1"> Dejar nota</p>
                        <BotonInformacion
                            widthHeight={"w-4 h-4"}
                            text="Aca puedes dejar tus notas para recordatorios que tengas con este cliente o reserva"
                        />
                    </div>
                </div>
                {/* <span
                    onClick={() => {
                        setTabSelected("Planificar");
                        setModalPlanificarActividad(!modalPlanificarActividad);
                    }}
                    className={`${
                        tabSelected === "Planificar" &&
                        "bg-black bg-opacity-50 text-white"
                    } py-2 px-1 flex justify-center items-center gap-0.5 flex-grow text-center border cursor-pointer`}>
                    <svg
                        className={`h-4 w-4 text-blue-bluecorner ${
                            tabSelected === "Planificar" && "text-white"
                        }`}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {" "}
                        <path stroke="none" d="M0 0h24v24H0z" />{" "}
                        <polyline points="12 8 12 12 14 14" />{" "}
                        <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
                    </svg>
                    <span>Planificar actividad</span>
                </span> */}
            </div>
            {nota.length > 0 &&
                nota?.map((item, i) => {
                    return (
                        <div
                            className=" border  h-auto px-3  rounded-lg mt-2  text-[#3C3C3C] shadow-md relative mx-2"
                            key={item.id}
                            id={`${item.id}`}>
                            <div className="flex items-start py-3 ">
                                <img
                                    className="object-cover w-8 h-8 border rounded-full"
                                    src={`${process.env.REACT_APP_PROXY}/usuarios/getImage/${item.usuario.avatar}`}
                                    alt="avatar"
                                />
                                <div className="flex mx-1">
                                    <div className="flex flex-col ">
                                        <h1 className="font-medium">
                                            {`Asunto : `}{" "}
                                            <span className="font-normal">
                                                {item.asunto}
                                            </span>
                                        </h1>
                                        <div
                                            onClick={() => {
                                                setModalVisibleEditarNota(true);
                                                setNotaEdit({
                                                    asunto: item.asunto,
                                                    idNota: item.id,
                                                    nota: item.nota,
                                                });
                                            }}
                                            className="flex items-center ">
                                            <p className="mr-2 text-sm font-medium cursor-pointer">
                                                Ver nota{" "}
                                            </p>
                                            <svg
                                                className="w-4 h-4 text-blue-500 cursor-pointer"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />{" "}
                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                                            </svg>
                                        </div>
                                        <p>
                                            <span className="text-xs text-gray-400">
                                                {`por : ${item.usuario.nombre} ${item.usuario.apellido}`}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <svg
                                    onClick={() => eliminarNota(item.id)}
                                    className="absolute w-6 h-6 text-blue-500 cursor-pointer top-1 right-2 hover:scale-105"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    {" "}
                                    <circle cx="12" cy="12" r="10" />{" "}
                                    <line x1="15" y1="9" x2="9" y2="15" />{" "}
                                    <line x1="9" y1="9" x2="15" y2="15" />
                                </svg>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};
