import React, { useState } from "react";
import { useAppSelector } from "../../hooks/hooks";

import { resena } from "../../designs/calendario-reserva/typeCasa";
import { ModalReviews } from "../modalEdit/ModalReviews";
import { Navigation, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import StarsRating from "../../pages/dashboardPropietarios/reservas/StarsRating";

const Reviews = ({ color }: { color: string }) => {
    const { resenas } = useAppSelector((state) => state.landing_house.house);
    const [modalVisibleResenas, setModalVisibleResenas] = useState(false);

    const fecha = (date: string) => {
        const formatoFecha = new Date(date);

        return formatoFecha.toLocaleDateString("es-ES", {
            year: "numeric",
            month: "long",
        });
    };
    const avatar = (resena: resena) => {
        if (resena.avatar) {
            return resena.avatar;
        } else {
            return resena.pedido?.Cliente.avatar;
        }
    };

    const handleModalResena = () => {
        setModalVisibleResenas(true);
    };

    return (
        <div className={resenas.length ? "flex flex-col " : "hidden"}>
            {modalVisibleResenas && (
                <ModalReviews
                    modalVisibleResenas={modalVisibleResenas}
                    setModalVisibleResenas={setModalVisibleResenas}
                />
            )}
            <div className={"flex justify-center px-2"}>
                <h3
                    className={`2xl:text-3xl md:text-2xl text-lg font-bold  w-fit text-[#029BFC]`}
                >
                    Mira lo que dicen sobre nosotros
                </h3>
            </div>
            {resenas.length > 3 ? (
                <div className="md:mt-10 2xl:mt-20 ">
                    <Swiper
                        className=""
                        modules={[Navigation, Autoplay, EffectFade]}
                        slidesPerView={1}
                        spaceBetween={10}
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 1,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 1,
                            },
                            1500: {
                                slidesPerView: 4,
                                spaceBetween: 1,
                            },
                        }}
                        loop
                        pagination={{
                            dynamicBullets: true,
                        }}
                        navigation
                    >
                        {resenas?.map((review, index) => (
                            <SwiperSlide
                                key={index}
                                className="flex items-center justify-center my-auto "
                            >
                                {" "}
                                <div className="flex flex-col items-center justify-between px-8 py-4 mx-5 mb-4 bg-white rounded-lg shadow-lg">
                                    <div>
                                        <p className="text-[#a0a6ad] mt-2 text-left">
                                            {review.resena.length > 97
                                                ? review.resena.slice(0, 97) +
                                                  "..."
                                                : review.resena}
                                        </p>
                                    </div>
                                    <div className="w-full justify-center flex">
                                        <StarsRating
                                            starsQuantity={5}
                                            value={Number(review.puntuacion)}
                                        />
                                    </div>
                                    <div className="flex items-center justify-center w-auto 2xl:mt-5 ">
                                        <img
                                            className="object-cover rounded-full 2xl:w-14 2xl:h-14 md:w-12 md:h-12 w-10 h-10"
                                            src={
                                                `${process.env.REACT_APP_PROXY}/usuarios/getImage/` +
                                                avatar(review)
                                            }
                                            alt="review"
                                        />
                                        <div className="flex flex-col ml-8 -mt-2">
                                            <p className="mt-5 text-lg  md:text-xl  font-bold text-[#029bfc] ">
                                                {review.pedido !== null
                                                    ? review.pedido?.Cliente
                                                          .nombre
                                                    : review.cliente_resena}
                                            </p>

                                            <p className=" text-base md:text-lg font-medium text-[#029bfc]">
                                                {fecha(
                                                    review.pedido
                                                        ?.fecha_inicio ||
                                                        review.fecha_registro
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : (
                <div className="grid sm:grid-cols-2  md818:grid-cols-3 w-full gap-2 md:mt-8 mb-5 md:mb-20">
                    {resenas?.map((review, index) => (
                        <div key={index} className="flex items-center my-auto">
                            <div className="flex flex-col items-center bg-white rounded-lg shadow-lg h-60 lg:h-64 2xl:h-72">
                                <div className="flex flex-col items-center px-8 py-4 mx-5 mb-auto">
                                    <div>
                                        <p className="text-[#a0a6ad] mt-2 text-left">
                                            {review.resena.length > 97
                                                ? review.resena.slice(0, 97) +
                                                  "..."
                                                : review.resena}
                                        </p>
                                    </div>
                                </div>

                                <div className="pb-4 ">
                                    <div className="flex items-center justify-center w-full mt-auto">
                                        <img
                                            className="object-cover rounded-full 2xl:w-14 2xl:h-14 md:h-12 md:w-12 h-10 w-10"
                                            src={
                                                `${process.env.REACT_APP_PROXY}/usuarios/getImage/` +
                                                avatar(review)
                                            }
                                            alt="review"
                                        />
                                        <div className="flex flex-col ml-8 justify-center">
                                            <p className=" text-base md:text-lg 2xl:text-xl font-bold text-[#029bfc]">
                                                {review.pedido !== null
                                                    ? review.pedido?.Cliente
                                                          .nombre
                                                    : review.cliente_resena}
                                            </p>
                                            <p className=" text-sm md:text-base 2xl:text-lg font-medium text-[#029bfc]">
                                                {fecha(
                                                    review.pedido
                                                        ?.fecha_inicio ||
                                                        review.fecha_registro
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-full justify-center flex">
                                        <StarsRating
                                            starsQuantity={5}
                                            value={Number(review.puntuacion)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Reviews;
