import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { ButtonSiguiente } from "../ButtonSiguiente";
import habitacionesImg from "../../../img/onboarding/habitaciones.jpg";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { ModalAgregarHab } from "./ModalAgregarHab";
import Swal from "sweetalert2";
import { ModalEditHab } from "./ModalEditHab";
import { useAppSelector } from "../../../hooks/hooks";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
export interface Habitacion {
    id: number;
    habitacion: string;
    descripcion: string;
    imagen: string;
}

export const Habitaciones = () => {
    const { idCasa } = useParams();
    const [habitaciones, setHabitaciones] = useState<Habitacion[]>([]);
    const [habitacionSelected, setHabitacionSelected] =
        useState<Habitacion | null>(null);

    const [refresh, setRefresh] = useState(false);
    const [modalCrearVisible, setModalCrearVisible] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const { id: id_casa_redux } = useAppSelector(
        (state) => state.landing_house.house
    );
    const id_casa =
        idCasa && Number(idCasa) > 0 ? Number(idCasa) : id_casa_redux;
    const dispatchDominio = useDispatchDominio();

    const navigate = useNavigate();

    const getHabitaciones = async () => {
        try {
            const data = await fetchAxiosToken({
                url: `/casa/getOne/id/${id_casa}`,
            });

            setHabitaciones(data.casa_habitaciones);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (id_casa) {
            getHabitaciones();
        }
    }, [refresh, id_casa]);

    const handleSubmit = async () => {
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putPagina/${id_casa}`,
                method: "put",
                body: {
                    pagina: 5,
                },
            });

            await fetchAxiosToken({
                url: `/casa/putPagina/${id_casa}`,
                method: "put",
                body: {
                    pagina: "ubicacion",
                },
            });
            dispatchDominio();

            navigate(
                idCasa && Number(idCasa) > 0
                    ? `/dashboard-corredor/onboarding/ubicacion/${idCasa}`
                    : `/dashboard/onboarding/ubicacion`
            );
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = (habitacion: Habitacion) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminar la habitación?",
            icon: "warning",
            showCancelButton: true,

            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await fetchAxiosToken({
                        url: `/casa_habitaciones/delete/${habitacion.id}`,
                        method: "delete",
                    });
                    setRefresh((prev) => !prev);
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleEdit = (habitacion: Habitacion) => {
        setModalEditVisible(true);
        setHabitacionSelected(habitacion);
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/4 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={habitacionesImg}
                        alt="habitaciones"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Escoge las habitaciones que posea tu alojamiento
                        </div>

                        <div className="mt-5 text-lg text-center text-white md:text-xl 2xl:text-2xl md:mt-14 ">
                            ¡No te preocupes podrás cambiarlo más adelante!
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center p-6 text-center text-black bg-white lg:overflow-x-auto">
                <ButtonRegresar
                    url={`${
                        idCasa
                            ? `/dashboard-corredor/onboarding/comodidades/${idCasa}`
                            : "/dashboard/onboarding/comodidades"
                    }`}
                    className="absolute top-10 left-10"
                    arrowColor="black"
                    textColor="black"
                />
                <div className="flex flex-col pb-4 mt-20 2xl:mt-52 lg:mt-40">
                    {habitaciones.length === 0 ? (
                        <div className="flex flex-col items-center text-lg font-medium">
                            <h2 className="text-base lg:text-lg 2xl:text-xl">
                                Aun no tienes habitaciones, agrega la primera
                            </h2>
                            <button
                                onClick={() => setModalCrearVisible(true)}
                                className="p-2 px-8 mt-10 bg-gray-200 border rounded-lg hover:bg-gray-300">
                                Agregar habitación
                            </button>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center max-w-3xl gap-4 mx-auto mt-4 text-base lg:text-lg 2xl:text-xl">
                            <h2 className="font-medium ">
                                Has asignado las siguientes habitaciones
                            </h2>
                            <button
                                onClick={() => setModalCrearVisible(true)}
                                className="p-2 px-5 mt-5 bg-gray-200 border rounded-lg 2xl:mt-10 hover:bg-gray-300 md:px-8">
                                Agregar habitación
                            </button>
                            <ul className="w-full lg:overflow-y-scroll lg:max-h-[70vh]">
                                {habitaciones.map((habitacion) => (
                                    <li
                                        key={habitacion.id}
                                        className="relative mx-auto my-6 text-white w-72 xs:w-96 text-start h-52">
                                        <div className="absolute bottom-0 z-20 w-full rounded-lg h-1/3 bg-gradient-to-b from-transparent to-zinc-900"></div>
                                        <div className="absolute z-20 flex items-end justify-between w-full h-full bottom-3">
                                            <div className="flex flex-col w-3/4 mx-5">
                                                <div className="text-base font-bold text-white lg:text-lg 2xl:text-xl">
                                                    {habitacion?.habitacion}
                                                </div>

                                                <div className="mt-1 text-xs text-white lg:text-sm 2xl:text-base">
                                                    {habitacion?.descripcion}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute top-0 right-0 z-30 flex ">
                                            <button
                                                onClick={() =>
                                                    handleEdit(habitacion)
                                                }
                                                title="Editar"
                                                className="absolute top-0 right-10 p-1.5 w-8 h-8 border rounded text-blue-blueFrom border-blue-blueFrom group bg-blue-blueFrom hover:scale-110">
                                                <svg
                                                    id="Capa_2"
                                                    data-name="Capa 2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 99.97 100"
                                                    className="w-5 h-5"
                                                    fill="white">
                                                    <g id="Testimonios">
                                                        <g>
                                                            <path
                                                                fill="white"
                                                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                                                            />
                                                            <path
                                                                fill="white"
                                                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </button>

                                            <button
                                                className="absolute top-0 right-0 p-1.5 w-8 h-8  border rounded text-blue-blueFrom border-blue-blueFrom group bg-blue-blueFrom hover:scale-110"
                                                onClick={() =>
                                                    handleDelete(habitacion)
                                                }
                                                title="Eliminar">
                                                <svg
                                                    id="Capa_2"
                                                    data-name="Capa 2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 91.81 100"
                                                    className="w-5 h-5"
                                                    fill="white">
                                                    <g id="Testimonios">
                                                        <path d="m69.32,100H22.49c-.28-.09-.55-.19-.83-.27-5.22-1.49-8.55-4.86-9.37-10.22-.74-4.81-.65-9.72-.96-14.58-.26-3.99-.41-7.98-.63-11.97-.43-7.56-.88-15.11-1.32-22.67-.27-4.74-.54-9.47-.78-14.21-.04-.73-.35-.96-1.03-.94-1.2.03-2.41.04-3.61,0-2.56-.11-4.44-2.66-3.83-5.15.52-2.1,2.14-3.35,4.44-3.36,5.04-.01,10.08-.02,15.12,0,.89,0,1.24-.28,1.19-1.18-.06-1-.02-2.02-.01-3.02C20.88,5.58,26.42.03,33.28.02c8.39-.01,16.78-.04,25.17,0,5.22.03,8.92,2.59,11.31,7.15,1.24,2.37,1.24,4.94,1.2,7.52-.04,1.92,0,1.92,1.88,1.92,4.88,0,9.76-.02,14.63.01,2.49.02,4.38,1.96,4.34,4.34-.04,2.37-1.91,4.15-4.38,4.17-.81,0-1.63,0-2.44,0-1.82,0-1.75-.08-1.8,1.65-.02.49-.11.97-.13,1.46-.33,5.84-.65,11.68-.97,17.52-.39,7.1-.78,14.21-1.17,21.31-.39,7.04-.78,14.08-1.17,21.12-.2,3.61-1.72,6.55-4.42,8.92-1.73,1.52-3.81,2.33-6,2.88ZM45.9,16.62c5.13,0,10.27-.01,15.4.01.84,0,1.18-.27,1.14-1.13-.05-1,0-2.02-.02-3.02-.07-2.18-1.75-3.91-3.93-3.92-8.38-.03-16.77-.03-25.15,0-2.19,0-3.87,1.73-3.94,3.91-.03.94-.01,1.88,0,2.83,0,1.29.03,1.32,1.31,1.32,5.07,0,10.14,0,15.21,0Zm4.04,41.72c0-4,0-8,0-12,0-.45,0-.92-.11-1.35-.49-2.09-2.32-3.32-4.55-3.07-1.9.21-3.4,1.94-3.41,4.01-.01,8.19-.01,16.39,0,24.58,0,2.58,1.75,4.46,4.07,4.42,2.32-.04,3.99-1.86,4-4.4,0-4.06,0-8.13,0-12.19Zm16.61.07s0,0,0,0c0-4.26.03-8.52-.01-12.78-.02-1.92-1.53-3.52-3.36-3.72-2.16-.23-3.98.93-4.5,2.89-.16.6-.21,1.21-.21,1.83,0,4.62,0,9.23,0,13.85,0,3.35-.03,6.7,0,10.05.03,2.96,2.41,4.91,5.09,4.22,1.77-.45,2.96-1.94,2.97-3.86.03-4.16,0-8.32,0-12.48Zm-33.21.01c0-3.93,0-7.87,0-11.8,0-.62-.06-1.24-.22-1.83-.52-1.93-2.27-3.07-4.41-2.88-1.76.16-3.41,1.68-3.42,3.45-.05,8.68-.06,17.36,0,26.04.02,2.19,2.43,3.87,4.58,3.44,2.19-.44,3.43-2,3.45-4.42.02-4,0-8,0-12Z" />
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>

                                        <img
                                            src={`${process.env.REACT_APP_PROXY}/casa_habitaciones/getImage/${habitacion.imagen}`}
                                            alt="imagen habitación"
                                            className="object-fill rounded-lg w-96 h-52"
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div className="w-2/3 mx-auto">
                    <ButtonSiguiente onSubmit={() => handleSubmit()} />
                </div>
            </div>
            <ModalAgregarHab
                modalVisible={modalCrearVisible}
                setModalVisible={setModalCrearVisible}
                setRefresh={setRefresh}
            />
            <ModalEditHab
                setHabitacionSelected={setHabitacionSelected}
                habitacionSelected={habitacionSelected}
                modalVisible={modalEditVisible}
                setModalVisible={setModalEditVisible}
                setRefresh={setRefresh}
            />
        </div>
    );
};
