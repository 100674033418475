import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { NavbarsAvatar } from "../../../../components/NavbarsAvatar";
import { ShowDateToUser } from "../../../../components/ShowDateToUser";
import addDaysToDate from "../../../../helpers/AddDaysToDate";
import { fetchAxiosToken } from "../../../../helpers/fetchAxiosToken";

import { async } from "q";
import { useDispatch } from "react-redux";
import { fetchAxiosNoToken } from "../../../../helpers/fetchAxiosNoToken";
import { set_landing_house } from "../../../../redux/reducers/landingReducer";
import { useAppSelector } from "../../../../hooks/hooks";
import { ModalLogin } from "../../../ModalLogin";

interface serviceType {
    id: number;
    nombre: string;
    opcional: string;
    precio_final: number;
}

interface PedidoAdicional {
    id: number;
    id_pedido: number;
    servicio: string;
    costo: string;
}

interface Pedido {
    id: number;
    costo_alquiler: string;
    costo_total: string;
    pedidos_servicios_adicionales: PedidoAdicional[];
    costo_servicios_adicionales: string;
    descuento_estadia: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    pagado: string;
    saldo: string;
    casa: Casa;
}
export interface Casa {
    nombre: string;
    banner: string;
    Propietario: Propietario;
}

export interface Propietario {
    nombre: string;
    apellido: string;
    correo: string;
    avatar: string;
    banco: string;
    tipo_cuenta: string;
    tipo_moneda: string;
    titular_cuenta: string;
    tipo_documento: string;
    numero_documento: string;
    numero_cuenta: string;
    cci: string;
}

export const CheckoutConfirmarPropuesta = () => {
    const dispatch = useDispatch();

    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const reservaString = localStorage.getItem("reserva");
    const reserva = reservaString ? JSON.parse(reservaString) : "";
    const [modalVisibleLogin, setModalVisibleLogin] = useState(false);
    const [pagoUnico, setPagoUnico] = useState(true);
    const [comprobantePreview, setComprobantePreview] = useState<string | null>(
        null
    );
    const { house } = useAppSelector((state) => state.landing_house);

    const [comprobante, setComprobante] = useState<File | null>(null);
    const { id_reserva } = useParams();
    const navigate = useNavigate();
    const [pedido, setPedido] = useState<Pedido | null>(null);
    const [enviando, setEnviando] = useState(false);
    const form = new FormData();

    const getHouseFromTenant = async () => {
        try {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/dominio`,
            });

            dispatch(set_landing_house(data));
        } catch (error) {
            console.log(error);
        }
    };

    console.log(pedido);

    useEffect(() => {
        fetchAxiosToken({
            url: `/pedidos/getOne/${id_reserva}`,
        }).then(setPedido);
        getHouseFromTenant();
    }, []);

    const handleComprobante = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        setComprobante(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setComprobantePreview(objectUrl);
    };

    const comprobarDisponibilidad = async () => {
        if (tipo_usuario === null) {
            setModalVisibleLogin(true);
        } else {
            const servicios_adicionales =
                pedido?.pedidos_servicios_adicionales.map(
                    (service: PedidoAdicional) => {
                        return {
                            servicio: service.servicio,
                            costo: service.costo,
                        };
                    }
                );

            // const one_day_off_final_date = new Date(
            //     reserva.dates_selected.final_date
            // );
            // const convert_final_date =
            //     one_day_off_final_date.toLocaleDateString("zh-Hans-CN");

            const fecha_inicial = pedido?.fecha_inicio;
            const fecha_final = pedido?.fecha_final;
            const reserva_final = {
                id_cliente: id,
                costo_alquiler: pedido?.costo_alquiler,
                descuento_estadia: pedido?.descuento_estadia,
                costo_servicios_adicionales:
                    pedido?.costo_servicios_adicionales,
                servicios_adicionales: servicios_adicionales,
                costo_total: pedido?.costo_total,
                fecha_inicio: fecha_inicial,
                fecha_final: fecha_final,
                tipo_moneda: pedido?.tipo_moneda,
            };

            console.log(reserva_final);

            try {
                const data = await fetchAxiosToken({
                    url: `/chat_mensajes/putMessagePedido/${id_reserva}`,
                    method: `put`,
                    body: {
                        id_cliente: id,
                    },
                });

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Tu pedido ha sido enviado!",
                    text: "Te avisaremos cuando la disponibilidad haya sido confirmada, gracias!",
                    icon: "success",
                });
                navigate("/");
            } catch (error) {
                console.log(error);
            }
        }
    };

    const sendPayment = async () => {
        if (!comprobante) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Debes adjuntar el comprobante",
                // text: "Te avisaremos cuando la disponibilidad haya sido confirmada, gracias!",
                icon: "warning",
            });
        } else {
            setEnviando(true);

            const data = await fetchAxiosToken({
                url: `/pedidos/putOferta/${id_reserva}`,
                method: `put`,
                body: form,
            });
            console.log(data);
            setEnviando(false);

            navigate("/comprobante-en-revicion");
        }
    };

    if (!pedido)
        return (
            <>
                <div className="fixed top-0 left-0 right-0 flex items-center justify-between p-3 font-medium text-center border shadow-md px-14 md:px-5">
                    <button
                        onClick={() => navigate("/")}
                        className="hover:underline">
                        Regresar al home
                    </button>
                    <NavbarsAvatar />
                </div>
                <div className="p-4 font-medium">Cargando pedido...</div>
            </>
        );

    return (
        <div>
            {modalVisibleLogin && (
                <ModalLogin
                    modalVisibleLogin={modalVisibleLogin}
                    setModalVisibleLogin={setModalVisibleLogin}
                />
            )}
            <div className="flex items-center justify-between p-3 font-medium text-center border shadow-md px-14 md:px-5">
                <button
                    onClick={() => navigate("/")}
                    className="hover:underline">
                    Regresar al home
                </button>
                <NavbarsAvatar />
            </div>
            <div className="flex items-center justify-center p-3 font-medium text-center px-14 md:px-5">
                <p className="mt-4 text-2xl uppercase sm:text-4xl">
                    Confirmar propuesta
                </p>
            </div>
            <div className="grid w-full gap-10 p-4 mx-auto mt-10 sm:grid-cols-2 max-w-7xl">
                <div className="w-full p-4 border-2 rounded-lg">
                    {/* <input type="radio"  checked={pagoUnico} onChange={()=>setPagoUnico(true)}></input> */}
                    <span className="font-semibold ">Pago unico:</span>
                    <p>Realiza el pago total de tu reserva.</p>
                    <hr className="my-5 border-t-2 border-gray-300" />
                    <div
                        className={
                            pedido.estado === "Pago Parcial" ? "hidden" : ""
                        }>
                        {/* <input type="radio" checked={!pagoUnico} onChange={()=>setPagoUnico(false)} ></input> */}
                        <span className="font-semibold ">
                            Pago en dos partes:
                        </span>

                        <p>
                            Realiza ahora el pago de la mitad del aquiler que
                            representa{" "}
                            <span className="font-medium">
                                {Number(pedido?.costo_alquiler) / 2}{" "}
                                {pedido.tipo_moneda}
                            </span>{" "}
                            y 7 días antes del check in (
                            <span className="font-medium">
                                {" "}
                                {pedido.fecha_inicio
                                    .split("-")
                                    .reverse()
                                    .join("-")}
                            </span>
                            ) realiza el pago pendiente junto con la garantía y
                            servicios adicionales.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col w-full p-4 border-2 rounded-lg">
                    <div className="text-lg ">
                        Tu pedido para
                        <span className="mx-1 font-medium">
                            {pedido.casa.nombre}.
                        </span>
                    </div>
                    <span>
                        Desde el{" "}
                        <span className="font-medium">
                            {" "}
                            {pedido.fecha_inicio.split("-").reverse().join("-")}
                        </span>
                        , hasta el{" "}
                        <span className="font-medium">
                            {" "}
                            {pedido.fecha_final.split("-").reverse().join("-")}
                        </span>
                        .
                    </span>
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between mt-4">
                            <span className="font-medium">Precio alquiler</span>
                            <span className="font-medium">
                                {pedido.costo_alquiler} {pedido.tipo_moneda}
                            </span>
                        </div>
                        <hr className="my-1" />
                        {Number(pedido.descuento_estadia) > 0 ? (
                            <>
                                <div className="flex justify-between">
                                    <span className="text-green-600">
                                        Descuento por larga estadía
                                    </span>
                                    <span className="font-medium text-green-600">
                                        - {pedido.descuento_estadia}{" "}
                                        {pedido.tipo_moneda}
                                    </span>
                                </div>
                                <hr className="my-1" />
                            </>
                        ) : null}
                        {pedido.pedidos_servicios_adicionales.length > 0 ? (
                            <>
                                <span className="font-medium">
                                    Servicios adicionales
                                </span>
                                {pedido.pedidos_servicios_adicionales.map(
                                    (servicio: any) => (
                                        <div
                                            key={servicio.id}
                                            className="flex justify-between">
                                            <span className="">
                                                {servicio.servicio}
                                            </span>
                                            <span className="font-medium">
                                                {servicio.costo}{" "}
                                                {pedido.tipo_moneda}
                                            </span>
                                        </div>
                                    )
                                )}
                                <hr className="my-1" />
                            </>
                        ) : null}
                    </div>
                    <div className="flex justify-between mt-4">
                        <span className="font-medium">
                            Precio total de la reserva
                        </span>
                        <span className="font-medium">
                            {pedido.costo_total} {pedido.tipo_moneda}
                        </span>
                    </div>
                </div>

                <div className="w-full p-4 border-2 rounded-lg">
                    {house?.plan > 1 ? (
                        <p>
                            Pago con tarjeta de crédito/débito cuenta con un
                            costo adicional del 5% del monto a pagar.
                        </p>
                    ) : null}
                    <button
                        onClick={() => comprobarDisponibilidad()}
                        className="flex justify-center w-full max-w-xs p-2 mx-auto mt-4 border-2 rounded-lg hover:bg-slate-100 hover:scale-105">
                        Comprobar disponibilidad
                    </button>
                </div>

                {/* <div className="w-full p-4 border-2 rounded-lg">
                    <p className="mt-5 text-base text-justify">
                                    Realiza tu pago directamente en nuestra
                                    cuenta bancaria. <span className="font-semibold">Por favor, usa el número
                                    del pedido como referencia de pago.</span>  Tu
                                    pedido no se procesará hasta que se haya
                                    recibido el importe en nuestra cuenta.
                                </p>
                                <p className="mt-3 font-semibold">Numero de pedido : {id_reserva}</p>
                                <div>
                                            <div className="flex flex-row">
                                                <p className="font-semibold">
                                                    Banco:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    {pedido.casa.Propietario.banco}
                                                </p>
                                            </div>
                                            <div className="flex flex-row">
                                                <p className="font-semibold">
                                                    Tipo de cuenta:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    {pedido.casa.Propietario.tipo_cuenta}
                                                   
                                                </p>
                                            </div>
                                            <div className="flex flex-row">
                                                <p className="font-semibold">
                                                    Cuenta en  {pedido.casa.Propietario.tipo_moneda}:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    {pedido.casa.Propietario.numero_cuenta}
                                                </p>
                                            </div>
                                            <div className="flex flex-row">
                                                <p className="font-semibold">
                                                    CCI:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    {pedido.casa.Propietario.cci}
                                                </p>
                                            </div>
                                            <div className="flex flex-row">
                                                <p className="font-semibold">
                                                    Titular de cuenta:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    {pedido.casa.Propietario.titular_cuenta}
                                                </p>
                                            </div>
                                        </div>

                                        <hr className="my-5 border-t-2 border-gray-300" />
                                <h1 className="mt-4 font-semibold">
                                    Por favor, adjuntar su comprobante en
                                    formato Imagen
                                </h1>

                                <div className="relative flex mx-auto mt-4 bg-white border-4 border-dashed rounded-lg w-72 h-72 ">
                                    {comprobantePreview ? (
                                        <div className="w-full h-full rounded-lg ">
                                            <img
                                                className="object-cover w-full h-full rounded-lg"
                                                src={comprobantePreview}
                                                alt="Comprobante de pago"
                                            />
                                        </div>
                                    ) : (
                                        <div className="flex items-center justify-center p-3 ">
                                            <div className="flex flex-col items-center justify-center w-full mx-auto ">
                                                <div className="relative flex flex-col items-center justify-center">
                                                    <span className="mt-2 font-bold text-center ">
                                                        Arrastre el comprobante
                                                    </span>
                                                    o
                                                    <span
                                                        className={`text-center text-blue-500  font-normal`}>
                                                        Sube la imagen desde tu
                                                        dispositivo
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        onChange={handleComprobante}
                                        accept="image/*"
                                        className="absolute top-0 left-0 bg-red-200 opacity-0 cursor-pointer w-72 h-72 file:cursor-pointer"
                                    />
                                </div>
                    <button
                    disabled={enviando}
                        onClick={() => sendPayment()}
                        className="flex justify-center w-full max-w-xs p-2 mx-auto mt-4 border-2 rounded-lg hover:bg-slate-100 hover:scale-105 disabled:bg-slate-100">
                        {enviando?"Enviando..":"Enviar Comprobante"}
                    </button>
                </div> */}
                {/* <div className="flex flex-col w-full p-4 border-2 rounded-lg h-60">
                    <div className="text-lg font-semibold ">
                        Resumen
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between mt-4">
                            <span className="font-medium">Pagado</span>
                            <span className="font-medium">
                               <span>{pedido.pagado}</span>{" "}
                                {pedido.tipo_moneda}
                            </span>
                        </div>
                        <hr className="my-1" />
                       
                    </div>
                   
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between mt-4">
                            <span className="font-medium">Monto a pagar </span>

                            {
                                pedido.estado === "Pago Parcial" ?
                                <span className="font-medium">
                                {pedido.saldo}{" "}
                                                                {pedido.tipo_moneda}

                            </span>
                            :

                            pagoUnico?<span className="font-medium">
                                {pedido.costo_total}{" "}
                                                                {pedido.tipo_moneda}

                            </span>: <span className="font-medium">
                                {(Number(pedido?.costo_alquiler)) / 2}{" "}
                                                                {pedido.tipo_moneda}

                            </span>
                            
                            }
                        </div>
                        <hr className="my-1" />
                       
                    </div>
                    <div className="flex justify-between mt-4">
                        <span className="font-medium">
                            Saldo pendiente
                        </span>
                        {pagoUnico?<span className="font-medium">
                            <span>{pedido.saldo}</span>{" "}
                                                            {pedido.tipo_moneda}

                        </span>:<span className="font-medium">
                             {Number(pedido.costo_total) - (Number(pedido?.costo_alquiler)) / 2}{" "}
                                                            {pedido.tipo_moneda}

                        </span>}
                    </div>
                </div> */}
            </div>
        </div>
    );
};
