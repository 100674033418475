import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { ModalPerdido } from "./ModalPerdido";

interface Props {
    setEstado: (s: Estado | null) => void;
    estado: Estado;
}

interface Estado {
    nombre: string;
    id: number;
}

const CRMModalEditEstado = ({
    setEstado,

    estado,
}: Props) => {
    const [nuevoEstado, setNuevoEstado] = useState(estado.nombre);
    const [modaPerdido, setModalPerdido] = useState(0);
    let proceso = [
        "Primer seguimiento",
        "Segundo seguimiento",
        "Propuesta enviada",
        "Negociación",
        "Ganados",
        "Perdidos",
        "Seguimiento a futuro",
    ];

    const handleSubmit = async () => {
        if (estado.nombre === nuevoEstado) {
            setEstado(null);
        } else if ("Perdidos" === nuevoEstado) {
            setModalPerdido(Number(estado.id));
            // setEstado(null);
        } else {
            const json = await fetchAxiosToken({
                url: `/crm/updateProcess/${estado.id}}`,
                method: "put",
                body: {
                    proceso: nuevoEstado,
                    motivo_perdida: "",
                },
            });
            console.log(json);
            setEstado(null);
        }
    };

    return (
        <div className={`relative items-center justify-center z-[999]`}>
            {modaPerdido ? (
                <ModalPerdido
                    modalVisibleNota={modaPerdido}
                    setModalVisibleNota={setModalPerdido}
                    setEstado={setEstado}
                />
            ) : null}
            <div
                className={`${"opacity-100 top-0 left-0"} delay-200 transition-opacity ease-out duration-500 fixed flex w-full h-full p-2 xs:p-6 bg-gray-900 bg-opacity-80`}
            >
                <div className="flex items-center justify-center w-full px-1 py-3">
                    <div className="relative flex flex-col items-center justify-center px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 ">
                        <header className="mt-5 mb-3 mr-auto text-base font-bold text-center lg:text-2xl">
                            ¿Quieres cambiar el estado de tu cliente?
                        </header>
                        <label className="w-full my-4 text-base text-left border-none outline-none ">
                            Selecciona el estado
                        </label>
                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                            <select
                                onChange={(e) => setNuevoEstado(e.target.value)}
                                defaultValue={nuevoEstado}
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C]"
                            >
                                {proceso.map((proceso) => {
                                    return (
                                        <option
                                            key={proceso}
                                            className="text-[#3C3C3C]"
                                            value={proceso}
                                        >
                                            {proceso}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div className="w-full px-4">
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="pb-3 text-center ">
                                            <button
                                                className="w-full rounded-2xl border-b-4 border-b-blue-blueFrom bg-blue-blueFrom py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10"
                                                onClick={() => handleSubmit()}
                                            >
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => setEstado(null)}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CRMModalEditEstado;
