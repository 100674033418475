import React, { useState } from "react";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import imagen1 from "../../img/landing_comprimidas/imagen-landing-1.webp";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import logo from "../../img/logoPNGalerta.png";
const Content = () => {
    const { tags } = useParams();

    const [input, setInput] = useState({
        nombre: "",
        apellido: "",
        celular: "",
        correo: "",
        mensaje: "",
        etiqueta: tags ? tags : "6",
    });
    const [saving, setSaving] = useState<boolean>(false);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setInput({
            ...input,
            [event.target.name]: event.target.value,
        });
    };
    console.log({ tags });
    const handleSubmit = async () => {
        if (
            !input.nombre ||
            !input.apellido ||
            !input.celular ||
            !input.correo
        ) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Campos incompletos",
                text: "Por favor, completa todos los campos.",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
            return;
        }
        console.log(input);
        try {
            setSaving(true);
            const data = await fetchAxiosNoToken({
                url: `/home/postPdf`,
                method: "post",
                body: input,
            });
            setInput({
                nombre: "",
                apellido: "",
                celular: "",
                correo: "",
                mensaje: "",
                etiqueta: tags ? tags : "6",
            });
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "¡Mensaje enviado!",
                text: "En breve nos pondremos en contacto",
                // icon: "success",
                imageUrl: `${logo}`,
                imageWidth: 200,
                imageHeight: 200,
                confirmButtonText: "Aceptar",
            });

            setSaving(false);
        } catch (error) {
            console.log(error);
            setSaving(true);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center  w-full md:px-5 md:pt-4 text-white md:flex bg-[#1C4CBB] pb-10">
            <div className="z-30 flex flex-col items-start justify-between w-5/6 gap-8 mx-auto xl:w-4/5 2xl:w-3/4 lg:flex-row">
                <div className="w-full mt-2 lg:mt-10 lg:w-1/2 lg:px-6">
                    <div className="md:text-2xl text-xl font-bold text-center text-white lg:text-3xl 2xl:text-4xl md:text-left">
                        ¿Eres propietario de una casa o alojamiento y deseas
                        potenciar tu propiedad?
                    </div>
                    <div className="2xl:mt-10 lg:mt-8 mt-5  text-justify text-white text-sm md:text-base 2xl:text-lg">
                        ¡Blustay está aquí para ayudarte! Te ofrecemos una
                        valiosa guía gratuita que te mostrará paso a paso cómo
                        crear un{" "}
                        <span className="font-bold">
                            Plan de Marketing para Propietarios
                        </span>{" "}
                        efectivo que maximice tus ingresos y aumente la
                        visibilidad de tu propiedad. En Blustay, entendemos las
                        necesidades de los propietarios y hemos desarrollado un
                        software de administración de propiedades excepcional
                        para simplificar tu trabajo. ¡No pierdas la oportunidad
                        de llevar tu negocio al siguiente nivel!
                    </div>
                </div>
                <div className="items-start justify-center mx-auto lg:justify-end">
                    <div className=" flex-col justify-center items-center bg-white rounded-lg 2xl:p-4 p-3 md:px-10 text-black w-[300px] md:w-[400px] xl:w-[500px] ">
                        <input
                            value={input.nombre}
                            onChange={handleInputChange}
                            type="text"
                            name="nombre"
                            className=" p-1.5 lg:p-2 2xl:p-3 rounded-lg placeholder-[#029bfc] placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-3 border w-full mt-2.5 md:mt-4 2xl:mt-8"
                            placeholder="Nombres"
                        />

                        <input
                            value={input.apellido}
                            onChange={handleInputChange}
                            type="text"
                            name="apellido"
                            className=" p-1.5 lg:p-2 2xl:p-3 rounded-lg placeholder-[#029bfc] placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-3 border w-full  mt-2.5 md:mt-4  2xl:mt-8"
                            placeholder="Apellidos"
                        />
                        <input
                            value={input.correo}
                            onChange={handleInputChange}
                            type="text"
                            name="correo"
                            className=" p-1.5 lg:p-2 2xl:p-3 rounded-lg placeholder-[#029bfc] placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-3 border w-full  mt-2.5 md:mt-4  2xl:mt-8"
                            placeholder="Correo electronico"
                        />

                        <input
                            value={input.celular}
                            onChange={handleInputChange}
                            type="number"
                            name="celular"
                            className=" p-1.5 lg:p-2 2xl:p-3 rounded-lg placeholder-[#029bfc] placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-3 border w-full  mt-2.5 md:mt-4  2xl:mt-8"
                            placeholder="Celular"
                        />
                        <textarea
                            value={input.mensaje}
                            onChange={handleInputChange}
                            name="mensaje"
                            className=" p-1.5 lg:p-2 2xl:p-3 rounded-lg placeholder-[#029bfc] placeholder:text-base 2xl:placeholder:text-lg placeholder:px-2 md:placeholder:px-3 border w-full  mt-2.5 md:mt-4  2xl:mt-8"
                            placeholder="Mensaje (opcional)..."
                        />

                        <div className="flex items-center justify-center w-full mt-5 mb-5 lg:mb-8 2xl:mb-10 md:mt-7 2xl:mt-10 md:px-5">
                            <button
                                onClick={() => handleSubmit()}
                                disabled={saving}
                                className="bg-[#029bfc] text-white text-base lg:text-lg 2xl:text-xl items-center justify-center  rounded-lg py-2 md:py-3 font-bold px-6 md:px-8 w-full md:w-auto hover:scale-105 disabled:cursor-not-allowed"
                            >
                                {" "}
                                {saving
                                    ? "Enviando.."
                                    : "Recibir guía gratuita"}{" "}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative z-20 w-full mt-10 lg:mt-0 xl:-mt-16 2xl:-mt-32 xl:w-4/5 2xl:w-3/4">
                <img
                    src={imagen1}
                    alt="img impact"
                    className="md:rounded-2xl md:h-[30rem] 2xl:h-[35rem] h-[20rem] w-full object-cover"
                />
                <div className="absolute top-0 w-full h-full bg-black opacity-50 md:rounded-2xl"></div>
                <div className="absolute flex flex-col top-10 md:top-20 md:left-6 left-10">
                    <div className="text-xl font-semibold text-white xs:w-1/2 md:text-2xl 2xl:text-3xl">
                        No pagues más comisiones de tus ventas a intermediarios,
                        gestiona tus reservas directamente desde nuestro
                        software.
                    </div>

                    {/* 
                    <div className="mt-10 text-xl font-semibold text-white md:text-2xl 2xl:text-3xl">
                        Hemos trabajado con
                    </div>
                    <div className="text-4xl font-bold text-white md:text-5xl 2xl:text-6xl ">
                        135 Propietarios
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Content;
