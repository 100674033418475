import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { verifyToken } from "../../../redux/reducers/authReducer";
import { getHouse } from "../../../redux/reducers/onboardingReducer";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { ModalNewCoupon } from "./ModalNewCoupon";
import { ModalEditCoupon } from "./ModalEditCoupon";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { useParams } from "react-router-dom";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";

interface Cupon {
    descuento: string;
    fecha_expiracion: string;
    id: number;
    id_casa: number;
    usado: string;
    cupon: string;
}

export const Coupon = () => {
    const {
        id: id_casa,
        tipo_moneda,
        estadia_maxima,
    } = useAppSelector((state) => state.landing_house.house);
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [modalVisibleEdit, setModalVisibleEdit] = useState<boolean>(false);

    const [cuponesAll, setCuponesAll] = useState<Cupon[]>([]);
    const [cuponSelect, setCuponSelect] = useState<Cupon | null>();

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);

    const putMaximaBase = async () => {
        setLoading(true);
        try {
            const data = await fetchAxiosToken({
                url: `/descuento_cupones/getAll/${id_casa}`,
                method: "get",
            });

            setCuponesAll(data);

            setLoading(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const handleDelete = async (id: number) => {
        setLoading(true);

        try {
            const data = await fetchAxiosToken({
                url: `/descuento_cupones/delete/${id}`,
                method: "delete",
            });
            putMaximaBase();

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Eliminado con exito",

                icon: "success",
                confirmButtonText: "Ok",
            });
            setLoading(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        if (id_casa) {
            putMaximaBase();
        }
    }, [id_casa, modalVisible, modalVisibleEdit]);

    const render = () => {
        return (
            <div className="flex">
                {modalVisible && (
                    <ModalNewCoupon
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                    />
                )}
                {modalVisibleEdit && cuponSelect && (
                    <ModalEditCoupon
                        modalVisible={modalVisibleEdit}
                        setModalVisible={setModalVisibleEdit}
                        cuponSelect={cuponSelect}
                        setCuponSelect={setCuponSelect}
                    />
                )}

                <div className="flex flex-col items-center justify-end w-full sm:mb-0">
                    <div className="grid w-11/12 gap-4 mx-auto mt-12 max-w-7xl lg:grid-cols-2 right-2">
                        <div className="  text-[#4D4D4D]">
                            <ButtonRegresar
                                // url={`/dashboard/onboarding/alojamiento`}
                                className="-ml-2 "
                                arrowColor="black"
                                textColor="black"
                            />
                            <p className="text-xl font-bold 2xl:text-2xl ">
                                Cupones de descuento
                            </p>
                            <p className="mt-6 text-sm 2xl:text-base">
                                Podrás ofrecer un descuento especial y ofertas
                                exclusivas por alquiler.
                            </p>
                        </div>
                        <div className="w-full p-4  border border-[#CCCCCC] rounded-lg shadow-xl mb-6">
                            <div>
                                <button
                                    onClick={() => setModalVisible(true)}
                                    className="flex items-center gap-1 p-2 border rounded-lg bg-[#E6E6E6] hover:scale-105 ">
                                    <svg
                                        className="h-5 w-5 mt-0.5 text-[#4D4D4D]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    <span className="text-[#4D4D4D] text-sm">
                                        Nuevo cupón
                                    </span>
                                </button>
                            </div>
                            {cuponesAll.length > 0 ? (
                                <div className="flex flex-col w-full gap-4 mt-4">
                                    {cuponesAll.map((cupon) => (
                                        <div
                                            key={cupon.id}
                                            className="flex justify-between w-full p-3 text-sm border rounded-lg 2xl:text-base">
                                            <div className="flex flex-col">
                                                <span className=" font-medium text-[#4D4D4D] 2xl:text-base text-sm">
                                                    <>
                                                        <span> Cupon </span>
                                                        <span className="font-bold">
                                                            {" "}
                                                            {cupon.cupon}{" "}
                                                        </span>
                                                        <span> expira el </span>

                                                        <span>
                                                            {
                                                                <ShowDateToUser
                                                                    date={
                                                                        cupon.fecha_expiracion
                                                                    }
                                                                />
                                                            }
                                                        </span>
                                                    </>
                                                </span>
                                                <span className="text-sm italic text-gray-500 2xl:text-base">
                                                    descuento de{" "}
                                                    {cupon.descuento}{" "}
                                                    {tipo_moneda}
                                                </span>
                                            </div>
                                            <div className="flex items-center gap-3">
                                                <button
                                                    className="rounded-lg bg-[#E6E6E6] p-2 hover:scale-105"
                                                    onClick={() => (
                                                        setCuponSelect(cupon),
                                                        setModalVisibleEdit(
                                                            true
                                                        )
                                                    )}
                                                    title="Editar cupon">
                                                    <svg
                                                        className="w-4 h-4"
                                                        id="Capa_2"
                                                        data-name="Capa 2"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 100 99.97">
                                                        <g id="Testimonios">
                                                            <g>
                                                                <path
                                                                    fill="#4D4D4D"
                                                                    d="m.01,95.86c1.03-2.98,1.79-6.04,2.66-9.06,1.65-5.68,3.25-11.36,4.88-17.05.35-1.21.41-1.22,1.3-.33,7.26,7.26,14.53,14.52,21.79,21.79.86.86.85.88-.32,1.21-8.47,2.41-16.93,4.82-25.39,7.23-.28.08-.54.21-.82.31-1.24,0-2.47-.02-3.71.01-.34,0-.41-.06-.4-.4.03-1.23.01-2.47.01-3.71Z"
                                                                />
                                                                <path
                                                                    fill="#4D4D4D"
                                                                    d="m36.11,88.77c-.31-.02-.47-.25-.65-.43-7.95-7.95-15.9-15.91-23.87-23.84-.72-.72-.2-1.05.23-1.48,8.48-8.48,16.96-16.96,25.44-25.44,7.95-7.95,15.9-15.9,23.86-23.85q.95-.95,1.88-.02c7.77,7.77,15.53,15.55,23.32,23.29.73.73.64,1.1-.03,1.77-16.45,16.42-32.87,32.86-49.3,49.29-.27.27-.49.61-.89.71Z"
                                                                />
                                                                <path
                                                                    fill="#4D4D4D"
                                                                    d="m78.52,0c2.53-.02,4.61.81,6.3,2.48,4.23,4.17,8.46,8.36,12.61,12.61,3.32,3.4,3.45,8.57.29,12.11-1.94,2.18-4.09,4.17-6.13,6.27-.61.63-.95.17-1.34-.22-4.55-4.55-9.1-9.1-13.65-13.65-3.29-3.29-6.56-6.58-9.87-9.84-.63-.62-.66-1.02,0-1.64,1.85-1.78,3.63-3.62,5.46-5.43C73.97.92,76.1-.04,78.52,0Z"
                                                                />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </button>

                                                <button
                                                    className="rounded-lg bg-[#E6E6E6] p-2 hover:scale-105"
                                                    onClick={() =>
                                                        handleDelete(cupon.id)
                                                    }
                                                    title="Eliminar cupon">
                                                    <svg
                                                        className="w-4 h-4  text-[#4D4D4D]"
                                                        id="Capa_2"
                                                        data-name="Capa 2"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 91.81 100">
                                                        <g id="Testimonios">
                                                            <path
                                                                fill="#4D4D4D"
                                                                d="m69.32,100H22.49c-.28-.09-.55-.19-.83-.27-5.22-1.49-8.55-4.86-9.37-10.22-.74-4.81-.65-9.72-.96-14.58-.26-3.99-.41-7.98-.63-11.97-.43-7.56-.88-15.11-1.32-22.67-.27-4.74-.54-9.47-.78-14.21-.04-.73-.35-.96-1.03-.94-1.2.03-2.41.04-3.61,0-2.56-.11-4.44-2.66-3.83-5.15.52-2.1,2.14-3.35,4.44-3.36,5.04-.01,10.08-.02,15.12,0,.89,0,1.24-.28,1.19-1.18-.06-1-.02-2.02-.01-3.02C20.88,5.58,26.42.03,33.28.02c8.39-.01,16.78-.04,25.17,0,5.22.03,8.92,2.59,11.31,7.15,1.24,2.37,1.24,4.94,1.2,7.52-.04,1.92,0,1.92,1.88,1.92,4.88,0,9.76-.02,14.63.01,2.49.02,4.38,1.96,4.34,4.34-.04,2.37-1.91,4.15-4.38,4.17-.81,0-1.63,0-2.44,0-1.82,0-1.75-.08-1.8,1.65-.02.49-.11.97-.13,1.46-.33,5.84-.65,11.68-.97,17.52-.39,7.1-.78,14.21-1.17,21.31-.39,7.04-.78,14.08-1.17,21.12-.2,3.61-1.72,6.55-4.42,8.92-1.73,1.52-3.81,2.33-6,2.88ZM45.9,16.62c5.13,0,10.27-.01,15.4.01.84,0,1.18-.27,1.14-1.13-.05-1,0-2.02-.02-3.02-.07-2.18-1.75-3.91-3.93-3.92-8.38-.03-16.77-.03-25.15,0-2.19,0-3.87,1.73-3.94,3.91-.03.94-.01,1.88,0,2.83,0,1.29.03,1.32,1.31,1.32,5.07,0,10.14,0,15.21,0Zm4.04,41.72c0-4,0-8,0-12,0-.45,0-.92-.11-1.35-.49-2.09-2.32-3.32-4.55-3.07-1.9.21-3.4,1.94-3.41,4.01-.01,8.19-.01,16.39,0,24.58,0,2.58,1.75,4.46,4.07,4.42,2.32-.04,3.99-1.86,4-4.4,0-4.06,0-8.13,0-12.19Zm16.61.07s0,0,0,0c0-4.26.03-8.52-.01-12.78-.02-1.92-1.53-3.52-3.36-3.72-2.16-.23-3.98.93-4.5,2.89-.16.6-.21,1.21-.21,1.83,0,4.62,0,9.23,0,13.85,0,3.35-.03,6.7,0,10.05.03,2.96,2.41,4.91,5.09,4.22,1.77-.45,2.96-1.94,2.97-3.86.03-4.16,0-8.32,0-12.48Zm-33.21.01c0-3.93,0-7.87,0-11.8,0-.62-.06-1.24-.22-1.83-.52-1.93-2.27-3.07-4.41-2.88-1.76.16-3.41,1.68-3.42,3.45-.05,8.68-.06,17.36,0,26.04.02,2.19,2.43,3.87,4.58,3.44,2.19-.44,3.43-2,3.45-4.42.02-4,0-8,0-12Z"
                                                            />
                                                        </g>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="flex flex-col items-center h-full mt-8">
                                    Aún no tienes cupones
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return tipo_usuario === 3 || tipo_usuario === 4 ? (
        <Navbar titulo="Cupones">
            {render()}
            <NavbarMobile />
        </Navbar>
    ) : tipo_usuario === 6 ? (
        <NavbarBroker titulo="Cupones">
            {render()}
            <NavbarMobileBroker />
        </NavbarBroker>
    ) : null;
};
