import React, { useState } from "react";
import { Footer } from "../../Footer";

import MainNavbar from "../../MainNavbar/MainNavbar";
import { Planes } from "./Planes";
import { Funcionalidades } from "./Funcionalidades";
import Rendimiento from "../Rendimiento";

export const PlanHome = () => {
    return (
        <div className="overflow-hidden bg-[#ffffff]">
            <MainNavbar />

            <div id="Planes">
                <Planes />
            </div>

            <div id="Funcionalidades">
                <Funcionalidades />
            </div>
            <div id="Rendimiento">
                <Rendimiento />
            </div>

            <Footer />
        </div>
    );
};
