import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import useHeaders from "../../../hooks/useHeaders";

interface comfort {
    id: number;
    nombre: string;
    habilitado: string;
    fecha_registro: string;
}
interface props {
    etiquetasBlogEdit: comfort;
    setEtiquetasBlogEdit: (cerrar: undefined) => void;
}

const ModalEditEtiquetaBlog = ({
    etiquetasBlogEdit,
    setEtiquetasBlogEdit,
}: props) => {
    const [cargando, SetCargando] = useState(false);
    const { headers } = useHeaders();

    const [input, setInput] = useState<comfort>(etiquetasBlogEdit);

    const handleSubmit = async () => {
        if (!input.nombre) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error!",
                text: "La Etiqueta debe tener un nombre",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else {
            SetCargando(true);

            try {
                const { data } = await axios.put(
                    `${process.env.REACT_APP_PROXY}/etiquetas_blogs/putEtiquetaBlog/${input.id}`,
                    input,
                    { headers }
                );
                console.log(data);
                if (data.message === "actualizacion exitoso") {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Etiqueta guardada con exito!",
                        showConfirmButton: false,
                        timer: 2000,
                    });

                    SetCargando(false);
                    setEtiquetasBlogEdit(undefined);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto sm:py-48">
                    <div className="relative flex flex-col items-center px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 ">
                        <div className="w-full my-3 text-left">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base md:text-xl font-bold ">
                                Editar Etiqueta del Blog
                            </header>
                        </div>

                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                            <input
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        nombre: e.target.value,
                                    })
                                }
                                type="text"
                                value={input?.nombre}
                                placeholder="Ej: Horno de barro"
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none placeholder:text-[#3C3C3C]"
                            />
                        </div>

                        <div className="pb-3 text-center mt-4">
                            <button
                                onClick={() => handleSubmit()}
                                disabled={cargando}
                                className="w-full p-3 text-center text-white border border-blue-400 rounded-md bg-blue-blueFrom hover:bg-white hover:text-blue-blueFrom disabled:bg-blue-400">
                                {cargando ? "Guardando..." : "Guardar"}
                            </button>
                        </div>

                        <button
                            onClick={() => setEtiquetasBlogEdit(undefined)}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditEtiquetaBlog;
