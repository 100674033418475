import React, { useState } from "react";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import Swal from "sweetalert2";
import { useParams, useNavigate } from "react-router-dom";

const Content = () => {
    const { tags } = useParams();
    const navigate = useNavigate();

    const [input, setInput] = useState({
        nombre: "",
        apellido: "",
        celular: "",
        correo: "",
        mensaje: "",
        etiqueta: tags ? tags : "6",
    });
    const [saving, setSaving] = useState<boolean>(false);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setInput({
            ...input,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async () => {
        if (
            !input.nombre ||
            !input.apellido ||
            !input.celular ||
            !input.correo
        ) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Campos incompletos",
                text: "Por favor, completa todos los campos.",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
            return;
        }

        try {
            setSaving(true);
            const data = await fetchAxiosNoToken({
                url: `/home/watchDemo`,
                method: "post",
                body: input,
            });
            setSaving(false);
            navigate(`/watchDemoThanks/`);
        } catch (error) {
            console.log(error);
            setSaving(true);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center  w-full md:px-5 md:pt-4 text-white md:flex bg-[#1C4CBB] pb-10">
            <div className="z-30 flex flex-col items-start justify-between w-5/6 gap-8 mx-auto xl:w-4/5 2xl:w-3/4 lg:flex-row">
                <div className="w-full mt-2 lg:mt-10 lg:w-1/2 lg:px-6">
                    <div className="text-2xl font-bold text-center text-white md:text-3xl 2xl:text-4xl md:text-left">
                        Eleva la gestión de tus propiedades a un nuevo nivel con
                        BluStay
                    </div>
                    <div className="mt-10 text-base text-justify text-white md:text-lg">
                        ¡Bienvenido a Blustay, donde la gestión de propiedades
                        se convierte en una experiencia excepcional! ¿Quieres
                        descubrir cómo nuestro software puede simplificar y
                        potenciar la administración de tus bienes raíces?
                        <div className="mt-3">
                            Completa el breve formulario a continuación y accede
                            a una demostración exclusiva. En este video, te
                            sumergirás en un mundo de funcionalidades avanzadas
                            diseñadas para hacer que la gestión de propiedades
                            sea más eficiente y efectiva que nunca.
                        </div>
                    </div>
                </div>
                <div className="items-start justify-center mx-auto lg:justify-end">
                    <div className=" flex-col justify-center items-center bg-white rounded-lg p-3 md:px-10 text-black w-[300px] md:w-[400px] xl:w-[500px] ">
                        <input
                            value={input.nombre}
                            onChange={handleInputChange}
                            type="text"
                            name="nombre"
                            className=" p-2 rounded-lg placeholder-[#029bfc] placeholder:text-base md:placeholder:text-xl placeholder:px-2 md:placeholder:px-3 border w-full mt-5 md:mt-7"
                            placeholder="Nombres"
                        />

                        <input
                            value={input.apellido}
                            onChange={handleInputChange}
                            type="text"
                            name="apellido"
                            className=" p-2 rounded-lg placeholder-[#029bfc] placeholder:text-base md:placeholder:text-xl placeholder:px-2 md:placeholder:px-3 border w-full  mt-5 md:mt-7"
                            placeholder="Apellidos"
                        />
                        <input
                            value={input.correo}
                            onChange={handleInputChange}
                            type="text"
                            name="correo"
                            className=" p-2 rounded-lg placeholder-[#029bfc] placeholder:text-base md:placeholder:text-xl placeholder:px-2 md:placeholder:px-3 border w-full  mt-5 md:mt-7"
                            placeholder="Correo electronico"
                        />

                        <input
                            value={input.celular}
                            onChange={handleInputChange}
                            type="number"
                            name="celular"
                            className=" p-2 rounded-lg placeholder-[#029bfc] placeholder:text-base md:placeholder:text-xl placeholder:px-2 md:placeholder:px-3 border w-full  mt-5 md:mt-7"
                            placeholder="Celular"
                        />
                        <textarea
                            value={input.mensaje}
                            onChange={handleInputChange}
                            name="mensaje"
                            className=" p-2 rounded-lg placeholder-[#029bfc] placeholder:text-base md:placeholder:text-xl placeholder:px-2 md:placeholder:px-3 border w-full  mt-5 md:mt-7"
                            placeholder="Mensaje (opcional)..."
                        />

                        <div className="flex items-center justify-center w-full mt-5 mb-10   md:px-5">
                            <button
                                onClick={() => handleSubmit()}
                                disabled={saving}
                                className="bg-[#029bfc] text-white text-lg items-center justify-center md:text-xl rounded-lg py-2 font-bold px-6 md:px-8 w-full md:w-auto hover:scale-105 disabled:cursor-not-allowed">
                                {" "}
                                {saving ? "Enviando.." : "Ver Demo"}{" "}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative mt-10 z-20 w-full xl:w-4/5 2xl:w-3/4  bg-[#029BFC] rounded-xl">
                <div className="flex flex-col justify-center items-center py-10 mb-5">
                    <div className="text-2xl font-semibold text-white xs:w-1/2 md:text-3xl 2xl:text-4xl text-center">
                        Blustay
                    </div>
                    <div className="text-sm text-white xs:w-1/2 md:text-base 2xl:text-lg text-center">
                        Reservas fáciles, ganancias aseguradas
                    </div>

                    <div className="text-lg  text-white xs:w-2/3 md:text-xl 2xl:text-2xl text-center mt-8">
                        No pagues más comisiones de tus ventas a intermediarios,
                        gestiona tus reservas directamente desde tu página web
                        generada por BluStay
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Content;
