import { Footer } from "../../components/Footer";
import ThanksContent from "./ThanksContent";
import Navbar from "./Navbar";

const WatchDemoThanks = () => {
    return (
        <div>
            <Navbar />
            <ThanksContent />
            <Footer />
        </div>
    );
};

export default WatchDemoThanks;
