import React, { useState } from "react";
import axios from "axios";

import Swal from "sweetalert2";
import useHeaders from "../../../hooks/useHeaders";

interface createComfort {
    create: boolean;
    setcreate: (cerrar: boolean) => void;
}
interface OutputImage extends HTMLImageElement {
    src: string;
}

const ModalCreatename = ({ create, setcreate }: createComfort) => {
    const [cargando, SetCargando] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [name, setname] = useState<string>("");
    const { headers } = useHeaders();

    const handleSubmit = async () => {
        console.log({ name, selectedFile });
        if (name === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error!",
                text: "la comodidad debe tener un nombre",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else if (!selectedFile) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error!",
                text: "la comodidad debe tener un icono",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else {
            SetCargando(true);
            const form = new FormData();

            form.append("nombre", name);
            form.append("comodidad_image", selectedFile);

            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/comodidad/`,
                    form,
                    { headers }
                );
                console.log(data);
                if (data.message === "registro exitoso") {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Comodidad guardada con exito!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    setSelectedFile(undefined);
                    setname("");
                    SetCargando(false);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const output = document.getElementById("output") as OutputImage;
            if (output) {
                output.src = URL.createObjectURL(e.target.files[0]);
                output.onload = function () {
                    URL.revokeObjectURL(output.src);
                };
            }
            // loadFile(e);
            setSelectedFile(e.target.files[0]);
        }
    };

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto sm:py-48">
                    <div className="relative flex flex-col items-center px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 ">
                        <div className="w-full my-3 text-left">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base md:text-xl font-bold ">
                                Agregar comodidad
                            </header>
                        </div>
                        <div className="w-full my-1 text-left">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base md:text-xl">
                                Nombre de la comodidad
                            </header>
                        </div>
                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                            <input
                                onChange={(e) => setname(e.target.value)}
                                type="text"
                                value={name}
                                placeholder="Ej: Horno de barro"
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400"
                            />
                        </div>
                        <div className="w-full mt-4 text-left">
                            <header className=" pl-2 text-[#3C3C3C]  text-base md:text-xl">
                                Icono de la comodidad
                            </header>
                        </div>
                        <div className="w-full ">
                            <div className="mx-auto overflow-hidden bg-white rounded-lg ">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="py-3">
                                            <div className="mb-2">
                                                <div className="relative flex items-center justify-center h-40 bg-white border border-[#E9E9E9] rounded-lg hover:cursor-pointer ">
                                                    <div className="absolute">
                                                        <div className="flex flex-col items-center ">
                                                            <i className="text-gray-200 fa fa-cloud-upload fa-3x"></i>{" "}
                                                            {!selectedFile ? (
                                                                <div>
                                                                    <span className="block font-bold text-[#3C3C3C]">
                                                                        Arrastra
                                                                        el icono
                                                                        aquí
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <span className="block font-normal text-gray-400">
                                                                    {
                                                                        selectedFile.name
                                                                    }
                                                                </span>
                                                            )}
                                                            <span className="block mt-3 font-normal text-blue-bluecorner">
                                                                sube la imagen
                                                                desde tu
                                                                dispositivo
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <input
                                                        accept="image/*"
                                                        type="file"
                                                        className="w-full h-full opacity-0 cursor-pointer"
                                                        onChange={
                                                            handleChangeImage
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    className={
                                                        !selectedFile
                                                            ? "hidden"
                                                            : "flex items-center justify-center"
                                                    }>
                                                    <img
                                                        src=""
                                                        id="output"
                                                        className="w-12 h-12 mt-2 border border-blue-500"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pb-3 text-center ">
                                            <button
                                                onClick={() => handleSubmit()}
                                                disabled={cargando}
                                                className="w-full p-3 text-center text-white border rounded-md border-blue-blueFrom bg-blue-blueFrom hover:bg-white hover:text-blue-blueFrom disabled:bg-blue-blueFrom">
                                                {cargando
                                                    ? "Creando..."
                                                    : "Crear"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={() => setcreate(false)}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCreatename;
