import React, { FormEvent, useState } from "react";
import Swal from "sweetalert2";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { infoCasa } from "./typeCasa";
import Spinner from "../../components/helpers/Spinner";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";

interface Props {
    infoCasa: infoCasa;

    setModalVisible: (prev: boolean) => void;
}

export const ModalEditEstadiaMinima = ({
    infoCasa,

    setModalVisible,
}: Props) => {
    const dispatchDominio = useDispatchDominio();
    const [input, setInput] = useState({
        estadia_minima: infoCasa.estadia_minima,
        estadia_maxima: infoCasa.estadia_maxima,
    });
    const [saving, setSaving] = useState<boolean>(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setSaving(true);
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putEstadia/${infoCasa.id}`,
                method: "put",
                body: input,
            });
            dispatchDominio();

            setTimeout(() => {
                setModalVisible(false);
                setSaving(false);

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Cambios guardados con exito!",
                    // text: "Refresca la página para ver los cambios",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
            }, 2500);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {saving ? (
                <Spinner />
            ) : (
                <form
                    onSubmit={(e) => handleSubmit(e)}
                    className="flex flex-col w-full gap-2 mb-4 md:flex-row md:items-end md:justify-center">
                    <div className="flex flex-col w-full gap-1 text-left">
                        <label className="text-base font-medium">
                            Estadía mínima
                        </label>
                        <input
                            className="w-full p-2 border rounded-lg"
                            min={0}
                            type={"number"}
                            name="estadia_minima"
                            value={input.estadia_minima}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col w-full gap-1 text-left">
                        <label className="text-base font-medium">
                            Estadía máxima
                        </label>
                        <input
                            className="w-full p-2 border rounded-lg"
                            min={0}
                            type={"number"}
                            name="estadia_maxima"
                            value={input.estadia_maxima}
                            onChange={handleInputChange}
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full p-2 mt-2 text-white border rounded-lg md:mt-0 bg-[#1C4CBB] hover:scale-105 h-fit">
                        Actualizar
                    </button>
                </form>
            )}
        </>
    );
};
