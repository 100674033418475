import logoBlueCorner from "../assets/img/BLUECORNER_PNG.png";
import GITSAAS from "../../src/img/GIF_Bluestay.gif";

const SpinnerVerifyToken = () => {
    return (
        // <div className="flex items-center justify-center h-screen lds-circle">
        //     <img
        //         src={logoBlueCorner}
        //         alt="Bluecorner"
        //         className="w-full max-w-lg "
        //         // motion-safe:animate-[spin_4s_linear_infinite]
        //     />
        // </div>
        <div className="flex items-center justify-center h-screen ">
            <img
                src={GITSAAS}
                alt="BlueStay"
                className="w-full max-w-lg "
                // motion-safe:animate-[spin_4s_linear_infinite]
            />
        </div>
    );
};

export default SpinnerVerifyToken;
