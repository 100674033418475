import React, { useState, ChangeEvent, useEffect } from "react";
import calculadora1 from "../../img/calculadora/Recurso 1.png";
import flechas from "../../img/calculadora/FlechasRecorte 88.png";
import hombre from "../../img/calculadora/hombre-negocios-multitarea-trabajando-noche-ex.jpg";
import lineas from "../../img/calculadora/LíneasDegradado.png";
import { ModalCalculadora } from "./ModalCalculadora";
import MainNavbar from "../../components/MainNavbar/MainNavbar";
import { Footer } from "../../components/Footer";

import { formatearNumero } from "../../components/helpers/formatearNumero";
import { useParams } from "react-router-dom";
import Rendimiento from "../../components/home/Rendimiento";

interface CalculadoraState {
    nombre: string;
    celular: string;
    correo: string;
    costoNoche: string;
    diasAlquiladas: string;
    costoNocheMoneda: string;
    cargado: boolean;
    etiqueta: string;
    apellido: string;
}

export const Calculadora = () => {
    const { tags } = useParams();
    const [modalVisible, setModalVisible] = useState(false);
    const [generar, setGenerar] = useState(false);
    const [calculadoraState, setCalculadoraState] = useState<CalculadoraState>({
        nombre: "",
        celular: "",
        correo: "",
        costoNoche: "",
        costoNocheMoneda: "S/.",
        diasAlquiladas: "",
        cargado: false,
        etiqueta: tags ? tags : "6",
        apellido: "",
    });

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCalculadoraState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setCalculadoraState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const {
        nombre,
        celular,
        correo,
        costoNoche,
        diasAlquiladas,
        costoNocheMoneda,
    } = calculadoraState;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (calculadoraState.cargado) window.scrollTo(0, 0);
    }, [calculadoraState.cargado]);

    return (
        <div>
            <MainNavbar calculadora={"Calculadora"} />
            {modalVisible && (
                <ModalCalculadora
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    input={calculadoraState}
                    setInput={setCalculadoraState}
                />
            )}
            <div className="w-4/5 gap-4 py-10 pt-16 mx-auto mt-5 lg:w-3/4 2xl:w-2/3 sm:pt-24">
                <div className="grid mt-16 md:mt-16 md:grid-cols-2">
                    <div className="flex flex-col justify-start w-full text-lg lg:text-xl text-justify md:w-4/5 lg:w-3/4 2xl:text-2xl">
                        <h2 className="text-[#1C4CBB] text-center font-semibold text-3xl md:text-5xl lg:mb-16 md:mb-10 mb-5">
                            {calculadoraState.cargado
                                ? `¡Hola ${calculadoraState.nombre}!`
                                : "¡Hola!"}
                        </h2>
                        {!calculadoraState.cargado ? (
                            <div>
                                <p className="leading-relaxed">
                                    En{" "}
                                    <span className="font-bold">BluStay </span>
                                    queremos brindarte esta herramienta para que
                                    puedas generar más alquileres y obtener el
                                    100% de tus ganancias para ti.
                                </p>

                                <p className="mt-10 leading-relaxed">
                                    Con esta calculadora podrás estimar cuánto
                                    es tu ingreso por alquileres y cuánto
                                    estarías perdiendo utilizando otras
                                    plataformas que te cobran una comisión de
                                    tus ventas
                                </p>
                            </div>
                        ) : (
                            <div>
                                {" "}
                                <div className="flex items-center justify-center">
                                    <p className=" leading-relaxed text-lg lg:text-xl 2xl:text-2xl text-justify">
                                        No vas a creer cuánto dinero dejas de
                                        ganar por pagar a plataformas que te
                                        cobran un porcentaje de tus ventas, a
                                        continuación te lo contamos:
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <img
                            className=" w-full h-[300px] md:h-[400px] lg:h-[450px] 2xl:h-[500px] 2xl:mt-10 mt-3 object-contain"
                            src={calculadora1}
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <div className=" bg-[#029BFC] bg-opacity-10">
                <div className=" w-full md:w-5/6 pb-6 mx-auto lg:w-4/5 xl:w-2/3">
                    {!calculadoraState.cargado && (
                        <div>
                            <h3 className="text-[#1C4CBB] text-center font-semibold text-2xl md:text-3xl pt-5 md:pt-12 ">
                                Simulador
                            </h3>
                            <div className=" py-10 px-10 mt-5 md:mt-8 bg-white rounded-lg shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.25)]">
                                <div className="grid md:grid-cols-2 ">
                                    <div className="flex flex-col items-center justify-center px-2 md:border-r-2">
                                        <p className="text-base md:text-lg">
                                            Si tu costo alquiler promedio por
                                            noche es de
                                        </p>

                                        <div className="flex flex-col items-center gap-4 pt-2 mt-3 md:flex-row">
                                            <div className="flex bg-white border-2 border-[#029BFC] outline-none rounded-lg w-1/2">
                                                <p className="flex items-center mx-2 font-semibold">
                                                    {costoNocheMoneda}
                                                </p>

                                                <input
                                                    className="w-full p-2 bg-transparent border-none appearance-none"
                                                    placeholder="500"
                                                    name="costoNoche"
                                                    value={costoNoche}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                />
                                            </div>
                                            <div className="flex">
                                                <button
                                                    disabled={
                                                        costoNocheMoneda ===
                                                        "S/."
                                                    }
                                                    onClick={() =>
                                                        setCalculadoraState({
                                                            ...calculadoraState,
                                                            costoNocheMoneda:
                                                                "S/.",
                                                        })
                                                    }
                                                    className=" py-2 px-4 border-[#E6E6E6] bg-[#E6E6E6] text-[#808080] disabled:bg-[#029BFC] border-2 disabled:border-[#029BFC] rounded-lg font-semibold disabled:text-white "
                                                >
                                                    {" "}
                                                    S/.
                                                </button>
                                                <button
                                                    disabled={
                                                        costoNocheMoneda === "$"
                                                    }
                                                    onClick={() =>
                                                        setCalculadoraState({
                                                            ...calculadoraState,
                                                            costoNocheMoneda:
                                                                "$",
                                                        })
                                                    }
                                                    className=" py-2 px-5 font-extrabold border-[#E6E6E6] bg-[#E6E6E6] text-[#808080] disabled:bg-[#029BFC] border-2 disabled:border-[#029BFC] rounded-lg  disabled:text-white"
                                                >
                                                    {" "}
                                                    $
                                                </button>

                                                <button
                                                    disabled={
                                                        costoNocheMoneda === "Є"
                                                    }
                                                    onClick={() =>
                                                        setCalculadoraState({
                                                            ...calculadoraState,
                                                            costoNocheMoneda:
                                                                "Є",
                                                        })
                                                    }
                                                    className=" py-2 px-5 font-extrabold border-[#E6E6E6] bg-[#E6E6E6] text-[#808080] disabled:bg-[#029BFC] border-2 disabled:border-[#029BFC] rounded-lg  disabled:text-white"
                                                >
                                                    {" "}
                                                    Є
                                                </button>
                                            </div>
                                            <hr className="w-full mx-auto mt-3 border-t-2 md:hidden"></hr>
                                            <div className="flex flex-col items-center justify-center text-xs font-light md:hidden">
                                                <p className="font-bold">
                                                    *Ejemplo:
                                                </p>
                                                <p className=" text-[#333333] ">
                                                    * Si lo alquilas solo en
                                                    temporada de verano (3
                                                    meses), serían{" "}
                                                    <span className="font-bold ">
                                                        90 días al año
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="my-4 border-t-2 md:hidden"></hr>
                                    <div className="flex flex-col items-center justify-center px-2">
                                        <p className="text-base md:text-lg">
                                            y tu dias de ocupabilidad al año es
                                            de
                                        </p>
                                        <div className="flex flex-col items-center gap-4 pt-2 mt-3 md:flex-row">
                                            <div className="flex bg-white border-2 border-[#029BFC] outline-none rounded-lg w-1/2">
                                                <input
                                                    className="w-full p-2 bg-transparent border-none appearance-none"
                                                    placeholder="30"
                                                    name="diasAlquiladas"
                                                    value={diasAlquiladas}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                />
                                                <p className="flex items-center mx-2 font-semibold">
                                                    Dias
                                                </p>
                                            </div>

                                            <p className="flex items-center h-10 px-1 text-xs text-center border-2 rounded-lg min-w-32">
                                                {" "}
                                                Cuantos dias del año está
                                                ocupada tu casa*
                                            </p>
                                        </div>
                                    </div>
                                    <hr className="my-4 border-t-2 md:hidden "></hr>

                                    <div className="flex flex-col items-center justify-center text-xs font-light md:hidden">
                                        <p className=" text-[#333333]">
                                            * Si lo alquilas en promedio 2 fines
                                            de semana al mes = 6 dias al mes,
                                            que serian{" "}
                                            <span className="font-bold ">
                                                72 dias al año
                                            </span>
                                        </p>
                                        <hr className="w-full mx-auto mt-3 border-t-2 "></hr>
                                    </div>
                                </div>

                                <div className="content-center hidden w-4/5 grid-cols-2 gap-4 mx-auto text-xs md:grid mt-7">
                                    <div>
                                        <p className="flex items-center font-bold">
                                            *Ejemplo:
                                        </p>
                                        <p className=" text-[#333333] px-4">
                                            * Si lo alquilas solo en temporada
                                            de verano (3 meses), serían{" "}
                                            <span className="font-bold ">
                                                90 días al año
                                            </span>
                                        </p>
                                    </div>
                                    <p className=" text-[#333333] px-2">
                                        * Si lo alquilas en promedio 2 fines de
                                        semana al mes = 6 dias al mes, que
                                        serian{" "}
                                        <span className="font-bold ">
                                            72 dias al año
                                        </span>
                                    </p>
                                </div>
                                <hr className="hidden w-full mx-auto border-t-2 mt-7 md:grid"></hr>

                                <div className="flex items-center justify-center mt-10">
                                    <button
                                        onClick={() => setModalVisible(true)}
                                        className=" py-2 px-16 md:px-28 bg-[#029BFC] border-2 border-[#029BFC] rounded-lg font-semibold text-white "
                                    >
                                        {" "}
                                        CALCULAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        className={`${
                            calculadoraState.cargado ? "" : "hidden"
                        }`}
                    >
                        <div className="grid w-full md:gap-8 md:grid-cols-2 text-[#333333]">
                            <div className=" p-10 mt-10 bg-white rounded-lg shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.25)] w-full leading-8  min-h-[280px] ">
                                <div className="w-full mx-auto text-justify lg:w-4/5 xl:w-3/4">
                                    <p className="text-base leading-relaxed md:text-lg lg:text-xl ">
                                        Esto quiere decir que de 365 días, tu
                                        casa esta alquilada aproximadamente
                                    </p>
                                    <div className="flex items-center justify-center mt-5 md:mt-10">
                                        <button className="w-full py-1 bg-[#029BFC] border-2 border-[#029BFC] rounded-lg font-semibold text-white ">
                                            {" "}
                                            {(
                                                (Number(diasAlquiladas) / 365) *
                                                100
                                            ).toFixed(2)}
                                            % al año
                                        </button>
                                    </div>
                                    <p className="mt-5 text-base leading-relaxed md:mt-10 md:text-lg lg:text-xl">
                                        Y esto genera, un monto de alquiler
                                        anual de
                                    </p>
                                    <div className="flex items-center justify-center mt-5 md:mt-10">
                                        <button className="w-full py-1 bg-[#029BFC] border-2 border-[#029BFC] rounded-lg font-semibold text-white ">
                                            {costoNocheMoneda}{" "}
                                            {formatearNumero(
                                                Number(diasAlquiladas) *
                                                    Number(costoNoche)
                                            )}
                                            {/* {Number(diasAlquiladas) *
                                                Number(costoNoche)} */}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex  p-10 mt-10 bg-white rounded-lg shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.25)] w-full leading-8 min-h-[280px]">
                                <div className="flex flex-col justify-between w-full mx-auto text-justify lg:w-4/5 xl:w-3/4">
                                    <p className="mb-auto text-base leading-relaxed md:text-lg lg:text-xl">
                                        Si actualmente tu casa está en
                                        plataformas como Airbnb o Booking, como
                                        propietarios dejas de ganar al rededor
                                        de 15% por cada alquiler.
                                    </p>
                                    <div className="mt-10 md:mt-0">
                                        <p className="mt-auto text-base leading-relaxed md:text-lg lg:text-xl">
                                            Esto puede parecer poco, pero
                                            haciendo los cálculos según los
                                            datos ingresados, esto representa un
                                            valor anual de
                                        </p>
                                        <div className="flex items-center justify-center mt-5 md:mt-10">
                                            <button className=" w-full py-1  bg-[#F86A74] border-2 border-[#F86A74] rounded-lg font-semibold text-white ">
                                                {" "}
                                                {costoNocheMoneda}{" "}
                                                {formatearNumero(
                                                    Number(diasAlquiladas) *
                                                        Number(costoNoche) *
                                                        0.15
                                                )}
                                                {/* {Number(diasAlquiladas) *
                                                    Number(costoNoche) *
                                                    0.15} */}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="grid w-full md:gap-4 md:grid-cols-2 text-[#333333] rounded-lg shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.25)] bg-white mt-10">
                            <div className="w-full p-10 leading-8 bg-white border-b-2 rounded-lg md:border-b-0 md:border-r-2">
                                <div className="w-full mx-auto text-base leading-relaxed text-justify lg:w-4/5 xl:w-3/4 md:text-lg lg:text-xl">
                                    <p className="">
                                        Con{" "}
                                        <span className="text-[#029BFC] font-bold">
                                            BluStay
                                        </span>{" "}
                                        queremos darte las herramientas
                                        tecnológicas para que puedas gestionar
                                        todas tus reservas con clientes desde
                                        nuestro software, brindándote una web
                                        exclusiva para tu casa y así no dependas
                                        de intermediarios.
                                    </p>

                                    <p className="mt-5 md:mt-10">
                                        Encuentra el módulo de gestión del
                                        alojamiento, reservas, chat, reportes,
                                        CRM y muchas más.
                                    </p>
                                </div>
                            </div>
                            <div className="w-full p-10 mx-auto mb-5 text-base leading-relaxed text-justify bg-white lg:w-4/5 xl:w-3/4 md:text-lg lg:text-xl md:mb-0">
                                <p className="">
                                    Este servicio a tan solo un pago anual de
                                </p>
                                <div className="flex items-center justify-center w-full mt-5 md:mt-8">
                                    <button className=" w-full py-1  bg-[#029BFC] border-2 border-[#029BFC] rounded-lg font-semibold text-white ">
                                        {" "}
                                        {costoNocheMoneda === "$"
                                            ? "$ 950"
                                            : costoNocheMoneda === "$"
                                            ? "S/. 3.516"
                                            : "€ 869"}
                                    </button>
                                </div>
                                <p className="mt-10">
                                    Que representa un gasto mínimo de tus
                                    ingresos anuales
                                </p>
                                <div className="flex items-center justify-center w-full mt-8">
                                    <button className="w-full py-1 bg-[#029BFC] border-2 border-[#029BFC] rounded-lg font-semibold text-white ">
                                        {costoNocheMoneda === "$"
                                            ? (
                                                  (950 * 100) /
                                                  (Number(diasAlquiladas) *
                                                      Number(costoNoche))
                                              ).toFixed(2)
                                            : (
                                                  (3516 * 100) /
                                                  (Number(diasAlquiladas) *
                                                      Number(costoNoche))
                                              ).toFixed(2)}
                                        %
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className=" flex justify-center items-center w-full md:gap-4  text-[#333333] rounded-lg shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.25)] mt-14 relative bg-white">
                            <img
                                src={flechas}
                                alt="Background"
                                className="absolute top-0 left-0 z-0 object-fill w-full h-full"
                            />

                            <div className="p-10 w-full md:w-2/3 leading-relaxed md:leading-loose min-h-[280px]  relative z-10  md:text-center text-base md:text-lg lg:text-xl">
                                <p className="text-center md:mt-10">
                                    Con{" "}
                                    <span className="text-[#029BFC] font-bold">
                                        BluStay
                                    </span>{" "}
                                    queremos que tu tasa de ocupabilidad aumente
                                    gestionando tus propios clientes, ya que
                                    tenemos aún 256 dias sin alquilar tu
                                    propiedad, que representa una oportunidad de
                                    incrementar tus ventas por{" "}
                                </p>
                                <p className=" py-1 md:w-72 mx-auto mt-10 bg-[#029BFC] border-2 border-[#029BFC] rounded-lg font-semibold text-center text-white ">
                                    {" "}
                                    {costoNocheMoneda}{" "}
                                    {formatearNumero(
                                        (365 - Number(diasAlquiladas)) *
                                            Number(costoNoche)
                                    )}{" "}
                                </p>
                            </div>
                        </div>

                        <div className=" flex justify-center items-center w-full md:gap-4  text-white text-lg md:text-xl font-bold rounded-lg shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.25)] mt-14 relative bg-[#029BFC]">
                            <div className="relative z-10 w-full p-5 text-lg leading-relaxed text-center lg:p-10 md:leading-8 lg:text-2xl ">
                                <p className="">
                                    Únete a esta revolución digital y apóyate
                                    con nosotros
                                </p>
                                <p className="mt-4">
                                    para maximizar tus reservas y no depender de
                                    intermediarios
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={` ${
                        calculadoraState.cargado ? "" : "hidden"
                    } relative w-full flex justify-center items-center overflow-hidden`}
                >
                    <div className="hidden lg:flex absolute top-0 left-0 z-10 justify-center w-full h-full bg-[#029BFC] bg-opacity-100 md:bg-opacity-0 md:bg-gradient-to-r md:from-transparent md:to-[#029BFC]"></div>
                    <img
                        src={hombre}
                        alt="Background"
                        className="absolute top-0 left-0 z-0 object-cover md:object-cover lg:object-fill w-full h-[200px] lg:h-[600px]"
                    />
                    <div className="relative z-10 flex flex-col items-center justify-center w-full mx-auto text-sm leading-8 text-center lg:p-4 lg:my-4 lg:flex-row md:text-xl lg:text-3xl mt-36 lg:mt-0">
                        <div className="hidden w-1/2 lg:flex"></div>
                        <div className="w-full lg:w-1/2 bg-[#029BFC] lg:bg-transparent p-6">
                            <div className="w-full lg:w-2/3">
                                <p className="font-semibold text-center text-white lg:text-3xl">
                                    Opta por el plan que mejor se acomode y
                                    empieza a potenciar tus ganancias desde hoy
                                    mismo
                                </p>

                                <button
                                    onClick={() =>
                                        window.open(`/planes`, "_blank")
                                    }
                                    className="py-2 md:py-5 px-5 md:px-10 mx-auto text-sm md:text-lg lg:text-xl  text-center mt-5 md:mt-10 bg-[#5AAD00] border-2 border-[#5AAD00] rounded-full font-semibold hover:scale-105 text-white shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.25)]"
                                >
                                    VER LOS PLANES
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {calculadoraState.cargado && (
                    <div className="z-30">
                        <h3 className="text-[#1C4CBB] text-center font-semibold text-2xl md:text-3xl pt-5 md:pt-12 ">
                            Simulador
                        </h3>
                        <div className=" py-10 px-10 mt-5 md:mt-8 bg-white rounded-lg shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.25)]">
                            <div className="grid md:grid-cols-2 ">
                                <div className="flex flex-col items-center justify-center px-2 md:border-r-2">
                                    <p className="text-base md:text-lg">
                                        Si tu costo alquiler promedio por noche
                                        es de
                                    </p>

                                    <div className="flex flex-col items-center gap-4 pt-2 mt-3 md:flex-row">
                                        <div className="flex bg-white border-2 border-[#029BFC] outline-none rounded-lg w-1/2">
                                            <p className="flex items-center mx-2 font-semibold">
                                                {costoNocheMoneda}
                                            </p>

                                            <input
                                                className="w-full p-2 bg-transparent border-none appearance-none"
                                                placeholder="500"
                                                name="costoNoche"
                                                value={costoNoche}
                                                onChange={handleInputChange}
                                                type="number"
                                            />
                                        </div>
                                        <div className="flex">
                                            <button
                                                disabled={
                                                    costoNocheMoneda === "S/."
                                                }
                                                onClick={() =>
                                                    setCalculadoraState({
                                                        ...calculadoraState,
                                                        costoNocheMoneda: "S/.",
                                                    })
                                                }
                                                className=" py-2 px-4 border-[#E6E6E6] bg-[#E6E6E6] text-[#808080] disabled:bg-[#029BFC] border-2 disabled:border-[#029BFC] rounded-lg font-semibold disabled:text-white "
                                            >
                                                {" "}
                                                S/.
                                            </button>
                                            <button
                                                disabled={
                                                    costoNocheMoneda === "$"
                                                }
                                                onClick={() =>
                                                    setCalculadoraState({
                                                        ...calculadoraState,
                                                        costoNocheMoneda: "$",
                                                    })
                                                }
                                                className=" py-2 px-5 font-extrabold border-[#E6E6E6] bg-[#E6E6E6] text-[#808080] disabled:bg-[#029BFC] border-2 disabled:border-[#029BFC] rounded-lg  disabled:text-white"
                                            >
                                                {" "}
                                                $
                                            </button>
                                        </div>
                                        <hr className="w-full mx-auto mt-3 border-t-2 md:hidden"></hr>
                                        <div className="flex flex-col items-center justify-center text-xs font-light md:hidden">
                                            <p className="font-bold">
                                                *Ejemplo:
                                            </p>
                                            <p className=" text-[#333333] ">
                                                * Si lo alquilas solo en
                                                temporada de verano (3 meses),
                                                serían{" "}
                                                <span className="font-bold ">
                                                    90 días al año
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <hr className="my-4 border-t-2 md:hidden"></hr>
                                <div className="flex flex-col items-center justify-center px-2">
                                    <p className="text-base md:text-lg">
                                        y tu dias de ocupabilidad al año es de
                                    </p>
                                    <div className="flex flex-col items-center gap-4 pt-2 mt-3 md:flex-row">
                                        <div className="flex bg-white border-2 border-[#029BFC] outline-none rounded-lg w-1/2">
                                            <input
                                                className="w-full p-2 bg-transparent border-none appearance-none"
                                                placeholder="30"
                                                name="diasAlquiladas"
                                                value={diasAlquiladas}
                                                onChange={handleInputChange}
                                                type="number"
                                            />
                                            <p className="flex items-center mx-2 font-semibold">
                                                Dias
                                            </p>
                                        </div>

                                        <p className="flex items-center h-10 px-1 text-xs text-center border-2 rounded-lg min-w-32">
                                            {" "}
                                            Cuantos dias del año está ocupada tu
                                            casa*
                                        </p>
                                    </div>
                                </div>
                                <hr className="my-4 border-t-2 md:hidden "></hr>

                                <div className="flex flex-col items-center justify-center text-xs font-light md:hidden">
                                    <p className=" text-[#333333]">
                                        * Si lo alquilas en promedio 2 fines de
                                        semana al mes = 6 dias al mes, que
                                        serian{" "}
                                        <span className="font-bold ">
                                            72 dias al año
                                        </span>
                                    </p>
                                    <hr className="w-full mx-auto mt-3 border-t-2 "></hr>
                                </div>
                            </div>

                            <div className="content-center hidden w-4/5 grid-cols-2 gap-4 mx-auto text-xs md:grid mt-7">
                                <div>
                                    <p className="flex items-center font-bold">
                                        *Ejemplo:
                                    </p>
                                    <p className=" text-[#333333] px-4">
                                        * Si lo alquilas solo en temporada de
                                        verano (3 meses), serían{" "}
                                        <span className="font-bold ">
                                            90 días al año
                                        </span>
                                    </p>
                                </div>
                                <p className=" text-[#333333] px-2">
                                    * Si lo alquilas en promedio 2 fines de
                                    semana al mes = 6 dias al mes, que serian{" "}
                                    <span className="font-bold ">
                                        72 dias al año
                                    </span>
                                </p>
                            </div>
                            <hr className="hidden w-full mx-auto border-t-2 mt-7 md:grid"></hr>

                            <div className="flex items-center justify-center mt-10">
                                <button
                                    onClick={() => setModalVisible(true)}
                                    className=" py-2 px-16 md:px-28 bg-[#029BFC] border-2 border-[#029BFC] rounded-lg font-semibold text-white "
                                >
                                    {" "}
                                    CALCULAR
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <div id="Rendimiento">
                    <Rendimiento />
                </div>
            </div>

            <Footer calculadora={"Calculadora"} />
        </div>
    );
};
