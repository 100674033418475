import React, { useState, useEffect, useRef } from "react";
import { Paginado } from "../../components/Paginado";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import bannerBlog from "../../img/landing_comprimidas/banner_blog.webp";
interface Blog {
    id: number;
    titulo: string;
    banner: string;
    contenido: string;
    fecha_publicado: Date;
}

interface getParams {
    pagina: number;
    habilitado: string;
    query: string;
    etiqueta: number;
}

interface etiquetaBlog {
    id: number;
    nombre: string;
    habilitado: string;
    fecha_registro: string;
}

const PrincipalContent = () => {
    const [blogs, setBlogs] = useState<Blog[]>([]);
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [blogsTotal, setBlogsTotal] = useState<number>(0);
    const blogs_PER_PAGE = 8;
    const [order, setOrder] = useState("Desc");
    const [input, setInput] = useState<string>("");
    const [etiquetaSelect, setEtiquetaSelect] = useState(0);
    const [etiquetasBLogs, setEtiquetasBlogs] = useState<etiquetaBlog[]>([]);
    const navigate = useNavigate();
    const debounceInput = useRef<ReturnType<typeof setTimeout> | null>(null);

    const getBlogs = async ({
        pagina,
        habilitado,
        query,
        etiqueta,
    }: getParams) => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/blog/getAll/${pagina}/${habilitado}/${order}/${etiqueta}?query=${query}`
            );
            console.log(data);
            setBlogs(data.rows);
            setBlogsTotal(data.count);
        } catch (error) {
            console.log(error);
        }
    };

    const getEtiquetaBlog = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/etiquetas_blogs/getAll/1/Si?query=`
            );

            console.log(data);
            setEtiquetasBlogs(data.rows);
            // setEtiquetasTotal(data.count);
        } catch (error) {
            console.log(error);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);

        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            getBlogs({
                pagina: pageNumber,
                habilitado: "Si",
                query: "",
                etiqueta: etiquetaSelect,
            });

            return;
        }
        debounceInput.current = setTimeout(() => {
            getBlogs({
                pagina: pageNumber,
                habilitado: "Si",
                query: e.target.value,
                etiqueta: etiquetaSelect,
            });
        }, 1000);
    };

    const pagination = (pageNumber: number) => {
        setpageNumber(pageNumber);
        getBlogs({
            pagina: pageNumber,
            habilitado: "Si",
            query: "",
            etiqueta: etiquetaSelect,
        });
    };

    useEffect(() => {
        getEtiquetaBlog();
    }, []);

    useEffect(() => {
        getBlogs({
            pagina: pageNumber,
            habilitado: "Si",
            query: "",
            etiqueta: etiquetaSelect,
        });
    }, [pageNumber, order, etiquetaSelect]);

    return (
        <div>
            <div>
                <div className="pt-16 mt-32 text-3xl lg:text-5xl text-[#1C4CBB] text-center font-bold">
                    Nuestros Blogs
                </div>
            </div>

            <div className="flex flex-col lg:flex-row justify-center items-center w-3/4 mt-10 lg:mt-14 mx-auto rounded-2xl border-2 border-[#1C4CBB] px-10 shadow-2xl bg-slate-100">
                <div className="w-full h-40 lg:h-52">
                    <img
                        src={bannerBlog}
                        alt="bannerBlog"
                        className="object-cover w-auto h-full mx-auto"
                    />
                </div>
                <div className="w-full text-[#1C4CBB] text-3xl lg:text-5xl font-semibold text-center mt-5 lg:mt-0 mb-5 lg:mb-0">
                    ¡Entérate de nuestras notas!
                </div>
            </div>
            <div className="flex justify-end w-3/4 mx-auto mt-8 ">
                <div className="flex w-full p-1 mr-2 border-2 border-[#CCCCCC] rounded-lg appearance-none md:w-80 left-2 text-stone-900">
                    <button className="flex items-center justify-center px-2">
                        <svg
                            className="w-6 h-6 text-[#808080]"
                            fill="currentColor"
                            viewBox="0 0 24 24">
                            <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                        </svg>
                    </button>{" "}
                    <input
                        // onBlur={() => setInput("")}
                        value={input}
                        type="text"
                        onChange={handleInputChange}
                        className="w-full bg-transparent outline-none appearance-none xs:py-2 placeholder:text-[#808080]"
                        placeholder="Buscar"
                    />
                </div>
                <select
                    name="tipo_estadia"
                    onChange={(e) => setOrder(e.target.value)}
                    className="ml-2  pl-1 xs:px-4 rounded-lg py-2 bg-transparent border-2 border-[#CCCCCC] outline-none focus:outline-none placeholder:text-[#808080] text-[#808080] cursor-pointer"
                    value={order}>
                    <option value="Desc">Más nuevos</option>
                    <option value="Asc">Más antiguos</option>
                </select>
                <select
                    name="tipo_estadia"
                    onChange={(e) => setEtiquetaSelect(Number(e.target.value))}
                    className="ml-2  pl-1 xs:px-4 rounded-lg py-2 bg-transparent border-2 border-[#CCCCCC] outline-none focus:outline-none placeholder:text-[#808080] text-[#808080] cursor-pointer"
                    value={etiquetaSelect}>
                    <option value={0}>Todos</option>
                    {etiquetasBLogs.map((etiqueta) => (
                        <option value={etiqueta.id}>{etiqueta.nombre}</option>
                    ))}
                </select>
            </div>

            <div>
                <div className="grid items-center justify-center w-4/5 grid-cols-1 gap-8 mx-auto mt-10 md:grid-cols-2 lg:grid-cols-4 lg:w-3/4 mb-14 lg:mt-20">
                    {blogs &&
                        blogs.length > 0 &&
                        blogs.map((blog, index) => (
                            <div
                                onClick={() => navigate(`/blog/${blog.id}`)}
                                className="cursor-pointer ">
                                <div className=" flex-col justify-center items-center h-[20rem] w-full  rounded-lg bg-[#1C4CBB]">
                                    <img
                                        src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/blog/getBanner/${blog.banner}`}
                                        alt="blog"
                                        className=" h-[10rem] w-full  rounded-t-lg object-cover "
                                    />
                                    <div className="px-5 mt-3 text-base font-bold text-center text-white">
                                        {blog.titulo.length > 30
                                            ? blog.titulo
                                                  .slice(0, 30)
                                                  .concat("...")
                                            : blog.titulo}
                                    </div>
                                    <div className="px-5 mt-1 text-xs text-center text-white">
                                        {new Date(
                                            blog.fecha_publicado
                                        ).getDate() < 10
                                            ? `0${new Date(
                                                  blog.fecha_publicado
                                              ).getDate()}`
                                            : new Date(
                                                  blog.fecha_publicado
                                              ).getDate()}{" "}
                                        {new Date(
                                            blog.fecha_publicado
                                        ).toLocaleDateString("es-ES", {
                                            month: "long",
                                        })}{" "}
                                        {new Date(
                                            blog.fecha_publicado
                                        ).getFullYear()}
                                    </div>
                                    <div className="px-3 mt-3 text-sm text-center text-white">
                                        {blog.contenido
                                            .replace(/<[^>]*>?/g, "")
                                            .replace(".", ". ")
                                            .split(" ", 10)
                                            .join(" ")}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>

                <div className="container flex items-center justify-between p-1 mx-auto">
                    <Paginado
                        elementsPerPage={blogs_PER_PAGE}
                        cantTotalElements={blogsTotal}
                        pagination={pagination}
                        actualPage={pageNumber}
                    />
                </div>
            </div>
        </div>
    );
};

export default PrincipalContent;
