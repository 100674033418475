import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../hooks/hooks";
import { useDispatchDominioCorredor } from "../../hooks/useDispatchDominioCorredor";

interface Props {
    modalVisible: boolean;

    setModalVisible: (visible: boolean) => void;
    type: string;
}

export const ModalEditLanding = ({
    modalVisible,
    setModalVisible,
    type,
}: Props) => {
    const { banner, avatar, correo, celular, descripcion, id } = useAppSelector(
        (state) => state.corredor
    );
    const dispatchDominio = useDispatchDominioCorredor();
    const [saving, setSaving] = useState<boolean>(false);
    const [input, setInput] = useState({
        banner,
        avatar,
        correo,
        celular,
        descripcion,
    });

    const [preview, setPreview] = useState<string | null>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setInput({ ...input, avatar: file.name });
            setImageFile(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleBannerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setInput({ ...input, banner: file.name });
            setImageFile(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async () => {
        setSaving(true);

        const formData = new FormData();

        if (type === "correo") {
            formData.append("correo", input.correo);
        } else if (type === "celular") {
            formData.append("celular", input.celular);
        } else if (type === "descripcion") {
            formData.append("descripcion", input.descripcion);
        } else if (type === "avatar" && imageFile) {
            formData.append("avatar_corredor", imageFile);
        } else if (type === "banner" && imageFile) {
            formData.append("banner_corredor", imageFile);
        }

        try {
            await fetchAxiosToken({
                url: `/corredor/updateLanding/${type}/${id}`,
                method: "put",
                body: formData,
            });

            setTimeout(() => {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Actualizado",
                    text: "Landing actualizada",
                    icon: "success",
                    confirmButtonText: "Ok",
                });
                setSaving(false);

                dispatchDominio();
                setModalVisible(false);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
        >
            <div className="flex flex-col items-center justify-center max-w-lg gap-4 xl:p-4  md:p-2 p-1 mx-auto text-gray-600 ">
                <h2 className="xl:text-4xl md:text-3xl text-2xl text-[#1C4CBB] text-center font-medium">
                    Editar {type}
                </h2>
                <hr className="w-full bg-gray-300" />
                {type === "correo" && (
                    <div className="flex flex-col justify-center w-10/12">
                        <label className="ml-2 xl:text-xl md:text-lg text-base font-medium">
                            Correo
                        </label>
                        <input
                            type={"text"}
                            min={0}
                            name="correo"
                            value={input.correo}
                            onChange={handleInputChange}
                            className="w-full max-w-sm xl:p-3 md:p-2.5 p-2 mx-auto mt-2 text-black border border-gray-500 rounded-lg placeholder:italic"
                        />
                    </div>
                )}
                {type === "celular" && (
                    <div className="flex flex-col justify-center w-10/12">
                        <label className="ml-2 xl:text-xl md:text-lg text-base font-medium">
                            Celular
                        </label>
                        <input
                            type={"text"}
                            min={0}
                            name="celular"
                            value={input.celular}
                            onChange={handleInputChange}
                            className="w-full max-w-sm xl:p-3 md:p-2.5 p-2 mx-auto mt-2 text-black border border-gray-500 rounded-lg placeholder:italic"
                        />
                    </div>
                )}
                {type === "descripcion" && (
                    <div className="flex flex-col justify-center w-10/12">
                        <label className="ml-2 xl:text-xl md:text-lg text-base font-medium">
                            Descripción
                        </label>
                        <textarea
                            name="descripcion"
                            rows={6}
                            value={input.descripcion}
                            onChange={handleInputChange}
                            className="w-full max-w-sm xl:p-3 md:p-2.5 p-2 mx-auto mt-2 text-black border border-gray-500 rounded-lg placeholder:italic"
                        />
                    </div>
                )}{" "}
                {type === "avatar" && (
                    <div className="flex flex-col justify-center w-10/12">
                        <label className="ml-2 xl:text-xl md:text-lg text-base font-medium">
                            Avatar
                        </label>
                        <div className="flex justify-center mt-4">
                            <img
                                src={
                                    preview ||
                                    `${process.env.REACT_APP_PROXY}/usuarios/getImage/${avatar}`
                                }
                                alt="Avatar"
                                className="w-32 h-32 rounded-full object-cover cursor-pointer border border-blue-500"
                                onClick={() =>
                                    document
                                        .getElementById("fileInput")
                                        ?.click()
                                }
                            />
                        </div>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                            accept="image/*"
                        />
                    </div>
                )}
                {type === "banner" && (
                    <div className="flex flex-col justify-center w-10/12">
                        <label className="ml-2 xl:text-xl md:text-lg text-base font-medium">
                            banner
                        </label>
                        <div className="flex justify-center mt-4">
                            <img
                                src={
                                    preview ||
                                    `${process.env.REACT_APP_PROXY}/usuarios/getImage/${banner}`
                                }
                                alt="Avatar"
                                className=" w-96 h-40 rounded-md object-cover cursor-pointer border border-blue-500"
                                onClick={() =>
                                    document
                                        .getElementById("fileInput")
                                        ?.click()
                                }
                            />
                        </div>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                            accept="image/*"
                        />
                    </div>
                )}
                <div className="w-10/12 xl:mt-8 md:mt-5 mt-3 ">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="w-full p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105"
                        >
                            Guardar cambios
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
