import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAppSelector } from "../../hooks/hooks";
import ModalReserva from "./ModalReserva/ModalReserva";
import es from "date-fns/locale/es";
import { ModalEditContenidoDescripcion } from "../modalEdit/ModalEditContenidoDescripcion";
import ReactPlayer from "react-player";
import Swal from "sweetalert2";
interface Input {
    nombre: string;
    fecha_inicio: Date;
    fecha_final: Date;
}

interface Props {
    toggleButton: boolean;
}

const Reserva = ({ toggleButton }: Props) => {
    registerLocale("es", es);
    const [selectedDate1, setSelectedDate1] = useState<Date>(new Date());
    const [isDatePickerVisible1, setDatePickerVisible1] =
        useState<boolean>(false);

    const [selectedDate2, setSelectedDate2] = useState<Date>(new Date());
    const [isDatePickerVisible2, setDatePickerVisible2] =
        useState<boolean>(false);

    const [
        modalVisibleContenidoDescripcion,
        setModalVisibleContenidoDescripcion,
    ] = useState<boolean>(false);

    const {
        personas,
        tipo_descripcion,
        imagen_descripcion,
        video_descripcion,
    } = useAppSelector((state) => state.landing_house.house);

    const handleDateChange = (date: Date | null, selectDate: number) => {
        if (date === null) return;

        const year = date?.getFullYear();
        const month = String(date?.getMonth() + 1).padStart(2, "0");
        const day = String(date?.getDate()).padStart(2, "0");

        if (selectDate === 1) {
            if (date) {
                setSelectedDate1(date);

                setInput({
                    ...input,
                    fecha_inicio: `${year}-${month}-${day}`,
                });
            }

            setDatePickerVisible1(false);
        } else {
            if (date) {
                setSelectedDate2(date);
                setInput({
                    ...input,
                    fecha_final: `${year}-${month}-${day}`,
                });
            }
            setDatePickerVisible2(false);
        }
    };

    const [modalVisibleReserva, setModalVisibleReserva] =
        useState<boolean>(false);

    const [input, setInput] = useState({
        adultos: 0,
        ninos: 0,

        fecha_inicio: `${new Date().getFullYear()}-${String(
            new Date().getMonth() + 1
        ).padStart(2, "0")}-${String(new Date().getDate()).padStart(2, "0")}`,
        fecha_final: `${new Date().getFullYear()}-${String(
            new Date().getMonth() + 1
        ).padStart(2, "0")}-${String(new Date().getDate()).padStart(2, "0")}`,
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        setInput({
            ...input,
            [event.target.name]: inputValue !== "" ? Number(inputValue) : "",
        });
    };

    const handleModalReserva = () => {
        if (input.fecha_inicio > input.fecha_final) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "la fecha de inicio no puede ser mayor a la fecha de salida ",

                icon: "error",
                confirmButtonText: "Ok",
            });
        } else {
            setModalVisibleReserva(true);
        }
    };
    return (
        <div className="flex flex-col mx-5 md:mx-10">
            {modalVisibleReserva && (
                <ModalReserva
                    modalVisible={modalVisibleReserva}
                    setModalVisible={setModalVisibleReserva}
                    toggleButton={toggleButton}
                    input={input}
                />
            )}
            {modalVisibleContenidoDescripcion && (
                <ModalEditContenidoDescripcion
                    setModalVisibleContenidoDescripcion={
                        setModalVisibleContenidoDescripcion
                    }
                    modalVisibleContenidoDescripcion={
                        modalVisibleContenidoDescripcion
                    }
                />
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 md:gap-0 gap-3 justify-center items-center bg-[#000000] bg-opacity-50 p-5 2xl:p-10 rounded-lg mx-auto lg:divide-x-4">
                <div className="relative flex flex-col items-center justify-center">
                    <div
                        className="flex justify-center gap-5 text-white cursor-pointer"
                        onClick={() => setDatePickerVisible1(true)}
                    >
                        <div className="flex flex-col justify-center">
                            <div className="flex flex-col items-end">
                                <div className="text-xs uppercase lg:text-sm">
                                    {selectedDate1.toLocaleDateString("es-ES", {
                                        weekday: "long",
                                    })}{" "}
                                </div>
                                <div className="text-4xl font-bold lg:text-5xl">
                                    {selectedDate1.getDate() < 10
                                        ? `0${selectedDate1.getDate()}`
                                        : selectedDate1.getDate()}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="text-xs font-bold">CHECK-IN</div>
                            <div className="mt-2 text-xs uppercase lg:text-base">
                                {" "}
                                {selectedDate1.toLocaleDateString("es-ES", {
                                    month: "long",
                                })}
                            </div>
                            <div className="text-xs lg:text-base">
                                {selectedDate1.getFullYear()}
                            </div>
                        </div>
                    </div>
                    {isDatePickerVisible1 && (
                        <div className="absolute bottom-0">
                            <DatePicker
                                locale="es"
                                selected={selectedDate1}
                                onChange={(date) => handleDateChange(date, 1)}
                                inline
                            />
                        </div>
                    )}
                </div>
                <div className="relative flex flex-col items-center justify-center ">
                    <div
                        className="flex justify-center gap-5 text-white cursor-pointer"
                        onClick={() => setDatePickerVisible2(true)}
                    >
                        <div className="flex flex-col items-end">
                            <div className="text-xs uppercase lg:text-sm">
                                {selectedDate2.toLocaleDateString("es-ES", {
                                    weekday: "long",
                                })}{" "}
                            </div>
                            <div className="text-4xl font-bold lg:text-5xl mt2">
                                {selectedDate2.getDate() < 10
                                    ? `0${selectedDate2.getDate()}`
                                    : selectedDate2.getDate()}
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="text-xs font-bold">CHECK-OUT</div>
                            <div className="mt-2 text-xs uppercase lg:text-base">
                                {" "}
                                {selectedDate2.toLocaleDateString("es-ES", {
                                    month: "long",
                                })}
                            </div>
                            <div className="text-xs lg:text-base">
                                {selectedDate2.getFullYear()}
                            </div>
                        </div>
                    </div>
                    {isDatePickerVisible2 && (
                        <div className="absolute bottom-0">
                            <DatePicker
                                locale="es"
                                selected={selectedDate2}
                                onChange={(date) => handleDateChange(date, 2)}
                                inline
                            />
                        </div>
                    )}
                </div>

                <div className="flex items-center justify-center text-white">
                    <div className="flex flex-col items-center">
                        <div className="text-xs uppercase lg:text-sm">
                            ADULTOS
                        </div>

                        <input
                            className="text-2xl font-bold bg-transparent outline-none cursor-pointer w-14 md:text-4xl lg:text-5xl "
                            type="number"
                            name="adultos"
                            value={input.adultos}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex items-center">
                        <div className="flex-col mt-2 md:mt-7">
                            <div>
                                <button
                                    onClick={() =>
                                        setInput({
                                            ...input,
                                            adultos:
                                                Number(input.adultos) +
                                                    Number(input.ninos) <
                                                personas
                                                    ? Number(input.adultos) + 1
                                                    : input.adultos,
                                        })
                                    }
                                >
                                    <svg
                                        className="md:w-5 md:h-5 w-3 h-3 font-bold cursor-pointer hover:scale-110 hover:text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <polyline points="6 15 12 9 18 15" />
                                    </svg>
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() =>
                                        setInput({
                                            ...input,
                                            adultos:
                                                input.adultos > 0
                                                    ? input.adultos - 1
                                                    : input.adultos,
                                        })
                                    }
                                >
                                    <svg
                                        user-select="none"
                                        className="md:w-5 md:h-5 w-3 h-3 font-bold cursor-pointer hover:scale-110 hover:text-[#1C4CBB]"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <polyline points="6 9 12 15 18 9" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center text-white">
                    <div className="flex flex-col items-center">
                        <div className="text-xs uppercase lg:text-sm">
                            NIÑOS
                        </div>
                        <input
                            className="text-2xl font-bold bg-transparent outline-none cursor-pointer w-14 md:text-4xl lg:text-5xl"
                            type="number"
                            name="ninos"
                            value={input.ninos}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex my-auto">
                        <div className="flex-col mt-2 md:mt-7">
                            <div>
                                <button
                                    onClick={() =>
                                        setInput({
                                            ...input,
                                            ninos:
                                                Number(input.adultos) +
                                                    Number(input.ninos) <
                                                personas
                                                    ? Number(input.ninos) + 1
                                                    : input.ninos,
                                        })
                                    }
                                >
                                    <svg
                                        className="md:w-5 md:h-5 w-3 h-3 font-bold cursor-pointer hover:scale-110 hover:text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <polyline points="6 15 12 9 18 15" />
                                    </svg>
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() =>
                                        setInput({
                                            ...input,
                                            ninos:
                                                input.ninos > 0
                                                    ? input.ninos - 1
                                                    : input.ninos,
                                        })
                                    }
                                >
                                    <svg
                                        user-select="none"
                                        className="md:w-5 md:h-5 w-3 h-3 font-bold cursor-pointer hover:scale-110 hover:text-[#1C4CBB]"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <polyline points="6 9 12 15 18 9" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-5">
                    <div
                        onClick={handleModalReserva}
                        className="flex items-center justify-center p-3 text-xs font-bold text-center text-white hover:bg-white hover:text-gray-500 border-4 rounded-lg cursor-pointer lg:p-5 lg:text-sm"
                    >
                        CONSULTAR DISPONIBILIDAD
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col-reverse items-center justify-center gap-5 md:gap-8 2xl:mt-32 lg:mt-20 mt-5 md:p-5  lg:flex-row lg:mx-32">
                <div className="relative first-letter:w-full lg:w-2/3">
                    {tipo_descripcion === "Imagen" && (
                        <img
                            src={
                                typeof imagen_descripcion === "string"
                                    ? `${process.env.REACT_APP_PROXY}/casa/getImage/${imagen_descripcion}`
                                    : URL.createObjectURL(imagen_descripcion)
                            }
                            className="object-cover w-full rounded-lg h-[18rem] md:h-[20rem] 2xl:h-[28rem] "
                            alt={`video descripcion`}
                        />
                    )}

                    {tipo_descripcion === "Video" && (
                        <ReactPlayer
                            url={video_descripcion}
                            width="100%"
                            height="28rem"
                            controls
                            playing
                            muted
                            className="w-full rounded-lg h-[20rem] 2xl:h-[28rem]"
                        />
                    )}

                    {toggleButton && (
                        <div
                            onClick={() =>
                                setModalVisibleContenidoDescripcion(true)
                            }
                            title="editar descripción"
                            className="absolute top-10 left-10 px-2 py-1 flex flex-col items-center rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                        >
                            <svg
                                className="w-8 h-8 text-[#1C4CBB]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                            </svg>
                            <div className="text-xs text-[#1C4CBB]">
                                Editar Descripción
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex flex-col w-full h-full md:p-10 md:w-1/3 ">
                    <div
                        onClick={handleModalReserva}
                        className="bg-white lg:absolute flex items-center justify-between border-2 w-full lg:w-2/5 border-[#1c4cbb] text-[#1c4cbb] lg:-top-10 lg:right-10 p-4 lg:p-6 2xl:p-8 rounded-lg cursor-pointer"
                    >
                        <div></div>
                        <div className="font-bold">DETALLE DE RESERVA</div>
                        <div className="">
                            {" "}
                            <svg
                                user-select="none"
                                className="w-7 h-7 font-bold  hover:scale-110 text-[#1C4CBB]"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <polyline points="6 9 12 15 18 9" />
                            </svg>
                        </div>
                    </div>
                    <div className="mt-5 text-lg text-center text-gray-500 lg:mx-5 2xl:text-xl">
                        Presiona en detalles para añadir cúpon, garantia y
                        servicios adicionales
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reserva;
