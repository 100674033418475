import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../../components/helpers/Spinner";

import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import { Navbar } from "../Navbar";
import { NavbarCliente } from "../../dashboardClientes/NavbarCliente";
import { NavbarMobileCliente } from "../../dashboardClientes/NavbarMobileCliente";
import { NavbarMobile } from "../NavbarMobile";
import { Paginado } from "../../../components/Paginado";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/hooks";
import StarsRating from "../reservas/StarsRating";
import ToggleButton from "../../../components/helpers/ToggleButton";
import { ModalCrearUsuario } from "./ModalCrearUsuario";
import { ModalActualizarUsuario } from "./ModalActualizarUsuario";
import { ModalActualizarContrasenaUsuario } from "./ModalActualizarContrasenaUsuario";

interface Propietario {
    apellido: string;
    avatar: string;
    id: number;
    nombre: string;
}

interface casa {
    banner: string;
    id: number;
    nombre: string;
    Propietario: Propietario;
}

interface getComfort {
    habilitado: boolean;
}

export interface Pedido {
    id: number;
    fecha_inicio: string;
    fecha_final: string;
    Cliente: Cliente;
}

export interface Cliente {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}
export interface usuario {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    celular: string;
    avatar: string;
}

export const SubUsuarios = () => {
    const { headers } = useHeaders();
    const [modalVisibleUsuario, setModalVisibleUsuario] =
        useState<boolean>(false);
    const [modalVisibleEditarUsuario, setModalVisibleEditarUsuario] =
        useState<boolean>(false);
    const [
        modalVisibleEditaContrasenaUsuario,
        setModalVisibleEditarContrasenaUsuario,
    ] = useState<boolean>(false);

    const [usuarioSelect, setUsuarioSelect] = useState<usuario>();

    const [usuario, setUsuario] = useState<usuario[]>([]);

    const [resenasTotal, setResenasTotal] = useState<number>(0);
    const [enabled, setenabled] = useState<boolean>(true);
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [create, setcreate] = useState<boolean>(false);

    const [input, setInput] = useState<string>("");
    const debounceInput = useRef<ReturnType<typeof setTimeout> | null>(null);

    const [toggleButton, setToggleButton] = useState(false);
    const navigate = useNavigate();
    const RESERVAS_PER_PAGE = 20;
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const getComforts = async ({ habilitado }: getComfort) => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/subusuarios/getAll/${
                    habilitado ? "Si" : "No"
                }/`,
                { headers }
            );
            console.log(data);
            setUsuario(data);
            // setResenasTotal(data.count);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getComforts({ habilitado: enabled });
    }, [
        enabled,
        create,
        toggleButton,
        modalVisibleUsuario,
        modalVisibleEditarUsuario,
    ]);

    const handleEnable = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará la reseña a habilitados!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.put(
                        `${process.env.REACT_APP_PROXY}/resenas/putState/${id}`,
                        {
                            habilitado: "Si",
                        },
                        { headers }
                    );
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "reseña restaurada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getComforts({
                        habilitado: enabled,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleDelete = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará al usuario a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.put(
                        `${process.env.REACT_APP_PROXY}/subusuarios/putHabilitado/${id}`,
                        {
                            habilitado: "No",
                        },
                        { headers }
                    );
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        icon: "success",
                        title: "Usuario elimidado!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getComforts({
                        habilitado: enabled,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleDetalles = async (usuario: usuario) => {
        setUsuarioSelect(usuario);
        setModalVisibleEditarUsuario(true);
    };

    const pagination = (pageNumber: number) => {
        setpageNumber(pageNumber);
        getComforts({ habilitado: enabled });
    };

    const render = () => {
        return (
            <div className="w-full mt-12">
                <div className="flex items-center justify-between w-full px-6 mx-auto ">
                    <div className="">
                        <button
                            onClick={() => setModalVisibleUsuario(true)}
                            className="flex items-center gap-1 p-2 border rounded-lg border-[#CCCCCC] text-[#808080] hover:scale-105">
                            <svg
                                className="w-6 h-6 text-[#808080] group-hover:text-white"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                <line x1="5" y1="12" x2="19" y2="12" />
                            </svg>
                            <span>Crear usuario</span>
                        </button>
                    </div>
                </div>

                <section className="flex justify-center items-center lg:py-[10px] ">
                    <div className="w-full px-11">
                        <div className="flex flex-wrap -mx-8">
                            <div className="w-full ">
                                <div className="flex flex-col">
                                    {true ? (
                                        <div className="w-full ">
                                            <table className="w-full mt-8 overflow-x-auto 2xl:mt-14">
                                                <thead className="text-left border-b-[3px] border-[#CCCCCC] 2xl:text-base text-sm">
                                                    <tr>
                                                        <th className="w-full min-w-[10rem] md:w-4/12 pb-4 ">
                                                            Usuario
                                                        </th>
                                                        <th className="w-full min-w-[10rem] md:w-2/12 hidden lg:table-cell  text-center pb-4 ">
                                                            Correo
                                                        </th>
                                                        <th className="w-full min-w-[10rem] md:w-2/12 hidden lg:table-cell text-center pb-4 ">
                                                            Celular
                                                        </th>

                                                        <th className="w-1/12 text-right min-w-[8rem] pr-10 pb-4">
                                                            Acciones
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <div className="my-2 2xl:my-4"></div>
                                                <tbody className="">
                                                    {usuario?.map((reserva) => (
                                                        <tr
                                                            key={reserva.id}
                                                            className="w-full text-sm cursor-pointer hover:bg-gray-200 2xl:text-base"
                                                            onClick={() =>
                                                                handleDetalles(
                                                                    reserva
                                                                )
                                                            }>
                                                            <td className="border-r-[3px] border-[#CCCCCC]">
                                                                <div className="flex items-center py-3 ">
                                                                    <img
                                                                        src={
                                                                            `${process.env.REACT_APP_PROXY}/usuarios/getImage/` +
                                                                            reserva.avatar
                                                                        }
                                                                        alt="icono"
                                                                        className="object-cover w-10 h-10 rounded-full"
                                                                    />
                                                                    <h1 className="mx-3 text-[#3C3C3C]">
                                                                        {
                                                                            reserva.nombre
                                                                        }{" "}
                                                                        {
                                                                            reserva.apellido
                                                                        }
                                                                    </h1>
                                                                </div>
                                                            </td>
                                                            <td className="hidden text-center lg:table-cell border-r-[3px] border-[#CCCCCC]">
                                                                {reserva.correo}
                                                            </td>
                                                            <td className="hidden text-center lg:table-cell border-r-[3px] border-[#CCCCCC]">
                                                                {
                                                                    reserva.celular
                                                                }
                                                            </td>

                                                            <td
                                                                className={
                                                                    enabled
                                                                        ? " md:flex justify-end 2xl:py-5 py-3 px-2"
                                                                        : " md:flex  justify-center pl-7 md:justify-end 2xl:py-5 py-3 md:pr-10 "
                                                                }>
                                                                {enabled ? (
                                                                    <div className="flex">
                                                                        <button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                setUsuarioSelect(
                                                                                    reserva
                                                                                );
                                                                                setModalVisibleEditarContrasenaUsuario(
                                                                                    true
                                                                                );
                                                                            }}
                                                                            title="Editar contraseña"
                                                                            className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded bg-[#029BFC] text-blue-blueFrom border-[#029BFC] hover:scale-105 group hover:bg-blue-blueFrom hover:text-white">
                                                                            <svg
                                                                                className="w-5 h-5"
                                                                                id="Capa_2"
                                                                                data-name="Capa 2"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 75.12 100">
                                                                                <g id="Testimonios">
                                                                                    <path
                                                                                        fill="white"
                                                                                        d="m64.7,100H10.46c-.25-.07-.49-.16-.74-.22C4.11,98.5-.06,93.13,0,87.21c.13-12.39.04-24.78.04-37.16,0-4.16,1.72-7.5,5.02-9.96.97-.72,1.24-1.45,1.25-2.59.01-3.54-.28-7.09.24-10.62C9.23,8.96,26.12-2.92,43.87.63c14.34,2.86,24.95,15.73,25,30.34,0,2.34.04,4.68-.02,7.02-.02.84.25,1.35.94,1.85,3.49,2.53,5.33,5.95,5.32,10.31,0,12.52,0,25.04,0,37.56,0,2.66-.82,5.05-2.37,7.19-2,2.77-4.76,4.35-8.05,5.11Zm-27.19-62.51c3.77,0,7.54,0,11.31,0,2.15,0,4.29,0,6.44,0,.55,0,.97-.09.97-.78-.01-2.7.16-5.4-.24-8.08-1.44-9.77-10.29-16.73-20.05-15.83-7.88.72-14.58,6.39-16.45,14.08-.75,3.07-.55,6.2-.58,9.31,0,1.28.02,1.3,1.32,1.3,5.75,0,11.51,0,17.26,0Zm0,46.66c2.13,0,4.27-.04,6.4.02,1,.02,1.35-.35,1.33-1.34-.04-2.74,0-5.48-.02-8.21,0-.77.18-1.38.72-1.96,2.46-2.68,3.46-5.83,2.95-9.45-.72-5.11-5.04-9.84-11.45-9.72-4.83.09-8.48,2.49-10.36,7.03-1.83,4.42-1.03,8.52,2.16,12.1.52.59.75,1.18.74,1.95-.03,2.74.02,5.48-.03,8.21-.02,1.07.38,1.42,1.42,1.39,2.05-.06,4.11-.02,6.16-.02Z"
                                                                                    />
                                                                                </g>
                                                                            </svg>
                                                                        </button>
                                                                        {!toggleButton && (
                                                                            <button
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.stopPropagation();
                                                                                    handleDetalles(
                                                                                        reserva
                                                                                    );
                                                                                }}
                                                                                title="Editar datos"
                                                                                className="flex items-center justify-center w-8 h-8 bg-[#029BFC] px-1 py-1 mx-1 border rounded text-blue-blueFrom border-[#029BFC] group hover:bg-blue-blueFrom hover:white hover:scale-105">
                                                                                <svg
                                                                                    className="w-5 h-5"
                                                                                    id="Capa_2"
                                                                                    data-name="Capa 2"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 100 99.97">
                                                                                    <g id="Testimonios">
                                                                                        <g>
                                                                                            <path
                                                                                                fill="white"
                                                                                                d="m.01,95.86c1.03-2.98,1.79-6.04,2.66-9.06,1.65-5.68,3.25-11.36,4.88-17.05.35-1.21.41-1.22,1.3-.33,7.26,7.26,14.53,14.52,21.79,21.79.86.86.85.88-.32,1.21-8.47,2.41-16.93,4.82-25.39,7.23-.28.08-.54.21-.82.31-1.24,0-2.47-.02-3.71.01-.34,0-.41-.06-.4-.4.03-1.23.01-2.47.01-3.71Z"
                                                                                            />
                                                                                            <path
                                                                                                fill="white"
                                                                                                d="m36.11,88.77c-.31-.02-.47-.25-.65-.43-7.95-7.95-15.9-15.91-23.87-23.84-.72-.72-.2-1.05.23-1.48,8.48-8.48,16.96-16.96,25.44-25.44,7.95-7.95,15.9-15.9,23.86-23.85q.95-.95,1.88-.02c7.77,7.77,15.53,15.55,23.32,23.29.73.73.64,1.1-.03,1.77-16.45,16.42-32.87,32.86-49.3,49.29-.27.27-.49.61-.89.71Z"
                                                                                            />
                                                                                            <path
                                                                                                fill="white"
                                                                                                d="m78.52,0c2.53-.02,4.61.81,6.3,2.48,4.23,4.17,8.46,8.36,12.61,12.61,3.32,3.4,3.45,8.57.29,12.11-1.94,2.18-4.09,4.17-6.13,6.27-.61.63-.95.17-1.34-.22-4.55-4.55-9.1-9.1-13.65-13.65-3.29-3.29-6.56-6.58-9.87-9.84-.63-.62-.66-1.02,0-1.64,1.85-1.78,3.63-3.62,5.46-5.43C73.97.92,76.1-.04,78.52,0Z"
                                                                                            />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </button>
                                                                        )}

                                                                        <button
                                                                            className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded bg-[#029BFC] text-blue-blueFrom border-[#029BFC] hover:scale-105 group hover:bg-blue-blueFrom hover:text-white"
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                handleDelete(
                                                                                    reserva.id
                                                                                );
                                                                            }}
                                                                            title="Eliminar">
                                                                            <svg
                                                                                className="w-5 h-5 "
                                                                                id="Capa_2"
                                                                                data-name="Capa 2"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 91.81 100">
                                                                                <g id="Testimonios">
                                                                                    <path
                                                                                        fill="white"
                                                                                        d="m69.32,100H22.49c-.28-.09-.55-.19-.83-.27-5.22-1.49-8.55-4.86-9.37-10.22-.74-4.81-.65-9.72-.96-14.58-.26-3.99-.41-7.98-.63-11.97-.43-7.56-.88-15.11-1.32-22.67-.27-4.74-.54-9.47-.78-14.21-.04-.73-.35-.96-1.03-.94-1.2.03-2.41.04-3.61,0-2.56-.11-4.44-2.66-3.83-5.15.52-2.1,2.14-3.35,4.44-3.36,5.04-.01,10.08-.02,15.12,0,.89,0,1.24-.28,1.19-1.18-.06-1-.02-2.02-.01-3.02C20.88,5.58,26.42.03,33.28.02c8.39-.01,16.78-.04,25.17,0,5.22.03,8.92,2.59,11.31,7.15,1.24,2.37,1.24,4.94,1.2,7.52-.04,1.92,0,1.92,1.88,1.92,4.88,0,9.76-.02,14.63.01,2.49.02,4.38,1.96,4.34,4.34-.04,2.37-1.91,4.15-4.38,4.17-.81,0-1.63,0-2.44,0-1.82,0-1.75-.08-1.8,1.65-.02.49-.11.97-.13,1.46-.33,5.84-.65,11.68-.97,17.52-.39,7.1-.78,14.21-1.17,21.31-.39,7.04-.78,14.08-1.17,21.12-.2,3.61-1.72,6.55-4.42,8.92-1.73,1.52-3.81,2.33-6,2.88ZM45.9,16.62c5.13,0,10.27-.01,15.4.01.84,0,1.18-.27,1.14-1.13-.05-1,0-2.02-.02-3.02-.07-2.18-1.75-3.91-3.93-3.92-8.38-.03-16.77-.03-25.15,0-2.19,0-3.87,1.73-3.94,3.91-.03.94-.01,1.88,0,2.83,0,1.29.03,1.32,1.31,1.32,5.07,0,10.14,0,15.21,0Zm4.04,41.72c0-4,0-8,0-12,0-.45,0-.92-.11-1.35-.49-2.09-2.32-3.32-4.55-3.07-1.9.21-3.4,1.94-3.41,4.01-.01,8.19-.01,16.39,0,24.58,0,2.58,1.75,4.46,4.07,4.42,2.32-.04,3.99-1.86,4-4.4,0-4.06,0-8.13,0-12.19Zm16.61.07s0,0,0,0c0-4.26.03-8.52-.01-12.78-.02-1.92-1.53-3.52-3.36-3.72-2.16-.23-3.98.93-4.5,2.89-.16.6-.21,1.21-.21,1.83,0,4.62,0,9.23,0,13.85,0,3.35-.03,6.7,0,10.05.03,2.96,2.41,4.91,5.09,4.22,1.77-.45,2.96-1.94,2.97-3.86.03-4.16,0-8.32,0-12.48Zm-33.21.01c0-3.93,0-7.87,0-11.8,0-.62-.06-1.24-.22-1.83-.52-1.93-2.27-3.07-4.41-2.88-1.76.16-3.41,1.68-3.42,3.45-.05,8.68-.06,17.36,0,26.04.02,2.19,2.43,3.87,4.58,3.44,2.19-.44,3.43-2,3.45-4.42.02-4,0-8,0-12Z"
                                                                                    />
                                                                                </g>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                handleEnable(
                                                                                    reserva.id
                                                                                );
                                                                            }}
                                                                            title="Habilitar"
                                                                            className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:white">
                                                                            <svg
                                                                                className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round">
                                                                                {" "}
                                                                                <polyline points="17 1 21 5 17 9" />{" "}
                                                                                <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                <polyline points="7 23 3 19 7 15" />{" "}
                                                                                <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <Spinner />
                                    )}
                                </div>

                                <div className="container flex items-center justify-between p-1 mx-auto">
                                    <Paginado
                                        elementsPerPage={RESERVAS_PER_PAGE}
                                        cantTotalElements={resenasTotal}
                                        pagination={pagination}
                                        actualPage={pageNumber}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    };

    return (
        <div>
            <div className="absolute z-[999] flex items-center justify-center w-full text-center">
                {modalVisibleUsuario && (
                    <ModalCrearUsuario
                        modalVisibleUsuario={modalVisibleUsuario}
                        setModalVisibleUsuario={setModalVisibleUsuario}
                    />
                )}
                {modalVisibleEditarUsuario && usuarioSelect && (
                    <ModalActualizarUsuario
                        modalVisibleEditarUsuario={modalVisibleEditarUsuario}
                        setModalVisibleEditarUsuario={
                            setModalVisibleEditarUsuario
                        }
                        usuarioSelect={usuarioSelect}
                    />
                )}
                {modalVisibleEditaContrasenaUsuario && usuarioSelect && (
                    <ModalActualizarContrasenaUsuario
                        modalVisibleEditarUsuario={
                            modalVisibleEditaContrasenaUsuario
                        }
                        setModalVisibleEditarUsuario={
                            setModalVisibleEditarContrasenaUsuario
                        }
                        usuarioSelect={usuarioSelect}
                    />
                )}
            </div>

            {tipo_usuario === 3 ? (
                <Navbar titulo="Sub Usuarios">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : (
                <NavbarCliente>
                    {render()}
                    <NavbarMobileCliente />
                </NavbarCliente>
            )}
        </div>
    );
};
