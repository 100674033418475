import React from "react";
import MainNavbar from "../../components/MainNavbar/MainNavbar";

import { Footer } from "../../components/Footer";
import PrincipalContent from "./PrincipalContent";

const BlogHome = () => {
    return (
        <div>
            <MainNavbar />
            <PrincipalContent />
            <Footer />
        </div>
    );
};

export default BlogHome;
