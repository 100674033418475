import React, { useEffect, useState } from "react";
import { InputDate } from "../../../../components/InputDate";
import ResponsiveModalAbsolute from "../../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../../helpers/fetchAxiosToken";
import { ShowDateToUser } from "../../../../components/ShowDateToUser";
import { tarifaAvanzada } from "./TarifaAvanzada";

interface ModalProps {
    tarifaSelected: tarifaAvanzada | null;
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    getTarifas: () => void;
}

type errorObject = { [key: string]: string };

export const EditarTarifaAvanzada = ({
    modalVisible,
    setModalVisible,
    getTarifas,
    tarifaSelected,
}: ModalProps) => {
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState<tarifaAvanzada>({
        fecha_inicio: "",
        fecha_final: "",
        precio: "",
    });
    const [errors, setErrors] = useState<tarifaAvanzada>({});
    useEffect(() => {
        if (tarifaSelected) {
            setInput({
                fecha_inicio: tarifaSelected.fecha_inicio,
                fecha_final: tarifaSelected.fecha_final,
                precio: tarifaSelected.precio,
            });
        }
    }, [tarifaSelected]);

    const validateInput = (form: tarifaAvanzada) => {
        let errores: errorObject = {};

        if (!form.fecha_inicio) {
            errores.fecha_inicio = "Debe ingresar una fecha inicial";
        } else if (!form.fecha_final) {
            errores.fecha_final = "Debe ingresar una fecha final";
        } else if (!form.precio || Number(form.precio) < 0) {
            errores.precio = "Debe ingresar un precio válido";
        }

        return errores;
    };

    const handleSubmit = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            try {
                setLoading(true);
                const data = await fetchAxiosToken({
                    url: `/servicios_adicionales_avanzado/put/${tarifaSelected?.id}`,
                    body: {
                        ...input,
                        id_servicios_adicionales:
                            tarifaSelected?.id_servicios_adicionales,
                    },
                    method: "put",
                });

                setLoading(false);
                setInput({
                    fecha_final: "",
                    fecha_inicio: "",
                    precio: "",
                });
                getTarifas();
                setModalVisible(false);
                setErrors({});
                getTarifas();
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            onClose={() => {
                setErrors({});
            }}
        >
            <div className="flex flex-col gap-2 p-3">
                <h1 className="2xl:text-lg text-base font-medium text-center">
                    Editar configuración de tarifa avanzada
                </h1>
                <div className={`flex flex-col gap-1 mt-4 p-3`}>
                    <label className="2xl:text-base text-sm">
                        Fecha inicial
                    </label>
                    <InputDate
                        className="border-2 2xl:text-base text-sm 2xl:p-3 p-2"
                        selectedDay={input.fecha_inicio}
                        placeholderText="13/05/2023"
                        onChange={(date) =>
                            setInput({
                                ...input,
                                fecha_inicio: date,
                            })
                        }
                    />
                    <label className="2xl:text-base text-sm">Fecha final</label>
                    <InputDate
                        className="border-2 2xl:text-base text-sm 2xl:p-3 p-2"
                        selectedDay={input.fecha_final}
                        placeholderText="13/08/2023"
                        onChange={(date) =>
                            setInput({
                                ...input,
                                fecha_final: date,
                            })
                        }
                    />
                    <label className="2xl:text-base text-sm">Precio</label>
                    <input
                        value={input.precio}
                        onChange={(e) =>
                            setInput({
                                ...input,
                                precio: e.target.value,
                            })
                        }
                        type={"text"}
                        className="2xl:text-base text-sm 2xl:p-3 p-2 border-2 rounded-lg"
                        placeholder="250.00"
                    />
                    <button
                        disabled={loading}
                        onClick={() => handleSubmit()}
                        className="max-w-xs p-3 mt-4 border-2 rounded-lg hover:bg-blue-blueFrom hover:text-white disabled:bg-gray-400 disabled:translate-y-0 active:translate-y-1 2xl:text-base text-sm mx-auto"
                    >
                        Guardar configuración
                    </button>
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
