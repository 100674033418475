interface Props {
    fecha_inicio: Date;
    fecha_final: Date;
    fecha_evaluada: Date;
}

export const DateIsBetween2Dates = ({
    fecha_inicio,
    fecha_final,
    fecha_evaluada,
}: Props): boolean => {
    // const fechaInicio = new Date(fecha_inicio + "T00:00:00");

    // const fechaFin = new Date(fecha_final + "T00:00:00");

    return fecha_evaluada >= fecha_inicio && fecha_evaluada <= fecha_final;
};
