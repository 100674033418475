import { useEffect, useState } from "react";

import { useAppSelector } from "../../hooks/hooks";
import { ModalEditImageCarrousel } from "../modalEdit/ModalEditImageCarrousel";
import ModalReserva from "./ModalReserva/ModalReserva";
import { ModalEditPrecioBase } from "../modalEdit/ModalEditPrecioBase";
import { CapacidadesCasa } from "../capacidades/CapacidadesCasa";
import { ModalEditName } from "../modalEdit/ModalEditName";
import { ModalGaleriaImagenes } from "../modalEdit/ModalGaleriaImagenes";

import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "../../assets/css/swiper_pagination.css";
import { usePriceToSelectedCurrency } from "../../hooks/usePriceToSelectedCurrency";
import useCurrencyPrices from "../../hooks/useCurrencyPrices";
import { formatearNumero } from "../../components/helpers/formatearNumero";

interface Props {
    toggleButton: boolean;
}

const Carrousel = ({ toggleButton }: Props) => {
    const {
        nombre,
        banner,
        precio_base,
        tipo_moneda,
        estadia_minima,
        casa_imagenes,
    } = useAppSelector((state) => state.landing_house.house);

    const [modalVisibleCarrousel, setModalVisibleCarrousel] =
        useState<boolean>(false);
    const [editPrice, setEditPrice] = useState<boolean>(false);
    const [editName, setEditName] = useState<boolean>(false);
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const { price_to_currency } = usePriceToSelectedCurrency();
    const { SELECTED_CURRENCY } = useCurrencyPrices();

    const [input, setInput] = useState({
        huespedes: 0,
        fecha_inicio: "",
        fecha_final: "",
    });

    useEffect(() => {
        if (banner) {
            setTimeout(() => {
                setIsFocus(true);
            }, 1500);
        }
    }, [banner]);

    const [modalVisibleReserva, setModalVisibleReserva] =
        useState<boolean>(false);
    const [modalImagesVisible, setModalImagesVisible] = useState(false);
    return (
        <div>
            <div className="relative">
                {modalVisibleReserva && (
                    <ModalReserva
                        modalVisible={modalVisibleReserva}
                        setModalVisible={setModalVisibleReserva}
                        toggleButton={toggleButton}
                        input={input}
                    />
                )}
                {modalVisibleCarrousel && (
                    <ModalEditImageCarrousel
                        modalVisible={modalVisibleCarrousel}
                        setModalVisible={setModalVisibleCarrousel}
                    />
                )}

                {editPrice && (
                    <ModalEditPrecioBase
                        modalVisible={editPrice}
                        setModalVisible={setEditPrice}
                    />
                )}
                {editName && (
                    <ModalEditName
                        modalVisible={editName}
                        setModalVisible={setEditName}
                    />
                )}

                {modalImagesVisible && (
                    <ModalGaleriaImagenes
                        imagen_principal={banner}
                        imagenes={casa_imagenes}
                        modalVisible={modalImagesVisible}
                        setModalVisible={setModalImagesVisible}
                    />
                )}

                <img
                    src={
                        typeof banner === "string"
                            ? `${process.env.REACT_APP_PROXY}/casa/getImage/${banner}`
                            : URL.createObjectURL(banner)
                    }
                    className=" object-cover w-full h-full max-h-[500px] "
                    alt={`banner`}
                />

                {toggleButton && (
                    <div
                        onClick={() => setModalVisibleCarrousel(true)}
                        className="absolute top-8  w-full h-[2.5rem] left-4 z-50"
                    >
                        <div className="flex ">
                            <div
                                title="editar fotos"
                                className="flex flex-col justify-center items-center z-20 px-2 py-1 mr-6 rounded-lg cursor-pointer opacity-60 bg-slate-200 hover:opacity-100"
                            >
                                <svg
                                    className="w-12 h-12 text-[#1C4CBB]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                    <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                    />
                                </svg>
                                <div className="text-xs text-[#1C4CBB]">
                                    Editar Fotos
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div
                    className={`${
                        !isFocus ? "hidden" : "opacity-100"
                    } transition-all ease-in-out duration-700 absolute hidden md:flex gap-3 b px-8 sm:px-16 md:py-10 items-end justify-between h-full w-full  max-h-[500px] bg-gradient-to-t from-black/70 top-0`}
                >
                    <div className="flex flex-col ">
                        <div className="flex justify-start select-none">
                            <CapacidadesCasa
                                toggleButton={toggleButton}
                                colorIcono={"#ffffff"}
                                opacity={"0%"}
                            />
                        </div>
                        <div className="flex ">
                            <div className="mt-2 font-bold text-white uppercase text-4xl lg:text-5xl 2xl:text-6xl">
                                {nombre}
                            </div>
                            {toggleButton && (
                                <div
                                    onClick={() => setEditName(!editName)}
                                    title="editar nombre"
                                    className="flex flex-col items-center px-2 py-1 my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                                >
                                    <svg
                                        className="w-10 h-10 text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                        <line
                                            x1="13.5"
                                            y1="6.5"
                                            x2="17.5"
                                            y2="10.5"
                                        />
                                    </svg>
                                    <div className="text-xs text-[#1C4CBB]">
                                        Editar Nombre
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col justify-center">
                        <div className="text-right text-white ">
                            <div className="flex items-center">
                                <div className="justify-center w-full mt-5 text-center lg:text-right lg:mt-0">
                                    <p className="mb-2 text-2xl font-light lg:text-4xl 2xl:text-5xl ">
                                        DESDE
                                    </p>
                                    <p className="mb-2 text-3xl font-bold md:text-4xl lg:text-5xl 2xl:text-6xl ">
                                        {SELECTED_CURRENCY}
                                        {formatearNumero(
                                            price_to_currency(
                                                Number(precio_base)
                                            )
                                        )}
                                    </p>
                                    <p className="mt-2 text-2xl font-light lg:text-3xl 2xl:text-4xl ">
                                        por noche
                                    </p>
                                    <p className="mt-2 text-xl xl:text-2xl 2xl:text-3xl xs:mb-8 ">
                                        Mínimo {estadia_minima}{" "}
                                        {estadia_minima === 1
                                            ? "noche"
                                            : "noches"}
                                    </p>
                                </div>

                                {toggleButton && (
                                    <div
                                        onClick={() => setEditPrice(!editPrice)}
                                        title="editar Precio base"
                                        className="flex flex-col items-center justify-center  px-2 py-1 mx-auto my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                                    >
                                        <svg
                                            className="w-8 h-8 text-[#1C4CBB]"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            {" "}
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                            />{" "}
                                            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                            <line
                                                x1="13.5"
                                                y1="6.5"
                                                x2="17.5"
                                                y2="10.5"
                                            />
                                        </svg>
                                        <div className="text-xs text-[#1C4CBB] text-center">
                                            Editar Precio
                                        </div>
                                    </div>
                                )}
                            </div>

                            <button
                                onClick={() => setModalVisibleReserva(true)}
                                className="flex w-full gap-1 px-2 py-4 text-white  rounded-md cursor-pointer bg-[#1E90FF] hover:scale-105 text-sm select-none disabled:bg-slate-300 disabled:scale-100 "
                            >
                                <p className="mx-auto font-bold">
                                    {" "}
                                    RESERVAR AHORA
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center mt-5 md:hidden ">
                <div className="flex ">
                    <CapacidadesCasa
                        toggleButton={toggleButton}
                        colorIcono={"#1C4CBB"}
                    />
                </div>
                <div className="flex ">
                    <div className="text-[#1C4CBB] text-2xl text-center mt-2 font-bold uppercase">
                        {nombre}
                    </div>
                    {toggleButton && (
                        <div
                            onClick={() => setEditName(!editName)}
                            title="editar nombre"
                            className="flex items-center px-2 py-1 my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                        >
                            <svg
                                className="w-10 h-10 text-[#1C4CBB]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                            </svg>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex flex-col items-center justify-center w-full md:hidden lg:mx-10">
                <div
                    className={` flex flex-col gap-3 lg:px-8 items-center justify-center lg:items-end lg:justify-end lg:w-1/3 w-full h-full lg:mx-10`}
                >
                    <div className="flex items-center">
                        <div className="w-full justify-center text-center lg:text-right text-[#1c4cbb] mt-5 lg:mt-0">
                            <p className="mb-1 text-xl font-light ">DESDE</p>
                            <p className="mb-1 text-2xl font-bold md:text-4xl lg:text-5xl 2xl:text-6xl ">
                                {tipo_moneda} {precio_base}
                            </p>
                            <p className="mt-1 text-xl font-light lg:text-3xl 2xl:text-4xl ">
                                por noche
                            </p>
                            <p className="mt-1 text-lg lg:text-2xl 2xl:text-3xl xs:mb-8">
                                Mínimo {estadia_minima}{" "}
                                {estadia_minima === 1 ? "noche" : "noches"}
                            </p>
                        </div>
                        {toggleButton && (
                            <div
                                onClick={() => setEditPrice(!editPrice)}
                                title="editar Precio base"
                                className="flex items-center justify-center px-2 py-1 mx-auto my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                            >
                                <svg
                                    className="w-8 h-8 text-[#1C4CBB]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                    <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                    />
                                </svg>
                            </div>
                        )}
                    </div>
                </div>

                <button
                    onClick={() => setModalVisibleReserva(true)}
                    className="flex w-3/4 gap-1 px-2 py-4 text-white rounded-md cursor-pointer bg-[#1E90FF] hover:scale-105 text-sm select-none disabled:bg-slate-300 disabled:scale-100 mt-5"
                >
                    <p className="mx-auto font-bold"> RESERVAR AHORA</p>
                </button>
            </div>
            <div className="flex flex-col-reverse items-center justify-center w-full gap-5 mx-auto mt-10 lg:flex-row lg:justify-between md:w-4/5 lg:w-3/4 2xl:w-2/3">
                <div>
                    <div
                        onClick={() => setModalImagesVisible(true)}
                        className="flex justify-center items-center hover:scale-105 bg-[#0096fc] text-white text-sm 2xl:text-base py-3 md:py-4 px-12 md:px-10 rounded-lg mx-auto text-center cursor-pointer"
                    >
                        <p className="mx-auto font-bold">VER GALERÍA</p>
                    </div>
                </div>
                <div className="2xl:w-3/4 w-4/5 ">
                    <Swiper
                        modules={[Navigation, Autoplay, EffectFade, Pagination]}
                        className=" 2xl:h-60 h-52 sample-slider"
                        spaceBetween={20}
                        slidesPerView={1}
                        autoplay={{ delay: 5000 }}
                        breakpoints={{
                            560: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                        }}
                        loop
                        pagination={{ clickable: true }}
                    >
                        {casa_imagenes.map((image, index) => (
                            <SwiperSlide key={index}>
                                <div className="h-full ">
                                    <img
                                        src={`${process.env.REACT_APP_PROXY}/casa/getImage/${image.imagen}`}
                                        className="object-cover w-full h-full mx-auto rounded-lg"
                                        alt={`slide ${index + 1}`}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Carrousel;
