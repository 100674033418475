import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

interface Props {
    userSelected: {
        id: number;
        nombre: string;
        apellido: string;
        correo: string;
    } | null;
    modalVisible: boolean;
    setModalVisible: (isOpen: boolean) => void;
    setRefreshUsers: (prev: any) => void;
}

interface RegisterForm {
    nombre?: string;
    apellido?: string;
    correo?: string;
}

type errorObject = { [key: string]: string };

const INITIAL_STATE: RegisterForm = {
    nombre: "",
    apellido: "",
    correo: "",
};

const ModalEditUser = ({
    modalVisible,
    setModalVisible,
    userSelected,
    setRefreshUsers,
}: Props) => {
    const [input, setInput] = useState<RegisterForm>(INITIAL_STATE);
    const [errors, setErrors] = useState<RegisterForm>({});

    useEffect(() => {
        if (modalVisible && userSelected) {
            setInput(userSelected);
        }
    }, [modalVisible]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const validateInput = (form: RegisterForm) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        let errores: errorObject = {};

        if (!form.nombre) {
            errores.nombre = "Debe ingresar un nombre válido";
        } else if (!form.apellido) {
            errores.apellido = "Debe ingresar un apellido válido";
        } else if (!regExpEmail.test(form.correo || "")) {
            errores.correo = "Debe ingresar un dirección de email válida";
        }

        return errores;
    };

    const handleSubmit = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            try {
                const data = await fetchAxiosToken({
                    url: `/administrador/update/${userSelected!.id}`,
                    method: "put",
                    body: input,
                });
                setModalVisible(false);
                setRefreshUsers((prev: boolean) => !prev);
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    position: "top-end",
                    icon: "success",
                    title: "El usuario ha sido creado correctamente!",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } catch (error: any) {
                console.log(error.response);
            }
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}>
            <div className="flex flex-col gap-4 p-4 md:p-10 max-w-lg items-center mx-auto">
                <h2 className="font-medium text-base">
                    Editar usuario administrador
                </h2>
                <div className="w-full">
                    <label className="font-medium text-sm">Nombre</label>
                    <input
                        value={input.nombre}
                        onChange={handleInputChange}
                        name="nombre"
                        type={"text"}
                        className="shadow-md p-3 rounded-lg mt-1 w-full border placeholder:italic"
                        placeholder="nombre"
                    />
                    {errors.nombre && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.nombre}
                        </p>
                    )}
                </div>
                <div className="w-full">
                    <label className="font-medium text-sm">Apellido</label>
                    <input
                        value={input.apellido}
                        onChange={handleInputChange}
                        name="apellido"
                        type={"text"}
                        className="shadow-md p-3 rounded-lg mt-1 w-full border placeholder:italic"
                        placeholder="apellido"
                    />
                    {errors.apellido && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.apellido}
                        </p>
                    )}
                </div>
                <div className="w-full">
                    <label className="font-medium text-sm">
                        Correo electrónico
                    </label>
                    <input
                        value={input.correo}
                        onChange={handleInputChange}
                        name="correo"
                        type={"email"}
                        className="shadow-md p-3 rounded-lg mt-1 w-full border placeholder:italic"
                        placeholder="ejemplo@gmail.com"
                    />
                    {errors.correo && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.correo}
                        </p>
                    )}
                </div>
                <div className="flex flex-col gap-4 mt-8">
                    <button
                        onClick={() => handleSubmit()}
                        className="border bg-[#1C4CBB] text-white p-3 rounded-lg px-5 hover:scale-105">
                        Guardar cambios
                    </button>
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};

export default ModalEditUser;
