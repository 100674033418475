const useHeaders = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    return { headers };
};

export default useHeaders;
