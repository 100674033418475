import { useState } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { ModalEditComforts } from "../modalEdit/ModalEditComforts";

interface Props {
    toggleButton: boolean;
}
const Servicios = ({ toggleButton }: Props) => {
    const {
        id_propietario,
        casa_comodidades,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);
    const [modalVisibleComodidades, setModalVisibleComodidades] =
        useState<boolean>(false);

    return (
        <>
            <div className="2xl:mt-10 bg-[#F2F5FF] px-4 md:px-8 xs:px-16 py-4 md:py-4 2xl:py-12 ">
                <div className="flex items-center justify-center ">
                    {modalVisibleComodidades && (
                        <ModalEditComforts
                            setModalVisibleComodidades={
                                setModalVisibleComodidades
                            }
                            modalVisibleComodidades={modalVisibleComodidades}
                        />
                    )}
                    <div className={"flex justify-center px-5 "}>
                        {toggleButton && (
                            <div
                                onClick={() => setModalVisibleComodidades(true)}
                                title="editar servicios"
                                className="flex flex-col items-center px-2 py-1 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                            >
                                <svg
                                    className="w-8 h-8 text-[#1C4CBB]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                    <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                    />
                                </svg>
                                <div className="text-xs text-[#1C4CBB] text-center">
                                    Editar Servicios
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="bg-white rounded-lg ">
                    <p className="font-bold text-[#029BFC] text-lg lg:text-2xl 2xl:text-3xl text-center 2xl:pt-5 pb-6 py-5">
                        SERVICIOS
                    </p>

                    <div className="grid xs:grid-cols-2  gap-6 pb-6 overflow-y-auto md:justify-center max-h-[270px]">
                        {casa_comodidades.map((service) => (
                            <div
                                className=" flex text-center  gap-4 items-center min-w-[205px] mx-auto"
                                key={service.id}
                            >
                                <img
                                    className="w-8 h-8 object-contain"
                                    src={
                                        `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/comodidad/getImage/` +
                                        service.comodidade.icono
                                    }
                                    alt=""
                                />
                                <h2 className="font-medium text-gray-500">
                                    {service.comodidade.nombre}
                                </h2>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Servicios;
