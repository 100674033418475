import { configureStore } from "@reduxjs/toolkit";
import usuario_logeado from "../redux/reducers/authReducer";
import InformacionReducer from "../redux/reducers/InformacionReducer";
import landing_house from "../redux/reducers/landingReducer";
import onboarding_house from "../redux/reducers/onboardingReducer";
import precio_reserva from "../redux/reducers/precioReservaReducer";
import activar_socket from "../redux/reducers/socketReducer";
import corredor from "../redux/reducers/corredorReducer";

// ...

export const store = configureStore({
    reducer: {
        usuario_logeado: usuario_logeado,
        onboarding_house: onboarding_house,
        landing_house: landing_house,
        precios_reserva: precio_reserva,
        informacion: InformacionReducer,
        activarSocket: activar_socket,
        corredor: corredor,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
