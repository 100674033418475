import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

interface Props {
    setModalVisible: (modalVisible: boolean) => void;
    modalVisible: boolean;
    loginAdmin: null | boolean;
}

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

export const ModalRecuperarContrasena = ({
    setModalVisible,
    modalVisible,
    loginAdmin,
}: Props) => {
    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        correo: "",
    });

    const INPUTS = [
        {
            input_id: 0,
            label: "Correo",
            nombre: "correo",
            type: "text",
        },
    ];

    const [saving, setSaving] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = async () => {
        const form = new FormData();

        if (userInfo.correo === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: "debe ingresar su correo",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }

        setSaving(true);

        try {
            const json = await fetchAxiosToken({
                url: `/usuarios/emailPassword/`,
                method: "put",
                body: {
                    correo: userInfo.correo,
                    is_admin: loginAdmin,
                },
            });
            console.log(json);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                position: "top-end",
                icon: "success",
                title: "correo enviado correctamente!",
                showConfirmButton: false,
                timer: 1500,
            });
            setUserInfo({ correo: "" });
            setModalVisible(false);
            setSaving(false);
        } catch (error: any) {
            console.log(error);
            setSaving(false);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: `${error.response.data.error}`,
                showConfirmButton: true,
                // timer: 1500,
            });
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-base font-medium">Recuperar contraseña</h2>
                <hr className="w-full bg-gray-300" />

                <div className="flex flex-col items-center w-full p-6">
                    {INPUTS.map(({ nombre, input_id, type, label }) => (
                        <div key={input_id} className="w-full max-w-xl">
                            <label className="ml-1 font-medium">{label}</label>
                            <input
                                className="w-full p-2 mt-1 border rounded-xl"
                                placeholder={label}
                                name={nombre}
                                value={userInfo[nombre]}
                                onChange={handleInputChange}
                                type={type}
                            />
                        </div>
                    ))}
                </div>

                <div className="flex flex-col gap-4 mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSave()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                            Enviar
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
