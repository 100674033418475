import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";

import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { ReactSortable } from "react-sortablejs";
import ModalNewEspacio from "./ModalNewEspacio";
import ModalEditEspacio from "./ModalEditEspacio";
import { useParams } from "react-router-dom";

interface Images {
    id: number;
    id_casa: number;
    imagen: string;
}

export interface Espacios {
    id: number;
    id_casa: number;
    nombre: string;
    orden: number;
    imagenes: EspaciosImagenes[];
}

export interface EspaciosImagenes {
    id: number;
    id_casa: number;
    imagen: string;
    id_espacio_imagen: number;
    orden: number;
}
interface Props {
    modalVisible: boolean;
    setModalVisible: (s: boolean) => void;
}

export const ModalEditImageCarrousel = ({
    modalVisible,
    setModalVisible,
}: Props) => {
    const { banner, id: casa_id } = useAppSelector(
        (state) => state.landing_house.house
    );
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const [bannerCasa, setBannerCasa] = useState<string | File>(banner);
    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [espacios, setEspacios] = useState<Espacios[]>([]);
    const [espaciosId, setEspaciosId] = useState<Espacios | null>(null);
    const [espacioNew, setEspacioNew] = useState<boolean>(false);
    useEffect(() => {
        cargarEtiquetas();
    }, [espacioNew, espaciosId]);
    console.log(espacioNew);
    const cargarEtiquetas = async () => {
        const data = await fetchAxiosToken({
            url: `/espacios_imagenes/getAll/${casa_id}`,
            method: "get",
        });

        console.log("cargarEtiquetas", data);
        setEspacios(data);
    };

    const multiLoadFile = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const form = new FormData();

        setLoading(true);
        form.append("id_casa", `${casa_id}`);
        if (event.target.files) {
            if (event.target.files.length > 0) {
                for (let i = 0; i < event.target.files.length; i++) {
                    form.append("imagenes_casa", event.target.files[i]);
                }
            }
        }

        try {
            const data = await fetchAxiosToken({
                url: `/casa_imagenes/`,
                method: "post",
                body: form,
            });

            dispatchDominio();
        } catch (error: any) {
            console.log(error.response);
        }

        setLoading(false);
    };

    const handleChangeBanner = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setBannerCasa(e.target.files[0]);
        }
    };

    const cambioDeEspacios = async () => {
        let array_orden: number[] = [];
        espacios.forEach((imagen) => {
            array_orden.push(imagen.id);
        });
        console.log(array_orden);

        const data = await fetchAxiosToken({
            url: `/espacios_imagenes/updateOrden`,
            method: "put",
            body: { array_espacios: array_orden },
        });
    };

    useEffect(() => {
        espacios.length && cambioDeEspacios();
    }, [espacios]);

    const handleSubmit = async () => {
        if (typeof bannerCasa !== "string") {
            setSaving(true);

            const form = new FormData();
            form.append("banner_casa", bannerCasa);
            try {
                const data = await fetchAxiosToken({
                    url: `/casa/putBanner/${casa_id}`,
                    method: "put",
                    body: form,
                });

                dispatchDominio();

                setTimeout(() => {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        title: "Actualizada",
                        text: "Imagen actualizada con exito",
                        icon: "success",
                        confirmButtonText: "Ok",
                    });

                    setSaving(false);
                    setModalVisible(false);
                }, 2500);
            } catch (error: any) {
                console.log(error.response);
            }
        } else {
            setModalVisible(false);
        }
    };
    const handleDeleteEspacio = async (id: number) => {
        try {
            const json = await fetchAxiosToken({
                url: `/espacios_imagenes/remove/${id}`,
                method: "delete",
            });
            console.log(json);

            cargarEtiquetas();
        } catch (error: any) {
            console.log(error);
            if (
                error.response?.data?.error ===
                "No se puede eliminar el espacio porque contiene imagenes"
            ) {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "No se puede eliminar el espacio porque contiene imagenes",
                    icon: "warning",

                    confirmButtonText: "Ok!",
                });
                return;
            }
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            espacioNew={espacioNew}
            setModalVisible={setModalVisible}>
            {espacioNew && (
                <ModalNewEspacio
                    setEspacioNew={setEspacioNew}
                    casaId={casa_id}
                />
            )}
            {espaciosId && (
                <ModalEditEspacio
                    setEspaciosEdit={setEspaciosId}
                    casaId={casa_id}
                    espacio={espaciosId}
                />
            )}
            {!espacioNew && !espaciosId && (
                <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                    <h2 className="text-base font-medium">Editar imagenes</h2>
                    <hr className="w-full bg-gray-300" />

                    <p className="w-full ">Banner</p>

                    <div>
                        <div className="relative flex items-center justify-center w-full h-48 mt-1 rounded-lg hover:cursor-pointer">
                            <div className="absolute w-full">
                                <div>
                                    <img
                                        src={
                                            typeof bannerCasa === "string"
                                                ? `${process.env.REACT_APP_PROXY}/casa/getImage/${bannerCasa}`
                                                : URL.createObjectURL(
                                                      bannerCasa
                                                  )
                                        }
                                        className="object-cover w-full h-48 mx-auto rounded-lg 2xl:h-52"
                                        alt="Banner"
                                    />
                                </div>
                            </div>
                            <input
                                accept="image/*"
                                type="file"
                                name="icono_si"
                                className="w-full rounded-lg opacity-0 cursor-pointer h-52 "
                                onChange={handleChangeBanner}
                            />
                        </div>
                    </div>
                    <hr className="w-full mt-4 bg-gray-300" />

                    <div className="w-full">
                        <div>
                            <p
                                onClick={() => setEspacioNew(true)}
                                className="flex items-center p-2 my-4 text-sm text-center border rounded-lg cursor-pointer 2xl:my-6 border-blue-blueFrom text-blue-blueFrom w-36 hover:scale-105 2xl:text-base">
                                {" "}
                                + Nuevo espacio
                            </p>
                            <div>
                                <p className="text-lg font-semibold 2xl:text-xl">
                                    Espacios:
                                </p>
                                <ReactSortable
                                    handle=".handle"
                                    className="gap-2 mt-4"
                                    group="espacios"
                                    animation={200}
                                    delay={2}
                                    list={espacios}
                                    setList={setEspacios}>
                                    {espacios?.map((espacio, index) => (
                                        <div
                                            key={index}
                                            className="relative p-4 mt-2 text-sm border border-gray-200 rounded-md  2xl:p-6 2xl:text-base handle">
                                            <p
                                                onClick={() =>
                                                    setEspaciosId(espacio)
                                                }
                                                className="cursor-pointer ">
                                                {espacio.nombre}
                                            </p>
                                            <div
                                                onClick={() =>
                                                    handleDeleteEspacio(
                                                        espacio.id
                                                    )
                                                }
                                                className="absolute z-10 rounded-md cursor-pointer top-1 right-1 bg-slate-200 hover:scale-105">
                                                <svg
                                                    className="w-5 h-5 text-blue-500 2xl:w-6 2xl:h-6"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="9"
                                                    />{" "}
                                                    <path d="M10 10l4 4m0 -4l-4 4" />
                                                </svg>
                                            </div>
                                            <div className="absolute z-10 rounded-md cursor-move bottom-1 right-1 bg-slate-200 hover:scale-105 ">
                                                <svg
                                                    className="w-5 h-5 text-blue-500 2xl:w-6 2xl:h-6"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <polyline points="5 9 2 12 5 15" />{" "}
                                                    <polyline points="9 5 12 2 15 5" />{" "}
                                                    <polyline points="15 19 12 22 9 19" />{" "}
                                                    <polyline points="19 9 22 12 19 15" />{" "}
                                                    <line
                                                        x1="2"
                                                        y1="12"
                                                        x2="22"
                                                        y2="12"
                                                    />{" "}
                                                    <line
                                                        x1="12"
                                                        y1="2"
                                                        x2="12"
                                                        y2="22"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    ))}
                                </ReactSortable>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4 mt-4 2xl:mt-8">
                        {saving ? (
                            <Spinner />
                        ) : (
                            <button
                                onClick={() => handleSubmit()}
                                className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 2xl:text-base text-sm">
                                Guardar cambios
                            </button>
                        )}
                    </div>
                </div>
            )}
        </ResponsiveModalAbsolute>
    );
};
