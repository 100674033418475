import React, { useEffect, useState } from "react";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import { Habitacion } from "./Habitaciones";

interface habitacionForm {
    habitacion?: string;
    descripcion?: string;
    habitacion_image?: any;
}

interface ModalProps {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    setRefresh: (prev: any) => void;
    habitacionSelected: Habitacion | null;
    setHabitacionSelected: (habitacion: Habitacion | null) => void;
}

type errorObject = { [key: string]: string };

export const ModalEditHab = ({
    modalVisible,
    setModalVisible,
    habitacionSelected,
    setHabitacionSelected,
    setRefresh,
}: ModalProps) => {
    const [loading, setLoading] = useState(false);

    const [input, setInput] = useState<habitacionForm>({
        habitacion: habitacionSelected?.habitacion || "",
        descripcion: habitacionSelected?.descripcion || "",
        habitacion_image: habitacionSelected?.imagen || null,
    });
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [errors, setErrors] = useState<habitacionForm>({});
    const { id: id_casa } = useAppSelector(
        (state) => state.landing_house.house
    );

    useEffect(() => {
        setInput({
            habitacion: habitacionSelected?.habitacion || "",
            descripcion: habitacionSelected?.descripcion || "",
            habitacion_image: habitacionSelected?.imagen || null,
        });

        return () => {
            setInput({
                habitacion: "",
                descripcion: "",
                habitacion_image: null,
            });
        };
    }, [habitacionSelected]);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        if (e.target.name === "habitacion_image") {
            if (e.target.files && e.target.name === "habitacion_image") {
                const imagePreview = URL.createObjectURL(e.target.files[0]);

                setInput({
                    ...input,
                    habitacion_image: e.target.files[0],
                });
                setImagePreview(imagePreview);
            }
        } else {
            setInput({
                ...input,
                [e.target.name]: e.target.value,
            });
        }
    };

    const validateInput = (form: habitacionForm) => {
        let errores: errorObject = {};

        if (!form.habitacion) {
            errores.habitacion = "Debe ingresar un nombre válido";
        } else if (!form.descripcion) {
            errores.descripcion = "Debe ingresar una descripción válida";
        } else if (!form.habitacion_image) {
            errores.habitacion_image =
                "Debe ingresar una imágen de la habitación";
        }

        return errores;
    };

    const handleSubmit = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            try {
                const form = new FormData();
                setLoading(true);
                form.append("id_casa", `${id_casa}`);
                form.append("habitacion", input.habitacion!);
                form.append("descripcion", input.descripcion!);
                form.append("habitacion_image", input.habitacion_image);

                const data = await fetchAxiosToken({
                    url: `/casa_habitaciones/update/${habitacionSelected?.id}`,
                    body: form,
                    method: "put",
                });
                setLoading(false);

                setModalVisible(false);
                setImagePreview(null);
                setErrors({});
                setHabitacionSelected(null);
                setRefresh((prev: boolean) => !prev);
            } catch (error) {
                console.log(error);
            }
        }
    };

    if (!habitacionSelected?.id) return null;
    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            onClose={() => {
                setImagePreview(null);
                setErrors({});
                setInput({
                    habitacion: "",
                    descripcion: "",
                    habitacion_image: null,
                });
                setHabitacionSelected(null);
            }}>
            <div className="flex flex-col items-center p-6">
                <h1 className="text-lg font-medium">
                    Editar {habitacionSelected?.habitacion}
                </h1>
                <div className="mt-8 gap-2 flex flex-col w-full md:w-1/2 md:max-w-[468px]">
                    <div>
                        <label className="text-sm font-medium">Nombre</label>
                        <input
                            value={input.habitacion}
                            onChange={handleInputChange}
                            name="habitacion"
                            type={"text"}
                            className="w-full p-3 mt-1 border rounded-lg shadow-md "
                            placeholder="Ej: Habitación Principal"
                        />
                        {errors.habitacion && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.habitacion}
                            </p>
                        )}
                    </div>
                    <div className="mt-8">
                        <label className="text-sm font-medium">
                            Descripción
                        </label>
                        <textarea
                            value={input.descripcion}
                            onChange={handleInputChange}
                            name="descripcion"
                            className="w-full h-32 p-3 mt-1 border rounded-lg shadow-md "
                            placeholder="Esta habitacion es la principal de toda la casa, es la mas amplia y con mas comodidades"
                        />
                        {errors.descripcion && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.descripcion}
                            </p>
                        )}
                    </div>
                    <div className="mt-8">
                        <label className="text-sm font-medium">Imagen</label>
                        {!imagePreview ? (
                            <div className="relative mt-1">
                                <div className="flex flex-col w-full mx-auto border border-dashed h-36 ">
                                    <img
                                        className="flex w-full h-full"
                                        src={`${process.env.REACT_APP_PROXY}/casa_habitaciones/getImage/${habitacionSelected?.imagen}`}
                                        alt="imagen habitación"
                                    />
                                </div>
                                <input
                                    accept="image/*"
                                    type="file"
                                    name="habitacion_image"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer bg-red-50 file:cursor-pointer"
                                    onChange={handleInputChange}
                                />
                            </div>
                        ) : (
                            <div className="relative mt-3">
                                <img
                                    src={imagePreview}
                                    alt="imagen habitación"
                                    className="p-0.5 flex border-dashed border flex-col w-full object-cover mx-auto"
                                />
                                <input
                                    accept="image/*"
                                    type="file"
                                    name="habitacion_image"
                                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer bg-red-50 file:cursor-pointer"
                                    onChange={handleInputChange}
                                />
                            </div>
                        )}

                        {errors.habitacion_image && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.habitacion_image}
                            </p>
                        )}
                    </div>
                </div>

                <button
                    disabled={loading}
                    onClick={() => handleSubmit()}
                    className="p-3 mt-6 font-medium text-white border rounded-lg bg-[#029BFC]  disabled:bg-gray-400 disabled:cursor-default w-full md:w-1/2">
                    Guardar cambios
                </button>
            </div>
        </ResponsiveModalAbsolute>
    );
};
