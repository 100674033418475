import { useAppSelector } from "./hooks";
import useCurrencyPrices from "./useCurrencyPrices";

export const usePriceToSelectedCurrency = () => {
    const { house } = useAppSelector((state) => state.landing_house);
    const { SELECTED_CURRENCY, SOLES_PRICE, EUR_PRICE } = useCurrencyPrices();

    const price_to_currency = (price: number) => {
        let precio_final = 0;

        if (house.tipo_moneda === "USD") {
            if (SELECTED_CURRENCY === house.tipo_moneda) {
                precio_final = price;
            } else if (SELECTED_CURRENCY === "PEN") {
                precio_final = price * SOLES_PRICE;
            } else if (SELECTED_CURRENCY === "EUR") {
                precio_final = price * EUR_PRICE;
            }
        } else if (house.tipo_moneda === "PEN") {
            if (SELECTED_CURRENCY === house.tipo_moneda) {
                precio_final = price;
            } else if (SELECTED_CURRENCY === "USD") {
                precio_final = price / SOLES_PRICE;
            } else if (SELECTED_CURRENCY === "EUR") {
                precio_final = (price / SOLES_PRICE) * EUR_PRICE;
            }
        } else if (house.tipo_moneda === "EUR") {
            if (SELECTED_CURRENCY === house.tipo_moneda) {
                precio_final = price;
            } else if (SELECTED_CURRENCY === "USD") {
                precio_final = price / EUR_PRICE;
            } else if (SELECTED_CURRENCY === "PEN") {
                precio_final = (price / EUR_PRICE) * SOLES_PRICE;
            }
        }

        return Math.ceil(precio_final);
    };

    return {
        price_to_currency,
    };
};
