import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import Spinner from "../../../components/helpers/Spinner";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { verifyToken } from "../../../redux/reducers/authReducer";
import { getHouse } from "../../../redux/reducers/onboardingReducer";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { useParams } from "react-router-dom";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";

interface Check {
    check_in: string;
    check_out: string;
}

export const CheckInCheckOut = () => {
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const {
        id: id_casa,
        check_out,
        check_in,
        id_propietario,
    } = useAppSelector((state) => state.landing_house.house);

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const [input, setInput] = useState<Check>({
        check_in: "",
        check_out: "",
    });

    const putMaximaBase = async () => {
        setLoading(true);
        if (input.check_in === "" || input.check_out === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Debes completar check-in y check-out ",

                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putCheck/${id_casa}`,
                method: "put",
                body: input,
            });
            console.log(data);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Actualizado con exito",

                icon: "success",
                confirmButtonText: "Ok",
            });

            dispatchDominio();

            setLoading(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        setInput({
            check_in: check_in,
            check_out: check_out,
        });
    }, [, check_out]);

    const render = () => {
        return (
            <div className="flex">
                {true ? (
                    <div className="grid w-11/12 max-w-5xl mx-auto mt-12 lg:grid-cols-2 right-2">
                        <div className="  text-[#4D4D4D]">
                            <ButtonRegresar
                                // url={`/dashboard/onboarding/alojamiento`}
                                className="-ml-2 "
                                arrowColor="black"
                                textColor="black"
                            />
                            <p className="text-xl font-bold 2xl:text-2xl ">
                                Check-in y Check-out
                            </p>
                            <p className="mt-6 text-sm 2xl:text-base">
                                Establece el horario de entrada y salida para
                                los huéspedes.
                            </p>
                        </div>
                        <div className="p-4 border rounded-lg shadow-2xl ">
                            <div className="grid w-full grid-cols-3 gap-2 lg:gap-5">
                                <div>
                                    <div className="mt-5 mb-2 ml-2 2xl:text-base text-sm italic text-[#4D4D4D] font-medium">
                                        <p>Check-In</p>
                                    </div>
                                    <div className="flex items-center text-base italic text-gray-500 border-2  border-[#CCCCCC] w-full rounded-lg outline-none">
                                        <input
                                            type="time"
                                            placeholder="Dias"
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    check_in: e.target.value,
                                                })
                                            }
                                            className=" 2xl:p-2 p-1.5 outline-none cursor-pointer text-[#3C3C3C] rounded-lg w-full 2xl:text-base text-sm"
                                            value={input.check_in}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <div className="mt-5 mb-2 ml-2 2xl:text-base text-sm italic text-[#4D4D4D] font-medium ">
                                        <p>Check-Out</p>
                                    </div>
                                    <div className="flex items-center 2xl:text-base text-sm italic text-gray-500 border-2  border-[#CCCCCC] w-full rounded-lg outline-none">
                                        <input
                                            type="time"
                                            placeholder="Dias"
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    check_out: e.target.value,
                                                })
                                            }
                                            className=" 2xl:p-2 p-1.5 2xl:text-base text-sm outline-none cursor-pointer text-[#3C3C3C] rounded-lg w-full"
                                            value={input.check_out}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-end justify-center w-full ">
                                    <div className="relative flex items-center justify-center w-full">
                                        {/* <BotonInformacion text="Ingresa los horarios de entrada Y salida para que tus clientes puedan verlo!" /> */}
                                        <button
                                            disabled={loading}
                                            onClick={() => putMaximaBase()}
                                            className="w-full px-2 py-[11px] uppercase font-medium text-white rounded-lg bg-[#029BFC] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default 2xl:text-base text-sm">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mt-8">
                        <Spinner />
                    </div>
                )}
            </div>
        );
    };

    return tipo_usuario === 3 || tipo_usuario === 4 ? (
        <Navbar titulo="Check-In Check-Out">
            {render()}
            <NavbarMobile />
        </Navbar>
    ) : tipo_usuario === 6 ? (
        <NavbarBroker titulo="Check-In Check-Out">
            {render()}
            <NavbarMobileBroker />
        </NavbarBroker>
    ) : null;
};
