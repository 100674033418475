import { Navigation, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import imagen3 from "../../img/Mask-group.webp";
import StarsRating from "../../pages/dashboardPropietarios/reservas/StarsRating";

const Testimonios = () => {
    const testimonios = [
        {
            img: "avatar.jpg",
            testimonio:
                "La gestión es satisfactoria debido a que te da cantidad de reservas y por cada requerimiento de clientes que tengan te consultan preguntas para ahcer match y salga un alquiler. Eltrato recibido hacia mi persona es muy buena y la calidad de los alquileres son excelentes, cordiales y educados.",
            nombre: "Bruce Ríos",
            casa: "Casa Geranios",
        },
        {
            img: "avatar.jpg",
            testimonio:
                "La gestión con relación a mi casa ha sido muy buena en las reservas que he tenido. El trato siempre muy amable por parte de todos. Los alquileres y clientes muy bien, siempre han sido gente respetuosa y amable.",
            nombre: "Walter Yabarren",
            casa: "Casa Rivera y Casa poli",
        },
        // {
        //     img: "avatar.jpg",
        //     testimonio:
        //         "Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthusiastically architect adaptive. Appropriately implement one-to-one catalysts for change vis-a-vis wireless catalysts for change. Enthu.",
        //     nombre: "Mark Parker",
        //     casa: "Casa Driet",
        // },
    ];

    const Star = ({ index }: { index: number }) => {
        return (
            <svg
                className="2xl:h-6 2xl:w-6 md:h-5 md:w-5 h-4 w-4 text-[#FDCC0D] cursor-pointer"
                viewBox="0 0 24 24"
                fill={`#FDCC0D`}
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
            </svg>
        );
    };

    return (
        <div>
            <div className="flex justify-center pt-4">
                <h2 className="bg-white pt-5 text-[#179BD8]  text-center font-bold text-[25px] md:text-[30px] lg:text-[40px] ">
                    Testimonios
                </h2>
            </div>
            <div className="flex flex-col items-center justify-center text-center md:hidden ">
                <div className="font-bold text-[#0E1928] text-[20px]">
                    Reseñas de nuestros clientes
                </div>

                <div className="text-[#C0D345] text-[15px] mt-5 md:mt-10 mx-5">
                    Comentarios auténticos de quienes confían en nosotros
                </div>
            </div>
            <div className="flex flex-col justify-center w-full px-5 mx-auto lg:w-11/12 2xl:w-5/6 md:flex-row md:mb-14">
                <div className="z-30 w-full md:w-1/3">
                    <img
                        src={imagen3}
                        alt="no se encontro imagen"
                        className="rounded-lg h-[200px] md:h-[400px] 2xl:h-[450px] w-full object-cover md:mx-10 mt-16"
                    />
                </div>
                <div className="relative z-40 w-full md:w-2/3">
                    <div className="flex-col items-center justify-center hidden mt-10 md:flex">
                        <div className="font-bold text-[#0E1928] text-[25px] xl:text-[30px] 2xl:text-[35px]">
                            Reseñas de nuestros clientes
                        </div>

                        <div className="text-[#C0D345] text-10px xl:text-[15px] 2xl:text-[18px] mt-5 2xl:mt-8">
                            Comentarios auténticos de quienes confían en
                            nosotros
                        </div>
                    </div>
                    <div className="mt-5 md:mt-10">
                        <Swiper
                            modules={[Navigation, Autoplay, EffectFade]}
                            slidesPerView={1}
                            speed={2000}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            loop
                            breakpoints={{
                                761: {
                                    slidesPerView: 1,
                                    spaceBetween: 1,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 1,
                                },
                                1500: {
                                    slidesPerView: 2,
                                    spaceBetween: 1,
                                },
                            }}>
                            {testimonios.map((testimonio, index) => (
                                <SwiperSlide
                                    key={index}
                                    className="flex items-center justify-center">
                                    {" "}
                                    <div className="flex flex-col items-center justify-between w-auto h-auto max-w-3xl px-8 py-6 mx-5 mb-8 bg-white rounded-lg shadow-lg lg:h-72 2xl:h-80">
                                        <div>
                                            <p className="text-xs font-semibold text-justify text-[#0E1928] lg:text-xs xl:text-sm 2xl:text-base">
                                                "{testimonio.testimonio}"
                                            </p>
                                        </div>
                                        <div className="flex flex-col items-center justify-center gap-2">
                                            <div className="flex items-center justify-center w-auto mt-10 mb-1 lg:mt-0">
                                                <div className="w-8 h-8 md:w-10 md:h-10 xl:w-14 xl:h-14 2xl:w-16  2xl:h-16 bg-[#029bfc] rounded-full flex items-center justify-center p-2">
                                                    <svg
                                                        id="Capa_2"
                                                        data-name="Capa 2"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 79.9 100"
                                                        fill="white"
                                                        className="w-5 h-5 md:w-6 md:h-6 2xl:w-10 2xl:h-10">
                                                        <g id="Testimonios">
                                                            <g>
                                                                <path d="m39.86,99.95c-12.39,0-24.78-.05-37.17.05-2.16.02-2.71-.71-2.69-2.76.1-7.8.02-15.6.05-23.4.05-12.19,9.19-22.67,21.26-24.23.83-.11,2.15.51,2.62,1.21,8.05,11.93,24.16,11.96,31.97-.04.71-1.09,1.47-1.41,2.69-1.26,11.6,1.43,21.14,11.95,21.26,23.62.08,8.16-.03,16.33.06,24.49.02,1.86-.66,2.36-2.43,2.35-12.54-.06-25.08-.03-37.61-.03" />
                                                                <path d="m39.92,0c11.68,0,21.09,9.3,21.12,20.86.03,11.9-9.33,21.31-21.16,21.29-11.69-.02-21.04-9.46-21.01-21.23C18.9,9.32,28.27,0,39.92,0" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div className="flex flex-col ml-8 -mt-2">
                                                    <p className="mt-5 text-sm md:text-base  2xl:text-xl  font-bold text-[#0E1928] ">
                                                        {testimonio.nombre}
                                                    </p>

                                                    <p className=" text-xs md:text-sm 2xl:text-lg font-medium text-[#0E1928]">
                                                        {testimonio.casa}
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div className="flex mb-1">
                                                {[...Array(5)].map(
                                                    (star, index) => (
                                                        <div
                                                            key={index}
                                                            className="mx-0.5">
                                                            <Star
                                                                index={index}
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            </div> */}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonios;
