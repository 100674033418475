import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BurguerMenuMobile } from "./BurguerMenuMobile";

const TITLES = [
    {
        id: 0,
        title: "Casas",
        url: "casas",

        icono: (
            <svg
                className="w-8 h-8 text-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8" />
                <line x1="13" y1="7" x2="13" y2="7.01" />
                <line x1="17" y1="7" x2="17" y2="7.01" />
                <line x1="17" y1="11" x2="17" y2="11.01" />
                <line x1="17" y1="15" x2="17" y2="15.01" />
            </svg>
        ),
    },
    {
        id: 1,
        title: "Comodidades",
        url: "comodidades",

        icono: (
            <svg
                className="w-8 h-8 text-white"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path d="M18 8h1a4 4 0 0 1 0 8h-1" />{" "}
                <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z" />{" "}
                <line x1="6" y1="1" x2="6" y2="4" />{" "}
                <line x1="10" y1="1" x2="10" y2="4" />{" "}
                <line x1="14" y1="1" x2="14" y2="4" />
            </svg>
        ),
    },
    {
        id: 2,
        title: "Administradores",
        url: "administradores",

        icono: (
            <svg
                className="w-8 h-8 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
            </svg>
        ),
    },
    {
        id: 3,
        title: "Ayuda",
        url: "ayuda",

        icono: (
            <svg
                className="w-8 h-8 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                />
            </svg>
        ),
    },
];

export const NavbarAdministrativaMobile = () => {
    const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleNavigate = (title: string) => {
        navigate(`/dashboard-admin/${title}`);
    };
    return (
        <>
            <div className="fixed bottom-0 w-full h-12 text-white bg-[#029BFC] sm:hidden ">
                <div className="flex items-center w-full my-auto mt-2 overflow-auto justify-evenly">
                    {TITLES.map((title) => (
                        <div
                            className="transition ease-in cursor-pointer"
                            key={title.id}
                            onClick={() => handleNavigate(title.url)}>
                            {title.icono}
                        </div>
                    ))}
                    <svg
                        onClick={() => setShowBurgerMenu((prev) => !prev)}
                        className="w-8 h-8 text-white cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </div>
            </div>
            <BurguerMenuMobile
                setShowBurgerMenu={setShowBurgerMenu}
                showBurgerMenu={showBurgerMenu}
            />
        </>
    );
};
