import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Map, { Marker } from "react-map-gl";
import Swal from "sweetalert2";

import Spinner from "../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { logIn } from "../redux/reducers/authReducer";

interface LoginForm {
    correo?: string;
    password?: string;
}

interface RegisterForm {
    nombre?: string;
    apellido?: string;
    celular?: string;
    correo?: string;
    password?: string;
    password2?: string;
    dominio?: string;
}
type errorObject = { [key: string]: string };
interface Props {
    modalVisibleLogin: boolean;
    setModalVisibleLogin: (modalVisibleLogin: boolean) => void;
}

const INITIAL_STATE = {
    correo: "",
    password: "",
};

const INITIAL_STATE_REGISTER = {
    nombre: "",
    apellido: "",
    correo: "",
    password: "",
    password2: "",
    celular: "",
};

export const ModalLogin = ({
    setModalVisibleLogin,
    modalVisibleLogin,
}: Props) => {
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const { house } = useAppSelector((state) => state.landing_house);
    const [errors, setErrors] = useState<LoginForm>({});
    const [input, setInput] = useState<LoginForm>(INITIAL_STATE);
    const [saving, setSaving] = useState<boolean>(false);
    const [showPass, setShowPass] = useState<boolean>(false);
    const [iniciarSesion, setIniciarSesion] = useState(true);
    const [inputRegister, setInputRegister] = useState<RegisterForm>(
        INITIAL_STATE_REGISTER
    );
    const [showPassRegister, setShowPassRegister] = useState<boolean>(false);
    const [errorsRegister, setErrorsRegister] = useState<RegisterForm>({});
    const dispatch = useAppDispatch();

    const validateInput = (form: LoginForm) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        let errores: errorObject = {};

        if (!regExpEmail.test(form.correo || "")) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.password) {
            errores.password = "Debe ingresar una contraseña";
        }

        return errores;
    };

    const validateInputRegister = (form: RegisterForm) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        let errores: errorObject = {};

        if (!form.nombre) {
            errores.nombre = "Debe ingresar un nombre válido";
        } else if (!form.apellido) {
            errores.apellido = "Debe ingresar un apellido válido";
        } else if (!regExpEmail.test(form.correo || "")) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.celular) {
            errores.celular = "Debe ingresar un celular válido";
        } else if (!form.password) {
            errores.password = "Debe ingresar una contraseña";
        } else if (form.password !== form.password2) {
            errores.password2 = "Las contraseñas deben coincidir";
        }

        return errores;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleInputChangeRegister = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setInputRegister({
            ...inputRegister,
            [e.target.name]: e.target.value,
        });
    };

    const handleLogin = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/usuarios/signIn`,
                    input
                );
                console.log(data);
                localStorage.setItem("token", data.token);
                dispatch(logIn({ ...data.currentUser }));
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Sesión iniciada correctamente",
                    text: "Ahora puedes reservar",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                setModalVisibleLogin(false);
            } catch (error: any) {
                console.log(error.response);
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    text: error.response.data.error,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
        }
    };

    const handleRegister = async () => {
        const errors = validateInputRegister(inputRegister);
        setErrorsRegister(errors);

        if (Object.entries(errors).length === 0) {
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/clientes/signUp`,
                    { ...inputRegister, id_casa: house?.id }
                );
                console.log(data);
                localStorage.setItem("token", data.token);
                dispatch(logIn(data.usuario));
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Felicidades te has registrado correctamente!",
                    text: "Ahora puedes reservar",
                    icon: "success",

                    confirmButtonText: "Aceptar",
                });
                setModalVisibleLogin(false);
            } catch (error: any) {
                console.log(error.response.data.error);

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    text: error.response.data.error,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleLogin}
            setModalVisible={setModalVisibleLogin}
            hide_on_close={false}>
            {iniciarSesion ? (
                <div className="flex flex-col max-w-lg gap-4 mx-auto ">
                    <div className="flex flex-col items-center justify-center p-4 mt-10">
                        <div className="flex flex-col gap-2 text-center">
                            <h1 className="text-xl font-bold lg:text-3xl">
                                Iniciar sesión
                            </h1>
                            <div className="font-medium">
                                Aun no tienes una cuenta?{" "}
                                <span
                                    onClick={() => setIniciarSesion(false)}
                                    className="text-blue-600 cursor-pointer hover:underline">
                                    Regístrate aquí.
                                </span>
                            </div>
                            <span className="text-sm text-gray-500">
                                Utiliza tu correo y contraseña para acceder
                            </span>
                        </div>
                        <div className="mt-8 w-full md:w-1/2 md:max-w-[468px]">
                            <label className="text-sm font-medium">
                                Correo electrónico
                            </label>
                            <input
                                value={input.correo}
                                onChange={handleInputChange}
                                name="correo"
                                type={"email"}
                                className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                                placeholder="ejemplo@gmail.com"
                            />
                            {errors.correo && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.correo}
                                </p>
                            )}
                            <label className="flex mt-4 text-sm font-medium">
                                Contraseña
                            </label>
                            <div className="relative flex items-center">
                                <input
                                    value={input.password}
                                    onChange={handleInputChange}
                                    name="password"
                                    type={showPass ? "text" : "password"}
                                    maxLength={20}
                                    className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                                    placeholder="contraseña"
                                />
                                <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                                    {showPass ? (
                                        <>
                                            <svg
                                                className={`w-6 h-6 mx-2 cursor-pointer`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                onClick={() => {
                                                    setShowPass(!showPass);
                                                }}>
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                />
                                            </svg>
                                        </>
                                    ) : (
                                        <>
                                            <svg
                                                className={`w-6 h-6 mx-2 cursor-pointer`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                onClick={() => {
                                                    setShowPass(!showPass);
                                                }}>
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                />
                                            </svg>
                                        </>
                                    )}
                                </span>
                            </div>
                            {errors.password && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.password}
                                </p>
                            )}
                        </div>

                        <div className="flex flex-col gap-4 mt-8">
                            {saving ? (
                                <Spinner />
                            ) : (
                                <button
                                    onClick={() => handleLogin()}
                                    className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                                    Acceder
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center p-4 mt-10">
                    <div className="flex flex-col gap-2 text-center">
                        <h1 className="text-xl font-bold lg:text-3xl">
                            Cuenta nueva
                        </h1>
                        <p className="font-medium">
                            Regístrate con tu correo electrónico para poder
                            generar tu reserva
                        </p>
                    </div>
                    <div className="mt-8 w-full md:w-1/2 md:max-w-[468px]">
                        <label className="text-sm font-medium">Nombre</label>
                        <input
                            value={inputRegister.nombre}
                            onChange={handleInputChangeRegister}
                            name="nombre"
                            type={"text"}
                            className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                            placeholder="nombre"
                        />
                        {errorsRegister.nombre && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errorsRegister.nombre}
                            </p>
                        )}
                        <label className="text-sm font-medium">Apellido</label>
                        <input
                            value={inputRegister.apellido}
                            onChange={handleInputChangeRegister}
                            name="apellido"
                            type={"text"}
                            className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                            placeholder="apellido"
                        />
                        {errorsRegister.apellido && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errorsRegister.apellido}
                            </p>
                        )}

                        <label className="text-sm font-medium">Celular</label>
                        <input
                            value={inputRegister.celular}
                            onChange={handleInputChangeRegister}
                            name="celular"
                            type={"text"}
                            className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                            placeholder="2216698754"
                        />
                        {errorsRegister.celular && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errorsRegister.celular}
                            </p>
                        )}
                        <label className="text-sm font-medium">
                            Correo electrónico
                        </label>
                        <input
                            value={inputRegister.correo}
                            onChange={handleInputChangeRegister}
                            name="correo"
                            type={"email"}
                            className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                            placeholder="micuenta@gmail.com"
                        />
                        {errorsRegister.correo && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errorsRegister.correo}
                            </p>
                        )}
                        <>
                            <label className="flex mt-4 text-sm font-medium">
                                Contraseña
                            </label>
                            <div className="relative flex flex-col justify-center">
                                <input
                                    value={inputRegister.password}
                                    onChange={handleInputChangeRegister}
                                    name="password"
                                    type={
                                        showPassRegister ? "text" : "password"
                                    }
                                    maxLength={20}
                                    className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                                    placeholder="contraseña"
                                />
                                <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                                    {showPassRegister ? (
                                        <>
                                            <svg
                                                className={`w-6 h-6 mx-2 cursor-pointer`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                onClick={() => {
                                                    setShowPassRegister(
                                                        !showPassRegister
                                                    );
                                                }}>
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                />
                                            </svg>
                                        </>
                                    ) : (
                                        <>
                                            <svg
                                                className={`w-6 h-6 mx-2 cursor-pointer`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                onClick={() => {
                                                    setShowPassRegister(
                                                        !showPassRegister
                                                    );
                                                }}>
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                />
                                            </svg>
                                        </>
                                    )}
                                </span>
                                {errorsRegister.password && (
                                    <p className="my-1 text-sm font-bold text-red-500 text-md">
                                        {errorsRegister.password}
                                    </p>
                                )}
                            </div>
                        </>

                        <>
                            <label className="flex mt-4 text-sm font-medium">
                                Repetir contraseña
                            </label>
                            <div className="relative flex flex-col justify-center">
                                <input
                                    value={inputRegister.password2}
                                    onChange={handleInputChangeRegister}
                                    name="password2"
                                    type={
                                        showPassRegister ? "text" : "password"
                                    }
                                    maxLength={20}
                                    className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                                    placeholder="repetir contraseña"
                                />
                                <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                                    {showPass ? (
                                        <>
                                            <svg
                                                className={`w-6 h-6 mx-2 cursor-pointer`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                onClick={() => {
                                                    setShowPassRegister(
                                                        !showPassRegister
                                                    );
                                                }}>
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                />
                                            </svg>
                                        </>
                                    ) : (
                                        <>
                                            <svg
                                                className={`w-6 h-6 mx-2 cursor-pointer`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                onClick={() => {
                                                    setShowPassRegister(
                                                        !showPassRegister
                                                    );
                                                }}>
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                />
                                            </svg>
                                        </>
                                    )}
                                </span>
                                {errorsRegister.password2 && (
                                    <p className="my-1 text-sm font-bold text-red-500 text-md">
                                        {errorsRegister.password2}
                                    </p>
                                )}
                            </div>
                        </>
                    </div>
                    <div className="flex flex-col gap-4 mt-8">
                        <button
                            onClick={() => handleRegister()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                            Registrar
                        </button>
                        <span>
                            Si ya te has registrado,{" "}
                            <span
                                onClick={() => setIniciarSesion(true)}
                                className="text-blue-600 cursor-pointer hover:underline">
                                <span className="font-medium">
                                    haz click aquí.
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
            )}
        </ResponsiveModalAbsolute>
    );
};
