import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { logOut } from "../../redux/reducers/authReducer";
import { Notificaiones } from "../../designs/modalEdit/Notificaiones";

const TITLES = [
    {
        id: 1,
        title: "Reservas",
        url: "reservas",

        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 99.54 100"
            >
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m49.75,73.36c-13.18,0-26.36,0-39.54,0-2.33,0-3.81-1.47-3.7-3.65.07-1.4.92-2.61,2.33-2.98.88-.23,1-.68,1.02-1.46.13-4.61.59-9.18,1.81-13.66,2.56-9.41,8.55-15.56,17.67-18.73,4.8-1.67,9.77-2.49,14.84-2.72,2.23-.1,2.67-.68,2.18-2.91-.13-.59-.6-.49-.95-.62-1.55-.58-2.41-1.8-2.36-3.42.04-1.46.94-2.68,2.33-3.1.36-.11.75-.15,1.12-.15,2.18-.01,4.36-.02,6.54,0,1.77.02,3.13,1.14,3.4,2.76.3,1.81-.65,3.41-2.46,3.91-.71.19-.83.53-.84,1.16-.01,2.3-.03,2.24,2.32,2.37,6.05.32,11.94,1.35,17.52,3.84,8.03,3.58,12.86,9.79,15.02,18.23,1.09,4.28,1.53,8.65,1.64,13.06.02.8.2,1.2,1.04,1.44,1.67.47,2.56,2.08,2.29,3.82-.25,1.63-1.67,2.79-3.48,2.79-9.72,0-19.44,0-29.16,0-3.53,0-7.06,0-10.59,0Zm6.41-29.71c-1.32-.17-2.31.99-2.74,2.1-.84,2.14.65,4.19,3.11,4.39,2.28.18,4.53.52,6.71,1.24,2.81.94,4.81,2.67,5.55,5.65.11.43.28.86.5,1.25.79,1.35,2.4,1.9,3.86,1.36,1.42-.53,2.34-2.12,1.99-3.59-1.04-4.33-3.3-7.73-7.41-9.73-3.36-1.64-6.95-2.23-11.58-2.66Z"
                        />
                        <path
                            fill="white"
                            d="m49.65,99.99c-15.95,0-31.9,0-47.84,0-.31,0-.62-.02-.93,0-.84.08-1.02-.26-.76-1.04.94-2.75,1.86-5.51,2.76-8.28.19-.57.51-.84,1.09-.93,3.27-.5,5.22-2.38,5.72-5.66.15-.98.78-.88,1.44-.88,11.21,0,22.42,0,33.63,0q1.62,0,1.62-1.64t1.69-1.69c1.38,0,2.77.03,4.15,0,.73-.02.94.28.96.98.06,2.36.08,2.36,2.45,2.36,10.9,0,21.79.01,32.69-.02,1.02,0,1.45.26,1.6,1.32.39,2.68,2.54,4.77,5.25,5.16.92.13,1.36.53,1.63,1.41.78,2.55,1.66,5.07,2.5,7.6.43,1.3.42,1.31-.97,1.31-16.22,0-32.45,0-48.67,0Z"
                        />
                        <path
                            fill="white"
                            d="m46.37,6.67c0-1.14-.03-2.29,0-3.43C46.43,1.36,47.86,0,49.73,0c1.87,0,3.37,1.35,3.4,3.19.05,2.35.05,4.71,0,7.07-.04,1.85-1.53,3.2-3.4,3.19-1.83,0-3.3-1.42-3.36-3.25-.03-1.18,0-2.36,0-3.53Z"
                        />
                        <path
                            fill="white"
                            d="m19.93,16.77c-.04,1.36-.63,2.41-1.86,2.99-1.25.6-2.54.54-3.61-.36-1.43-1.2-2.76-2.52-3.95-3.96-1.07-1.31-.9-3.29.25-4.47,1.18-1.21,3.2-1.4,4.54-.3,1.43,1.19,2.73,2.55,3.96,3.95.51.59.72,1.33.67,2.13Z"
                        />
                        <path
                            fill="white"
                            d="m79.57,16.21c-.01-.5.36-1.3,1.01-1.97,1.06-1.09,2.12-2.18,3.23-3.23,1.47-1.38,3.63-1.38,4.94-.03,1.28,1.32,1.29,3.44-.06,4.88-1.09,1.16-2.21,2.29-3.38,3.37-1.13,1.03-2.48,1.2-3.86.55-1.28-.6-1.87-1.69-1.88-3.56Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 2,
        title: "Chat",
        url: "chat",

        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 95.03"
            >
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m100,19.8v32.79c-.11.57-.22,1.15-.33,1.72-1.91,10.16-11.15,17.98-21.49,18.05-10.15.07-20.3.03-30.44,0-.92,0-1.58.26-2.23.92-6.81,6.85-13.65,13.67-20.48,20.5-1.22,1.21-2.43,1.54-3.69.98-1.39-.63-1.83-1.78-1.83-3.25.03-5.92.01-11.84.01-17.76q0-1.49-1.5-1.78C7.76,69.98.03,60.67,0,50.23c-.02-9.33,0-18.67,0-28,0-.91.06-1.81.17-2.72C1.59,8.55,11.17.04,22.24.02,40.75,0,59.25-.02,77.76.03c7.06.02,12.82,2.95,17.33,8.35,2.76,3.31,4.25,7.19,4.91,11.41ZM26.68,31.73c-3.19-.02-5.74,2.44-5.82,5.6-.07,3.12,2.59,5.81,5.75,5.81,3.12,0,5.71-2.55,5.77-5.7.06-3.09-2.55-5.7-5.7-5.72Zm23.43,0c-3.22-.03-5.86,2.54-5.83,5.68.02,3.12,2.62,5.72,5.74,5.73,3.12.01,5.71-2.53,5.79-5.68.07-3.08-2.53-5.71-5.69-5.73Zm23.42,0c-3.22-.02-5.86,2.54-5.83,5.69.02,3.13,2.62,5.72,5.74,5.73,3.12,0,5.71-2.54,5.78-5.69.07-3.08-2.54-5.71-5.69-5.73Z"
                    />
                </g>
            </svg>
        ),
    },
];

interface NavProps {
    children: React.ReactNode;
}

export const NavbarCliente: React.FC<NavProps> = ({ children }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [showChildren, setShowChildren] = useState<number>(-1);
    const { avatar, nombre, apellido, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );

    console.log(tipo_usuario);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleNavigate = (title: any) => {
        if (title.url) navigate(`/dashboard-cliente/${title.url}`);
        else {
            if (showChildren === title.id) setShowChildren(-1);
            else setShowChildren(title.id);
        }
    };

    const handleChildrenClick = (url: string) => {
        navigate(`/dashboard-cliente/${url}`);
    };

    return (
        <div className="flex w-full">
            {/* SIDE BAR */}
            <div
                className={`transition-all z-30 ease-out duration-700 fixed bottom-0 top-0 left-0 pl-1.5 bg-[#029BFC] text-white sm:flex gap-4 w-48 lg:w-64 hidden`}
            >
                <div className="flex flex-col w-full gap-5 px-2 mt-7">
                    <p
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-2 mb-4 text-xl font-bold cursor-pointer w-max hover:scale-105"
                    >
                        <svg
                            className="w-8 h-8"
                            id="Capa_2"
                            data-name="Capa 2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 438 424.58"
                        >
                            <g id="Capa_1-2" data-name="Capa 1">
                                <path
                                    fill="white"
                                    d="m435.34,244.45L227.19,3.74c-4.32-4.99-12.06-4.99-16.38,0L2.66,244.45c-6.06,7.01-1.08,17.91,8.19,17.91h57.47l21.09,150.33c1.38,9.84,12.4,15.04,20.87,9.85l32.85-20.11c14-8.57,25.67-20.45,34-34.59h0c9.07-15.4,13.86-32.95,13.86-50.82v-62.55h-53.22c-2.85,0-4.19-5.01-2.05-7.7l81.24-102.22c1.17-1.47,2.93-1.47,4.11,0l81.24,102.22c2.13,2.69.8,7.7-2.05,7.7h-53.22v62.55c0,17.87,4.78,35.42,13.86,50.83h0c8.33,14.14,20,26.02,34,34.58l32.85,20.11c8.48,5.19,19.49-.01,20.87-9.85l21.09-150.33h57.47c9.27,0,14.25-10.89,8.19-17.91Z"
                                />
                            </g>
                        </svg>
                        <span className="uppercase">{nombre}</span>
                    </p>

                    {TITLES.map((title, index) => (
                        <div
                            className={`transition ease-in cursor-pointer`}
                            key={title.id}
                        >
                            {((index === 1 && tipo_usuario === 5) ||
                                index === 0) && (
                                <div>
                                    {" "}
                                    <div className="flex flex-col cursor-pointer">
                                        <div
                                            className="flex items-center gap-2 hover:scale-105"
                                            onClick={() =>
                                                handleNavigate(title)
                                            }
                                        >
                                            <span>{title.icono}</span>
                                            <span className="capitalize lg:text-lg ">
                                                {title.title}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                height:
                                                    showChildren === title.id
                                                        ? ``
                                                        : "0rem",
                                            }}
                                            className={`${
                                                showChildren === title.id
                                                    ? `opacity-100 visible`
                                                    : "opacity-0 invisible"
                                            } transition-all duration-300 ease-in-out`}
                                        >
                                            {/* {title.children &&
                                        title.children.map((children) => (
                                            <div
                                                onClick={() =>
                                                    handleChildrenClick(
                                                        children.url
                                                    )
                                                }
                                                key={children.id}
                                                className="p-2 pl-4 lg:pl-10 hover:underline hover:scale-105">
                                                {children.title}
                                            </div>
                                        ))} */}
                                        </div>
                                    </div>
                                    <hr className="mt-2 mr-2" />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {/* SIDE BAR */}
            {/* TOP BAR */}
            <div className="fixed top-0 left-0 z-20 flex justify-between sm:justify-end w-full p-4 bg-white border-b-2 border-[#CCCCCC]">
                <div className="flex items-center justify-center sm:hidden text-start">
                    <p
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-2 text-xs font-bold cursor-pointer xs:text-sm w-max hover:scale-105 text-blue-blueFrom"
                    >
                        <svg
                            className="w-5 h-5 xs:w-6 xs:h-6 text-blue-blueFrom"
                            id="Capa_2"
                            data-name="Capa 2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 438 424.58"
                        >
                            <g id="Capa_1-2" data-name="Capa 1">
                                <path
                                    fill="#3c96ff"
                                    d="m435.34,244.45L227.19,3.74c-4.32-4.99-12.06-4.99-16.38,0L2.66,244.45c-6.06,7.01-1.08,17.91,8.19,17.91h57.47l21.09,150.33c1.38,9.84,12.4,15.04,20.87,9.85l32.85-20.11c14-8.57,25.67-20.45,34-34.59h0c9.07-15.4,13.86-32.95,13.86-50.82v-62.55h-53.22c-2.85,0-4.19-5.01-2.05-7.7l81.24-102.22c1.17-1.47,2.93-1.47,4.11,0l81.24,102.22c2.13,2.69.8,7.7-2.05,7.7h-53.22v62.55c0,17.87,4.78,35.42,13.86,50.83h0c8.33,14.14,20,26.02,34,34.58l32.85,20.11c8.48,5.19,19.49-.01,20.87-9.85l21.09-150.33h57.47c9.27,0,14.25-10.89,8.19-17.91Z"
                                />
                            </g>
                        </svg>
                        <span className="uppercase">{nombre}</span>
                    </p>
                </div>
                <div
                    className="relative flex flex-col items-center"
                    onBlur={() => setShowOptions(false)}
                >
                    <div className="flex items-center gap-4 cursor-pointer hover:scale-105">
                        <Notificaiones landing={false} />
                        <button onClick={() => setShowOptions(true)}>
                            <img
                                src={`${process.env.REACT_APP_PROXY}/usuarios/getImage/${avatar}`}
                                className="z-30 object-cover w-12 h-12 border-2 border-[#808080] rounded-full cursor-pointer hover:scale-105"
                                alt="foto de perfil"
                            />
                        </button>
                    </div>
                    <div
                        className={`${
                            showOptions
                                ? "h-max opacity-100 visible"
                                : "h-0 opacity-0 invisible"
                        }  fixed flex flex-col z-0 ml-4 top-16 pt-4 bg-white transition-all duration-500 delay-200 ease-out right-4`}
                    >
                        <div className="flex flex-col p-3 border">
                            <div
                                onClick={() =>
                                    navigate("/dashboard-cliente/mi-cuenta")
                                }
                                className="flex items-center gap-2 cursor-pointer hover:scale-105 hover:text-blue-blueFrom"
                            >
                                <svg
                                    className="w-6 h-6 text-blue-blueFrom"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2.5"
                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                    />
                                </svg>
                                <span className="text-sm">Mi perfil</span>
                            </div>
                            <hr className="my-2" />
                            <div
                                onClick={() =>
                                    navigate(
                                        "/dashboard-cliente/cambiar-contraseña"
                                    )
                                }
                                className="flex items-center gap-2 cursor-pointer hover:scale-105 hover:text-blue-blueFrom"
                            >
                                <svg
                                    className="w-6 h-6 text-blue-blueFrom"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                    />
                                </svg>
                                <span className="text-sm">
                                    Cambiar contraseña
                                </span>
                            </div>
                            <hr className="my-2" />
                            <div
                                onClick={() => {
                                    navigate("/");
                                    dispatch(logOut());
                                }}
                                className="flex items-center gap-2 cursor-pointer hover:scale-105 hover:text-blue-blueFrom"
                            >
                                <svg
                                    className="ml-0.5 w-6 h-6"
                                    stroke="#0060ff"
                                    fill="none"
                                >
                                    <g>
                                        <path d="M19.2854 12.0002L11.2727 12.0002"></path>
                                        <path d="M16.5101 15.6364L19.9999 12L16.5101 8.36363"></path>
                                        <path d="M13.4545 7V4H4V20H13.4545V17"></path>
                                    </g>
                                </svg>
                                <span className="absolute ml-8 text-sm">
                                    Cerrar sesión
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* TOP BAR */}
            <div className="flex justify-center w-full mt-20 mb-10 sm:ml-48 lg:ml-64">
                {children}
            </div>
        </div>
    );
};
