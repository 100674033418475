import React, { useState } from "react";
import useCurrencyPrices from "../hooks/useCurrencyPrices";

export const ElegirMoneda = () => {
    const [showCurrencies, setShowCurrencies] = useState(false);
    const { SELECTED_CURRENCY } = useCurrencyPrices();

    const handleNewCurrency = (currency: string) => {
        localStorage.setItem("selected_currency", currency);
        window.location.reload();
    };

    return (
        <div
            className="relative flex flex-col items-center"
            onBlur={() => setShowCurrencies(false)}>
            <button
                onClick={() => setShowCurrencies(!showCurrencies)}
                className="flex items-center gap-1 p-[2px] xs:p-1 xs:px-2 border rounded-md cursor-pointer bg-gray-50 hover:bg-gray-100 text-[#1C4CBB]">
                <span className="ml-1 text-sm font-bold xs:text-base ">
                    {SELECTED_CURRENCY}
                </span>
                <svg
                    className="w-5 h-5 font-bold"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <polyline points="6 9 12 15 18 9" />
                </svg>
            </button>
            <div
                className={`${
                    showCurrencies
                        ? "h-max opacity-100 visible"
                        : "h-0 opacity-0 invisible"
                }  absolute flex flex-col z-0 top-[2.3rem] left-0 w-full text-center text-black right-1 bg-white transition-all duration-100 delay-200 ease-out`}>
                <div className="flex flex-col px-1 py-3 font-bold border ">
                    <button
                        disabled={SELECTED_CURRENCY === "PEN"}
                        onClick={() => handleNewCurrency("PEN")}
                        className=" gap-2 cursor-pointer text-[#1C4CBB] disabled:text-white disabled:cursor-not-allowed disabled:bg-[#029BFC] disabled:bg-opacity-30 px-3 py-1 rounded-sm text-center">
                        <p className="text-sm">PEN</p>
                    </button>
                    <hr className="my-2" />
                    <button
                        disabled={SELECTED_CURRENCY === "USD"}
                        onClick={() => handleNewCurrency("USD")}
                        className=" gap-2 cursor-pointer text-[#1C4CBB] disabled:text-white disabled:cursor-not-allowed disabled:bg-[#029BFC] disabled:bg-opacity-30 px-3 py-1 rounded-sm text-center">
                        <p className="text-sm">USD</p>
                    </button>
                    <hr className="my-2" />

                    <button
                        disabled={SELECTED_CURRENCY === "EUR"}
                        onClick={() => handleNewCurrency("EUR")}
                        className=" gap-2 cursor-pointer text-[#1C4CBB] disabled:text-white disabled:cursor-not-allowed disabled:bg-[#029BFC] disabled:bg-opacity-30 px-3 py-1 rounded-sm text-center">
                        <p className="text-sm">EUR</p>
                    </button>
                </div>
            </div>
        </div>
    );
};
