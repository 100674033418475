import { useState, useEffect } from "react";
import { useAppDispatch } from "./hooks";
import { currencyPage } from "../redux/reducers/InformacionReducer";
import { fetchAxiosNoToken } from "../helpers/fetchAxiosNoToken";

const useCurrency = () => {
    const [currency, setCurrency] = useState(
        localStorage.getItem("currency") || ""
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        getCurrency();
    }, []);

    const getCurrency = async () => {
        const storedCurrency = localStorage.getItem("currency");

        if (!storedCurrency) {
            try {
                const data = await fetchAxiosNoToken({
                    url: `/location`,
                });

                console.log("data", data);
                const currency = data && data === "Europe" ? "EUR" : "USD";
                localStorage.setItem("currency", currency);
                setCurrency(currency);
                dispatch(currencyPage(currency));
            } catch (error) {
                console.error(
                    "Error al obtener información de ubicación:",
                    error
                );
            }
        } else {
            setCurrency(storedCurrency);
            dispatch(currencyPage(storedCurrency));
        }
    };

    const updateCurrency = (newCurrency: string) => {
        setCurrency(newCurrency);
        localStorage.setItem("currency", newCurrency);
    };

    return { currency, updateCurrency };
};

export default useCurrency;
