import { useState } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { ModalEditComforts } from "../modalEdit/ModalEditComforts";
import { ModalEditDescripcion1 } from "../modalEdit/ModalEditDescripcion1";
import ImagenVideo from "../../img/editar_landing/Recurso-6.webp";
import { ModalGaleriaImagenes } from "../modalEdit/ModalGaleriaImagenes";
import { ModalEditContenidoDescripcion } from "../modalEdit/ModalEditContenidoDescripcion";
import ReactPlayer from "react-player";
interface Props {
    toggleButton: boolean;
}
const Descripciones = ({ toggleButton }: Props) => {
    const {
        titulo_descripcion_1,
        descripcion_1,
        banner,
        casa_imagenes,
        tipo_descripcion,
        imagen_descripcion,
        video_descripcion,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);
    const [modalVisibleComodidades, setModalVisibleComodidades] =
        useState<boolean>(false);
    const [modalImagesVisible, setModalImagesVisible] = useState(false);

    const [
        modalVisibleContenidoDescripcion,
        setModalVisibleContenidoDescripcion,
    ] = useState<boolean>(false);
    return (
        <div className="w-full ">
            {modalVisibleComodidades && (
                <ModalEditDescripcion1
                    setModalVisibleComodidades={setModalVisibleComodidades}
                    modalVisibleComodidades={modalVisibleComodidades}
                />
            )}
            {modalImagesVisible && (
                <ModalGaleriaImagenes
                    imagen_principal={banner}
                    imagenes={casa_imagenes}
                    modalVisible={modalImagesVisible}
                    setModalVisible={setModalImagesVisible}
                />
            )}

            {modalVisibleContenidoDescripcion && (
                <ModalEditContenidoDescripcion
                    setModalVisibleContenidoDescripcion={
                        setModalVisibleContenidoDescripcion
                    }
                    modalVisibleContenidoDescripcion={
                        modalVisibleContenidoDescripcion
                    }
                />
            )}
            <div
                className={
                    toggleButton
                        ? "flex justify-start px-5"
                        : "flex justify-start px-5"
                }
            >
                <h2 className="font-bold text-[#1C4CBB] text-lg  lg:text-3xl 2xl:text-4xl mb-3 ">
                    Sobre el alojamiento
                </h2>
                {toggleButton && (
                    <div
                        onClick={() => setModalVisibleComodidades(true)}
                        title="editar descripción"
                        className="flex flex-col items-center px-2 py-1 mr-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                    >
                        <svg
                            className="w-8 h-8 text-[#1C4CBB]"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                            <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                        </svg>
                        <div className="text-xs text-[#1C4CBB]">
                            Editar Descripción
                        </div>
                    </div>
                )}
            </div>
            <div className="text-justify mt-5 mx-5">
                <p className="text-[#666666] text-base lg:text-lg 2xl:text-xl font-semibold mt-2 ">
                    {titulo_descripcion_1
                        ? titulo_descripcion_1
                        : "La casa cuenta con:"}
                </p>
                {descripcion_1 ? (
                    <div
                        className="mt-3 text-sm md:text-base 2xl:text-lg text-[#666666]"
                        dangerouslySetInnerHTML={{
                            __html: descripcion_1.replace(/\n/g, "<br>"),
                        }}
                    ></div>
                ) : (
                    <p className="mt-3 text-[#666666] text-sm md:text-base lg:text-lg ">
                        Sala con chimenea, comedor, cocina equipada, zona de
                        parrilla con horno de barro, zona de fogata, terraza que
                        da a un amplio jardín con una hamaca y camillas para
                        disfrutar de la piscina y el exterior, piscina privada
                        de 1.5 metros de profundidad en la parte más honda, zona
                        de trabajo (2 escritorios), un huerto.
                    </p>
                )}
            </div>

            <div className="relative md:mt-8 2xl:mt-14">
                {tipo_descripcion === "Imagen" && (
                    <img
                        src={
                            typeof imagen_descripcion === "string"
                                ? `${process.env.REACT_APP_PROXY}/casa/getImage/${imagen_descripcion}`
                                : URL.createObjectURL(imagen_descripcion)
                        }
                        className="object-cover w-full rounded-lg 2xl:h-96 md:h-80 h-72"
                        alt={`video descripcion`}
                    />
                )}

                {tipo_descripcion === "Video" && (
                    <ReactPlayer
                        url={video_descripcion}
                        width="100%"
                        height="24rem"
                        controls
                        playing
                        muted
                        className="object-cover w-full  2xl:h-96 md:h-80 h-72"
                    />
                )}

                {toggleButton && (
                    <div
                        onClick={() =>
                            setModalVisibleContenidoDescripcion(true)
                        }
                        title="editar descripción"
                        className="absolute top-10 left-10 px-2 py-1 flex flex-col items-center rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                    >
                        <svg
                            className="w-8 h-8 text-[#1C4CBB]"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                            <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                        </svg>
                        <div className="text-xs text-[#1C4CBB]">
                            Editar Descripción
                        </div>
                    </div>
                )}
            </div>
            <div
                onClick={() => setModalImagesVisible(true)}
                className="flex gap-1 px-2 py-4 text-white  rounded-md cursor-pointer bg-[#1E90FF] hover:scale-105 text-sm 2xl:text-base select-none disabled:bg-slate-300 disabled:scale-100 mt-5 2xl:mt-14 w-1/2 mx-auto"
            >
                <p className="mx-auto font-bold">GALERÍA</p>
            </div>
        </div>
    );
};

export default Descripciones;
