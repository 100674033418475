interface Props {
    date: Date | string | undefined;
    className?: string;
}

export const ShowDateToUser = ({ date, className }: Props) => {
    if (!date) return <span>No se encontró fecha</span>;

    let final_date;

    if (typeof date === "string") {
        final_date = date.split("-").reverse().join("/");
    } else {
        const dateTo_YYYY_MM_DD = date.toLocaleDateString("zh-Hans-CN");

        final_date = dateTo_YYYY_MM_DD.split("/").reverse().join("/");
    }

    return <span className={` ${className}`}>{final_date}</span>;
};
