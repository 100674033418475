import axios from "axios";

interface Props {
    url: string;
    method?: "get" | "post" | "put" | "delete";
    body?: Object;
}

export const fetchAxiosToken = async ({ url, method, body }: Props) => {
    const BASE_URL = process.env.REACT_APP_PROXY;
    let token = localStorage.getItem("token");

    const currentURL = window.location.href;

    const startMarker = "/bienvenidalogin/";
    const endMarker = "/";
    const startIndex = currentURL.indexOf(startMarker);

    if (startIndex !== -1) {
        const endIndex = currentURL.indexOf(
            endMarker,
            startIndex + startMarker.length
        );

        if (endIndex !== -1) {
            const tokenOptenido = currentURL.slice(
                startIndex + startMarker.length,
                endIndex
            );

            token = tokenOptenido;

            localStorage.setItem("token", tokenOptenido);
        }
    }

    const headers = {
        authorization: token,
    };

    const { data } = await axios({
        method: method ? method : "get",
        url: `${BASE_URL}${url}`,
        data: body ? body : "",
        headers: headers,
    });

    return data;
};
