import { transformDatesToSlash } from "./transformDatesToSlash";

interface Props {
    current_day: Date;
    house_variable_prices: any[];
    base_price: number;
}

export const getEachDayPrice = ({
    current_day,
    house_variable_prices,
    base_price,
}: Props): number => {
    let final_price = base_price;

    //Encuentra si hay una coincidencia con una fecha dentro de precios_variables
    const dateMatch = house_variable_prices.find((variablePrice) => {
        const dateTransformed = transformDatesToSlash({
            date: variablePrice.fecha,
        });
        const new_date = new Date(dateTransformed);

        if (current_day.getTime() === new_date.getTime()) return variablePrice;
    });

    //Si encontré coincidencia, retorno ese precio, sino retorno precio base establecrido en linea 14
    if (dateMatch) {
        final_price = Number(dateMatch.precio);
    }

    return final_price;
};
