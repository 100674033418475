import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/hooks";
export const Planes = () => {
    const [Plan, setPlan] = useState(false);
    const { currency } = useAppSelector((state) => state.informacion);
    const [moneda, setMoneda] = useState("");
    const navigate = useNavigate();

    const handlePlan = () => {
        setPlan(!Plan);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        console.log("currency", currency);
        setMoneda(currency === "USD" ? "$" : "€");
    }, [currency]);
    return (
        <div className="mt-14 pt-28 bg-[#ffffff] ">
            <div className="flex flex-col justify-center h-auto mb-10 lg:mx-32">
                <div className="text-[#179BD8] text-center text-[35px] md:text-[40px] lg:text-[50px] xl:text-[55px] 2xl:text-[60px] font-semibold ">
                    Nuestros Planes
                </div>

                <div className="">
                    <div className="flex items-center justify-center px-1 py-1 mx-auto border-2 w-60 rounded-3xl">
                        <div
                            className={`${
                                !Plan
                                    ? "bg-[#0E1928] text-[#C0D345]"
                                    : " text-[#0E1928]"
                            }   text-center  rounded-3xl cursor-pointer w-1/2 h-full `}
                            onClick={handlePlan}
                        >
                            <p className="text-lg ">Anual</p>
                            <p
                                className={`text-[8px] w-full  mb-1 ${
                                    !Plan ? "text-white" : " text-[#0E1928]"
                                }`}
                            >
                                Ahorra un 20% de dscto
                            </p>
                        </div>
                        <div
                            className={` ${
                                Plan
                                    ? "bg-[#0E1928] text-[#C0D345]"
                                    : " text-[#0E1928]"
                            }   px-4 text-lg   rounded-3xl cursor-pointer w-1/2 h-full py-2 text-center`}
                            onClick={handlePlan}
                        >
                            Mensual
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center w-full gap-6 px-5 mx-auto mt-5 lg:flex-row ">
                <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5   h-auto w-[330px] mb-4">
                    <div
                        className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} `}
                    >
                        Starter
                    </div>

                    <div className="flex flex-col gap-8 md:gap-0">
                        <div className="flex flex-col ">
                            <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-2">
                                {Plan ? (
                                    <div className="flex">
                                        <p className="text-base font-bold">
                                            {moneda}
                                        </p>
                                        <p className="text-4xl font-bold">
                                            {" "}
                                            {moneda === "$" ? 19 : 19}
                                        </p>
                                    </div>
                                ) : (
                                    <div className="flex">
                                        <p className="text-base font-bold">
                                            {moneda}
                                        </p>
                                        <p className="text-5xl text-[#179BD8] font-bold">
                                            {" "}
                                            {moneda === "$" ? 16 : 16}
                                        </p>
                                    </div>
                                )}

                                <div className="text-base font-semibold xl:text-lg">
                                    /mes
                                </div>
                            </div>
                            <div className="text-[#0E1928]  text-sm  flex items-center gap-1 mt-2">
                                {moneda}
                                {!Plan
                                    ? moneda === "$"
                                        ? "192"
                                        : "192"
                                    : moneda === "$"
                                    ? "228"
                                    : "228"}{" "}
                                al año.
                                {!Plan && (
                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                        Ahorras {moneda}
                                        {moneda === "$" ? 36 : 36}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col ">
                            <div
                                className={`text-[#0E1928] text-sm  mt-4 mb-0`}
                            >
                                <ul className="marker:text-[#0E1928] text-xs md:text-base list-outside list-disc ml-6">
                                    <li className="mb-1">
                                        Landing personalizada
                                    </li>
                                    <li className="mb-1">
                                        Dominio para tu página
                                    </li>
                                    <li className="mb-1">
                                        Reseñas de clientes
                                    </li>
                                    <li className="mb-1">
                                        Atributos de la casa
                                    </li>
                                    <li className="mb-1">
                                        Pago por transferencia
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#179BD8] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto"
                        >
                            <p className="w-full text-center">COMPRAR</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col  rounded-2xl  bg-[#0E1928] text-white border-solid px-5   h-auto w-[330px] mb-4 ">
                    <div
                        className={` text-lg md:text-xl xl:text-2xl flex items-center justify-between w-full  ${"mt-5"} `}
                    >
                        <p>Estándar</p>{" "}
                        <p className="text-xs text-[#C0D345] bg-[#C0D345]/10 rounded-3xl px-3 py-1">
                            El más seleccionado
                        </p>
                    </div>

                    <div className="flex flex-col gap-8 md:gap-0">
                        <div className="flex flex-col ">
                            <div className="flex items-end mt-2 text-2xl md:text-3xl xl:text-5xl">
                                {Plan ? (
                                    <div className="flex">
                                        <p className="text-base font-bold">
                                            {moneda}
                                        </p>
                                        <p className="text-4xl font-bold text-[#C0D345]">
                                            {" "}
                                            {moneda === "$" ? 39 : 39}
                                        </p>
                                    </div>
                                ) : (
                                    <div className="flex">
                                        <p className="text-base font-bold">
                                            {moneda}
                                        </p>
                                        <p className="text-5xl text-[#C0D345] font-bold">
                                            {" "}
                                            {moneda === "$" ? 32 : 32}
                                        </p>
                                    </div>
                                )}

                                <div className="text-base font-semibold xl:text-lg">
                                    /mes
                                </div>
                            </div>
                            <div className="flex items-center gap-1 mt-2 text-sm ">
                                {moneda}
                                {!Plan
                                    ? moneda === "$"
                                        ? "384"
                                        : "384"
                                    : moneda === "$"
                                    ? "468"
                                    : "468"}{" "}
                                al año.
                                {!Plan && (
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        Ahorras {moneda}
                                        {moneda === "$" ? 84 : 84}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col ">
                            <div className={` text-sm  mt-4 mb-0`}>
                                <ul className="ml-6 text-xs list-disc list-outside marker:text-white md:text-base">
                                    <li className="mb-1">
                                        Calendario de reservas
                                    </li>
                                    <li className="mb-1">
                                        Plataformas de pago
                                    </li>
                                    <li className="mb-1">
                                        Creación de cupones
                                    </li>
                                    <li className="mb-1">
                                        Chat y gestión de clientes
                                    </li>
                                    <li className="mb-1">
                                        Resumen de estadísticas
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#C0D345] py-1 px-8 my-8 text-base md:text-xl text-[#0E1928] font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto"
                        >
                            <p className="w-full text-center">COMPRAR</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5   h-auto w-[330px] mb-4">
                    <div
                        className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} `}
                    >
                        Premium
                    </div>

                    <div className="flex flex-col gap-8 md:gap-0">
                        <div className="flex flex-col ">
                            <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-2">
                                {Plan ? (
                                    <div className="flex">
                                        <p className="text-base font-bold">
                                            {moneda}
                                        </p>
                                        <p className="text-4xl font-bold">
                                            {" "}
                                            {moneda === "$" ? 79 : 79}
                                        </p>
                                    </div>
                                ) : (
                                    <div className="flex">
                                        <p className="text-base font-bold">
                                            {moneda}
                                        </p>
                                        <p className="text-5xl text-[#179BD8] font-bold">
                                            {" "}
                                            {moneda === "$" ? 64 : 64}
                                        </p>
                                    </div>
                                )}

                                <div className="text-base font-semibold xl:text-lg">
                                    /mes
                                </div>
                            </div>
                            <div className="text-[#0E1928]  text-sm  flex items-center gap-1 mt-2">
                                {moneda}
                                {!Plan
                                    ? moneda === "$"
                                        ? "768"
                                        : "768"
                                    : moneda === "$"
                                    ? "948"
                                    : "948"}{" "}
                                al año.
                                {!Plan && (
                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                        Ahorras {moneda}
                                        {moneda === "$" ? 180 : 180}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col ">
                            <div
                                className={`text-[#0E1928] text-sm  mt-4 mb-0`}
                            >
                                <ul className="marker:text-[#0E1928] text-xs md:text-base list-outside list-disc ml-6">
                                    <li className="mb-1">
                                        Dominio personalizado
                                    </li>
                                    <li className="mb-1">
                                        Asistente administrativo
                                    </li>
                                    <li className="mb-1">
                                        Asesoría en pauta digital
                                    </li>
                                    <li className="mb-1">
                                        Integración de facturación
                                    </li>
                                    <li className="mb-1">
                                        Integrar Airbnb y Booking
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#179BD8] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto"
                        >
                            <p className="w-full text-center">COMPRAR</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
