import React, { useRef, useState } from "react";

export interface getinfo {
    pagina?: number;
    habilitado?: boolean;
    query?: string;
}

interface Props {
    enabled: boolean;
    callbackFunction: (props: getinfo) => void;
}

const DinamicSearchBar = ({ enabled, callbackFunction }: Props) => {
    const [input, setInput] = useState("");
    const debounceInput = useRef<any>();

    const handleInputChangeDebounce = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setInput(e.target.value);
        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            if (enabled)
                callbackFunction({
                    pagina: 1,
                    habilitado: true,
                    query: "",
                });
            else
                callbackFunction({
                    pagina: 1,
                    habilitado: false,
                    query: "",
                });
            return;
        }
        debounceInput.current = setTimeout(async () => {
            if (enabled)
                callbackFunction({
                    pagina: 1,
                    habilitado: true,
                    query: e.target.value,
                });
            else
                callbackFunction({
                    pagina: 1,
                    habilitado: false,
                    query: e.target.value,
                });
        }, 1000);
    };

    return (
        <div className="flex p-1 m-5 border rounded appearance-none w-96 left-2 sm:max-w-screen-sm text-stone-900">
            <button className="flex items-center justify-center px-1">
                <svg
                    className="w-6 h-6 text-blue-blueFrom"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                </svg>
            </button>
            <input
                onBlur={() => setInput("")}
                value={input}
                type="text"
                onChange={handleInputChangeDebounce}
                className="w-full px-1 py-2 bg-transparent outline-none appearance-none placeholder:text-[#3C3C3C]"
                placeholder="Buscar"
            />
        </div>
    );
};

export default DinamicSearchBar;
