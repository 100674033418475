import React, { useEffect, useState } from "react";
import { number } from "yargs";

interface props {
    starsQuantity: number;
    setInput?: (n: number) => void;
    value?: number;
}

const StarsRating = ({ starsQuantity, setInput, value }: props) => {
    const [rating, setRating] = useState<number>(-1);
    const [hoverValue, setHoverValue] = useState<number>(value ? value - 1 : 5);

    const Star = ({ index }: { index: number }) => {
        return (
            <svg
                className="2xl:h-8 2xl:w-8 md:h-7 md:w-7 h-6 w-6 text-[#FDCC0D] cursor-pointer"
                viewBox="0 0 24 24"
                fill={`${
                    index <= rating || index <= hoverValue ? "#FDCC0D" : "#FFF"
                }`}
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                onMouseEnter={() => setInput && setHoverValue(index)}
                onMouseLeave={() => setInput && setHoverValue(-1)}
                onClick={() => {
                    setRating(index);
                    setInput && setInput(index + 1);
                }}>
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
            </svg>
        );
    };

    return (
        <div className="flex mt-4">
            {[...Array(starsQuantity)].map((star, index) => (
                <div key={index}>
                    <Star index={index} />
                </div>
            ))}
        </div>
    );
};

export default StarsRating;
