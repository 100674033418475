import { infoCasa } from "../designs/calendario-reserva/typeCasa";
import { DateIsBetween2Dates } from "../helpers/DateIsBetween2Dates";
import { transformDatesToSlash } from "../helpers/transformDatesToSlash";
import {
    remove_descuento_estadia,
    set_descuento_estadia,
} from "../redux/reducers/precioReservaReducer";
import { useAppDispatch, useAppSelector } from "./hooks";

export const useDescuentoEstadia = ({ descuentos_tiempos }: infoCasa) => {
    let descuento_estadia = 0;
    const dispatch = useAppDispatch();
    const { dates_selected, precio_alquiler, precio_servicios_adicionales } =
        useAppSelector((state) => state.precios_reserva);

    const total_nights = dates_selected.allDates.length;

    const matchDiscountFecha = descuentos_tiempos.find((descuento) => {
        if (
            total_nights >= descuento.dia_inicio &&
            total_nights <= descuento.dia_final &&
            descuento.tipo === "Fecha"
        ) {
            //Transformo las fechas de la base de datos a objetos new Date()
            const nueva_fecha_inicio = new Date(
                transformDatesToSlash({ date: descuento.fecha_inicio })
            );
            const nueva_fecha_final = new Date(
                transformDatesToSlash({ date: descuento.fecha_final })
            );

            if (
                dates_selected.allDates.some((dateSelected) => {
                    if (
                        DateIsBetween2Dates({
                            fecha_evaluada: dateSelected,
                            fecha_inicio: nueva_fecha_inicio,
                            fecha_final: nueva_fecha_final,
                        })
                    )
                        return true;
                })
            ) {
                return descuento;
            }
        }
    });

    //Si en matchNights encuentro uno por fechas lo uso, sino intento encontrar uno por tipo "Siempre"
    if (matchDiscountFecha) {
        descuento_estadia = Number(matchDiscountFecha.descuento);
        dispatch(set_descuento_estadia(matchDiscountFecha));
    } else {
        const matchDiscountSiempre = descuentos_tiempos.find(
            (descuento) =>
                total_nights >= descuento.dia_inicio &&
                total_nights <= descuento.dia_final &&
                descuento.tipo === "Siempre"
        );

        if (matchDiscountSiempre) {
            descuento_estadia = Number(matchDiscountSiempre.descuento);
            dispatch(set_descuento_estadia(matchDiscountSiempre));
        } else {
            descuento_estadia = 0;
            dispatch(remove_descuento_estadia());
        }
    }
};
