import React, { useState, useEffect } from "react";
import Spinner from "../../../components/helpers/Spinner";
import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import AOS from "aos";
import "aos/dist/aos.css";
import { Paginado } from "../../../components/Paginado";
// import SearchBar from "./SearchBar";
import { useNavigate, useParams } from "react-router-dom";
// import ModalUsuariosCasa from "./ModalUsuariosCasa";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { useAppSelector } from "../../../hooks/hooks";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { ModalOnePago } from "./ModalOnePago";

interface getComfort {
    pagina: number;
    habilitado: boolean;
    query: string;
}

export interface CasasChats {
    id: number;
    count_chats: number;
    casa: Casa;
}

export interface SuscripcionActual {
    id: number;
    id_casa: number;
    plan: string;
    costo: string;
    fecha_pago: string;
    fecha_vencimiento: string;
    tipo_pago: string;
    estado: string;
    motivo_rechazo: string;
    metodo: string;
    imagen: string;
}
export interface PagoSuscricion {
    id: number;
    id_casa: number;
    plan: string;
    costo: string;
    fecha_pago: string;
    fecha_vencimiento: string;
    fecha_inicio: string;
    tipo_pago: string;
    estado: string;
    motivo_rechazo: string;
    metodo: string;
    imagen: string;
    casa: Casa;
    actual: string;
    tipo_moneda: string;
}

export interface Casa {
    id: number;
    nombre: string;
    banner: string;
}

const BASICO = [
    "Landing personalizada",
    "Dominio para tu página",
    "Reseñas de clientes",
    "Atributos de la casa",
    "Pago por transferencia",
];
const STANDARD = [
    "Calendario de reservas",
    "Plataformas de pago",
    "Creación de cupones",
    "Chat y gestión de clientes",
    "Resumen de estadisticas",
];
const PREMIUM = [
    "Dominio personalizado",
    "Asistente administrativo",
    "Asesoría en pauta digital",
    "Integracion de facturación",
    "Integrar Airbnb y Booking",
];

export const Suscripciones = () => {
    AOS.init();

    const { headers } = useHeaders();
    const [suscricionActual, setSuscripcionActual] =
        useState<SuscripcionActual>();
    const [pagoSelect, setPagoSelect] = useState<PagoSuscricion>();
    const [comforts, setComforts] = useState<PagoSuscricion[]>([]);
    const { id: id_casa } = useAppSelector(
        (state) => state.landing_house.house
    );
    const [comfortsTotal, setComfortsTotal] = useState<number>(0);
    const [enabled, setenabled] = useState<boolean>(true);
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [create, setcreate] = useState<boolean>(false);
    const [modalEnabled, setModalEnabled] = useState<boolean>(false);
    // const [comfortEdit, setComfortEdit] = useState<UsuariosCasa>();
    const [cargando, setCargando] = useState<boolean>(false);
    const COMFORTS_PER_PAGE = 20;
    const [modalUsuarioVisible, setModalUsuarioVisible] =
        useState<boolean>(false);
    const navigate = useNavigate();
    const [mes, setMes] = useState<number>(new Date().getMonth() + 1);
    const [ano, setAno] = useState<number>(new Date().getFullYear());
    const [verMas, setVerMas] = useState<boolean>(false);
    console.log(id_casa);

    const months = [
        { name: "Enero", value: 1 },
        { name: "Febrero", value: 2 },
        { name: "Marzo", value: 3 },
        { name: "Abril", value: 4 },
        { name: "Mayo", value: 5 },
        { name: "Junio", value: 6 },
        { name: "Julio", value: 7 },
        { name: "Agosto", value: 8 },
        { name: "Septiembre", value: 9 },
        { name: "Octubre", value: 10 },
        { name: "Noviembre", value: 11 },
        { name: "Diciembre", value: 12 },
    ];

    const getPedidos = async ({ pagina, habilitado, query }: getComfort) => {
        try {
            setCargando(false);
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/suscripcion_pagos/getAll/${pagina}/${id_casa}`,
                { headers }
            );
            console.log(data);
            setComforts(data);
            // setTotal(data.total)
            setCargando(true);
        } catch (error) {
            console.log(error);
        }
    };
    const getSuscripcionActual = async () => {
        try {
            // setCargando(false)
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/suscripcion_pagos/getCurrentSuscripcion/${id_casa}`,
                { headers }
            );
            console.log(data);
            setSuscripcionActual(data);
            // setTotal(data.total)
            // setCargando(true)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (id_casa) {
            getSuscripcionActual();
            getPedidos({
                pagina: pageNumber,
                habilitado: enabled,
                query: "",
            });
        }
    }, [id_casa]);

    const getOneSuscripcion = async (pagoSelect: PagoSuscricion) => {
        setPagoSelect(pagoSelect);
        setVerMas(true);
    };

    useEffect(() => {
        getPedidos({ pagina: pageNumber, habilitado: enabled, query: "" });
    }, [enabled, create, mes, ano]);

    return (
        <div>
            <div className="absolute z-[999] flex items-center justify-center w-full text-center">
                {verMas && pagoSelect !== undefined && (
                    <ModalOnePago
                        modalVisible={verMas}
                        setModalVisible={setVerMas}
                        pagoSelect={pagoSelect}
                    />
                )}
            </div>
            <Navbar titulo="Suscripciones">
                <div className="w-full mx-8 mt-12 border-[#CCCCCC] border-2 rounded-xl ">
                    <div className="flex justify-around w-full px-2 xs:px-6 mx-auto border-[#CCCCCC] border-b-2 2xl:py-8 py-5 text-[#333333]">
                        <div className="flex flex-col items-center justify-center gap-2 lg:flex-row">
                            <p className="text-base 2xl:text-lg">
                                Plan actual:
                            </p>{" "}
                            <p className="font-bold px-4 py-2 rounded-lg border border-[#CCCCCC] bg-[#DAF900] bg-opacity-20 2xl:text-base text-sm">
                                {suscricionActual?.plan}
                            </p>
                        </div>

                        <div className="flex flex-col items-center justify-center gap-2 lg:flex-row">
                            <p className="text-base 2xl:text-lg">
                                Vencimiento:
                            </p>{" "}
                            <p className="font-bold px-4 py-2 rounded-lg border border-[#CCCCCC] 2xl:text-base text-sm">
                                <ShowDateToUser
                                    date={suscricionActual?.fecha_vencimiento.slice(
                                        0,
                                        10
                                    )}
                                />
                            </p>
                        </div>

                        <div className="flex flex-col items-center justify-center gap-2 lg:flex-row">
                            <p className="text-base 2xl:text-lg">
                                Ciclo de Pago:{" "}
                            </p>{" "}
                            <p className="font-bold px-4 py-2 rounded-lg border border-[#CCCCCC] 2xl:text-base text-sm">
                                {suscricionActual?.tipo_pago}
                            </p>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className="grid px-5 mt-5 mb-10 lg1170:gap-4 lg:grid-cols-2 justify-items-center">
                                <div
                                    onClick={() =>
                                        window.open(
                                            `https://www.blustay.io/planes`,
                                            "_blank"
                                        )
                                    }
                                    className="grid xs:grid-cols-2 px-4 rounded-md shadow-xl gap-4 xs:gap-1  border-2 border-[#1C4CBB]  w-full 2xl:py-6 py-4 cursor-pointer hover:scale-105">
                                    <div className="flex flex-col items-center justify-center gap-2 ">
                                        <p className="2xl:text-xl text-lg text-[#1C4CBB] font-bold group-hover:text-white ">
                                            Mi plan:
                                        </p>
                                        <p className="2xl:text-2xl text-xl text-[#1C4CBB] font-bold group-hover:text-white uppercase ">
                                            {suscricionActual?.plan}
                                        </p>
                                    </div>

                                    <div className="flex items-center justify-center ml-6 xs:ml-0 ">
                                        <ul className="marker:text-[#1c4cbb] text-xs list-outside list-disc ">
                                            {suscricionActual?.plan ===
                                                "Stándar" &&
                                                STANDARD.map((a, i) => {
                                                    return (
                                                        <li
                                                            key={i}
                                                            className="mb-2">
                                                            {a}
                                                        </li>
                                                    );
                                                })}

                                            {suscricionActual?.plan ===
                                                "Premium" &&
                                                PREMIUM.map((a, i) => {
                                                    return (
                                                        <li
                                                            key={i}
                                                            className="mb-2">
                                                            {a}
                                                        </li>
                                                    );
                                                })}
                                            {suscricionActual?.plan ===
                                                "Starter" &&
                                                BASICO.map((a, i) => {
                                                    return (
                                                        <li
                                                            key={i}
                                                            className="mb-2">
                                                            {a}
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                </div>

                                <div
                                    // data-aos="fade-left"
                                    className="grid grid-cols-1 gap-3 px-4 mt-6 lg:grid-cols-3 w-80 md:w-full lg:mt-0">
                                    <div className="flex flex-col items-center justify-center gap-4 text-center text-white bg-green-500 rounded-md">
                                        <p className="text-lg font-medium">
                                            Plan Starter
                                        </p>
                                        <div className="w-11/12 px-5 py-2 mx-auto font-medium text-center text-green-500 uppercase bg-white rounded-lg cursor-pointer ">
                                            <p
                                                onClick={() =>
                                                    navigate(
                                                        `/checkout/suscripcion/1/1`
                                                    )
                                                }>
                                                Suscribirse
                                            </p>
                                        </div>
                                    </div>
                                    <div className="rounded-md bg-[#1C4CBB] text-white text-center flex flex-col justify-center items-center gap-4">
                                        <p className="text-lg font-medium">
                                            Plan Stándar
                                        </p>
                                        <div className=" px-5 py-2 rounded-lg bg-white  uppercase font-medium text-center text-[#1C4CBB] cursor-pointer w-11/12 mx-auto ">
                                            <p
                                                onClick={() =>
                                                    navigate(
                                                        `/checkout/suscripcion/2/1`
                                                    )
                                                }>
                                                Suscribirse
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center gap-4 text-center text-white bg-orange-500 rounded-md">
                                        <p className="text-lg font-medium">
                                            Plan Premium
                                        </p>
                                        <div className="w-11/12 px-5 py-2 mx-auto font-medium text-center text-orange-500 uppercase bg-white rounded-lg cursor-pointer ">
                                            <p
                                                onClick={() =>
                                                    navigate(
                                                        `/checkout/suscripcion/3/1`
                                                    )
                                                }>
                                                Suscribirse
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* eliminado */}
                            <div className="hidden gap-4 px-5 mt-3 mb-10 2xl:mt-5 lg1170:grid-cols-3 justify-items-center">
                                <div
                                    // data-aos="fade-right"
                                    className="flex flex-col items-center justify-center px-4 py-2 rounded-md shadow-xl  border-2 border-[#1C4CBB] w-80 md:w-full">
                                    <p className="text-2xl text-[#1C4CBB] font-bold group-hover:text-white py-2 uppercase">
                                        {suscricionActual?.plan}
                                    </p>

                                    <div className="grid grid-cols-2 pl-1 mr-4 md:mr-0 xl:pl-4">
                                        <div className="flex items-center justify-center ">
                                            <ul className="marker:text-[#1c4cbb] text-xs list-outside list-disc ">
                                                {suscricionActual?.plan ===
                                                    "Stándar" &&
                                                    STANDARD.map((a, i) => {
                                                        return (
                                                            <li
                                                                key={i}
                                                                className="mb-2">
                                                                {a}
                                                            </li>
                                                        );
                                                    })}

                                                {suscricionActual?.plan ===
                                                    "Premium" &&
                                                    PREMIUM.map((a, i) => {
                                                        return (
                                                            <li
                                                                key={i}
                                                                className="mb-2">
                                                                {a}
                                                            </li>
                                                        );
                                                    })}
                                                {suscricionActual?.plan ===
                                                    "Básico" &&
                                                    BASICO.map((a, i) => {
                                                        return (
                                                            <li
                                                                key={i}
                                                                className="mb-2">
                                                                {a}
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                        <div className="flex flex-col items-center justify-center gap-4 xl:justify-start">
                                            <p className="text-[#1C4CBB] font-bold text-3xl mx-auto ">
                                                $0
                                            </p>
                                            <div className=" px-5 py-2 rounded-lg bg-[#cccccc]  uppercase font-medium text-center text-white cursor-not-allowed mx-auto  ">
                                                <p>COMPRAR</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    // data-aos="fade-right"
                                    className="flex flex-col items-center justify-center px-4 py-2 rounded-md shadow-xl  border-2 border-[#1C4CBB]  w-80 md:w-full">
                                    <p className="text-2xl text-[#1C4CBB] font-bold uppercase group-hover:text-white pt-2">
                                        Stándar
                                    </p>

                                    <p className="text-sm mb-1 text-[#666666]">
                                        Más seleccionado
                                    </p>

                                    <div className="grid grid-cols-2 pl-1 xl:pl-4">
                                        <div className="flex items-center justify-center ">
                                            <ul className="marker:text-[#1c4cbb] text-xs list-outside list-disc ">
                                                <li className="mb-2">
                                                    Calendario de reservas
                                                </li>
                                                <li className="mb-2">
                                                    Plataformas de pago
                                                </li>
                                                <li className="mb-2">
                                                    Creación de cupones
                                                </li>
                                                <li className="mb-2">
                                                    Chat y gestión de clientes
                                                </li>
                                                <li className="mb-2">
                                                    Resumen de estadisticas
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="flex flex-col items-center justify-center gap-4 xl:justify-start">
                                            <p className="text-[#1C4CBB] font-bold text-3xl mx-auto ">
                                                $99
                                            </p>
                                            <div
                                                onClick={() =>
                                                    navigate(
                                                        "/checkout/suscripcion/1"
                                                    )
                                                }
                                                className=" px-5 py-2 rounded-lg bg-[#1C4CBB]  uppercase font-medium text-center text-white cursor-pointer mx-auto  ">
                                                <p>COMPRAR</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    // data-aos="fade-left"
                                    className="flex flex-col items-center justify-center px-4 py-2 rounded-md shadow-xl  border-2 border-[#1C4CBB] w-80 md:w-full">
                                    <h2 className="text-2xl text-[#1C4CBB] font-bold uppercase group-hover:text-white py-2">
                                        Premium
                                    </h2>

                                    <div className="grid grid-cols-2 pl-1 xl:pl-4">
                                        <div className="flex items-center justify-center ">
                                            <ul className="marker:text-[#1c4cbb] text-xs list-outside list-disc ">
                                                <li className="mb-2">
                                                    Dominio personalizado
                                                </li>
                                                <li className="mb-2">
                                                    Asistente administrativo
                                                </li>
                                                <li className="mb-2">
                                                    Asesoría en pauta digital
                                                </li>
                                                <li className="mb-2">
                                                    Integracion de facturación
                                                </li>
                                                <li className="mb-2">
                                                    Integrar Airbnb y Booking
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="flex flex-col items-center justify-center gap-4 xl:justify-start">
                                            <p className="text-[#1C4CBB] font-bold text-3xl mx-auto ">
                                                $499
                                            </p>
                                            <div
                                                onClick={() =>
                                                    navigate(
                                                        "/checkout/suscripcion/2"
                                                    )
                                                }
                                                className=" px-5 py-2 rounded-lg bg-[#1C4CBB]  uppercase font-medium text-center text-white cursor-pointer mx-auto  ">
                                                <p>COMPRAR</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* eliminado */}
                        </div>
                    </div>
                    <div className="flex justify-center w-full">
                        <p className="mx-auto text-[#1C4CBB] font-bold 2xl:text-3xl text-2xl">
                            Historial
                        </p>
                    </div>
                    <section className="flex justify-center items-center lg:py-[10px] ">
                        <div className="w-11/12 lg:mx-auto">
                            <div className="flex flex-wrap -mx-4">
                                <div className="w-full ">
                                    <div className="flex flex-col">
                                        {cargando ? (
                                            <>
                                                <div className={` `}>
                                                    <table className="w-full mt-2 overflow-x-auto">
                                                        <thead className="text-sm text-left 2xl:text-base">
                                                            {comforts.length ? (
                                                                <tr className="border-y-2  border-[#CCCCCC]">
                                                                    <th className="w-full min-w-[6rem] md:w-4/12 py-4 text-center ">
                                                                        Monto
                                                                    </th>
                                                                    <th className="w-full min-w-[8rem] md:w-4/12 table-cell py-4 text-center">
                                                                        Fecha de
                                                                        pago
                                                                    </th>
                                                                    <th className="w-2/12 text-center min-w-[6rem] hidden lg:table-cell py-4">
                                                                        Fecha
                                                                        inicio
                                                                    </th>
                                                                    <th className="w-2/12 text-center min-w-[6rem] hidden lg:table-cell py-4">
                                                                        Fecha
                                                                        final
                                                                    </th>
                                                                    <th className="w-2/12 text-center min-w-[6rem] hidden md723:table-cell py-4">
                                                                        Plan
                                                                    </th>

                                                                    <th className="w-2/12 text-center min-w-[6rem] md:table-cell   py-4">
                                                                        Estado
                                                                    </th>
                                                                </tr>
                                                            ) : (
                                                                <p className="text-center"></p>
                                                            )}
                                                        </thead>
                                                        <div className="2xl:py-2"></div>
                                                        <tbody className="text-sm 2xl:text-base">
                                                            {comforts?.map(
                                                                (comforts) => (
                                                                    <tr
                                                                        onClick={() =>
                                                                            getOneSuscripcion(
                                                                                comforts
                                                                            )
                                                                        }
                                                                        key={
                                                                            comforts.id
                                                                        }
                                                                        className={`w-full ${
                                                                            comforts.actual ===
                                                                            "Si"
                                                                                ? "bg-[#5aad00] bg-opacity-25"
                                                                                : "bg-white"
                                                                        } `}>
                                                                        <td className="border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className="mx-3 text-[#3C3C3C]">
                                                                                    {
                                                                                        comforts?.costo
                                                                                    }{" "}
                                                                                    {
                                                                                        comforts?.tipo_moneda
                                                                                    }
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-cell border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    <ShowDateToUser
                                                                                        date={
                                                                                            comforts?.fecha_pago
                                                                                        }
                                                                                    />
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="lg:table-cell hidden border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    <ShowDateToUser
                                                                                        date={
                                                                                            comforts?.fecha_inicio
                                                                                        }
                                                                                    />
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="lg:table-cell hidden border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    <ShowDateToUser
                                                                                        date={
                                                                                            comforts?.fecha_vencimiento
                                                                                        }
                                                                                    />
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="md723:table-cell hidden border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    {
                                                                                        comforts.plan
                                                                                    }
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-cell">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    {
                                                                                        comforts.estado
                                                                                    }
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        ) : (
                                            <Spinner />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <NavbarMobile />
            </Navbar>
        </div>
    );
};
