import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { useParams } from "react-router-dom";

interface Props {
    setModalVisibleComodidades: (modalVisibleComodidades: boolean) => void;
    modalVisibleComodidades: boolean;
}

interface comfort {
    id: number;
    nombre: string;
    icono: string;
    habilitado: string;
    fecha_registro: string;
}

export const ModalEditComforts = ({
    setModalVisibleComodidades,
    modalVisibleComodidades,
}: Props) => {
    const {
        id_propietario,
        casa_comodidades,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );

    const [saving, setSaving] = useState<boolean>(false);
    const [comforts, setComforts] = useState<comfort[]>([]);
    const [comfortsSelected, setComfortsSelected] = useState<number[]>([]);
    const [comodidadesSelected, setComodidadesSelected] = useState<number[]>(
        []
    );

    const getComforts = async () => {
        try {
            const data = await fetchAxiosToken({
                url: `/comodidad/getAll/0/Si?query=`,
                method: "get",
            });

            setComforts(data.rows);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const handleAttributeClick = (id: number) => {
        if (comfortsSelected.includes(id)) {
            setComfortsSelected(comfortsSelected.filter((item) => item !== id));
        } else {
            setComfortsSelected([...comfortsSelected, id]);
        }
    };

    useEffect(() => {
        let idComodidades: number[] = [];
        casa_comodidades.forEach((comodidad) => {
            idComodidades.push(comodidad.id_comodidad);
        });
        setComfortsSelected(idComodidades);
        setComodidadesSelected(idComodidades);
        getComforts();
    }, [modalVisibleComodidades]);

    const handleSubmit = async () => {
        setSaving(true);

        try {
            const data = await fetchAxiosToken({
                url: `/casa_comodidades/`,
                method: "post",
                body: {
                    id_casa: casa_id,
                    comodidades: comfortsSelected,
                },
            });

            try {
                dispatchDominio();
                setTimeout(() => {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        title: "Actualizado",
                        text: "Comodidades actualizadas",
                        icon: "success",
                        confirmButtonText: "Ok",
                    });
                    setSaving(false);
                    setModalVisibleComodidades(false);
                }, 2000);
            } catch (error: any) {
                console.log(error.response);
            }
        } catch (error: any) {
            console.log(error.response);
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleComodidades}
            setModalVisible={setModalVisibleComodidades}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-base font-medium">Editar comodidades</h2>
                <hr className="w-full bg-gray-300" />

                <div className="h-[60vh] overflow-hidden">
                    <div className="max-h-[60vh] overflow-auto w-[105%] md:w-[104%] lg:w-[80%] 2xl:w-[102%] grid grid-cols-2 mx-auto">
                        {casa_comodidades?.map((attribute) => {
                            return (
                                <div
                                    key={attribute.id}
                                    className="w-full p-3"
                                    onClick={() =>
                                        handleAttributeClick(
                                            attribute.id_comodidad
                                        )
                                    }>
                                    <div
                                        className={
                                            comfortsSelected.includes(
                                                attribute.id_comodidad
                                            )
                                                ? " items-center text-center text-base py-4 border border-solid border-[#3C3C3C] rounded-lg bg-[#F4F2F1] 2xl:h-24 h-20 cursor-pointer"
                                                : " items-center text-center text-base  py-4 border border-solid border-[#E9E9E9]  rounded-lg 2xl:h-24 h-20  cursor-pointer"
                                        }>
                                        <img
                                            className="object-contain w-5 h-5 mx-auto 2xl:w-6 2xl:h-6"
                                            src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/comodidad/getImage/${attribute.comodidade.icono}`}
                                            alt="imagen"
                                        />
                                        <span className=" 2xl:text-sm text-xs text-[#3C3C3C]">
                                            {attribute.comodidade.nombre}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                        {comforts?.map((comforts) => {
                            return (
                                <div
                                    key={comforts.id}
                                    className={`w-full p-3 ${
                                        comodidadesSelected.includes(
                                            comforts.id
                                        ) && "hidden"
                                    }`}
                                    onClick={() =>
                                        handleAttributeClick(comforts.id)
                                    }>
                                    <div
                                        className={
                                            comfortsSelected.includes(
                                                comforts.id
                                            )
                                                ? " items-center text-center text-base py-4 border border-solid border-[#3C3C3C] rounded-lg bg-[#F4F2F1] 2xl:h-24 h-20 cursor-pointer"
                                                : " items-center text-center text-base  py-4 border border-solid border-[#E9E9E9]  rounded-lg 2xl:h-24 h-20  cursor-pointer"
                                        }>
                                        <img
                                            className="w-5 h-5 mx-auto 2xl:w-6 2xl:h-6"
                                            src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/comodidad/getImage/${comforts?.icono}`}
                                            alt="Servicios"
                                        />

                                        <span className=" 2xl:text-sm text-xs text-[#3C3C3C]">
                                            {comforts.nombre}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="flex flex-col gap-4 mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                            Guardar cambios
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
