import React,{useEffect,useState} from "react";
import { useDispatch } from "react-redux";

import { Link, useParams } from "react-router-dom";
import Spinner from "../../../../components/helpers/Spinner";
import { fetchAxiosNoToken } from "../../../../helpers/fetchAxiosNoToken";
import { useAppSelector } from "../../../../hooks/hooks";
import { set_landing_house } from "../../../../redux/reducers/landingReducer";



const PagoConfirmado = () => {
    const dispatch = useDispatch();
    // const { id_pedido,tipo_pago } = useParams();
    // const[cargando,serCargando]=useState(true)
      const { banner } = useAppSelector((state) => state.landing_house.house);
 
    //   const putMercadoPago = async () => {
    //     try {
    //         const data = await fetchAxiosNoToken({
    //             url: `/mercadopago/updateCheckout`,
    //             method:"put",
    //             body:{
    //                 tipo_pago,
    //                 id_pedido
    //             }
            
    //         });

    //         console.log(data)
    //         if(data.msg === "Pedido actualizado"){

    //             serCargando(false)
    //         }
    //     } catch (error) {
    //         console.log(error);
           
    //     }
    // };
    
    const getHouseFromTenant = async () => {
        try {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/dominio`,
            });

            dispatch(set_landing_house(data));
            // putMercadoPago()
        } catch (error) {
            console.log(error);
           
        }
    };

    useEffect(() => {
        getHouseFromTenant();
    }, []);
    
    return (
        <div className="flex">
            {banner?<div
                style={{
                    backgroundImage: `url(${process.env.REACT_APP_PROXY}/casa/getImage/${banner})`,
                    filter: "brightness( 100% ) contrast( 113% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg )",
                }}
                className={`w-full bg-blue-500 h-screen bg-center bg-no-repeat
                bg-cover flex flex-col text-center`}>
                <div className="h-screen bg-[rgba(5,52,101,0.60)]">
                    {/* <div className="w-full">
                        <img
                            src={logo}
                            className="mt-20 lg:h-36 min-h-[3rem] w-full lg:max-w-md"
                        />
                    </div> */}
                    <h1 className="p-4 mt-20 text-4xl font-bold text-white lg:text-5xl">
                        ¡Gracias por tu pago!
                    </h1>
                    <h1 className="mt-5 text-xl font-bold text-white lg:text-3xl">
                       Tu pago fue procesado correctamente
                    </h1>
                    <div className="px-10">
                        <div className="flex flex-col max-w-lg py-10 mx-auto mt-10 rounded-lg bg-neutral-200 lg:mt-28 opacity-95">
                            {/* <h1 className="px-10 py-2 mb-10 text-2xl text-gray-600">
                                Muy pronto nos pondremos en contacto
                            </h1> */}
                            <Link to="/">
                                <span
                                    className={`font-semibold hover:border hover:underline border-gray-400 bg-blue-500 text-white p-3 min-w-max mx-auto cursor-pointer rounded-md text-lg`}>
                                    Volver al sitio
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>:
            <div className="mx-auto mt-20">

                <Spinner/>
            </div>
            }
        </div>
    );
};

export default PagoConfirmado;
