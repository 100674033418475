import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { Navbar } from "./Navbar";
import { NavbarMobile } from "./NavbarMobile";

interface house {
    id: number;
    pagina: string;
    terminado: string;
}

export const Inicio = () => {
    const [modalVisible, setModalVisible] = useState(true);
    const navigate = useNavigate();
    const { id: id_usuario } = useAppSelector((state) => state.usuario_logeado);
    const { terminado, pagina } = useAppSelector(
        (state) => state.landing_house.house
    );
    const dispatch = useAppDispatch();

    const getUserHouse = async () => {
        dispatch(getHouse(id_usuario)).then((data) => {
            // if (data.payload.terminado === "No") {
            //     setModalVisible(true);
            // } else {
            //     navigate("/dashboard/calendario");
            // }
        });
    };

    useEffect(() => {
        if (id_usuario) getUserHouse();
    }, [id_usuario]);

    const handleOnboarding = () => {
        navigate(
            `/dashboard/onboarding/${
                pagina.toString() === "1" ? "bienvenida" : pagina
            }`
        );
    };

    const handleClose = () => {
        if (terminado === "No") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Por favor termina tu onboarding de alojamiento primero",
                icon: "info",
                showDenyButton: true,
                denyButtonText: "Volver al home",
                confirmButtonText: "Ir al onboarding",
            }).then((res) => {
                if (res.isDenied) {
                    navigate("/");
                }
                if (res.isConfirmed) {
                    handleOnboarding();
                }
            });
        } else setModalVisible(false);
    };

    return (
        <div>
            <Navbar titulo="Inicio">
                <ResponsiveModalAbsolute
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    onClose={() => handleClose()}
                    hide_on_close={false}>
                    <div className="flex flex-col items-center">
                        <div className="flex flex-col w-full p-10">
                            <h2 className="font-medium text-center">
                                Para acceder al panel de propietarios es
                                necesario terminar de completar los datos de tu
                                alojamiento.
                            </h2>
                            <h3 className="mt-6 font-medium text-center">
                                O si quieres, puedes{" "}
                                <span
                                    className="cursor-pointer text-blue-blueFrom hover:underline"
                                    onClick={() => navigate("/")}>
                                    Regresar al home
                                </span>
                            </h3>
                            <button
                                onClick={() => handleOnboarding()}
                                className="flex items-center gap-2 p-3 mx-auto mt-10 border rounded-lg hover:scale-105 hover:underline">
                                <svg
                                    className="w-8 h-8 text-gray-600 rotate-180"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                                    />
                                </svg>
                                <span>Continuar al onboarding</span>
                            </button>
                        </div>
                    </div>
                </ResponsiveModalAbsolute>
                <NavbarMobile />
            </Navbar>
        </div>
    );
};
