import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import StarsRating from "../../pages/dashboardPropietarios/reservas/StarsRating";
import { getHouse } from "../../redux/reducers/onboardingReducer";

interface Props {
    setModalVisibleResenas: (modalVisibleResenas: boolean) => void;
    modalVisibleResenas: boolean;
}
export interface resena {
    id: number;
    id_pedidos: number;
    puntuacion: string;
    resena: string;
    fecha_registro: string;
    habilitado: string;
    pedido: Pedido | null;
    avatar: string;
    cliente_resena: string;
}

export interface Pedido {
    id: number;
    fecha_inicio: string;
    fecha_final: string;
    Cliente: Cliente;
}

export interface Cliente {
    nombre: string;
    apellido: string;
    correo: string;
    avatar: string;
}

export const ModalReviews = ({
    setModalVisibleResenas,
    modalVisibleResenas,
}: Props) => {
    const {
        id_propietario,
        casa_comodidades,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);
    const dispatchDominio = useDispatchDominio();

    const [saving, setSaving] = useState<boolean>(false);
    const [resena, setResena] = useState<resena[]>([]);
    const [comfortsSelected, setComfortsSelected] = useState<number[]>([]);

    const getComforts = async () => {
        try {
            const data = await fetchAxiosToken({
                url: `/resenas/getAll/Si?cliente=`,
                method: "get",
            });
            console.log(data);
            setResena(data.rows);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const handleAttributeClick = (id: number) => {
        if (comfortsSelected.includes(id)) {
            setComfortsSelected(comfortsSelected.filter((item) => item !== id));
        } else {
            setComfortsSelected([...comfortsSelected, id]);
        }
    };

    useEffect(() => {
        getComforts();
    }, []);

    const fecha = (date: string) => {
        const formatoFecha = new Date(date);

        return formatoFecha.toLocaleDateString("es-ES", {
            year: "numeric",
            month: "long",
        });
    };
    const avatar = (resena: resena) => {
        if (resena.avatar) {
            return resena.avatar;
        } else {
            return resena.pedido?.Cliente.avatar;
        }
    };
    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleResenas}
            setModalVisible={setModalVisibleResenas}
        >
            <div className="flex flex-col items-center max-w-2xl gap-2 mx-auto ">
                <h2 className="2xl:text-4xl md:text-2xl text-xl font-medium text-[#1c4cbb]">
                    RESEÑAS
                </h2>

                <hr className="w-full bg-gray-300" />

                <div className="grid gap-2 mt-4 sm:grid-cols-1 bg-white ">
                    {resena.length
                        ? resena.map((review) => (
                              <div className="px-8 py-5" key={review.id}>
                                  <div className="flex items-center gap-3">
                                      <img
                                          className="object-cover rounded-full 2xl:w-14 2xl:h-14 md:w-12 md:h-12 h-10 w-10"
                                          src={
                                              `${process.env.REACT_APP_PROXY}/usuarios/getImage/` +
                                              avatar(review)
                                          }
                                          alt="review"
                                      />
                                      <div className="flex justify-between  gap-4">
                                          <div>
                                              <div className="font-bold text-gray-700 text-base md:text-lg 2xl:text-xl ">
                                                  {review.pedido !== null
                                                      ? review.pedido?.Cliente
                                                            .nombre
                                                      : review.cliente_resena}
                                              </div>

                                              <p className="text-gray-400 text-sm md:text-base 2xl:text-lg">
                                                  {fecha(
                                                      review.pedido
                                                          ?.fecha_inicio ||
                                                          review.fecha_registro
                                                  )}
                                              </p>
                                          </div>

                                          {
                                              <StarsRating
                                                  starsQuantity={5}
                                                  value={Number(
                                                      review.puntuacion
                                                  )}
                                              />
                                          }
                                      </div>
                                  </div>
                                  <p className="mt-3 text-gray-600 md:text-base text-sm">
                                      {review.resena}
                                  </p>

                                  <hr className="w-full bg-gray-300 mt-5" />
                              </div>
                          ))
                        : null}
                </div>
                {!resena.length && (
                    <div className="w-full text-center">
                        <p>Cargando reseñas...</p>
                    </div>
                )}

                {/* <div className="flex flex-col gap-4 mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                            Guardar cambios
                        </button>
                    )}
                </div> */}
            </div>
        </ResponsiveModalAbsolute>
    );
};
