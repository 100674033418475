import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { NavbarBroker } from "./NavbarBroker";
import { NavbarMobileBroker } from "./NavbarMobileBroker";

interface house {
    id: number;
    pagina: string;
    terminado: string;
}

export const InicioBroker = () => {
    const navigate = useNavigate();
    const { terminado, pagina } = useAppSelector(
        (state) => state.onboarding_house
    );

    return (
        <div>
            <NavbarBroker titulo="Inicio">
                <div>HOLA</div>
            </NavbarBroker>
            <NavbarMobileBroker />
        </div>
    );
};
