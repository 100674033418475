import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";

export interface usuario {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    celular: string;
    avatar: string;
}

interface Props {
    setModalVisibleEditarUsuario: (modalVisibleEditarUsuario: boolean) => void;
    modalVisibleEditarUsuario: boolean;
    usuarioSelect: usuario;
}

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

export const ModalActualizarContrasenaUsuario = ({
    setModalVisibleEditarUsuario,
    modalVisibleEditarUsuario,
    usuarioSelect,
}: Props) => {
    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        password2: "",
        password: "",
    });

    const INPUTS = [
        {
            input_id: 3,
            label: "Nueva contraseña",
            nombre: "password",
            type: "password",
        },
        {
            input_id: 4,
            label: "Escribir nuevamente",
            nombre: "password2",
            type: "password",
        },
    ];

    const [saving, setSaving] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = async () => {
        const form = new FormData();
        if (userInfo.password === "" || userInfo.password2 === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: "La contraseña no puede estar vacía",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }
        if (userInfo.password !== userInfo.password2) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: "debe coincidir la contraseña",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }

        setSaving(true);

        try {
            const json = await fetchAxiosToken({
                url: `/subusuarios/putPasswordByOwner/${usuarioSelect.id}`,
                method: "put",
                body: {
                    password: userInfo.password,
                },
            });
            console.log(json);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                position: "top-end",
                icon: "success",
                title: "Usuario actualizado correctamente!",
                showConfirmButton: false,
                timer: 1500,
            });
            setModalVisibleEditarUsuario(false);
            setSaving(false);
        } catch (error: any) {
            console.log(error);
            setSaving(false);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: `${error.response.data.error}`,
                showConfirmButton: true,
                // timer: 1500,
            });
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleEditarUsuario}
            setModalVisible={setModalVisibleEditarUsuario}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="2xl:text-base text-sm font-medium">
                    Actualizar contraseña
                </h2>
                <hr className="w-full bg-gray-300" />

                <div className="flex flex-col items-center w-full 2xl:p-6">
                    {INPUTS.map(({ nombre, input_id, type, label }) => (
                        <div key={input_id} className="w-full max-w-xl">
                            <label className="font-medium 2xl:text-base text-sm">
                                {label}
                            </label>
                            <input
                                className="w-full 2xl:p-2 p-1.5 mt-1 border rounded-xl"
                                placeholder={label}
                                name={nombre}
                                value={userInfo[nombre]}
                                onChange={handleInputChange}
                                type={type}
                            />
                        </div>
                    ))}
                </div>

                <div className="flex flex-col gap-4 2xl:mt-8 mt-3">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSave()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 2xl:text-base text-sm">
                            Actualizar
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
