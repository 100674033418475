import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";

import { ModalEditCapacidades } from "../modalEdit/ModalEditCapacidades";

interface Props {
    colorIcono: string;
    toggleButton: boolean;
    opacity?: string;
}

export const CapacidadesCasa = ({
    toggleButton,
    colorIcono,
    opacity = "100%",
}: Props) => {
    const { banos, habitaciones, mascotas, personas } = useAppSelector(
        (state) => state.landing_house.house
    );

    const style = {
        color: `${colorIcono}`,
    };

    const [modalEditCapacidades, setModalEditCapacidades] = useState(false);

    return (
        <div
            className={`flex gap-2 py-2 pb-4 md:py-6 text-[${colorIcono}] justify-center items-center`}>
            <div className="flex flex-col items-center w-max gap-1.5">
                <svg
                    className={`w-8 h-8 md:w-10 md:h-10 2xl:h-12 2xl:w-12 border border-[${colorIcono}] rounded-full`}
                    id="Capa_2"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 62.33 62.33">
                    <g id="Portada">
                        <g>
                            <circle
                                className="cls-1"
                                cx="31.17"
                                cy="31.17"
                                r="30.67"
                                fill="white"
                                fillOpacity={opacity}
                            />
                            <g>
                                <path
                                    fill={`${colorIcono}`}
                                    className="cls-2"
                                    d="m39.6,39.12c0,1.02,0,2.05,0,3.07,0,.34-.13.55-.43.71-2.07,1.09-4.27,1.75-6.61,1.95-3.3.28-6.39-.4-9.32-1.91-.35-.18-.52-.4-.51-.82.02-1.91,0-3.82.01-5.74.01-2.29,1.55-4.44,3.7-5.21.23-.08.39-.05.59.1,2.62,1.99,5.67,2,8.27,0,.23-.17.41-.18.66-.08,2.19.86,3.63,2.93,3.64,5.29,0,.88,0,1.75,0,2.63Z"
                                />
                                <path
                                    fill={`${colorIcono}`}
                                    className="cls-2"
                                    d="m50.43,36.04c0,.85,0,1.7,0,2.54,0,.32-.13.53-.41.67-1.79.94-3.68,1.49-5.7,1.64-1.14.09-2.25-.01-3.37-.17-.33-.05-.44-.18-.45-.52-.01-1.4.07-2.8-.05-4.2-.17-2.05-1.12-3.65-2.77-4.85-.4-.29-.4-.28-.05-.64.41-.42.88-.75,1.41-1,.2-.1.36-.09.55.07,2.13,1.9,5.43,1.87,7.49,0,.18-.16.34-.17.55-.06,1.75.88,2.71,2.29,2.78,4.26.03.75,0,1.51,0,2.26Z"
                                />
                                <path
                                    fill={`${colorIcono}`}
                                    className="cls-2"
                                    d="m11.91,35.81c.05-.84-.13-1.89.1-2.91.34-1.56,1.25-2.68,2.69-3.38.23-.11.39-.08.58.08,2.26,1.88,5.2,1.88,7.43,0,.21-.17.38-.2.61-.08.54.27,1,.62,1.43,1.04.23.23.23.36-.05.56-1.51,1.05-2.44,2.49-2.75,4.3-.14.8-.12,1.61-.12,2.42,0,.79-.01,1.59,0,2.38,0,.3-.1.43-.39.49-3.22.56-6.26.04-9.15-1.48-.27-.14-.38-.35-.38-.65,0-.86,0-1.72,0-2.78Z"
                                />
                                <path
                                    fill={`${colorIcono}`}
                                    className="cls-2"
                                    d="m25.49,26.17c0-3.16,2.55-5.7,5.71-5.69,3.15,0,5.67,2.56,5.66,5.74,0,3.12-2.57,5.69-5.69,5.68-3.14,0-5.68-2.57-5.68-5.73Z"
                                />
                                <path
                                    fill={`${colorIcono}`}
                                    className="cls-2"
                                    d="m48.16,25.31c0,2.66-2.17,4.83-4.84,4.83-2.62,0-4.81-2.21-4.81-4.83,0-2.63,2.21-4.84,4.85-4.83,2.64,0,4.8,2.18,4.8,4.83Z"
                                />
                                <path
                                    fill={`${colorIcono}`}
                                    className="cls-2"
                                    d="m14.18,25.3c0-2.66,2.18-4.83,4.84-4.83,2.62,0,4.81,2.21,4.81,4.83,0,2.64-2.21,4.83-4.86,4.83-2.64,0-4.8-2.18-4.8-4.83Z"
                                />
                            </g>
                        </g>
                    </g>
                </svg>

                <div className="text-xs text-center md:text-base" style={style}>
                    {personas} Personas
                </div>
            </div>

            <div className="flex flex-col items-center justify-center w-max gap-1.5">
                <svg
                    className={`w-8 h-8 md:w-10 md:h-10 2xl:h-12 2xl:w-12 border border-[${colorIcono}] rounded-full`}
                    id="Capa_2"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 62.33 62.33">
                    <g id="Portada">
                        <g>
                            <circle
                                cx="31.17"
                                cy="31.17"
                                r="30.67"
                                fill="white"
                                fill-opacity={opacity}
                            />
                            <g>
                                <path
                                    fill={`${colorIcono}`}
                                    d="m13.82,31.56c.27-.84.78-1.43,1.67-1.66.26-.07.51-.05.77-.05,9.08,0,18.16,0,27.23,0,.11,0,.23,0,.34,0,.2.01.3-.06.28-.27,0-.08,0-.16,0-.24,0-4.5,0-9,0-13.5,0-.21,0-.43-.05-.64-.2-.96-1.31-1.38-2.05-.74-.6.53-1.14,1.12-1.71,1.68-.1.1-.11.18,0,.28.27.26.29.58.21.94-.22,1.06-.42,2.12-.64,3.18-.07.36-.2.68-.6.79-.38.1-.65-.09-.89-.35-1.36-1.4-2.71-2.8-4.07-4.2-.23-.24-.38-.51-.27-.85.11-.35.39-.5.74-.56,1.09-.18,2.18-.36,3.27-.53.32-.05.63-.04.88.22.17.18.29.03.39-.08.58-.57,1.14-1.16,1.74-1.7.95-.84,2.47-.84,3.47-.05.81.64,1.1,1.51,1.1,2.5,0,4.47,0,8.93,0,13.4,0,.85-.06.7.69.71,1.04.02,1.89.72,2.13,1.75.21.94-.27,1.96-1.18,2.39-.3.14-.43.33-.52.64-.56,2.01-1.14,4.01-1.72,6.01-.58,1.99-2.29,3.43-4.34,3.67-.34.04-.36.08-.21.4.21.42.43.85.63,1.27.25.51.14.96-.28,1.17-.41.2-.82.03-1.07-.47-.34-.67-.68-1.35-1.01-2.03-.1-.21-.24-.29-.47-.29-4.77,0-9.54,0-14.31,0-.24,0-.37.08-.47.29-.33.68-.67,1.35-1.01,2.03-.13.26-.3.48-.61.53-.29.04-.54-.04-.72-.28-.19-.26-.2-.55-.07-.83.22-.48.46-.96.71-1.42.13-.25.07-.33-.2-.37-2.26-.33-3.74-1.59-4.39-3.77-.6-2-1.15-4.01-1.72-6.01-.07-.23-.16-.39-.4-.49-.68-.29-1.07-.84-1.28-1.53v-.95Zm8.04,9.8c.61,0,.91-.21.94-.58.04-.39-.18-.67-.62-.74-.5-.07-.73-.37-.85-.83-.26-.97-.54-1.93-.82-2.89-.14-.47-.48-.7-.87-.59-.37.1-.57.44-.45.89.31,1.15.62,2.31,1,3.44.28.84.97,1.24,1.67,1.31Z"
                                />
                                <path
                                    fill={`${colorIcono}`}
                                    d="m31.13,26.2c0-.18.1-.37.25-.52.44-.45.88-.9,1.34-1.34.36-.35.81-.36,1.12-.06.32.31.32.74-.03,1.12-.45.48-.91.93-1.38,1.38-.25.24-.55.3-.87.15-.28-.13-.42-.36-.42-.72Z"
                                />
                                <path
                                    fill={`${colorIcono}`}
                                    d="m31.17,22.02c0,.15-.11.34-.27.5-.44.44-.87.88-1.31,1.31-.39.37-.82.38-1.14.05-.31-.32-.29-.74.07-1.11.44-.46.9-.91,1.36-1.36.25-.24.54-.31.87-.16.28.13.42.36.43.76Z"
                                />
                                <path
                                    fill={`${colorIcono}`}
                                    d="m34.72,19.74c.32,0,.55.13.69.39.13.25.15.52-.02.73-.5.62-1.06,1.19-1.69,1.68-.28.22-.69.14-.92-.11-.24-.25-.3-.65-.07-.92.5-.59,1.06-1.13,1.64-1.65.11-.1.26-.13.38-.13Z"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
                <div className="text-xs text-center md:text-base" style={style}>
                    {" "}
                    {banos} Baños
                </div>
            </div>

            <div className="flex flex-col items-center w-max gap-1.5">
                <svg
                    className={`w-8 h-8 md:w-10 md:h-10 2xl:h-12 2xl:w-12 border border-[${colorIcono}] rounded-full p-2`}
                    id="Capa_2"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 71.43">
                    <g id="Testimonios">
                        <g>
                            <path
                                fill={`${colorIcono}`}
                                d="m50.01,32.14c12.83,0,25.66,0,38.49,0,7.01,0,11.5,4.49,11.5,11.5,0,8,0,15.99,0,23.99,0,1.61-.69,2.81-2.16,3.47-1.39.63-2.69.35-3.83-.65-.7-.62-1.07-1.42-1.15-2.34-.02-.22,0-.45-.01-.67-.08-1.03.45-2.39-.22-2.98-.58-.51-1.91-.18-2.9-.18-27.07,0-54.14,0-81.22-.03-1.15,0-1.49.3-1.36,1.4.09.77.04,1.56,0,2.34-.1,1.93-1.67,3.43-3.57,3.42-1.89,0-3.53-1.51-3.54-3.44-.03-8.59-.11-17.18.04-25.77.1-5.57,4.94-10.01,10.66-10.04,7.29-.04,14.58-.01,21.87-.01,5.8,0,11.6,0,17.4,0Z"
                            />
                            <path
                                fill={`${colorIcono}`}
                                d="m49.97,0c11.67,0,23.35,0,35.02,0,2.96,0,4.29,1.33,4.29,4.28,0,6.54-.02,13.09.02,19.63,0,.87-.21,1.12-1.1,1.1-2.86-.06-5.73-.05-8.59,0-.8.01-1.1-.15-1.07-1.02.04-1.33.15-2.67-.14-4-.72-3.33-3.5-5.66-6.91-5.7-3.61-.04-7.21-.04-10.82,0-3.96.04-7.03,3.14-7.09,7.11-.02.97-.03,1.93,0,2.9.02.53-.16.71-.7.7-1.93-.03-3.87-.03-5.8,0-.58,0-.65-.25-.66-.74-.03-1.45.15-2.9-.17-4.34-.72-3.27-3.49-5.6-6.85-5.64-3.64-.04-7.29-.04-10.93,0-3.9.05-6.96,3.15-7.03,7.06-.02,1-.02,2.01,0,3.01.01.49-.16.64-.64.64-3.12-.02-6.25-.02-9.37,0-.51,0-.72-.13-.71-.68.02-6.99-.02-13.98.04-20.97.01-1.92,1.62-3.33,3.62-3.34,3.83-.02,7.66,0,11.49,0,8.03,0,16.06,0,24.09,0Z"
                            />
                        </g>
                    </g>
                </svg>
                <div className="text-xs text-center md:text-base" style={style}>
                    {" "}
                    {habitaciones} Habitaciones
                </div>
            </div>

            {mascotas > 0 ? (
                <div className="flex flex-col items-center w-max gap-1.5">
                    <svg
                        className={`w-8 h-8 md:w-10 md:h-10 2xl:h-12 2xl:w-12 border border-[${colorIcono}] rounded-full`}
                        id="Capa_2"
                        data-name="Capa 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 62.33 62.33">
                        <g id="Portada">
                            <g>
                                <circle
                                    cx="31.17"
                                    cy="31.17"
                                    r="30.67"
                                    fill="white"
                                    fillOpacity={opacity}
                                />
                                <g>
                                    <path
                                        fill={`${colorIcono}`}
                                        d="m14.73,27.76c.13-.57.29-1.13.63-1.62,1-1.45,2.81-1.81,4.39-.83,1.43.88,2.17,2.22,2.45,3.84.11.64.06,1.27-.1,1.89-.62,2.39-3.08,3.28-5.12,1.85-1.13-.79-1.81-1.88-2.14-3.21-.04-.15-.07-.31-.11-.46,0-.49,0-.98,0-1.46Z"
                                    />
                                    <path
                                        fill={`${colorIcono}`}
                                        d="m47.38,29.23c-.13.5-.23,1.01-.46,1.48-.62,1.3-1.52,2.31-2.94,2.73-1.63.48-3.23-.32-3.82-1.91-.69-1.87-.23-3.57.93-5.11.63-.83,1.46-1.4,2.5-1.59,1.58-.29,2.98.54,3.54,2.08.1.28.16.57.24.85v1.46Z"
                                    />
                                    <path
                                        fill={`${colorIcono}`}
                                        d="m31.04,47.71c-1.5,0-3-.03-4.5,0-2.4.07-4.45-1.49-5.33-3.57-.49-1.18-.58-2.39-.46-3.65.23-2.33,1.03-4.43,2.51-6.25,1.78-2.19,4.07-3.53,6.9-3.8,2.67-.25,5.07.52,7.13,2.24,2.44,2.03,3.72,4.68,4.05,7.81.15,1.46.02,2.87-.71,4.16-1.1,1.95-2.74,3.05-5.04,3.05-1.52,0-3.04,0-4.56,0Z"
                                    />
                                    <path
                                        fill={`${colorIcono}`}
                                        d="m40.4,21.47c-.02,1.18-.4,2.53-1.44,3.61-1.62,1.69-3.94,1.59-5.44-.22-1.76-2.12-1.63-5.76.28-7.66,1.6-1.59,3.94-1.47,5.36.29.87,1.08,1.24,2.32,1.24,3.98Z"
                                    />
                                    <path
                                        fill={`${colorIcono}`}
                                        d="m21.72,21.26c0-1.33.32-2.48,1.04-3.5,1.58-2.21,4.37-2.23,5.99-.06,1.5,2.01,1.44,5.24-.15,7.16-1.64,1.98-4.3,1.9-5.82-.19-.75-1.04-1.08-2.22-1.07-3.41Z"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                    <div
                        className="text-xs text-center md:text-base"
                        style={style}>
                        {mascotas} Mascotas
                    </div>
                </div>
            ) : null}
            {toggleButton && (
                <div
                    onClick={() =>
                        setModalEditCapacidades(!modalEditCapacidades)
                    }
                    title="editar nombre"
                    className="flex flex-col items-center px-2 py-1 my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100">
                    <svg
                        className={`w-6 h-6 2xl:h-8 2xl:w-8 text-[#1C4CBB]  `}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {" "}
                        <path stroke="none" d="M0 0h24v24H0z" />{" "}
                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                        <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                    </svg>
                    <div className="text-xs text-center text-[#1C4CBB]">
                        Editar Capacidades
                    </div>
                </div>
            )}
            {modalEditCapacidades && (
                <ModalEditCapacidades
                    modalVisible={modalEditCapacidades}
                    setModalVisible={setModalEditCapacidades}
                />
            )}
        </div>
    );
};
