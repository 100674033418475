import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";

import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import ReactPlayer from "react-player";

interface Props {
    setModalVisibleContenidoDescripcion: (
        modalVisibleComodidades: boolean
    ) => void;
    modalVisibleContenidoDescripcion: boolean;
}

export const ModalEditContenidoDescripcion = ({
    setModalVisibleContenidoDescripcion,
    modalVisibleContenidoDescripcion,
}: Props) => {
    const {
        tipo_descripcion,
        imagen_descripcion,
        video_descripcion,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);
    const dispatchDominio = useDispatchDominio();

    const [saving, setSaving] = useState<boolean>(false);
    const [tipoDescripcion, setTipoDescripcion] =
        useState<string>(tipo_descripcion);

    const [imagenDescripcion, setImagenDescripcion] = useState<string | File>(
        imagen_descripcion
    );

    const [videoDescripcion, setVideoDescripcion] =
        useState<string>(video_descripcion);

    const handleSubmit = async () => {
        setSaving(true);

        try {
            if (
                typeof imagenDescripcion !== "string" &&
                tipoDescripcion === "Imagen"
            ) {
                const form = new FormData();
                form.append("imagen_descripcion", imagenDescripcion);
                form.append("tipo_descripcion", tipoDescripcion);
                form.append("video_descripcion", videoDescripcion);

                try {
                    const data = await fetchAxiosToken({
                        url: `/casa/putContenidoDescripcion/${casa_id}`,
                        method: "put",
                        body: form,
                    });
                    console.log(data);
                    dispatchDominio();

                    setTimeout(() => {
                        Swal.fire({
                            confirmButtonColor: "#029BFC",
                            iconColor: "#029BFC",
                            title: "Actualizado",
                            text: "Contenido actualizado con exito",
                            icon: "success",
                            confirmButtonText: "Ok",
                        });

                        setSaving(false);
                        setModalVisibleContenidoDescripcion(false);
                    }, 2500);
                } catch (error: any) {
                    console.log(error.response);
                }
            } else {
                const input = {
                    tipo_descripcion: tipoDescripcion,
                    video_descripcion: videoDescripcion,
                };
                try {
                    const data = await fetchAxiosToken({
                        url: `/casa/putContenidoDescripcion/${casa_id}`,
                        method: "put",
                        body: input,
                    });
                    console.log(data);
                    dispatchDominio();

                    setTimeout(() => {
                        Swal.fire({
                            confirmButtonColor: "#029BFC",
                            iconColor: "#029BFC",
                            title: "Actualizado",
                            text: "Contenido actualizado con exito",
                            icon: "success",
                            confirmButtonText: "Ok",
                        });

                        setSaving(false);
                        setModalVisibleContenidoDescripcion(false);
                    }, 2500);
                } catch (error: any) {
                    console.log(error.response);
                }
            }
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const handleChangeImagenDescripcion = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.files && e.target.files.length > 0) {
            setImagenDescripcion(e.target.files[0]);
        }
    };

    const handleChangeVideoDescripcion = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        console.log(e.target.value);
        setVideoDescripcion(e.target.value);
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleContenidoDescripcion}
            setModalVisible={setModalVisibleContenidoDescripcion}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-base font-medium">Descripción</h2>
                <hr className="w-full bg-gray-300" />
                <div className="z-30 flex items-center justify-center ">
                    <div
                        className={` ${
                            tipoDescripcion === "Imagen"
                                ? "bg-[#1c4cbb] text-white"
                                : "bg-[#e6e6e6] text-[#999999]"
                        }  py-3 px-8 text-base lg:text-lg 2xl:text-xl font-bold rounded-lg cursor-pointer`}
                        onClick={() => {
                            setTipoDescripcion("Imagen");
                        }}>
                        Imagen
                    </div>
                    <div
                        className={`${
                            tipoDescripcion === "Video"
                                ? "bg-[#1c4cbb] text-white"
                                : "bg-[#e6e6e6] text-[#999999]"
                        }  py-3 px-8 text-base lg:text-lg 2xl:text-xl font-bold rounded-lg cursor-pointer`}
                        onClick={() => {
                            setTipoDescripcion("Video");
                        }}>
                        Video
                    </div>
                </div>

                {tipoDescripcion === "Imagen" && (
                    <div>
                        <div className="w-full mt-3 text-left">
                            <div className="mb-3  text-[#3C3C3C]  text-base">
                                Imagen
                            </div>
                        </div>
                        <div className="relative flex items-center justify-center w-full h-48 mt-1 rounded-lg hover:cursor-pointer">
                            <div className="absolute w-full">
                                <div>
                                    <img
                                        src={
                                            typeof imagenDescripcion ===
                                            "string"
                                                ? `${process.env.REACT_APP_PROXY}/casa/getImage/${imagenDescripcion}`
                                                : URL.createObjectURL(
                                                      imagenDescripcion
                                                  )
                                        }
                                        className="object-cover w-full mx-auto border rounded-lg h-52"
                                        alt="Imagen Descripcion"
                                    />
                                </div>
                            </div>
                            <input
                                accept="image/*"
                                type="file"
                                name="icono_si"
                                className="w-full rounded-lg opacity-0 cursor-pointer h-52 "
                                onChange={handleChangeImagenDescripcion}
                            />
                        </div>
                    </div>
                )}

                {tipoDescripcion === "Video" && (
                    <div>
                        <div className="w-full mt-3 text-left">
                            <div className="mb-3  text-[#3C3C3C]  text-base">
                                Url video
                            </div>
                        </div>
                        <div className="relative flex items-center justify-center w-full mt-1 mb-4 rounded-lg hover:cursor-pointer">
                            <div className="w-full">
                                <div>
                                    <input
                                        type="text"
                                        name="video_descripcion"
                                        className="w-full rounded-lg border-[#1C4CBB] border-2 px-1 outline-none"
                                        value={videoDescripcion}
                                        onChange={handleChangeVideoDescripcion}
                                    />
                                </div>
                            </div>
                        </div>
                        {tipoDescripcion === "Video" &&
                            videoDescripcion !== "" && (
                                <ReactPlayer
                                    url={videoDescripcion}
                                    width="100%"
                                    height="10rem"
                                    controls
                                    playing
                                    muted
                                    className=""
                                />
                            )}
                    </div>
                )}

                <div className="flex flex-col gap-4 mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                            Guardar cambios
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
