import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
import { ModalEditRedesSociales } from "./ModalEditRedesSociales";

const TITLES = [
    {
        id: 1,
        title: "Inicio",
        url: "inicio",
    },

    {
        id: 2,
        title: "Funcionalidades",
        url: "funcionalidades",
    },
    {
        id: 3,
        title: "Planes",
        url: "planes",
    },
    {
        id: 4,
        title: "Testimonios",
        url: "testimonios",
    },
    {
        id: 5,
        title: "Nosotros",
        url: "nosotros",
    },
    {
        id: 6,
        title: "Contáctanos",
        url: "contactanos",
    },
    {
        id: 7,
        title: "Simulador",
        url: "calculadora",
    },
];

interface Pros {
    calculadora?: string;
}

export const FooterLanding = ({ toggleButton }: { toggleButton: boolean }) => {
    const [titleSelected, setTitleSelected] = useState<number>(0);
    const { nombre, facebook, instagram, tiktok } = useAppSelector(
        (state) => state.landing_house.house
    );
    const [modalVisible, setModalVisible] = useState(false);
    const { view } = useParams();
    const navigate = useNavigate();
    const [selectedCurrency, setSelectedCurrency] = useState<string>("");

    const handleSmoothScroll = (title: string) => {
        navigate(`/home/${title}`);
    };

    const handleChangeCurrency = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const selectedValue = event.target.value;
        setSelectedCurrency(selectedValue);
        localStorage.setItem("currency", selectedValue);
    };

    return (
        <div className={`flex flex-col  text-white  bg-[#1C4CBB] `}>
            {modalVisible && (
                <ModalEditRedesSociales
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                />
            )}
            {/* <a
                className="z-50"
                href={`https://wa.me/51950707924?text=`}
                target="_blank">
                <div className="fixed select-none bottom-5 right-5 xs:right-10 bg-[#00BB22] shadow-lg rounded-full p-3 flex justify-center items-center hover:scale-110 transition animate-bounce z-30">
                    <svg
                        className="w-12 h-12 "
                        id="Capa_2"
                        data-name="Capa 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 99.76 100">
                        <g id="Testimonios">
                            <g>
                                <path
                                    fill="white"
                                    d="m0,100l7.17-26.75c-4.06-7.32-6.2-15.53-6.2-23.86C.97,22.16,23.13,0,50.36,0s49.39,22.16,49.39,49.39-22.16,49.39-49.39,49.39c-8.18,0-16.25-2.06-23.48-5.98L0,100Zm28.22-17.23l1.69,1.01c6.22,3.7,13.29,5.66,20.45,5.66,22.09,0,40.06-17.97,40.06-40.06S72.45,9.34,50.36,9.34,10.31,27.3,10.31,49.39c0,7.29,2.03,14.47,5.86,20.77l1.04,1.71-4,14.93,15.02-4.02Z"
                                />
                                <path
                                    fill="white"
                                    d="m68.17,56.26c-2.03-1.22-4.68-2.57-7.07-1.59-1.84.75-3.01,3.63-4.2,5.1-.61.75-1.34.87-2.28.49-6.9-2.75-12.18-7.35-15.99-13.7-.64-.99-.53-1.76.25-2.68,1.15-1.35,2.59-2.89,2.9-4.72.31-1.82-.54-3.96-1.3-5.58-.96-2.08-2.04-5.04-4.12-6.21-1.91-1.08-4.43-.47-6.13.91-2.94,2.39-4.36,6.14-4.31,9.86.01,1.06.14,2.11.39,3.13.59,2.45,1.73,4.74,3,6.92.96,1.64,2.01,3.23,3.13,4.77,3.69,5.01,8.28,9.37,13.59,12.62,2.66,1.63,5.52,3.05,8.48,4.03,3.32,1.1,6.28,2.24,9.87,1.56,3.75-.71,7.46-3.04,8.95-6.66.44-1.07.66-2.27.42-3.4-.51-2.34-3.68-3.73-5.57-4.86Z"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
            </a> */}

            <div className="flex flex-col justify-center w-full gap-6 py-8 text-white border-t-2 border-white md723:gap-1 md723:flex-row md723:justify-evenly">
                <div className="flex items-center justify-center text-center ">
                    <p className="text-sm font-medium text-center uppercase">
                        <span className="pr-2">© 2024 </span> -{" "}
                        <span className="pl-2"> {nombre}</span>
                    </p>
                </div>

                {facebook?.length ||
                instagram?.length ||
                tiktok?.length ||
                toggleButton ? (
                    <div className="">
                        <div className="flex justify-center ">
                            <p className="mb-2 font-medium text-center ">
                                SÍGUENOS
                            </p>

                            {toggleButton && (
                                <div
                                    onClick={() => setModalVisible(true)}
                                    className="h-[2.5rem]">
                                    <div className="flex justify-start ">
                                        <div
                                            title="editar direccion"
                                            className="flex flex-col items-center px-2 py-1 ml-6 -mt-5 rounded-lg cursor-pointer opacity-90 bg-slate-200 hover:opacity-100">
                                            <svg
                                                className="w-6 h-6 text-[#1C4CBB]"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                <line
                                                    x1="13.5"
                                                    y1="6.5"
                                                    x2="17.5"
                                                    y2="10.5"
                                                />
                                            </svg>
                                            <div className="text-xs text-[#1C4CBB] text-center">
                                                Editar Redes
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex justify-center lg:justify-start ">
                            {instagram?.length ? (
                                <a
                                    aria-label="icono instagram"
                                    href={instagram}
                                    target="_blank">
                                    <svg
                                        className="mx-2 text-white transition ease-in cursor-pointer w-9 h-9 hover:scale-110"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <rect
                                            x="2"
                                            y="2"
                                            width="20"
                                            height="20"
                                            rx="5"
                                            ry="5"
                                        />{" "}
                                        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />{" "}
                                        <line
                                            x1="17.5"
                                            y1="6.5"
                                            x2="17.51"
                                            y2="6.5"
                                        />
                                    </svg>
                                </a>
                            ) : null}
                            {facebook?.length ? (
                                <a
                                    aria-label="icono facebook"
                                    href={facebook}
                                    target="_blank">
                                    <svg
                                        className="w-8 h-8 mx-2 text-white transition ease-in cursor-pointer hover:scale-110"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                                    </svg>
                                </a>
                            ) : null}
                            {tiktok?.length ? (
                                <a
                                    aria-label="icono tiktok"
                                    href={tiktok}
                                    target="_blank">
                                    <svg
                                        className="w-8 h-8 mx-2 text-white transition ease-in cursor-pointer hover:scale-110"
                                        fill="white"
                                        width="800px"
                                        height="800px"
                                        viewBox="0 0 512 512"
                                        id="icons"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" />
                                    </svg>
                                </a>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
