import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    dates_selected: {
        initial_date: new Date(),
        final_date: new Date(),
        allDates: [],
    },
    precio_personas_adicionales: 0,
    precio_alquiler: 0,
    garantia: 0,
    tipo_garantia:""
    ,
    precio_servicios_adicionales: 0,
    servicios_adicionales: [
        { id: 0, nombre: "", precio_final: 0, opcional: "" },
    ],
    descuento_estadia: null,

    error_message: "",
};

export const precio_reserva = createSlice({
    name: "precio_reserva",
    initialState: INITIAL_STATE,
    reducers: {
        set_dates_selected: (state, action) => {
            return {
                ...state,
                dates_selected: action.payload,
            };
        },
        set_precio_alquiler: (state, action) => {
            return {
                ...state,
                precio_alquiler: action.payload,
            };
        },
        set_garantia: (state, action) => {
            return {
                ...state,
                garantia: action.payload,
            };
        },
        set_precio_servicios_adicionales: (state, action) => {
            return {
                ...state,
                precio_servicios_adicionales: action.payload,
            };
        },
        insert_servicios_adicionales: (state, action) => {
            state.servicios_adicionales.push(action.payload);
        },
        remove_servicios_adicionales: (state) => {
            state.servicios_adicionales = [];
        },
        set_descuento_estadia: (state, action) => {
            state.descuento_estadia = action.payload;
        },
        set_precio_personas_adicionales: (state, action) => {
            state.precio_personas_adicionales = action.payload;
        },
        remove_descuento_estadia: (state) => {
            state.descuento_estadia = null;
        },
        set_error_message: (state, action) => {
            state.error_message = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    set_dates_selected,
    set_precio_alquiler,
    set_garantia,
    set_precio_servicios_adicionales,
    insert_servicios_adicionales,
    remove_servicios_adicionales,
    set_descuento_estadia,
    remove_descuento_estadia,
    set_precio_personas_adicionales,
    set_error_message,
} = precio_reserva.actions;

export default precio_reserva.reducer;
