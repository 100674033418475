import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";

const watchDemoVideo = require("../../assets/videos/watchDemoVideo.mp4");

const ThanksContent = () => {
    const { tags } = useParams();
    const navigate = useNavigate();
    const { currency } = useAppSelector((state) => state.informacion);
    const [selectedCurrency, setSelectedCurrency] = useState<string>("");

    useEffect(() => {
        setSelectedCurrency(currency);
    }, [currency]);
    return (
        <div className="flex flex-col items-center justify-center  w-full md:px-5 md:pt-4 text-white md:flex bg-[#1C4CBB] pb-10">
            <div className="z-30 flex flex-col items-start justify-between w-5/6 gap-8 mx-auto xl:w-4/5 2xl:w-3/4 lg:flex-row">
                <div className="w-auto h-[30rem]  mt-2 lg:mt-5 lg:px-6 rounded-xl mx-auto cursor-pointer">
                    <video
                        loop
                        className="w-auto h-full mx-auto rounded-xl"
                        src={watchDemoVideo}
                        controls
                        controlsList="nodownload"></video>
                </div>
            </div>
            <div className="relative mt-10 z-20 w-full xl:w-4/5 2xl:w-3/4  bg-[#029BFC] rounded-xl">
                <div className="flex flex-col items-center justify-center py-10">
                    <div className="text-2xl font-semibold text-center text-white xs:w-1/2 md:text-3xl 2xl:text-4xl">
                        Blustay
                    </div>
                    <div className="text-sm text-center text-white xs:w-1/2 md:text-base 2xl:text-lg">
                        Reservas fáciles, ganancias aseguradas
                    </div>

                    <div className="mt-5 text-lg text-center text-white xs:w-2/3 md:text-xl 2xl:text-2xl">
                        Escoge tu plan ahora mismo y lleva tu propiedad a otro
                        nivel
                    </div>
                    <div className="flex items-center justify-center gap-8 mt-5">
                        <div
                            onClick={() => {
                                navigate(`/planes/`);
                            }}
                            className="bg-[#5aad00] rounded-2xl p-3 text-white text-lg mt-5 cursor-pointer hover:scale-105">
                            Ver planes
                        </div>

                        <div
                            onClick={() => {
                                window.open(
                                    `${
                                        selectedCurrency === "EUR"
                                            ? "https://calendly.com/alessandra-2hu7/30min"
                                            : "https://calendly.com/katherine_escobar"
                                    }`,
                                    "_blank"
                                );
                            }}
                            className="bg-[#1C4CBB] rounded-2xl p-3 text-white text-lg mt-5 cursor-pointer hover:scale-105">
                            Agenda una reunión
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThanksContent;
