import React, { useState, useEffect } from "react";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import Swal from "sweetalert2";
import ToggleButton from "../../components/helpers/ToggleButton";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { useParams } from "react-router-dom";

interface Actividad {
    descripcion: string;
    actividad: string;
    id: number;
    id_casa: number;
    imagen: string;
}

interface Events {
    actividad: string;
    descripcion: string;
    actividad_image: File | string;
}
interface Props {
    modalVisibleEvents: boolean;
    setModalVisibleEvents: (s: boolean) => void;
}

interface EditEvents {
    id: number;
    edit: boolean;
    actividad: string;
    descripcion: string;
    actividad_image: File | string;
}

export const ModalEditEvents = ({
    setModalVisibleEvents,
    modalVisibleEvents,
}: Props) => {
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );

    const { casa_actividades, id: casa_id } = useAppSelector(
        (state) => state.landing_house.house
    );

    const [toggleButton, setToggleButton] = useState(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [editEvents, setEditEvents] = useState<EditEvents>({
        id: -1,
        edit: false,
        actividad: "",
        descripcion: "",
        actividad_image: "",
    });

    const [actividad, setActividad] = useState<Events>({
        descripcion: "",
        actividad: "",
        actividad_image: "",
    });

    const handleChangeImage = (
        e: React.ChangeEvent<HTMLInputElement>,
        tipo: string
    ) => {
        if (e.target.files && e.target.files.length > 0) {
            if (tipo === "crear") {
                setActividad({
                    ...actividad,
                    actividad_image: e.target.files[0],
                });
            } else {
                setEditEvents({
                    ...editEvents,
                    actividad_image: e.target.files[0],
                });
            }
        }
    };

    const postEvents = async () => {
        if (actividad.descripcion === "" || actividad.actividad === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Para crear una actividad debe tener nombre y descripcion",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        } else if (actividad.actividad_image === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Para crear una actividad debe tener una imagen",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }
        setLoading(true);
        const form = new FormData();

        form.append("id_casa", `${casa_id}`);
        form.append("actividad", actividad.actividad);
        form.append("descripcion", actividad.descripcion);
        form.append("actividad_image", actividad.actividad_image);

        try {
            const data = await fetchAxiosToken({
                url: `/casa_actividades`,
                method: "post",
                body: form,
            });
            dispatchDominio();

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Creada",
                text: "Actividad creada con exito",
                icon: "success",
                confirmButtonText: "Ok",
            });

            setActividad({
                descripcion: "",
                actividad: "",
                actividad_image: "",
            });

            setLoading(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const putEvents = async () => {
        if (editEvents.descripcion === "" || editEvents.actividad === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Para actualizar una actividad debe tener nombre y descripcion",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }
        setSaving(true);
        const form = new FormData();

        form.append("actividad", editEvents.actividad);
        form.append("descripcion", editEvents.descripcion);
        form.append("actividad_image", editEvents.actividad_image);

        console.log(editEvents);
        try {
            const data = await fetchAxiosToken({
                url: `/casa_actividades/update/${editEvents.id}`,
                method: "put",
                body: form,
            });
            dispatchDominio();

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Actualizada",
                text: "Actividad actualizada con exito",
                icon: "success",
                confirmButtonText: "Ok",
            });

            setEditEvents({
                id: -1,
                edit: false,
                actividad: "",
                descripcion: "",
                actividad_image: "",
            });

            setSaving(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const deleteEvents = async (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres eliminar la actividad?",
            icon: "warning",
            showCancelButton: true,

            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, mejor no",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setSaving(true);
                try {
                    const data = await fetchAxiosToken({
                        url: `/casa_actividades/delete/${id}`,
                        method: "delete",
                    });
                    const dataCasa = await fetchAxiosNoToken({
                        url: `/casa/getOne/id/${casa_id}`,
                    });

                    dispatchDominio();

                    setSaving(false);
                } catch (error: any) {
                    console.log(error.response);
                }
            }
        });
    };

    return (
        <div>
            <ResponsiveModalAbsolute
                modalVisible={modalVisibleEvents}
                setModalVisible={setModalVisibleEvents}>
                <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                    <div className="flex justify-end w-full">
                        <p>
                            <span className="text-[#053465] font-medium mr-2 2xl:text-base text-sm">
                                Crear nueva
                            </span>
                        </p>
                        <ToggleButton
                            isToggled={false}
                            onChangeToggle={(e) => setToggleButton(e)}
                        />
                    </div>
                    {toggleButton ? (
                        <div>
                            <h2 className="text-base font-medium text-center">
                                Nueva actividad
                            </h2>
                            <hr className="w-full bg-gray-300" />

                            {!loading ? (
                                <div>
                                    <div className="w-full my-1 text-left">
                                        <header className="mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                                            Nombre de la actividad:
                                        </header>
                                    </div>
                                    <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                                        <input
                                            onChange={(e) =>
                                                setActividad({
                                                    ...actividad,
                                                    actividad: e.target.value,
                                                })
                                            }
                                            type="text"
                                            value={actividad.actividad}
                                            placeholder="Ej: Escalar"
                                            className="w-full my-2 text-sm bg-transparent border-none outline-none 2xl:my-3 2xl:text-base focus:outline-none placeholder:text-gray-400"
                                        />
                                    </div>
                                    <div className="w-full my-1 mt-3 text-left">
                                        <header className="mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                                            Imagen de la actividad:
                                        </header>
                                    </div>
                                    <div className="w-full ">
                                        <div className="mx-auto overflow-hidden bg-white rounded-lg ">
                                            <div className="md:flex">
                                                <div className="w-full">
                                                    <div className="py-3">
                                                        <div className="mb-2">
                                                            <div className="relative flex items-center justify-center 2xl:h-40 h-36 bg-white border border-[#E9E9E9] rounded-lg hover:cursor-pointer ">
                                                                <div className="absolute">
                                                                    <div className="flex flex-col items-center ">
                                                                        <i className="text-gray-200 fa fa-cloud-upload fa-3x"></i>{" "}
                                                                        {actividad.actividad_image ===
                                                                            "" ||
                                                                        actividad.actividad_image ===
                                                                            undefined ? (
                                                                            <div className="text-sm 2xl:text-base">
                                                                                <p className="block font-bold text-[#3C3C3C] text-center">
                                                                                    Arrastra
                                                                                    la
                                                                                    imagen
                                                                                    aquí
                                                                                </p>
                                                                                <p className="block mt-3 font-normal text-center text-blue-blueFrom">
                                                                                    sube
                                                                                    la
                                                                                    imagen
                                                                                    desde
                                                                                    tu
                                                                                    dispositivo
                                                                                </p>
                                                                            </div>
                                                                        ) : (
                                                                            <img
                                                                                src={
                                                                                    typeof actividad.actividad_image ===
                                                                                    "string"
                                                                                        ? ``
                                                                                        : URL.createObjectURL(
                                                                                              actividad.actividad_image
                                                                                          )
                                                                                }
                                                                                className="object-cover w-full h-full"
                                                                                alt={`imagen actividad`}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <input
                                                                    accept="image/*"
                                                                    type="file"
                                                                    className="w-full h-full opacity-0 cursor-pointer"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeImage(
                                                                            e,
                                                                            "crear"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full my-1 mt-3 text-left">
                                        <header className="mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                                            Descripción de la actividad:
                                        </header>
                                    </div>
                                    <div className="w-full p-2 rounded-lg border border-[#E9E9E9]  ">
                                        <textarea
                                            onChange={(e) =>
                                                setActividad({
                                                    ...actividad,
                                                    descripcion: e.target.value,
                                                })
                                            }
                                            value={actividad.descripcion}
                                            placeholder="Ej: a menos de 1 km tienen las montañas donde puedes escalar y pasar un dia en la naturaleza"
                                            className="w-full h-20 text-sm bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400 2xl:text-base"
                                        />
                                    </div>

                                    <div className="flex justify-center my-4">
                                        <button
                                            onClick={() => postEvents()}
                                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 2xl:text-base text-sm">
                                            Crear
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <Spinner />
                            )}
                        </div>
                    ) : (
                        <div>
                            <h2
                                className={
                                    editEvents.edit
                                        ? "hidden"
                                        : "text-base font-medium text-center"
                                }>
                                Lista de eventos
                            </h2>
                            <hr className="w-full bg-gray-300" />

                            <section className="flex items-center justify-center ">
                                <div className="w-full">
                                    <div className="flex flex-col w-full mt-4 ">
                                        {!saving ? (
                                            <div className="w-full ">
                                                <table className="w-full overflow-x-auto ">
                                                    <thead
                                                        className={
                                                            editEvents.edit
                                                                ? "hidden"
                                                                : "text-left 2xl:text-base text-sm"
                                                        }>
                                                        <tr>
                                                            <th className="w-full ">
                                                                Nombre
                                                            </th>

                                                            <th className="table-cell w-full pl-5 text-center ">
                                                                Acciones
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-sm 2xl:text-base">
                                                        {casa_actividades?.map(
                                                            (actividad) => (
                                                                <>
                                                                    {editEvents.id !==
                                                                    actividad.id ? (
                                                                        <tr
                                                                            key={
                                                                                actividad.id
                                                                            }
                                                                            className={
                                                                                editEvents.edit
                                                                                    ? "hidden"
                                                                                    : "w-full border-t "
                                                                            }>
                                                                            <td>
                                                                                <div className="flex items-center py-3 cursor-pointer">
                                                                                    <img
                                                                                        src={
                                                                                            `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_actividades/getImage/` +
                                                                                            actividad?.imagen
                                                                                        }
                                                                                        alt="icono"
                                                                                        className="object-cover w-10 h-10 rounded"
                                                                                    />
                                                                                    <h1 className="ml-3 text-[#3C3C3C]">
                                                                                        {
                                                                                            actividad?.actividad
                                                                                        }
                                                                                    </h1>
                                                                                </div>
                                                                            </td>

                                                                            <td
                                                                                className={
                                                                                    " flex justify-end py-5 pl-5 "
                                                                                }>
                                                                                <div className="flex">
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            setEditEvents(
                                                                                                {
                                                                                                    edit: true,
                                                                                                    id: actividad.id,
                                                                                                    actividad:
                                                                                                        actividad.actividad,
                                                                                                    descripcion:
                                                                                                        actividad.descripcion,
                                                                                                    actividad_image:
                                                                                                        actividad.imagen,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        title="Editar"
                                                                                        className="flex items-center justify-center px-1 py-1 border rounded 2xl:w-8 2xl:h-8 w-7 h-7 text-blue-blueFrom border-blue-blueFrom group bg-blue-blueFrom hover:scale-110">
                                                                                        <svg
                                                                                            id="Capa_2"
                                                                                            data-name="Capa 2"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="0 0 99.97 100"
                                                                                            className="w-4 h-4 2xl:w-5 2xl:h-5"
                                                                                            fill="white">
                                                                                            <g id="Testimonios">
                                                                                                <g>
                                                                                                    <path
                                                                                                        fill="white"
                                                                                                        d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                                                                                                    />
                                                                                                    <path
                                                                                                        fill="white"
                                                                                                        d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                                                                                                    />
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                    </button>
                                                                                    <button
                                                                                        className="flex items-center px-1 py-1 mx-3 border rounded justify-center2xl:w-8 2xl:h-8 w-7 h-7 text-blue-blueFrom border-blue-blueFrom group bg-blue-blueFrom hover:scale-110"
                                                                                        onClick={() =>
                                                                                            deleteEvents(
                                                                                                actividad.id
                                                                                            )
                                                                                        }
                                                                                        title="Eliminar">
                                                                                        <svg
                                                                                            id="Capa_2"
                                                                                            data-name="Capa 2"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="0 0 91.81 100"
                                                                                            className="2xl:w-5 2xl:h-5"
                                                                                            fill="white">
                                                                                            <g id="Testimonios">
                                                                                                <path d="m69.32,100H22.49c-.28-.09-.55-.19-.83-.27-5.22-1.49-8.55-4.86-9.37-10.22-.74-4.81-.65-9.72-.96-14.58-.26-3.99-.41-7.98-.63-11.97-.43-7.56-.88-15.11-1.32-22.67-.27-4.74-.54-9.47-.78-14.21-.04-.73-.35-.96-1.03-.94-1.2.03-2.41.04-3.61,0-2.56-.11-4.44-2.66-3.83-5.15.52-2.1,2.14-3.35,4.44-3.36,5.04-.01,10.08-.02,15.12,0,.89,0,1.24-.28,1.19-1.18-.06-1-.02-2.02-.01-3.02C20.88,5.58,26.42.03,33.28.02c8.39-.01,16.78-.04,25.17,0,5.22.03,8.92,2.59,11.31,7.15,1.24,2.37,1.24,4.94,1.2,7.52-.04,1.92,0,1.92,1.88,1.92,4.88,0,9.76-.02,14.63.01,2.49.02,4.38,1.96,4.34,4.34-.04,2.37-1.91,4.15-4.38,4.17-.81,0-1.63,0-2.44,0-1.82,0-1.75-.08-1.8,1.65-.02.49-.11.97-.13,1.46-.33,5.84-.65,11.68-.97,17.52-.39,7.1-.78,14.21-1.17,21.31-.39,7.04-.78,14.08-1.17,21.12-.2,3.61-1.72,6.55-4.42,8.92-1.73,1.52-3.81,2.33-6,2.88ZM45.9,16.62c5.13,0,10.27-.01,15.4.01.84,0,1.18-.27,1.14-1.13-.05-1,0-2.02-.02-3.02-.07-2.18-1.75-3.91-3.93-3.92-8.38-.03-16.77-.03-25.15,0-2.19,0-3.87,1.73-3.94,3.91-.03.94-.01,1.88,0,2.83,0,1.29.03,1.32,1.31,1.32,5.07,0,10.14,0,15.21,0Zm4.04,41.72c0-4,0-8,0-12,0-.45,0-.92-.11-1.35-.49-2.09-2.32-3.32-4.55-3.07-1.9.21-3.4,1.94-3.41,4.01-.01,8.19-.01,16.39,0,24.58,0,2.58,1.75,4.46,4.07,4.42,2.32-.04,3.99-1.86,4-4.4,0-4.06,0-8.13,0-12.19Zm16.61.07s0,0,0,0c0-4.26.03-8.52-.01-12.78-.02-1.92-1.53-3.52-3.36-3.72-2.16-.23-3.98.93-4.5,2.89-.16.6-.21,1.21-.21,1.83,0,4.62,0,9.23,0,13.85,0,3.35-.03,6.7,0,10.05.03,2.96,2.41,4.91,5.09,4.22,1.77-.45,2.96-1.94,2.97-3.86.03-4.16,0-8.32,0-12.48Zm-33.21.01c0-3.93,0-7.87,0-11.8,0-.62-.06-1.24-.22-1.83-.52-1.93-2.27-3.07-4.41-2.88-1.76.16-3.41,1.68-3.42,3.45-.05,8.68-.06,17.36,0,26.04.02,2.19,2.43,3.87,4.58,3.44,2.19-.44,3.43-2,3.45-4.42.02-4,0-8,0-12Z" />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        <div>
                                                                            {" "}
                                                                            <div>
                                                                                <div className="w-full my-1 text-left">
                                                                                    <header className="mb-3  text-[#3C3C3C] 2xl:text-base text-sm">
                                                                                        Nombre
                                                                                        de
                                                                                        la
                                                                                        actividad:
                                                                                    </header>
                                                                                </div>
                                                                                <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                                                                                    <input
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setEditEvents(
                                                                                                {
                                                                                                    ...editEvents,
                                                                                                    actividad:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        type="text"
                                                                                        value={
                                                                                            editEvents.actividad
                                                                                        }
                                                                                        placeholder="Ej: Actividad principal"
                                                                                        className="w-full my-2 text-sm bg-transparent border-none outline-none 2xl:my-3 2xl:text-base focus:outline-none placeholder:text-gray-400"
                                                                                    />
                                                                                </div>
                                                                                <div className="w-full my-1 mt-3 text-left">
                                                                                    <header className="mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                                                                                        Imagen
                                                                                        de
                                                                                        la
                                                                                        actividad:
                                                                                    </header>
                                                                                </div>
                                                                                <div className="w-full ">
                                                                                    <div className="mx-auto overflow-hidden bg-white rounded-lg ">
                                                                                        <div className="md:flex">
                                                                                            <div className="w-full">
                                                                                                <div className="py-3">
                                                                                                    <div className="mb-2">
                                                                                                        <div className="relative flex items-center justify-center h-40 bg-white border border-[#E9E9E9] rounded-lg hover:cursor-pointer ">
                                                                                                            <div className="absolute">
                                                                                                                <div className="flex flex-col items-center ">
                                                                                                                    <i className="text-gray-200 fa fa-cloud-upload fa-3x"></i>{" "}
                                                                                                                    {editEvents.actividad_image ===
                                                                                                                        "" ||
                                                                                                                    editEvents.actividad_image ===
                                                                                                                        undefined ? (
                                                                                                                        <div className="text-sm 2xl:text-base">
                                                                                                                            <p className="block font-bold text-[#3C3C3C] text-center">
                                                                                                                                Arrastra
                                                                                                                                la
                                                                                                                                imagen
                                                                                                                                aquí
                                                                                                                            </p>
                                                                                                                            <p className="block mt-3 font-normal text-center text-blue-blueFrom">
                                                                                                                                sube
                                                                                                                                la
                                                                                                                                imagen
                                                                                                                                desde
                                                                                                                                tu
                                                                                                                                dispositivo
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    ) : (
                                                                                                                        <img
                                                                                                                            src={
                                                                                                                                typeof editEvents.actividad_image ===
                                                                                                                                "string"
                                                                                                                                    ? `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_actividades/getImage/${editEvents.actividad_image}`
                                                                                                                                    : URL.createObjectURL(
                                                                                                                                          editEvents.actividad_image
                                                                                                                                      )
                                                                                                                            }
                                                                                                                            className="object-cover w-full h-full"
                                                                                                                            alt={`imagen actividad`}
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                accept="image/*"
                                                                                                                type="file"
                                                                                                                className="w-full h-full opacity-0 cursor-pointer"
                                                                                                                onChange={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    handleChangeImage(
                                                                                                                        e,
                                                                                                                        "actualizar"
                                                                                                                    )
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="w-full my-1 mt-3 text-left">
                                                                                    <header className="mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                                                                                        Descripcion
                                                                                        de
                                                                                        la
                                                                                        actividad:
                                                                                    </header>
                                                                                </div>
                                                                                <div className="w-full p-2 rounded-lg border border-[#E9E9E9]  ">
                                                                                    <textarea
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setEditEvents(
                                                                                                {
                                                                                                    ...editEvents,
                                                                                                    descripcion:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        value={
                                                                                            editEvents.descripcion
                                                                                        }
                                                                                        placeholder="Ej: Cuenta con dos camas de 1 plaza y baño"
                                                                                        className="w-full h-20 text-sm bg-transparent border-none outline-none focus:outline-none 2xl:text-base placeholder:text-gray-400"
                                                                                    />
                                                                                </div>

                                                                                <div className="flex justify-center my-4">
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            putEvents()
                                                                                        }
                                                                                        className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 2xl:text-base text-sm">
                                                                                        Actualizar
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <Spinner />
                                        )}
                                    </div>
                                </div>
                            </section>
                        </div>
                    )}
                </div>
            </ResponsiveModalAbsolute>
        </div>
    );
};
