import React from "react";

interface Props {
    servicio_adicional: any;
    dia_inicial: Date;
    dia_final: Date;
}

export const getPriceAditionalServicePerDay = ({
    servicio_adicional,
    dia_inicial,
    dia_final,
}: Props) => {
    let precioTotal = 0;
    let precio_avanzado = 0;

    if (servicio_adicional?.servicios_adicionales_avanzados?.length > 0) {
        let coincidencia_avanzada =
            servicio_adicional.servicios_adicionales_avanzados.find(
                (service_avanzado: any) => {
                    const service_avanzado_fecha_inicio = new Date(
                        service_avanzado.fecha_inicio
                    );
                    const service_avanzado_fecha_final = new Date(
                        service_avanzado.fecha_final
                    );

                    if (
                        (service_avanzado_fecha_inicio <= dia_final &&
                            service_avanzado_fecha_final >= dia_final) ||
                        (service_avanzado_fecha_inicio <= dia_final &&
                            service_avanzado_fecha_final >= dia_final) ||
                        (dia_inicial <= service_avanzado_fecha_inicio &&
                            dia_final >= service_avanzado_fecha_inicio) ||
                        (dia_inicial <= service_avanzado_fecha_final &&
                            dia_final >= service_avanzado_fecha_final)
                    ) {
                        return service_avanzado;
                    }
                }
            );

        if (coincidencia_avanzada) {
            precio_avanzado = coincidencia_avanzada.precio;
        }
    }

    if (precio_avanzado > 0) {
        precioTotal = Number(precio_avanzado);
    } else {
        precioTotal = Number(servicio_adicional.precio);
    }

    return precioTotal;
};
