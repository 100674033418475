import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import ModalSubMenu from "./ModalSubMenu";
import FormSubMenu from "./FormSubMenu";
import { ReactSortable } from "react-sortablejs";

interface SelectedFile {
    img: File | null;
}

interface submenu_bot {
    id: number;
    id_respuestas_bots: number;
    respuesta: string;
}

interface submenu_bots {
    loading: boolean;
    submenu_bots: submenu_bot[];
}

interface Props {
    selectedFile: SelectedFile;
    setSelectedFile: React.Dispatch<React.SetStateAction<SelectedFile>>;
    inputRespuesta: respuesta_bot;
    setInputRespuesta: React.Dispatch<React.SetStateAction<respuesta_bot>>;
    isExistRespuesta: boolean;
    getData: () => Promise<void>;
    submenuBots: submenu_bots;
}

interface respuesta_bot {
    id: number;
    id_submenu_bots: number;
    respuesta: string;
    banner_respuesta: string;
}

const FormRespuesta = ({
    selectedFile,
    setSelectedFile,
    inputRespuesta,
    setInputRespuesta,
    isExistRespuesta,
    getData,
    submenuBots,
}: Props) => {
    const [isModalSubmenu, setIsModalSubMenu] = useState<boolean>(false);
    const [submenBotsState, setSubmenuBotsState] = useState<submenu_bot[]>([]);

    useEffect(() => {
        setSubmenuBotsState(submenuBots.submenu_bots);
    }, [submenuBots.submenu_bots]);

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        setSelectedFile({ img: e.target.files[0] });
        setInputRespuesta({
            ...inputRespuesta,
            banner_respuesta: URL.createObjectURL(e.target.files[0]),
        });

        const cancelButton: HTMLElement | null =
            document.querySelector(".cancel-button");
        if (cancelButton) {
            cancelButton.click();
        }
    };

    const enviarRespuesta = async () => {
        if (inputRespuesta.respuesta === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "warning",
                title: "Debe agregar una respuesta",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }

        try {
            if (!isExistRespuesta) {
                const form = new FormData();
                form.append("respuesta", inputRespuesta.respuesta);
                form.append(
                    "id_submenu_bots",
                    inputRespuesta.id_submenu_bots.toString()
                );
                if (selectedFile.img) {
                    form.append("banner_respuesta", selectedFile.img);
                }

                const data = await fetchAxiosToken({
                    url: `/respuestas_bot/postRespuesta/`,
                    method: "post",
                    body: form,
                });

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    // position: "top-end",
                    icon: "success",
                    title: "Respuesta guardada",
                    text: "",
                    showConfirmButton: true,
                    // timer: 2000,
                });
                setSelectedFile({ img: null });
                getData();
            } else {
                const form = new FormData();
                form.append("respuesta", inputRespuesta.respuesta);

                if (selectedFile.img) {
                    form.append("banner_respuesta", selectedFile.img);
                }
                const data = await fetchAxiosToken({
                    url: `/respuestas_bot/putRespuesta/${inputRespuesta.id}`,
                    method: "put",
                    body: form,
                });

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    // position: "top-end",
                    icon: "success",
                    title: "Respuesta guardada",
                    text: "",
                    showConfirmButton: true,
                    // timer: 2000,
                });
                setSelectedFile({ img: null });
                getData();
            }
        } catch (error: any) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "warning",
                title: "No se pudo guardar",
                text: `${error.response.data.error}`,
                showConfirmButton: true,
                // timer: 2000,
            });
        }
    };

    const hanldeDelete = async () => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quiere limpiar la respuesta?",
            text: "Esta acción no se puede revertir",
            icon: "warning",
            showCancelButton: true,

            cancelButtonColor: "#d33",
            confirmButtonText: "¡Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await fetchAxiosToken({
                        url: `/respuestas_bot/deleteRespuesta/${inputRespuesta.id}`,
                        method: "delete",
                    });

                    if (data.success) {
                        Swal.fire({
                            confirmButtonColor: "#029BFC",
                            iconColor: "#029BFC",
                            // position: "top-end",
                            icon: "success",
                            title: "Respuesta limpiada",
                            confirmButtonText: "Ok",
                        });
                        getData();
                    } else {
                        Swal.fire({
                            confirmButtonColor: "#029BFC",
                            iconColor: "#029BFC",
                            // position: "top-end",
                            icon: "error",
                            text: `${data.message}`,
                            confirmButtonText: "Ok",
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleSort = async (submenuBots: submenu_bot[]) => {
        let array_id_submenu: number[] = [];
        submenuBots.map((submenubot) => {
            array_id_submenu.push(submenubot.id);
        });

        setSubmenuBotsState(submenuBots);

        const data = await fetchAxiosToken({
            url: `/respuestas_bot/putOrden/`,
            body: {
                array_orden: array_id_submenu,
            },
            method: "put",
        });

        if (data.msg === "Actualización exitosa") {
            getData();
        }
    };

    return (
        <div className="flex flex-col justify-center items-start w-full shadow-md border rounded-md p-2">
            {isModalSubmenu && (
                <ModalSubMenu
                    modalVisible={isModalSubmenu}
                    setModalVisible={setIsModalSubMenu}
                    id_respuestas_bots={inputRespuesta.id}
                    getData={getData}
                />
            )}
            <div className="flex items-center justify-center mx-auto mt-3 transition-transform duration-300 ease-in-out border rounded-lg cursor-pointer 2xl:w-96 2xl:h-56 lg:w-96 lg:h-56 w-96 h-56 hover:scale-105">
                <div className="cursor-pointer mt-0 relative flex justify-center w-full h-full border-2 border-dashed border-[#E9E9E9]  rounded-lg hover:cursor-pointer ">
                    {inputRespuesta.banner_respuesta === "" ||
                    inputRespuesta.banner_respuesta === "sinImagen.png" ? (
                        <>
                            <div className="absolute">
                                <div className="flex flex-col w-full mx-auto ">
                                    <div className="relative flex flex-col justify-center items-center top-7">
                                        <svg
                                            className="h-8 w-8 "
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            {" "}
                                            <rect
                                                x="3"
                                                y="3"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                            />{" "}
                                            <circle cx="8.5" cy="8.5" r="1.5" />{" "}
                                            <polyline points="21 15 16 10 5 21" />
                                        </svg>
                                        <span className="block mt-5 text-sm font-semibold text-center ">
                                            Agrega un banner para tu respuesta
                                            (opcional)
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <input
                                accept="image/*"
                                type="file"
                                name="banner"
                                className="w-full h-full opacity-0 cursor-pointer file:cursor-pointer"
                                onChange={(e) => handleChangeImage(e)}
                            />
                        </>
                    ) : selectedFile.img ? (
                        <>
                            <img
                                className=" object-cover w-full h-full rounded-lg"
                                src={inputRespuesta.banner_respuesta}
                                alt="Imagen de banner"
                            />
                            <input
                                accept="image/*"
                                type="file"
                                name="banner"
                                className="absolute w-full h-full opacity-0 cursor-pointer file:cursor-pointer"
                                onChange={(e) => handleChangeImage(e)}
                            />
                        </>
                    ) : (
                        <>
                            <img
                                className=" object-cover w-full h-full rounded-lg "
                                src={`${process.env.REACT_APP_PROXY}/respuestas_bot/getImage/${inputRespuesta.banner_respuesta}`}
                                alt="Imagen de banner"
                            />
                            <input
                                accept="image/*"
                                type="file"
                                name="banner"
                                className="absolute w-full h-full opacity-0 cursor-pointer file:cursor-pointer"
                                onChange={(e) => handleChangeImage(e)}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="flex flex-col gap-2 mx-auto 2xl:mt-4 mt-2 w-96">
                <label className="">
                    Respuesta <span className="text-red-500">*</span>
                </label>
                <textarea
                    className=" p-3 border rounded-lg border-blue-blueFrom"
                    name="Respuesta"
                    value={inputRespuesta.respuesta}
                    rows={7}
                    onChange={(e) =>
                        setInputRespuesta({
                            ...inputRespuesta,
                            respuesta: e.target.value,
                        })
                    }
                    placeholder="Hola bienvenido a mi pagina web!, te atenderemos en unos minutos... por favor, descríbeme que necesitas, para poder atenderte de manera rápida."
                />
            </div>

            <div className="w-96 text-center mx-auto">
                {!isExistRespuesta ? (
                    <button
                        onClick={() => enviarRespuesta()}
                        className="w-full p-3 mt-4 font-medium text-white border rounded-lg bg-blue-blueFrom active:translate-y-1 hover:scale-105"
                    >
                        Guardar
                    </button>
                ) : (
                    <div className="w-full">
                        <button
                            onClick={() => enviarRespuesta()}
                            className="w-3/4 p-3 mt-4 font-medium text-white border rounded-lg bg-blue-blueFrom active:translate-y-1 hover:scale-105"
                        >
                            Guardar
                        </button>

                        <button
                            onClick={() => hanldeDelete()}
                            className="w-1/4 p-3 mt-4 font-medium text-white border rounded-lg bg-red-500 active:translate-y-1 hover:scale-105"
                        >
                            Limpiar
                        </button>
                    </div>
                )}{" "}
            </div>
            <hr className=" border mt-4 w-full border-blue-blueFrom px-5" />
            {isExistRespuesta && (
                <div>
                    <div className="mt-5 flex gap-4 items-center mx-3">
                        <div className="font-semibold text-sm">
                            ¿Deseas agregar opciones de respuestas?
                        </div>
                        <button
                            onClick={() => setIsModalSubMenu(true)}
                            className="p-2 px-3 rounded-md bg-blue-blueFrom hover:scale-105 text-white"
                        >
                            Agregar +
                        </button>
                    </div>
                    <div className="ml-5 w-full mt-2">
                        <ReactSortable
                            id="1"
                            className=" px-2 lg:px-4"
                            list={submenBotsState}
                            setList={handleSort}
                            dragClass="draggable"
                            /*   animation={100} */
                            multiDrag={true}
                        >
                            {submenBotsState.map((submenu) => (
                                <FormSubMenu
                                    key={submenu.id}
                                    submenu={submenu}
                                    getData={getData}
                                />
                            ))}
                        </ReactSortable>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FormRespuesta;
