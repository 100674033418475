import React, { useState, useEffect } from "react";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import dividirFechaDatePicker from "../../../helpers/dividirFechaDatePicker";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";

import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import ToggleButton from "../../../components/helpers/ToggleButton";

interface Input {
    fecha_inicio: string;
    fecha_final: string;
    precio: string;
    motivo: string;
    otro_motivo: string;
    tipo: string;
}

interface VariablePrice {
    id: number;
    id_casa: number;
    fecha: string;
    precio: string;
}
interface Fechas {
    fecha: string;
    motivo: string;
}
export interface Selected {
    price: string;
    date: string;
    fechaNoDisponible: Fechas | false;
}

interface Props {
    setModalVisiblePrice: (modalVisiblePrice: boolean) => void;
    modalVisiblePrice: boolean;
}

export const ModalAlertDesbloqueo = ({
    setModalVisiblePrice,
    modalVisiblePrice,
}: Props) => {
    return (
        <ResponsiveModalAbsolute
            high={50}
            modalVisible={modalVisiblePrice}
            setModalVisible={setModalVisiblePrice}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <div className="relative z-20 flex items-center justify-center">
                    <h2 className="mr-1 text-lg font-medium">
                        Para desbloquear esta fecha vaya a "Días no disponibles"
                    </h2>
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
