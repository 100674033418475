import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Spinner from "../../../components/helpers/Spinner";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { CRMNota } from "./CRMNota";
import { NavbarAdministrativa } from "../NavbarAdministrativa";
import { NavbarAdministrativaMobile } from "../NavbarAdministrativaMobile";
// import { CRMNotaEmpresas } from "./actividades/CRMNotaEmpresas";

export interface CRM {
    id: number;
    id_cliente: number;
    tipo: string;
    etiqueta: string;

    id_chat: number;
    id_pedidos: number;
    proceso: string;
    chat: Chat;
    pedido: Pedido;
    nombre: string;
    apellido: string;
    correo: string;
    celular: string;
}

export interface Chat {
    id: number;
    cliente: string;
    correo: string;
    titulo: string;
    Cliente: Cliente;
}
export interface Pedido {
    id: number;
    id_cliente: number;
    id_casa: number;
    costo_alquiler: string;
    descuento_estadia: string;
    descuento_cupon: string;
    costo_servicios_adicionales: string;
    costo_total: string;
    pagado: string;
    saldo: string;
    habilitado: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    fecha_registro: string;
    Cliente: Cliente;
}

export interface Cliente {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
    correo: string;
    celular: string;
}

export const CRMDetallePropietarios = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [detalle, setDetalle] = useState<CRM>();

    const getDetalle = async (id: string) => {
        const json = await fetchAxiosToken({
            url: `/crm_soporte/getOne/${id}`,
            method: "get",
        });
        console.log(json);
        setDetalle(json);
    };

    useEffect(() => {
        if (id) {
            getDetalle(`${id}`);
        }
    }, []);
    function getColorForTag(tag: string): string {
        switch (tag) {
            case "Instagram":
                return "#E4405F";
            case "Linkedin":
                return "#0077B5";
            case "Facebook":
                return "#1877F2";
            case "Whatsapp":
                return "#25D366";
            case "Email":
                return "#FF0000";
            case "Web":
                return "#FFFF00";
            default:
                return "#FFFF00";
        }
    }
    return (
        <div>
            <NavbarAdministrativa titulo="CRM">
                {/* <div
                onClick={() => navigate(`/dashboard-admin-admin/crm`)}
                className={
                    "flex items-center text-lg cursor-pointer pt-4 px-4 mb-3 "
                }>
                <svg
                    className="w-6 h-6 -ml-2 text-black"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    {" "}
                    <polyline points="15 18 9 12 15 6" />
                </svg>
                <span className="mx-1">Regresar</span>
            </div> */}
                {!detalle ? (
                    <Spinner />
                ) : (
                    <div className="w-full sm:flex">
                        <div className="lg:h-[50vh] sm:h-[60vh] px-1 py-1 sm:w-3/5 border-y sm:mx-2 sm:border-x">
                            <div className="">
                                <p className="pl-2 text-2xl font-semibold sm:text-4xl">
                                    {/* {empresa?.nombre} */}
                                </p>
                                <div className="pl-2 lg:flex">
                                    <div className="w-1/2 gap-6">
                                        <p className="text-lg sm:text-xl text-[053465] mt-1  font-semibold mb-2">
                                            Tipo :{" "}
                                            {detalle?.tipo ?? "Formulario"}
                                        </p>
                                        {detalle.etiqueta && (
                                            <div className="flex items-center gap-1 mb-2">
                                                <p
                                                    style={{
                                                        backgroundColor:
                                                            getColorForTag(
                                                                detalle.etiqueta
                                                            ),
                                                    }}
                                                    className={`w-3 h-3 rounded-full `}></p>
                                                <p className="text-gray-400 ">
                                                    {detalle.etiqueta}
                                                </p>
                                            </div>
                                        )}
                                        <div className="flex flex-col mb-2">
                                            <p className="text-sm font-semibold sm:text-base ">
                                                Nombre:{" "}
                                            </p>
                                            <p className="pt-1 text-sm">
                                                <span className="font-normal">{`${detalle.nombre} ${detalle.apellido}`}</span>
                                                {/* {empresa?.nombre} */}
                                            </p>
                                        </div>
                                        <div className="grid lg:grid-cols-[30%,70%] mb-2">
                                            <p className="text-sm font-semibold sm:text-base ">
                                                Correo:{" "}
                                                <span className="font-normal">
                                                    {detalle.correo}
                                                </span>
                                            </p>
                                            <p className="pt-1 text-sm">
                                                {/* {empresa?.direccion} */}
                                            </p>
                                        </div>
                                        <div className="grid lg:grid-cols-[30%,70%] mb-2">
                                            <p className="text-sm font-semibold sm:text-base ">
                                                Telefono:{" "}
                                                <span className="font-normal">
                                                    {detalle.celular}
                                                </span>
                                            </p>
                                            <p className="pt-1 text-sm"></p>
                                        </div>
                                    </div>
                                    {/* encargado */}
                                    <hr className="my-2 sm:hidden" />
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 sm:w-2/5 sm:mt-0">
                            <CRMNota />
                        </div>
                    </div>
                )}
                <NavbarAdministrativaMobile />
            </NavbarAdministrativa>
        </div>
    );
};
