import axios from "axios";
import React, { useState, useEffect } from "react";
import Spinner from "../../components/helpers/Spinner";
import { useAppSelector } from "../../hooks/hooks";
import useHeaders from "../../hooks/useHeaders";
import { Navbar } from "./Navbar";
import { NavbarMobile } from "./NavbarMobile";

import { Bar } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { Chart, Plugin, ChartOptions } from "chart.js";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from "chart.js";
import "chartjs-plugin-datalabels";

import { FunnelChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";
import BotonInformacion from "../../components/helpers/BotonInformacion";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);
export interface Estadisticas {
    total_pedidos: TotalPedido[];
    total_financiero: TotalFinanciero;
    total_noches: number;
    total_chat: any[];
    promedios_noche: number;
    total_visitas: number;
    visitas_semana: VisitasSemana[];
    ticket_promedio: number;
    embudos: Embudos;
    motivos_perdida: MotivosPerdida;
    total_perdidos: number;
    ratios: Ratios;
}
export interface TotalPedido {
    id: number;
    count_pedidos: number;
    casa: Casa;
}

export interface Casa {
    id: number;
    nombre: string;
    banner: string;
}

export interface TotalFinanciero {
    total_alquiler: number;
    total_servicios_adicionales: number;
    total_reserva: number;
    total_pagado: number;
    total_saldo: number;
}

export interface Embudos {
    total: Total;
    total_negocios: TotalNegocios;
    total_ganados: TotalGanados;
}

export interface Total {
    count: number;
    porcentaje: string;
}

export interface TotalNegocios {
    count: number;
    porcentaje: string;
}

export interface TotalGanados {
    count: number;
    porcentaje: string;
}

export interface VisitasSemana {
    fecha: string;
    visitas: number;
}
export interface MotivosPerdida {
    motivos_sin_concretar: MotivosSinConcretar;
    caracteristicas_propiedad: CaracteristicasPropiedad;
    competencia: Competencia;
    desinteres: Desinteres;
    disponibilidad_fechas: DisponibilidadFechas;
    fuerza_mayor: FuerzaMayor;
    precio: Precio;
    sin_respuesta: SinRespuesta;
    otros: Otros;
}

export interface MotivosSinConcretar {
    count: number;
    porcentaje: string;
}

export interface CaracteristicasPropiedad {
    count: number;
    porcentaje: string;
}

export interface Competencia {
    count: number;
    porcentaje: string;
}

export interface Desinteres {
    count: number;
    porcentaje: string;
}

export interface DisponibilidadFechas {
    count: number;
    porcentaje: string;
}

export interface FuerzaMayor {
    count: number;
    porcentaje: string;
}

export interface Precio {
    count: number;
    porcentaje: string;
}

export interface SinRespuesta {
    count: number;
    porcentaje: string;
}

export interface Otros {
    count: number;
    porcentaje: string;
}
export interface Ratios {
    lead_alquiler: string;
    lead_negocio: string;
    negocio_alquiler: string;
}

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: false,
            text: "",
        },
    },
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
                precision: 0, // Configurar el número de decimales
            },
        },
    },
};

export const Estadisticas = () => {
    const { headers } = useHeaders();
    const { id } = useAppSelector((state) => state.landing_house.house);

    const [estadisticas, setEstadisticas] = useState<Estadisticas>();

    const [cargando, setCargando] = useState<boolean>(false);

    const [mes, setMes] = useState<number>(new Date().getMonth() + 1);
    const [ano, setAno] = useState<number>(new Date().getFullYear());
    const [moneda, setMoneda] = useState("USD");
    const [fechas, setFechas] = useState<string[]>([]);
    const [motivoPerdidas, setMotivoPerdidas] = useState([]);

    //

    const dataNuevo = [
        { name: "Group A", value: 400 },
        { name: "Group B", value: 300 },
        { name: "Group C", value: 300 },
        { name: "Group D", value: 200 },
    ];

    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    //
    useEffect(() => {
        if (estadisticas) {
            const dias: string[] = [];

            for (let i = 0; i < estadisticas.visitas_semana.length; i++) {
                dias.push(estadisticas.visitas_semana[i].fecha);
            }
            setFechas(dias);
        }
    }, [estadisticas]);

    const data = {
        labels: fechas,
        datasets: [
            {
                label: `Visitas por dia`,
                data: estadisticas?.visitas_semana?.map((v) => v.visitas),
                backgroundColor: "rgb(49,224,255)",
            },
        ],
    };

    const razon = [
        "Caracteristicas de la propiedad",
        "Competencia",
        "Desinteres",
        "Disponibilidad fechas",
        "Fuerza mayor",
        "Motivos sin concretar",
        "Otros",
        "Precio",
        "Sin respuesta",
    ];

    const razonClave = [
        "caracteristicas_propiedad",
        "competencia",
        "desinteres",
        "disponibilidad_fechas",
        "fuerza_mayor",
        "motivos_sin_concretar",
        "otros",
        "precio",
        "sin_respuesta",
    ];

    const dataTorta = {
        labels: razon,
        datasets: [
            {
                label: "Razones de pérdidas de reservas",
                data: razonClave.map(
                    (razonItem: string) =>
                        estadisticas?.motivos_perdida?.[
                            razonItem as keyof MotivosPerdida
                        ]?.count ?? 0
                ),
                backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)",
                    "rgba(191, 46, 14, 0.2)",
                    "rgba(14, 161, 191, 0.2)",
                    "rgba(135, 14, 191, 0.2)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                    "rgba(191, 46, 14, 1)",
                    "rgba(14, 161, 191, 1)",
                    "rgba(135, 14, 191, 1)",
                ],
                borderWidth: 1,
            },
        ],

        options: {
            plugins: {
                datalabels: {
                    formatter: (value: any) => {
                        return value + "%";
                    },
                },
            },
        },
    };
    const optionsTorta = {
        plugins: {
            datalabels: {
                formatter: (value: any) => {
                    return value + "%";
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const dataset = context.dataset;
                        const dataIndex = context.dataIndex;
                        const data = dataset.data[dataIndex];
                        const total = dataset.data.reduce(
                            (previousValue: number, currentValue: number) =>
                                previousValue + currentValue
                        );
                        const percentage = (data / total) * 100;
                        return `${dataset.label}: ${data} (${percentage}%)`;
                    },
                    datalabels: {
                        formatter: (value: any) => {
                            return value + "%";
                        },
                    },
                },
            },
        },
    };

    const plugins = [
        {
            beforeDraw: function (chart: any) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();
                var fontSize = (height / 160).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                var text = "Foo-bar",
                    textX = Math.round(
                        (width - ctx.measureText(text).width) / 2
                    ),
                    textY = height / 2;
                ctx.fillText(text, textX, textY);
                ctx.save();
            },
        },
    ];

    const months = [
        { name: "Enero", value: 1 },
        { name: "Febrero", value: 2 },
        { name: "Marzo", value: 3 },
        { name: "Abril", value: 4 },
        { name: "Mayo", value: 5 },
        { name: "Junio", value: 6 },
        { name: "Julio", value: 7 },
        { name: "Agosto", value: 8 },
        { name: "Septiembre", value: 9 },
        { name: "Octubre", value: 10 },
        { name: "Noviembre", value: 11 },
        { name: "Diciembre", value: 12 },
    ];

    const getStadistica = async () => {
        try {
            setCargando(false);
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/pedidos/getAllEstadisticas/`,
                {
                    month: mes,
                    year: ano,
                    id_casa: id,
                    tipo_moneda: moneda,
                },
                { headers }
            );
            data.visitas_semana.pop();

            console.log(data);
            setEstadisticas(data);
            // setTotal(data.total)
            setCargando(true);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (id) {
            getStadistica();
        }
    }, [mes, ano, moneda, id]);

    return (
        <div>
            <Navbar titulo="Estadisticas">
                <div className="absolute right-0 w-full p-2 mx-auto mt-10 text-center xs:p-10 2xl:top-30 top-10 h-350 md:w-10/12">
                    {/* <div className="flex items-center justify-between w-full px-6 mx-auto md:w-10/12">
                        <h1 className="2xl:text-4xl text-2xl py-4 text-center text-[#3C3C3C] ">
                            Estadisticas
                        </h1>
                    </div> */}
                    <div className="flex items-center justify-end pr-6 mx-auto mb-6 sm:px-6 md:w-10/12">
                        <div className="relative ml-2">
                            <select
                                onChange={(e) => setMes(Number(e.target.value))}
                                name={"ano"}
                                value={mes}
                                className="w-full p-2 py-[9px] mt-1 border rounded-xl"
                            >
                                {months.map((mes) => {
                                    return (
                                        <option
                                            key={mes.name}
                                            className="bg-transparent"
                                            value={mes.value}
                                        >
                                            {" "}
                                            {mes.name}{" "}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="relative mx-2">
                            <select
                                onChange={(e) => setAno(Number(e.target.value))}
                                name={"ano"}
                                value={ano}
                                className="w-full p-2 py-[9px] mt-1 border rounded-xl"
                            >
                                <option className="bg-transparent" value="2023">
                                    {" "}
                                    2023{" "}
                                </option>
                                <option className="bg-transparent" value="2024">
                                    {" "}
                                    2024{" "}
                                </option>
                                <option className="bg-transparent" value="2025">
                                    {" "}
                                    2025{" "}
                                </option>
                            </select>
                        </div>
                        <div className="relative mr-2">
                            <select
                                onChange={(e) => setMoneda(e.target.value)}
                                name={"moneda"}
                                value={moneda}
                                className="w-full p-2 py-[9px] mt-1 border rounded-xl"
                            >
                                <option className="bg-transparent" value="USD">
                                    {" "}
                                    USD{" "}
                                </option>
                                <option className="bg-transparent" value="PEN">
                                    {" "}
                                    PEN{" "}
                                </option>

                                <option className="bg-transparent" value="EUR">
                                    {" "}
                                    EUR{" "}
                                </option>
                            </select>
                        </div>
                    </div>
                    {!cargando ? (
                        <div>
                            <Spinner />
                        </div>
                    ) : (
                        <div className="h-full mx-auto bg-blue-100 border-4 border-blue-400 rounded-lg md:w-10/12">
                            <div className="flex flex-col mb-3">
                                <div className="grid w-full grid-cols-2 gap-2 p-2 text-sm text-center xs:text-base md:text-lg 2xl:text-xl">
                                    <div className="flex items-center justify-between w-full h-24 mt-5 border-4 rounded-lg bg-cyan-400 border-cyan-600 2xl:h-28">
                                        <div className="relative z-20 flex items-center justify-center">
                                            <p className="ml-2 font-serif text-white">
                                                Total visitas:
                                            </p>
                                            <BotonInformacion text="Estas son el total de visitas a tu pagina que tuviste este mes!" />
                                        </div>
                                        <div>
                                            <p className="ml-2 font-serif text-white">
                                                {estadisticas?.total_visitas}
                                            </p>
                                        </div>

                                        <div>
                                            <svg
                                                className="w-8 h-8 text-white md:w-12 md:h-12 2xl:w-16 2xl:h-16"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between w-full h-24 mt-5 bg-indigo-400 border-4 border-indigo-600 rounded-lg 2xl:h-28">
                                        <div className="relative z-20 flex items-center justify-center">
                                            <p className="ml-2 font-serif text-white">
                                                Total chats:
                                            </p>
                                            <BotonInformacion text="Estas son el total de chats que tuviste de clientes a tu pagina!" />
                                        </div>
                                        <div>
                                            <p className="ml-2 font-serif text-white">
                                                {
                                                    estadisticas?.total_chat
                                                        .length
                                                }
                                            </p>
                                        </div>

                                        <div>
                                            <svg
                                                className="w-8 h-8 text-white md:w-12 md:h-12 2xl:w-16 2xl:h-16"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between w-full h-24 mt-5 bg-teal-400 border-4 border-teal-600 rounded-lg 2xl:h-28">
                                        <div className="relative z-20 flex items-center justify-center">
                                            <p className="ml-2 font-serif text-white ">
                                                Total reservas:
                                            </p>
                                            <BotonInformacion text="Estas son el total de reservas que tuviste este mes!" />
                                        </div>
                                        <div>
                                            <p className="ml-2 font-serif text-white">
                                                {
                                                    estadisticas?.total_pedidos
                                                        .length
                                                }
                                            </p>
                                        </div>
                                        <div>
                                            <svg
                                                className="w-8 h-8 text-white md:w-12 md:h-12 2xl:w-16 2xl:h-16"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <polyline points="5 12 3 12 12 3 21 12 19 12" />{" "}
                                                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />{" "}
                                                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between w-full h-24 mt-5 bg-yellow-500 border-4 border-yellow-600 rounded-lg 2xl:h-28">
                                        <div className="relative z-20 flex items-center justify-center">
                                            <p className="ml-2 font-serif text-white">
                                                Total ganado:
                                            </p>
                                            <BotonInformacion text="Estas son el total recaudado (Cobrado) que tuviste de todas las reservas de este mes!" />
                                        </div>
                                        <div>
                                            <p className="ml-2 font-serif text-white">
                                                {
                                                    estadisticas
                                                        ?.total_financiero
                                                        .total_pagado
                                                }{" "}
                                                {moneda}
                                            </p>
                                        </div>

                                        <div>
                                            <svg
                                                className="w-8 h-8 text-white md:w-12 md:h-12 2xl:w-16 2xl:h-16"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between w-full h-24 mt-5 bg-yellow-500 border-4 border-yellow-600 rounded-lg 2xl:h-28">
                                        <div className="relative z-20 flex items-center justify-center">
                                            <p className="ml-2 font-serif text-white">
                                                Por cobrar:
                                            </p>
                                            <BotonInformacion text="Esto es el monto que te falta cobrar si es que hay clientes que pagaron el 50% de la reserva" />
                                        </div>
                                        <div>
                                            <p className="ml-2 font-serif text-white">
                                                {
                                                    estadisticas
                                                        ?.total_financiero
                                                        .total_saldo
                                                }{" "}
                                                {moneda}
                                            </p>
                                        </div>

                                        <div>
                                            <svg
                                                className="w-8 h-8 text-white md:w-14 md:h-14 2xl:w-16 2xl:h-16"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between w-full h-24 mt-5 bg-yellow-500 border-4 border-yellow-600 rounded-lg 2xl:h-28">
                                        <div className="relative z-20 flex items-center justify-center">
                                            <p className="ml-2 font-serif text-white">
                                                Ticket promedio:
                                            </p>
                                            <BotonInformacion text="El promedio que generas de dinero por cada reserva" />
                                        </div>
                                        <div>
                                            <p className="ml-2 font-serif text-white">
                                                {estadisticas?.ticket_promedio}{" "}
                                                {moneda}
                                            </p>
                                        </div>

                                        <div>
                                            <svg
                                                className="w-8 h-8 text-white md:w-12 md:h-12 2xl:w-16 2xl:h-16"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between w-full h-24 mt-5 bg-blue-400 border-4 border-blue-600 rounded-lg 2xl:h-28">
                                        <div className="relative z-20 flex items-center justify-center">
                                            <p className="ml-2 font-serif text-white">
                                                Total noches:
                                            </p>
                                            <BotonInformacion text="Este es el total de noches reservadas en este mes!" />
                                        </div>
                                        <div>
                                            <p className="ml-2 font-serif text-white">
                                                {estadisticas?.total_noches}
                                            </p>
                                        </div>

                                        <div>
                                            <svg
                                                className="w-8 h-8 text-white md:w-12 md:h-12 2xl:w-16 2xl:h-16"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                {" "}
                                                <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between w-full h-24 mt-5 bg-blue-400 border-4 border-blue-600 rounded-lg 2xl:h-28">
                                        <div className="relative z-20 flex items-center justify-center">
                                            <p className="ml-2 font-serif text-white">
                                                Promedio noches:
                                            </p>
                                            <BotonInformacion text="Este es el promedio de noches por cada reserva en este mes" />
                                        </div>
                                        <div>
                                            <p className="ml-2 font-serif text-white">
                                                {estadisticas?.promedios_noche}
                                            </p>
                                        </div>

                                        <div>
                                            <svg
                                                className="w-8 h-8 text-white md:w-12 md:h-12 2xl:w-16 2xl:h-16"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                {" "}
                                                <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full p-2 text-center ">
                                    <div className="relative z-20 flex items-center justify-center mx-auto w-36">
                                        <BotonInformacion text="Estas son las visitas que tuviste en cada fecha del Mes escogido" />
                                    </div>
                                    <Bar options={options} data={data} />
                                </div>

                                <div className="w-full mx-auto mt-4 xs:w-11/12">
                                    <div className="relative z-20 flex items-center justify-center w-48 mx-auto">
                                        <p className="font-serif text-base font-bold 2xl:text-lg">
                                            Embudo
                                        </p>

                                        <BotonInformacion
                                            text="1. Total leads: Este es el total de Leads que llegaron este mes.
     2. Total Negociacion:	De todos los leads que llegaron este mes, este porcentaje llegaron a negociacion.
3. Total Ganados:	De todos los leads que llgaron este mes, este porcentaje llegaron a convertirse en reservas."
                                        />
                                    </div>
                                    <FunnelChart
                                        data={[
                                            {
                                                name: `Total de lead`,
                                                value: `${estadisticas?.embudos.total.count}`,
                                            },
                                            {
                                                name: `Negociacion (${estadisticas?.embudos.total_negocios.porcentaje}%)`,
                                                value: estadisticas?.embudos
                                                    .total_negocios.count,
                                            },
                                            {
                                                name: `Ganados (${estadisticas?.embudos.total_ganados.porcentaje}%)`,
                                                value: estadisticas?.embudos
                                                    .total_ganados.count,
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="p-2 mt-3">
                                    <p className="font-serif text-base font-bold 2xl:text-lg">
                                        Ratios de lead
                                    </p>
                                    <div className="flex items-center justify-around w-full h-24 mt-5 bg-red-400 border-4 border-red-600 rounded-lg 2xl:h-28">
                                        <div>
                                            {" "}
                                            <div className="relative z-20 flex items-center justify-center">
                                                <p className="ml-2 mr-1 font-serif text-white">
                                                    Lead-Alquiler
                                                </p>
                                                <BotonInformacion text="De todos los leads de este mes estos llegaron a ser alquiler!" />
                                            </div>{" "}
                                            <p className="ml-2 font-serif text-white">
                                                {
                                                    estadisticas?.ratios
                                                        .lead_alquiler
                                                }
                                                %
                                            </p>
                                        </div>
                                        <div>
                                            {" "}
                                            <div className="relative z-20 flex items-center justify-center">
                                                <p className="ml-2 mr-1 font-serif text-white">
                                                    Lead-Negocio
                                                </p>
                                                <BotonInformacion text="De todos los leads de este mes estos llegaron a ser negocio!" />
                                            </div>{" "}
                                            <p className="ml-2 font-serif text-white">
                                                {
                                                    estadisticas?.ratios
                                                        .lead_negocio
                                                }
                                                %
                                            </p>
                                        </div>

                                        <div>
                                            {" "}
                                            <div className="relative z-20 flex items-center justify-center">
                                                <p className="ml-2 mr-1 font-serif text-white">
                                                    Negocio - Alquiler
                                                </p>
                                                <BotonInformacion text="Este es el promedio de noches por cada reserva en este mes" />
                                            </div>{" "}
                                            <p className="ml-2 font-serif text-white">
                                                {
                                                    estadisticas?.ratios
                                                        .negocio_alquiler
                                                }
                                                %
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {estadisticas &&
                                estadisticas.total_perdidos > 0 ? (
                                    <div>
                                        <div className="relative z-20 flex items-center justify-center w-56 mx-auto">
                                            <p className="font-serif text-lg font-bold">
                                                Motivos de perdidas
                                            </p>

                                            <BotonInformacion text="De todos los leads que llegaron este mes, este porcentaje llegaron a convertirse en reservas!" />
                                        </div>
                                        <div className="flex items-center justify-center w-full p-2 text-center max-h-96 ">
                                            <Doughnut
                                                data={dataTorta}
                                                options={optionsTorta}
                                            />
                                            {/*                                
                                <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie
            data={dataNuevo}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {dataNuevo.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer> */}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="p-2 mt-3">
                                        <p className="font-serif text-base font-bold 2xl:text-lg">
                                            Motivos de perdidas
                                        </p>
                                        <div className="flex items-center justify-around w-full mt-5 bg-[#8884d8] border-4 border-[#8884d1] rounded-lg h-24 2xl:h-28">
                                            <div>
                                                {" "}
                                                <p className="ml-2 font-serif text-white">
                                                    En {months[mes - 1].name} no
                                                    has enviado ningun cliente a
                                                    perdidos
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Navbar>

            <NavbarMobile />
        </div>
    );
};
