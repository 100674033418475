import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { async } from "q";
import { useDispatch } from "react-redux";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { NavbarsAvatar } from "../../../components/NavbarsAvatar";
import { useAppSelector } from "../../../hooks/hooks";
import { loadStripe } from "@stripe/stripe-js";
import FormStripe from "./FormStripe";
import { Elements } from "@stripe/react-stripe-js";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";

interface serviceType {
    id: number;
    nombre: string;
    opcional: string;
    precio_final: number;
}

interface PedidoAdicional {
    id: number;
    id_pedido: number;
    servicio: string;
    costo: string;
}

interface Pedido {
    id: number;
    costo_alquiler: string;
    costo_total: string;
    pedidos_servicios_adicionales: PedidoAdicional[];
    costo_servicios_adicionales: string;
    descuento_estadia: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    pagado: string;
    saldo: string;
    casa: Casa;
}
export interface Casa {
    nombre: string;
    banner: string;
    Propietario: Propietario;
}

export interface Propietario {
    nombre: string;
    apellido: string;
    correo: string;
    avatar: string;
    banco: string;
    tipo_cuenta: string;
    tipo_moneda: string;
    titular_cuenta: string;
    tipo_documento: string;
    numero_documento: string;
    numero_cuenta: string;
    cci: string;
}

export const CheckoutSuscripcion = () => {
    const { id_suscripcion, tipo = 1 } = useParams();
    const { currency } = useAppSelector((state) => state.informacion);

    const dispatch = useDispatch();
    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
    );
    // const { id, tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const { id } = useAppSelector((state) => state.landing_house.house);

    const reservaString = localStorage.getItem("reserva");
    const reserva = reservaString ? JSON.parse(reservaString) : "";
    const [modalVisibleLogin, setModalVisibleLogin] = useState(false);
    const [pagoUnico, setPagoUnico] = useState(Number(tipo) === 1);
    const [comprobantePreview, setComprobantePreview] = useState<string | null>(
        null
    );
    const [comprobante, setComprobante] = useState<File | null>(null);
    const navigate = useNavigate();
    const [pedido, setPedido] = useState<Pedido | null>(null);
    const [enviando, setEnviando] = useState(false);
    const form = new FormData();
    const [formaPago, setFormaPago] = useState("stripe");
    const Standard = {
        plan: "Stándar",
        costo: 39.0,
        tipo_pago: "Mensual",
        anual: 375.0,
        descuento: 94,
    };
    const StandardEUR = {
        plan: "Stándar",
        costo: 89.0,
        tipo_pago: "Mensual",
        anual: 869.0,
        descuento: 199,
    };
    const Premium = {
        plan: "Premium",
        costo: 79.0,
        tipo_pago: "Anual",
        anual: 759.0,
        descuento: 189,
    };
    const PremiumEUR = {
        plan: "Premium",
        costo: 179.0,
        tipo_pago: "Anual",
        anual: 1749.0,
        descuento: 399,
    };
    const Starter = {
        plan: "Starter",
        costo: 19.0,
        tipo_pago: "Mensual",
        anual: 183.0,
        descuento: 46,
    };

    const StarterEUR = {
        plan: "Starter",
        costo: 19.0,
        tipo_pago: "Mensual",
        anual: 183.0,
        descuento: 46,
    };

    const getHouseFromTenant = async () => {
        try {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/dominio`,
            });
        } catch (error) {
            console.log(error);
        }
    };

    console.log(pedido);

    const handleComprobante = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        setComprobante(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setComprobantePreview(objectUrl);
    };

    const costo = () => {
        if (id_suscripcion === "1") {
            return pagoUnico
                ? currency === "USD"
                    ? Starter.costo
                    : StarterEUR.costo
                : currency === "USD"
                ? Starter.anual
                : StarterEUR.anual;
        } else if (id_suscripcion === "2") {
            return pagoUnico
                ? currency === "USD"
                    ? Standard.costo
                    : StandardEUR.costo
                : currency === "USD"
                ? Standard.anual
                : StandardEUR.anual;
        } else {
            return pagoUnico
                ? currency === "USD"
                    ? Premium.costo
                    : PremiumEUR.costo
                : currency === "USD"
                ? Premium.anual
                : PremiumEUR.anual;
        }
    };

    const sendPayment = async () => {
        if (!comprobante) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Debes adjuntar el comprobante",
                // text: "Te avisaremos cuando la disponibilidad haya sido confirmada, gracias!",
                icon: "warning",
            });
        } else {
            setEnviando(true);

            form.append("costo", `${costo()}`);
            form.append("tipo_pago", `${pagoUnico ? "Mensual" : "Anual"}`);
            form.append("tipo_moneda", `${currency}`);

            form.append(
                "plan",
                `${
                    id_suscripcion === "1"
                        ? "Starter"
                        : id_suscripcion === "2"
                        ? "Stándar"
                        : "Premium"
                }`
            );

            form.append("suscripcion_pago", comprobante);
            form.append("id_casa", `${id}`);

            console.log(form.entries());

            const data = await fetchAxiosToken({
                url: `/suscripcion_pagos/`,
                method: `post`,
                body: form,
            });
            console.log(data);
            setEnviando(false);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Comprobante recibido , muy pronto te confirmaremos si todo salio bien",
                // text: "Te avisaremos cuando la disponibilidad haya sido confirmada, gracias!",
                icon: "success",
            });

            navigate("/dashboard/suscripciones");
        }
    };

    return (
        <div>
            <Navbar titulo="Suscripciones">
                <div>
                    <div className="flex flex-col items-center justify-center p-3 font-medium text-center px-14 md:px-5">
                        <p className="mt-4 text-2xl uppercase 2xl:mt-2 2xl:text-4xl">
                            Pagar suscripción
                        </p>
                    </div>
                    <div className="grid w-full gap-10 p-4 mx-auto mt-5 text-sm 2xl:mt-10 md:grid-cols-2 max-w-7xl 2xl:text-base">
                        <div className="w-full p-4 border-2 rounded-lg">
                            <input
                                type="radio"
                                checked={pagoUnico}
                                onChange={() => setPagoUnico(true)}></input>
                            <span className="ml-3 text-sm font-semibold 2xl:text-base">
                                Pago mensual:
                            </span>
                            <p>Realiza el pago mes a mes.</p>
                            <hr className="2xl:my-5 my-2.5 border-t-2 border-gray-300" />
                            <div className={""}>
                                <input
                                    type="radio"
                                    checked={!pagoUnico}
                                    onChange={() =>
                                        setPagoUnico(false)
                                    }></input>
                                <span className="ml-3 font-semibold">
                                    Pago Anual:
                                </span>

                                <p>
                                    Realiza ahora el pago por un año entero y
                                    obtén un 20% de descuento
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col w-full p-4 border-2 rounded-lg">
                            <div className="text-base 2xl:text-lg">
                                Tu pedido para el plan
                                <span className="mx-1 font-medium">
                                    {id_suscripcion === "1"
                                        ? Starter.plan
                                        : id_suscripcion === "2"
                                        ? Standard.plan
                                        : Premium.plan}
                                    .
                                </span>
                            </div>

                            <div className="flex flex-col gap-2">
                                <div className="flex justify-between mt-4">
                                    <span className="font-medium">
                                        Precio del plan
                                    </span>
                                    <span className="font-medium">
                                        {pagoUnico
                                            ? costo()
                                            : id_suscripcion === "1"
                                            ? (currency === "USD"
                                                  ? Starter.descuento
                                                  : StarterEUR.descuento) +
                                              costo()
                                            : id_suscripcion === "2"
                                            ? (currency === "USD"
                                                  ? Standard.descuento
                                                  : StandardEUR.descuento) +
                                              costo()
                                            : (currency === "USD"
                                                  ? Premium.descuento
                                                  : PremiumEUR.descuento) +
                                              costo()}{" "}
                                        {currency}
                                    </span>
                                </div>
                                <hr className="my-1" />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col-reverse w-full gap-10 p-4 mx-auto mt-2 text-sm 2xl:mt-4 md:flex-row max-w-7xl 2xl:text-base">
                        <div className="w-full p-4 border-2 rounded-lg">
                            <h1 className="font-bold">
                                Elige tu metodo de pago
                            </h1>
                            <hr className="2xl:my-5 my-2.5 border-t-2 border-gray-300" />
                            <select
                                onChange={(e) => {
                                    setFormaPago(e.target.value);
                                }}
                                value={formaPago}
                                className="bg-slate-50 my-3 p-3 max-w-[500px] w-full border rounded-md">
                                <option className="p-10" value="stripe">
                                    Tarjeta de crédito o débito
                                </option>
                                <option className="p-10" value="transferencia">
                                    Transferencia bancaria
                                </option>
                            </select>
                            <p
                                className={
                                    formaPago === "stripe"
                                        ? "mb-4 2xl:text-base text-sm font-medium"
                                        : "hidden"
                                }>
                                Pago con tarjeta de crédito/débito cuenta con un
                                costo adicional del 5% del monto a pagar.
                            </p>

                            {formaPago === "stripe" ? (
                                <Elements stripe={stripePromise}>
                                    <FormStripe
                                        costo={costo()}
                                        plan={
                                            id_suscripcion === "1"
                                                ? "Starter"
                                                : id_suscripcion === "2"
                                                ? "Stándar"
                                                : "Premium"
                                        }
                                        tipo_pago={
                                            pagoUnico ? "Mensual" : "Anual"
                                        }
                                        currency={currency}
                                    />
                                </Elements>
                            ) : (
                                <div className="w-full p-4 border-2 rounded-lg">
                                    <p className="mt-5 text-sm text-justify 2xl:text-base">
                                        Realiza tu pago directamente en nuestra
                                        cuenta bancaria.{" "}
                                        <span className="font-semibold">
                                            Por favor, usa el número del pedido
                                            como referencia de pago.
                                        </span>{" "}
                                        Tu pedido no se procesará hasta que se
                                        haya recibido el importe en nuestra
                                        cuenta.
                                    </p>

                                    <div>
                                        <div className="flex flex-row">
                                            <p className="font-semibold">
                                                Banco:
                                            </p>{" "}
                                            <p className="ml-3">INTERBANK</p>
                                        </div>
                                        <div className="flex flex-row">
                                            <p className="font-semibold">
                                                Tipo de cuenta:
                                            </p>{" "}
                                            <p className="ml-3">
                                                CUENTA CORRIENTE
                                            </p>
                                        </div>
                                        <div className="flex flex-row">
                                            <p className="font-semibold">
                                                Cuenta en USD:
                                            </p>{" "}
                                            <p className="ml-3">
                                                2003005063122
                                            </p>
                                        </div>
                                        <div className="flex flex-row">
                                            <p className="font-semibold">
                                                CCI:
                                            </p>{" "}
                                            <p className="ml-3">
                                                003-200-003005063122-32
                                            </p>
                                        </div>
                                        <div className="flex flex-row">
                                            <p className="font-semibold">
                                                Titular de cuenta:
                                            </p>{" "}
                                            <p className="ml-3">
                                                BLUSYSTEM S.A.C.
                                            </p>
                                        </div>
                                    </div>

                                    <hr className="my-5 border-t-2 border-gray-300" />
                                    <h1 className="mt-4 font-semibold">
                                        Por favor, adjuntar su comprobante en
                                        formato Imagen
                                    </h1>

                                    <div className="relative flex mx-auto mt-4 bg-white border-4 border-dashed rounded-lg w-72 h-72 ">
                                        {comprobantePreview ? (
                                            <div className="w-full h-full rounded-lg ">
                                                <img
                                                    className="object-cover w-full h-full rounded-lg"
                                                    src={comprobantePreview}
                                                    alt="Comprobante de pago"
                                                />
                                            </div>
                                        ) : (
                                            <div className="flex items-center justify-center p-3 ">
                                                <div className="flex flex-col items-center justify-center w-full mx-auto ">
                                                    <div className="relative flex flex-col items-center justify-center">
                                                        <span className="mt-2 font-bold text-center ">
                                                            Arrastre el
                                                            comprobante
                                                        </span>
                                                        o
                                                        <span
                                                            className={`text-center text-blue-500  font-normal`}>
                                                            Sube la imagen desde
                                                            tu dispositivo
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <input
                                            type="file"
                                            onChange={handleComprobante}
                                            accept="image/*"
                                            className="absolute top-0 left-0 bg-red-200 opacity-0 cursor-pointer w-72 h-72 file:cursor-pointer"
                                        />
                                    </div>
                                    <button
                                        disabled={enviando}
                                        onClick={() => sendPayment()}
                                        className="flex justify-center w-full max-w-xs p-2 mx-auto mt-4 border-2 rounded-lg hover:bg-slate-100 hover:scale-105 disabled:bg-slate-100">
                                        {enviando
                                            ? "Enviando.."
                                            : "Enviar Comprobante"}
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-full p-4 border-2 rounded-lg h-60">
                            <div className="text-base font-semibold 2xl:text-lg ">
                                Resumen
                            </div>
                            <div className="flex flex-col gap-2"></div>

                            <div className="flex flex-col gap-2">
                                <div className="flex justify-between mt-4">
                                    <span className="font-medium">
                                        Monto a pagar{" "}
                                    </span>
                                    {pagoUnico
                                        ? costo()
                                        : id_suscripcion === "1"
                                        ? (currency === "USD"
                                              ? Starter.descuento
                                              : StarterEUR.descuento) + costo()
                                        : id_suscripcion === "2"
                                        ? (currency === "USD"
                                              ? Standard.descuento
                                              : StandardEUR.descuento) + costo()
                                        : (currency === "USD"
                                              ? Premium.descuento
                                              : PremiumEUR.descuento) +
                                          costo()}{" "}
                                    {currency}
                                </div>
                                <hr className="my-1" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex justify-between mt-4 text-green-500">
                                    <span className="font-medium ">
                                        Descuento{" "}
                                    </span>
                                    {pagoUnico
                                        ? 0
                                        : `- ${
                                              id_suscripcion === "1"
                                                  ? currency === "USD"
                                                      ? Starter.descuento
                                                      : StarterEUR.descuento
                                                  : id_suscripcion === "2"
                                                  ? currency === "USD"
                                                      ? Standard.descuento
                                                      : StandardEUR.descuento
                                                  : currency === "USD"
                                                  ? Premium.descuento
                                                  : PremiumEUR.descuento
                                          }`}{" "}
                                    {currency}
                                </div>
                                <hr className="my-1" />
                            </div>
                            <div className="flex justify-between mt-4">
                                <span className="font-medium">Total</span>
                                {pagoUnico ? (
                                    <span className="font-medium">
                                        {costo()} {currency}
                                    </span>
                                ) : (
                                    <span className="font-medium">
                                        {costo()} {currency}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Navbar>
            <NavbarMobile />
        </div>
    );
};
