import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import { NavbarCliente } from "../../dashboardClientes/NavbarCliente";
import { NavbarMobileCliente } from "../../dashboardClientes/NavbarMobileCliente";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import StarsRating from "../reservas/StarsRating";
import dividirFechaDatePicker from "../../../helpers/dividirFechaDatePicker";
import es from "date-fns/locale/es";
import { registerLocale } from "react-datepicker";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";

interface PedidoAdicional {
    id: number;
    id_pedido: number;
    servicio: string;
    costo: string;
}

export interface Pedido {
    id: number;
    id_pedidos: number;
    id_casa: number;
    puntuacion: string;
    resena: string;
    cliente_resena: string;
    avatar_resena: string;
    fecha_registro: string;
    habilitado: string;
    pedido: any;
}

interface input {
    cliente: string;
    avatar_resena: File | string;
    fecha_registro: string;
}

export const ResenaPropietario = () => {
    registerLocale("es", es);

    const { reservaID } = useParams();
    const navigate = useNavigate();

    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const [pedido, setPedido] = useState<Pedido | null>(null);
    const [preview, setPreview] = useState("");

    const [puntuacion, setPuntuacion] = useState(5);
    const [comentario, setComentario] = useState("");
    const [input, setInput] = useState<input>({
        cliente: "",
        avatar_resena: "",
        fecha_registro: "",
    });

    const { id: id_casa_redux } = useAppSelector(
        (state) => state.landing_house.house
    );

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setInput({ ...input, avatar_resena: e.target.files[0] });
            const objectUrl = URL.createObjectURL(e.target.files[0]);
            setPreview(objectUrl);
        }
    };

    useEffect(() => {
        if (!id_casa_redux) {
            if (tipo_usuario === 3 || tipo_usuario === 4) {
                navigate("/dashboard/reviews");
            }

            if (tipo_usuario === 6) {
                navigate("/dashboard-corredor/casas");
            }
        }
    }, [id_casa_redux]);

    useEffect(() => {
        if (reservaID !== "0") {
            fetchAxiosToken({
                url: `/resenas/getOne/${reservaID}/${id}`,
            })
                .then((e) => {
                    setPedido(e);
                    setInput({
                        ...input,
                        avatar_resena: e.avatar_resena,
                        cliente: e.cliente_resena,
                        fecha_registro: e.fecha_registro,
                    });
                    setPuntuacion(Number(e.puntuacion));
                    setComentario(e.resena);
                })
                .catch((e) => console.log(e));
        }
    }, [reservaID]);

    const enviarResena = async () => {
        if (comentario === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "warning",
                title: "Debes agregar un comentario",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }
        if (input.cliente === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "warning",
                title: "Debes agregar un nombre de cliente",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }
        if (input.fecha_registro === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "warning",
                title: "Debes agregar una fecha",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }
        if (input.avatar_resena === "" && reservaID !== "0") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "warning",
                title: "Debes agregar una imagen ",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }
        try {
            const form = new FormData();
            form.append("cliente", input.cliente);
            form.append("avatar_usuario", input.avatar_resena);
            form.append("fecha_registro", input.fecha_registro);
            form.append("puntuacion", `${puntuacion}`);
            form.append("resena", `${comentario}`);
            form.append("id_casa", `${id_casa_redux}`);

            if (reservaID === "0") {
                const data = await fetchAxiosToken({
                    url: `/resenas/postByOwner`,
                    method: "post",
                    body: form,
                });

                navigate(`/dashboard/reviews`, { replace: false });
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    // position: "top-end",
                    icon: "success",
                    title: "Reseña guardada",
                    text: "su reseña fue guardada con exito",
                    showConfirmButton: true,
                    // timer: 2000,
                });
            } else {
                const data = await fetchAxiosToken({
                    url: `/resenas/putResena/${reservaID}`,
                    method: "put",
                    body: form,
                });
                console.log(data);
                navigate(`/dashboard/reviews`, { replace: false });
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    // position: "top-end",
                    icon: "success",
                    title: "Reseña actualizada",
                    text: "su reseña fue guardada con exito",
                    showConfirmButton: true,
                    // timer: 2000,
                });
            }
        } catch (error: any) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "warning",
                title: "No se pudo guardar",
                text: `${error.response.data.error}`,
                showConfirmButton: true,
                // timer: 2000,
            });
        }
    };

    const render = () => {
        return (
            <div className="flex flex-col w-full p-4">
                <span className="text-base font-medium text-center 2xl:text-lg">
                    Realizar una reseña
                </span>
                <div className="mt-4 text-sm font-medium text-center 2xl:text-base">
                    Puedes realizar una reseña para que tus futuros clientes
                    puedan verla
                </div>
                <div className="mx-auto mt-4 font-medium text-center w-80">
                    <span>
                        Fecha: <span className="text-red-500">*</span>
                    </span>
                    <ReactDatePicker
                        className="2xl:p-2 p-1.5 mt-2 bg-white border rounded-lg cursor-pointer border-blue-blueFrom w-80"
                        placeholderText="Seleccionar fecha"
                        locale="es"
                        autoComplete="off"
                        name="fechaHasta"
                        dateFormat="dd/MM/yyyy"
                        selected={
                            input.fecha_registro !== ""
                                ? new Date(
                                      dividirFechaDatePicker(
                                          input.fecha_registro
                                      )[0],
                                      dividirFechaDatePicker(
                                          input.fecha_registro
                                      )[1] - 1,
                                      dividirFechaDatePicker(
                                          input.fecha_registro
                                      )[2]
                                  )
                                : null
                        }
                        onChange={(e) =>
                            setInput({
                                ...input,
                                fecha_registro: e
                                    ? `${e.getFullYear()}-${`${
                                          e.getMonth() + 1
                                      }`.padStart(
                                          2,
                                          "0"
                                      )}-${`${e.getDate()}`.padStart(2, "0")}`
                                    : "",
                            })
                        }
                    />
                </div>
                <div className="mt-4 font-medium text-center">
                    <p>
                        Cliente: <span className="text-red-500">*</span>
                    </p>
                    <input
                        type={"text"}
                        className="p-2 border rounded-lg 2xl:p-3 border-blue-blueFrom w-80"
                        name="cliente"
                        placeholder="Jose Perez"
                        value={input.cliente}
                        onChange={(e) =>
                            setInput({
                                ...input,
                                cliente: e.target.value,
                            })
                        }
                    />
                </div>

                <div className="flex flex-col items-center w-full mt-4 select-none">
                    <div className="flex overflow-hidden transition-transform duration-300 ease-in-out rounded-full 2xl:h-60 2xl:w-60 h-52 w-52 hover:scale-125">
                        <div className="relative transition-transform duration-300 ease-in-out">
                            <input
                                onChange={handleChangeImage}
                                type="file"
                                accept="image/*"
                                className="absolute left-0 z-50 w-full h-full rounded-full opacity-0 cursor-pointer select-none file:cursor-pointer "
                            />
                            {typeof input.avatar_resena !== "string" ? (
                                <img
                                    src={preview}
                                    className="rounded-full flex 2xl:h-60 2xl:w-60 h-52 w-52 p-[0.050rem] border-[3px] object-cover cursor-pointer border-blue-blueFrom"
                                    alt="foto de perfil"
                                />
                            ) : (
                                <img
                                    src={`${process.env.REACT_APP_PROXY}/usuarios/getImage/${input.avatar_resena}`}
                                    className="rounded-full p-[0.050rem] 2xl:h-60 2xl:w-60 h-52 w-52 border-[3px] object-cover cursor-pointer border-blue-blueFrom"
                                    alt=""
                                />
                            )}
                        </div>
                    </div>
                    <span className="relative mt-2 text-center cursor-pointer w-max text-blue-blueFrom hover:scale-105">
                        <span>Foto de cliente</span>{" "}
                        <span className="text-xl text-red-600">*</span>
                        <input
                            onChange={handleChangeImage}
                            type="file"
                            className="absolute left-0 z-50 w-full h-full bg-red-200 opacity-0 cursor-pointer select-none file:cursor-pointer"
                        />
                    </span>
                </div>
                <div className="flex justify-center mt-5 mb-2 2xl:mt-10">
                    <span>
                        Puntuación <span className="text-red-500">*</span>
                    </span>
                </div>
                <div className="mx-auto ">
                    <StarsRating starsQuantity={5} setInput={setPuntuacion} />
                </div>
                <div className="flex flex-col gap-2 p-4 mx-auto mt-2 2xl:mt-4 w-80">
                    <label className="mx-auto">
                        Comentario <span className="text-red-500">*</span>
                    </label>
                    <textarea
                        className="h-24 p-3 border rounded-lg border-blue-blueFrom"
                        name="comentarios"
                        value={comentario}
                        onChange={(e) => setComentario(e.target.value)}
                    />
                </div>
                {
                    <div className="w-full text-center">
                        <button
                            onClick={() => enviarResena()}
                            className="w-2/5 p-3 mt-4 font-medium text-white border rounded-lg 2xl:w-3/5 bg-blue-blueFrom active:translate-y-1">
                            Enviar
                        </button>
                    </div>
                }
            </div>
        );
    };

    if (!pedido && reservaID !== "0")
        return (
            <>
                <Navbar titulo="Reseñas">
                    <div className="p-4 font-medium">Cargando pedido...</div>
                    <NavbarMobile />
                </Navbar>
            </>
        );
    return (
        <>
            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="Reseñas">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : tipo_usuario === 5 || tipo_usuario === 7 ? (
                <NavbarCliente>
                    {render()}
                    <NavbarMobileCliente />
                </NavbarCliente>
            ) : (
                <NavbarBroker titulo="Reseñas">
                    {render()}
                    <NavbarMobileBroker />
                </NavbarBroker>
            )}
        </>
    );
};
