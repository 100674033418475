import { useState, useRef, useEffect } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { ModalEditRooms } from "../modalEdit/ModalEditRooms";
import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../assets/css/swiper_pagination.css";
import { ModalEditDescripcion1 } from "../modalEdit/ModalEditDescripcion1";
import Lightbox from "react-18-image-lightbox";
interface Props {
    toggleButton: boolean;
}

interface Input {
    descripcion_1: string;
    titulo_descripcion_1: string;
}

const Habitaciones = ({ toggleButton }: Props) => {
    const {
        titulo_descripcion_1,
        descripcion_1,
        casa_habitaciones,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);
    const [modalVisibleRooms, setModalVisibleRooms] = useState<boolean>(false);
    const [modalVisibleComodidades, setModalVisibleComodidades] =
        useState<boolean>(false);
    const habitacionesRef = useRef<HTMLDivElement>(null);

    const [input, setInput] = useState<Input>({
        descripcion_1: descripcion_1,
        titulo_descripcion_1: titulo_descripcion_1,
    });

    const getOffsetTop = (entries: any, observer: any) => {
        entries.forEach((entry: any) => {
            if (entry.intersectionRatio) {
                if (entry.intersectionRatio < 0.5) {
                    habitacionesRef.current?.classList.add("opacity-100");
                }
            }
        });
    };

    useEffect(() => {
        if (habitacionesRef.current) {
            const options = {
                root: null,
                rootMargin: "0px",
                threshold: [0, 1],
            };

            let observer = new IntersectionObserver(getOffsetTop, options);

            observer.observe(habitacionesRef.current);
        }
    }, []);

    useEffect(() => {
        setInput({
            descripcion_1: descripcion_1,
            titulo_descripcion_1: titulo_descripcion_1,
        });
    }, [descripcion_1]);

    const [photos, setPhotos] = useState([
        {
            src: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${casa_habitaciones[0]?.imagen}`,
            original: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${casa_habitaciones[0]?.imagen}`,
            width: 400,
            height: 200,
        },
    ]);
    const [index, setIndex] = useState(-1);

    const currentImage = photos?.[index];
    const nextIndex = (index + 1) % photos?.length;
    const nextImage = photos?.[nextIndex] || currentImage;
    const prevIndex = (index + photos?.length - 1) % photos?.length;
    const prevImage = photos?.[prevIndex] || currentImage;

    const handleClick = (index: any) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    useEffect(() => {
        let imagenesTotal: any = photos.length ? [...photos] : [];
        casa_habitaciones?.map((imagen) => {
            imagenesTotal.push({
                src: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${imagen.imagen}`,
                original: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${imagen.imagen}`,

                height: 200,
            });
        });

        setPhotos(imagenesTotal);
    }, [casa_habitaciones]);

    return (
        <div className="lg:mx-10">
            {!!currentImage && (
                <Lightbox
                    mainSrc={currentImage.original}
                    mainSrcThumbnail={currentImage.src}
                    nextSrc={nextImage.original}
                    nextSrcThumbnail={nextImage.src}
                    prevSrc={prevImage.original}
                    prevSrcThumbnail={prevImage.src}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
            )}
            {modalVisibleComodidades && (
                <ModalEditDescripcion1
                    setModalVisibleComodidades={setModalVisibleComodidades}
                    modalVisibleComodidades={modalVisibleComodidades}
                />
            )}

            {modalVisibleRooms && (
                <ModalEditRooms
                    modalVisibleRooms={modalVisibleRooms}
                    setModalVisibleRooms={setModalVisibleRooms}
                />
            )}
            <div className="flex flex-col items-center justify-center gap-8 lg:flex-row lg:p-10 2xl:p-16 md:p-7 ">
                <div className="flex-col w-full lg:w-2/3">
                    <div className="flex items-center justify-center md:justify-start ">
                        <div className=" text-xl lg:text-3xl 2xl:text-4xl font-bold text-[#1c4cbb]">
                            {titulo_descripcion_1 ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: titulo_descripcion_1.replace(
                                            /\n/g,
                                            "<br>"
                                        ),
                                    }}
                                ></div>
                            ) : (
                                <div>Sobre el alojamiento</div>
                            )}
                        </div>
                        {toggleButton && (
                            <div
                                onClick={() => setModalVisibleComodidades(true)}
                                title="editar descripcion"
                                className="flex flex-col items-center px-2 py-1 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                            >
                                <svg
                                    className="w-8 h-8 text-[#1C4CBB]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                    <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                    />
                                </svg>
                                <div className="text-xs text-[#1C4CBB] text-center">
                                    Editar Descripción
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mt-10 text-base text-justify text-gray-500 2xl:text-lg lg:mr-16 ">
                        {descripcion_1 ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: descripcion_1.replace(
                                        /\n/g,
                                        "<br>"
                                    ),
                                }}
                            ></div>
                        ) : (
                            <div>
                                {" "}
                                La casa cuenta con :<br></br>
                                <br></br>
                                Sala con chimenea, comedor,cocina equipada, zona
                                de parrilla con horno de barro, zona de fogata,
                                terraza que da aun patio amplio jardin con una
                                hamaca y camillas para disfrutar de la psicina y
                                el exterior, piscina privada de 1.5 metros de
                                profundidad en la parte mas honda, zona de
                                trabaja (2 escritorios), un huerto.
                            </div>
                        )}
                    </div>

                    {casa_habitaciones.length > 1 && (
                        <div className="mt-10 2xl:mt-20">
                            <Swiper
                                modules={[
                                    Navigation,
                                    Autoplay,
                                    EffectFade,
                                    Pagination,
                                ]}
                                className=" h-72 sample-slider"
                                spaceBetween={1}
                                slidesPerView={1}
                                autoplay={{ delay: 5000 }}
                                breakpoints={{
                                    560: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    1500: {
                                        slidesPerView:
                                            casa_habitaciones.length > 2
                                                ? 3
                                                : 2,
                                        spaceBetween: 20,
                                    },
                                }}
                                loop
                                pagination={{ clickable: true }}
                            >
                                {casa_habitaciones?.map((habitacion, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="relative h-full">
                                            <img
                                                src={`${process.env.REACT_APP_PROXY}/casa_habitaciones/getImage/${habitacion.imagen}`}
                                                className="object-cover w-full h-full mx-auto rounded-lg"
                                                alt={`slide ${index + 1}`}
                                            />
                                            <div className="absolute bottom-0 w-full rounded-lg h-1/3 bg-gradient-to-b from-transparent to-zinc-900"></div>
                                            <div className="absolute z-20 flex items-end justify-between w-full h-full bottom-3">
                                                <div className="flex flex-col w-1/2 mx-5">
                                                    <div className="text-base font-bold text-white lg:text-xl">
                                                        {habitacion?.habitacion}
                                                    </div>

                                                    <div className="mt-1 text-xs text-white lg:text-base">
                                                        {
                                                            habitacion?.descripcion
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        handleClick(index + 1)
                                                    }
                                                    className="hover:scale-105 w-10 h-10 bg-white rounded-lg shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px] absolute top-6 right-1 cursor-pointer mx-3  flex justify-center items-center"
                                                >
                                                    <svg
                                                        className="w-6 h-6 cursor-pointer"
                                                        id="Capa_2"
                                                        data-name="Capa 2"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 100 100"
                                                    >
                                                        <g id="Testimonios">
                                                            <path
                                                                d="m94.89,39.06h-33.96V5.1c0-2.82-2.29-5.1-5.11-5.1h-11.66c-2.82,0-5.11,2.28-5.11,5.1v33.96H5.11c-2.82,0-5.11,2.29-5.11,5.11v11.66c0,2.82,2.29,5.11,5.11,5.11h33.96v33.95c0,2.82,2.29,5.11,5.11,5.11h11.66c2.82,0,5.11-2.29,5.11-5.11v-33.95h33.96c2.82,0,5.11-2.29,5.11-5.11v-11.66c0-2.82-2.29-5.11-5.11-5.11"
                                                                fill="#1E90FF"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    )}
                </div>
                {casa_habitaciones.length ? (
                    <div className="relative h-[250px] md:h-[400px] 2xl:h-[550px] w-full lg:w-1/3 md:flex hidden">
                        <img
                            className="object-cover w-full h-full rounded-lg"
                            src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${casa_habitaciones[0]?.imagen}`}
                            alt="imagen habitación"
                        />
                        <div className="absolute z-20 top-4 left-4">
                            {toggleButton && (
                                <div
                                    onClick={() => setModalVisibleRooms(true)}
                                    title="editar habitaciones"
                                    className="flex flex-col items-center px-2 py-1 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100 "
                                >
                                    <svg
                                        className="w-8 h-8 text-blue-500"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                        <line
                                            x1="13.5"
                                            y1="6.5"
                                            x2="17.5"
                                            y2="10.5"
                                        />
                                    </svg>
                                    <div className="text-xs text-[#1C4CBB] text-center">
                                        Editar Habitaciones
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="absolute bottom-0 z-10 w-full rounded-lg h-1/3 bg-gradient-to-b from-transparent to-zinc-900"></div>
                        <div className="absolute z-10 flex items-end justify-between w-full h-full bottom-3">
                            <div className="flex flex-col w-1/2 mx-5">
                                <div className="text-base font-bold text-white lg:text-xl ">
                                    {casa_habitaciones[0]?.habitacion}
                                </div>

                                <div className="mt-1 text-xs text-white lg:text-base ">
                                    {casa_habitaciones[0]?.descripcion}
                                </div>
                            </div>
                            <div
                                onClick={() => handleClick(0)}
                                className="hover:scale-105 w-10 h-10 bg-white rounded-lg shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px] cursor-pointer mx-3 flex justify-center items-center absolute top-6 right-1  "
                            >
                                <svg
                                    className="w-6 h-6 cursor-pointer "
                                    id="Capa_2"
                                    data-name="Capa 2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 100 100"
                                >
                                    <g id="Testimonios">
                                        <path
                                            d="m94.89,39.06h-33.96V5.1c0-2.82-2.29-5.1-5.11-5.1h-11.66c-2.82,0-5.11,2.28-5.11,5.1v33.96H5.11c-2.82,0-5.11,2.29-5.11,5.11v11.66c0,2.82,2.29,5.11,5.11,5.11h33.96v33.95c0,2.82,2.29,5.11,5.11,5.11h11.66c2.82,0,5.11-2.29,5.11-5.11v-33.95h33.96c2.82,0,5.11-2.29,5.11-5.11v-11.66c0-2.82-2.29-5.11-5.11-5.11"
                                            fill="#1E90FF"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className={
                            toggleButton
                                ? "flex items-center justify-center gap-2"
                                : "hidden"
                        }
                    >
                        <p className="text-[#1C4CBB] text-xl md:text-3xl font-semibold">
                            {" "}
                            Agregar habitaciones
                        </p>
                        <div
                            onClick={() => setModalVisibleRooms(true)}
                            title="editar habitaciones"
                            className="flex items-center px-2 py-1 my-5 mr-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                        >
                            <svg
                                className="w-10 h-10 text-[#1C4CBB]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                            </svg>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Habitaciones;
