import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ItemInterface, Estado } from "./CRMAdmin";

interface AccordionProps {
    column1: ItemInterface[];
    column2: ItemInterface[];
    column3: ItemInterface[];
    column4: ItemInterface[];
    column5: ItemInterface[];
    column6: ItemInterface[];
    handleEnable: (n: number) => void;
    modalEliminar: ModalEliminar;
    setModalEliminar: (s: ModalEliminar) => void;
    setEstadoEdit: (s: Estado | null) => void;
}

interface ModalEliminar {
    visible: boolean;
    index: number;
}

const Accordion: React.FC<AccordionProps> = ({
    column1,
    column2,
    column3,
    column4,
    column5,
    column6,
    handleEnable,
    modalEliminar,
    setModalEliminar,
    setEstadoEdit,
}) => {
    const [activeAccordion, setActiveAccordion] = useState<number | null>(null);
    const navigate = useNavigate();
    const handleAccordionClick = (index: number) => {
        setActiveAccordion((prevIndex) => (prevIndex === index ? null : index));
    };
    function getColorForTag(tag: string): string {
        switch (tag) {
            case "Instagram":
                return "#E4405F";
            case "LinkedIn":
                return "#0077B5";
            case "Facebook":
                return "#1877F2";
            case "WhatsApp":
                return "#25D366";
            case "Email":
                return "#FF0000";
            case "Web":
                return "#FFFF00";
            default:
                return "#FFFF00";
        }
    }
    return (
        <div className="flex-col hidden w-11/12 mx-auto mt-6 lg:flex max-w-7xl">
            <div className="w-full my-1">
                <h2
                    className={`text-lg font-bold cursor-pointer flex items-center  ${
                        activeAccordion === 0
                            ? "bg-slate-100 hover:bg-gray-50"
                            : "bg-gray-50 hover:bg-gray-100"
                    }`}
                    onClick={() => handleAccordionClick(0)}>
                    {activeAccordion !== 0 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Primer seguimiento ({column1.length})
                </h2>
                {activeAccordion === 0 &&
                    column1.map((item) => (
                        <div
                            key={item.id}
                            className="relative flex items-center justify-between gap-2 p-2 mt-2 border border-gray-100 rounded-lg ">
                            <div
                                onClick={() =>
                                    navigate(`/dashboard-admin/crm/${item.id}`)
                                }
                                className="flex items-center gap-2 cursor-pointer hover:scale-105">
                                <h1 className="">{`${item.nombre} ${item.apellido}`}</h1>
                                <div className="flex items-center gap-1">
                                    <p
                                        style={{
                                            backgroundColor: getColorForTag(
                                                item.etiqueta
                                            ),
                                        }}
                                        className={`w-3 h-3 rounded-full `}></p>
                                    <p className="text-xs text-gray-400">
                                        {item.etiqueta}
                                    </p>
                                </div>
                                <span className="text-xs text-gray-400">
                                    Tipo : # {item.tipo}
                                </span>
                            </div>

                            <div
                                onMouseLeave={() =>
                                    setModalEliminar({
                                        visible: false,
                                        index: 0,
                                    })
                                }
                                className="bg-red-500">
                                <span
                                    onClick={() =>
                                        setModalEliminar({
                                            visible: true,
                                            index:
                                                item.id === modalEliminar.index
                                                    ? 0
                                                    : item.id,
                                        })
                                    }
                                    className={`absolute right-3 top-3  cursor-pointer `}
                                    aria-label="close">
                                    <svg
                                        className="w-5 h-5 text-black"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="12" cy="12" r="1" />{" "}
                                        <circle cx="12" cy="5" r="1" />{" "}
                                        <circle cx="12" cy="19" r="1" />
                                    </svg>
                                </span>
                                <div
                                    className={
                                        modalEliminar.visible === true &&
                                        modalEliminar.index === item.id
                                            ? "absolute bg-white  right-1 top-8 border rounded-lg  p-2 z-10"
                                            : "hidden"
                                    }>
                                    <p
                                        onClick={() =>
                                            navigate(
                                                `/dashboard-admin/crm/${item.id}`
                                            )
                                        }
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Detalle
                                    </p>

                                    <p
                                        onClick={() => (
                                            setModalEliminar({
                                                visible: false,
                                                index: 0,
                                            }),
                                            setEstadoEdit({
                                                id: item.id,
                                                nombre: "Primer seguimiento",
                                            })
                                        )}
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Actualizar
                                    </p>

                                    <p
                                        onClick={() => (
                                            setModalEliminar({
                                                visible: false,
                                                index: 0,
                                            }),
                                            handleEnable(item.id)
                                        )}
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Eliminar
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <div className="w-full my-1">
                <h2
                    className={`text-lg font-bold cursor-pointer flex items-center  ${
                        activeAccordion === 1
                            ? "bg-slate-100 hover:bg-gray-50"
                            : "bg-gray-50 hover:bg-gray-100"
                    }`}
                    onClick={() => handleAccordionClick(1)}>
                    {activeAccordion !== 1 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Segundo seguimiento ({column2.length})
                </h2>
                {activeAccordion === 1 &&
                    column2.map((item) => (
                        <div
                            key={item.id}
                            className="relative flex items-center justify-between gap-2 p-2 mt-2 border border-gray-100 rounded-lg ">
                            <div
                                onClick={() =>
                                    navigate(`/dashboard-admin/crm/${item.id}`)
                                }
                                className="flex items-center gap-2 cursor-pointer hover:scale-105">
                                <h1 className="">{`${item.nombre} ${item.apellido}`}</h1>
                                <div className="flex items-center gap-1">
                                    <p
                                        style={{
                                            backgroundColor: getColorForTag(
                                                item.etiqueta
                                            ),
                                        }}
                                        className={`w-3 h-3 rounded-full `}></p>
                                    <p className="text-xs text-gray-400">
                                        {item.etiqueta}
                                    </p>
                                </div>
                                <span className="text-xs text-gray-400">
                                    Tipo : # {item.tipo}
                                </span>
                            </div>

                            <div
                                onMouseLeave={() =>
                                    setModalEliminar({
                                        visible: false,
                                        index: 0,
                                    })
                                }
                                className="bg-red-500">
                                <span
                                    onClick={() =>
                                        setModalEliminar({
                                            visible: true,
                                            index:
                                                item.id === modalEliminar.index
                                                    ? 0
                                                    : item.id,
                                        })
                                    }
                                    className={`absolute right-3 top-3  cursor-pointer `}
                                    aria-label="close">
                                    <svg
                                        className="w-5 h-5 text-black"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="12" cy="12" r="1" />{" "}
                                        <circle cx="12" cy="5" r="1" />{" "}
                                        <circle cx="12" cy="19" r="1" />
                                    </svg>
                                </span>
                                <div
                                    className={
                                        modalEliminar.visible === true &&
                                        modalEliminar.index === item.id
                                            ? "absolute bg-white  right-1 top-8 border rounded-lg  p-2 z-10"
                                            : "hidden"
                                    }>
                                    <p
                                        onClick={() =>
                                            navigate(
                                                `/dashboard-admin/crm/${item.id}`
                                            )
                                        }
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Detalle
                                    </p>

                                    <p
                                        onClick={() => (
                                            setModalEliminar({
                                                visible: false,
                                                index: 0,
                                            }),
                                            setEstadoEdit({
                                                id: item.id,
                                                nombre: "Segundo seguimiento",
                                            })
                                        )}
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Actualizar
                                    </p>

                                    <p
                                        onClick={() => (
                                            setModalEliminar({
                                                visible: false,
                                                index: 0,
                                            }),
                                            handleEnable(item.id)
                                        )}
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Eliminar
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <div className="w-full my-1">
                <h2
                    className={`text-lg font-bold cursor-pointer flex items-center  ${
                        activeAccordion === 2
                            ? "bg-slate-100 hover:bg-gray-50"
                            : "bg-gray-50 hover:bg-gray-100"
                    }`}
                    onClick={() => handleAccordionClick(2)}>
                    {activeAccordion !== 2 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Propuesta enviada ({column3.length})
                </h2>
                {activeAccordion === 2 &&
                    column3.map((item) => (
                        <div
                            key={item.id}
                            className="relative flex items-center justify-between gap-2 p-2 mt-2 border border-gray-100 rounded-lg ">
                            <div
                                onClick={() =>
                                    navigate(`/dashboard-admin/crm/${item.id}`)
                                }
                                className="flex items-center gap-2 cursor-pointer hover:scale-105">
                                <h1 className="">{`${item.nombre} ${item.apellido}`}</h1>
                                <div className="flex items-center gap-1">
                                    <p
                                        style={{
                                            backgroundColor: getColorForTag(
                                                item.etiqueta
                                            ),
                                        }}
                                        className={`w-3 h-3 rounded-full `}></p>
                                    <p className="text-xs text-gray-400">
                                        {item.etiqueta}
                                    </p>
                                </div>
                                <span className="text-xs text-gray-400">
                                    Tipo : # {item.tipo}
                                </span>
                            </div>

                            <div
                                onMouseLeave={() =>
                                    setModalEliminar({
                                        visible: false,
                                        index: 0,
                                    })
                                }
                                className="bg-red-500">
                                <span
                                    onClick={() =>
                                        setModalEliminar({
                                            visible: true,
                                            index:
                                                item.id === modalEliminar.index
                                                    ? 0
                                                    : item.id,
                                        })
                                    }
                                    className={`absolute right-3 top-3  cursor-pointer `}
                                    aria-label="close">
                                    <svg
                                        className="w-5 h-5 text-black"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="12" cy="12" r="1" />{" "}
                                        <circle cx="12" cy="5" r="1" />{" "}
                                        <circle cx="12" cy="19" r="1" />
                                    </svg>
                                </span>
                                <div
                                    className={
                                        modalEliminar.visible === true &&
                                        modalEliminar.index === item.id
                                            ? "absolute bg-white  right-1 top-8 border rounded-lg  p-2 z-10"
                                            : "hidden"
                                    }>
                                    <p
                                        onClick={() =>
                                            navigate(
                                                `/dashboard-admin/crm/${item.id}`
                                            )
                                        }
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Detalle
                                    </p>

                                    <p
                                        onClick={() => (
                                            setModalEliminar({
                                                visible: false,
                                                index: 0,
                                            }),
                                            setEstadoEdit({
                                                id: item.id,
                                                nombre: "Propuesta enviada",
                                            })
                                        )}
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Actualizar
                                    </p>

                                    <p
                                        onClick={() => (
                                            setModalEliminar({
                                                visible: false,
                                                index: 0,
                                            }),
                                            handleEnable(item.id)
                                        )}
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Eliminar
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <div className="w-full my-1">
                <h2
                    className={`text-lg font-bold cursor-pointer flex items-center  ${
                        activeAccordion === 1
                            ? "bg-slate-100 hover:bg-gray-50"
                            : "bg-gray-50 hover:bg-gray-100"
                    }`}
                    onClick={() => handleAccordionClick(3)}>
                    {activeAccordion !== 3 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Negociación ({column4.length})
                </h2>
                {activeAccordion === 3 &&
                    column4.map((item) => (
                        <div
                            key={item.id}
                            className="relative flex items-center justify-between gap-2 p-2 mt-2 border border-gray-100 rounded-lg ">
                            <div
                                onClick={() =>
                                    navigate(`/dashboard-admin/crm/${item.id}`)
                                }
                                className="flex items-center gap-2 cursor-pointer hover:scale-105">
                                <h1 className="">{`${item.nombre} ${item.apellido}`}</h1>
                                <div className="flex items-center gap-1">
                                    <p
                                        style={{
                                            backgroundColor: getColorForTag(
                                                item.etiqueta
                                            ),
                                        }}
                                        className={`w-3 h-3 rounded-full `}></p>
                                    <p className="text-xs text-gray-400">
                                        {item.etiqueta}
                                    </p>
                                </div>
                                <span className="text-xs text-gray-400">
                                    Tipo : # {item.tipo}
                                </span>
                            </div>

                            <div
                                onMouseLeave={() =>
                                    setModalEliminar({
                                        visible: false,
                                        index: 0,
                                    })
                                }
                                className="bg-red-500">
                                <span
                                    onClick={() =>
                                        setModalEliminar({
                                            visible: true,
                                            index:
                                                item.id === modalEliminar.index
                                                    ? 0
                                                    : item.id,
                                        })
                                    }
                                    className={`absolute right-3 top-3  cursor-pointer `}
                                    aria-label="close">
                                    <svg
                                        className="w-5 h-5 text-black"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="12" cy="12" r="1" />{" "}
                                        <circle cx="12" cy="5" r="1" />{" "}
                                        <circle cx="12" cy="19" r="1" />
                                    </svg>
                                </span>
                                <div
                                    className={
                                        modalEliminar.visible === true &&
                                        modalEliminar.index === item.id
                                            ? "absolute bg-white  right-1 top-8 border rounded-lg  p-2 z-10"
                                            : "hidden"
                                    }>
                                    <p
                                        onClick={() =>
                                            navigate(
                                                `/dashboard-admin/crm/${item.id}`
                                            )
                                        }
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Detalle
                                    </p>

                                    <p
                                        onClick={() => (
                                            setModalEliminar({
                                                visible: false,
                                                index: 0,
                                            }),
                                            setEstadoEdit({
                                                id: item.id,
                                                nombre: "Negociación",
                                            })
                                        )}
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Actualizar
                                    </p>

                                    <p
                                        onClick={() => (
                                            setModalEliminar({
                                                visible: false,
                                                index: 0,
                                            }),
                                            handleEnable(item.id)
                                        )}
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Eliminar
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <div className="w-full my-1">
                <h2
                    className={`text-lg font-bold cursor-pointer flex items-center  ${
                        activeAccordion === 4
                            ? "bg-slate-100 hover:bg-gray-50"
                            : "bg-gray-50 hover:bg-gray-100"
                    }`}
                    onClick={() => handleAccordionClick(4)}>
                    {activeAccordion !== 4 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Ganados ({column5.length})
                </h2>
                {activeAccordion === 4 &&
                    column5.map((item) => (
                        <div
                            key={item.id}
                            className="relative flex items-center justify-between gap-2 p-2 mt-2 border border-gray-100 rounded-lg ">
                            <div
                                onClick={() =>
                                    navigate(`/dashboard-admin/crm/${item.id}`)
                                }
                                className="flex items-center gap-2 cursor-pointer hover:scale-105">
                                <h1 className="">{`${item.nombre} ${item.apellido}`}</h1>
                                <div className="flex items-center gap-1">
                                    <p
                                        style={{
                                            backgroundColor: getColorForTag(
                                                item.etiqueta
                                            ),
                                        }}
                                        className={`w-3 h-3 rounded-full `}></p>
                                    <p className="text-xs text-gray-400">
                                        {item.etiqueta}
                                    </p>
                                </div>
                                <span className="text-xs text-gray-400">
                                    Tipo : # {item.tipo}
                                </span>
                            </div>

                            <div
                                onMouseLeave={() =>
                                    setModalEliminar({
                                        visible: false,
                                        index: 0,
                                    })
                                }
                                className="bg-red-500">
                                <span
                                    onClick={() =>
                                        setModalEliminar({
                                            visible: true,
                                            index:
                                                item.id === modalEliminar.index
                                                    ? 0
                                                    : item.id,
                                        })
                                    }
                                    className={`absolute right-3 top-3  cursor-pointer `}
                                    aria-label="close">
                                    <svg
                                        className="w-5 h-5 text-black"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="12" cy="12" r="1" />{" "}
                                        <circle cx="12" cy="5" r="1" />{" "}
                                        <circle cx="12" cy="19" r="1" />
                                    </svg>
                                </span>
                                <div
                                    className={
                                        modalEliminar.visible === true &&
                                        modalEliminar.index === item.id
                                            ? "absolute bg-white  right-1 top-8 border rounded-lg  p-2 z-10"
                                            : "hidden"
                                    }>
                                    <p
                                        onClick={() =>
                                            navigate(
                                                `/dashboard-admin/crm/${item.id}`
                                            )
                                        }
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Detalle
                                    </p>

                                    <p
                                        onClick={() => (
                                            setModalEliminar({
                                                visible: false,
                                                index: 0,
                                            }),
                                            setEstadoEdit({
                                                id: item.id,
                                                nombre: "Ganados",
                                            })
                                        )}
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Actualizar
                                    </p>

                                    <p
                                        onClick={() => (
                                            setModalEliminar({
                                                visible: false,
                                                index: 0,
                                            }),
                                            handleEnable(item.id)
                                        )}
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Eliminar
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <div className="w-full my-1">
                <h2
                    className={`text-lg font-bold cursor-pointer flex items-center  ${
                        activeAccordion === 5
                            ? "bg-slate-100 hover:bg-gray-50"
                            : "bg-gray-50 hover:bg-gray-100"
                    }`}
                    onClick={() => handleAccordionClick(5)}>
                    {activeAccordion !== 5 ? (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(90 12 12)"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-5 h-5 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path
                                d="M18 15l-6-6l-6 6h12"
                                transform="rotate(180 12 12)"
                            />
                        </svg>
                    )}
                    Perdidos ({column6.length})
                </h2>
                {activeAccordion === 5 &&
                    column6.map((item) => (
                        <div
                            key={item.id}
                            className="relative flex items-center justify-between gap-2 p-2 mt-2 border border-gray-100 rounded-lg ">
                            <div
                                onClick={() =>
                                    navigate(`/dashboard-admin/crm/${item.id}`)
                                }
                                className="flex items-center gap-2 cursor-pointer hover:scale-105">
                                <h1 className="">{`${item.nombre} ${item.apellido}`}</h1>
                                <div className="flex items-center gap-1">
                                    <p
                                        style={{
                                            backgroundColor: getColorForTag(
                                                item.etiqueta
                                            ),
                                        }}
                                        className={`w-3 h-3 rounded-full `}></p>
                                    <p className="text-xs text-gray-400">
                                        {item.etiqueta}
                                    </p>
                                </div>
                                <span className="text-xs text-gray-400">
                                    Tipo : # {item.tipo}
                                </span>
                            </div>

                            <div
                                onMouseLeave={() =>
                                    setModalEliminar({
                                        visible: false,
                                        index: 0,
                                    })
                                }
                                className="bg-red-500">
                                <span
                                    onClick={() =>
                                        setModalEliminar({
                                            visible: true,
                                            index:
                                                item.id === modalEliminar.index
                                                    ? 0
                                                    : item.id,
                                        })
                                    }
                                    className={`absolute right-3 top-3  cursor-pointer `}
                                    aria-label="close">
                                    <svg
                                        className="w-5 h-5 text-black"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="12" cy="12" r="1" />{" "}
                                        <circle cx="12" cy="5" r="1" />{" "}
                                        <circle cx="12" cy="19" r="1" />
                                    </svg>
                                </span>
                                <div
                                    className={
                                        modalEliminar.visible === true &&
                                        modalEliminar.index === item.id
                                            ? "absolute bg-white  right-1 top-8 border rounded-lg  p-2 z-10"
                                            : "hidden"
                                    }>
                                    <p
                                        onClick={() =>
                                            navigate(
                                                `/dashboard-admin/crm/${item.id}`
                                            )
                                        }
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Detalle
                                    </p>

                                    <p
                                        onClick={() => (
                                            setModalEliminar({
                                                visible: false,
                                                index: 0,
                                            }),
                                            setEstadoEdit({
                                                id: item.id,
                                                nombre: "Perdidos",
                                            })
                                        )}
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Actualizar
                                    </p>

                                    <p
                                        onClick={() => (
                                            setModalEliminar({
                                                visible: false,
                                                index: 0,
                                            }),
                                            handleEnable(item.id)
                                        )}
                                        className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                        Eliminar
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Accordion;
