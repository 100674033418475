import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";

const AuthGoogle = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        getAuthGoogle();
    }, []);

    const getAuthGoogle = async () => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get("code");
        const state = queryParams.get("state");

        const response = await fetchAxiosToken({
            url: `/google_calendar/auth/google/callback?state=${state}&code=${code}`,
            method: "get",
        });

        navigate("/dashboard/configuraciones/conexiones-externas");
    };

    return <div>Autorizacion exitosa!! </div>;
};

export default AuthGoogle;
