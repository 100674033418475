import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import BotonInformacion from "../../components/helpers/BotonInformacion";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import useHeaders from "../../hooks/useHeaders";
import { verifyToken } from "../../redux/reducers/authReducer";
import { NavbarAdministrativa } from "../dashboardAdministrativo/NavbarAdministrativa";
import { NavbarAdministrativaMobile } from "../dashboardAdministrativo/NavbarAdministrativaMobile";
import { Navbar } from "./Navbar";
import { NavbarMobile } from "./NavbarMobile";

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

interface Props {
    isAdmin?: boolean;
}

export const MicuentaBancaria = ({ isAdmin = false }: Props) => {
    const {
        pais_banco,
        banco,
        tipo_cuenta,
        tipo_moneda,
        numero_cuenta,
        cci,
        titular_cuenta,
        tipo_documento,
        numero_documento,
        id,
    } = useAppSelector((state) => state.usuario_logeado);

    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        pais_banco,
        banco,
        tipo_cuenta,
        tipo_moneda,
        numero_cuenta,
        cci,
        titular_cuenta,
        tipo_documento,
        numero_documento,
    });

    const dispatch = useAppDispatch();
    const { headers } = useHeaders();
    const bancosSelectPeru = [
        "BBVA",
        "Banco de Comercio",
        "Banco de Crédito del Perú",
        "Banco Interamericano de Finanzas (BanBif)",
        "Banco Pichincha",
        "Citibank Perú",
        "Interbank",
        "MiBanco",
        "Scotiabank Perú",
        "Banco GNB Perú",
        "Banco Falabella",
        "Banco Ripley",
        "Banco Santander Perú",
        "Alfin Banco",
        "Bank of China",
        "ICBC PERU BANK",
    ];

    const bancosSelectEspana = [
        "Banco Santander",
        "Banco Bilbao Vizcaya Argentaria (BBVA)",
        "CaixaBank",
        "Banco Sabadell",
        "Bankia",
        "Banco Popular",
        "Bankinter",
        "Kutxabank",
        "Abanca",
        "Unicaja Banco",
        "Liberbank",
        "Ibercaja Banco",
        "Caja Rural",
        "Banco Mare Nostrum (BMN)",
        "Banco de Crédito Social Cooperativo (Cajamar)",
        "Banco Cooperativo Español",
        "Caja Rural del Sur (Cajamar)",
        "Caja Rural de Navarra",
        "Caja Laboral Popular (Laboral Kutxa)",
        "Banco de Valencia",
        "Banco de Castilla-La Mancha (CCM)",
        "Banco de Crédito Balear (Banco de Mallorca)",
        "Banca March",
        "Banco de Guayaquil España",
        "Banco Cooperativo Español",
        "Banca Pueyo",
        "Banco de Madrid",
        "Banco Finantia Sofinloc",
        "Banco Alcalá",
        "Banco Pichincha España",
    ];

    const bancosSelectColombia = [
        "Bancolombia",
        "Banco de Bogotá",
        "Grupo Aval Acciones y Valores (GAA)",
        "Banco Davivienda",
        "Banco Popular",
        "Banco de Occidente",
        "Banco Caja Social",
        "BBVA Colombia",
        "Banco GNB Sudameris",
        "Banco de la República (Banco Central de Colombia)",
        "Bancoomeva",
        "Banco Agrario de Colombia",
        "Banco Falabella Colombia",
        "Citibank Colombia",
        "Banco Colpatria",
        "Banco Cooperativo Coopcentral",
        "Banco CorpBanca Colombia",
        "Banco Finandina",
        "Banco Mundo Mujer",
        "Banco W",
        "Banco Pichincha Colombia",
        "Banco Santander Colombia",
        "Banco AV Villas",
        "Banco Procredit Colombia",
        "Bancoomeva",
        "Banco Serfinansa",
        "Banco Corpbanca",
        "Banco Cooperativo Coopcentral",
        "Banco de Comercio Exterior de Colombia (Bancóldex)",
    ];

    const bancosSelectMexico = [
        "BBVA Bancomer",
        "Banco Santander México",
        "Banorte",
        "Citibanamex",
        "HSBC México",
        "Scotiabank México",
        "Inbursa",
        "Banco Azteca",
        "Banco del Bajío",
        "Banco Afirme",
        "Banco Inbursa",
        "Banco Invex",
        "Banco Interacciones",
        "Banco Mifel",
        "Banco Ahorro Famsa",
        "Banco Compartamos",
        "Banco Multiva",
        "Banco Ve Por Más (Bx+)",
        "BanCoppel",
        "BanRegio",
        "Bancrea",
        "Banco Autofin México",
        "Banco Monex",
        "Bankaool",
        "Banco Base",
        "ABC Capital",
        "Banco Actinver",
        "Banco Forjadores",
        "Banco Regional de Monterrey",
        "Banco Ahorro Citibanamex",
    ];

    const documento = [
        "Documento de identidad",
        "Pasaporte",
        "Cédula Extranjera",
    ];
    const monedasPeru = ["PEN", "USD"];
    const monedasEspana = ["EUR", "USD"];
    const monedasMexico = ["MXN", "USD"];
    const monedasColombia = ["COP", "USD"];
    const tipoCuenta = ["Ahorros", "Corriente"];

    const INPUTS = [
        {
            input_id: 1,
            label: "Número de cuenta",
            nombre: "numero_cuenta",
            type: "text",
        },
        {
            input_id: 2,
            label: "Número de cuenta interbancaria",
            nombre: "cci",
            type: "text",
        },
        {
            input_id: 3,
            label: "Nombre del titular",
            nombre: "titular_cuenta",
            type: "text",
        },
    ];
    const BANCO = {
        input_id: 0,
        label: "Banco",
        nombre: "banco",
        optionPeru: bancosSelectPeru,
        optionEspana: bancosSelectEspana,
        optionColombia: bancosSelectColombia,
        optionMexico: bancosSelectMexico,
    };

    const INPUTSELECT = [
        {
            input_id: 1,
            label: "Tipo de cuenta",
            nombre: "tipo_cuenta",
            option: tipoCuenta,
        },
        {
            input_id: 2,
            label: "Moneda",
            nombre: "tipo_moneda",
            optionPeru: monedasPeru,
            optionEspana: monedasEspana,
            optionMexico: monedasMexico,
            optionColombia: monedasColombia,
        },
        {
            input_id: 3,
            label: "Tipo de documento",
            nombre: "tipo_documento",
            option: documento,
        },
    ];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const handleInputSelectChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        console.log(e.target.name, e.target.value);
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = async () => {
        const form = new FormData();

        const nullAttributes = [];
        for (const key in userInfo) {
            if (userInfo[key] === null || userInfo[key] === "") {
                nullAttributes.push(key);
            }
        }
        if (nullAttributes.length) {
            Swal.fire({
                icon: "error",
                title: `Debes completar todos los campos`,
                showConfirmButton: true,
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
            });
            return;
        }
        console.log(userInfo);
        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/usuarios/updateBanco/${id}`,
                userInfo,
                { headers }
            );
            console.log(data);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Tus datos han sido guardados correctamente!",
                showConfirmButton: false,
                timer: 1500,
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
            });
            dispatch(verifyToken());
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="flex">
            <>
                {/* <Navbar> */}
                <div className="flex flex-col-reverse items-center justify-end w-full mt-10 sm:mb-0">
                    <div className="flex flex-col items-center w-full pb-6">
                        <div className="relative flex items-center justify-center">
                            <h1 className="mb-5 mr-1 text-xl font-bold text-[#4D4D4D] ">
                                Cuenta Bancaria
                            </h1>
                            <BotonInformacion text="Ingresa tu cuenta bancaria, para que los clientes puedan realizarte una transferencia!" />
                        </div>

                        <div className="p-6 border-[#CCCCCC] border-2 rounded-xl text-[#4D4D4D]">
                            <div className="w-full max-w-xl mb-2">
                                <label className="mb-1 font-medium">País</label>
                                <select
                                    onChange={handleInputSelectChange}
                                    name="pais_banco"
                                    value={userInfo.pais_banco || ""}
                                    className="w-full 2xl:p-2 p-1.5 2xl:py-[9px] mt-1 border-2 bg-white border-[#CCCCCC]  rounded-lg">
                                    <option className="bg-transparent" value="">
                                        {" "}
                                        Seleccionar{" "}
                                    </option>
                                    <option
                                        key="colombia"
                                        className="bg-transparent"
                                        value="Colombia">
                                        Colombia
                                    </option>
                                    <option
                                        key="españa"
                                        className="bg-transparent"
                                        value="España">
                                        España
                                    </option>
                                    <option
                                        key="mexico"
                                        className="bg-transparent"
                                        value="México">
                                        México
                                    </option>
                                    <option
                                        key="peru"
                                        className="bg-transparent"
                                        value="Perú">
                                        Perú
                                    </option>
                                </select>
                            </div>

                            <div className="w-full max-w-xl mb-2">
                                <label className="mb-1 font-medium">
                                    {BANCO.label}
                                </label>
                                <select
                                    onChange={handleInputSelectChange}
                                    name={BANCO.nombre}
                                    value={userInfo[BANCO.nombre] || ""}
                                    className="w-full 2xl:p-2 p-1.5 2xl:py-[9px] mt-1 border-2 bg-white border-[#CCCCCC]  rounded-lg">
                                    <option className="bg-transparent" value="">
                                        {" "}
                                        Seleccionar{" "}
                                    </option>

                                    {userInfo.pais_banco === "Perú" &&
                                        BANCO.optionPeru.map((banco, i) => {
                                            return (
                                                <option
                                                    key={i}
                                                    className="bg-transparent"
                                                    value={banco}>
                                                    {" "}
                                                    {banco}{" "}
                                                </option>
                                            );
                                        })}

                                    {userInfo.pais_banco === "España" &&
                                        BANCO.optionEspana.map((banco, i) => {
                                            return (
                                                <option
                                                    key={i}
                                                    className="bg-transparent"
                                                    value={banco}>
                                                    {" "}
                                                    {banco}{" "}
                                                </option>
                                            );
                                        })}

                                    {userInfo.pais_banco === "Colombia" &&
                                        BANCO.optionColombia.map((banco, i) => {
                                            return (
                                                <option
                                                    key={i}
                                                    className="bg-transparent"
                                                    value={banco}>
                                                    {" "}
                                                    {banco}{" "}
                                                </option>
                                            );
                                        })}

                                    {userInfo.pais_banco === "México" &&
                                        BANCO.optionMexico.map((banco, i) => {
                                            return (
                                                <option
                                                    key={i}
                                                    className="bg-transparent"
                                                    value={banco}>
                                                    {" "}
                                                    {banco}{" "}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>

                            <div className="grid max-w-xl grid-cols-2 lg:gap-x-8 gap-x-4">
                                {INPUTSELECT.map(
                                    ({
                                        nombre,
                                        input_id,
                                        label,
                                        optionPeru,
                                        optionColombia,
                                        optionEspana,
                                        optionMexico,
                                        option,
                                    }) => (
                                        <div key={input_id} className="">
                                            <label className="mb-1 font-medium">
                                                {label}
                                            </label>
                                            <select
                                                onChange={
                                                    handleInputSelectChange
                                                }
                                                name={nombre}
                                                value={userInfo[nombre] || ""}
                                                className="w-full 2xl:p-2 p-1.5 2xl:py-[9px] mt-1 border-2 bg-white  border-[#CCCCCC]  rounded-lg">
                                                <option
                                                    className="bg-transparent"
                                                    value="">
                                                    {" "}
                                                    Seleccionar{" "}
                                                </option>

                                                {(nombre === "tipo_documento" ||
                                                    nombre === "tipo_cuenta") &&
                                                    option?.map((banco, i) => {
                                                        return (
                                                            <option
                                                                key={i}
                                                                className="bg-transparent"
                                                                value={banco}>
                                                                {" "}
                                                                {banco}{" "}
                                                            </option>
                                                        );
                                                    })}

                                                {userInfo.pais_banco ===
                                                    "Perú" &&
                                                    optionPeru?.map(
                                                        (banco, i) => {
                                                            return (
                                                                <option
                                                                    key={i}
                                                                    className="bg-transparent"
                                                                    value={
                                                                        banco
                                                                    }>
                                                                    {" "}
                                                                    {banco}{" "}
                                                                </option>
                                                            );
                                                        }
                                                    )}

                                                {userInfo.pais_banco ===
                                                    "España" &&
                                                    optionEspana?.map(
                                                        (banco, i) => {
                                                            return (
                                                                <option
                                                                    key={i}
                                                                    className="bg-transparent"
                                                                    value={
                                                                        banco
                                                                    }>
                                                                    {" "}
                                                                    {banco}{" "}
                                                                </option>
                                                            );
                                                        }
                                                    )}

                                                {userInfo.pais_banco ===
                                                    "Colombia" &&
                                                    optionColombia?.map(
                                                        (banco, i) => {
                                                            return (
                                                                <option
                                                                    key={i}
                                                                    className="bg-transparent"
                                                                    value={
                                                                        banco
                                                                    }>
                                                                    {" "}
                                                                    {banco}{" "}
                                                                </option>
                                                            );
                                                        }
                                                    )}

                                                {userInfo.pais_banco ===
                                                    "México" &&
                                                    optionMexico?.map(
                                                        (banco, i) => {
                                                            return (
                                                                <option
                                                                    key={i}
                                                                    className="bg-transparent"
                                                                    value={
                                                                        banco
                                                                    }>
                                                                    {" "}
                                                                    {banco}{" "}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                            </select>
                                        </div>
                                    )
                                )}
                                <div className="w-full max-w-xl">
                                    <label className="mb-1 font-medium">
                                        Documento
                                    </label>
                                    <input
                                        className="w-full 2xl:p-2 p-1.5 2xl:py-[9px] mt-1 border-2 bg-white border-[#CCCCCC]  rounded-lg"
                                        placeholder={"Documento"}
                                        name={"numero_documento"}
                                        value={userInfo.numero_documento || ""}
                                        onChange={handleInputChange}
                                        type={"text"}
                                    />
                                </div>
                            </div>

                            {INPUTS.map(({ nombre, input_id, type, label }) => (
                                <div key={input_id} className="w-full max-w-xl">
                                    <label className="mb-1 font-medium">
                                        {label}
                                    </label>
                                    <input
                                        className="w-full 2xl:p-2 p-1.5 mt-1 border-2  border-[#CCCCCC] rounded-lg"
                                        placeholder={label}
                                        name={nombre}
                                        value={userInfo[nombre] || ""}
                                        onChange={handleInputChange}
                                        type={type}
                                    />
                                </div>
                            ))}
                            <button
                                onClick={() => handleSave()}
                                className="w-full max-w-xl p-3 mt-4 font-medium text-white border rounded-lg bg-[#029BFC] hover:scale-105 active:translate-y-1 2xl:text-base text-sm ">
                                Guardar cambios
                            </button>
                        </div>
                    </div>
                </div>
                {/* </Navbar>
                    <NavbarMobile /> */}
            </>
        </div>
    );
};
