import React, { useEffect, useState } from "react";
import { InputDate } from "../../../../components/InputDate";
import ResponsiveModalAbsolute from "../../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../../helpers/fetchAxiosToken";
import { ShowDateToUser } from "../../../../components/ShowDateToUser";
import { useAppSelector } from "../../../../hooks/hooks";
import { tarifa } from "./TarifasAdicionales";
import { EditarTarifa } from "./EditarTarifa";
import { EditarTarifaAvanzada } from "./EditarTarifaAvanzada";

export interface tarifaAvanzada {
    fecha_inicio?: string | undefined;
    fecha_final?: string | undefined;
    precio?: string;
    id?: number;
    id_servicios_adicionales?: number;
}

interface ModalProps {
    tarifaSelected: tarifa | null;
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    getTarifas: () => void;
}

type errorObject = { [key: string]: string };

export const TarifaAvanzada = ({
    modalVisible,
    setModalVisible,
    getTarifas,
    tarifaSelected,
}: ModalProps) => {
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState<tarifaAvanzada>({
        fecha_inicio: "",
        fecha_final: "",
        precio: "",
    });
    const [modalAvanzado, setModalAvanzado] = useState(false);
    const [modalEditAvanzado, setModalEditAvanzado] = useState(false);
    const [tarifasAvanzadas, setTarifasAvanzadas] =
        useState<tarifaAvanzada[]>();
    const [tarifaAvanzadaSelected, setTarifaAvanzadaSelected] =
        useState<tarifaAvanzada | null>(null);
    const { tipo_moneda } = useAppSelector(
        (state) => state.landing_house.house
    );
    const [errors, setErrors] = useState<tarifaAvanzada>({});

    const getTarifasAvanzadas = async () => {
        const data = await fetchAxiosToken({
            url: `/servicios_adicionales_avanzado/getAll/${tarifaSelected?.id}`,
        });

        setTarifasAvanzadas(data);
    };

    useEffect(() => {
        if (tarifaSelected) {
            getTarifasAvanzadas();
        }
    }, [tarifaSelected]);

    const handleEdit = (tarifaAvanzada: tarifaAvanzada) => {
        setTarifaAvanzadaSelected(tarifaAvanzada);
        setModalEditAvanzado(true);
    };

    const handleDelete = async (id: number | undefined) => {
        await fetchAxiosToken({
            url: `/servicios_adicionales_avanzado/delete/${id}`,
            method: "delete",
        });

        getTarifasAvanzadas();
    };

    const validateInput = (form: tarifaAvanzada) => {
        let errores: errorObject = {};

        if (!form.fecha_inicio) {
            errores.fecha_inicio = "Debe ingresar una fecha inicial";
        } else if (!form.fecha_final) {
            errores.fecha_final = "Debe ingresar una fecha final";
        } else if (!form.precio || Number(form.precio) < 0) {
            errores.precio = "Debe ingresar un precio válido";
        }

        return errores;
    };

    const handleSubmit = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            try {
                setLoading(true);
                const data = await fetchAxiosToken({
                    url: `/servicios_adicionales_avanzado`,
                    body: {
                        id_servicios_adicionales: tarifaSelected?.id,
                        ...input,
                    },
                    method: "post",
                });

                setLoading(false);
                setInput({
                    fecha_final: "",
                    fecha_inicio: "",
                    precio: "",
                });
                setModalAvanzado(false);
                setErrors({});
                getTarifasAvanzadas();
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            onClose={() => {
                setErrors({});
            }}
        >
            <div className="flex flex-col gap-2 p-3">
                <h1 className="2xl:text-lg text-base font-medium">
                    Configuración avanzada de:
                </h1>
                <div className="p-4 border rounded-lg">
                    <span className="flex justify-between 2xl:text-base text-sm">
                        <div className="font-medium">
                            {tarifaSelected?.nombre}
                        </div>
                        <div className="italic text-black text-end">
                            {tarifaSelected?.precio} {tipo_moneda} (
                            {tarifaSelected?.opcional === "Si"
                                ? "Opcional"
                                : "Obligatoria"}
                            )
                        </div>
                    </span>
                </div>
                <div className="flex justify-between mt-6 font-medium">
                    <span className="2xl:text-base text-sm">
                        Configuraciones avanzadas
                    </span>
                    <button
                        onClick={() => setModalAvanzado((prev) => !prev)}
                        className="flex items-center gap-1 p-2 border rounded-lg border-blue-blueFrom text-blue-blueFrom hover:scale-105"
                    >
                        <svg
                            className="h-6 w-6 mt-0.5 text-blue-blueFrom"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        {modalAvanzado ? (
                            <span className="min-w-max 2xl:text-base text-sm">
                                Cerrar adicional
                            </span>
                        ) : (
                            <span className="min-w-max 2xl:text-base text-sm">
                                Tarifa adicional
                            </span>
                        )}
                    </button>
                </div>
                <div
                    className={`${
                        modalAvanzado
                            ? "h-[22rem] opacity-100"
                            : "h-0 opacity-0 invisible"
                    } flex flex-col gap-1 transition-all duration-300 ease-in-out  border-dashed border-blue-blueFrom 2xl:mt-4 p-3`}
                >
                    <label className="2xl:text-base text-sm">
                        Fecha inicial
                    </label>
                    <InputDate
                        className="border-2 2xl:text-base text-sm 2xl:p-3 p-2"
                        selectedDay={input.fecha_inicio}
                        placeholderText="13/05/2023"
                        onChange={(date) =>
                            setInput({
                                ...input,
                                fecha_inicio: date,
                            })
                        }
                    />
                    <label className="2xl:text-base text-sm">Fecha final</label>
                    <InputDate
                        className="border-2 2xl:text-base text-sm 2xl:p-3 p-2"
                        selectedDay={input.fecha_final}
                        placeholderText="13/08/2023"
                        onChange={(date) =>
                            setInput({
                                ...input,
                                fecha_final: date,
                            })
                        }
                    />
                    <label className="2xl:text-base text-sm">Precio</label>
                    <input
                        value={input.precio}
                        onChange={(e) =>
                            setInput({
                                ...input,
                                precio: e.target.value,
                            })
                        }
                        type={"text"}
                        className="2xl:text-base text-sm 2xl:p-3 p-2 border-2 rounded-lg"
                        placeholder="250.00"
                    />
                    <button
                        disabled={loading}
                        onClick={() => handleSubmit()}
                        className="max-w-xs p-3 mt-4 border-2 rounded-lg hover:bg-blue-blueFrom hover:text-white disabled:bg-gray-400 disabled:translate-y-0 active:translate-y-1 mx-auto 2xl:text-base text-sm"
                    >
                        Agregar configuración
                    </button>
                </div>
                {tarifasAvanzadas && tarifasAvanzadas?.length > 0 ? (
                    <>
                        {tarifasAvanzadas.map((tarifaAvanzada, index) => (
                            <div
                                key={tarifaAvanzada.id}
                                className="flex justify-between w-full p-3 border rounded-lg"
                            >
                                <div className="flex flex-col 2xl:text-base text-sm">
                                    <b>Tarifa {index + 1}</b>
                                    <div className="flex items-center">
                                        <span>
                                            Desde el{" "}
                                            <span className="font-medium">
                                                <ShowDateToUser
                                                    date={
                                                        tarifaAvanzada.fecha_inicio
                                                    }
                                                />
                                            </span>{" "}
                                            al{" "}
                                            <span className="font-medium">
                                                {
                                                    <ShowDateToUser
                                                        date={
                                                            tarifaAvanzada.fecha_final
                                                        }
                                                    />
                                                }
                                            </span>
                                        </span>
                                        <span className="ml-1.5 text-gray-500 italic">
                                            Valor: {tarifaAvanzada.precio}{" "}
                                            {tipo_moneda}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex items-baseline gap-3">
                                    <button
                                        title="Editar tarifa"
                                        onClick={() =>
                                            handleEdit(tarifaAvanzada)
                                        }
                                    >
                                        <svg
                                            className="cursor-pointer 2xl:h-7 2xl:w-7 h-6 w-6 text-blue-blueFrom hover:scale-105"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                            />
                                        </svg>
                                    </button>

                                    <button
                                        onClick={() =>
                                            handleDelete(tarifaAvanzada.id)
                                        }
                                        title="Eliminar tarifa"
                                    >
                                        <svg
                                            className="text-red-400 cursor-pointer 2xl:h-7 2xl:w-7 h-6 w-6 hover:scale-105"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <polyline points="3 6 5 6 21 6" />
                                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                            <line
                                                x1="10"
                                                y1="11"
                                                x2="10"
                                                y2="17"
                                            />
                                            <line
                                                x1="14"
                                                y1="11"
                                                x2="14"
                                                y2="17"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="mt-6 font-medium text-center 2xl:text-base text-sm">
                        Aún no tienes configuraciones avanzadadas en esta tarifa
                    </div>
                )}
                <button
                    onClick={() => {
                        setInput({
                            fecha_final: "",
                            fecha_inicio: "",
                            precio: "",
                        });
                        setModalAvanzado(false);
                        setModalVisible(false);
                    }}
                    className="max-w-xs p-3 mx-auto mt-3 font-medium text-white border rounded-lg bg-[#029BFC] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default 2xl:text-base text-sm"
                >
                    Guardar configuración avanzada
                </button>
            </div>
            <EditarTarifaAvanzada
                modalVisible={modalEditAvanzado}
                setModalVisible={setModalEditAvanzado}
                tarifaSelected={tarifaAvanzadaSelected}
                getTarifas={getTarifasAvanzadas}
            />
        </ResponsiveModalAbsolute>
    );
};
