import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../hooks/hooks";

interface Props {
    widthHeight?: string;
    text: string;
    colorIcono?: string;
    ver?: boolean;
    left?: number;
}

const BotonInformacion = ({
    text,
    colorIcono,
    widthHeight,
    ver = false,
    left = 4,
}: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef<HTMLDivElement>(null);
    const { mostrar } = useAppSelector((state) => state.informacion);

    const handleIconClick = (e: any) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    const onHandleBlur = (e: any) => {
        console.log("hola!");
        e.stopPropagation();
        setIsOpen(false);
    };

    return (
        <div className={mostrar || ver ? "overflow-visible" : "hidden"}>
            <div
                ref={buttonRef}
                className={`${
                    widthHeight ? widthHeight : "w-6 h-6"
                } rounded-2xl flex items-center cursor-pointer duration-200 ease-linear select-none `}
                onClick={handleIconClick}
                onMouseLeave={onHandleBlur}
            >
                <svg
                    className={`${widthHeight ? widthHeight : "w-6 h-6"} ${
                        colorIcono ? colorIcono : "text-blue-500"
                    } `}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="12" cy="12" r="10" />
                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                    <line x1="12" y1="17" x2="12.01" y2="17" />
                </svg>
            </div>
            {isOpen && (
                <div
                    className={`absolute px-2 py-1 text-xs text-white bg-blue-500 rounded-lg  left-${left}`}
                    title="Información adicional"
                >
                    {text}
                </div>
            )}
        </div>
    );
};

export default BotonInformacion;
