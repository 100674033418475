import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BurguerMenuMobile } from "./BurguerMenuMobile";
import { useAppSelector } from "../../hooks/hooks";

const TITLES = [
    {
        id: 1,
        title: "Calendario",
        url: "calendario",

        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 99.99">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m50.06,35.65h48.35c1.58,0,1.59,0,1.59,1.59,0,17.23,0,34.46,0,51.69,0,6.41-4.61,11.05-11,11.05-25.99,0-51.99,0-77.99,0C4.67,99.98.01,95.35.01,89.04,0,71.73,0,54.43,0,37.12c0-1.46,0-1.47,1.48-1.47h48.57m-25.01,17.99c-1.11,0-2.23-.03-3.34,0-2.16.07-3.73,1.58-3.71,3.51.03,1.85,1.62,3.39,3.68,3.44,2.22.05,4.45.05,6.67,0,2.1-.04,3.75-1.64,3.71-3.51-.03-1.85-1.63-3.37-3.68-3.44-1.11-.04-2.23,0-3.34,0m24.9,6.97c1.11,0,2.23.03,3.34,0,2.11-.06,3.75-1.61,3.74-3.49-.01-1.88-1.65-3.43-3.76-3.46-2.19-.04-4.38-.04-6.57,0-2.17.04-3.74,1.54-3.73,3.48,0,1.88,1.64,3.41,3.76,3.47,1.07.03,2.15,0,3.23,0m25.11-6.97c-1.15,0-2.3-.03-3.45,0-2.16.07-3.73,1.6-3.69,3.52.04,1.86,1.63,3.38,3.69,3.43,2.22.05,4.45.05,6.67,0,2.1-.04,3.74-1.66,3.7-3.53-.05-1.86-1.64-3.36-3.69-3.42-1.07-.04-2.15,0-3.23,0m-50.03,21.39c-1.11,0-2.23-.03-3.34,0-2.15.07-3.73,1.59-3.7,3.52.04,1.86,1.63,3.39,3.69,3.43,2.22.05,4.45.05,6.67,0,2.1-.04,3.75-1.65,3.7-3.52-.04-1.85-1.64-3.36-3.69-3.43-1.11-.03-2.22,0-3.34,0m25.01,6.97c1.07,0,2.15.03,3.23,0,2.11-.06,3.75-1.59,3.75-3.48,0-1.88-1.64-3.44-3.75-3.48-2.19-.04-4.38-.04-6.57,0-2.18.04-3.74,1.52-3.75,3.47,0,1.88,1.63,3.43,3.74,3.49,1.11.03,2.23,0,3.34,0m24.86,0c1.11,0,2.23.03,3.34,0,2.11-.06,3.75-1.61,3.74-3.49-.01-1.89-1.65-3.43-3.77-3.46-2.19-.04-4.38-.04-6.57,0-2.17.04-3.74,1.54-3.73,3.48,0,1.89,1.64,3.41,3.76,3.47,1.07.03,2.15,0,3.23,0"
                        />
                        <path
                            fill="white"
                            d="m49.92,28.68H1.79Q0,28.68,0,26.84C0,23.76-.01,20.68.02,17.6.06,11.93,4.81,7.18,10.48,7.14c4.38-.03,8.76-.04,13.15.01,1.1.01,1.46-.34,1.35-1.4-.07-.7-.02-1.41,0-2.12C25.02,1.48,26.52-.01,28.64,0c2.04.01,3.56,1.52,3.61,3.59.02.85.04,1.71,0,2.56-.03.72.24,1.01.97.98,1.11-.04,2.23,0,3.34,0h29.74q1.45,0,1.45-1.48c0-.63,0-1.26,0-1.89C67.79,1.5,69.26,0,71.42,0c2.12,0,3.59,1.56,3.62,3.81,0,.7.05,1.41,0,2.11-.08.92.26,1.23,1.19,1.22,4.23-.04,8.46-.02,12.7-.02,6.47.01,11.06,4.61,11.08,11.09,0,2.93,0,5.86,0,8.8q0,1.66-1.63,1.66h-48.45"
                        />
                    </g>
                </g>
            </svg>
        ),
    },

    {
        id: 4,
        title: "Reseñas",
        url: "reviews",

        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 99.76 95.45">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m55.13,3.27l12.24,24.79,27.36,3.98c4.81.7,6.73,6.61,3.25,10l-19.8,19.3,4.67,27.25c.82,4.79-4.2,8.44-8.5,6.18l-24.47-12.87-24.47,12.87c-4.3,2.26-9.32-1.39-8.5-6.18l4.67-27.25L1.78,42.03c-3.48-3.39-1.56-9.3,3.25-10l27.36-3.98L44.62,3.27c2.15-4.36,8.36-4.36,10.51,0Z"
                    />
                </g>
            </svg>
        ),
    },

    // {
    //     id: 7,
    //     title: "Redes sociales",
    //     url: "redes-sociales",

    //     icono: (
    //         <svg
    //             className=" w-7 h-7"
    //             id="Capa_2"
    //             data-name="Capa 2"
    //             xmlns="http://www.w3.org/2000/svg"
    //             viewBox="0 0 90.97 99.99">
    //             <g id="Testimonios">
    //                 <path
    //                     fill="white"
    //                     d="m72.65,36.48c-4.66,0-8.73-1.57-12.18-4.7-.51-.47-.88-.51-1.47-.16-7.42,4.36-14.85,8.7-22.29,13.02-.57.33-.81.67-.64,1.36.65,2.66.65,5.33,0,7.99-.16.67.02,1.04.61,1.37,7.47,4.34,14.94,8.68,22.4,13.05.56.33.89.26,1.37-.16,5.4-4.71,11.6-6.02,18.31-3.67,6.82,2.4,10.86,7.45,11.98,14.57,1.57,10.01-5.6,19.35-15.67,20.67-9.87,1.3-18.92-5.5-20.45-15.38-.35-2.23-.27-4.45.26-6.64.18-.73-.03-1.11-.64-1.47-7.41-4.3-14.82-8.61-22.2-12.96-.79-.47-1.22-.12-1.75.35-3.32,2.94-7.25,4.46-11.63,4.53-5.48.08-10.24-1.87-13.88-6.01C-3.82,52.48-.39,37.82,11.7,33.03c6.55-2.59,12.72-1.42,18.26,2.96,1.42,1.13,1,1.24,2.7.25,7.07-4.12,14.13-8.25,21.21-12.34.91-.52,1.16-1.02.96-2.1C52.63,9.98,61.56-.36,73.59,0c8.91.27,16.44,7.61,17.28,16.6.87,9.34-5.68,17.76-14.41,19.52-1.25.25-2.51.41-3.8.35Z"
    //                 />
    //             </g>
    //         </svg>
    //     ),
    // },
    {
        id: 8,
        title: "Ayuda",
        url: "ayuda",

        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 83.02 99.97">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m5.82,99.97c-1.12-.44-2.26-.84-3.17-1.67-2.29-2.08-3.11-4.62-2.41-7.66,3.81-16.64,17.64-29.6,34.48-32.31,1.34-.22,2.69-.32,4.05-.52-4.12-.74-7.81-2.36-10.96-5.11-4.07-3.55-6.26-8.07-6.78-13.4-.91-9.31,4.88-18.25,13.73-21.32,12.2-4.23,25.12,3.58,27.14,16.37.47,2.94.28,5.81-.53,8.67-.22.76-.61,1.02-1.39,1.02-4.03-.03-8.06-.03-12.09,0-1.66,0-2.76,1.07-2.82,2.61-.07,1.73,1.02,2.94,2.76,2.96,2.96.03,5.92,0,8.87.02.34,0,.8-.16,1,.18.22.39-.23.63-.44.88-3.16,3.8-7.18,6.12-12.04,7-.13.02-.25.05-.38.08-.02,0-.02.05-.07.15,1.92.14,3.8.39,5.66.8,12.42,2.72,21.59,9.83,27.95,20.71,2.19,3.76,3.81,7.77,4.55,12.07.62,3.67-1.55,7.04-5.08,8.19-.28.09-.55.19-.82.29H5.82Z"
                        />
                        <path
                            fill="white"
                            d="m79.03,37.44c0,2.08.01,4.16,0,6.24-.03,5.03-3.9,9.1-8.91,9.3-2.14.09-4.29.02-6.43.02-.24,0-.56.1-.7-.19-.13-.28.14-.47.28-.68,5.93-8.83,5.96-20.41.07-29.32-.15-.23-.48-.46-.33-.76.16-.3.53-.16.8-.16,1.27-.01,2.54,0,3.8-.01.31,0,.73.16.91-.13.2-.31-.14-.6-.3-.86-1.89-3.12-4.21-5.85-7.08-8.11-4.96-3.91-10.59-6.19-16.9-6.6-12.59-.8-22.45,3.99-29.35,14.65-.18.28-.59.6-.37.93.2.3.67.12,1.01.12,1.3.02,2.6.02,3.9,0,.68,0,.83.19.42.78-3.59,5.09-4.81,10.81-4.4,16.92.3,4.49,1.67,8.66,4.23,12.4.17.25.55.49.37.84-.17.34-.59.2-.89.19-2.5-.07-5.01.23-7.49-.18-4.27-.7-7.6-4.53-7.66-8.85-.05-4.35-.02-8.71,0-13.07,0-1.42.32-2.8,1.06-4.01.83-1.34,1.34-2.81,1.97-4.23C12.73,10.08,22.35,2.47,36.02.46c12.22-1.79,22.98,1.65,31.93,10.25,4,3.84,6.81,8.5,8.77,13.68.16.42.29.88.54,1.25,1.38,1.94,1.88,4.09,1.78,6.45-.07,1.78-.01,3.57-.01,5.36Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
];

export const NavbarMobile = () => {
    const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false);
    const navigate = useNavigate();
    const { plan } = useAppSelector((state) => state.landing_house.house);
    const { avatar, nombre, apellido, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );

    const handleNavigate = (title: string) => {
        navigate(`/dashboard/${title}`);
    };

    const ocultar = (titulo: string) => {
        const nombres = [
            "personas",
            "disponibilidad-maxima",
            "descuento-estadia",
            "cupones",
            "Chat",
            "CRM",
            "Estadisticas",
            "mercadopago",
            "Sub Usuarios",
        ];

        if (nombres.includes(titulo) && plan < 2) {
            return "hidden";
        } else {
            return "";
        }
    };

    const opcionesPremium = (titulo: string) => {
        const nombres = [
            "personas",
            "disponibilidad-maxima",
            "descuento-estadia",
            "cupones",
            "Chat",
            "CRM",
            "Estadisticas",
            "mercadopago",
            "Sub usuarios",
        ];

        // if (nombres.includes(titulo)) {
        //     return true;
        // } else {
        //     return false;
        // }

        if (nombres.includes(titulo) && plan < 2) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <div className="fixed bottom-0 w-full h-12 text-white bg-[#029BFC] sm:hidden ">
                <div className="flex items-center w-full my-auto mt-2 overflow-auto justify-evenly">
                    {TITLES.map((title) => (
                        <div
                            className={`transition ease-in cursor-pointer ${ocultar(
                                title.title
                            )} ${
                                tipo_usuario === 4 && title.id === 6
                                    ? "hidden"
                                    : ""
                            }`}
                            key={title.id}
                            onClick={() => handleNavigate(title.url)}>
                            {title.icono}
                        </div>
                    ))}
                    <svg
                        onClick={() => setShowBurgerMenu((prev) => !prev)}
                        className="w-8 h-8 text-white cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </div>
            </div>
            <BurguerMenuMobile
                setShowBurgerMenu={setShowBurgerMenu}
                showBurgerMenu={showBurgerMenu}
            />
        </>
    );
};
