import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import useHeaders from "../../../hooks/useHeaders";

interface comfort {
    id: number;
    nombre: string;
    icono: string | File;
    habilitado: string;
    fecha_registro: string;
}
interface props {
    comfortEdit: comfort;
    setComfortEdit: (cerrar: undefined) => void;
}

interface OutputImage extends HTMLImageElement {
    src: string;
}

const ModalEditselectedFile = ({ comfortEdit, setComfortEdit }: props) => {
    const [cargando, SetCargando] = useState(false);
    const { headers } = useHeaders();

    const form = new FormData();

    const [selectedFile, setSelectedFile] = useState<comfort>(comfortEdit);

    const handleSubmit = async () => {
        if (!selectedFile.nombre) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error!",
                text: "El atributo debe tener un nombre",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else {
            SetCargando(true);

            form.append("nombre", selectedFile.nombre);
            form.append("comodidad_image", selectedFile.icono);

            try {
                const { data } = await axios.put(
                    `${process.env.REACT_APP_PROXY}/comodidad/update/${selectedFile.id}`,
                    form,
                    { headers }
                );
                console.log(data);
                if (data.message === "actuaizacion exitoso") {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Comodidad guardada con exito!",
                        showConfirmButton: false,
                        timer: 2000,
                    });

                    SetCargando(false);
                    setComfortEdit(undefined);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const output = document.getElementById("output") as OutputImage;
            if (output) {
                output.src = URL.createObjectURL(e.target.files[0]);
                output.onload = function () {
                    URL.revokeObjectURL(output.src);
                };
            }
            // loadFile(e);
            setSelectedFile({ ...selectedFile, icono: e.target.files[0] });
        }
    };

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto sm:py-48">
                    <div className="relative flex flex-col items-center px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 ">
                        <div className="w-full my-3 text-left">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base md:text-xl font-bold ">
                                Editar comodidad
                            </header>
                        </div>

                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                            <input
                                onChange={(e) =>
                                    setSelectedFile({
                                        ...selectedFile,
                                        nombre: e.target.value,
                                    })
                                }
                                type="text"
                                value={selectedFile?.nombre}
                                placeholder="Ej: Horno de barro"
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none placeholder:text-[#3C3C3C]"
                            />
                        </div>

                        <div className="w-full pt-1">
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="">
                                            <div className="mb-2">
                                                <div className="flex items-center justify-center w-full mt-2">
                                                    <div
                                                        className={
                                                            selectedFile.icono ===
                                                            ""
                                                                ? ""
                                                                : "hidden"
                                                        }>
                                                        <label className="mb-3 pl-2 text-[#3C3C3C]  text-base md:text-xl">
                                                            Icono actual
                                                        </label>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        typeof selectedFile.icono ===
                                                        "string"
                                                            ? "w-full flex justify-center items-center mt-2 mb-4 p-2"
                                                            : "hidden"
                                                    }>
                                                    <img
                                                        className={
                                                            typeof selectedFile.icono ===
                                                            "string"
                                                                ? "border border-blue-500 mb-2 h-16 w-16 rounded-lg object-contain p-2 "
                                                                : "hidden"
                                                        }
                                                        alt="imagen de atributo"
                                                        src={
                                                            typeof selectedFile.icono ===
                                                            "string"
                                                                ? `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/comodidad/getImage/` +
                                                                  selectedFile?.icono
                                                                : ""
                                                        }
                                                    />
                                                </div>
                                                <div className="w-full my-3 text-left">
                                                    <label className="mb-3 pl-2 text-[#3C3C3C]  text-base md:text-xl">
                                                        Nuevo icono
                                                    </label>
                                                </div>

                                                <div className="relative flex items-center justify-center h-40 bg-white border border-[#E9E9E9] rounded-lg hover:cursor-pointer ">
                                                    <div className="absolute">
                                                        <div className="flex flex-col items-center ">
                                                            <i className="text-gray-200 fa fa-cloud-upload fa-3x"></i>{" "}
                                                            {selectedFile.icono ===
                                                            "" ? (
                                                                <div>
                                                                    <span className="block font-bold text-[#3C3C3C]">
                                                                        Arrastra
                                                                        el icono
                                                                        aquí
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <span className="block font-normal text-gray-400">
                                                                    {}
                                                                </span>
                                                            )}
                                                            <span className="block mt-3 font-normal text-blue-blueFrom">
                                                                sube la imagen
                                                                desde tu
                                                                dispositivo
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <input
                                                        accept="image/*"
                                                        type="file"
                                                        className="w-full h-full opacity-0 cursor-pointer"
                                                        onChange={
                                                            handleChangeImage
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    className={
                                                        typeof selectedFile.icono ===
                                                        "string"
                                                            ? "hidden"
                                                            : "flex items-center justify-center"
                                                    }>
                                                    <img
                                                        id="output"
                                                        className="w-12 h-12 mt-2 border border-blue-500"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="pb-3 text-center ">
                                            <button
                                                onClick={() => handleSubmit()}
                                                disabled={cargando}
                                                className="w-full p-3 text-center text-white border border-blue-400 rounded-md bg-blue-blueFrom hover:bg-white hover:text-blue-blueFrom disabled:bg-blue-400">
                                                {cargando
                                                    ? "Guardando..."
                                                    : "Guardar"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={() => setComfortEdit(undefined)}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditselectedFile;
