import React, { useState } from "react";
import ButtonCopyToClipboard from "./ButtonCopyToClipboard";
import ModalMessageTopRight from "./ModalMessageTopRight";
import { Coupon } from './Coupon';

interface Prop{

    setModalVisible:(s:boolean)=>void;
    setModalPadre:(s:boolean)=>void;
    cupon:string;
    cuponBlue:boolean
}

const ModalPromoCreada = ({
    setModalVisible,
    setModalPadre,
    cupon,
    cuponBlue = false,
}:Prop) => {
    const [modalTopRight, setModalTopRight] = useState(false);

    const handleClose = () => {
        setModalVisible(false);
        setModalPadre(false);
    };

    return (
        <div className="fixed top-0 left-0 z-[999] flex justify-center w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
            <div className="flex items-center justify-center w-full px-4 py-8 2xl:container 2xl:mx-auto md:px-28 ">
                <div className="relative w-full max-w-sm flex flex-col items-center justify-center p-4 bg-white rounded-lg md:w-96 dark:bg-white h-[calc(100vh-60vh)] overflow-y-auto">
                    {modalTopRight && (
                        <ModalMessageTopRight
                            message="Cupón copiado al portapapeles correctamente"
                            success={true}
                        />
                    )}
                    <button
                        onClick={handleClose}
                        className="absolute text-gray-800 dark:text-gray-400 top-2 right-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                        <svg
                            className="rounded-full hover:bg-gray-600 hover:text-white"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 6L6 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6 6L18 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <header className="text-xl font-bold text-center">
                        {cuponBlue ? "¡Cupon creado!" : "¡Promoción creada!"}
                    </header>
                    <span className="mt-3 font-medium text-center">
                        {cuponBlue
                            ? "Comparte el siguiente cupon con tu cliente"
                            : "Comparte el siguiente cupon con tus trabajadores"}
                    </span>
                    <div className="flex items-center justify-between w-full p-2 mt-4 text-gray-600 border rounded-lg">
                        <span>{cupon}</span>

                        <ButtonCopyToClipboard
                            confirmationText="Cupón copiado al portapapeles correctamente"
                            textToCopy={cupon}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalPromoCreada;
