import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import { CountryData } from "react-phone-input-2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface Props {
    setModalVisibleUsuario: (modalVisibleUsuario: boolean) => void;
    modalVisibleUsuario: boolean;
}

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

export const ModalCrearUsuario = ({
    setModalVisibleUsuario,
    modalVisibleUsuario,
}: Props) => {
    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        nombre: "",
        apellido: "",
        correo: "",
        celular: "",
        password: "",
    });

    const [input, setInput] = useState({
        asunto: "",
        Usuario: "",
    });
    const INPUTS = [
        {
            input_id: 0,
            label: "Nombre",
            nombre: "nombre",
            type: "text",
        },
        {
            input_id: 1,
            label: "Apellido",
            nombre: "apellido",
            type: "text",
        },
        {
            input_id: 2,
            label: "Correo",
            nombre: "correo",
            type: "email",
        },

        {
            input_id: 4,
            label: "Contraseña",
            nombre: "password",
            type: "password",
        },
    ];

    const [saving, setSaving] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = async () => {
        const form = new FormData();
        if (userInfo.nombre.trim() === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: "debes agregar un nombre",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }
        if (userInfo.apellido.trim() === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: "debes agregar un apellido",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }
        if (userInfo.correo.trim() === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: "debes agregar un correo",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }
        if (userInfo.celular.trim() === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: "debes agregar un celular",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }
        if (userInfo.celular.trim() === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: "debes agregar una contraseña",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }

        setSaving(true);

        try {
            const json = await fetchAxiosToken({
                url: `/subusuarios/`,
                method: "post",
                body: {
                    nombre: userInfo.nombre.trim(),
                    apellido: userInfo.apellido.trim(),
                    correo: userInfo.correo.trim(),
                    celular: userInfo.celular.trim(),
                    password: userInfo.password,
                },
            });

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                position: "top-end",
                icon: "success",
                title: "Usuario creado correctamente!",
                showConfirmButton: false,
                timer: 1500,
            });
            setModalVisibleUsuario(false);

            setSaving(false);
        } catch (error: any) {
            console.log(error);
            setSaving(false);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: `${error.response.data.error}`,
                showConfirmButton: true,
                // timer: 1500,
            });
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleUsuario}
            setModalVisible={setModalVisibleUsuario}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-sm font-medium 2xl:text-base">
                    Crear Usuario
                </h2>
                <hr className="w-full bg-gray-300" />

                <div className="flex flex-col items-center w-full 2xl:pt-6 ">
                    {INPUTS.map(({ nombre, input_id, type, label }) => (
                        <div key={input_id} className="w-full max-w-xl">
                            <label className="text-sm font-medium 2xl:text-base">
                                {label}
                            </label>
                            <input
                                className="w-full 2xl:p-2 p-1.5 mt-1 border rounded-xl"
                                placeholder={label}
                                name={nombre}
                                value={userInfo[nombre]}
                                onChange={handleInputChange}
                                type={type}
                            />
                        </div>
                    ))}
                    <div className="w-full max-w-xl mt-1">
                        <label className="font-medium">Celular</label>

                        <PhoneInput
                            country={"pe"}
                            value={userInfo.celular}
                            onChange={(celular) =>
                                setUserInfo({
                                    ...userInfo,
                                    celular,
                                })
                            }
                            inputStyle={{
                                width: "100%",
                                padding: "1.2rem 3rem",
                                border: "1px solid #e2e8f0",
                                borderRadius: "12px",
                            }}
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-4 mt-3 2xl:mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSave()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 2xl:text-base text-sm">
                            Crear
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
