import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import { NavbarCliente } from "../../dashboardClientes/NavbarCliente";
import { NavbarMobileCliente } from "../../dashboardClientes/NavbarMobileCliente";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { ModalDetalleDePagos } from "./ModalDetalleDePagos";
import StarsRating from "./StarsRating";
import { io, Socket } from "socket.io-client";

interface PedidoAdicional {
    id: number;
    id_pedido: number;
    servicio: string;
    costo: string;
}

export interface resena {
    id: number;
    id_pedidos: number;
    puntuacion: string;
    resena: string;
    fecha_registro: string;
    habilitado: string;
    pedido: Pedido;
    cliente_resena: string;
}

export interface Pedido {
    id: number;
    fecha_inicio: string;
    fecha_final: string;
    Cliente: Cliente;
}

export interface Cliente {
    nombre: string;
    apellido: string;
    correo: string;
    avatar: string;
    id: number;
}

export const DetalleResena = () => {
    const { resenaID } = useParams();
    const navigate = useNavigate();

    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const casa = useAppSelector((state) => state.landing_house);
    const [resena, setResena] = useState<resena | null>(null);
    const [estado, setEstado] = useState<number | null>(1);
    const [input, setInput] = useState(5);
    const [comentario, setComentario] = useState("");
    const { socket } = useAppSelector((state) => state.activarSocket);

    const getOne = async () => {
        const data = await fetchAxiosToken({
            url: `/resenas/getOne/${resenaID}/${id}`,
        }).then((e: resena) => {
            setResena(e);

            if (socket) {
                socket?.emit("send-notificacion-leida", {
                    id_cliente: e.pedido.Cliente.id,
                    id_casa: casa.house.id,
                });
            }
        });
    };

    useEffect(() => {
        getOne();
    }, []);

    const enviarResena = async () => {
        if (comentario === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "warning",
                title: "Debes agregar un comentario",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }
        try {
            const data = await fetchAxiosToken({
                url: `/resenas`,
                method: "post",
                body: {
                    id_pedidos: resena?.id,
                    puntuacion: input,
                    resena: comentario,
                },
            });
            console.log(data);
            navigate(`/dashboard-cliente/reservas`, { replace: false });
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "success",
                title: "Reseña guardada",
                text: "su reseña fue guardada con exito",
                showConfirmButton: true,
                // timer: 2000,
            });
        } catch (error: any) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "warning",
                title: "No se pudo guardar",
                text: `${error.response.data.error}`,
                showConfirmButton: true,
                // timer: 2000,
            });
        }
    };

    const render = () => {
        return (
            <div className="flex flex-col w-full p-4">
                <span className="text-lg font-medium text-center">
                    Reseña
                    {/* reserva #{resena?.id} */}
                </span>
                <div className="mt-4 font-medium text-center">
                    Reserva del{" "}
                    <ShowDateToUser
                        date={
                            resena?.id_pedidos === 0
                                ? resena.fecha_registro
                                : resena?.pedido.fecha_inicio
                        }
                    />{" "}
                    al{" "}
                    <ShowDateToUser
                        date={
                            resena?.id_pedidos === 0
                                ? resena.fecha_registro
                                : resena?.pedido.fecha_final
                        }
                    />
                </div>
                <div className="mt-4 font-medium text-center">
                    <span>
                        Cliente:{" "}
                        {resena?.id_pedidos === 0
                            ? `${resena.cliente_resena} (hecho por el propietario)`
                            : `${resena?.pedido.Cliente.nombre} ${resena?.pedido.Cliente.apellido}`}{" "}
                    </span>
                </div>
                <div className="flex justify-center mt-10 mb-2">
                    <span>
                        su puntuación <span className="text-red-500">*</span>
                    </span>
                </div>
                <div className="mx-auto ">
                    <StarsRating
                        starsQuantity={5}
                        value={Number(resena?.puntuacion)}
                    />
                </div>
                <div className="flex flex-col gap-2 p-4 mx-auto mt-4 w-80">
                    <label className="mx-auto">
                        su comentario <span className="text-red-500">*</span>
                    </label>
                    <textarea
                        disabled
                        className="h-24 p-3 border rounded-lg cursor-not-allowed border-blue-blueFrom"
                        name="comentarios"
                        value={resena?.resena}
                        onChange={(e) => setComentario(e.target.value)}
                    />
                </div>
                {
                    <div className="w-full text-center">
                        {/* <button
                            onClick={() => enviarResena()}
                            className="w-3/5 p-3 mt-4 font-medium text-white border rounded-lg bg-blue-blueFrom active:translate-y-1">
                            Enviar
                        </button> */}
                    </div>
                }
            </div>
        );
    };

    if (!resena)
        return (
            <>
                <Navbar titulo="Reservas">
                    <div className="p-4 font-medium">Cargando resena...</div>
                    <NavbarMobile />
                </Navbar>
            </>
        );
    return (
        <>
            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="Reservas">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : (
                <NavbarCliente>
                    {render()}
                    <NavbarMobileCliente />
                </NavbarCliente>
            )}
        </>
    );
};
