import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";

interface Props {
    setModalVisibleNota: (modalVisibleNota: boolean) => void;
    modalVisibleNota: boolean;
}

export const ModalCrearNota = ({
    setModalVisibleNota,
    modalVisibleNota,
}: Props) => {
    const { id: idUsuario } = useAppSelector((state) => state.usuario_logeado);

    const { id } = useParams();
    const [input, setInput] = useState({
        asunto: "",
        nota: "",
    });

    const [saving, setSaving] = useState<boolean>(false);

    const handleSubmit = async () => {
        if (input.asunto === "" || input.nota === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: "Debes agregar un asunto y una nota",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }

        const json = await fetchAxiosToken({
            url: `/crm_notas/`,
            method: "post",
            body: {
                id_crm: id,
                id_usuario: idUsuario,
                asunto: input.asunto,
                nota: input.nota,
            },
        });
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            // position: "top-end",
            icon: "success",
            title: "Nota creada con exito",

            showConfirmButton: true,
            // timer: 2000,
        });
        setModalVisibleNota(false);
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleNota}
            setModalVisible={setModalVisibleNota}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-base font-medium">Crear Nota</h2>
                <hr className="w-full bg-gray-300" />

                <div>
                    <div className="w-full my-1 text-left">
                        <header className="mb-3 pl-1 text-[#3C3C3C]  text-base">
                            Asunto:
                        </header>
                    </div>
                    <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                        <input
                            onChange={(e) =>
                                setInput({
                                    ...input,
                                    asunto: e.target.value,
                                })
                            }
                            type="text"
                            value={input.asunto}
                            placeholder="Asunto"
                            className="w-full px-1 2xl:py-3 py-2 bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400"
                        />
                    </div>
                    <div className="w-full my-1 text-left">
                        <header className="mb-3 pl-1 text-[#3C3C3C]  text-base">
                            Nota:
                        </header>
                    </div>
                    <div className="w-full px-2 rounded-lg border border-[#E9E9E9]  ">
                        <textarea
                            onChange={(e) =>
                                setInput({
                                    ...input,
                                    nota: e.target.value,
                                })
                            }
                            value={input.nota}
                            placeholder="Nota"
                            className="w-full px-1 2xl:py-3 py-2 min-h-[80px] bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400"
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-4 2xl:mt-8 mt-4">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 2xl:text-base text-sm">
                            Crear
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
