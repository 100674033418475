import React, { useEffect, useMemo, useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../hooks/hooks";

interface Props {
    imagen_principal: string;
    imagenes: Imagenes[];
    modalVisible: boolean;
    setModalVisible: (b: boolean) => void;
}

export interface Imagenes {
    id: number;
    id_casa: number;
    imagen: string;
}

export const ModalGaleriaImagenes = ({
    modalVisible,
    setModalVisible,
    imagenes,
    imagen_principal,
}: Props) => {
    const [photos, setPhotos] = useState([
        {
            src: `${
                process.env.REACT_APP_PROXY
            }${"/casa/getImage/"}${imagen_principal}`,
            original: `${
                process.env.REACT_APP_PROXY
            }${"/casa/getImage/"}${imagen_principal}`,
            width: 400,
            height: 200,
        },
    ]);
    const [index, setIndex] = useState(-1);
    const { banner, id: casa_id } = useAppSelector(
        (state) => state.landing_house.house
    );
    const currentImage = photos?.[index];
    const nextIndex = (index + 1) % photos?.length;
    const nextImage = photos?.[nextIndex] || currentImage;
    const prevIndex = (index + photos?.length - 1) % photos?.length;
    const prevImage = photos?.[prevIndex] || currentImage;

    const handleClick = (i: any, item: any) => {
        setIndex(item.orden);
    };
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);
    const [espacioSeleccionado, setEspacioSeleccionado] = useState<
        number | null
    >(null);

    const [espacios, setEspacios] = useState<any>([]);

    const groupRefs: any = useMemo(
        () => Array.from({ length: espacios.length }, () => React.createRef()),
        [espacios.length]
    );

    const handleClickEspacio = (index: number) => {
        setEspacioSeleccionado(index);

        if (groupRefs[index] && groupRefs[index].current) {
            groupRefs[index].current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (espacioSeleccionado !== null) {
            if (
                groupRefs[espacioSeleccionado] &&
                groupRefs[espacioSeleccionado].current
            ) {
                groupRefs[espacioSeleccionado].current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        }
    }, [espacioSeleccionado, groupRefs]);

    const cargarEtiquetas = async () => {
        try {
            const data = await fetchAxiosToken({
                url: `/espacios_imagenes/getAll/${casa_id}`,
                method: "get",
            });

            let contador_imagenes = 0;
            const espaciosAdaptados = data.map((espacio: any) => {
                const imagenesAdaptadas = espacio.imagenes.map(
                    (imagen: any) => {
                        contador_imagenes++;
                        return {
                            src: `${
                                process.env.REACT_APP_PROXY
                            }${"/casa/getImage/"}${imagen.imagen}`,
                            original: `${
                                process.env.REACT_APP_PROXY
                            }${"/casa/getImage/"}${imagen.imagen}`,
                            description: imagen.description,
                            height: 200,
                            orden: contador_imagenes,
                        };
                    }
                );
                setPhotos((prevPhotos) => [
                    ...prevPhotos,
                    ...imagenesAdaptadas,
                ]);

                return {
                    ...espacio,
                    imagenesAdaptadas: imagenesAdaptadas,
                };
            });

            setEspacios(espaciosAdaptados);
        } catch (error) {
            console.error("Error al cargar etiquetas:", error);
        }
    };

    useEffect(() => {
        cargarEtiquetas();
    }, []);

    const handleCloseModal = () => {
        setModalVisible(false);
    };
    console.log(currentImage);
    console.log("espacios", espacios);
    return (
        <div
            className={`${
                !modalVisible
                    ? "translate-y-full invisible"
                    : "translate-y-0 flex visible"
            } transition-all ease-out duration-500 flex-col bg-slate-50 absolute left-0 top-0 z-[999] h-[1000vh] w-full items-center`}
        >
            <div className="flex flex-col w-full h-full">
                <div
                    onClick={() => handleCloseModal()}
                    className="flex items-center justify-center w-8 h-8 p-2 m-4 font-medium border border-gray-300 rounded-full shadow-md cursor-pointer"
                >
                    <svg
                        className="w-4 h-4 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </div>

                <div className="w-full mx-auto xs:w-11/12">
                    <div className="flex justify-start xs:justify-start  items-start w-full mb-4 gap-4 flex-wrap">
                        {espacios.map((espacio: any, index: any) =>
                            espacio.imagenes.length ? (
                                <div
                                    onClick={() => handleClickEspacio(index)}
                                    key={index}
                                    className="px-3 py-2 hover:scale-105 cursor-pointer w-36"
                                >
                                    <img
                                        className="w-32 h-20"
                                        src={`${
                                            process.env.REACT_APP_PROXY
                                        }${"/casa/getImage/"}${
                                            espacio.imagenes[0].imagen
                                        }`}
                                        alt={espacio.nombre}
                                    />
                                    <span className=" capitalize font-light ">
                                        {espacio.nombre}
                                    </span>
                                </div>
                            ) : null
                        )}
                    </div>
                    {espacios.map((espacio: any, index: any) =>
                        espacio.imagenes.length ? (
                            <div
                                ref={groupRefs[index]}
                                key={espacio.id}
                                className="w-full grid grid-cols-[20%,80%] gap-2 mt-6 pt-6"
                            >
                                <p className="font-bold text-lg">
                                    {espacio.nombre}
                                </p>{" "}
                                <div>
                                    {
                                        <Gallery
                                            images={espacio.imagenesAdaptadas}
                                            onClick={handleClick}
                                            enableImageSelection={false}
                                            rowHeight={200}
                                        />
                                    }
                                </div>
                            </div>
                        ) : null
                    )}
                    {!!currentImage && (
                        /* @ts-ignore */
                        <Lightbox
                            mainSrc={currentImage.original}
                            // imageTitle={currentImage.caption}
                            mainSrcThumbnail={currentImage.src}
                            nextSrc={nextImage.original}
                            nextSrcThumbnail={nextImage.src}
                            prevSrc={prevImage.original}
                            prevSrcThumbnail={prevImage.src}
                            onCloseRequest={handleClose}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
