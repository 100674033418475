const Rendimiento = () => {
    return (
        <div className=" flex justify-center items-center w-full md:gap-4  text-[#333333]  px-6 relative bg-[#179BD8] ">
            <div className="p-4 my-4 w-full md:w-5/6 xl:w-4/5 leading-relaxed py-8  relative z-10 text-center flex justify-center items-center bg-white rounded-xl">
                <p className=" text-[#0E1928] font-medium lg:w-3/4 md:w-4/5 w-5/6 text-base md723:text-xl md818:text-2xl lg:text-3xl ">
                    Aumenta tu rentabilidad{" "}
                    <span className=" text-[#C0D345] font-semibold rounded-md">
                        hasta en un 20%
                    </span>{" "}
                    comparado con herramientas como Airbnb o Booking.com
                </p>
            </div>
        </div>
    );
};
export default Rendimiento;
