import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";

// const TITLES = [
//     {
//         id: 1,
//         title: "Calendario",
//         url: "calendario",
//         textInfo: "Aca podras visualizar tu calendario de reservas y precios!",
//         icono: (
//             <svg
//                 className="w-8 h-8 text-blue"
//                 width="24"
//                 height="24"
//                 viewBox="0 0 24 24"
//                 strokeWidth="2"
//                 stroke="currentColor"
//                 fill="none"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//             >
//                 <path stroke="none" d="M0 0h24v24H0z" />
//                 <rect x="4" y="5" width="16" height="16" rx="2" />
//                 <line x1="16" y1="3" x2="16" y2="7" />
//                 <line x1="8" y1="3" x2="8" y2="7" />
//                 <line x1="4" y1="11" x2="20" y2="11" />
//                 <rect x="8" y="15" width="2" height="2" />
//             </svg>
//         ),
//     },
//     {
//         id: 2,
//         title: "Reservas",
//         url: "reservas",
//         textInfo:
//             "Aca podras ver los pedidos de reservas que te haran los clientes!",
//         icono: (
//             <svg
//                 className="w-8 h-8 text-blue"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//             >
//                 <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
//                 />
//             </svg>
//         ),
//     },
//     {
//         id: 3,
//         title: "Chat",
//         url: "chat",
//         textInfo:
//             "Aca podras ver los mensajes que te envien tus clientes y podras conversar con ellos",
//         icono: (
//             <svg
//                 className="w-8 h-8 text-blue"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//             >
//                 <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
//                 />
//             </svg>
//         ),
//     },
//     {
//         id: 12,
//         title: "Contactos",
//         url: "contactos",
//         textInfo:
//             "Aca podras ver todo el detalle de tus contactos",
//         icono: (
//             <svg className="w-8 h-8 text-blue"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="5" r="2" />  <path d="M5 22v-5l-1-1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />  <circle cx="17" cy="5" r="2" />  <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" /></svg>
//         ),
//     },
//     {
//         id: 4,
//         title: "Estadisticas",
//         url: "estadisticas",
//         textInfo: "Aca podras ver las estadisticas que te ofrece BLU!",
//         icono: (
//             <svg
//                 className="w-8 h-8 text-blue"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//             >
//                 <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
//                 />
//             </svg>
//         ),
//     },
//     {
//         id: 5,
//         title: "Reseñas",
//         url: "reviews",
//         textInfo:
//             "Aca podras ver las reseñas de los clientes que ya te hicieron una reserva!",
//         icono: (
//             <svg
//                 className="w-8 h-8 text-blue"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//             >
//                 <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
//                 />
//             </svg>
//         ),
//     },
//     {
//         id: 6,
//         title: "CRM",
//         url: "crm",
//         textInfo:
//             "Aca podras ver los leads que te llegan, desde chats y pedidos!",
//         icono: (
//             <svg
//                 className="w-8 h-8 text-blue"
//                 viewBox="0 0 24 24"
//                 fill="none"
//                 stroke="currentColor"
//                 strokeWidth="2"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//             >
//                 <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
//                 <polyline points="7.5 4.21 12 6.81 16.5 4.21" />
//                 <polyline points="7.5 19.79 7.5 14.6 3 12" />
//                 <polyline points="21 12 16.5 14.6 16.5 19.79" />
//                 <polyline points="3.27 6.96 12 12.01 20.73 6.96" />
//                 <line x1="12" y1="22.08" x2="12" y2="12" />
//             </svg>
//         ),
//     },
//     {
//         id: 7,
//         title: "Sub usuarios",
//         url: "sub-usuarios",
//         textInfo:
//             "Aca podras ver los usuarios que te ayudaran a administrar tu casa!",
//         icono: (
//             <svg
//                 className="w-8 h-8 text-blue"
//                 viewBox="0 0 24 24"
//                 fill="none"
//                 stroke="currentColor"
//                 strokeWidth="2"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//             >
//                 {" "}
//                 <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />{" "}
//                 <circle cx="9" cy="7" r="4" />{" "}
//                 <path d="M23 21v-2a4 4 0 0 0-3-3.87" />{" "}
//                 <path d="M16 3.13a4 4 0 0 1 0 7.75" />
//             </svg>
//         ),
//     },
//     {
//         id: 8,
//         title: "Redes sociales",
//         url: "redes-sociales",
//         textInfo: "Aca podras compartir tu casa en diferentes redes sociales",
//         icono: (
//             <svg
//                 className="w-8 h-8 text-blue"
//                 width="24"
//                 height="24"
//                 viewBox="0 0 24 24"
//                 strokeWidth="2"
//                 stroke="currentColor"
//                 fill="none"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//             >
//                 <path stroke="none" d="M0 0h24v24H0z" />
//                 <rect x="4" y="4" width="16" height="16" rx="4" />
//                 <circle cx="12" cy="12" r="3" />
//                 <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
//             </svg>
//         ),
//     },
//     {
//         id: 9,
//         title: "Medios de pago",
//         // url: "medios-de-pago",
//         textInfo:
//             "Aca podras configurar los diferentes medios de pago que te ofrece BLU!",
//         icono: (
//             <svg
//                 className="w-8 h-8 text-blue"
//                 width="24"
//                 height="24"
//                 viewBox="0 0 24 24"
//                 strokeWidth="2"
//                 stroke="currentColor"
//                 fill="none"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//             >
//                 <path stroke="none" d="M0 0h24v24H0z" />
//                 <path d="M10 13l2.538-.003c2.46 0 4.962-2.497 4.962-4.997 0-3-1.89-5-4.962-5H7c-.5 0-1 .5-1 1L4 18c0 .5.5 1 1 1h2.846L9 14c.089-.564.43-1 1-1zm7.467-5.837C19.204 8.153 20 10 20 12c0 2.467-2.54 4.505-5 4.505h.021-2.629l-.576 3.65a.998.998 0 01-.988.844l-2.712-.002a.5.5 0 01-.494-.578L7.846 19" />
//             </svg>
//         ),
//         children: [
//             {
//                 id: "transferencia",
//                 title: "Transferencia",
//                 url: "transferencia",
//                 textInfo: "Configurar la cuenta para transferir",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//             {
//                 id: "mercadopago",
//                 title: "Mercado Pago",
//                 url: "mercado-pago",
//                 textInfo: "Configurar la cuenta para transferir",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//         ],
//     },
//     {
//         id: 10,
//         title: "Suscripciones",
//         url: "suscripciones",
//         textInfo: "Aca podras pagar tu suscripcion a BLU!",
//         icono: (
//             <svg
//                 className="w-8 h-8 text-blue"
//                 width="24"
//                 height="24"
//                 viewBox="0 0 24 24"
//                 strokeWidth="2"
//                 stroke="currentColor"
//                 fill="none"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//             >
//                 {" "}
//                 <path stroke="none" d="M0 0h24v24H0z" />{" "}
//                 <rect x="3" y="5" width="18" height="14" rx="3" />{" "}
//                 <line x1="3" y1="10" x2="21" y2="10" />{" "}
//                 <line x1="7" y1="15" x2="7.01" y2="15" />{" "}
//                 <line x1="11" y1="15" x2="13" y2="15" />
//             </svg>
//         ),
//     },
//     {
//         id: 13,
//         title: "Ayuda",
//         url: "ayuda",
//         textInfo: "Aca podras comunicarte con el equipo de soporte!",
//         icono: (
//             <svg className="w-8 h-8 text-blue"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"/>
// </svg>

//         ),
//     },
//     {
//         id: 11,
//         title: "Configuraciones",
//         textInfo:
//             "Aca podras configurar tu casa, revisa las diferentes opciones que te damos!",
//         icono: (
//             <svg
//                 className="w-8 h-8 text-blue"
//                 viewBox="0 0 24 24"
//                 fill="none"
//                 stroke="currentColor"
//                 strokeWidth="2"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//             >
//                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//             </svg>
//         ),

//         children: [
//             {
//                 id: "landing",
//                 title: "Como editar mi landing",
//                 url: "editar-landing",
//                 textInfo: "Configura y personaliza tu landing!",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//             {
//                 id: "tema",
//                 title: "Tema de landing",
//                 url: "tema-landing",
//                 textInfo: "Cambiar el tema de tu landing!",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//             {
//                 id: "garantia",
//                 title: "Garantia",
//                 url: "garantia",
//                 textInfo: "Establece un precio de grantia de tu casa!",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//             {
//                 id: "bienvenida",
//                 title: "Mensaje de bienvenida",
//                 url: "mensaje-bienvenida",
//                 textInfo: "Establece un mensaje de bienvenida!",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//             {
//                 id: "check",
//                 title: "Check-in - Check-out",
//                 url: "check",
//                 textInfo: "Configura el check in y check out de tu casa!",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//             {
//                 id: "tarifas",
//                 title: "Tarifas adicionales",
//                 url: "tarifas-adicionales",
//                 textInfo:
//                     "Configura costos adicionales para tu casa, ej: Limpieza, Luz, etc",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//             {
//                 id: "personas",
//                 title: "Personas adicionales",
//                 url: "personas-adicionales",
//                 textInfo:
//                     "Cobra algun costo extra por personas adicionales, superior a la capacidad de tu casa!",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//             {
//                 id: "descuento-estadia",
//                 title: "Descuento por estadia",
//                 url: "descuento-por-estadia",
//                 textInfo:
//                     "Puedes crear descuentos , dependiendo la cantidad de dias que tus clientes se hospeden!",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//             {
//                 id: "cupones",
//                 title: "Cupones",
//                 url: "cupones",
//                 textInfo:
//                     "Puedes crear cupones de descuento para tus clientes recurrentes!",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//             {
//                 id: "disponibilidad-minima",
//                 title: "Disponibilidad minima",
//                 url: "minima-disponibilidad",
//                 textInfo:
//                     "Elige la estadia minima  de tu casa (siempre y en dias especiales!)",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//             {
//                 id: "disponibilidad-maxima",
//                 title: "Disponibilidad maxima",
//                 url: "maxima-disponibilidad",
//                 textInfo:
//                     "Elige la estadia maxima  de tu casa (siempre y en dias especiales!) ",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//             {
//                 id: "conexiones-externas",
//                 title: "Conexiones externas",
//                 url: "conexiones-externas",
//                 textInfo:
//                     "Conecta tu casa con BLUECORNER, asi para compartir un mismo calendario de reservas!",
//                 icono: (
//                     <svg
//                         className="w-8 h-8 text-blue"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                     >
//                         <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
//                     </svg>
//                 ),
//             },
//         ],
//     },
// ];;

const TITLES = [
    {
        id: 1,
        title: "Calendario",
        url: "calendario",
        textInfo: "Aca podras visualizar tu calendario de reservas y precios!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 99.99">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m50.06,35.65h48.35c1.58,0,1.59,0,1.59,1.59,0,17.23,0,34.46,0,51.69,0,6.41-4.61,11.05-11,11.05-25.99,0-51.99,0-77.99,0C4.67,99.98.01,95.35.01,89.04,0,71.73,0,54.43,0,37.12c0-1.46,0-1.47,1.48-1.47h48.57m-25.01,17.99c-1.11,0-2.23-.03-3.34,0-2.16.07-3.73,1.58-3.71,3.51.03,1.85,1.62,3.39,3.68,3.44,2.22.05,4.45.05,6.67,0,2.1-.04,3.75-1.64,3.71-3.51-.03-1.85-1.63-3.37-3.68-3.44-1.11-.04-2.23,0-3.34,0m24.9,6.97c1.11,0,2.23.03,3.34,0,2.11-.06,3.75-1.61,3.74-3.49-.01-1.88-1.65-3.43-3.76-3.46-2.19-.04-4.38-.04-6.57,0-2.17.04-3.74,1.54-3.73,3.48,0,1.88,1.64,3.41,3.76,3.47,1.07.03,2.15,0,3.23,0m25.11-6.97c-1.15,0-2.3-.03-3.45,0-2.16.07-3.73,1.6-3.69,3.52.04,1.86,1.63,3.38,3.69,3.43,2.22.05,4.45.05,6.67,0,2.1-.04,3.74-1.66,3.7-3.53-.05-1.86-1.64-3.36-3.69-3.42-1.07-.04-2.15,0-3.23,0m-50.03,21.39c-1.11,0-2.23-.03-3.34,0-2.15.07-3.73,1.59-3.7,3.52.04,1.86,1.63,3.39,3.69,3.43,2.22.05,4.45.05,6.67,0,2.1-.04,3.75-1.65,3.7-3.52-.04-1.85-1.64-3.36-3.69-3.43-1.11-.03-2.22,0-3.34,0m25.01,6.97c1.07,0,2.15.03,3.23,0,2.11-.06,3.75-1.59,3.75-3.48,0-1.88-1.64-3.44-3.75-3.48-2.19-.04-4.38-.04-6.57,0-2.18.04-3.74,1.52-3.75,3.47,0,1.88,1.63,3.43,3.74,3.49,1.11.03,2.23,0,3.34,0m24.86,0c1.11,0,2.23.03,3.34,0,2.11-.06,3.75-1.61,3.74-3.49-.01-1.89-1.65-3.43-3.77-3.46-2.19-.04-4.38-.04-6.57,0-2.17.04-3.74,1.54-3.73,3.48,0,1.89,1.64,3.41,3.76,3.47,1.07.03,2.15,0,3.23,0"
                        />
                        <path
                            fill="white"
                            d="m49.92,28.68H1.79Q0,28.68,0,26.84C0,23.76-.01,20.68.02,17.6.06,11.93,4.81,7.18,10.48,7.14c4.38-.03,8.76-.04,13.15.01,1.1.01,1.46-.34,1.35-1.4-.07-.7-.02-1.41,0-2.12C25.02,1.48,26.52-.01,28.64,0c2.04.01,3.56,1.52,3.61,3.59.02.85.04,1.71,0,2.56-.03.72.24,1.01.97.98,1.11-.04,2.23,0,3.34,0h29.74q1.45,0,1.45-1.48c0-.63,0-1.26,0-1.89C67.79,1.5,69.26,0,71.42,0c2.12,0,3.59,1.56,3.62,3.81,0,.7.05,1.41,0,2.11-.08.92.26,1.23,1.19,1.22,4.23-.04,8.46-.02,12.7-.02,6.47.01,11.06,4.61,11.08,11.09,0,2.93,0,5.86,0,8.8q0,1.66-1.63,1.66h-48.45"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 2,
        title: "Reservas",
        url: "reservas",
        textInfo:
            "Aca podras ver los pedidos de reservas que te haran los clientes!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 99.54 100">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m49.75,73.36c-13.18,0-26.36,0-39.54,0-2.33,0-3.81-1.47-3.7-3.65.07-1.4.92-2.61,2.33-2.98.88-.23,1-.68,1.02-1.46.13-4.61.59-9.18,1.81-13.66,2.56-9.41,8.55-15.56,17.67-18.73,4.8-1.67,9.77-2.49,14.84-2.72,2.23-.1,2.67-.68,2.18-2.91-.13-.59-.6-.49-.95-.62-1.55-.58-2.41-1.8-2.36-3.42.04-1.46.94-2.68,2.33-3.1.36-.11.75-.15,1.12-.15,2.18-.01,4.36-.02,6.54,0,1.77.02,3.13,1.14,3.4,2.76.3,1.81-.65,3.41-2.46,3.91-.71.19-.83.53-.84,1.16-.01,2.3-.03,2.24,2.32,2.37,6.05.32,11.94,1.35,17.52,3.84,8.03,3.58,12.86,9.79,15.02,18.23,1.09,4.28,1.53,8.65,1.64,13.06.02.8.2,1.2,1.04,1.44,1.67.47,2.56,2.08,2.29,3.82-.25,1.63-1.67,2.79-3.48,2.79-9.72,0-19.44,0-29.16,0-3.53,0-7.06,0-10.59,0Zm6.41-29.71c-1.32-.17-2.31.99-2.74,2.1-.84,2.14.65,4.19,3.11,4.39,2.28.18,4.53.52,6.71,1.24,2.81.94,4.81,2.67,5.55,5.65.11.43.28.86.5,1.25.79,1.35,2.4,1.9,3.86,1.36,1.42-.53,2.34-2.12,1.99-3.59-1.04-4.33-3.3-7.73-7.41-9.73-3.36-1.64-6.95-2.23-11.58-2.66Z"
                        />
                        <path
                            fill="white"
                            d="m49.65,99.99c-15.95,0-31.9,0-47.84,0-.31,0-.62-.02-.93,0-.84.08-1.02-.26-.76-1.04.94-2.75,1.86-5.51,2.76-8.28.19-.57.51-.84,1.09-.93,3.27-.5,5.22-2.38,5.72-5.66.15-.98.78-.88,1.44-.88,11.21,0,22.42,0,33.63,0q1.62,0,1.62-1.64t1.69-1.69c1.38,0,2.77.03,4.15,0,.73-.02.94.28.96.98.06,2.36.08,2.36,2.45,2.36,10.9,0,21.79.01,32.69-.02,1.02,0,1.45.26,1.6,1.32.39,2.68,2.54,4.77,5.25,5.16.92.13,1.36.53,1.63,1.41.78,2.55,1.66,5.07,2.5,7.6.43,1.3.42,1.31-.97,1.31-16.22,0-32.45,0-48.67,0Z"
                        />
                        <path
                            fill="white"
                            d="m46.37,6.67c0-1.14-.03-2.29,0-3.43C46.43,1.36,47.86,0,49.73,0c1.87,0,3.37,1.35,3.4,3.19.05,2.35.05,4.71,0,7.07-.04,1.85-1.53,3.2-3.4,3.19-1.83,0-3.3-1.42-3.36-3.25-.03-1.18,0-2.36,0-3.53Z"
                        />
                        <path
                            fill="white"
                            d="m19.93,16.77c-.04,1.36-.63,2.41-1.86,2.99-1.25.6-2.54.54-3.61-.36-1.43-1.2-2.76-2.52-3.95-3.96-1.07-1.31-.9-3.29.25-4.47,1.18-1.21,3.2-1.4,4.54-.3,1.43,1.19,2.73,2.55,3.96,3.95.51.59.72,1.33.67,2.13Z"
                        />
                        <path
                            fill="white"
                            d="m79.57,16.21c-.01-.5.36-1.3,1.01-1.97,1.06-1.09,2.12-2.18,3.23-3.23,1.47-1.38,3.63-1.38,4.94-.03,1.28,1.32,1.29,3.44-.06,4.88-1.09,1.16-2.21,2.29-3.38,3.37-1.13,1.03-2.48,1.2-3.86.55-1.28-.6-1.87-1.69-1.88-3.56Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 3,
        title: "Chat",
        url: "chat",
        textInfo:
            "Aca podras ver los mensajes que te envien tus clientes y podras conversar con ellos",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 95.03">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m100,19.8v32.79c-.11.57-.22,1.15-.33,1.72-1.91,10.16-11.15,17.98-21.49,18.05-10.15.07-20.3.03-30.44,0-.92,0-1.58.26-2.23.92-6.81,6.85-13.65,13.67-20.48,20.5-1.22,1.21-2.43,1.54-3.69.98-1.39-.63-1.83-1.78-1.83-3.25.03-5.92.01-11.84.01-17.76q0-1.49-1.5-1.78C7.76,69.98.03,60.67,0,50.23c-.02-9.33,0-18.67,0-28,0-.91.06-1.81.17-2.72C1.59,8.55,11.17.04,22.24.02,40.75,0,59.25-.02,77.76.03c7.06.02,12.82,2.95,17.33,8.35,2.76,3.31,4.25,7.19,4.91,11.41ZM26.68,31.73c-3.19-.02-5.74,2.44-5.82,5.6-.07,3.12,2.59,5.81,5.75,5.81,3.12,0,5.71-2.55,5.77-5.7.06-3.09-2.55-5.7-5.7-5.72Zm23.43,0c-3.22-.03-5.86,2.54-5.83,5.68.02,3.12,2.62,5.72,5.74,5.73,3.12.01,5.71-2.53,5.79-5.68.07-3.08-2.53-5.71-5.69-5.73Zm23.42,0c-3.22-.02-5.86,2.54-5.83,5.69.02,3.13,2.62,5.72,5.74,5.73,3.12,0,5.71-2.54,5.78-5.69.07-3.08-2.54-5.71-5.69-5.73Z"
                    />
                </g>
            </svg>
        ),
    },
    {
        id: 12,
        title: "Contactos",
        url: "contactos",
        textInfo: "Aca podras ver todo el detalle de tus contactos",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 62.39">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m53.4,27.32c1.41.22,2.72.32,4,.64,8.74,2.17,15.03,10.14,15.08,19.14.01,2.64.02,5.28,0,7.92-.04,4.06-3.3,7.34-7.36,7.35-10.06.03-20.12.02-30.18,0-4.06,0-7.38-3.29-7.35-7.34.02-3.68-.31-7.38.23-11.05,1.27-8.59,9.01-15.79,17.68-16.5.34-.03.68-.1,1.36-.2-3.87-1.13-6.71-3.13-8.63-6.34-1.89-3.18-2.51-6.57-1.65-10.19C38.1,4.3,43.7-.08,50.3,0c6.36.07,11.95,4.75,13.24,11.08,1.44,7.03-2.04,12.65-10.14,16.25Z"
                        />
                        <path
                            fill="white"
                            d="m17.38,24.84c-7.04-2.13-9.24-8.37-8.29-13.13,1.15-5.76,5.83-9.33,11.87-9.16,5.08.14,9.72,4.39,10.41,9.66.76,5.81-2.66,11.05-8.79,12.69,3.26,0,6.03.78,8.64,2.2,1.15.62,1.15.7.17,1.57-5.69,5.06-8.55,11.4-8.58,19.02,0,1.86,0,3.73,0,5.59q0,1.64-1.7,1.64c-4.35,0-8.7,0-13.05,0-4.27,0-7.38-2.52-7.8-6.74-.4-4.09-.53-8.26,1.13-12.17,3.01-7.11,8.5-10.69,15.99-11.17Z"
                        />
                        <path
                            fill="white"
                            d="m82.24,24.91c9.43.16,16.59,6.75,17.53,15.9.27,2.59.34,5.2-.03,7.78-.53,3.7-3.61,6.32-7.36,6.33-4.58.02-9.17-.03-13.75.03-1.16.01-1.43-.42-1.43-1.49-.01-3.37.27-6.76-.31-10.11-1.01-5.89-3.8-10.78-8.3-14.71-.92-.8-.91-.91.17-1.5,2.62-1.43,5.41-2.25,8.53-2.22-7.12-2.13-9.48-8.2-8.57-13.09,1.09-5.78,5.76-9.4,11.78-9.27,5.15.11,9.83,4.36,10.53,9.67.76,5.81-2.67,11.04-8.82,12.69Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 4,
        title: "Estadisticas",
        url: "estadisticas",
        textInfo: "Aca podras ver las estadisticas que te ofrece BLU!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 91.01">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m100,88.63c-.81,1.75-2.22,2.39-4.1,2.38-6.47-.04-12.94,0-19.41-.02-2.86,0-4.21-1.31-4.21-4.17-.01-20.52-.01-41.03,0-61.54,0-2.95,1.31-4.22,4.24-4.22,6.47,0,12.94.02,19.41-.02,1.89-.01,3.28.62,4.07,2.4v65.19Z"
                        />
                        <path
                            fill="white"
                            d="m63.94,45.52v41.17c0,3.05-1.26,4.31-4.34,4.31-6.44,0-12.88,0-19.32,0-1.55,0-2.9-.37-3.72-1.84-.42-.74-.43-1.55-.43-2.37,0-19.12,0-38.24,0-57.36,0-8.42,0-16.84,0-25.27C36.13,1.31,37.44,0,40.32,0,46.79,0,53.26,0,59.73,0c2.92,0,4.2,1.3,4.2,4.25v41.27"
                        />
                        <path
                            fill="white"
                            d="m0,66.52C0,59.69,0,52.86,0,46.03c0-2.63,1.25-3.9,3.88-3.9,6.66,0,13.33,0,20,0,2.37,0,3.62,1.12,3.87,3.47.04.42.04.85.04,1.27,0,13.14,0,26.28,0,39.42,0,.36,0,.72-.02,1.07-.18,2.36-1.48,3.59-3.84,3.64-.94.02-1.88,0-2.83,0H4.41c-.32,0-.65.01-.97-.01-2.18-.16-3.43-1.49-3.43-3.68,0-6.93,0-13.85,0-20.78"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 5,
        title: "Reseñas",
        url: "reviews",
        textInfo:
            "Aca podras ver las reseñas de los clientes que ya te hicieron una reserva!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 99.76 95.45">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m55.13,3.27l12.24,24.79,27.36,3.98c4.81.7,6.73,6.61,3.25,10l-19.8,19.3,4.67,27.25c.82,4.79-4.2,8.44-8.5,6.18l-24.47-12.87-24.47,12.87c-4.3,2.26-9.32-1.39-8.5-6.18l4.67-27.25L1.78,42.03c-3.48-3.39-1.56-9.3,3.25-10l27.36-3.98L44.62,3.27c2.15-4.36,8.36-4.36,10.51,0Z"
                    />
                </g>
            </svg>
        ),
    },
    {
        id: 6,
        title: "CRM",
        url: "crm",
        textInfo:
            "Aca podras ver los leads que te llegan, desde chats y pedidos!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 87.85">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m0,27.24c.32-.65.78-1.19,1.29-1.7,7.96-7.95,15.91-15.9,23.86-23.85,1.87-1.87,3.38-1.86,5.26.03,2.13,2.14,4.24,4.31,6.4,6.42.55.54.55.87,0,1.41-3.12,3.08-6.21,6.2-9.32,9.3-2.04,2.03-3.12,4.42-2.88,7.36.19,2.22,1.15,4.05,2.71,5.57,5.4,5.25,13.98,5.35,19.76.26,2.12-1.86,3.18-1.87,5.32-.02,5.02,4.33,11.26,4.98,17.11,1.75.56-.31.91-.26,1.35.18,5.69,5.71,11.41,11.4,17.09,17.11,1.89,1.9,1.95,4.57.23,6.39-1.78,1.88-4.43,1.97-6.44.07-2.36-2.23-4.62-4.57-6.92-6.87-.54-.54-1.12-.99-1.9-1.1-1.17-.17-2.3.4-2.86,1.44-.6,1.11-.46,2.35.49,3.32,2.32,2.36,4.68,4.69,7.02,7.05,1.2,1.21,1.77,2.65,1.39,4.35-.7,3.13-4.27,4.52-6.91,2.71-.43-.29-.81-.66-1.17-1.02-2.17-2.15-4.32-4.33-6.49-6.47-1.35-1.33-3.07-1.39-4.23-.19-1.14,1.18-1.04,2.83.27,4.16,2.24,2.26,4.51,4.5,6.75,6.77,1.85,1.88,2.07,4.35.61,6.17-1.76,2.18-4.65,2.36-6.78.36-1.19-1.11-2.34-2.26-3.45-3.45-1.92-2.06-4.23-3.33-7.06-3.51-.76-.05-.95-.37-1.02-1.08-.43-4.2-2.59-7.13-6.56-8.64-.72-.27-.65-.61-.43-1.18,1.73-4.46.69-8.79-2.98-11.76-2.97-2.4-6.36-2.81-9.91-1.47-.85.32-1.2.28-1.54-.65-2.44-6.47-10.97-8.42-16.09-3.72-1.56,1.43-1.59,1.44-2.23-.61-.9-2.89-2.43-5.37-4.6-7.47-1.24-1.2-2.42-2.45-3.64-3.66-.56-.55-1.07-1.14-1.46-1.82v-1.95Z"
                        />
                        <path
                            fill="white"
                            d="m55.02,8.7c1.46,0,2.93-.03,4.39,0,1.87.05,3.4-.6,4.7-1.95,1.69-1.76,3.44-3.46,5.17-5.18,2.11-2.1,3.57-2.11,5.65-.03,7.86,7.86,15.73,15.72,23.59,23.59,1.99,1.99,1.99,3.5,0,5.51-1.99,2.01-4,4-6,6-1.62,1.62-2.19,3.55-1.78,5.79.14.8.28,1.59.1,2.42-.16.74-.43.7-.87.24-.18-.19-.37-.37-.55-.55-6.02-6.02-12.06-12.04-18.06-18.08-.67-.67-1.05-.75-1.79-.06-3.54,3.3-8.91,3.75-13.22,1.18-.78-.47-1.55-.96-2.34-1.41-3.16-1.8-7.09-1.45-9.83.94-1.8,1.57-3.72,2.85-6.19,3-2.79.17-5.19-.76-7.09-2.79-1.08-1.16-.96-2.84.2-4.05,1.33-1.38,2.7-2.72,4.06-4.08,2.39-2.39,4.84-4.73,7.16-7.19,2.18-2.31,4.81-3.33,7.93-3.31,1.59.01,3.19,0,4.78,0Z"
                        />
                        <path
                            fill="white"
                            d="m37.7,56.36c-.03,1.4-.53,2.47-1.41,3.36-3.37,3.39-6.74,6.77-10.13,10.13-1.36,1.34-2.99,1.82-4.83,1.15-1.78-.66-2.76-2.01-2.94-3.89-.13-1.33.35-2.51,1.28-3.45,3.43-3.46,6.86-6.92,10.34-10.33,1.43-1.4,3.18-1.78,5.03-.93,1.72.79,2.56,2.22,2.66,3.97Z"
                        />
                        <path
                            fill="white"
                            d="m12.22,60.93c-1.51-.08-2.92-.89-3.73-2.56-.82-1.68-.61-3.34.59-4.74,1.18-1.38,2.52-2.62,3.8-3.91.91-.92,1.81-1.87,2.78-2.74,1.34-1.19,2.89-1.59,4.61-.94,1.7.65,2.67,1.92,2.91,3.71.18,1.33-.23,2.55-1.15,3.5-2.12,2.2-4.3,4.34-6.48,6.48-.82.81-1.86,1.17-3.33,1.2Z"
                        />
                        <path
                            fill="white"
                            d="m33.25,81.65c-1.82-.13-3.23-.88-4.04-2.52-.81-1.63-.73-3.29.45-4.66,2.16-2.51,4.52-4.85,7.01-7.03,1.74-1.53,4.34-1.27,5.93.36,1.58,1.61,1.82,4.13.32,5.86-2.17,2.5-4.57,4.79-7.01,7.04-.74.68-1.72.88-2.67.96Z"
                        />
                        <path
                            fill="white"
                            d="m43.25,83.28c0-.1,0-.19,0-.29.13-2.99,4.64-6.68,7.42-6.09,3.17.68,4.63,4.3,2.74,6.94-.87,1.22-1.95,2.28-3.16,3.16-1.44,1.05-3.03,1.09-4.61.32-1.56-.76-2.41-2.27-2.39-4.04Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 7,
        title: "Sub usuarios",
        url: "sub-usuarios",
        textInfo:
            "Aca podras ver los usuarios que te ayudaran a administrar tu casa!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 94.81 100">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m35.89,98.76c-6.15,0-12.31.02-18.46,0-2.73-.01-5.4-.48-7.94-1.5-4.81-1.94-7.8-5.55-8.77-10.56-1.91-9.78-.06-18.79,6.57-26.44,4.12-4.76,9.64-7.1,15.79-8.02,3.5-.53,7.02-.44,10.54-.45,5.75,0,11.5-.07,17.24,0,7.73.08,14.35,2.88,19.79,8.39,1.7,1.72,1.42,2.9-.84,3.74-9.64,3.6-15.78,13.05-15.07,23.29.2,2.89.92,5.66,2.17,8.29,1.08,2.28.43,3.28-2.08,3.28-6.31,0-12.63,0-18.94,0h0Z"
                        />
                        <path
                            fill="white"
                            d="m38.97,41.69c-11.43,0-20.84-9.4-20.82-20.77C18.18,9.43,27.58,0,39.01,0c11.5,0,21,9.34,20.92,20.83-.09,12.83-10.59,21.11-20.95,20.86Z"
                        />
                        <path
                            fill="white"
                            d="m72.82,81.64c1.05-.16,2.55.53,3.36-.31.75-.78.2-2.24.25-3.4.04-.93-.02-1.86.03-2.79.11-2.09,1.73-3.71,3.83-3.86,1.88-.14,3.69,1.28,4.1,3.26.13.63.11,1.28.17,1.93.15,1.67-.74,3.84.4,4.89,1.06.97,3.18.2,4.83.28.24.01.49,0,.73,0,2.53.04,4.28,1.66,4.3,3.97.02,2.34-1.65,4.01-4.17,4.12-1.29.05-2.59.01-3.89.02q-2.2,0-2.2,2.14c0,1.3.03,2.59-.01,3.89-.09,2.59-1.78,4.28-4.18,4.23-2.29-.05-3.88-1.82-3.91-4.37-.01-1.42,0-2.83,0-4.25q0-1.63-1.59-1.64c-1.54,0-3.08.04-4.61-.02-1.71-.07-3.05-.84-3.78-2.42-.67-1.45-.44-2.85.51-4.11.76-1,1.8-1.49,3.04-1.54.89-.04,1.78,0,2.82,0Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    // {
    //     id: 8,
    //     title: "Redes sociales",
    //     url: "redes-sociales",
    //     textInfo: "Aca podras compartir tu casa en diferentes redes sociales",
    //     icono: (
    //         <svg className=" w-7 h-7" id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.97 99.99">
    //         <g id="Testimonios">
    //             <path fill="white" d="m72.65,36.48c-4.66,0-8.73-1.57-12.18-4.7-.51-.47-.88-.51-1.47-.16-7.42,4.36-14.85,8.7-22.29,13.02-.57.33-.81.67-.64,1.36.65,2.66.65,5.33,0,7.99-.16.67.02,1.04.61,1.37,7.47,4.34,14.94,8.68,22.4,13.05.56.33.89.26,1.37-.16,5.4-4.71,11.6-6.02,18.31-3.67,6.82,2.4,10.86,7.45,11.98,14.57,1.57,10.01-5.6,19.35-15.67,20.67-9.87,1.3-18.92-5.5-20.45-15.38-.35-2.23-.27-4.45.26-6.64.18-.73-.03-1.11-.64-1.47-7.41-4.3-14.82-8.61-22.2-12.96-.79-.47-1.22-.12-1.75.35-3.32,2.94-7.25,4.46-11.63,4.53-5.48.08-10.24-1.87-13.88-6.01C-3.82,52.48-.39,37.82,11.7,33.03c6.55-2.59,12.72-1.42,18.26,2.96,1.42,1.13,1,1.24,2.7.25,7.07-4.12,14.13-8.25,21.21-12.34.91-.52,1.16-1.02.96-2.1C52.63,9.98,61.56-.36,73.59,0c8.91.27,16.44,7.61,17.28,16.6.87,9.34-5.68,17.76-14.41,19.52-1.25.25-2.51.41-3.8.35Z"/>
    //         </g>
    //         </svg>
    //     ),
    // },
    {
        id: 9,
        title: "Medios de pago",
        // url: "medios-de-pago",
        textInfo:
            "Aca podras configurar los diferentes medios de pago que te ofrece BLU!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 99.97">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m50.01,0c27.44-.18,49.95,22.47,49.99,49.84.04,27.56-22.48,50.32-50.37,50.12C22.42,99.78.09,77.54,0,50.14-.09,22.41,22.57-.15,50.01,0Zm-4.85,41.39c0-2.29,1.48-4.26,3.65-4.87,2.09-.59,4.24.28,5.47,2.22.19.29.34.6.52.9.97,1.54,2.78,2.03,4.34,1.17,1.5-.82,2.04-2.59,1.27-4.22-1.27-2.68-3.23-4.66-6.02-5.72-.91-.34-1.24-.83-1.15-1.76.07-.79.03-1.59,0-2.39-.06-2.07-1.37-3.49-3.22-3.5-1.89-.02-3.22,1.41-3.28,3.55-.02.83-.05,1.67,0,2.49.06.8-.27,1.15-1,1.42-7.48,2.84-9.69,12.52-4.26,18.4,2.44,2.64,5.47,3.74,8.97,3.95,2.18.13,3.88,1.68,4.4,3.81.54,2.17-.28,4.33-2.12,5.54-2.55,1.68-5.78.74-7.3-2.13-1.02-1.92-2.88-2.61-4.58-1.69-1.65.89-2.12,2.78-1.14,4.69,1.28,2.5,3.22,4.34,5.84,5.36.95.37,1.24.88,1.2,1.84-.05,1.03-.14,2.08.11,3.1.38,1.54,1.62,2.56,3.13,2.55,1.51,0,2.72-1.02,3.11-2.57.21-.82.19-1.67.11-2.48-.15-1.38.29-2.1,1.66-2.7,5-2.21,7.71-7.74,6.46-12.74-1.3-5.19-5.65-8.68-11.33-9.1-2.79-.21-4.86-2.38-4.87-5.11Z"
                    />
                </g>
            </svg>
        ),
        children: [
            {
                id: "transferencia",
                title: "Transferencia",
                url: "transferencia",
                textInfo: "Configurar la cuenta para transferir",
                icono: (
                    <svg
                        className="w-8 h-8 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
                    </svg>
                ),
            },
            {
                id: "mercadopago",
                title: "Mercado Pago",
                url: "mercado-pago",
                textInfo: "Configurar la cuenta para transferir",
                icono: (
                    <svg
                        className="w-8 h-8 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
                    </svg>
                ),
            },
        ],
    },
    {
        id: 10,
        title: "Suscripciones",
        url: "suscripciones",
        textInfo: "Aca podras pagar tu suscripcion a BLU!",
        icono: <p>BLU</p>,
    },
    {
        id: 13,
        title: "Ayuda",
        url: "ayuda",
        textInfo: "Aca podras comunicarte con el equipo de soporte!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 83.02 99.97">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m5.82,99.97c-1.12-.44-2.26-.84-3.17-1.67-2.29-2.08-3.11-4.62-2.41-7.66,3.81-16.64,17.64-29.6,34.48-32.31,1.34-.22,2.69-.32,4.05-.52-4.12-.74-7.81-2.36-10.96-5.11-4.07-3.55-6.26-8.07-6.78-13.4-.91-9.31,4.88-18.25,13.73-21.32,12.2-4.23,25.12,3.58,27.14,16.37.47,2.94.28,5.81-.53,8.67-.22.76-.61,1.02-1.39,1.02-4.03-.03-8.06-.03-12.09,0-1.66,0-2.76,1.07-2.82,2.61-.07,1.73,1.02,2.94,2.76,2.96,2.96.03,5.92,0,8.87.02.34,0,.8-.16,1,.18.22.39-.23.63-.44.88-3.16,3.8-7.18,6.12-12.04,7-.13.02-.25.05-.38.08-.02,0-.02.05-.07.15,1.92.14,3.8.39,5.66.8,12.42,2.72,21.59,9.83,27.95,20.71,2.19,3.76,3.81,7.77,4.55,12.07.62,3.67-1.55,7.04-5.08,8.19-.28.09-.55.19-.82.29H5.82Z"
                        />
                        <path
                            fill="white"
                            d="m79.03,37.44c0,2.08.01,4.16,0,6.24-.03,5.03-3.9,9.1-8.91,9.3-2.14.09-4.29.02-6.43.02-.24,0-.56.1-.7-.19-.13-.28.14-.47.28-.68,5.93-8.83,5.96-20.41.07-29.32-.15-.23-.48-.46-.33-.76.16-.3.53-.16.8-.16,1.27-.01,2.54,0,3.8-.01.31,0,.73.16.91-.13.2-.31-.14-.6-.3-.86-1.89-3.12-4.21-5.85-7.08-8.11-4.96-3.91-10.59-6.19-16.9-6.6-12.59-.8-22.45,3.99-29.35,14.65-.18.28-.59.6-.37.93.2.3.67.12,1.01.12,1.3.02,2.6.02,3.9,0,.68,0,.83.19.42.78-3.59,5.09-4.81,10.81-4.4,16.92.3,4.49,1.67,8.66,4.23,12.4.17.25.55.49.37.84-.17.34-.59.2-.89.19-2.5-.07-5.01.23-7.49-.18-4.27-.7-7.6-4.53-7.66-8.85-.05-4.35-.02-8.71,0-13.07,0-1.42.32-2.8,1.06-4.01.83-1.34,1.34-2.81,1.97-4.23C12.73,10.08,22.35,2.47,36.02.46c12.22-1.79,22.98,1.65,31.93,10.25,4,3.84,6.81,8.5,8.77,13.68.16.42.29.88.54,1.25,1.38,1.94,1.88,4.09,1.78,6.45-.07,1.78-.01,3.57-.01,5.36Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 11,
        title: "Configuraciones",
        url: "configuraciones",
        textInfo:
            "Aca podras configurar tu casa, revisa las diferentes opciones que te damos!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 94.85">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m65.95,94.38c-2.01-.02-3.64-.85-4.85-2.42-2.49-3.26-5.79-5.08-9.84-5.27-4.44-.2-8.08,1.58-10.83,5.11-2.41,3.08-4.72,3.77-8.38,2.35-5.02-1.94-9.6-4.64-13.72-8.1-2.24-1.88-2.78-4.58-1.6-7.55,1.87-4.74,1.39-9.19-1.77-13.23-2.12-2.71-4.97-4.27-8.39-4.69-3.26-.4-5.37-2.26-5.92-5.48-.97-5.77-.85-11.54.36-17.26.56-2.67,2.75-4.53,5.46-4.86,6.65-.8,11.41-5.88,11.65-12.54.06-1.63-.28-3.24-.82-4.79-1.2-3.41-.44-5.85,2.42-8.05,3.85-2.96,8.04-5.29,12.56-7.03,3.11-1.19,5.69-.52,7.8,2.03,5.13,6.21,14.69,6.24,19.84.05C62.13,0,64.64-.63,67.89.63c5.01,1.95,9.61,4.61,13.75,8.05,2.39,1.99,3.09,4.43,2.06,7.45-1.44,4.23-.98,8.22,1.55,11.9,2.04,2.98,4.92,4.73,8.46,5.35,3,.53,4.87,2.23,5.46,5.21,1.15,5.86,1.08,11.72-.1,17.57-.52,2.57-2.57,4.39-5.34,4.86-5.67.96-9.64,4.57-10.91,9.92-.59,2.5-.4,4.95.48,7.36,1.29,3.55.63,5.87-2.34,8.25-3.75,3.01-7.86,5.44-12.3,7.28-.88.36-1.78.56-2.72.56Zm-32.48-46.87c.29,9.29,7.3,16.39,16.47,16.42,9.3.04,16.63-7.16,16.57-16.69-.06-9.3-7.31-16.38-16.47-16.37-9.45.02-16.41,7.33-16.57,16.63Z"
                    />
                </g>
            </svg>
        ),

        // children: [
        //     {
        //         id: "landing",
        //         title: "Como editar mi landing",
        //         url: "editar-landing",
        //         textInfo: "Configura y personaliza tu landing!",
        //         icono: (
        //             <svg
        //                 className="w-8 h-8 text-white"
        //                 viewBox="0 0 24 24"
        //                 fill="none"
        //                 stroke="currentColor"
        //                 strokeWidth="2"
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //             >
        //                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        //             </svg>
        //         ),
        //     },
        //     {
        //         id: "tema",
        //         title: "Tema de landing",
        //         url: "tema-landing",
        //         textInfo: "Cambiar el tema de tu landing!",
        //         icono: (
        //             <svg
        //                 className="w-8 h-8 text-white"
        //                 viewBox="0 0 24 24"
        //                 fill="none"
        //                 stroke="currentColor"
        //                 strokeWidth="2"
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //             >
        //                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        //             </svg>
        //         ),
        //     },
        //     {
        //         id: "garantia",
        //         title: "Garantia",
        //         url: "garantia",
        //         textInfo: "Establece un precio de grantia de tu casa!",
        //         icono: (
        //             <svg
        //                 className="w-8 h-8 text-white"
        //                 viewBox="0 0 24 24"
        //                 fill="none"
        //                 stroke="currentColor"
        //                 strokeWidth="2"
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //             >
        //                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        //             </svg>
        //         ),
        //     },
        //     {
        //         id: "bienvenida",
        //         title: "Mensaje de bienvenida",
        //         url: "mensaje-bienvenida",
        //         textInfo: "Establece un mensaje de bienvenida!",
        //         icono: (
        //             <svg
        //                 className="w-8 h-8 text-white"
        //                 viewBox="0 0 24 24"
        //                 fill="none"
        //                 stroke="currentColor"
        //                 strokeWidth="2"
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //             >
        //                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        //             </svg>
        //         ),
        //     },
        //     {
        //         id: "check",
        //         title: "Check-in - Check-out",
        //         url: "check",
        //         textInfo: "Configura el check in y check out de tu casa!",
        //         icono: (
        //             <svg
        //                 className="w-8 h-8 text-white"
        //                 viewBox="0 0 24 24"
        //                 fill="none"
        //                 stroke="currentColor"
        //                 strokeWidth="2"
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //             >
        //                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        //             </svg>
        //         ),
        //     },
        //     {
        //         id: "tarifas",
        //         title: "Tarifas adicionales",
        //         url: "tarifas-adicionales",
        //         textInfo:
        //             "Configura costos adicionales para tu casa, ej: Limpieza, Luz, etc",
        //         icono: (
        //             <svg
        //                 className="w-8 h-8 text-white"
        //                 viewBox="0 0 24 24"
        //                 fill="none"
        //                 stroke="currentColor"
        //                 strokeWidth="2"
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //             >
        //                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        //             </svg>
        //         ),
        //     },
        //     {
        //         id: "personas",
        //         title: "Personas adicionales",
        //         url: "personas-adicionales",
        //         textInfo:
        //             "Cobra algun costo extra por personas adicionales, superior a la capacidad de tu casa!",
        //         icono: (
        //             <svg
        //                 className="w-8 h-8 text-white"
        //                 viewBox="0 0 24 24"
        //                 fill="none"
        //                 stroke="currentColor"
        //                 strokeWidth="2"
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //             >
        //                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        //             </svg>
        //         ),
        //     },
        //     {
        //         id: "descuento-estadia",
        //         title: "Descuento por estadia",
        //         url: "descuento-por-estadia",
        //         textInfo:
        //             "Puedes crear descuentos , dependiendo la cantidad de dias que tus clientes se hospeden!",
        //         icono: (
        //             <svg
        //                 className="w-8 h-8 text-white"
        //                 viewBox="0 0 24 24"
        //                 fill="none"
        //                 stroke="currentColor"
        //                 strokeWidth="2"
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //             >
        //                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        //             </svg>
        //         ),
        //     },
        //     {
        //         id: "cupones",
        //         title: "Cupones",
        //         url: "cupones",
        //         textInfo:
        //             "Puedes crear cupones de descuento para tus clientes recurrentes!",
        //         icono: (
        //             <svg
        //                 className="w-8 h-8 text-white"
        //                 viewBox="0 0 24 24"
        //                 fill="none"
        //                 stroke="currentColor"
        //                 strokeWidth="2"
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //             >
        //                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        //             </svg>
        //         ),
        //     },
        //     {
        //         id: "disponibilidad-minima",
        //         title: "Disponibilidad minima",
        //         url: "minima-disponibilidad",
        //         textInfo:
        //             "Elige la estadia minima  de tu casa (siempre y en dias especiales!)",
        //         icono: (
        //             <svg
        //                 className="w-8 h-8 text-white"
        //                 viewBox="0 0 24 24"
        //                 fill="none"
        //                 stroke="currentColor"
        //                 strokeWidth="2"
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //             >
        //                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        //             </svg>
        //         ),
        //     },
        //     {
        //         id: "disponibilidad-maxima",
        //         title: "Disponibilidad maxima",
        //         url: "maxima-disponibilidad",
        //         textInfo:
        //             "Elige la estadia maxima  de tu casa (siempre y en dias especiales!) ",
        //         icono: (
        //             <svg
        //                 className="w-8 h-8 text-white"
        //                 viewBox="0 0 24 24"
        //                 fill="none"
        //                 stroke="currentColor"
        //                 strokeWidth="2"
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //             >
        //                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        //             </svg>
        //         ),
        //     },
        //     {
        //         id: "conexiones-externas",
        //         title: "Conexiones externas",
        //         url: "conexiones-externas",
        //         textInfo:
        //             "Conecta tu casa con BLUECORNER, asi para compartir un mismo calendario de reservas!",
        //         icono: (
        //             <svg
        //                 className="w-8 h-8 text-white"
        //                 viewBox="0 0 24 24"
        //                 fill="none"
        //                 stroke="currentColor"
        //                 strokeWidth="2"
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //             >
        //                 <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        //             </svg>
        //         ),
        //     },
        // ],
    },
];

interface Props {
    setShowBurgerMenu: (visible: boolean) => void;
    showBurgerMenu: boolean;
}

export const BurguerMenuMobile = ({
    setShowBurgerMenu,
    showBurgerMenu,
}: Props) => {
    const { currency } = useAppSelector((state) => state.informacion);

    const [showChildren, setShowChildren] = useState<number>(-1);
    const navigate = useNavigate();
    const { plan } = useAppSelector((state) => state.landing_house.house);
    const { avatar, nombre, apellido, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const handleNavigate = (title: any) => {
        if (title.url) navigate(`/dashboard/${title.url}`);
        else {
            if (showChildren === title.id) setShowChildren(-1);
            else setShowChildren(title.id);
        }
    };

    const handleChildrenClick = (url: string) => {
        navigate(`/dashboard/${url}`);
    };

    const ocultar = (titulo: string) => {
        const nombres = [
            "personas",
            "disponibilidad-maxima",
            "descuento-estadia",
            "cupones",
            "Chat",
            "CRM",
            "Estadisticas",
            "mercadopago",
            "Sub usuarios",
        ];

        if (nombres.includes(titulo) && plan < 2) {
            return "hidden";
        } else {
            return "";
        }
    };

    const opcionesPremium = (titulo: string) => {
        const nombres = [
            "personas",
            "disponibilidad-maxima",
            "descuento-estadia",
            "cupones",
            "Chat",
            "CRM",
            "Estadisticas",
            "mercadopago",
            "Sub usuarios",
        ];

        // if (nombres.includes(titulo)) {
        //     return true;
        // } else {
        //     return false;
        // }

        if (nombres.includes(titulo) && plan < 2) {
            return true;
        } else {
            return false;
        }
    };
    return (
        <div
            className={`${
                showBurgerMenu ? "translate-x-0" : "translate-x-full"
            } sm:hidden fixed flex flex-col overflow-auto top-0 right-0 left-0 bottom-0 transition-all duration-500 ease-in-out z-50 bg-[#029BFC] text-white`}>
            <button
                onClick={() => setShowBurgerMenu(false)}
                className="fixed p-1 font-medium right-1 hover:underline w-max">
                Cerrar
            </button>
            <div className="mt-10">
                {TITLES.map((title) => (
                    <div
                        className={`transition ease-in cursor-pointer p-1 ${ocultar(
                            title.title
                        )} ${
                            tipo_usuario === 4 &&
                            (title.id === 9 || title.id === 7)
                                ? "hidden"
                                : ""
                        }`}
                        key={title.id}>
                        <button
                            className="w-full"
                            disabled={opcionesPremium(title.title)}
                            title={
                                opcionesPremium(title.title)
                                    ? "opcion estándar"
                                    : ""
                            }>
                            <div
                                className={` flex flex-col  gap-2  w-full ${
                                    opcionesPremium(title.title)
                                        ? " bg-slate-300 bg-opacity-20 mr-2 py-2 rounded-md pl-1 cursor-not-allowed"
                                        : " cursor-pointer "
                                }`}>
                                <div
                                    className="flex items-center gap-2"
                                    onClick={() => handleNavigate(title)}>
                                    <span className="text-white">
                                        {title.icono}
                                    </span>
                                    <span className="font-medium capitalize ">
                                        {title.title}
                                    </span>
                                    {opcionesPremium(title.title) && (
                                        <span className="text-[10px] border border-yellow-300 text-yellow-300 p-[2px] rounded-md">
                                            Estándar
                                        </span>
                                    )}
                                </div>
                                <div
                                    style={{
                                        height:
                                            showChildren === title.id
                                                ? `${
                                                      title.children &&
                                                      title.children.length *
                                                          2.5
                                                  }rem`
                                                : "0rem",
                                    }}
                                    className={`${
                                        showChildren === title.id
                                            ? `opacity-100 visible`
                                            : "opacity-0 invisible"
                                    } transition-all duration-300 ease-in-out flex items-start flex-col`}>
                                    {title.children &&
                                        title.children.map((children) => (
                                            <button
                                                className={
                                                    currency === "EUR" &&
                                                    children.id ===
                                                        "mercadopago"
                                                        ? "hidden"
                                                        : ""
                                                }
                                                disabled={opcionesPremium(
                                                    children.id
                                                )}
                                                title={
                                                    opcionesPremium(children.id)
                                                        ? "opcion estándar"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    handleChildrenClick(
                                                        children.url
                                                    )
                                                }
                                                key={children.id}>
                                                <div
                                                    className={`  flex items-center p-2 pl-4 lg:pl-10 ${
                                                        opcionesPremium(
                                                            children.id
                                                        )
                                                            ? "bg-slate-300 bg-opacity-20 mr-2 py-2 rounded-md pl-1 cursor-not-allowed"
                                                            : "hover:underline hover:scale-105 "
                                                    }`}>
                                                    <span className="mr-1">
                                                        {children.title}
                                                    </span>
                                                    {/* <BotonInformacion
                                                        text={children.textInfo}
                                                        widthHeight={"w-4 h-4"}
                                                    /> */}
                                                    {opcionesPremium(
                                                        children.id
                                                    ) && (
                                                        <span className="text-[10px] border border-yellow-300 text-yellow-300 p-[2px] rounded-md">
                                                            Estándar
                                                        </span>
                                                    )}
                                                </div>
                                            </button>
                                        ))}
                                </div>
                            </div>
                        </button>
                        <hr className="mt-2" />
                    </div>
                ))}
            </div>
        </div>
    );
};
