import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { async } from "q";
import { Cuota } from "../../dashboardPropietarios/suscripcion/CheckoutSuscripcionRechazada";

interface Props {
    modalVisible: boolean;
    setModalVisible: (isOpen: boolean) => void;
}

interface RegisterForm {
    nombre?: string;
    apellido?: string;
    correo?: string;
}

type errorObject = { [key: string]: string };

const INITIAL_STATE: RegisterForm = {
    nombre: "",
    apellido: "",
    correo: "",
};

interface CuotaInfo {
    fecha_vencimiento: string;
    costo: string;
    cuota: number;
}
interface INITAL_STATE_TYPE {
    [key: string]: string;
}

const ModalSuscripcionAvanzada = ({ modalVisible, setModalVisible }: Props) => {
    const { id_casa, nombre } = useParams();
    const [input, setInput] = useState<RegisterForm>(INITIAL_STATE);
    const [errors, setErrors] = useState<RegisterForm>({});
    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        plan: "Basico",
        tipo_pago: "Anual",
        cuota: "1",
    });
    const [cuotas, setCuotas] = useState<CuotaInfo[]>([
        {
            fecha_vencimiento: "",
            costo: "",
            cuota: 1,
        },
    ]);

    const plan = ["Basico", "Premium"];
    const tipo_pago = ["Anual"];
    const cuota = ["1", "2", "3"];

    const INPUTSELECT = [
        {
            input_id: 0,
            label: "Plan",
            nombre: "plan",
            option: plan,
        },
        {
            input_id: 1,
            label: "Tipo de pago",
            nombre: "tipo_pago",
            option: tipo_pago,
        },
        {
            input_id: 2,
            label: "Cantidad de cuotas",
            nombre: "cuota",
            option: cuota,
        },
    ];

    const handleInputSelectChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === "cuota") {
            // Actualizar las cuotas
            const cuotaCount = parseInt(e.target.value);
            const newCuotas: CuotaInfo[] = [];

            for (let i = 1; i <= cuotaCount; i++) {
                newCuotas.push({
                    fecha_vencimiento: "",
                    costo: "",
                    cuota: i,
                });
            }

            setCuotas(newCuotas);
        }
    };

    const handleCuotaInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number,
        key: keyof CuotaInfo
    ) => {
        if (key !== "cuota") {
            const newCuotas = [...cuotas];
            newCuotas[index][key] = e.target.value;
            setCuotas(newCuotas);
        }
    };

    const validateCuotas = (cuotas: CuotaInfo[]) => {
        const errores: errorObject = {};

        cuotas.forEach((cuota, index) => {
            if (!cuota.fecha_vencimiento) {
                errores[`fecha_${index}`] = `La cuota #${
                    index + 1
                } debe tener una fecha válida, `;
            }
            if (!cuota.costo) {
                errores[`valor_${index}`] = `La cuota #${
                    index + 1
                } debe tener un valor válido, `;
            }
        });

        return errores;
    };
    const handleSubmit = async () => {
        const cuotaErrors = validateCuotas(cuotas);

        let costo = 0;

        cuotas.map((cuota) => {
            costo = costo + Number(cuota.costo);
        });

        if (Object.keys(cuotaErrors).length === 0) {
            const json = await fetchAxiosToken({
                url: `/suscripcion_pagos/postSuscripcionByAdmin`,
                method: "post",
                body: {
                    id_casa: id_casa,
                    tipo_pago: userInfo.tipo_pago,
                    plan: userInfo.plan,
                    cuotas: cuotas,
                    costo: costo,
                },
            });
            console.log(json);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                icon: "success",
                title: "suscripcion creada con exito",
            });
            setModalVisible(false);
        } else {
            const errorMessages = Object.values(cuotaErrors).join("\n");
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                icon: "error",
                title: "Error en las cuotas",
                text: errorMessages,
            });
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto md:p-10">
                <h2 className="text-base font-medium">Suscripcion Avanzada</h2>
                {INPUTSELECT.map(({ nombre, input_id, label, option }) => (
                    <div key={input_id} className="w-full max-w-xl">
                        <label className="font-medium">{label}</label>
                        <select
                            onChange={handleInputSelectChange}
                            name={nombre}
                            value={userInfo[nombre] || ""}
                            className="w-full p-2 py-[9px] mt-1 border rounded-xl">
                            {option.map((banco, i) => {
                                return (
                                    <option
                                        key={i}
                                        className="bg-transparent"
                                        value={banco}>
                                        {banco}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                ))}

                <hr className="w-full bg-slate-400"></hr>

                {cuotas.map((cuota, index) => (
                    <div key={index}>
                        <p className="font-medium">Cuota #{index + 1}</p>
                        <div className="grid grid-cols-2 gap-2">
                            <div>
                                <div className="w-full max-w-xl">
                                    <label className="font-medium">
                                        Fecha de vencimiento
                                    </label>
                                    <input
                                        className="w-full p-2 mt-1 border rounded-xl"
                                        value={cuota.fecha_vencimiento}
                                        onChange={(e) =>
                                            handleCuotaInputChange(
                                                e,
                                                index,
                                                "fecha_vencimiento"
                                            )
                                        }
                                        type="date"
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="w-full max-w-xl">
                                    <label className="font-medium">
                                        Valor de la cuota (USD)
                                    </label>
                                    <input
                                        className="w-full p-2 mt-1 border rounded-xl"
                                        value={cuota.costo}
                                        onChange={(e) =>
                                            handleCuotaInputChange(
                                                e,
                                                index,
                                                "costo"
                                            )
                                        }
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                <div className="flex flex-col gap-4 mt-8">
                    <button
                        onClick={() => handleSubmit()}
                        className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                        Crear
                    </button>
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};

export default ModalSuscripcionAvanzada;
