import { useEffect, useState } from "react";

import { useAppSelector } from "../../hooks/hooks";
import useCurrencyPrices from "../../hooks/useCurrencyPrices";
import { CapacidadesCasa } from "../capacidades/CapacidadesCasa";
import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "../../assets/css/swiper_pagination.css";

import { ModalEditImageCarrousel } from "../modalEdit/ModalEditImageCarrousel";
import { ModalEditPrecioBase } from "../modalEdit/ModalEditPrecioBase";
import { ModalGaleriaImagenes } from "../modalEdit/ModalGaleriaImagenes";
import { ModalEditName } from "../modalEdit/ModalEditName";
import { usePriceToSelectedCurrency } from "../../hooks/usePriceToSelectedCurrency";
import { formatearNumero } from "../../components/helpers/formatearNumero";
interface Props {
    toggleButton: boolean;
}

interface Input {
    precio_base: string;
    tipo_moneda: string;
}

const Carrousel = ({ toggleButton }: Props) => {
    const {
        nombre,
        banner,
        casa_imagenes,
        precio_base,

        estadia_minima,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);

    const [modalVisibleCarrousel, setModalVisibleCarrousel] =
        useState<boolean>(false);
    const [editPrice, setEditPrice] = useState<boolean>(false);
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [editName, setEditName] = useState<boolean>(false);
    const { price_to_currency } = usePriceToSelectedCurrency();
    const { SELECTED_CURRENCY } = useCurrencyPrices();

    const [input, setInput] = useState<Input>({
        precio_base: precio_base,
        tipo_moneda: SELECTED_CURRENCY,
    });

    const [modalImagesVisible, setModalImagesVisible] = useState(false);

    useEffect(() => {
        if (banner) {
            setTimeout(() => {
                setIsFocus(true);
            }, 1500);
        }
    }, [banner]);

    useEffect(() => {
        setInput({
            precio_base: precio_base,
            tipo_moneda: SELECTED_CURRENCY,
        });
    }, [precio_base]);

    return (
        <div className="flex flex-col items-center bg-white lg:flex-row md:items-end">
            {modalVisibleCarrousel && (
                <ModalEditImageCarrousel
                    modalVisible={modalVisibleCarrousel}
                    setModalVisible={setModalVisibleCarrousel}
                />
            )}

            {modalImagesVisible && (
                <ModalGaleriaImagenes
                    imagen_principal={banner}
                    imagenes={casa_imagenes}
                    modalVisible={modalImagesVisible}
                    setModalVisible={setModalImagesVisible}
                />
            )}

            {editPrice && (
                <ModalEditPrecioBase
                    modalVisible={editPrice}
                    setModalVisible={setEditPrice}
                />
            )}

            {editName && (
                <ModalEditName
                    modalVisible={editName}
                    setModalVisible={setEditName}
                />
            )}
            <div className="relative w-full lg:w-2/3">
                <div>
                    <img
                        src={
                            typeof banner === "string"
                                ? `${process.env.REACT_APP_PROXY}/casa/getImage/${banner}`
                                : URL.createObjectURL(banner)
                        }
                        className="object-cover w-full h-full 2xl:h-[650px] xl:h-[500px] md:h-[500px] lg:rounded-br-xl "
                        alt={`banner`}
                    />
                </div>
                {toggleButton && (
                    <div
                        onClick={() => setModalVisibleCarrousel(true)}
                        className="absolute w-full h-[2.5rem] top-10 left-4"
                    >
                        <div className="flex ">
                            <div
                                title="editar fotos"
                                className="flex flex-col justify-center items-center z-20 px-2 py-1 mr-6 rounded-lg cursor-pointer opacity-60 bg-slate-200 hover:opacity-100"
                            >
                                <svg
                                    className="w-12 h-12 text-[#1C4CBB]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                    <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                    />
                                </svg>
                                <div className="text-xs text-[#1C4CBB] text-center">
                                    Editar Fotos
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div
                    className={`${
                        !isFocus ? "hidden" : "opacity-100"
                    } transition-all ease-in-out duration-700 absolute hidden md:flex gap-3 px-10 sm:px-16 py-20 items-end justify-start h-full w-full lg:rounded-br-xl  bg-gradient-to-t from-black/70 top-0`}
                >
                    <div className="flex items-center justify-center gap-10 2xl:gap-20 ">
                        <div className="flex items-center justify-center">
                            <div className="mt-2 font-bold text-white uppercase text-4xl lg:text-5xl 2xl:text-6xl">
                                {nombre}
                            </div>
                            {toggleButton && (
                                <div
                                    onClick={() => setEditName(!editName)}
                                    title="editar nombre"
                                    className="flex flex-col items-center px-2 py-1 my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                                >
                                    <svg
                                        className="w-10 h-10 text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                        <line
                                            x1="13.5"
                                            y1="6.5"
                                            x2="17.5"
                                            y2="10.5"
                                        />
                                    </svg>
                                    <div className="text-xs text-[#1C4CBB] text-center">
                                        Editar Nombre
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="flex justify-center select-none">
                            <CapacidadesCasa
                                toggleButton={toggleButton}
                                colorIcono={"#ffffff"}
                                opacity={"0%"}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center mt-5 md:mt-10 md:hidden">
                <div className="flex ">
                    <CapacidadesCasa
                        toggleButton={toggleButton}
                        colorIcono={"#1C4CBB"}
                    />
                </div>
                <div className="flex ">
                    <div className="text-[#1C4CBB] text-2xl text-center mt-2 font-bold uppercase">
                        {nombre}
                    </div>
                    {toggleButton && (
                        <div
                            onClick={() => setEditName(!editName)}
                            title="editar nombre"
                            className="flex flex-col items-center px-2 py-1 my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                        >
                            <svg
                                className="w-10 h-10 text-[#1C4CBB]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                            </svg>
                            <div className="text-xs text-[#1C4CBB] text-center">
                                Editar Nombre
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div
                className={` flex flex-col gap-3  items-center justify-center lg:items-end lg:justify-end lg:w-1/3 w-full h-full lg:mx-10`}
            >
                <div className="flex items-center">
                    <div className="w-full justify-center text-center lg:text-right text-[#1c4cbb] mt-5 lg:mt-0">
                        <p className="mb-1 text-xl font-light ">DESDE</p>
                        <p className="mb-1 text-2xl font-bold md:text-4xl lg:text-5xl 2xl:text-6xl ">
                            {SELECTED_CURRENCY}{" "}
                            {formatearNumero(
                                price_to_currency(Number(precio_base))
                            )}
                        </p>
                        <p className="mt-1 text-xl font-light lg:text-3xl 2xl:text-4xl ">
                            por noche
                        </p>
                        <p className="mt-1 text-lg lg:text-2xl 2xl:text-3xl xs:mb-8 opacity-60 ">
                            Mínimo {estadia_minima}{" "}
                            {estadia_minima === 1 ? "noche" : "noches"}
                        </p>
                    </div>
                    {toggleButton && (
                        <div
                            onClick={() => setEditPrice(!editPrice)}
                            title="editar Precio base"
                            className="flex flex-col items-center justify-center px-2 py-1 mx-auto my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                        >
                            <svg
                                className="w-8 h-8 text-[#1C4CBB]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                            </svg>
                            <div className="text-xs text-[#1C4CBB] text-center">
                                Editar Precio
                            </div>
                        </div>
                    )}
                </div>

                <div className="w-full px-5 lg:p-0">
                    <Swiper
                        modules={[Navigation, Autoplay, EffectFade, Pagination]}
                        className="h-60 lg:h-40 sample-slider"
                        spaceBetween={20}
                        slidesPerView={1}
                        autoplay={{ delay: 5000 }}
                        breakpoints={{
                            560: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                        }}
                        loop
                        pagination={{ clickable: true }}
                    >
                        {casa_imagenes.map((image, index) => (
                            <SwiperSlide key={index}>
                                <div className="h-full ">
                                    <img
                                        src={`${process.env.REACT_APP_PROXY}/casa/getImage/${image.imagen}`}
                                        className="object-cover w-full h-full mx-auto rounded-lg"
                                        alt={`slide ${index + 1}`}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <div
                    onClick={() => setModalImagesVisible(true)}
                    className="flex justify-center items-center mt-2 bg-[#0096fc] text-white text-sm  2xl:text-base py-3 md:py-4 px-12 md:px-16 rounded-lg mx-auto text-center cursor-pointer hover:scale-105"
                >
                    <p className="font-bold"> GALERÍA</p>
                </div>
            </div>
        </div>
    );
};

export default Carrousel;
