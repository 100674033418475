import React, { useState, useEffect } from "react";
import ToggleButton from "../../../components/helpers/ToggleButton";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import imagen1 from "./../../../img/mercado_pago/1.webp";
import imagen2 from "./../../../img/mercado_pago/2.webp";
import imagen3 from "./../../../img/mercado_pago/3.webp";
import imagen4 from "./../../../img/mercado_pago/4.webp";
import imagen5 from "./../../../img/mercado_pago/8.webp";
import imagen6 from "./../../../img/mercado_pago/9.webp";
import { MicuentaBancaria } from "../MicuentaBancaria";
import Swal from "sweetalert2";

import Spinner from "../../../components/helpers/Spinner";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { verifyToken } from "../../../redux/reducers/authReducer";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

export const MediosDePago = () => {
    const usuarioLogeado = useAppSelector((state) => state.usuario_logeado);

    const casa = useAppSelector((state) => state.landing_house);
    console.log(casa);
    const dispatch = useAppDispatch();
    const [saving, setSaving] = useState<boolean>(false);
    const [toggleButton, setToggleButton] = useState<boolean | null>(null);
    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        id_casa: "",
        publica: "",
        privada: "",
    });
    const getHouseFromTenant = async () => {
        try {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/dominio`,
            });
            console.log(data);
            setToggleButton(data.is_mercado_pago === "Si" ? true : false);
            setUserInfo({
                id_casa: data.id,
                publica: data.llave_publica_mp ? data.llave_publica_mp : "",
                privada: data.llave_privada_mp ? data.llave_privada_mp : "",
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getHouseFromTenant();
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Navbar titulo="Cuenta para Transferir">
                <div className="w-full">
                    <div>
                        <MicuentaBancaria />
                    </div>
                </div>
            </Navbar>
            <NavbarMobile />
        </div>
    );
};
