interface Props {
    date: string;
}

export const transformDatesToSlash = ({ date }: Props): string | number => {
   
    if (date) {
        const final_date = date.replaceAll("-", "/");
     
        return final_date;
    }
    return 0;
};
