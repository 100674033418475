import React, { ReactNode, useState } from "react";

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";

import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { InputDate } from "../../../components/InputDate";

import ButtonCopyToClipboard from "./ButtonCopyToClipboard";

interface Input {
    id_casa: number | null;

    descuento: string;
    fecha_expiracion: string;
}

interface Cupon {
    descuento: string;
    fecha_expiracion: string;
    id: number;
    id_casa: number;
    usado: string;
    cupon: string;
}

interface ModalProps {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    cuponSelect: Cupon;
    setCuponSelect: (s: Cupon | null) => void;
}

export const ModalEditCoupon = ({
    modalVisible,
    setModalVisible,
    cuponSelect,
    setCuponSelect,
}: ModalProps) => {
    registerLocale("es", es);
    const dispatch = useAppDispatch();

    const {
        id: id_casa,
        id_propietario,
        tipo_moneda,
    } = useAppSelector((state) => state.landing_house.house);

    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState<Input>({
        id_casa: cuponSelect.id_casa,

        descuento: cuponSelect.descuento,
        fecha_expiracion: cuponSelect.fecha_expiracion,
    });

    const handleSubmit = async () => {
        if (input.fecha_expiracion === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes seleccionar la fecha de expiracion ",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }

        if (input.descuento === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar el valor a descontar",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }

        try {
            setLoading(true);
            const data = await fetchAxiosToken({
                url: `/descuento_cupones/put/${cuponSelect.id}`,
                body: {
                    ...input,
                },
                method: "put",
            });

            setCuponSelect(null);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Actualizado con exito",

                icon: "success",
                confirmButtonText: "Ok",
            });
            setModalVisible(false);
            setLoading(false);
        } catch (error: any) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: `${error.response.data.error}`,
                icon: "error",
                confirmButtonText: "Ok",
            });
            setLoading(false);
        }
    };

    return (
        <>
            <ResponsiveModalAbsolute
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}>
                <h1 className="2xl:text-lg text-base font-medium text-center">
                    Editar Cupon
                </h1>
                <div className="flex flex-col max-w-md gap-4 p-4 mx-auto ">
                    <div className="flex flex-col items-center justify-center">
                        <>
                            <hr className="w-full my-4 bg-gray-300" />
                            <div className="w-full">
                                <h2 className="2xl:text-base text-sm font-medium text-left">
                                    Fecha de expiración
                                </h2>
                                <div className="flex items-center w-full mt-2">
                                    <label className="2xl:text-lg text-base font-medium ">
                                        Fecha:
                                    </label>
                                    <InputDate
                                        className="w-8/12 p-2 ml-6 bg-white border rounded-lg cursor-pointer 2xl:text-base text-sm"
                                        selectedDay={input.fecha_expiracion}
                                        placeholderText="Seleccionar fecha"
                                        onChange={(date) =>
                                            setInput({
                                                ...input,
                                                fecha_expiracion: date
                                                    ? date
                                                    : "",
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </>

                        <hr className="w-full my-4 bg-gray-300" />
                        <div className="w-full">
                            <h2 className="2xl:text-base text-sm font-medium text-left">
                                Descuento
                            </h2>
                            <div className="flex items-center w-full mt-2">
                                <label className="2xl:text-lg text-base font-medium ">
                                    Numero:
                                </label>

                                <div className="w-7/12  ml-1 pl-[1px] 2xl:text-base text-sm  xs:px-2 rounded-lg 2xl:py-1 py-0.5 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400 flex justify-between items-center">
                                    <span>{cuponSelect.cupon}</span>

                                    <ButtonCopyToClipboard
                                        confirmationText="Cupón copiado al portapapeles correctamente"
                                        textToCopy={cuponSelect.cupon}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center w-full mt-2">
                                <label className="2xl:text-lg text-base font-medium ">
                                    Valor: ({tipo_moneda})
                                </label>

                                <div className=" w-28  ml-2 pl-[1px]  xs:px-2 rounded-lg py-2 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400 flex">
                                    <p>-</p>
                                    <input
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                descuento: e.target.value,
                                            })
                                        }
                                        autoComplete="off"
                                        className="w-full px-1 rounded-lg outline-none 2xl:text-base text-sm"
                                        placeholder=" 50"
                                        value={input.descuento}
                                        name="descuento_propietario"
                                        type="number"></input>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button
                        disabled={loading}
                        onClick={() => handleSubmit()}
                        className="p-3 mt-6 font-medium text-white border rounded-lg bg-[#029BFC] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default 2xl:text-base text-sm">
                        Actualizar cupon
                    </button>
                </div>
            </ResponsiveModalAbsolute>
        </>
    );
};
