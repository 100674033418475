import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";

export interface Cuotas {
    id: number;
    id_casa: number;
    plan: string;
    costo: string;
    fecha_pago: string;
    fecha_vencimiento: string;
    tipo_pago: string;
    estado: string;
    casa: Casa;
    suscripcion_cuotas: SuscripcionCuota[];
    tipo_moneda: string;
}

export interface SuscripcionCuota {
    cuota: number;
    costo: string;
    estado: string;
    metodo: string;
    imagen: string;
    fecha_vencimiento: string;
    fecha_inicio: string;
    fecha_pago: string;
    motivo_rechazo: string;
    id: number;
}
export interface Casa {
    id: number;
    nombre: string;
    banner: string;
}

export interface PagoSuscricion {
    id: number;
    id_casa: number;
    plan: string;
    costo: string;
    fecha_pago: string;
    fecha_vencimiento: string;
    fecha_inicio: string;
    tipo_pago: string;
    estado: string;
    motivo_rechazo: string;
    metodo: string;
    imagen: string;
    casa: Casa;
}

interface Props {
    setModalVisible: (modalVisible: boolean) => void;
    modalVisible: boolean;
    pagoSelect: PagoSuscricion;
}

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

export const ModalOnePago = ({
    setModalVisible,
    modalVisible,
    pagoSelect,
}: Props) => {
    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        password2: "",
        password: "",
    });

    const [pago, setPago] = useState(pagoSelect);
    const [cuotas, setCuotas] = useState<Cuotas>();

    const INPUTS = [
        {
            input_id: 3,
            label: "Nueva contraseña",
            nombre: "password",
            type: "password",
        },
        {
            input_id: 4,
            label: "Escribir nuevamente",
            nombre: "password2",
            type: "password",
        },
    ];

    const [saving, setSaving] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const getOne = async () => {
        try {
            const json = await fetchAxiosToken({
                url: `/suscripcion_pagos/getOne/${pagoSelect.id}`,
                method: "get",
            });
            console.log(json);
            setCuotas(json);
        } catch (error: any) {
            console.log(error);
            setSaving(false);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: `${error.response.data.error}`,
                showConfirmButton: true,
                // timer: 1500,
            });
        }
    };

    useEffect(() => {
        getOne();
    }, []);
    console.log("cuoras", cuotas);
    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-base font-medium">Detalle</h2>
                <hr className="w-full bg-gray-300" />
                {pago && (
                    <div className="p-2 mt-4 border border-[#1C4CBB] rounded-lg w-72 text-[#1C4CBB]">
                        <div className="flex justify-between ">
                            <label>Plan</label>
                            <span>{pago.plan}</span>
                        </div>
                        <div className="flex justify-between ">
                            <label>Tipo</label>
                            <span>{pago.tipo_pago}</span>
                        </div>
                        <div className="flex justify-between w-full">
                            <label>Fecha inicial</label>
                            <span>
                                <ShowDateToUser
                                    date={pago?.fecha_inicio?.split("T")[0]}
                                />
                            </span>
                        </div>
                        <div className="flex justify-between w-full">
                            <label>Fecha final</label>
                            <span>
                                <ShowDateToUser
                                    date={
                                        pago?.fecha_vencimiento?.split("T")[0]
                                    }
                                />
                            </span>
                        </div>
                    </div>
                )}
                {cuotas ? (
                    cuotas?.suscripcion_cuotas.map((cuota) => (
                        <div key={cuota.cuota}>
                            {cuota.metodo === "Transferencia" && (
                                <div className=" w-72 h-72">
                                    <img
                                        src={
                                            `${process.env.REACT_APP_PROXY}/suscripcion_pagos/getImage/` +
                                            cuota?.imagen
                                        }
                                        alt="icono"
                                        className="object-cover w-full h-full rounded-lg"
                                    />
                                </div>
                            )}

                            <div className="w-72">
                                <div className="w-full p-2 mt-4 border rounded-lg">
                                    <div className="flex items-center justify-around mb-2">
                                        <p className="text-base font-medium ">
                                            ID #{cuota.id}
                                        </p>
                                        {cuota.estado === "Rechazado" ||
                                            (cuota.estado === "Pendiente" && (
                                                <p
                                                    onClick={() =>
                                                        navigate(
                                                            `/checkout/pagar/suscripcion/${cuota.id}`
                                                        )
                                                    }
                                                    className="px-2 pt-1 pb-2 text-sm text-white border rounded-lg cursor-pointer hover:scale-105 bg-blue-blueFrom">
                                                    Pagar
                                                </p>
                                            ))}
                                    </div>
                                    <hr />
                                    <div className="flex justify-between mt-2 ">
                                        <label>Método de pago</label>
                                        <span>{cuota.metodo}</span>
                                    </div>

                                    <div className="flex justify-between ">
                                        <label>Costo</label>
                                        <span>
                                            {cuota.costo} {cuotas.tipo_moneda}
                                        </span>
                                    </div>
                                    <div className="flex justify-between">
                                        <label>Estado de pago</label>
                                        <span
                                            className={`${
                                                cuota.estado === "Aceptado"
                                                    ? "text-green-600"
                                                    : cuota.estado ===
                                                      "Revision"
                                                    ? "text-orange-300"
                                                    : "text-red-500"
                                            } uppercase font-medium`}>
                                            {cuota.estado}
                                        </span>
                                    </div>
                                    {cuota.estado !== "Aceptado" &&
                                        cuota.motivo_rechazo && (
                                            <div className="flex flex-col justify-between my-2">
                                                <label>
                                                    Motivo del rechazo
                                                </label>
                                                <span className="p-1 my-2 border-y">
                                                    {cuota.motivo_rechazo}
                                                </span>
                                            </div>
                                        )}
                                    {cuota.estado !== "Aceptado" &&
                                        pago.motivo_rechazo && (
                                            <div className="flex flex-col justify-between ">
                                                <label>
                                                    Motivo del rechazo
                                                </label>
                                                <span className="p-1 my-1 border-y">
                                                    {cuota.motivo_rechazo}
                                                </span>
                                            </div>
                                        )}

                                    {cuota.metodo === "Stripe" ? (
                                        <div className="flex justify-between w-full">
                                            <label>Fecha de registro</label>
                                            <span>
                                                <ShowDateToUser
                                                    date={
                                                        cuota?.fecha_pago.split(
                                                            "T"
                                                        )[0]
                                                    }
                                                />
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="flex justify-between w-full">
                                            <label>Fecha de carga</label>
                                            <span>
                                                <ShowDateToUser
                                                    date={
                                                        cuota?.fecha_pago?.split(
                                                            "T"
                                                        )[0]
                                                    }
                                                />
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <Spinner />
                )}
            </div>
        </ResponsiveModalAbsolute>
    );
};
