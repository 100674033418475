import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

export interface UsuariosCasa {
    usuarioPropietario: UsuarioPropietario[];
    usuarioSubpropietario: UsuarioSubpropietario[];
}

export interface UsuarioPropietario {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    password: string;
    celular: string;
    grupo_trabajo: string;
    tipo_usuario: number;
    avatar: string;
    habilitado: string;
    fecha_registro: string;
    id_casa: any;
    banco: string;
    tipo_cuenta: string;
    tipo_moneda: string;
    numero_cuenta: string;
    cci: string;
    titular_cuenta: string;
    tipo_documento: string;
    numero_documento: string;
    token_password: string;
}

export interface UsuarioSubpropietario {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    password: string;
    celular: string;
    grupo_trabajo: string;
    tipo_usuario: number;
    avatar: string;
    habilitado: string;
    fecha_registro: string;
    id_casa: number;
    banco: any;
    tipo_cuenta: any;
    tipo_moneda: string;
    numero_cuenta: any;
    cci: any;
    titular_cuenta: any;
    tipo_documento: any;
    numero_documento: any;
    token_password: any;
}

interface Props {
    usuariosCasa: UsuariosCasa;
    modalVisible: boolean;
    setModalVisible: (isOpen: boolean) => void;
    setUsuariosCasa: (u: UsuariosCasa) => void;
}

interface RegisterForm {
    nombre?: string;
    apellido?: string;
    correo?: string;
}

type errorObject = { [key: string]: string };

const INITIAL_STATE: RegisterForm = {
    nombre: "",
    apellido: "",
    correo: "",
};

const ModalUsuariosCasa = ({
    modalVisible,
    setModalVisible,
    usuariosCasa,
    setUsuariosCasa,
}: Props) => {
    const [input, setInput] = useState<RegisterForm>(INITIAL_STATE);
    const [errors, setErrors] = useState<RegisterForm>({});
    const [propietario, setPropietario] = useState<UsuarioPropietario>(
        usuariosCasa?.usuarioPropietario[0]
    );
    const [subPropietario, setSubPropietario] = useState<
        UsuarioSubpropietario[]
    >(usuariosCasa?.usuarioSubpropietario);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const validateInput = (form: RegisterForm) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        let errores: errorObject = {};

        if (!form.nombre) {
            errores.nombre = "Debe ingresar un nombre válido";
        } else if (!form.apellido) {
            errores.apellido = "Debe ingresar un apellido válido";
        } else if (!regExpEmail.test(form.correo || "")) {
            errores.correo = "Debe ingresar un dirección de email válida";
        }

        return errores;
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto md:p-10">
                <h2 className="text-base font-medium">
                    Usuarios administradores
                </h2>
                <label className="font-medium ">Propietario</label>
                <div className="w-full text-center ">
                    <div className="flex justify-around gap-2">
                        <div>
                            <label className="ml-1 text-lg font-semibold">
                                Nombre
                            </label>

                            <p className="w-full pt-2 ">
                                {propietario?.nombre}
                            </p>
                        </div>
                        <div>
                            <label className="ml-1 text-lg font-semibold">
                                Apellido
                            </label>

                            <p className="w-full pt-2 ">
                                {propietario?.apellido}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-full text-center">
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <label className="text-lg font-semibold ">
                                Documento ({propietario?.tipo_documento})
                            </label>

                            <p className="w-full pt-2 ">
                                {propietario?.numero_documento}
                            </p>
                        </div>
                        <div>
                            <label className="text-lg font-semibold ">
                                Telefono
                            </label>

                            <p className="w-full pt-2 ">
                                {propietario?.celular}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-full">
                    <label className="text-lg font-semibold ">
                        Correo electrónico
                    </label>

                    <p className="w-full pt-2 ">{propietario?.correo}</p>
                </div>
                <hr className="w-full bg-slate-400"></hr>
                <label className="font-medium ">Sub propietarios</label>
                {subPropietario.map((sub) => {
                    return (
                        <div key={sub.id} className="w-full gap-4">
                            <div className="w-full text-center ">
                                <div className="flex justify-around gap-2">
                                    <div>
                                        <label className="ml-1 text-lg font-semibold">
                                            Nombre
                                        </label>

                                        <p className="w-full pt-2 ">
                                            {sub?.nombre}
                                        </p>
                                    </div>
                                    <div>
                                        <label className="ml-1 text-lg font-semibold">
                                            Apellido
                                        </label>

                                        <p className="w-full pt-2 ">
                                            {sub?.apellido}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full text-center">
                                <div className="grid grid-cols-2 gap-2 mt-4">
                                    <div>
                                        <label className="text-lg font-semibold ">
                                            Documento ({sub?.tipo_documento})
                                        </label>

                                        <p className="w-full pt-2 ">
                                            {sub?.numero_documento}
                                        </p>
                                    </div>
                                    <div>
                                        <label className="text-lg font-semibold ">
                                            Telefono
                                        </label>

                                        <p className="w-full pt-2 ">
                                            {sub?.celular}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mt-4">
                                <label className="text-lg font-semibold ">
                                    Correo electrónico
                                </label>

                                <p className="w-full pt-2 ">{sub?.correo}</p>
                            </div>
                        </div>
                    );
                })}
                {/* <div className="flex flex-col gap-4 mt-8">
                    <button
                        onClick={() => handleSubmit()}
                        className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                        Guardar cambios
                    </button>
                </div> */}
            </div>
        </ResponsiveModalAbsolute>
    );
};

export default ModalUsuariosCasa;
