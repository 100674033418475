import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonRegresar } from "../../components/ButtonRegresar";
import { ButtonSiguiente } from "./ButtonSiguiente";

import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { set_landing_house } from "../../redux/reducers/landingReducer";
import precio from "../../img/onboarding/precio.jpg";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { useDispatchDominioCorredor } from "../../hooks/useDispatchDominioCorredor";
interface PreciosForm {
    precio_base?: string | number;
    tipo_moneda?: string;
    garantia?: string;
    tipo_garantia?: string;
}

type errorObject = { [key: string]: string };

export const Precio = () => {
    const { idCasa } = useParams();
    const [input, setInput] = useState<PreciosForm>({
        precio_base: "",
        tipo_moneda: "USD",
        garantia: "",
        tipo_garantia: "Pago con reserva",
    });
    const [errors, setErrors] = useState<PreciosForm>({});
    const casa = useAppSelector((state) => state.landing_house.house);
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const dispatchDominioCorredor = useDispatchDominioCorredor();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setInput({
            precio_base: casa.precio_base,
            tipo_moneda: casa.tipo_moneda,
            garantia: casa.garantia,
            tipo_garantia: casa.tipo_garantia,
        });
    }, [casa.precio_base, casa.tipo_moneda, casa.garantia, casa.tipo_garantia]);

    const getOne = () => {
        fetchAxiosNoToken({
            url: `/casa/getOne/id/${
                idCasa && Number(idCasa) > 0 ? idCasa : casa.id
            }`,
        })
            .then((res) => {
                console.log(res);
                dispatch(set_landing_house(res));
            })
            .catch((err) => {
                console.log(err);
                // dispatch(set_landing_house(null));
            });
    };
    useEffect(() => {
        getOne();
    }, []);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const validateInput = (form: PreciosForm) => {
        let errores: errorObject = {};

        if (!form.precio_base || Number(form.precio_base) <= 0) {
            errores.precio_base = "Debe ingresar un precio válido";
        }

        return errores;
    };

    const handleSubmit = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            try {
                const data = await fetchAxiosToken({
                    url: `/casa/putPrecio/${casa.id}`,
                    method: "put",
                    body: input,
                });

                await fetchAxiosToken({
                    url: `/casa/putTerminado/${casa.id}`,
                    method: "put",
                });

                await fetchAxiosNoToken({
                    url: `/casa/getOne/id/${casa.id}`,
                })
                    .then((res) => {
                        console.log(res);
                        dispatch(set_landing_house(res));
                    })
                    .catch((err) => {
                        console.log(err);
                        // dispatch(set_landing_house(null));
                    });
                dispatchDominio();
                idCasa && Number(idCasa) > 0 && dispatchDominioCorredor();
                setTimeout(() => {
                    navigate(`/`);
                }, 3000);
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/3 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={precio}
                        alt="precio"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Ahora definamos un precio competitivo para tu web
                        </div>

                        <div className="mt-5 text-lg text-center text-white md:text-xl 2xl:text-2xl md:mt-14 ">
                            ¡No te preocupes podrás cambiarlo más adelante!
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center p-6 text-center text-black bg-white lg:overflow-x-auto">
                <ButtonRegresar
                    url={`${
                        idCasa
                            ? `/dashboard-corredor/onboarding/ubicacion/${idCasa}`
                            : "/dashboard/onboarding/ubicacion"
                    }`}
                    className="absolute top-10 left-10"
                    arrowColor="black"
                    textColor="black"
                />
                <div className="flex flex-col items-center pb-4 mt-14 2xl:mt-24 xl:mt-10 lg:mt-16">
                    <h2 className="text-lg font-medium lg:text-xl 2xl:text-2xl">
                        Define tu precio base por noche
                    </h2>
                    <div className="2xl:mt-10 lg:mt-5 mt-3 w-full flex gap-4 md:w-1/2 max-w-[468px] ">
                        <div className="flex flex-col flex-1 text-start">
                            <label className="text-sm font-medium">
                                Precio por noche
                            </label>
                            <input
                                value={input.precio_base}
                                onChange={handleInputChange}
                                name="precio_base"
                                type={"number"}
                                className="w-full p-3 mt-1 text-black border rounded-lg shadow-md placeholder:italic"
                                placeholder="100"
                            />
                            {errors.precio_base && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.precio_base}
                                </p>
                            )}
                        </div>
                        <div className="flex flex-col ">
                            <label className="text-sm font-medium">
                                Moneda
                            </label>
                            <select
                                value={input.tipo_moneda}
                                name="tipo_moneda"
                                onChange={handleInputChange}
                                className="w-full p-3 mt-1 text-black border rounded-lg shadow-md placeholder:italic">
                                <option value={"PEN"}>PEN</option>
                                <option value={"USD"}>USD</option>
                                <option value={"EUR"}>EUR</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center pb-4 mt-5 lg:mt-8 2xl:mt-10 ">
                    <h2 className="text-lg font-medium lg:text-xl 2xl:text-2xl">
                        Define la garantía
                    </h2>
                    <div className="2xl:mt-10 lg:mt-5 mt-3 w-full flex flex-col md:w-1/2 gap-1 max-w-[468px] ">
                        <div className="flex flex-col flex-1 text-start">
                            <label className="text-sm font-medium">
                                Valor de la garantía
                            </label>
                            {/* <input
                                value={input.garantia}
                                onChange={handleInputChange}
                                name="garantia"
                                type={"number"}
                                className="w-full p-3 mt-1 text-black border rounded-lg shadow-md placeholder:italic"
                                placeholder="100"
                            /> */}
                            <div className="flex items-center w-full p-1 italic text-gray-500 border rounded-lg shadow-md outline-none text- base placeholder:italic">
                                <input
                                    value={input.garantia}
                                    onChange={handleInputChange}
                                    name="garantia"
                                    type={"number"}
                                    className=" w-full mt-2 mb-2 ml-4  outline-none text-[#3C3C3C]"
                                />{" "}
                                <p className="w-full text-center">
                                    {input.tipo_moneda}
                                </p>
                            </div>
                            {errors.garantia && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.garantia}
                                </p>
                            )}
                        </div>
                        <div className="flex flex-col flex-1 mt-8 text-start">
                            <label className="text-sm font-medium">
                                Tipo de garantía
                            </label>

                            <select
                                className="w-full px-3 py-3 my-3 text-black bg-transparent bg-white border rounded-lg outline-none focus:outline-none placeholder:font-bold"
                                value={input.tipo_garantia}
                                name="warrantyType"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        tipo_garantia: e.target.value,
                                    })
                                }>
                                <option value={"Pago con reserva"}>
                                    {" "}
                                    Cobrarlo en la reserva
                                </option>
                                <option value={"Pago al ingreso casa"}>
                                    {" "}
                                    Cobrarlo en el check in
                                </option>
                            </select>
                            <div>
                                {input.tipo_garantia === "Pago con reserva" && (
                                    <p className="mt-2 text-xs text-center text-gray-400 lg:text-sm 2xl:text-base">
                                        El cliente debera pagar la garantía al
                                        momento de hacer la reserva
                                    </p>
                                )}
                                {input.tipo_garantia ===
                                    "Pago al ingreso casa" && (
                                    <p className="mt-2 text-xs text-center text-gray-400 lg:text-sm 2xl:text-base">
                                        El cliente debera pagar la garantía al
                                        momento de llegar a la propiedad
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-1/2 mx-auto">
                    <ButtonSiguiente
                        onSubmit={() => handleSubmit()}
                        texto="Finalizar"
                    />
                </div>
            </div>
        </div>
    );
};
