import React, { useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { InputDate } from "../../../components/InputDate";
import { useNavigate } from "react-router-dom";
import useHeaders from "../../../hooks/useHeaders";

let quillObj: any;
let formData = new FormData();
interface etiquetaBlog {
    id: number;
    nombre: string;
    habilitado: string;
    fecha_registro: string;
}

const imageHandler = async () => {
    const input: HTMLInputElement = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
        const file: File | null = input.files?.[0] || null;

        if (file) {
            console.log(file);
            formData.delete("imagen_blog");
            formData.append("imagen_blog", file);

            uploadFiles(formData, quillObj);
        }
    };
};

const uploadFiles = async function (
    formData: FormData,
    quillObj: ReactQuill | null
) {
    const spinerElement = document.getElementById("spiner");
    const contenidoElement = document.getElementById("contenido");

    if (spinerElement && contenidoElement) {
        spinerElement.className = "mt-5";
        contenidoElement.className = "hidden";
    }

    try {
        const response = await fetchAxiosToken({
            url: `/blog/insertImagen`,
            body: formData,
            method: "post",
        });

        console.log(response);

        if (response.message === "actualizacion exitoso" && quillObj !== null) {
            const range = quillObj.getEditorSelection();

            console.log("cargando imagen quill");
            if (range) {
                // Verificar que range no sea null

                const imageUrl = `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/blog/getImagen/${response.imagen}`;

                quillObj
                    .getEditor()
                    .insertEmbed(range.index, "image", imageUrl);

                const imgElements = document.querySelectorAll(
                    'img[src="' + imageUrl + '"]'
                );
                imgElements.forEach((img: any) => {
                    img.style.height = "25rem";

                    img.style.borderRadius = "10px";
                    img.style.marginTop = "10px";
                    img.style.marginBottom = "10px";
                    img.style.width = "auto";
                });

                quillObj.getEditor().insertText(range.index + 1, "    ");
            }

            if (spinerElement && contenidoElement) {
                spinerElement.className = "hidden";
                contenidoElement.className = "mt-5";
            }
        }
    } catch (error) {
        console.error("Error:", error);
    }
};

const CreateBlog = () => {
    const { headers } = useHeaders();
    const [description, setdescription] = useState("");
    const [title, settitle] = useState("");
    const [baner, setbaner] = useState<any>("");
    const [preview, setPreview] = useState("");
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;
    const [fecha, setFecha] = useState(formattedDate);
    const [cargando, SetCargando] = useState(false);
    const navigate = useNavigate();
    const handleChangeImage = (e: any) => {
        console.log(e.target.files[0]);
        setbaner(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setPreview(objectUrl);
    };
    const [etiquetasBLogs, setEtiquetasBlogs] = useState<etiquetaBlog[]>([]);
    const [comfortsSelected, setComfortsSelected] = useState<number[]>([]);

    const handleAttributeClick = (id: number) => {
        if (comfortsSelected.includes(id)) {
            setComfortsSelected(comfortsSelected.filter((item) => item !== id));
        } else {
            setComfortsSelected([...comfortsSelected, id]);
        }
    };

    useEffect(() => {
        getEtiquetaBlog();
    }, []);

    const getEtiquetaBlog = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/etiquetas_blogs/getAll/1/Si?query=`,
                { headers }
            );

            console.log(data);
            setEtiquetasBlogs(data.rows);
            // setEtiquetasTotal(data.count);
        } catch (error) {
            console.log(error);
        }
    };

    const addDetails = async () => {
        if (baner === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error!",
                text: "Debes colocar un banner",
                icon: "error",

                confirmButtonText: "Aceptar",
            });
        }
        if (description === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error!",
                text: "el contenido no puede estar vacio",
                icon: "error",

                confirmButtonText: "Aceptar",
            });
        }
        if (baner && description) {
            SetCargando(true);

            formData.append("banner_blog", baner);
            formData.append("titulo", title);
            formData.append("contenido", description);
            formData.append("fecha_publicado", fecha);
            comfortsSelected.map((etiqueta) => {
                formData.append("etiquetas_blogs", `${etiqueta}`);
            });

            const json = await fetchAxiosToken({
                url: `/blog/postBlog`,
                body: formData,
                method: "post",
            });

            console.log(json);

            if (json.message === "registro exitoso") {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Procesado!",
                    text: "blog creado",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                formData.delete("banner_blog");
                formData.delete("titulo");
                formData.delete("contenido");
                formData.delete("imagen_blog");
                formData.delete("fecha_publicado");
                setdescription("");
                setbaner("");
                settitle("");
                setPreview("");
                navigate("/dashboard-admin/blogs");
            }
        }
    };

    const onChangeText = (text: any) => {
        text = text !== "<p><br></p>" ? text : "";

        setdescription(text);
    };

    const Size = Quill.import("formats/size");
    Size.whitelist = ["extra-small", "small", "medium", "large"];
    Quill.register(Size, true);

    const Font = Quill.import("formats/font");
    Font.whitelist = [
        "arial",
        "comic-sans",
        "courier-new",
        "georgia",
        "helvetica",
        "Inter",
        "lucida",
    ];
    Quill.register(Font, true);

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "align",
        "strike",
        "script",
        "blockquote",
        "background",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "color",
        "code-block",
    ];

    const modules = (props: any) => ({
        toolbar: {
            container: "#" + props,

            handlers: {
                // undo: undoChange,
                // redo: redoChange,
                image: imageHandler,
            },
        },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
    });

    return (
        <>
            <div className="w-full ">
                <div className="">
                    <div className="">
                        <div className="flex items-center w-11/12 py-4 mx-auto text-lg sm:w-full sm:ml-6">
                            <svg
                                onClick={() => (
                                    setdescription(""),
                                    settitle(""),
                                    setPreview(""),
                                    setbaner(""),
                                    formData.delete("banner_blog"),
                                    formData.delete("titulo"),
                                    formData.delete("contenido"),
                                    formData.delete("imagen_blog")
                                )}
                                className="w-6 h-6 -ml-2 text-black cursor-pointer"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <polyline points="15 18 9 12 15 6" />
                            </svg>
                            <span
                                onClick={() => (
                                    setdescription(""),
                                    settitle(""),
                                    setPreview(""),
                                    setbaner(""),
                                    formData.delete("banner_blog"),
                                    formData.delete("titulo"),
                                    formData.delete("contenido"),
                                    formData.delete("imagen_entradas"),
                                    formData.delete("fecha_publicado"),
                                    navigate("/dashboard-admin/blogs")
                                )}
                                className="mx-1 cursor-pointer">
                                Regresar
                            </span>
                        </div>
                        <div className="w-11/12 mx-auto sm:w-7/12">
                            <div className="grid grid-cols-1">
                                <label className="text-xl text-[#3C3C3C]">
                                    {" "}
                                    Título{" "}
                                </label>
                                <input
                                    type="text"
                                    name="title"
                                    value={title}
                                    onChange={(e) => settitle(e.target.value)}
                                    className="w-full h-12 p-4 mt-2 border border-[#E9E9E9] rounded-lg"
                                    placeholder="Título"
                                    required
                                />
                            </div>
                            <div className="grid grid-cols-1 mt-1">
                                <label className="text-xl text-[#3C3C3C]">
                                    {" "}
                                    Fecha{" "}
                                </label>
                                <button className="">
                                    <InputDate
                                        className="flex items-center px-2 py-2 mt-2 text-gray-400 border rounded-md cursor-pointer bg-gray-50 hover:bg-gray-100 xs:w-1/3"
                                        selectedDay={fecha}
                                        placeholderText="Seleccionar fecha"
                                        onChange={(date) =>
                                            setFecha(date ? date : "")
                                        }
                                    />
                                </button>
                            </div>

                            <div className="mt-5">
                                <div className="mb-2">
                                    <label className="text-xl text-[#3C3C3C]">
                                        {" "}
                                        Imagen principal{" "}
                                    </label>
                                    {!baner ? (
                                        <div className="relative flex items-center justify-center sm:w-4/12 sm:min-w-[400px] h-64 border-gray-200 border rounded-lg hover:cursor-pointer my-5 py-4 px-6">
                                            <div className="absolute">
                                                <div className="flex flex-col w-full mx-auto ">
                                                    <div className="relative ">
                                                        <span className="mt-2 font-normal text-gray-400 ">
                                                            Arrastre y suelte
                                                            una imagen aquí
                                                        </span>

                                                        <span className="block font-normal text-center text-blue-400 ">
                                                            Buscar
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                accept="image/*"
                                                type="file"
                                                name="icono_si"
                                                className="w-full h-full opacity-0 cursor-pointer "
                                                onChange={handleChangeImage}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="relative flex items-center justify-center sm:w-4/12 sm:min-w-[400px] h-64 border-gray-200 border rounded-lg hover:cursor-pointer my-5 py-4 px-6">
                                                <div className="absolute bg-opacity-50 bg-slate-100">
                                                    <div>
                                                        <img
                                                            src={preview}
                                                            className="object-contain w-11/12 h-48 py-2 mx-auto mt-2"
                                                            alt="img_blog"
                                                        />

                                                        <span className="text-base font-semibold ">
                                                            {baner?.name}
                                                        </span>
                                                    </div>
                                                </div>
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    name="icono_si"
                                                    className="w-full h-full opacity-0 cursor-pointer "
                                                    onChange={handleChangeImage}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="grid grid-cols-1 mt-1">
                                <label className="text-xl text-[#3C3C3C]">
                                    {" "}
                                    Etiquetas{" "}
                                </label>
                                <div className="h-[30vh] overflow-hidden mt-2">
                                    <div className="max-h-[30vh] overflow-auto w-[104%] md:w-[103%] lg:w-[102%] 2xl:w-[101%] grid grid-cols-2 mr-4">
                                        {etiquetasBLogs?.map((comforts) => {
                                            return (
                                                <div
                                                    key={comforts.id}
                                                    onClick={() =>
                                                        handleAttributeClick(
                                                            comforts.id
                                                        )
                                                    }>
                                                    <div
                                                        className={
                                                            comfortsSelected.includes(
                                                                comforts.id
                                                            )
                                                                ? " items-center text-center text-base py-4 border border-solid border-[#3C3C3C] rounded-lg bg-[#F4F2F1] h-16 cursor-pointer mt-2 w-4/5"
                                                                : " items-center text-center text-base  py-4 border border-solid border-[#E9E9E9]  rounded-lg h-16  cursor-pointer mt-2  w-4/5"
                                                        }>
                                                        <span className=" text-sm text-[#3C3C3C]">
                                                            {comforts.nombre}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="spiner" className="hidden">
                            <Spinner width={20} />
                        </div>
                        <div className="w-5/6 mx-auto">
                            {" "}
                            <hr className=" my-4 border-[#E9E9E9]"></hr>
                            <label className=" text-xl text-[#3C3C3C] py-5">
                                {" "}
                                Contenido{" "}
                            </label>
                            <div id="contenido" className="mt-5 ">
                                <QuillToolbar toolbarId={"t1"} />
                                <ReactQuill
                                    ref={(el) => {
                                        quillObj = el;
                                    }}
                                    theme="snow"
                                    value={description}
                                    onChange={onChangeText}
                                    placeholder={"Escribe algo..."}
                                    modules={modules("t1")}
                                    formats={formats}
                                    style={{
                                        backgroundColor: "white",
                                        height: "100vh",
                                    }}
                                />
                            </div>
                            <div className="pb-6 mt-4 text-center">
                                <button
                                    onClick={() => addDetails()}
                                    disabled={cargando}
                                    className="w-full p-3 text-center text-white border border-[#029BFC] rounded-md bg-[#029BFC] xs:w-80 hover:scale-105 disabled:bg-[#029BFC]">
                                    {cargando ? "Guardando..." : "Guardar"}
                                </button>
                            </div>
                            <hr className="pl-4 mx-auto mt-12 border-white xs:hidden"></hr>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const CustomUndo = () => (
    <svg viewBox="0 0 18 18">
        <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
        <path
            className="ql-stroke"
            d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
        />
    </svg>
);

const CustomRedo = () => (
    <svg viewBox="0 0 18 18">
        <polygon
            className="ql-fill ql-stroke"
            points="12 10 14 12 16 10 12 10"
        />
        <path
            className="ql-stroke"
            d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
        />
    </svg>
);

const QuillToolbar = (props: any) => {
    return (
        <>
            {props.toolbarId !== undefined && (
                <div id={props.toolbarId}>
                    <span className="ql-formats">
                        <button className="ql-bold" />
                        <button className="ql-italic" />
                        <button className="ql-underline" />
                        <button className="ql-strike" />
                    </span>
                    <span className="ql-formats">
                        <select className="ql-font">
                            <option value="arial"> Arial </option>
                            <option value="comic-sans">Comic Sans</option>
                            <option value="courier-new">Courier New</option>
                            <option value="georgia">Georgia</option>
                            <option value="helvetica">Helvetica</option>
                            <option value="Inter" selected>
                                Inter
                            </option>
                            <option value="lucida">Lucida</option>
                        </select>
                        <select className="ql-size">
                            <option value="extra-small">Extra Small</option>
                            <option value="small">Small</option>
                            <option value="medium" selected>
                                Medium
                            </option>
                            <option value="large">Large</option>
                        </select>
                        <select className="ql-header">
                            <option value="1">Heading 1</option>
                            <option value="2">Heading 2</option>
                            <option value="3">Heading 3</option>
                            <option value="4">Heading 4</option>
                            <option value="5">Heading 5</option>
                            <option value="6">Heading 6</option>
                            <option value="" selected>
                                Normal
                            </option>
                        </select>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-list" value="ordered" />
                        <button className="ql-list" value="bullet" />
                        <button className="ql-indent" value="-1" />
                        <button className="ql-indent" value="+1" />
                    </span>
                    <span className="ql-formats">
                        <button className="ql-script" value="super" />
                        <button className="ql-script" value="sub" />
                        <button className="ql-blockquote" />
                        <button className="ql-direction" />
                    </span>
                    <span className="ql-formats">
                        <select className="ql-align" />
                        <select className="ql-color" />
                        <select className="ql-background" />
                    </span>
                    <span className="ql-formats">
                        <button className="ql-link" />
                        <button className="ql-image" />
                        <button className="ql-video" />
                    </span>
                    <span className="ql-formats">
                        <button className="ql-formula" />
                        <button className="ql-code-block" />
                        <button className="ql-clean" />
                    </span>
                    {/* <span className="ql-formats">
                        <button className="ql-undo">
                            <CustomUndo />
                        </button>
                        <button className="ql-redo">
                            <CustomRedo />
                        </button>
                    </span> */}
                </div>
            )}
        </>
    );
};

export default CreateBlog;
