import React, { useEffect, useMemo, useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import Habitaciones from "../design2New/Habitaciones";

interface Props {
    modalVisible: boolean;
    setModalVisible: (b: boolean) => void;
}

export interface Imagenes {
    id: number;
    id_casa: number;
    imagen: string;
}

export const ModalHabitaciones = ({ modalVisible, setModalVisible }: Props) => {
    const handleCloseModal = () => {
        setModalVisible(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div
            className={`${
                !modalVisible
                    ? "translate-y-full invisible"
                    : "translate-y-0 flex visible"
            } transition-all ease-out duration-500 flex-col bg-slate-50 absolute left-0 top-0 z-[999] h-[1000vh] w-full items-center`}>
            <div className="flex flex-col w-full h-full">
                <div
                    onClick={() => handleCloseModal()}
                    className="flex items-center justify-center w-8 h-8 p-2 m-4 font-medium border border-gray-300 rounded-full shadow-md cursor-pointer">
                    <svg
                        className="w-4 h-4 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </div>
                <Habitaciones />
            </div>
        </div>
    );
};
