import React, { useEffect, useState } from "react";
// import logo from "../../../img/Home/BLUECORNER_PNG.png";
import background from "../../img/Comprobar_disponibilidad.png";
import { Link, useParams } from "react-router-dom";
// import colores from "../../helpers/colores";

import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";

const ComprobarDisponibilidadPropietario = () => {
    const [disponibilidad, setDisponibilidad] = useState<null | string>(null);
    const { disponible, idReserva } = useParams();

    const putEstadoReserva = async () => {
        console.log(disponible);
        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/pedidos/putConfirm/${idReserva}`,
                {
                    disponibilidad: disponible,
                }
            );
            console.log(data);
            setDisponibilidad(data.estado);

            console.log(data);
        } catch (error: any) {
            console.log(error.response);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: `${error.response.data.error}`,
                icon: "error",
                confirmButtonText: "Aceptar",
            });

            setDisponibilidad(`${error.response.data.error}`);
        }
    };

    useEffect(() => {
        console.log({ disponible, idReserva });
        if (disponible) {
            putEstadoReserva();
        }
    }, [disponible]);
    console.log(disponibilidad);
    return (
        <div className="flex">
            <div
                style={{
                    backgroundImage: `url(${background})`,
                    filter: "brightness( 100% ) contrast( 113% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg )",
                }}
                className={`w-full  h-screen bg-center bg-no-repeat
                bg-cover flex flex-col text-center`}>
                <div className="h-screen bg-[rgba(5,52,101,0.60)]">
                    <div className="w-full">
                        {/* <img
                            src={logo}
                            className="mt-20 lg:h-36 min-h-[2rem] w-full lg:max-w-md"
                        /> */}
                    </div>
                    {disponibilidad === null ? (
                        <Spinner />
                    ) : (
                        <>
                            {disponibilidad ===
                            "No corresponde actualizar el estado" ? (
                                <>
                                    <h1 className="p-4 text-4xl font-bold text-white lg:mt-20 lg:text-5xl">
                                        ¡Esta reserva ya estaba actualizada!
                                    </h1>
                                    <h1 className="mt-5 text-xl font-bold text-white lg:text-3xl">
                                        la reserva ya se habia actualizado
                                        anteriormente
                                    </h1>
                                </>
                            ) : (
                                <>
                                    <h1 className="p-4 text-4xl font-bold text-white lg:mt-20 lg:text-5xl">
                                        ¡Gracias por responder!
                                    </h1>
                                    <h1 className="mt-5 text-xl font-bold text-white lg:text-3xl">
                                        {disponible === "Si"
                                            ? "Le hemos avisado a tu cliente que la casa esta disponible"
                                            : "Le hemos avisado a tu cliente que la casa no esta disponible"}
                                    </h1>
                                </>
                            )}
                            <div className="px-10">
                                <div className="flex flex-col max-w-lg py-10 mx-auto mt-10 rounded-lg bg-neutral-200 lg:mt-28 opacity-95">
                                    {/* <h1 className="px-10 py-2 mb-10 text-2xl text-gray-600">
                                        Mientras esperas, puedes ver algunos de
                                        nuestros alojamientos.
                                    </h1> */}
                                    <Link to="/">
                                        <span
                                            className={`font-semibold hover:border hover:underline border-gray-400 bg-blue-500  text-white p-3 min-w-max mx-auto cursor-pointer rounded-md text-lg`}>
                                            Volver al sitio
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ComprobarDisponibilidadPropietario;
