import { useEffect, useState } from "react";

import { useAppSelector } from "../../hooks/hooks";
import { ModalEditImageCarrousel } from "../modalEdit/ModalEditImageCarrousel";
import ModalReserva from "./ModalReserva/ModalReserva";

import { InputDate } from "../../components/InputDate";

interface Props {
    toggleButton: boolean;
}

const Carrousel = ({ toggleButton }: Props) => {
    const { banner, personas } = useAppSelector(
        (state) => state.landing_house.house
    );
    const newDate = new Date();

    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const [input, setInput] = useState({
        huespedes: 1,
        fecha_inicio: `${year}/${month}/${day}`,
        fecha_final: `${year}/${month}/${day}`,
    });

    const [modalVisibleReserva, setModalVisibleReserva] =
        useState<boolean>(false);
    const [modalVisibleCarrousel, setModalVisibleCarrousel] =
        useState<boolean>(false);

    const handleReservaClick = () => {
        setModalVisibleReserva(true);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        setInput({
            ...input,
            [event.target.name]: inputValue !== "" ? Number(inputValue) : "",
        });
    };

    return (
        <div className="relative h-[20rem] xl:h-[25rem] 2xl:h-[30rem]">
            {modalVisibleReserva && (
                <ModalReserva
                    modalVisible={modalVisibleReserva}
                    setModalVisible={setModalVisibleReserva}
                    toggleButton={toggleButton}
                    input={input}
                />
            )}
            {modalVisibleCarrousel && (
                <ModalEditImageCarrousel
                    modalVisible={modalVisibleCarrousel}
                    setModalVisible={setModalVisibleCarrousel}
                />
            )}

            <img
                src={
                    typeof banner === "string"
                        ? `${process.env.REACT_APP_PROXY}/casa/getImage/${banner}`
                        : URL.createObjectURL(banner)
                }
                className="object-cover w-full h-full "
                alt={`banner`}
            />
            {toggleButton && (
                <div
                    onClick={() => setModalVisibleCarrousel(true)}
                    className="absolute top-8  w-full h-[2.5rem] left-4"
                >
                    <div className="flex ">
                        <div
                            title="editar fotos"
                            className="z-20 flex flex-col items-center px-2 py-1 mr-6 rounded-lg cursor-pointer opacity-60 bg-slate-200 hover:opacity-100"
                        >
                            <svg
                                className="w-12 h-12 text-[#1C4CBB]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                            </svg>
                            <div className="text-xs text-[#1C4CBB] text-center">
                                Editar Fotos
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="absolute bottom-0 w-full h-2/3 xs:h-1/3 bg-gradient-to-b from-transparent to-zinc-900"></div>

            <div className="absolute bottom-0 right-0 z-20 flex flex-wrap w-full gap-2 p-3 sm:gap-4 md:p-5 md:px-10">
                <div className="relative flex flex-col text-sm md:w-2/5 lg:w-1/4 xs:w-auto sm:text-base">
                    <p className="mb-2 text-white text-start">INGRESO</p>
                    <button className="">
                        <InputDate
                            className="flex items-center py-2 px-2 border rounded-md cursor-pointer bg-gray-50 hover:bg-gray-100 text-[#1C4CBB] font-bold"
                            selectedDay={input.fecha_inicio}
                            placeholderText="Seleccionar fecha"
                            onChange={(date) =>
                                setInput({
                                    ...input,
                                    fecha_inicio: date ? date : "",
                                })
                            }
                        />
                    </button>
                </div>
                <div className="relative flex flex-col text-sm md:w-2/5 lg:w-1/4 xs:w-auto sm:text-base">
                    <p className="mb-2 text-white text-start">SALIDA</p>
                    <button className="">
                        <InputDate
                            className="flex items-center   py-2 px-2   border rounded-md cursor-pointer bg-gray-50 hover:bg-gray-100 text-[#1C4CBB] font-bold"
                            selectedDay={input.fecha_final}
                            placeholderText="Seleccionar fecha"
                            onChange={(date) =>
                                setInput({
                                    ...input,
                                    fecha_final: date ? date : "",
                                })
                            }
                        />
                    </button>
                </div>

                <div className="relative flex flex-col text-sm sm:text-base ">
                    <p className="mb-2 text-white select-none text-start">
                        HUÉSPEDES
                    </p>
                    <div className="flex items-center h-full gap-1 px-2 py-2 text-gray-400 border rounded-md bg-gray-50 hover:bg-gray-100">
                        <input
                            className="w-6 ml-1 mr-6 font-bold outline-none select-none"
                            type="number"
                            name="huespedes"
                            value={input.huespedes}
                            onChange={handleInputChange}
                        />
                        <button
                            onClick={() =>
                                setInput({
                                    ...input,
                                    huespedes:
                                        input.huespedes < personas
                                            ? Number(input.huespedes) + 1
                                            : input.huespedes,
                                })
                            }
                        >
                            <svg
                                className="w-5 h-5 font-bold cursor-pointer hover:scale-110 hover:text-[#1C4CBB]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <polyline points="6 15 12 9 18 15" />
                            </svg>
                        </button>

                        <button
                            onClick={() =>
                                setInput({
                                    ...input,
                                    huespedes:
                                        input.huespedes > 0
                                            ? input.huespedes - 1
                                            : input.huespedes,
                                })
                            }
                        >
                            <svg
                                user-select="none"
                                className="w-5 h-5 font-bold cursor-pointer hover:scale-110 hover:text-[#1C4CBB]"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <polyline points="6 9 12 15 18 9" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="relative flex flex-col-reverse">
                    <button
                        disabled={
                            input.fecha_final === "" ||
                            input.fecha_inicio === "" ||
                            input.huespedes < 1
                        }
                        onClick={() => setModalVisibleReserva(true)}
                        className="flex   gap-1 px-2 py-2 text-white  rounded-md cursor-pointer bg-[#1C4CBB] hover:scale-105 text-sm sm:text-base select-none disabled:bg-slate-300 disabled:scale-100 "
                    >
                        <p>CONSULTAR</p>
                        <p>DISPONIBILIDAD</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Carrousel;
