import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
import { ModalEditRedesSociales } from "./ModalEditRedesSociales";

export const FooterLanding = ({ toggleButton }: { toggleButton: boolean }) => {
    const { nombre, apellido, facebook, instagram, tiktok } = useAppSelector(
        (state) => state.corredor
    );
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <div className={`flex flex-col  text-white  bg-[#1C4CBB] `}>
            {modalVisible && (
                <ModalEditRedesSociales
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                />
            )}

            <div className="flex flex-col justify-center w-full gap-6 py-8 text-white border-t-2 border-white md723:gap-1 md723:flex-row md723:justify-evenly">
                <div className="flex items-center justify-center text-center ">
                    <p className="text-sm font-medium text-center uppercase">
                        <span className="pr-2">© 2024 </span> -{" "}
                        <span className="pl-2">
                            {" "}
                            {nombre} {apellido}{" "}
                        </span>
                    </p>
                </div>

                {facebook?.length ||
                instagram?.length ||
                tiktok?.length ||
                toggleButton ? (
                    <div className="">
                        <div className="flex justify-center ">
                            <p className="mb-2 font-medium text-center ">
                                SÍGUENOS
                            </p>

                            {toggleButton && (
                                <div
                                    onClick={() => setModalVisible(true)}
                                    className="h-[2.5rem]"
                                >
                                    <div className="flex justify-start ">
                                        <div
                                            title="editar dirección"
                                            className="flex flex-col items-center px-2 py-1 ml-6 -mt-5 rounded-lg cursor-pointer opacity-90 bg-slate-200 hover:opacity-100"
                                        >
                                            <svg
                                                className="w-6 h-6 text-[#1C4CBB]"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                <line
                                                    x1="13.5"
                                                    y1="6.5"
                                                    x2="17.5"
                                                    y2="10.5"
                                                />
                                            </svg>
                                            <div className="text-xs text-[#1C4CBB] text-center">
                                                Editar Redes
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex justify-center lg:justify-start ">
                            {instagram?.length ? (
                                <a
                                    aria-label="icono instagram"
                                    href={instagram}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <svg
                                        className="mx-2 text-white transition ease-in cursor-pointer w-9 h-9 hover:scale-110"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <rect
                                            x="2"
                                            y="2"
                                            width="20"
                                            height="20"
                                            rx="5"
                                            ry="5"
                                        />{" "}
                                        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />{" "}
                                        <line
                                            x1="17.5"
                                            y1="6.5"
                                            x2="17.51"
                                            y2="6.5"
                                        />
                                    </svg>
                                </a>
                            ) : null}
                            {facebook?.length ? (
                                <a
                                    aria-label="icono facebook"
                                    href={facebook}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <svg
                                        className="w-8 h-8 mx-2 text-white transition ease-in cursor-pointer hover:scale-110"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                                    </svg>
                                </a>
                            ) : null}
                            {tiktok?.length ? (
                                <a
                                    aria-label="icono tiktok"
                                    href={tiktok}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <svg
                                        className="w-8 h-8 mx-2 text-white transition ease-in cursor-pointer hover:scale-110"
                                        fill="white"
                                        width="800px"
                                        height="800px"
                                        viewBox="0 0 512 512"
                                        id="icons"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" />
                                    </svg>
                                </a>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
