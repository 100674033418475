import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getHouse } from "../../redux/reducers/onboardingReducer";

interface Props {
    onSubmit: () => Promise<any> | (() => void);
    texto?: string;
}

export const ButtonSiguiente = ({ onSubmit, texto }: Props) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const { id: id_usuario } = useAppSelector((state) => state.usuario_logeado);
    const dispatch = useAppDispatch();

    const handleSubmit = async () => {
        setButtonDisabled(true);
        await onSubmit();
        dispatch(getHouse(id_usuario));
        setButtonDisabled(false);
    };

    return (
        <button
            disabled={buttonDisabled}
            onClick={() => {
                handleSubmit();
            }}
            className=" z-50 border p-3 2xl:p-4 w-full flex items-center justify-center px-20 text-white font-bold rounded-lg bg-[#029BFC] active:translate-y-1 text-base lg:text-lg cursor-pointer hover:underline disabled:bg-gray-400 disabled:no-underline disabled:cursor-default disabled:translate-y-0 2xl:mt-14 mt-10">
            {buttonDisabled ? (
                <span className="ml-2">Cargando...</span>
            ) : texto ? (
                <span className="ml-2">{texto}</span>
            ) : (
                <span className="ml-2">Siguiente</span>
            )}
        </button>
    );
};
