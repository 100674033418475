import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import Spinner from "../../../components/helpers/Spinner";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { verifyToken } from "../../../redux/reducers/authReducer";
import { getHouse } from "../../../redux/reducers/onboardingReducer";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { useParams } from "react-router-dom";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";

interface Garantia {
    garantia: string;
    precio_base: string;
    tipo_moneda: string;
    tipo_garantia: string;
}

export const Garantia = () => {
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const {
        id: id_casa,
        garantia,
        precio_base,
        tipo_moneda,
        id_propietario,
        tipo_garantia,
    } = useAppSelector((state) => state.landing_house.house);

    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const [input, setInput] = useState<Garantia>({
        garantia: "",
        precio_base,
        tipo_moneda,
        tipo_garantia: "Pago con reserva",
    });

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);

    const putMaximaBase = async () => {
        setLoading(true);
        if (input.garantia === "") {
            setInput({
                garantia: "0",
                precio_base,
                tipo_moneda,
                tipo_garantia: "Pago con reserva",
            });
        }
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putPrecio/${id_casa}`,
                method: "put",
                body: input,
            });

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Actualizado con exito",

                icon: "success",
                confirmButtonText: "Ok",
            });

            dispatchDominio();

            setLoading(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        setInput({
            ...input,
            garantia: garantia,
            tipo_garantia: tipo_garantia,
        });
    }, [garantia, tipo_garantia]);

    const render = () => {
        return (
            <div className="flex">
                {true ? (
                    <div className="grid w-11/12 max-w-5xl mx-auto mt-12 md:grid-cols-2 right-2">
                        <div className="p-4 text-[#4D4D4D]">
                            <ButtonRegresar
                                // url={`/dashboard/onboarding/alojamiento`}
                                className="-ml-2"
                                arrowColor="black"
                                textColor="black"
                            />
                            <p className="text-xl font-bold 2xl:text-2xl ">
                                Garantía
                            </p>
                            <p className="mt-6 text-sm 2xl:text-base">
                                Establece una garantía reembolsable por la
                                reserva.
                            </p>
                        </div>
                        <div className="p-4 border rounded-lg shadow-2xl ">
                            <div className="grid w-full grid-cols-2 gap-2">
                                <div>
                                    <div className="mt-5 mb-2 ml-2 2xl:text-base text-sm italic text-[#4D4D4D] font-medium">
                                        <p>Garantía</p>
                                    </div>
                                    <div className="flex items-center 2xl:text-base text-sm italic text-gray-500 border-2  border-[#CCCCCC] w-full rounded-lg outline-none">
                                        <input
                                            type="text"
                                            placeholder="1000"
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    garantia: e.target.value,
                                                })
                                            }
                                            className=" w-full mt-2 mb-2 ml-4   outline-none text-[#3C3C3C]"
                                            value={input.garantia}
                                        />{" "}
                                        <p className="w-full text-center">
                                            {input.tipo_moneda}
                                        </p>
                                    </div>
                                </div>

                                <div className="w-full">
                                    <div className="mt-5 mb-2 ml-2 2xl:text-base text-sm  italic text-[#4D4D4D] font-medium ">
                                        <p>Tipo garantía</p>
                                    </div>
                                    <div className="py-2 bg-transparent w-full px-2 border-2 2xl:text-base text-sm border-[#CCCCCC] rounded-lg ">
                                        <select
                                            className=" w-full outline-none focus:outline-none text-[#3C3C3C] placeholder:font-bold bg-white"
                                            value={input.tipo_garantia}
                                            name="warrantyType"
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    tipo_garantia:
                                                        e.target.value,
                                                })
                                            }>
                                            <option
                                                value={"Pago con reserva"}
                                                className="bg-white">
                                                {" "}
                                                Cobrarlo en la reserva
                                            </option>
                                            <option
                                                value={"Pago al ingreso casa"}
                                                className="bg-white">
                                                {" "}
                                                Cobrarlo en el check in
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-4 border-[#CCCCCC]" />
                            <div className="flex flex-col items-center justify-center w-full ">
                                <div>
                                    {input.tipo_garantia ===
                                        "Pago con reserva" && (
                                        <p className="text-sm italic text-center 2xl:text-base ">
                                            El cliente debera pagar la garantía
                                            al momento de hacer la reserva.
                                        </p>
                                    )}
                                    {input.tipo_garantia ===
                                        "Pago al ingreso casa" && (
                                        <p className="text-sm italic text-center 2xl:text-base ">
                                            El cliente debera pagar la garantía
                                            al momento de llegar a la propiedad.
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center justify-center w-full mt-4">
                                <div className="relative flex items-center justify-center w-full">
                                    {/* <BotonInformacion text="Ingresa los horarios de entrada Y salida para que tus clientes puedan verlo!" /> */}
                                    <button
                                        disabled={loading}
                                        onClick={() => putMaximaBase()}
                                        className="w-full p-2 uppercase font-medium 2xl:text-base text-sm text-white rounded-lg bg-[#029BFC] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default">
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mt-8">
                        <Spinner />
                    </div>
                )}
            </div>
        );
    };
    return tipo_usuario === 3 || tipo_usuario === 4 ? (
        <Navbar titulo="Garantía">
            {render()}
            <NavbarMobile />
        </Navbar>
    ) : tipo_usuario === 6 ? (
        <NavbarBroker titulo="Garantía">
            {render()}
            <NavbarMobileBroker />
        </NavbarBroker>
    ) : null;
};
