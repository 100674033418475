import { useDispatch } from "react-redux";
import { set_landing_house } from "../redux/reducers/landingReducer";
import { fetchAxiosNoToken } from "../helpers/fetchAxiosNoToken";

export const useDispatchDominio = (id?: string) => {
    const dispatch = useDispatch();

    const dispatchDominio = () => {
        fetchAxiosNoToken({
            url: id ? `/casa/getOne/id/${id}` : `/casa/getOne/dominio`,
        })
            .then((res) => {
                dispatch(set_landing_house(res));
            })
            .catch((err) => {
                console.log(err);
                // dispatch(set_landing_house(null));
            });
    };

    return dispatchDominio;
};
