export const important_funcionalidades = [
    {
        group: "Nuestros Beneficios",
        content: [
            {
                title: "Landing pesonalizada",
                content: {
                    basico: "2 opciones",
                    estandar: "4 opciones",
                    premium: "4 opciones",
                },
            },
            {
                title: "Edición personalizada desde la landing Web",
                content: {
                    basico: "Si",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Contar con un calendario de reservas",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Módulo de CRM para gestionar clientes",
                content: {
                    basico: "No",
                    estandar: "Si",
                    premium: "Si",
                },
            },
            {
                title: "Creación de multiusuarios",
                content: {
                    basico: "No",
                    estandar: "+1 usuario",
                    premium: "+1 usuario",
                },
            },
            {
                title: "Integración de facturación electrónica (Emitir boleta o factura)",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },

            {
                title: "Asesoria en pauta digital",
                content: {
                    basico: "No",
                    estandar: "No",
                    premium: "Si",
                },
            },
        ],
    },
];
