import { useState, useEffect } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { ModalEditRooms } from "../modalEdit/ModalEditRooms";
import { ModalGaleriaImagenes } from "../modalEdit/ModalGaleriaImagenes";

import Lightbox from "react-18-image-lightbox";
import { ModalHabitaciones } from "../modalEdit/ModalHabitaciones";

interface Props {
    toggleButton: boolean;
}
const Imagenes = ({ toggleButton }: Props) => {
    const {
        id: casa_id,
        banner,
        casa_imagenes,
        casa_habitaciones,
    } = useAppSelector((state) => state.landing_house.house);
    const [modalImagesVisible, setModalImagesVisible] = useState(false);
    const [modalVisibleRooms, setModalVisibleRooms] = useState<boolean>(false);
    const [photos, setPhotos] = useState([
        {
            src: `${process.env.REACT_APP_PROXY}${"/casa/getImage/"}${banner}`,
            original: `${
                process.env.REACT_APP_PROXY
            }${"/casa/getImage/"}${banner}`,
            width: 400,
            height: 200,
        },
    ]);
    const [index, setIndex] = useState(-1);

    const currentImage = photos?.[index];
    const nextIndex = (index + 1) % photos?.length;
    const nextImage = photos?.[nextIndex] || currentImage;
    const prevIndex = (index + photos?.length - 1) % photos?.length;
    const prevImage = photos?.[prevIndex] || currentImage;

    const handleClick = (index: any) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);
    console.log({
        casa_habitaciones,
        casa_imagenes,
    });
    useEffect(() => {
        let imagenesTotal: any = photos.length ? [...photos] : [];
        casa_habitaciones?.map((imagen) => {
            imagenesTotal.push({
                src: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${imagen.imagen}`,
                original: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${imagen.imagen}`,

                height: 200,
            });
        });

        setPhotos(imagenesTotal);
    }, [casa_habitaciones]);

    return (
        <>
            <div className="flex items-center justify-center ">
                {!!currentImage && (
                    <Lightbox
                        mainSrc={currentImage.original}
                        mainSrcThumbnail={currentImage.src}
                        nextSrc={nextImage.original}
                        nextSrcThumbnail={nextImage.src}
                        prevSrc={prevImage.original}
                        prevSrcThumbnail={prevImage.src}
                        onCloseRequest={handleClose}
                        onMovePrevRequest={handleMovePrev}
                        onMoveNextRequest={handleMoveNext}
                    />
                )}

                {modalImagesVisible && (
                    <ModalHabitaciones
                        modalVisible={modalImagesVisible}
                        setModalVisible={setModalImagesVisible}
                    />
                )}

                {modalVisibleRooms && (
                    <ModalEditRooms
                        modalVisibleRooms={modalVisibleRooms}
                        setModalVisibleRooms={setModalVisibleRooms}
                    />
                )}
            </div>
            <div
                className={`grid gap-6 md:mt-5 2xl:mt-10   pb-1 mb-1   ${
                    casa_habitaciones.length > 1
                        ? "lg:grid-cols-2"
                        : "lg:grid-cols-1 "
                }`}>
                {casa_habitaciones.length ? (
                    <div className="relative">
                        <img
                            src={
                                typeof banner === "string"
                                    ? `${process.env.REACT_APP_PROXY}/casa_habitaciones/getImage/${casa_habitaciones[0]?.imagen}`
                                    : URL.createObjectURL(banner)
                            }
                            className={`object-cover  rounded-lg   ${
                                casa_habitaciones.length > 1
                                    ? "w-full h-[500px]"
                                    : "w-full h-[500px] "
                            } `}
                            alt={`imagen principal`}
                        />

                        <div className="flex  absolute z-30 top-4 left-4">
                            {toggleButton && (
                                <div
                                    onClick={() => setModalVisibleRooms(true)}
                                    title="editar habitaciones"
                                    className="flex flex-col items-center px-2 py-1 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100 ">
                                    <svg
                                        className="w-8 h-8 text-blue-500"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                        <line
                                            x1="13.5"
                                            y1="6.5"
                                            x2="17.5"
                                            y2="10.5"
                                        />
                                    </svg>
                                    <div className="text-xs text-[#1C4CBB]">
                                        Editar Habitaciones
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`absolute h-[500px] w-full rounded-lg  bg-gradient-to-t from-black/70 top-0`}></div>
                        <div className="absolute z-20 flex items-end justify-between w-full max-h-[500px] bottom-3">
                            <div className="flex flex-col w-1/2 mx-5">
                                <div className="text-base font-bold text-white lg:text-3xl">
                                    {casa_habitaciones[0]?.habitacion}
                                </div>

                                <div className="mt-1 text-xs text-white lg:text-xl ">
                                    {casa_habitaciones[0]?.descripcion}
                                </div>
                            </div>

                            <div
                                onClick={() => handleClick(1)}
                                className="hover:scale-105 bg-white rounded-lg shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px] w-12 h-12 flex justify-center items-center mx-5 cursor-pointer">
                                <svg
                                    className="w-8 h-8 "
                                    id="Capa_2"
                                    data-name="Capa 2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 100 100">
                                    <g id="Testimonios">
                                        <path
                                            d="m94.89,39.06h-33.96V5.1c0-2.82-2.29-5.1-5.11-5.1h-11.66c-2.82,0-5.11,2.28-5.11,5.1v33.96H5.11c-2.82,0-5.11,2.29-5.11,5.11v11.66c0,2.82,2.29,5.11,5.11,5.11h33.96v33.95c0,2.82,2.29,5.11,5.11,5.11h11.66c2.82,0,5.11-2.29,5.11-5.11v-33.95h33.96c2.82,0,5.11-2.29,5.11-5.11v-11.66c0-2.82-2.29-5.11-5.11-5.11"
                                            fill="#1E90FF"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className={
                            toggleButton
                                ? "flex items-center justify-center gap-2"
                                : "hidden"
                        }>
                        <p className="text-[#1C4CBB] text-xl md:text-3xl font-semibold">
                            {" "}
                            Agregar habitaciones
                        </p>
                        <div
                            onClick={() => setModalVisibleRooms(true)}
                            title="editar habitaciones"
                            className="flex items-center px-2 py-1 my-5 mr-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100">
                            <svg
                                className="w-10 h-10 text-[#1C4CBB]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                            </svg>
                        </div>
                    </div>
                )}

                {casa_habitaciones.length > 0 && (
                    <div className="h-[500px]">
                        <div
                            className={`grid max-h-[500px] gap-6  ${
                                casa_habitaciones.length < 4
                                    ? "xs:grid-cols-1"
                                    : "xs:grid-cols-2"
                            }`}>
                            {casa_habitaciones.length > 1 && (
                                <div className={`relative  `}>
                                    <div
                                        className={`absolute  ${
                                            casa_habitaciones.length === 2
                                                ? "w-full h-[500px]"
                                                : "w-full h-[239px]"
                                        } w-full rounded-lg  bg-gradient-to-t from-black/70 top-0`}></div>
                                    <div className="absolute z-20 flex items-end justify-between w-full h-[239px] bottom-3">
                                        <div className="flex flex-col w-1/2 mx-5">
                                            <div className="text-base font-bold text-white lg:text-xl">
                                                {
                                                    casa_habitaciones[1]
                                                        ?.habitacion
                                                }
                                            </div>

                                            <div className="mt-1 text-xs text-white lg:text-base ">
                                                {
                                                    casa_habitaciones[1]
                                                        ?.descripcion
                                                }
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleClick(2)}
                                            className="hover:scale-105 w-10 h-10 bg-white rounded-lg shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px] cursor-pointer mx-3 flex justify-center items-center">
                                            <svg
                                                className="w-6 h-6 "
                                                id="Capa_2"
                                                data-name="Capa 2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 100 100">
                                                <g id="Testimonios">
                                                    <path
                                                        d="m94.89,39.06h-33.96V5.1c0-2.82-2.29-5.1-5.11-5.1h-11.66c-2.82,0-5.11,2.28-5.11,5.1v33.96H5.11c-2.82,0-5.11,2.29-5.11,5.11v11.66c0,2.82,2.29,5.11,5.11,5.11h33.96v33.95c0,2.82,2.29,5.11,5.11,5.11h11.66c2.82,0,5.11-2.29,5.11-5.11v-33.95h33.96c2.82,0,5.11-2.29,5.11-5.11v-11.66c0-2.82-2.29-5.11-5.11-5.11"
                                                        fill="#1E90FF"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                    <img
                                        src={
                                            typeof banner === "string"
                                                ? `${process.env.REACT_APP_PROXY}/casa_habitaciones/getImage/${casa_habitaciones[1]?.imagen}`
                                                : URL.createObjectURL(banner)
                                        }
                                        className={`object-cover rounded-lg  ${
                                            casa_habitaciones.length === 2
                                                ? "w-full h-[500px]"
                                                : "w-full h-[239px]"
                                        }`}
                                        alt={`imagen 1`}
                                    />
                                </div>
                            )}
                            {casa_habitaciones.length > 2 && (
                                <div className="relative  ">
                                    <div
                                        className={`absolute h-[239px] w-full rounded-lg  bg-gradient-to-t from-black/70 top-0`}></div>
                                    <div className="absolute z-20 flex items-end justify-between w-full h-[239px] bottom-3">
                                        <div className="flex flex-col w-1/2 mx-5">
                                            <div className="text-base font-bold text-white lg:text-xl">
                                                {
                                                    casa_habitaciones[2]
                                                        ?.habitacion
                                                }
                                            </div>

                                            <div className="mt-1 text-xs text-white lg:text-base ">
                                                {
                                                    casa_habitaciones[2]
                                                        ?.descripcion
                                                }
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleClick(3)}
                                            className="hover:scale-105 w-10 h-10 bg-white rounded-lg shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px] cursor-pointer mx-3 flex justify-center items-center">
                                            <svg
                                                className="w-6 h-6 "
                                                id="Capa_2"
                                                data-name="Capa 2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 100 100">
                                                <g id="Testimonios">
                                                    <path
                                                        d="m94.89,39.06h-33.96V5.1c0-2.82-2.29-5.1-5.11-5.1h-11.66c-2.82,0-5.11,2.28-5.11,5.1v33.96H5.11c-2.82,0-5.11,2.29-5.11,5.11v11.66c0,2.82,2.29,5.11,5.11,5.11h33.96v33.95c0,2.82,2.29,5.11,5.11,5.11h11.66c2.82,0,5.11-2.29,5.11-5.11v-33.95h33.96c2.82,0,5.11-2.29,5.11-5.11v-11.66c0-2.82-2.29-5.11-5.11-5.11"
                                                        fill="#1E90FF"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                    <img
                                        src={
                                            typeof banner === "string"
                                                ? `${process.env.REACT_APP_PROXY}/casa_habitaciones/getImage/${casa_habitaciones[2]?.imagen}`
                                                : URL.createObjectURL(banner)
                                        }
                                        className={`object-cover w-full ${"w-full h-[239px]"} rounded-lg`}
                                        alt={`imagen 1`}
                                    />
                                </div>
                            )}
                            {casa_habitaciones.length > 3 && (
                                <div className="relative  ">
                                    <div
                                        className={`absolute h-[239px] w-full rounded-lg  bg-gradient-to-t from-black/70 top-0`}></div>
                                    <div className="absolute z-20 flex items-end justify-between w-full h-[239px] bottom-3">
                                        <div className="flex flex-col w-1/2 mx-5">
                                            <div className="text-base font-bold text-white lg:text-xl">
                                                {
                                                    casa_habitaciones[3]
                                                        ?.habitacion
                                                }
                                            </div>

                                            <div className="mt-1 text-xs text-white lg:text-base ">
                                                {
                                                    casa_habitaciones[3]
                                                        ?.descripcion
                                                }
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleClick(4)}
                                            className="hover:scale-105 w-10 h-10 bg-white rounded-lg shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px] cursor-pointer mx-3 flex justify-center items-center">
                                            <svg
                                                className="w-6 h-6 "
                                                id="Capa_2"
                                                data-name="Capa 2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 100 100">
                                                <g id="Testimonios">
                                                    <path
                                                        d="m94.89,39.06h-33.96V5.1c0-2.82-2.29-5.1-5.11-5.1h-11.66c-2.82,0-5.11,2.28-5.11,5.1v33.96H5.11c-2.82,0-5.11,2.29-5.11,5.11v11.66c0,2.82,2.29,5.11,5.11,5.11h33.96v33.95c0,2.82,2.29,5.11,5.11,5.11h11.66c2.82,0,5.11-2.29,5.11-5.11v-33.95h33.96c2.82,0,5.11-2.29,5.11-5.11v-11.66c0-2.82-2.29-5.11-5.11-5.11"
                                                        fill="#1E90FF"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                    <img
                                        src={
                                            typeof banner === "string"
                                                ? `${process.env.REACT_APP_PROXY}/casa_habitaciones/getImage/${casa_habitaciones[3]?.imagen}`
                                                : URL.createObjectURL(banner)
                                        }
                                        className="object-cover w-full h-[239px] rounded-lg"
                                        alt={`imagen 1`}
                                    />
                                </div>
                            )}
                            {casa_habitaciones.length > 3 && (
                                <div
                                    onClick={() => setModalImagesVisible(true)}
                                    className=" flex flex-col items-center justify-center text-[#1C4CBB] font-semibold text-sm sm:text-lg cursor-pointer hover:scale-105">
                                    <p>VER MÁS</p>
                                    <p className="flex items-center">
                                        {" "}
                                        <span>
                                            <svg
                                                className="sm:h-6 sm:w-6 w-4 h-4 text-[#1C4CBB]"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <polyline points="9 6 15 12 9 18" />
                                            </svg>
                                        </span>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Imagenes;
