import React from 'react';

export const Paso = ({ numero }: { numero: number }) => {
  const paso1Activo = numero === 1;
  const paso2Activo = numero === 2;

  console.log(paso1Activo)

  return (
    <div className="flex items-center justify-center w-full max-w-[539px] mx-auto mt-28">
      {/* Paso 1 */}
      <div className="flex items-center ">
        
        
        <div className="flex-1 ml-2 bg-[#1C4CBB] px-4 py-2 rounded-full">
          <p className={'text-white font-bold '}>
            Paso 1
          </p>
        </div>
      </div>

      <div className={`w-full border-t h-1 ${paso2Activo ? 'border-[#1C4CBB] bg-[#1C4CBB]' : 'border-gray-300 bg-gray-300'}`}></div>

      {/* Paso 2 */}
      <div className="flex items-center justify-start ">
       
        
        <div className={paso2Activo ?"flex-1  bg-[#1C4CBB] px-4 py-2 rounded-full ":"flex-1  bg-gray-300 px-4 py-2 rounded-full "}>
          <p className={'text-white font-bold '}>
            Paso 2
          </p>
        </div>
      </div>
    </div>
  );
};
