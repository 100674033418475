import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { ChatBody } from "./ChatBody";
import { NavbarAdministrativa } from "../../dashboardAdministrativo/NavbarAdministrativa";
import { NavbarAdministrativaMobile } from "../../dashboardAdministrativo/NavbarAdministrativaMobile";

export interface usersChat {
    id: number;
    id_cliente: number;
    id_propietario: number;
    titulo: string;
    estado: string;
    fecha_registro: string;
    cliente: any;
    correo: any;
    codigo: any;
    Soporte: Soporte;
    Propietario: Propietario;
}

export interface Soporte {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

export interface Propietario {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

export interface Mensaje {
    id: number;
    id_usuario: number;
    message: string;
    avatar: string;
    time: string;
}
export interface Chat {
    id: number;
    title: string;
    id_cliente: number;
    id_propietario: number;
    last_message?: {
        time: string;
        message: string;
    };
    messageNotSeen?: number;
    mensajes: Mensaje[];
    estado: string;
    usersChat: usersChat;
}

export const AyudaPropietario = () => {
    const { idChat } = useParams();
    const [chats, setChats] = useState<Chat[]>([]);
    const [chatSelected, setChatSelected] = useState<Chat | null>(null);
    const { id: id_usuario, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const casa = useAppSelector((state) => state.landing_house.house);

    const [loading, setLoading] = useState<boolean>(false);

    const getChats = () => {
        fetchAxiosToken({
            url: `/chat_soporte/getAll/${id_usuario}/${
                tipo_usuario === 1 ? 0 : casa.id
            }`,
        })
            .then((res) => {
                if (chatSelected === null) {
                    res.map((r: any) => {
                        if (r.id === Number(idChat)) {
                            setChatSelected(r);
                            return;
                        }
                    });
                }
                setChats(res);
                setLoading(true);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (casa.id || tipo_usuario) {
            getChats();
        }
    }, [casa, tipo_usuario]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const createNewChat = async (tipo: string) => {
        try {
            const response = await fetchAxiosToken({
                url: `/chat_soporte`,
                method: "post",
                body: {
                    tipo: tipo,
                },
            });

            getChats();
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: `Chat iniciado correctamente`,
                icon: "success",
                showConfirmButton: true,
                confirmButtonText: "Aceptar",
            });
        } catch (error) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: `Hubo un error al iniciar el chat`,
                icon: "error",
                showConfirmButton: true,
                confirmButtonText: "Aceptar",
            });
        }
    };

    const handleNewChat = () => {
        // Swal.fire({  confirmButtonColor: "#029BFC",
        // iconColor: "#029BFC",
        //     title: "Ingresa el motivo de la conversación",
        //     input: "text",
        //     inputAttributes: {
        //         autocapitalize: "off",
        //     },
        //     showCancelButton: true,
        //     confirmButtonText: "Crear chat",
        //     cancelButtonText: "Cancelar",
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         createNewChat(result.value);
        //     }
        // });

        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "En que necesita ayuda?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Ayuda general",
            denyButtonText: `Soporte técnico`,
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                // Swal.fire('Saved!', '', 'success')
                createNewChat("General");
            } else if (result.isDenied) {
                // Swal.fire('Changes are not saved', '', 'info')
                createNewChat("Soporte");
            }
        });
    };
    const fechaUltimoMensaje = (ultimoMensaje: string) => {
        let nuevoFormato =
            ultimoMensaje &&
            new Date(ultimoMensaje).toLocaleDateString("es-ES", {
                month: "long",
                day: "numeric",
            });
        return nuevoFormato;
    };
    const render = () => {
        return (
            <div className="flex w-full px-4 m-2 mt-12 ">
                {chats.length > 0 || loading ? (
                    <div
                        className={`${
                            chatSelected ? "hidden" : ""
                        } border-2 rounded-l-lg border-[#CCCCCC] h-[85vh] overflow-auto lg:flex min-w-[19rem] flex flex-col justify-between`}>
                        <div>
                            <div className="flex items-center p-2 px-3 text-sm 2xl:text-base">
                                <button
                                    onClick={() => handleNewChat()}
                                    className={
                                        "bg-[#029BFC] text-white border p-2 rounded-md hover:scale-105 active:translate-y-0.5 w-full font-bold flex items-center justify-between px-6 py-3"
                                    }>
                                    <p> Generar nuevo chat</p>{" "}
                                    <p>
                                        <svg
                                            className="w-4 h-4"
                                            id="Capa_2"
                                            data-name="Capa 2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 100 100">
                                            <g id="Testimonios">
                                                <path
                                                    fill="white"
                                                    d="m94.89,39.06h-33.96V5.1c0-2.82-2.29-5.1-5.11-5.1h-11.66c-2.82,0-5.11,2.28-5.11,5.1v33.96H5.11c-2.82,0-5.11,2.29-5.11,5.11v11.66c0,2.82,2.29,5.11,5.11,5.11h33.96v33.95c0,2.82,2.29,5.11,5.11,5.11h11.66c2.82,0,5.11-2.29,5.11-5.11v-33.95h33.96c2.82,0,5.11-2.29,5.11-5.11v-11.66c0-2.82-2.29-5.11-5.11-5.11"
                                                />
                                            </g>
                                        </svg>
                                    </p>
                                </button>
                            </div>
                            {chats?.map((chat) => (
                                <div key={chat.id}>
                                    <div
                                        onClick={() => setChatSelected(chat)}
                                        className={`${
                                            chatSelected?.id === chat.id
                                                ? "bg-slate-100"
                                                : ""
                                        } cursor-pointer flex flex-col p-2`}>
                                        <div className="flex items-center gap-2">
                                            <img
                                                className="w-8 h-8 border rounded-full"
                                                src={`${
                                                    process.env.REACT_APP_PROXY
                                                }/usuarios/getImage/${
                                                    chat.usersChat
                                                        .id_cliente === 0
                                                        ? "sinImagen.png"
                                                        : chat.usersChat.Soporte
                                                              ?.avatar
                                                }`}
                                                alt="avatar del usuario"
                                            />
                                            <div className="w-full">
                                                <div className="flex justify-between">
                                                    <span className="text-sm font-medium 2xl:text-base">
                                                        {chat.title}
                                                    </span>
                                                    {chat.messageNotSeen ? (
                                                        <div className="h-5 w-5 pb-0.5  text-white flex justify-center items-center rounded-md bg-[#029BFC]">
                                                            <span className="text-xs font-medium">
                                                                {
                                                                    chat.messageNotSeen
                                                                }
                                                            </span>
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <p className="text-sm text-gray-500">
                                                    {chat.usersChat
                                                        .id_cliente === 0
                                                        ? chat.usersChat.cliente
                                                        : `${chat.usersChat.Soporte?.nombre} ${chat.usersChat.Soporte?.apellido}`}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex items-center gap-2"></div>

                                        {chat.last_message ? (
                                            <span className="flex justify-between mt-1 text-sm text-gray-500">
                                                <span>
                                                    Mensaje:{" "}
                                                    {chat.last_message.message
                                                        .length > 14
                                                        ? `${chat.last_message.message.slice(
                                                              0,
                                                              12
                                                          )}...`
                                                        : chat.last_message
                                                              .message}
                                                </span>
                                                <span>
                                                    {/* {fechaUltimoMensaje(chat.last_message.time)} */}
                                                    {chat.last_message.time}
                                                </span>
                                            </span>
                                        ) : (
                                            <span className="mt-1 text-sm text-gray-500 ">
                                                Aún no has iniciado esta
                                                conversación
                                            </span>
                                        )}
                                        <div className="flex gap-1 text-xs">
                                            <span className="text-gray-500">
                                                Estado:
                                            </span>
                                            <span
                                                className={`${
                                                    chat.estado === "Abierto"
                                                        ? "text-green-500"
                                                        : "text-red-500"
                                                } w-max font-medium`}>
                                                {chat.estado}
                                            </span>
                                        </div>
                                    </div>
                                    <hr className=" border border-t-[#CCCCCC] w-11/12 mx-auto" />
                                </div>
                            ))}
                        </div>
                        <div className="flex items-center justify-center gap-2 p-3 mt-2 text-sm text-center text-[#029BFC]">
                            <svg
                                className="w-4 h-4"
                                id="Capa_2"
                                data-name="Capa 2"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 75.12 100">
                                <g id="Testimonios">
                                    <path
                                        fill="#029BFC"
                                        d="m64.7,100H10.46c-.25-.07-.49-.16-.74-.22C4.11,98.5-.06,93.13,0,87.21c.13-12.39.04-24.78.04-37.16,0-4.16,1.72-7.5,5.02-9.96.97-.72,1.24-1.45,1.25-2.59.01-3.54-.28-7.09.24-10.62C9.23,8.96,26.12-2.92,43.87.63c14.34,2.86,24.95,15.73,25,30.34,0,2.34.04,4.68-.02,7.02-.02.84.25,1.35.94,1.85,3.49,2.53,5.33,5.95,5.32,10.31,0,12.52,0,25.04,0,37.56,0,2.66-.82,5.05-2.37,7.19-2,2.77-4.76,4.35-8.05,5.11Zm-27.19-62.51c3.77,0,7.54,0,11.31,0,2.15,0,4.29,0,6.44,0,.55,0,.97-.09.97-.78-.01-2.7.16-5.4-.24-8.08-1.44-9.77-10.29-16.73-20.05-15.83-7.88.72-14.58,6.39-16.45,14.08-.75,3.07-.55,6.2-.58,9.31,0,1.28.02,1.3,1.32,1.3,5.75,0,11.51,0,17.26,0Zm0,46.66c2.13,0,4.27-.04,6.4.02,1,.02,1.35-.35,1.33-1.34-.04-2.74,0-5.48-.02-8.21,0-.77.18-1.38.72-1.96,2.46-2.68,3.46-5.83,2.95-9.45-.72-5.11-5.04-9.84-11.45-9.72-4.83.09-8.48,2.49-10.36,7.03-1.83,4.42-1.03,8.52,2.16,12.1.52.59.75,1.18.74,1.95-.03,2.74.02,5.48-.03,8.21-.02,1.07.38,1.42,1.42,1.39,2.05-.06,4.11-.02,6.16-.02Z"
                                    />
                                </g>
                            </svg>
                            <span className="">
                                Solo tú y el cliente pueden ver esto
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center justify-center w-full h-96">
                        <Spinner />
                    </div>
                )}
                <ChatBody
                    chat={chatSelected}
                    setChat={setChatSelected}
                    getChats={getChats}
                />
            </div>
        );
    };
    return (
        <div className="w-full">
            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="Ayuda">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : (
                <NavbarAdministrativa titulo="Ayuda">
                    {render()}

                    <NavbarAdministrativaMobile />
                </NavbarAdministrativa>
            )}
        </div>
    );
};
