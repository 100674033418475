import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { NavbarBroker } from "./NavbarBroker";
import { NavbarMobileBroker } from "./NavbarMobileBroker";
import { useAppSelector } from "../../hooks/hooks";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { useDispatch } from "react-redux";
import { set_landing_house } from "../../redux/reducers/landingReducer";
import Spinner from "../../components/helpers/Spinner";

interface Cliente {
    apellido: string;
    avatar: string;
    id: number;
    nombre: string;
}

interface Propietario {
    apellido: string;
    avatar: string;
    id: number;
    nombre: string;
}

interface casa {
    banner: string;
    id: number;
    nombre: string;
    Propietario: Propietario;
}

interface contacto {
    id: number;
    fecha_inicio: string;
    fecha_final: string;
    costo_total: number;
    avatar: string;
    estado: string;
    habilitado: string;
    fecha_registro: string;
    Cliente: Cliente;
    casa: casa;
}

interface getComfort {
    pagina: number;
    habilitado: string;
    query: string;
}

export interface Contactos {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    celular: string;
    avatar: string;
    tipo_contacto: string;
    fecha_ultima_reserva: string;
    id_pedido: number;
    etapa_crm: string;
    id_crm: number;
}

export const CasasBroker = () => {
    const [contactos, setContactos] = useState<Contactos[]>([]);
    const [contactoSelect, setContactoSelect] = useState<Contactos | null>(
        null
    );
    const [modalContacto, setModalContacto] = useState(false);

    const [pageNumber, setpageNumber] = useState<number>(1);

    const [input, setInput] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const debounceInput = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [modalVisibleUsuario, setModalVisibleUsuario] =
        useState<boolean>(false);
    const [modalMasiva, setModalMasiva] = useState(false);

    const navigate = useNavigate();
    const contactoS_PER_PAGE = 20;
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );

    const dispatch = useDispatch();
    const { casas } = useAppSelector((state) => state.corredor);

    const handleCalendario = async (id: number) => {
        try {
            if (id) {
                navigate(`/dashboard-corredor/calendario/${id}`);
            }
        } catch (error) {
            console.log(error);
            dispatch(set_landing_house(null));
        }
    };

    const handleReserva = async (id: number) => {
        try {
            if (id) {
                navigate(`/dashboard-corredor/reservas/${id}`);
            }
        } catch (error) {
            console.log(error);
            dispatch(set_landing_house(null));
        }
    };

    const handleReviews = async (id: number) => {
        try {
            if (id) {
                navigate(`/dashboard-corredor/reviews/${id}`);
            }
        } catch (error) {
            console.log(error);
            dispatch(set_landing_house(null));
        }
    };

    const handleConfiguraciones = async (id: number) => {
        try {
            if (id) {
                navigate(`/dashboard-corredor/configuraciones/${id}`);
            }
        } catch (error) {
            console.log(error);
            dispatch(set_landing_house(null));
        }
    };

    const render = () => {
        return (
            <div className="w-full">
                {loading ? (
                    <div className="flex-col items-center justify-center">
                        <div className="flex items-center justify-center mx-auto w-28 h-28">
                            <Spinner width={28} />
                        </div>
                        <div className="mt-5 text-xl text-center">
                            Por favor, aguarde mientras cargamos los datos.
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="flex items-center px-3 mx-auto mt-4 sm:px-6">
                            <div className="flex flex-col items-center justify-center w-full gap-3 md818:flex-row md818:justify-end">
                                <div className="flex"></div>

                                <div className="flex gap-4 md:ml-4">
                                    <button
                                        onClick={() =>
                                            navigate(
                                                "/dashboard-corredor/onboarding/alojamiento/0"
                                            )
                                        }
                                        className="flex items-center gap-1 p-2 border-2 rounded-lg h-[51px] border-[#CCCCCC] text-[#808080] hover:scale-105">
                                        <svg
                                            className="h-6 w-6 mt-0.5 text-[#808080]"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                            />
                                            <line
                                                x1="12"
                                                y1="5"
                                                x2="12"
                                                y2="19"
                                            />
                                            <line
                                                x1="5"
                                                y1="12"
                                                x2="19"
                                                y2="12"
                                            />
                                        </svg>
                                        <span>Crear Casa</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <section className="flex justify-center items-center lg:pb-[10px] ">
                            <div className="w-full px-11">
                                <div className="flex flex-wrap -mx-8">
                                    <div className="w-full ">
                                        <div className="flex flex-col">
                                            {true ? (
                                                <div className="w-full ">
                                                    <table className="w-full mt-6 overflow-x-auto ">
                                                        <thead className="text-left border-b-[3px] border-[#CCCCCC] 2xl:text-base text-sm">
                                                            <tr className="">
                                                                <th className="w-full min-w-[10rem] md:w-3/12 pb-4 ">
                                                                    Nombre
                                                                </th>
                                                                <th className="w-full min-w-[5rem] md:w-2/12 pb-4 text-center hidden xs:table-cell">
                                                                    Estado
                                                                </th>
                                                                <th className="w-full min-w-[10rem] md:w-2/12 hidden lg1170:table-cell pb-4 text-center">
                                                                    Precio
                                                                </th>
                                                                <th className="w-full min-w-[8rem] md:w-2/12 pb-4 text-center hidden lg:table-cell">
                                                                    Moneda
                                                                </th>
                                                                <th className="w-full min-w-[8rem] md:w-2/12 pb-4 text-center">
                                                                    Acciones
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <div className="my-2 2xl:my-4"></div>
                                                        <tbody className="">
                                                            {casas?.map(
                                                                (
                                                                    casa: any,
                                                                    i
                                                                ) => (
                                                                    <tr
                                                                        key={i}
                                                                        className="w-full text-sm cursor-pointer hover:bg-gray-200 2xl:text-base">
                                                                        <td className=" border-r-[3px] border-[#CCCCCC]">
                                                                            <div className="flex items-center 2xl:py-3 py-1.5 ">
                                                                                <img
                                                                                    src={
                                                                                        `${process.env.REACT_APP_PROXY}/casa/getImage/` +
                                                                                        casa?.banner
                                                                                    }
                                                                                    alt="icono"
                                                                                    className="object-cover w-10 h-10 rounded"
                                                                                />
                                                                                <p className="mx-3 text-[#3C3C3C] 2xl:text-base text-sm">
                                                                                    {`${casa.nombre}`}
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td className=" border-r-[3px] border-[#CCCCCC] text-center hidden xs:table-cell px-1">
                                                                            xxx
                                                                        </td>
                                                                        <td className="hidden lg1170:table-cell border-r-[3px] border-[#CCCCCC] text-center">
                                                                            {
                                                                                casa.precio_base
                                                                            }
                                                                        </td>
                                                                        <td className="border-r-[3px] border-[#CCCCCC] hidden text-center lg:table-cell">
                                                                            {
                                                                                casa.tipo_moneda
                                                                            }
                                                                        </td>
                                                                        <td className="gap-2 border-r-[3px] border-[#CCCCCC] text-center ">
                                                                            <div className="flex items-center justify-center gap-2">
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleCalendario(
                                                                                            casa.id
                                                                                        )
                                                                                    }
                                                                                    className="h-9 w-9 rounded-md bg-[#029BFC] hover:scale-105 flex justify-center items-center">
                                                                                    <svg
                                                                                        className="text-white h-7 w-7"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />{" "}
                                                                                        <rect
                                                                                            x="4"
                                                                                            y="5"
                                                                                            width="16"
                                                                                            height="16"
                                                                                            rx="2"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="16"
                                                                                            y1="3"
                                                                                            x2="16"
                                                                                            y2="7"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="8"
                                                                                            y1="3"
                                                                                            x2="8"
                                                                                            y2="7"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="4"
                                                                                            y1="11"
                                                                                            x2="20"
                                                                                            y2="11"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="11"
                                                                                            y1="15"
                                                                                            x2="12"
                                                                                            y2="15"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="12"
                                                                                            y1="15"
                                                                                            x2="12"
                                                                                            y2="18"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleReserva(
                                                                                            casa.id
                                                                                        )
                                                                                    }
                                                                                    className="h-9 w-9 rounded-md bg-[#029BFC] hover:scale-105 flex justify-center items-center">
                                                                                    <svg
                                                                                        className="text-white h-7 w-7"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                        stroke="currentColor">
                                                                                        <path
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            strokeWidth="2"
                                                                                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleReviews(
                                                                                            casa.id
                                                                                        )
                                                                                    }
                                                                                    className="h-9 w-9 rounded-md bg-[#029BFC] hover:scale-105 flex justify-center items-center">
                                                                                    <svg
                                                                                        className="text-white h-7 w-7"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />{" "}
                                                                                        <path d="M12 17.75l-6.172 3.245 1.179-6.873-4.993-4.867 6.9-1.002L12 2l3.086 6.253 6.9 1.002-4.993 4.867 1.179 6.873z" />
                                                                                    </svg>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleConfiguraciones(
                                                                                            casa.id
                                                                                        )
                                                                                    }
                                                                                    className="h-9 w-9 rounded-md bg-[#029BFC] hover:scale-105 flex justify-center items-center">
                                                                                    <svg
                                                                                        className="text-white h-7 w-7"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />{" "}
                                                                                        <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />{" "}
                                                                                        <circle
                                                                                            cx="12"
                                                                                            cy="12"
                                                                                            r="3"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : (
                                                "Aun no tiene Casas"
                                            )}
                                        </div>

                                        {/* <div className="container flex items-center justify-between p-1 mx-auto">
                                    <Paginado
                                        elementsPerPage={contactoS_PER_PAGE}
                                        cantTotalElements={contactosTotal}
                                        pagination={pagination}
                                        actualPage={pageNumber}
                                    />
                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <NavbarBroker titulo="Casas">
                {render()}
                <NavbarMobileBroker />
            </NavbarBroker>
        </div>
    );
};
