import axios from "axios";
import React, { useEffect } from "react";
import Design1 from "../../designs/design1New/Design1";
import Design2 from "../../designs/design2New/Design2";
import Design3 from "../../designs/design3New/Design3";
import Design4 from "../../designs/design4New/Design4";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { useAppSelector } from "../../hooks/hooks";

export const LandingElegida = () => {
    const { house } = useAppSelector((state) => state.landing_house);
    const contador = localStorage.getItem("contador");

    const getHousesCarrouselOne = async () => {
        const data = await fetchAxiosNoToken({
            url: `/visitas/`,
            method: "post",
        });

        if (data.message === "registro exitoso") {
            localStorage.setItem("contador", "Si");
        }
    };

    useEffect(() => {
        if (contador !== "Si") {
            getHousesCarrouselOne();
        }
    }, []);

    const chooseSelectedDesign = () => {
        switch (house?.tema) {
            case "1":
                return <Design1 />;
            case "2":
                return <Design2 />;
            case "3":
                return <Design3 />;
            case "4":
                return <Design4 />;
            default:
                break;
        }
    };

    return <>{chooseSelectedDesign()}</>;
};
