import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import mujer from "../../img/imagen-home-mujer.webp";

const Inicio = () => {
    const { tags } = useParams();
    const navigate = useNavigate();
    return (
        <div className="flex justify-center w-full bg-[#0E1928]">
            <div className="grid md723:grid-cols-[60%,40%] pt-24 mt-8 xs:pt-32 md:mt-10  bg-[#0E1928] w-9/12 mx-auto 2xl:w-4/6">
                <div className="flex flex-col items-center justify-start w-full pb-5 ">
                    <div className="flex items-center  px-1 bg-[#C0D345] rounded-3xl   justify-center shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)]  w-11/12 lg:w-full mt-5">
                        <div className="py-2  rounded-3xl text-[#0E1928] font-bold text-center text-[12px] md:text-[14px] xl:text-[15px] 2xl:text-[17px]">
                            Te damos todas las herramientas digitales para
                            incrementar tus alquileres
                        </div>
                    </div>

                    <div className="relative w-full grid-cols-2 overflow-visible">
                        <div>
                            <div className="z-10 flex flex-col justify-center pt-3 text-white rounded-lg md723:justify-start ">
                                <div className="py-3 rounded-3xl  font-bold text-center md723:text-start text-[18px] md:text-[22px] lg:text-[38px]">
                                    <p>GESTIONAR TU PROPIEDAD </p>
                                    <p>NUNCA FUE TAN FÁCIL</p>
                                </div>

                                <div className="py-3  rounded-3xl text-gray-500  text-center md723:text-start text-[12px] md:text-[15px] lg:text-[18px]  md723:mx-0 mx-auto">
                                    Aumenta tus reservas y no dependas de
                                    intermediarios como Airbnb o Booking.com{" "}
                                    <p>con estas herramientas tendrás</p>
                                </div>

                                <div className="py-3 rounded-3xl text-[#C0D345] justify-start font-bold text-center md723:text-start text-[18px] md:text-[23px] lg:text-[25px] ">
                                    Más alquileres y huéspedes más felices
                                </div>
                            </div>
                            <div className="flex  items-center justify-center md723:justify-start w-full  mx-auto  text-[#0E1928] rounded-lg flex-row md723:mb-10">
                                <div
                                    onClick={() =>
                                        navigate(`/registro-propietario`)
                                    }
                                    className="flex  px-4 gap-2 mx-5 justify-between items-center bg-[#C0D345] rounded-3xl   cursor-pointer hover:scale-105">
                                    <div className="py-1 text-xs cursor-pointer md:text-base">
                                        Prueba gratis
                                    </div>
                                    <div className="px-1 py-2">
                                        <svg
                                            width="7"
                                            height="10"
                                            viewBox="0 0 7 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_1_38)">
                                                <path
                                                    d="M5.81305 4.99428L0.170438 0.765716C0.0751903 0.694486 0.0139897 0.590648 0.000291429 0.477054C-0.0134069 0.363461 0.0215221 0.249419 0.097397 0.160003C0.173272 0.0705869 0.283873 0.0131247 0.404875 0.000265013C0.525877 -0.0125946 0.647366 0.0202045 0.742613 0.0914341L6.82957 4.66286C6.88203 4.70416 6.92421 4.75574 6.95316 4.81399C6.9821 4.87223 6.9971 4.93573 6.9971 5C6.9971 5.06428 6.9821 5.12777 6.95316 5.18602C6.92421 5.24427 6.88203 5.29585 6.82957 5.33714L0.742613 9.90857C0.647366 9.9798 0.525877 10.0126 0.404875 9.99974C0.283873 9.98688 0.173272 9.92942 0.097397 9.84C0.0215221 9.75059 -0.0134069 9.63655 0.000291429 9.52295C0.0139897 9.40936 0.0751903 9.30552 0.170438 9.23429L5.81305 4.99428Z"
                                                    fill="#0E1928"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1_38">
                                                    <rect
                                                        width="7"
                                                        height="10"
                                                        fill="white"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>

                                <div
                                    onClick={() =>
                                        navigate(
                                            `/watchDemo/${tags ? tags : "6"}`
                                        )
                                    }
                                    className="flex  mx-5 px-4 gap-2 justify-between items-center bg-[#C0D345] text-[20px]  rounded-3xl cursor-pointer hover:scale-105">
                                    <div className="py-1 text-xs cursor-pointer md:text-base">
                                        Ver demo
                                    </div>
                                    <div className="px-1 py-2">
                                        <svg
                                            width="7"
                                            height="10"
                                            viewBox="0 0 7 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_1_38)">
                                                <path
                                                    d="M5.81305 4.99428L0.170438 0.765716C0.0751903 0.694486 0.0139897 0.590648 0.000291429 0.477054C-0.0134069 0.363461 0.0215221 0.249419 0.097397 0.160003C0.173272 0.0705869 0.283873 0.0131247 0.404875 0.000265013C0.525877 -0.0125946 0.647366 0.0202045 0.742613 0.0914341L6.82957 4.66286C6.88203 4.70416 6.92421 4.75574 6.95316 4.81399C6.9821 4.87223 6.9971 4.93573 6.9971 5C6.9971 5.06428 6.9821 5.12777 6.95316 5.18602C6.92421 5.24427 6.88203 5.29585 6.82957 5.33714L0.742613 9.90857C0.647366 9.9798 0.525877 10.0126 0.404875 9.99974C0.283873 9.98688 0.173272 9.92942 0.097397 9.84C0.0215221 9.75059 -0.0134069 9.63655 0.000291429 9.52295C0.0139897 9.40936 0.0751903 9.30552 0.170438 9.23429L5.81305 4.99428Z"
                                                    fill="#0E1928"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1_38">
                                                    <rect
                                                        width="7"
                                                        height="10"
                                                        fill="white"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full h-full md723:relative">
                    <img
                        src={mujer}
                        alt="Background"
                        className="object-contain w-full h-48 xs:h-80 lg:h-[20rem] 2xl:h-[28rem] md723:absolute bottom-0 "
                    />
                </div>
            </div>
        </div>
    );
};
export default Inicio;
