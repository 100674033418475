import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonRegresar } from "../../components/ButtonRegresar";
import { ButtonSiguiente } from "./ButtonSiguiente";
import escogerTema from "../../img/onboarding/escoger_tema.jpg";
import pictureDesign1 from "../../img/onboarding/desingnew1.webp";
import pictureDesign2 from "../../img/onboarding/desingnew2.webp";
import pictureDesign3 from "../../img/onboarding/desingnew3.webp";
import pictureDesign4 from "../../img/onboarding/desingnew4.webp";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import "../../assets/css/styles_animation_scale.css";
import BotonInformacion from "../../components/helpers/BotonInformacion";

const TEMAS = [
    {
        id: 1,
        title: "Plantilla 1",
        image: pictureDesign1,
        type: "Básico",
        info: "Gratis por siempre",
    },
    {
        id: 2,
        title: "Plantilla 2",
        image: pictureDesign2,
        type: "Básico",
        info: "Gratis por siempre",
    },
    {
        id: 3,
        title: "Plantilla 3",
        image: pictureDesign3,
        type: "Stándar",
        info: "Gratis por 14 días, luego deberás pagar un fee mensual",
    },
    {
        id: 4,
        title: "Plantilla 4",
        image: pictureDesign4,
        type: "Stándar",
        info: "Gratis por 14 días, luego deberás pagar un fee mensual",
    },
];

export const EscogerTema = () => {
    const [temaSelected, setTemaSelected] = useState<number>(0);
    const navigate = useNavigate();
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const { id: id_casa, tema } = useAppSelector(
        (state) => state.landing_house.house
    );

    const casa = useAppSelector((state) => state.landing_house);

    useEffect(() => {
        setTemaSelected(Number(tema));
    }, [tema]);
    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);
    const handleSubmit = async () => {
        if (idCasa && Number(idCasa) > 0) {
            await fetchAxiosToken({
                url: `/casa/putTema/${idCasa}`,
                method: "put",
                body: {
                    tema: temaSelected,
                },
            });

            await fetchAxiosToken({
                url: `/casa/putPagina/${idCasa}`,
                method: "put",
                body: {
                    pagina: "imagenes",
                },
            });
            dispatchDominio();
            navigate(`/dashboard-corredor/onboarding/imagenes/${idCasa}`);
        } else {
            await fetchAxiosToken({
                url: `/casa/putTema/${id_casa}`,
                method: "put",
                body: {
                    tema: temaSelected,
                },
            });

            await fetchAxiosToken({
                url: `/casa/putPagina/${id_casa}`,
                method: "put",
                body: {
                    pagina: "imagenes",
                },
            });
            dispatchDominio();
            navigate(`/dashboard/onboarding/imagenes`);
        }
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/4 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={escogerTema}
                        alt="escoger_tema"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl::text-6xl ">
                            Escoge una plantilla para tu web
                        </div>

                        <div className="mt-5 text-lg text-center text-white md:text-xl 2xl:text-2xl md:mt-14 ">
                            ¡No te preocupes podrás cambiarlo más adelante!
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center p-6 text-center text-black bg-white lg:overflow-x-auto">
                <ButtonRegresar
                    url={`${
                        idCasa
                            ? `/dashboard-corredor/onboarding/capacidad/${idCasa}`
                            : "/dashboard/onboarding/capacidad"
                    }`}
                    className="absolute top-10 left-10"
                    arrowColor="black"
                    textColor="black"
                />
                <div className="flex flex-col pb-4 mx-auto mt-4 2xl:mt-80 lg:mt-16 lg:w-11/12">
                    <div
                        className={`grid md:grid-cols-2 grid-cols-1 justify-center gap-8 2xl:mt-44 lg:mt-56 mt-10 `}
                    >
                        {TEMAS.map((tema) => (
                            <div
                                className={`  rounded-lg flex flex-col  relative mt-10 lg:top-44  ${
                                    temaSelected === tema.id
                                        ? " scale-105 border-4 border-[#029BFC] rounded-lg"
                                        : "  "
                                } `}
                                key={tema.id}
                            >
                                <img
                                    onClick={() =>
                                        casa.house.plan >= 0 &&
                                        setTemaSelected(tema.id)
                                    }
                                    className={`2xl:h-96 h-72 cursor-pointer object-cover object-left-top ${
                                        temaSelected === tema.id
                                            ? "scale-110 border-4 border-[#029BFC] rounded-lg"
                                            : ""
                                    } `}
                                    /*  ${
                                        casa.house.plan < 2 &&
                                        tema.type === "Standard" &&
                                        "opacity-30"
                                    } */
                                    src={tema.image}
                                    alt={tema.title}
                                />
                                <div className="flex items-center justify-around mt-6">
                                    <p
                                        className={`font-bold text-[#4D4D4D] text-sm flex gap-1 items-center`}
                                    >
                                        {tema.title} - {tema.type}
                                        <BotonInformacion
                                            text={tema.info}
                                            ver={true}
                                            left={14}
                                            widthHeight={" w-4 h-4"}
                                        />
                                    </p>
                                    <a
                                        target={"_blank"}
                                        href={`/dashboard/onboarding/temaEjemplo/${tema.id}`}
                                        className={`  text-xs ${
                                            tema.type === "Standard"
                                                ? "bg-blue-500 text-white animated-button"
                                                : "bg-[#E6E6E6] text-[#4D4D4D]"
                                        }  px-2 py-1 rounded-md cursor-pointer hover:scale-105`}
                                        rel="noreferrer"
                                    >
                                        Vista Previa
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="lg:mt-60 ">
                        <ButtonSiguiente onSubmit={() => handleSubmit()} />
                    </div>
                </div>
            </div>
        </div>
    );
};
