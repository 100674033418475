export const normalizeString = (input: string | undefined): string => {
    let cadena = input;

    cadena = cadena?.replace(/ /g, "-");

    cadena = cadena
        ?.toLowerCase()
        .trim()
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

    return cadena || "";
};
