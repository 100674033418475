import React, { useState } from "react";

import { useParams } from "react-router";
import axios from "axios";
import { useNavigate } from "react-router";

import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";

const RecuerarContraseña = () => {
    const { token, id } = useParams();
    const navigate = useNavigate();
    const [ojo, setOjo] = useState(false);
    const [saving, setSaving] = useState<boolean>(false);

    const [contraseña, setContraseña] = useState({
        contraseña: "",
        contraseña_confirmacion: "",
    });

    const enviarCorreo = async () => {
        if (
            contraseña.contraseña === "" ||
            contraseña.contraseña_confirmacion === ""
        ) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                icon: "error",
                title: "Oops...",
                text: "Debes escribir una contraseña",
            });
            return;
        }
        if (contraseña.contraseña !== contraseña.contraseña_confirmacion) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                icon: "error",
                title: "Oops...",
                text: "Las contraseñas no coinciden",
            });
        } else {
            setSaving(true);
            try {
                const json = await axios.put(
                    `${process.env.REACT_APP_PROXY}/usuarios/recuperarPassword/`,

                    {
                        password: contraseña.contraseña,
                        id: id,
                        token: token,
                    }
                );

                if (json.data.message === "actualizacion exitoso") {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        title: "Exito!",

                        text: " Contraseña cambiada con exito",
                        icon: "success",

                        confirmButtonText: "Aceptar",
                    }).then((result) => {
                        navigate(`/`);
                    });
                }
            } catch (error: any) {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Error!",
                    icon: "error",

                    text: error.response.data.error,
                    confirmButtonText: "Aceptar",
                });
            }
            setSaving(false);
        }
    };
    return (
        <div className="flex flex-col items-center justify-center h-screen p-4">
            <div
                className={
                    "flex items-center text-lg cursor-pointer pt-4 px-4 absolute top-3 left-3 "
                }
                onClick={() => {
                    navigate("/");
                }}>
                <svg
                    className="w-6 h-6 -ml-2 text-black"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    {" "}
                    <polyline points="15 18 9 12 15 6" />
                </svg>
                <span className="mx-1">Regresar</span>
            </div>
            <div className="">
                <h1 className="text-xl font-bold lg:text-3xl">
                    Recuperar Contraseña
                </h1>
            </div>
            <div className="mt-8 w-full md:w-1/2 md:max-w-[468px]">
                <div className="w-full mt-4 ">
                    <label className="text-sm font-medium">
                        Nueva contraseña:
                    </label>
                    <div className="relative flex items-center">
                        <input
                            value={contraseña.contraseña}
                            type={ojo ? "text" : "password"}
                            onChange={(e) => {
                                setContraseña({
                                    ...contraseña,
                                    contraseña: e.target.value,
                                });
                            }}
                            name="tipo_cuenta"
                            placeholder="Nueva contraseña"
                            className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        />
                        <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                            {ojo ? (
                                <>
                                    <svg
                                        className={`w-6 h-6 mx-2 cursor-pointer`}
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        onClick={() => {
                                            setOjo(!ojo);
                                        }}>
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                        />
                                    </svg>
                                </>
                            ) : (
                                <>
                                    <svg
                                        className={`w-6 h-6 mx-2 cursor-pointer`}
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        onClick={() => {
                                            setOjo(!ojo);
                                        }}>
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                        />
                                    </svg>
                                </>
                            )}
                        </span>
                    </div>
                </div>
                <div
                    className={"tab-pane fade show active mb-4"}
                    id="tabs-messages"
                    role="tabpanel"
                    aria-labelledby="tabs-profile-tab">
                    <div className="w-full mt-3 ">
                        <label className="text-sm font-medium">
                            Repetir contraseña:
                        </label>

                        <div className="relative flex items-center">
                            <input
                                type={ojo ? "text" : "password"}
                                onChange={(e) => {
                                    setContraseña({
                                        ...contraseña,
                                        contraseña_confirmacion: e.target.value,
                                    });
                                }}
                                name="tipo_cuenta"
                                placeholder="Repetir contraseña"
                                value={contraseña.contraseña_confirmacion}
                                className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                            />
                            <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                                {ojo ? (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setOjo(!ojo);
                                            }}>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setOjo(!ojo);
                                            }}>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                            />
                                        </svg>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                {saving ? (
                    <Spinner />
                ) : (
                    <button
                        onClick={() => enviarCorreo()}
                        className="w-full xs:w-7/12 flex items-center justify-center mx-auto rounded-lg text-center  bg-blue-500 py-3  text-white hover:bg-blue-400 active:translate-y-[0.125rem]  mt-3 mb-3">
                        Guardar
                    </button>
                )}
            </div>
        </div>
    );
};

export default RecuerarContraseña;
