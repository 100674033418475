import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ElegirMoneda } from "../../components/ElegirMoneda";
import { NavbarsAvatar } from "../../components/NavbarsAvatar";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { Notificaiones } from "../../designs/modalEdit/Notificaiones";
import BurguerMenuCorredor from "./BurguerMenuCorredor";

const NavbarCorredor = () => {
    const [titleSelected, setTitleSelected] = useState<number>(0);

    const navigate = useNavigate();
    const { view } = useParams();
    const corredor = useAppSelector((state) => state.corredor);

    useEffect(() => {
        if (view) {
            // window.scrollTo(0, 0);
            const element = document.getElementById(view);
            const marginTopToScroll = -90;
            if (element) {
                const elementYposition =
                    element?.getBoundingClientRect().top +
                    window.pageYOffset +
                    marginTopToScroll;

                window.scrollTo({ top: elementYposition, behavior: "smooth" });
            }
        }
    }, [view]);

    return (
        <div className="sticky top-0 z-50 w-full ">
            <div className="hidden w-full md818:flex justify-between items-center bg-[#1C4CBB] p-4 px-5 text-white">
                <span className="font-medium uppercase">
                    {corredor.nombre} {corredor.apellido}
                </span>
                <div className="flex items-center gap-4 lg:gap-6">
                    <Notificaiones />

                    <ElegirMoneda />
                    <NavbarsAvatar />
                </div>
            </div>
            <BurguerMenuCorredor className="md818:hidden" />
        </div>
    );
};

export default NavbarCorredor;
