import React from "react";

import { Helmet } from "react-helmet";
import MainNavbar from "../MainNavbar/MainNavbar";
import { Footer } from "../Footer";

const PoliticasDeUsoYPriv = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <MainNavbar />

            <div className="bg-[#0E1928] pt-32 h-72 flex">
                <p className="w-10/12 m-auto text-2xl font-bold text-center text-white ">
                    POLÍTICAS DE USO Y PRIVACIDAD
                </p>
            </div>
            <div className="w-10/12 mx-auto mt-10 text-lg text-[#0E1928] mb-10">
                <p>
                    BluStay PLATAFORMA WEB busca acercar a propietarios con
                    posibles clientes para la venta y/o alquiler de productos y
                    servicios. El presente documento contiene los Términos y
                    Condiciones de Uso que regulan el acceso y uso de este
                    Portal (en adelante “el sitio”) y, en general, la relación
                    entre BluStay PLATAFORMA WEB y los USUARIOS del sitio, sin
                    perjuicio de otras condiciones e instrucciones particulares
                    o específicas que BluStay comunique adecuadamente a los
                    usuarios. La utilización del sitio atribuye la condición de
                    USUARIO del mismo, e implica la aceptación plena y sin
                    reservas de todas y cada una de las disposiciones incluidas
                    en estos Términos y Condiciones, siendo obligación del
                    USUARIO leer atentamente los Términos y Condiciones antes de
                    hacer uso del sitio y de su contenido. Al comunicarte con
                    este sitio Web, las políticas y normas detalladas a
                    continuación, se consideran aceptadas.
                </p>
                <div>
                    <ol className="mt-10">
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">1. DEFINICIONES</p>
                            <ul className="list-disc">
                                <li className="ml-10">
                                    “Usuario (s)”: Se entiende por usuario a
                                    todo aquel que acceda a portal web.
                                </li>
                                <li className="ml-10">
                                    “Cliente (s)”: Se entiende por cliente a
                                    todos los usuario que solicite, contrate y
                                    adquiera alguno de los servicios ofrecidos
                                    por BluStay PLATAFORMA WEB y manifiesta su
                                    participación en la comunidad BluStay
                                    PLATAFORMA WEB de acuerdo a los Términos.
                                </li>
                                <li className="ml-10">
                                    “Aliado (s)”: Se entiende por Aliado al
                                    prestador final y efectivo del servicio y/o
                                    producto. Pueden ser personas naturales o
                                    jurídicas.
                                </li>
                                <li className="ml-10">
                                    “Empresa”: Se entiende por la Empresa a
                                    BluStay.
                                </li>
                                <li className="ml-10">
                                    “Segmentación”: Es la separación o división
                                    de las actividades, productos y/o servicios,
                                    realizado de acuerdo a los criterios de
                                    especialidad, afinidad, características,
                                    entre otros parámetros, realizada de manera
                                    directa por BluStay PLATAFORMA WEB para
                                    brindar facilidades en la adquisición de
                                    servicios.
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                2. SOBRE BluStay PLATAFORMA WEB
                            </p>
                            <ul className="list-disc">
                                <li className="ml-10">
                                    BluStay PLATAFORMA WEB es un portal online
                                    especializado a través del cual nuestros
                                    usuarios podrán:
                                    <ul className="list-decimal">
                                        <li className="ml-10">
                                            Encontrar de manera organizada y
                                            segmentada alquiler de equipos
                                            náuticos, alquiler de casas de playa
                                            y/o campo, compra de experiencias
                                            (full day, paseos, etc.), servicios
                                            de publicidad especializada, entre
                                            otros.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                3. USO DEL PORTAL:
                            </p>
                            <ul className="list-disc">
                                <li className="ml-10">
                                    El uso del portal es gratuito, dependiendo
                                    de la necesidad los usuarios podrán
                                    registrarse bajo determinados perfiles de
                                    acuerdo a sus necesidades.
                                </li>
                                <li className="ml-10">
                                    Para mayor información sobre el uso del
                                    portal, los usuarios pueden consultar los
                                    Términos y Condiciones Generales de Uso de
                                    BluStay.
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                4. MODALIDADES DE REGISTRO, OBLIGACIONES Y
                                DERECHOS DE LOS USUARIOS:
                            </p>
                            <ul className="list-disc">
                                <li className="ml-10">
                                    Los usuarios podrán registrarse bajo
                                    perfiles especializados de acuerdo a sus
                                    necesidades, teniendo a su disposición los
                                    siguientes perfiles:
                                    <ul className="list-decimal">
                                        <li className="ml-10">
                                            <b>Clientes</b>: Son los usuarios
                                            que cuentan con alguna necesidad que
                                            desean satisfacer a través de la
                                            adquisición de un producto y/
                                            servicio específico que se encuentra
                                            publicado u ofrecido a través de
                                            BluStay PLATAFORMA WEB.
                                        </li>
                                    </ul>
                                </li>
                                <li className="ml-10">
                                    Los usuarios que se registren bajo este
                                    formato se encuentran sujetos a las
                                    siguientes
                                    <b>
                                        {" "}
                                        consideraciones, obligaciones y derechos
                                    </b>
                                    :
                                    <ul className="list-decimal">
                                        <li className="ml-10">
                                            El usuario deberá realizar el
                                            proceso de registro y brindar
                                            información veraz y fidedigna, la
                                            cual automáticamente tendrá
                                            condición de declaración jurada.
                                        </li>
                                        <li className="ml-10">
                                            El usuario será íntegramente
                                            responsable del manejo y control de
                                            su clave y usuario.
                                        </li>
                                        <li className="ml-10">
                                            El usuario será responsable por
                                            todas las operaciones efectuadas en
                                            su cuenta, pues el acceso a la misma
                                            está restringido al ingreso y uso de
                                            su clave de seguridad, de
                                            conocimiento exclusivo del Usuario.
                                        </li>
                                        <li className="ml-10">
                                            El usuario se compromete a notificar
                                            a BluStay PLATAFORMA WEB de forma
                                            inmediata y por medio idóneo y
                                            fehaciente, cualquier uso no
                                            autorizado de su cuenta, así como el
                                            ingreso por terceros no autorizados
                                            a la misma.
                                        </li>
                                        <li className="ml-10">
                                            En caso el usuario desee adquirir
                                            algún producto y/o servicio ofrecido
                                            mediante el portal, es su obligación
                                            personal y exclusiva revisar las
                                            características técnicas del
                                            producto.
                                        </li>
                                        <li className="ml-10">
                                            El usuario reconoce que los
                                            productos y/o servicios exhibidos y
                                            ofrecidos mediante el portal no son
                                            suministrados, ofrecidos y
                                            comercializados directamente por
                                            BluStay PLATAFORMA WEB, reconociendo
                                            que BluStay PLATAFORMA WEB funciona
                                            como una herramienta de promoción de
                                            los productos y un nexo entre los
                                            clientes y aliados finales.
                                        </li>
                                        <li className="ml-10">
                                            El usuario podrá optar por la forma
                                            de pago que considere conveniente,
                                            de acuerdo a las modalidades
                                            ofrecidas por BluStay PLATAFORMA
                                            WEB.
                                        </li>
                                        <li className="ml-10">
                                            El usuario reconoce que las
                                            transacciones que realiza mediante
                                            el portal web no significan el
                                            establecimiento de una relación de
                                            dependencia entre el usuario y
                                            BluStay PLATAFORMA WEB.
                                        </li>
                                        <li className="ml-10">
                                            <b>Aliados</b>: Son los usuarios que
                                            desean promover la venta y/o
                                            alquiler a través del portal sus
                                            productos/servicios.
                                        </li>
                                    </ul>
                                </li>
                                <li className="ml-10">
                                    Los usuarios que se registren bajo este
                                    formato se encuentran sujetos a las
                                    siguientes{" "}
                                    <b>
                                        consideraciones, obligaciones y derechos
                                    </b>
                                    :
                                    <ul className="list-decimal">
                                        <li className="ml-10">
                                            El usuario deberá crearse una cuenta
                                            en el portal BluStay.
                                        </li>
                                        <li className="ml-10">
                                            El usuario deberá realizar el
                                            proceso de registro y brindar
                                            información veraz y fidedigna, la
                                            cual automáticamente tendrá
                                            condición de declaración jurada.
                                        </li>
                                        <li className="ml-10">
                                            El usuario será íntegramente
                                            responsable del manejo y control de
                                            su clave y usuario.
                                        </li>
                                        <li className="ml-10">
                                            El usuario será responsable por
                                            todas las operaciones efectuadas en
                                            su cuenta, pues el acceso a la misma
                                            está restringido al ingreso y uso de
                                            su clave de seguridad, de
                                            conocimiento exclusivo del Usuario.
                                        </li>
                                        <li className="ml-10">
                                            El usuario se compromete a notificar
                                            a BluStay PLATAFORMA WEB en forma
                                            inmediata y por medio idóneo y
                                            fehaciente, cualquier uso no
                                            autorizado de su cuenta, así como el
                                            ingreso por terceros no autorizados
                                            a la misma.
                                        </li>
                                        <li className="ml-10">
                                            Al utilizar el portal, los usuarios
                                            reconocen y aceptan que BluStay
                                            PLATAFORMA WEB será responsable
                                            únicamente por cualquier problema
                                            que se genere con los servicios
                                            proveídos por BluStay PLATAFORMA
                                            WEB.
                                        </li>
                                        <li className="ml-10">
                                            Los usuarios aceptan y reconocen
                                            que, BluStay PLATAFORMA WEB no será
                                            responsable por lo ocurrido durante
                                            la prestación de los servicios
                                            contratados.
                                        </li>
                                        <li className="ml-10">
                                            El usuario acepta que BluStay
                                            PLATAFORMA WEB perciba un porcentaje
                                            de comisión por la conexión
                                            realizada con los Clientes finales.
                                            La indicada comisión y su forma de
                                            pago será establecida de manera
                                            específica a cada usuario.
                                        </li>
                                        <li className="ml-10">
                                            El usuario declara que tiene la
                                            condición de comercializador,
                                            representante, productor o
                                            propietario de los productos
                                            ofrecidos a través del portal.
                                        </li>
                                        <li className="ml-10">
                                            El usuario manifiesta, en calidad de
                                            declaración jurada, que los
                                            productos y/o servicios ofrecidos
                                            han sido adquiridos o fabricados de
                                            manera legal.
                                        </li>
                                        <li className="ml-10">
                                            El usuario se encuentra obligado a
                                            ofrecer productos y/o servicios que
                                            se encuentren en perfecto estado o
                                            que cumplan con los estándares de
                                            calidad necesarios, asumiendo
                                            integra responsabilidad por publicar
                                            y/o ofrecer servicios no fidedignos.
                                        </li>
                                        <li className="ml-10">
                                            El usuario será íntegramente
                                            responsable por el pago de los
                                            tributos que se generen por la
                                            comercialización de sus productos a
                                            través del portal, encontrándose en
                                            la obligación de ofrecer boletas o
                                            facturas a los clientes.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                5. OBLIGACIONES Y DERECHOS DE BluStay PLATAFORMA
                                WEB:
                            </p>
                            <ul className="list-disc">
                                <li className="ml-10">
                                    BluStay PLATAFORMA WEB es integra y
                                    únicamente responsable por los servicios
                                    brindados a través de su portal.
                                </li>
                                <li className="ml-10">
                                    BluStay PLATAFORMA WEB se encuentra
                                    facultada a establecer modalidades y formas
                                    de pago de acuerdo a las necesidades del
                                    portal y los clientes.
                                </li>
                                <li className="ml-10">
                                    BluStay PLATAFORMA WEB será responsable de
                                    la gestión de los pagos y recaudación de
                                    estos.
                                </li>
                                <li className="ml-10">
                                    BluStay PLATAFORMA WEB es únicamente un nexo
                                    conector entre los clientes y consumidores
                                    finales, no siendo un comercializador
                                    directo de productos y/o servicios.
                                </li>
                                <li className="ml-10">
                                    BluStay PLATAFORMA WEB no es responsable de
                                    la calidad, integridad y características de
                                    los productos ofrecidos a través de su
                                    portal.
                                </li>
                                <li className="ml-10">
                                    Los Términos no generan ninguna sociedad,
                                    franquicia o relación laboral entre los
                                    Clientes, Aliados y usuarios finales con
                                    BluStay PLATAFORMA WEB.
                                </li>
                                <li className="ml-10">
                                    BluStay PLATAFORMA WEB puede modificar o
                                    variar los presentes términos de acuerdo a
                                    su solo criterio en cualquier momento y sin
                                    previo aviso. Los cambios serán obligatorios
                                    automáticamente en la fecha de publicación
                                    de la nueva versión que se publicará en la
                                    página de BluStay.
                                </li>
                                <li className="ml-10">
                                    BluStay soportará sus operaciones con
                                    tarjeta de crédito, debido y/o operaciones
                                    pre pagadas en la plataforma Mercado Pago.
                                    Por tanto, BluStay en ningún supuesto
                                    manejará información de tarjetas de crédito
                                    y/o débito de los usuarios, cuentas
                                    bancarias y/o cualquier tipo de información
                                    financiera personal, no siendo responsable
                                    en ningún supuesto de los procesos de pago
                                    que realicen los usuarios una vez aceptado
                                    las políticas de Mercado Pago y accedido al
                                    link de pago de la plataforma; cualquier
                                    reclamo y/o solicitud respecto a temas
                                    relacionados a este punto, el usuario se
                                    compromete a realizarla mediante el proceso
                                    interno que reclamo que la plataforma
                                    indique.
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                6. PROCEDIMIENTO DE ATENCIÓN AL CLIENTE Y
                                RECLAMOS
                            </p>
                            <ul className="list-disc">
                                <li className="ml-10">
                                    Para la atención de cualquier reclamo en
                                    relación a los servicios brindados por
                                    BluStay, podrá realizarlo mediante los
                                    siguientes canales de atención:
                                    <ul className="ml-10 list-decimal">
                                        <li>Correo: info@blustay.io</li>
                                        <li>
                                            Libro de Reclamación: www.blustay.io
                                        </li>
                                        <li>
                                            BluStay procurará sus mejores
                                            esfuerzos para que el usuario pueda
                                            resolver los inconvenientes de
                                            manera amistosa y directa.
                                        </li>
                                        <li>
                                            El procedimiento de reclamo constará
                                            de las siguientes etapas:
                                            <ul className="ml-10 list-disc">
                                                <li>
                                                    Notificación al correo
                                                    suministrado.
                                                </li>
                                                <li>
                                                    BluStay contará con el plazo
                                                    de 15 días hábiles para
                                                    brindar una respuesta al
                                                    reclamo formulado.
                                                </li>
                                                <li>
                                                    En caso el usuario no se
                                                    encontrará conforme con lo
                                                    resuelto por BluStay podrá
                                                    solicitar la reevaluación
                                                    del reclamo, el cual
                                                    nuevamente será evaluado y
                                                    atendido por BluStay en un
                                                    plazo máximo de 15 días
                                                    hábiles.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="ml-10">
                                    En el caso que el usuario requiera
                                    interponer un reclamo contra algún producto
                                    y/o servicio brindado por algún tercero, el
                                    usuario se compromete a iniciar el
                                    procedimiento interno establecido por cada
                                    aliado.
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">7. SANCIONES:</p>
                            <ul className="list-disc">
                                <li className="ml-10">
                                    BluStay PLATAFORMA WEB podrá retirar de su
                                    red de clientes o aliados que incurran en
                                    las siguientes causales:
                                    <ul className="ml-10 list-decimal ">
                                        <li>
                                            Usuarios que realicen cualquier tipo
                                            de publicidad engañosa.
                                        </li>
                                        <li>
                                            Usuarios que incurran en falta de
                                            idoneidad del producto y/o servicio.
                                        </li>
                                        <li>
                                            Usuarios que realicen
                                            deliberadamente actos de mala fe.
                                        </li>
                                        <li>
                                            Usuarios que incurran en
                                            negligencia.
                                        </li>
                                        <li>
                                            Usuarios que de manera maliciosa
                                            intente evitar sus obligaciones de
                                            BluStay PLATAFORMA WEB .
                                        </li>
                                        <li>
                                            Usuarios que no cumplan con las
                                            obligaciones de los presentes
                                            términos.
                                        </li>
                                    </ul>
                                </li>
                                <li className="ml-10">
                                    Para cualquier otro supuesto no contemplado
                                    en este punto será de aplicación lo
                                    pertinente establecido en los Términos y
                                    Condiciones Generales de Uso de la web
                                    BluStay.
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                8. FORMAS DE PAGO:
                            </p>
                            <ul className="list-disc">
                                <li className="ml-10">
                                    Los usuarios entienden y aceptan que por la
                                    prestación de los diversos servicios que
                                    ofrece BluStay a través de sus portales
                                    podrán encontrarse sujetos a pagos y
                                    comisiones.
                                </li>
                                <li className="ml-10">
                                    La cancelación de los servicios contratados
                                    podrá efectuarse bajo las siguientes
                                    modalidades de pago:
                                    <ul className="ml-10 list-decimal">
                                        <li>
                                            Transferencia y/o Depósito en cuenta
                                            bancaria.
                                        </li>
                                        <li>
                                            Pago con tarjetas de crédito, débito
                                            o pre pagados. En este caso, BluStay
                                            comunica a sus usuarios que todos
                                            los procesos de pago por este medio
                                            se soportaran en la pasarela de pago
                                            ON LINE Mercado Pago.
                                        </li>
                                    </ul>
                                </li>
                                <li className="ml-10">
                                    BluStay en ningún supuesto manejará
                                    información de tarjetas de crédito y/o
                                    débito de los usuarios, cuentas bancarias
                                    y/o cualquier tipo de información financiera
                                    personal, no siendo responsable en ningún
                                    supuesto de los procesos de pago que
                                    realicen los usuarios una vez aceptado las
                                    políticas de Mercado Pago y accedido al link
                                    de pago de la plataforma; cualquier reclamo
                                    y/o solicitud respecto a temas relacionados
                                    a este punto, el usuario se compromete a
                                    realizarla mediante el proceso interno que
                                    reclamo que la plataforma indique.
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PoliticasDeUsoYPriv;
