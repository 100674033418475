import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

interface Props {
    modalVisible: boolean;
    setModalVisible: (isOpen: boolean) => void;
}

interface RegisterForm {
    nombre?: string;
    apellido?: string;
    correo?: string;
    celular?: string;
    tipo?: string;
    etiqueta?: string;
}

type errorObject = { [key: string]: string };

const INITIAL_STATE = {
    nombre: "",
    apellido: "",
    correo: "",
    celular: "",
    tipo: "Simulador",
    etiqueta: "Web",
};

const ModalCreatePropietarioCRM = ({
    modalVisible,
    setModalVisible,
}: Props) => {
    const [input, setInput] = useState<RegisterForm>(INITIAL_STATE);
    const [errors, setErrors] = useState<RegisterForm>({});
    const [crear, setCrear] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const validateInput = (form: RegisterForm) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        let errores: errorObject = {};

        if (!form.nombre) {
            errores.nombre = "Debe ingresar un nombre válido";
        } else if (!form.apellido) {
            errores.apellido = "Debe ingresar un apellido válido";
        } else if (!regExpEmail.test(form.correo || "")) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.celular) {
            errores.celular = "Debe ingresar un celular";
        }

        return errores;
    };

    const handleSubmit = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            setCrear(true);
            try {
                const data = await fetchAxiosToken({
                    url: `/crm_soporte`,
                    method: "post",
                    body: input,
                });
                console.log(data);
                setCrear(false);
                setModalVisible(false);

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    position: "top-end",
                    icon: "success",
                    title: "El propietario ha sido creado correctamente!",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } catch (error: any) {
                setCrear(false);

                console.log(error.response);
            }
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto md:p-10">
                <h2 className="text-base font-medium">
                    Crear nuevo propietario al CRM
                </h2>
                <div className="w-full">
                    <label className="text-sm font-medium">Nombre</label>
                    <input
                        value={input.nombre}
                        onChange={handleInputChange}
                        name="nombre"
                        type={"text"}
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        placeholder="nombre"
                    />
                    {errors.nombre && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.nombre}
                        </p>
                    )}
                </div>
                <div className="w-full">
                    <label className="text-sm font-medium">Apellido</label>
                    <input
                        value={input.apellido}
                        onChange={handleInputChange}
                        name="apellido"
                        type={"text"}
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        placeholder="apellido"
                    />
                    {errors.apellido && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.apellido}
                        </p>
                    )}
                </div>
                <div className="w-full">
                    <label className="text-sm font-medium">
                        Correo electrónico
                    </label>
                    <input
                        value={input.correo}
                        onChange={handleInputChange}
                        name="correo"
                        type={"email"}
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        placeholder="ejemplo@gmail.com"
                    />
                    {errors.correo && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.correo}
                        </p>
                    )}
                </div>
                <div className="w-full">
                    <label className="text-sm font-medium">Celular</label>
                    <input
                        value={input.celular}
                        onChange={handleInputChange}
                        name="celular"
                        type={"text"}
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        placeholder="Celular"
                    />
                    {errors.celular && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.celular}
                        </p>
                    )}
                </div>
                <div className="w-full">
                    <label className="text-sm font-medium">Tipo</label>

                    <select
                        name="tipo_estadia"
                        onChange={(e) =>
                            setInput({
                                ...input,
                                tipo: e.target.value,
                            })
                        }
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        value={input.tipo}>
                        <option value="Simulador">Simulador</option>
                        <option value="Home">Home</option>
                        <option value="Descarga Pdf">Descarga Pdf</option>

                        <option value="Ver demo">Ver demo</option>
                    </select>
                </div>
                <div className="w-full">
                    <label className="text-sm font-medium">Etiqueta</label>
                    <select
                        name="tipo_estadia"
                        onChange={(e) =>
                            setInput({
                                ...input,
                                etiqueta: e.target.value,
                            })
                        }
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        value={input.etiqueta}>
                        <option value="Web">Web</option>
                        <option value="Email">Email</option>
                        <option value="Whatsapp">Whatsapp</option>

                        <option value="Facebook">Facebook</option>
                        <option value="Linkedin">Linkedin</option>
                        <option value="Instagram">Instagram</option>
                    </select>
                </div>
                <div className="flex flex-col gap-4 mt-8">
                    <button
                        disabled={crear}
                        onClick={() => handleSubmit()}
                        className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 disabled:from-slate-300 disabled:to-slate-100 disabled:cursor-not-allowed">
                        {crear ? "Creando.." : "Crear"}
                    </button>
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};

export default ModalCreatePropietarioCRM;
