import React, { useState } from "react";
import { useAppSelector } from "../../hooks/hooks";

import { resena } from "../../designs/calendario-reserva/typeCasa";
import { ModalReviews } from "../modalEdit/ModalReviews";
import { Navigation, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import StarsRating from "../../pages/dashboardPropietarios/reservas/StarsRating";

interface Props {
    toggleButton: boolean;
}

const Reviews = ({ toggleButton }: Props) => {
    const { resenas } = useAppSelector((state) => state.landing_house.house);
    const [modalVisibleResenas, setModalVisibleResenas] = useState(false);

    const fecha = (date: string) => {
        const formatoFecha = new Date(date);

        return formatoFecha.toLocaleDateString("es-ES", {
            year: "numeric",
            month: "long",
        });
    };
    const avatar = (resena: resena) => {
        if (resena.avatar) {
            return resena.avatar;
        } else {
            return resena.pedido?.Cliente.avatar;
        }
    };

    const handleModalResena = () => {
        setModalVisibleResenas(true);
    };

    return (
        <div
            className={`${
                resenas.length ? "flex flex-col md:p-4 px-4 md:px-0" : "hidden"
            } mt-0 2xl:mt-10  `}>
            {modalVisibleResenas && (
                <ModalReviews
                    modalVisibleResenas={modalVisibleResenas}
                    setModalVisibleResenas={setModalVisibleResenas}
                />
            )}
            <div className={"flex justify-center px-5 md:mb-4"}>
                <h3
                    className={`2xl:text-3xl lg:text-2xl text-lg font-bold  w-fit text-[#1c4cbb]`}>
                    Mira lo que dicen sobre nosotros
                </h3>
            </div>

            <div className="w-full mx-auto mt-2 lg:mt-5 2xl:mt-10 lg:w-3/4">
                <Swiper
                    className="flex items-center justify-center "
                    modules={[Navigation, Autoplay, EffectFade]}
                    slidesPerView={1}
                    spaceBetween={10}
                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 1,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 1,
                        },
                    }}
                    loop
                    pagination={{
                        dynamicBullets: true,
                    }}
                    navigation>
                    {resenas?.map((review, index) => (
                        <SwiperSlide
                            key={index}
                            className="flex items-center justify-center my-auto ">
                            {" "}
                            <div className="flex flex-col items-center justify-between px-8 md:py-4 py-2 mx-5 mb-4 bg-white rounded-lg shadow-lg  border-2 border-[#1c4cbb]">
                                <div className="flex items-center justify-center gap-8 mt-3 2xl:mt-5">
                                    <div className="flex w-auto ">
                                        <img
                                            className="object-cover w-10 h-10 rounded-full 2xl:w-14 2xl:h-14 md:w-12 md:h-12"
                                            src={
                                                `${process.env.REACT_APP_PROXY}/usuarios/getImage/` +
                                                avatar(review)
                                            }
                                            alt="review"
                                        />
                                    </div>
                                    <div className="flex flex-col items-start justify-center">
                                        <p className="text-base font-bold text-gray-800 md:text-lg 2xl:text-xl">
                                            {review.pedido !== null
                                                ? review.pedido?.Cliente.nombre
                                                : review.cliente_resena}
                                        </p>

                                        <p className="text-sm font-medium text-gray-800 md:text-base 2xl:text-lg">
                                            {fecha(
                                                review.pedido?.fecha_inicio ||
                                                    review.fecha_registro
                                            )}
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <p className="mt-5 text-justify text-gray-500 ">
                                        {review.resena.length > 97
                                            ? review.resena.slice(0, 97) + "..."
                                            : review.resena}
                                    </p>
                                </div>

                                <div className="mb-4 2xl:mb-5">
                                    {
                                        <StarsRating
                                            starsQuantity={5}
                                            value={Number(review.puntuacion)}
                                        />
                                    }
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <div
                className="flex justify-center w-full mx-auto mb-4 cursor-pointer lg:justify-end lg:w-9/12 "
                onClick={handleModalResena}>
                <p className="text-[#1c4cbb] text-base lg:text-lg ">
                    Ver todos los testimonios
                </p>
                <svg
                    className="h-8 w-8 text-[#1c4cbb]"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    {" "}
                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                    <line x1="17" y1="7" x2="7" y2="17" />{" "}
                    <polyline points="8 7 17 7 17 16" />
                </svg>
            </div>
        </div>
    );
};

export default Reviews;
