import { Footer } from "../../components/Footer";
import Content from "./Content";
import Navbar from "./Navbar";

const Impact = () => {
    return (
        <div>
            <Navbar />
            <Content />
            <Footer />
        </div>
    );
};

export default Impact;
