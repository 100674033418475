import { useState, useEffect } from "react";
import amigos_felices from "../../img/landing_comprimidas/amigos-felices.webp";
import StarsRating from "../../pages/dashboardPropietarios/reservas/StarsRating";
import axios from "axios";
import { ModalReviews } from "../modalEdit/ModalReviews";
import { useAppSelector } from "../../hooks/hooks";
import { resena } from "../calendario-reserva/typeCasa";
export interface resenas {
    id: number;
    id_pedidos: number;
    puntuacion: string;
    resena: string;
    fecha_registro: string;
    habilitado: string;
    pedido: Pedido | null;
    avatar: string;
    cliente_resena: string;
    avatar_resena: string;
}

export interface Pedido {
    id: number;
    fecha_inicio: string;
    fecha_final: string;
    Cliente: Cliente;
}

export interface Cliente {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

const Reviews = () => {
    const [modalVisibleResenas, setModalVisibleResenas] = useState(false);
    const { resenas } = useAppSelector((state) => state.landing_house.house);
    const fecha = (date: string) => {
        const formatoFecha = new Date(date);

        return formatoFecha.toLocaleDateString("es-ES", {
            year: "numeric",
            month: "long",
        });
    };
    const avatar = (resena: resena) => {
        if (resena.avatar) {
            return resena.avatar;
        } else {
            return resena.pedido?.Cliente.avatar;
        }
    };

    // const [resenas, setResenas] = useState<resenas[]>([]);
    // const getComforts = async () => {
    //     try {
    //         const { data } = await axios.get(
    //             `${process.env.REACT_APP_PROXY}/resenas/getFirstReviews`
    //         );
    //         console.log("reseñas data", data);
    //         setResenas(data);
    //     } catch (error) {
    //         console.log("error reseñnas", error);
    //     }
    // };

    // useEffect(() => {
    //     getComforts();
    // }, []);

    const handleModalResena = () => {
        setModalVisibleResenas(true);
    };

    return (
        <div className="bg-[#f2f5ff] mt-5 2xl:mt-10">
            {modalVisibleResenas && (
                <ModalReviews
                    modalVisibleResenas={modalVisibleResenas}
                    setModalVisibleResenas={setModalVisibleResenas}
                />
            )}
            {resenas.length ? (
                <div className="flex flex-col w-11/12 mx-auto">
                    <div className="flex flex-col items-center justify-center py-4 2xl:py-5 md:flex-row md:justify-between">
                        <div className=" py-3 text-[#029BFC] text-lg md:text-xl lg:text-2xl 2xl:text-3xl font-bold">
                            <p>Mira lo que dicen sobre nosotros</p>
                        </div>

                        <div
                            className="hidden mt-10 cursor-pointer md:flex"
                            onClick={handleModalResena}>
                            <p className="text-[#1c4cbb] text-xs lg:text-base ">
                                Ver todos los testimonios
                            </p>
                            <svg
                                className="h-8 w-8 text-[#1c4cbb]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <line x1="17" y1="7" x2="7" y2="17" />{" "}
                                <polyline points="8 7 17 7 17 16" />
                            </svg>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row ">
                        <div className="w-2/3 mx-auto mb-12 lg:w-1/3">
                            <img
                                src={amigos_felices}
                                alt="No se encontro imagen"
                                className="rounded-lg object-cover w-full lg:h-[30rem] h-[20rem] "
                            />
                        </div>

                        <div className="grid w-full grid-cols-1 gap-3 mb-5 md:mb-12 lg:w-2/3 lg:grid-cols-2">
                            {resenas?.map((review, index) => (
                                <div
                                    className={`bg-white h-auto mx-7 rounded-lg p-3 px-7 ${
                                        index > 1 && " hidden lg:flex"
                                    }`}
                                    key={review.id}>
                                    <div className="flex flex-col gap-3">
                                        <div>
                                            {
                                                <StarsRating
                                                    starsQuantity={5}
                                                    value={Number(
                                                        review.puntuacion
                                                    )}
                                                />
                                            }
                                        </div>

                                        <p className="mt-2 text-sm text-justify text-gray-600 2xl:mt-3 2xl:text-base ">
                                            {review.resena.length > 97
                                                ? review.resena.slice(0, 97) +
                                                  "..."
                                                : review.resena}
                                        </p>

                                        <div className="flex mt-2 2xl:mt-3">
                                            <img
                                                className="object-cover mr-6 rounded-full 2xl:w-12 2xl:h-12 md:w-10 md:h-10 h-9 w-9"
                                                src={
                                                    `${process.env.REACT_APP_PROXY}/usuarios/getImage/` +
                                                    avatar(review)
                                                }
                                                alt="review"
                                            />
                                            <div>
                                                <div className="text-sm font-bold text-gray-700 md:text-base 2xl:text-lg ">
                                                    {review.pedido !== null
                                                        ? review.pedido?.Cliente
                                                              .nombre
                                                        : review.cliente_resena}
                                                </div>
                                                <p className="text-xs text-gray-500 md:text-sm 2xl:text-base">
                                                    {fecha(
                                                        review.pedido
                                                            ?.fecha_inicio ||
                                                            review.fecha_registro
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div
                            className="flex mx-auto mb-4 cursor-pointer md:hidden"
                            onClick={handleModalResena}>
                            <p className="text-[#1c4cbb] text-sm md:text-base lg:text-lg ">
                                Ver todos los testimonios
                            </p>
                            <svg
                                className="md:h-8 md:w-8 w-7 h-7 text-[#1c4cbb]"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <line x1="17" y1="7" x2="7" y2="17" />{" "}
                                <polyline points="8 7 17 7 17 16" />
                            </svg>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Reviews;
