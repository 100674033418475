import imagen4 from "../../img/bg-de-mision.webp";
// import laptop from "../../img/bg-de-mision.webp";
const Mision = () => {
    return (
        <div>
            <div className="z-30 flex flex-col justify-center h-auto">
                <div className="relative w-full h-[680px] sm:h-[750px] md:h-[800px] lg:h-[500px] xl:h-[550px] 2xl:h-[600px] z-30">
                    <img
                        className="object-cover w-full h-full"
                        src={imagen4}
                        alt="Imagen de fondo"
                    />
                    {/* <div className="absolute top-0 left-0 z-30 w-full h-full"></div> */}
                    <div className="absolute top-0 left-0 right-0 z-40 flex flex-col items-center justify-between w-full h-full py-10 mx-auto sm:gap-5 md:gap-10 lg:flex-row lg:justify-center lg:py-10 xl:py-20">
                        <div className="flex flex-col items-center justify-center w-10/12 max-w-6xl">
                            <div className="text-xl font-bold text-center text-white sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-5xl ">
                                <p>
                                    Nuestra misión es ayudar a nuestros clientes
                                    a
                                </p>
                                <p className="text-[#C0D345]">
                                    gestionar sus propiedades
                                </p>
                            </div>

                            <div className="mt-10 text-sm text-center text-white sm:text-base md:text-lg lg:text-lg xl:text-xl ">
                                Ofreciendo a los propietarios una única
                                plataforma donde puedan controlar la gestión de
                                clientes, reservas, cobranzas y comunicación con
                                sus prospecto y clientes.
                            </div>

                            <div className="mt-10 text-sm text-center text-white sm:text-base md:text-lg lg:text-lg xl:text-xl ">
                                Nuestra misión es ser una herramienta que les
                                genere mayores retornos y mayor eficiencia en su
                                operación comercial.
                            </div>
                        </div>
                        {/* <div className="w-full h-full mt-5 lg:w-1/2 lg:mt-10">
                            <img
                                src={laptop}
                                alt="No se encontro la imagen"
                                className="w-full h-[300px] sm:h-[350px] md:h-[400px] lg:[430px] 2xl:h-[450px] object-contain "
                            />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mision;
