import React, { useState, useEffect } from "react";
import Spinner from "../../../components/helpers/Spinner";
import SearchBar from "./SearchBar";
import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import { NavbarAdministrativa } from "../NavbarAdministrativa";
import { NavbarAdministrativaMobile } from "../NavbarAdministrativaMobile";
import ModalCreatename from "./ModalCreateComfort";
import { Paginado } from "../../../components/Paginado";
import ModalEditselectedFile from "./ModalEditComfort";
import { useNavigate } from "react-router-dom";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
interface comfort {
    id: number;
    titulo: string;
    contenido: string;
    banner: string;
    fecha_publicado: string;
    fecha_registro: string;
    habilitado: string;
}

interface getComfort {
    pagina: number;
    habilitado: boolean;
}

export const Blogs = () => {
    const { headers } = useHeaders();
    const [comforts, setComforts] = useState<comfort[]>([]);
    const [comfortsTotal, setComfortsTotal] = useState<number>(0);
    const [enabled, setenabled] = useState<boolean>(true);
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [create, setcreate] = useState<boolean>(false);
    const [modalEnabled, setModalEnabled] = useState<boolean>(false);
    const [comfortEdit, setComfortEdit] = useState<comfort>();
    const COMFORTS_PER_PAGE = 20;
    const navigate = useNavigate();

    const getComforts = async ({ pagina, habilitado }: getComfort) => {
        try {
            const data = await fetchAxiosToken({
                url: `/blog/getAll/${pagina}/${
                    habilitado ? "Si" : "No"
                }/asc/0?query=`,

                method: "get",
            });

            console.log(data);
            setComforts(data.rows);
            setComfortsTotal(data.count);
        } catch (error) {
            console.log(error);
        }
    };

    const handleEnable = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará el Blog a habilitados!",
            icon: "warning",
            showCancelButton: true,

            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // const { data } = await axios.put(
                    //     `${process.env.REACT_APP_PROXY}/blog/activateBlog/${id}/Si`,
                    //     { headers }
                    // );
                    await fetchAxiosToken({
                        url: `/blog/activateBlog/${id}/Si`,

                        method: "put",
                    });
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Blog restaurada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getComforts({
                        pagina: pageNumber,
                        habilitado: enabled,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleDelete = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el blog a deshabilitados!",
            icon: "warning",
            showCancelButton: true,

            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // const { data } = await axios.put(
                    //     `${process.env.REACT_APP_PROXY}/blog/activateBlog/${id}/No`,
                    //     { headers }
                    // );
                    await fetchAxiosToken({
                        url: `/blog/activateBlog/${id}/No`,

                        method: "put",
                    });

                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Blog elimidado!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getComforts({
                        pagina: pageNumber,
                        habilitado: enabled,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleEdit = async (id: number) => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/comodidad/getOne/${id}`,
                { headers }
            );

            setComfortEdit(data);
        } catch (error) {
            console.log(error);
        }
    };

    const pagination = (pageNumber: number) => {
        setpageNumber(pageNumber);
        getComforts({ pagina: pageNumber, habilitado: enabled });
    };

    useEffect(() => {
        getComforts({ pagina: pageNumber, habilitado: enabled });
    }, [enabled, create, comfortEdit]);

    return (
        <div>
            <div className="absolute z-[999] flex items-center justify-center w-full text-center">
                {create && (
                    <ModalCreatename create={create} setcreate={setcreate} />
                )}
            </div>
            <NavbarAdministrativa titulo="Blogs">
                <div className="w-full">
                    <div className="flex items-center justify-end w-full px-6 mx-auto "></div>
                    <div className="flex items-center justify-end gap-2 pr-6 mx-auto mt-6 sm:px-6">
                        <div
                            onClick={() =>
                                navigate("/dashboard-admin/blogs-create")
                            }
                            className=" md:ml-10">
                            <button className="flex items-center px-3 py-2 mx-auto text-lg border rounded-lg text-[#029BFC] border-[#029BFC] group hover:bg-[#029BFC] hover:text-white">
                                <svg
                                    className="w-5 h-5 text-[#029BFC] group-hover:text-white"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                                <span className="items-center hidden text-base sm:block ">
                                    Nuevo blog{" "}
                                </span>
                            </button>
                        </div>
                        <div
                            onMouseOut={() => setModalEnabled(false)}
                            onMouseOver={() => setModalEnabled(true)}
                            className="relative">
                            <button className="w-8 h-8 xs:w-[51px] xs:h-[51px] flex items-center justify-center px-1 py-1  text-[#029BFC] border-[#029BFC] rounded-lg group hover:bg-[#029BFC] hover:text-white">
                                <svg
                                    className="w-6 h-6 text-[#029BFC] xs:w-8 xs:h-8 group-hover:text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                    />
                                </svg>
                            </button>
                            <div
                                className={
                                    modalEnabled
                                        ? "absolute top-8 xs:top-[50px]  w-36 z-10  pt-2 right-0 "
                                        : "hidden"
                                }>
                                <div
                                    className={
                                        modalEnabled
                                            ? "block bg-white  top-16 md723:top-20 py-2 rounded-lg shadow-md"
                                            : "hidden"
                                    }>
                                    <div
                                        onClick={() => (
                                            setModalEnabled(false),
                                            setenabled(!enabled)
                                        )}
                                        className=" w-32 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start pl-4 ">
                                        {!enabled
                                            ? `Habilitados`
                                            : `Deshabilitadas`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="flex justify-center items-center lg:py-[10px] ">
                        <div className="w-full px-11">
                            <div className="flex flex-wrap -mx-4">
                                <div className="w-full ">
                                    <div className="flex flex-col">
                                        {true ? (
                                            <div className="w-full ">
                                                <table className="w-full overflow-x-auto mt-14">
                                                    <thead className="text-left">
                                                        <tr>
                                                            <th className="w-full min-w-[10rem] md:w-4/12 ">
                                                                Nombre
                                                            </th>
                                                            <th className="w-1/12  min-w-[6rem] hidden xs:table-cell pr-10">
                                                                Fecha publicado
                                                            </th>
                                                            <th className="w-1/12 text-right min-w-[8rem] md:table-cell pr-10">
                                                                Acciones
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {comforts?.map(
                                                            (comforts) => (
                                                                <tr
                                                                    key={
                                                                        comforts.id
                                                                    }
                                                                    className="w-full border-t cursor-pointer hover:bg-gray-200 "
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/dashboard-admin/blogs-edit/${comforts.id}`
                                                                        )
                                                                    }>
                                                                    <td>
                                                                        <div className="flex items-center py-3">
                                                                            <img
                                                                                src={
                                                                                    `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/blog/getBanner/` +
                                                                                    comforts?.banner
                                                                                }
                                                                                alt="icono"
                                                                                className="object-cover w-10 h-10 rounded"
                                                                            />
                                                                            <h1 className="mx-3 text-[#3C3C3C] text-base">
                                                                                {
                                                                                    comforts?.titulo
                                                                                }
                                                                            </h1>
                                                                        </div>
                                                                    </td>

                                                                    <td className="hidden xs:table-cell">
                                                                        <ShowDateToUser
                                                                            date={comforts.fecha_publicado?.slice(
                                                                                0,
                                                                                10
                                                                            )}
                                                                        />
                                                                    </td>

                                                                    <td
                                                                        className={
                                                                            enabled
                                                                                ? " md:flex justify-end py-5 px-2"
                                                                                : " md:flex  justify-center pl-7 md:justify-end py-5 md:pr-10 "
                                                                        }>
                                                                        {enabled ? (
                                                                            <div className="flex">
                                                                                <button
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        navigate(
                                                                                            `/dashboard-admin/blogs-edit/${comforts.id}`
                                                                                        );
                                                                                    }}
                                                                                    title="Editar"
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:white">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />
                                                                                        <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                                        <line
                                                                                            x1="16"
                                                                                            y1="5"
                                                                                            x2="19"
                                                                                            y2="8"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                                <button
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:text-white"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        handleDelete(
                                                                                            comforts.id
                                                                                        );
                                                                                    }}
                                                                                    title="Eliminar">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="4"
                                                                                            y1="7"
                                                                                            x2="20"
                                                                                            y2="7"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="10"
                                                                                            y1="11"
                                                                                            x2="10"
                                                                                            y2="17"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="14"
                                                                                            y1="11"
                                                                                            x2="14"
                                                                                            y2="17"
                                                                                        />{" "}
                                                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <button
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        handleEnable(
                                                                                            comforts.id
                                                                                        );
                                                                                    }}
                                                                                    title="Habilitar"
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:white">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="2"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <polyline points="17 1 21 5 17 9" />{" "}
                                                                                        <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                        <polyline points="7 23 3 19 7 15" />{" "}
                                                                                        <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <Spinner />
                                        )}
                                    </div>

                                    <div className="container flex items-center justify-between p-1 mx-auto">
                                        <Paginado
                                            elementsPerPage={COMFORTS_PER_PAGE}
                                            cantTotalElements={comfortsTotal}
                                            pagination={pagination}
                                            actualPage={pageNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <NavbarAdministrativaMobile />
            </NavbarAdministrativa>
        </div>
    );
};
