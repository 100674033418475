import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

interface Props {
    modalVisible: boolean;
    setModalVisible: (isOpen: boolean) => void;
    setRefreshUsers: (prev: any) => void;
}

interface RegisterForm {
    nombre?: string;
    apellido?: string;
    correo?: string;
    password?: string;
}

type errorObject = { [key: string]: string };

const INITIAL_STATE = {
    nombre: "",
    apellido: "",
    correo: "",
    password: "",
};

const ModalCreateUser = ({
    modalVisible,
    setModalVisible,
    setRefreshUsers,
}: Props) => {
    const [input, setInput] = useState<RegisterForm>(INITIAL_STATE);
    const [errors, setErrors] = useState<RegisterForm>({});

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const validateInput = (form: RegisterForm) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        let errores: errorObject = {};

        if (!form.nombre) {
            errores.nombre = "Debe ingresar un nombre válido";
        } else if (!form.apellido) {
            errores.apellido = "Debe ingresar un apellido válido";
        } else if (!regExpEmail.test(form.correo || "")) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.password) {
            errores.password = "Debe ingresar una contraseña";
        }

        return errores;
    };

    const handleSubmit = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            try {
                const data = await fetchAxiosToken({
                    url: `/administrador`,
                    method: "post",
                    body: input,
                });
                setModalVisible(false);
                setRefreshUsers((prev: boolean) => !prev);
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    position: "top-end",
                    icon: "success",
                    title: "El usuario ha sido creado correctamente!",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } catch (error: any) {
                console.log(error.response);
            }
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto md:p-10">
                <h2 className="text-base font-medium">
                    Crear nuevo usuario administrador
                </h2>
                <div className="w-full">
                    <label className="text-sm font-medium">Nombre</label>
                    <input
                        value={input.nombre}
                        onChange={handleInputChange}
                        name="nombre"
                        type={"text"}
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        placeholder="nombre"
                    />
                    {errors.nombre && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.nombre}
                        </p>
                    )}
                </div>
                <div className="w-full">
                    <label className="text-sm font-medium">Apellido</label>
                    <input
                        value={input.apellido}
                        onChange={handleInputChange}
                        name="apellido"
                        type={"text"}
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        placeholder="apellido"
                    />
                    {errors.apellido && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.apellido}
                        </p>
                    )}
                </div>
                <div className="w-full">
                    <label className="text-sm font-medium">
                        Correo electrónico
                    </label>
                    <input
                        value={input.correo}
                        onChange={handleInputChange}
                        name="correo"
                        type={"email"}
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        placeholder="ejemplo@gmail.com"
                    />
                    {errors.correo && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.correo}
                        </p>
                    )}
                </div>
                <div className="w-full">
                    <label className="text-sm font-medium">Contraseña</label>
                    <input
                        value={input.password}
                        onChange={handleInputChange}
                        name="password"
                        type={"text"}
                        className="w-full p-3 mt-1 border rounded-lg shadow-md placeholder:italic"
                        placeholder="contraseña"
                    />
                    {errors.password && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.password}
                        </p>
                    )}
                </div>
                <div className="flex flex-col gap-4 mt-8">
                    <button
                        onClick={() => handleSubmit()}
                        className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                        Guardar cambios
                    </button>
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};

export default ModalCreateUser;
