import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import MainNavbar from "../../components/MainNavbar/MainNavbar";

import { Footer } from "../../components/Footer";
import axios from "axios";
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

interface Blog {
    id: number;
    titulo: string;
    banner: string;
    contenido: string;
    fecha_publicado: Date;
}

const DetailedBlog = () => {
    const { idBlog } = useParams();

    const [blog, setBlog] = useState<Blog>();
    const [previous, setPrevious] = useState<Blog>();
    const [next, setNext] = useState<Blog>();
    const navigate = useNavigate();

    const token = localStorage.getItem("token");

    useEffect(() => {
        if (idBlog) {
            getBlogDetails(idBlog);
        }
    }, [idBlog]);

    const getBlogDetails = async (id: string) => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/blog/getOne/${id}/`
        );

        setBlog(json.data);

        const jsonNext = await axios.get(
            `${process.env.REACT_APP_PROXY}/blog/getPreviousNext/${id}/`
        );
        let pre: Blog = jsonNext.data?.prevPost;
        let nex: Blog = jsonNext.data?.nextPost;

        setPrevious(pre);
        setNext(nex);
    };

    return (
        <div>
            <MainNavbar />
            {blog && (
                <div className="px-10 pt-32 bg-white ">
                    <div className="w-4/5 mx-auto">
                        <p className="text-[#053465] text-base mt-14">
                            {new Date(blog.fecha_publicado).getDate() < 10
                                ? `0${new Date(blog.fecha_publicado).getDate()}`
                                : new Date(blog.fecha_publicado).getDate()}{" "}
                            {new Date(blog.fecha_publicado).toLocaleDateString(
                                "es-ES",
                                {
                                    month: "long",
                                }
                            )}{" "}
                            {new Date(blog.fecha_publicado).getFullYear()}
                        </p>
                        <h2 className="text-[#053465] text-4xl font-bold my-5">
                            {blog?.titulo}
                        </h2>
                        <div className="mb-5">
                            <FacebookShareButton
                                className="mr-4"
                                url={`https://www.blustay.io/blog/${idBlog}`}
                            >
                                <FacebookIcon size={40} />
                            </FacebookShareButton>
                            <LinkedinShareButton
                                className="xs:mx-4 "
                                url={`https://www.blustay.io/blog/${idBlog}`}
                            >
                                <LinkedinIcon size={40} />
                            </LinkedinShareButton>
                            <PinterestShareButton
                                className="mx-4"
                                url={`https://www.blustay.io/blog/${idBlog}`}
                                media={
                                    `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/blog/getbanner/` +
                                    blog?.banner
                                }
                            >
                                <PinterestIcon size={40} />
                            </PinterestShareButton>
                            <TwitterShareButton
                                className=" xs:mx-4"
                                url={`https://www.blustay.io/blog/${idBlog}`}
                            >
                                <TwitterIcon size={40} />
                            </TwitterShareButton>{" "}
                            <WhatsappShareButton
                                className="mx-3 xs:mx-4"
                                url={`https://www.blustay.io/blog/${idBlog}`}
                            >
                                <WhatsappIcon size={40} />
                            </WhatsappShareButton>
                        </div>
                        <img
                            className="w-4/5 h-[30rem] object-cover mx-auto rounded-lg mt-10"
                            alt="banner de la entrada"
                            src={
                                `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/blog/getbanner/` +
                                blog?.banner
                            }
                        />
                    </div>

                    <br></br>
                    <br></br>
                    <br></br>

                    <div
                        className="w-[55%] mx-auto text-justify"
                        dangerouslySetInnerHTML={{ __html: blog?.contenido }}
                    />
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="grid w-10/12 mx-auto my-5 md:flex md:justify-around">
                        <div>
                            <h2 className="text-[#053465] font-bold text-3xl">
                                Compartir
                            </h2>
                        </div>

                        <div className="flex justify-center items-center">
                            <div className="rounded-lg">
                                <FacebookShareButton
                                    className="md:mr-4"
                                    url={`https://www.blustay.io/blog/${idBlog}`}
                                >
                                    <FacebookIcon size={40} />
                                </FacebookShareButton>
                            </div>
                            <div className="rounded-lg">
                                <LinkedinShareButton
                                    className="md:mx-4"
                                    url={`https://www.blustay.io/blog/${idBlog}`}
                                >
                                    <LinkedinIcon size={40} />
                                </LinkedinShareButton>
                            </div>
                            <div className="rounded-lg">
                                <PinterestShareButton
                                    className="md:mx-4"
                                    url={`https://www.blustay.io/blog/${idBlog}`}
                                    media={
                                        `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/blog/getbanner/` +
                                        blog?.banner
                                    }
                                >
                                    <PinterestIcon size={40} />
                                </PinterestShareButton>
                            </div>
                            <div className="rounded-lg">
                                <TwitterShareButton
                                    className="md:mx-4"
                                    url={`https://www.blustay.io/blog/${idBlog}`}
                                >
                                    <TwitterIcon size={40} />
                                </TwitterShareButton>
                            </div>
                            <WhatsappShareButton
                                className="mx-3 xs:mx-4"
                                url={`https://www.blustay.io/blog/${idBlog}`}
                            >
                                <WhatsappIcon size={40} />
                            </WhatsappShareButton>
                        </div>
                    </div>
                    <div className="bg-[#f7f7f7] border border-gray-300  border-solid w-10/12 mx-auto p-8 ">
                        <div className="flex justify-between">
                            <div className=" text-[#053465] text-xs relative">
                                <span
                                    onClick={() =>
                                        navigate(`/blog/${previous?.id}`)
                                    }
                                    className={
                                        previous
                                            ? " text-[#053465] text-2xl font-bold pr-2 top-[5px] right-[100%] absolute cursor-pointer"
                                            : "hidden"
                                    }
                                >
                                    {"<"}
                                </span>
                                {""}
                                <span
                                    onClick={() =>
                                        navigate(`/blog/${previous?.id}`)
                                    }
                                    className={
                                        previous
                                            ? "text-[#053465] cursor-pointer"
                                            : "hidden"
                                    }
                                >
                                    ENTRADA ANTERIOR
                                </span>
                            </div>
                            <div className="relative text-xs ">
                                <span
                                    onClick={() =>
                                        navigate(`/blog/${next?.id}`)
                                    }
                                    className={
                                        next
                                            ? "text-[#053465] cursor-pointer"
                                            : "hidden"
                                    }
                                >
                                    ENTRADA SIGUIENTE
                                </span>
                                <span
                                    onClick={() =>
                                        navigate(`/blog/${next?.id}`)
                                    }
                                    className={
                                        next
                                            ? " text-[#053465] text-2xl font-bold pl-2 top-[5px] absolute cursor-pointer"
                                            : "hidden"
                                    }
                                >
                                    {">"}
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-between ">
                            <p className="md:text-lg xl:text-xl font-serif text-[#053465] ">
                                <span
                                    onClick={() =>
                                        navigate(`/blog/${previous?.id}`)
                                    }
                                    className="cursor-pointer"
                                >
                                    {previous?.titulo}
                                </span>
                            </p>
                            <p className="md:text-lg xl:text-xl font-serif text-[#053465] ">
                                <span
                                    onClick={() =>
                                        navigate(`/blog/${next?.id}`)
                                    }
                                    className="cursor-pointer"
                                >
                                    {next?.titulo}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            )}

            <Footer />
        </div>
    );
};

export default DetailedBlog;
