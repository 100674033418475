import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const TITLES = [
    {
        id: 1,
        title: "Reservas",
        url: "reservas",

        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 99.54 100">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m49.75,73.36c-13.18,0-26.36,0-39.54,0-2.33,0-3.81-1.47-3.7-3.65.07-1.4.92-2.61,2.33-2.98.88-.23,1-.68,1.02-1.46.13-4.61.59-9.18,1.81-13.66,2.56-9.41,8.55-15.56,17.67-18.73,4.8-1.67,9.77-2.49,14.84-2.72,2.23-.1,2.67-.68,2.18-2.91-.13-.59-.6-.49-.95-.62-1.55-.58-2.41-1.8-2.36-3.42.04-1.46.94-2.68,2.33-3.1.36-.11.75-.15,1.12-.15,2.18-.01,4.36-.02,6.54,0,1.77.02,3.13,1.14,3.4,2.76.3,1.81-.65,3.41-2.46,3.91-.71.19-.83.53-.84,1.16-.01,2.3-.03,2.24,2.32,2.37,6.05.32,11.94,1.35,17.52,3.84,8.03,3.58,12.86,9.79,15.02,18.23,1.09,4.28,1.53,8.65,1.64,13.06.02.8.2,1.2,1.04,1.44,1.67.47,2.56,2.08,2.29,3.82-.25,1.63-1.67,2.79-3.48,2.79-9.72,0-19.44,0-29.16,0-3.53,0-7.06,0-10.59,0Zm6.41-29.71c-1.32-.17-2.31.99-2.74,2.1-.84,2.14.65,4.19,3.11,4.39,2.28.18,4.53.52,6.71,1.24,2.81.94,4.81,2.67,5.55,5.65.11.43.28.86.5,1.25.79,1.35,2.4,1.9,3.86,1.36,1.42-.53,2.34-2.12,1.99-3.59-1.04-4.33-3.3-7.73-7.41-9.73-3.36-1.64-6.95-2.23-11.58-2.66Z"
                        />
                        <path
                            fill="white"
                            d="m49.65,99.99c-15.95,0-31.9,0-47.84,0-.31,0-.62-.02-.93,0-.84.08-1.02-.26-.76-1.04.94-2.75,1.86-5.51,2.76-8.28.19-.57.51-.84,1.09-.93,3.27-.5,5.22-2.38,5.72-5.66.15-.98.78-.88,1.44-.88,11.21,0,22.42,0,33.63,0q1.62,0,1.62-1.64t1.69-1.69c1.38,0,2.77.03,4.15,0,.73-.02.94.28.96.98.06,2.36.08,2.36,2.45,2.36,10.9,0,21.79.01,32.69-.02,1.02,0,1.45.26,1.6,1.32.39,2.68,2.54,4.77,5.25,5.16.92.13,1.36.53,1.63,1.41.78,2.55,1.66,5.07,2.5,7.6.43,1.3.42,1.31-.97,1.31-16.22,0-32.45,0-48.67,0Z"
                        />
                        <path
                            fill="white"
                            d="m46.37,6.67c0-1.14-.03-2.29,0-3.43C46.43,1.36,47.86,0,49.73,0c1.87,0,3.37,1.35,3.4,3.19.05,2.35.05,4.71,0,7.07-.04,1.85-1.53,3.2-3.4,3.19-1.83,0-3.3-1.42-3.36-3.25-.03-1.18,0-2.36,0-3.53Z"
                        />
                        <path
                            fill="white"
                            d="m19.93,16.77c-.04,1.36-.63,2.41-1.86,2.99-1.25.6-2.54.54-3.61-.36-1.43-1.2-2.76-2.52-3.95-3.96-1.07-1.31-.9-3.29.25-4.47,1.18-1.21,3.2-1.4,4.54-.3,1.43,1.19,2.73,2.55,3.96,3.95.51.59.72,1.33.67,2.13Z"
                        />
                        <path
                            fill="white"
                            d="m79.57,16.21c-.01-.5.36-1.3,1.01-1.97,1.06-1.09,2.12-2.18,3.23-3.23,1.47-1.38,3.63-1.38,4.94-.03,1.28,1.32,1.29,3.44-.06,4.88-1.09,1.16-2.21,2.29-3.38,3.37-1.13,1.03-2.48,1.2-3.86.55-1.28-.6-1.87-1.69-1.88-3.56Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 2,
        title: "Chat",
        url: "chat",

        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 95.03">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m100,19.8v32.79c-.11.57-.22,1.15-.33,1.72-1.91,10.16-11.15,17.98-21.49,18.05-10.15.07-20.3.03-30.44,0-.92,0-1.58.26-2.23.92-6.81,6.85-13.65,13.67-20.48,20.5-1.22,1.21-2.43,1.54-3.69.98-1.39-.63-1.83-1.78-1.83-3.25.03-5.92.01-11.84.01-17.76q0-1.49-1.5-1.78C7.76,69.98.03,60.67,0,50.23c-.02-9.33,0-18.67,0-28,0-.91.06-1.81.17-2.72C1.59,8.55,11.17.04,22.24.02,40.75,0,59.25-.02,77.76.03c7.06.02,12.82,2.95,17.33,8.35,2.76,3.31,4.25,7.19,4.91,11.41ZM26.68,31.73c-3.19-.02-5.74,2.44-5.82,5.6-.07,3.12,2.59,5.81,5.75,5.81,3.12,0,5.71-2.55,5.77-5.7.06-3.09-2.55-5.7-5.7-5.72Zm23.43,0c-3.22-.03-5.86,2.54-5.83,5.68.02,3.12,2.62,5.72,5.74,5.73,3.12.01,5.71-2.53,5.79-5.68.07-3.08-2.53-5.71-5.69-5.73Zm23.42,0c-3.22-.02-5.86,2.54-5.83,5.69.02,3.13,2.62,5.72,5.74,5.73,3.12,0,5.71-2.54,5.78-5.69.07-3.08-2.54-5.71-5.69-5.73Z"
                    />
                </g>
            </svg>
        ),
    },
];

interface Props {
    setShowBurgerMenu: (visible: boolean) => void;
    showBurgerMenu: boolean;
}

export const BurguerMenuMobile = ({
    setShowBurgerMenu,
    showBurgerMenu,
}: Props) => {
    const [showChildren, setShowChildren] = useState<number>(-1);
    const navigate = useNavigate();

    const handleNavigate = (title: any) => {
        if (title.url) navigate(`/dashboard-cliente/${title.url}`);
        else {
            if (showChildren === title.id) setShowChildren(-1);
            else setShowChildren(title.id);
        }
    };

    const handleChildrenClick = (url: string) => {
        navigate(`/dashboard/${url}`);
    };

    return (
        <div
            className={`${
                showBurgerMenu ? "translate-x-0" : "translate-x-full"
            } sm:hidden fixed flex flex-col overflow-auto top-0 right-0 left-0 bottom-0 transition-all duration-500 ease-in-out z-50 bg-[#029BFC] text-white`}>
            <button
                onClick={() => setShowBurgerMenu(false)}
                className="fixed p-1 font-medium right-1 hover:underline w-max">
                Cerrar
            </button>
            <div className="mt-10">
                {TITLES.map((title) => (
                    <div
                        className={`transition ease-in cursor-pointer p-1`}
                        key={title.id}>
                        <div className="flex flex-col cursor-pointer">
                            <div
                                className="flex items-center gap-2"
                                onClick={() => handleNavigate(title)}>
                                <span className="text-blue-blueFrom">
                                    {title.icono}
                                </span>
                                <span className="font-medium capitalize ">
                                    {title.title}
                                </span>
                            </div>
                            {/* <div
                                style={{
                                    height:
                                        showChildren === title.id
                                            ? `${
                                                  title.children &&
                                                  title.children.length * 2.5
                                              }rem`
                                            : "0rem",
                                }}
                                className={`${
                                    showChildren === title.id
                                        ? `opacity-100 visible`
                                        : "opacity-0 invisible"
                                } transition-all duration-300 ease-in-out`}>
                                {title.children &&
                                    title.children.map((children) => (
                                        <div
                                            onClick={() =>
                                                handleChildrenClick(
                                                    children.url
                                                )
                                            }
                                            key={children.id}
                                            className="p-2 pl-10 hover:underline">
                                            {children.title}
                                        </div>
                                    ))}
                            </div> */}
                        </div>
                        <hr className="mt-2" />
                    </div>
                ))}
            </div>
        </div>
    );
};
