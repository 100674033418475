import React, { useState } from "react";

interface getComfort{
    pagina:number,
    habilitado:boolean
    query:string
}

const SearchBar = ({ getComforts,enabled }: { getComforts: (buscar:getComfort)=>void ,enabled:boolean})  => {
    const [input, setinput] = useState("");

    const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setinput(e.target.value);
        setTimeout(() => {
            if (e.target.value.length > 0) {
                getComforts({ pagina: 1, habilitado: enabled, query: e.target.value });
            
            
            } else {
                getComforts({ pagina: 1, habilitado: enabled, query: "" });
                    }
    }
             
        , 500);
    };

    return (
        <div className="flex p-1 m-5 border rounded appearance-none w-96 left-2 sm:max-w-screen-sm text-stone-900">
            <button className="flex items-center justify-center px-1">
                <svg
                    className="w-6 h-6 text-blue-blueFrom"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                </svg>
            </button>
            <input
                onBlur={() => setinput("")}
                value={input}
                type="text"
                onChange={handleInputChange}
                className="w-full px-1 py-2 bg-transparent outline-none appearance-none placeholder:text-[#3C3C3C]"
                placeholder="Buscar"
            />
        </div>
    );
};

export default SearchBar;
