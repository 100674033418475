import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { ButtonSiguiente } from "../ButtonSiguiente";
import ubicacion from "../../../img/onboarding/ubicacion.jpg";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

import SearchBarMapBox from "./SearchBarMap";
import { useAppSelector } from "../../../hooks/hooks";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import {
    GoogleMap,
    LoadScript,
    Marker,
    StandaloneSearchBox,
} from "@react-google-maps/api";
interface UbicacionForm {
    pais?: string;
    ciudad?: string;
    departamento?: string;
    direccion?: string;
}

type errorObject = { [key: string]: string };

const PERU_DEPARTAMENTOS = [
    "Amazonas",
    "Áncash",
    "Apurímac",
    "Arequipa",
    "Ayacucho",
    "Cajamarca",
    "Callao",
    "Cusco",
    "Huancavelica",
    "Huánuco",
    "Ica",
    "Junín",
    "La Libertad",
    "Lambayeque",
    "Lima",
    "Loreto",
    "Madre de Dios",
    "Moquegua",
    "Pasco",
    "Piura",
    "Puno",
    "San Martín",
    "Tacna",
    "Tumbes",
    "Ucayali",
];

export const Ubicacion = () => {
    const { idCasa } = useParams();
    const [input, setInput] = useState<UbicacionForm>({
        pais: "Perú",
        ciudad: "",
        departamento: "Amazonas",
        direccion: "",
    });
    const [coordinates, setCoordinates] = useState({
        lat: -12.04318,
        lng: -77.02824,
        zoom: 11,
    });
    const [errors, setErrors] = useState<UbicacionForm>({});
    const {
        id: id_casa_redux,
        latitud,
        longitud,
        ciudad,
        departamento,
        pais,
        direccion,
    } = useAppSelector((state) => state.landing_house.house);
    const id_casa =
        idCasa && Number(idCasa) > 0 ? Number(idCasa) : id_casa_redux;
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const map = useRef(null);
    const navigate = useNavigate();
    const MAPBOX_API = process.env.REACT_APP_MAPBOX_API_KEY;
    console.log(direccion);
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);

    useEffect(() => {
        setCoordinates({
            ...coordinates,
            lat: Number(latitud) || -12.04318,
            lng: Number(longitud) || -77.02824,
        });
        setInput({
            ciudad: ciudad,
            departamento: departamento,
            direccion: direccion,
            pais: pais,
        });
    }, [ciudad, departamento, pais, latitud, longitud, direccion]);
    const [searchBox, setSearchBox] =
        useState<google.maps.places.SearchBox | null>(null);
    const validateInput = (form: UbicacionForm) => {
        let errores: errorObject = {};

        if (!form.pais) {
            errores.pais = "Debe ingresar un país válido";
        } else if (!form.ciudad) {
            errores.ciudad = "Debe ingresar una ciudad válida";
        } else if (!form.departamento) {
            errores.departamento = "Debe ingresar un departamento válido";
        }

        return errores;
    };

    const handleSubmit = async () => {
        const errors = validateInput(input);
        setErrors(errors);
        console.log({
            pais: input.pais,
            ciudad: input.ciudad,
            departamento: input.departamento,
            latitud: coordinates.lat,
            longitud: coordinates.lng,
            direccion: input.direccion,
        });
        if (Object.entries(errors).length === 0) {
            try {
                const data = await fetchAxiosToken({
                    url: `/casa/putMapa/${id_casa}`,
                    method: "put",
                    body: {
                        pais: input.pais,
                        ciudad: input.ciudad,
                        departamento: input.departamento,
                        latitud: coordinates.lat,
                        longitud: coordinates.lng,
                        direccion: input.direccion,
                    },
                });

                await fetchAxiosToken({
                    url: `/casa/putPagina/${id_casa}`,
                    method: "put",
                    body: {
                        pagina: "precio",
                    },
                });
                dispatchDominio();
                navigate(`/dashboard/onboarding/precio`);
                navigate(
                    idCasa && Number(idCasa) > 0
                        ? `/dashboard-corredor/onboarding/precio/${idCasa}`
                        : `/dashboard/onboarding/precio`
                );
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handlePlacesChanged = () => {
        if (searchBox) {
            const places: any = searchBox.getPlaces();
            if (places.length === 0) return;

            const place = places[0];
            console.log(place);
            const location = place.geometry?.location;
            if (location) {
                setCoordinates({
                    lat: location.lat(),
                    lng: location.lng(),
                    zoom: 14,
                });
            }
        }
    };

    const onSearchBoxMounted = (ref: google.maps.places.SearchBox) => {
        setSearchBox(ref);
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/4 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={ubicacion}
                        alt="habitaciones"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            ¿Dónde se ubica tu alojamiento?
                        </div>

                        <div className="mt-5 text-lg text-center text-white md:text-xl 2xl:text-2xl md:mt-14 ">
                            ¡No te preocupes podrás cambiarlo más adelante!
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center p-6 text-center text-black bg-white lg:overflow-x-auto">
                <ButtonRegresar
                    url={`${
                        idCasa
                            ? `/dashboard-corredor/onboarding/habitaciones/${idCasa}`
                            : "/dashboard/onboarding/habitaciones"
                    }`}
                    className="absolute top-10 2xl:top-6 left-10"
                    arrowColor="black"
                    textColor="black"
                />
                <div className="flex flex-col pb-4 mt-10 2xl:mt-52 lg:mt-40">
                    <h2 className="mt-10 text-lg font-medium text-gray-400 md:text-xl">
                        Escribe tu ubicación para acercarte rápidamente a tu
                        localidad.
                    </h2>
                    <div className="mx-auto mt-2 md:w-11/12 2xl:w-9/12">
                        <div className="h-80 sm:h-96 ">
                            <StandaloneSearchBox
                                onLoad={onSearchBoxMounted}
                                onPlacesChanged={handlePlacesChanged}>
                                <input
                                    type="text"
                                    placeholder="Busca tu ubicación"
                                    className="z-50 input-box"
                                    style={{
                                        boxSizing: `border-box`,
                                        border: `1px solid transparent`,
                                        width: `280px`,
                                        height: `32px`,
                                        padding: `0 12px`,
                                        borderRadius: `3px`,
                                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                        fontSize: `14px`,
                                        outline: `none`,
                                        textOverflow: `ellipses`,
                                        position: "absolute",
                                        left: "50%",
                                        marginLeft: "-140px",
                                    }}
                                />
                            </StandaloneSearchBox>
                            <GoogleMap
                                options={{ disableDefaultUI: true }}
                                mapContainerStyle={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "8px",
                                }}
                                center={coordinates}
                                zoom={coordinates.zoom}
                                onClick={(e) =>
                                    setCoordinates({
                                        ...coordinates,
                                        lat: e.latLng?.lat() || coordinates.lat,
                                        lng: e.latLng?.lng() || coordinates.lng,
                                    })
                                }>
                                <Marker
                                    position={{
                                        lat: coordinates.lat,
                                        lng: coordinates.lng,
                                    }}
                                    draggable={true}
                                    onDragEnd={(e) => {
                                        setCoordinates({
                                            ...coordinates,
                                            lat:
                                                e.latLng?.lat() ||
                                                coordinates.lat,
                                            lng:
                                                e.latLng?.lng() ||
                                                coordinates.lng,
                                        });
                                    }}
                                />
                            </GoogleMap>
                        </div>
                        <div className="items-center ">
                            <div className="flex w-full gap-4 mt-8 ">
                                <div className="flex flex-col items-start w-full">
                                    <label className="text-base font-medium md:text-lg">
                                        País
                                    </label>
                                    <select
                                        placeholder="ej: Perú"
                                        value={input.pais}
                                        name="pais"
                                        onChange={handleInputChange}
                                        className="w-full p-3 mt-1 text-black border rounded-lg shadow-md placeholder:italic">
                                        <option value={"Perú"}>Perú</option>
                                        <option value={"España"}>España</option>
                                        <option value={"Colombia"}>
                                            Colombia
                                        </option>
                                        <option value={"México"}>México</option>
                                    </select>
                                    {errors.pais && (
                                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                                            {errors.pais}
                                        </p>
                                    )}
                                    {/* <input
                                        value={input.pais}
                                        onChange={handleInputChange}
                                        name="pais"
                                        type={"text"}
                                        className="w-full p-3 mt-1 text-black border rounded-lg shadow-md placeholder:italic"
                                        placeholder="ej: Peru"
                                    /> */}
                                </div>
                                <div className="flex flex-col items-start w-full">
                                    <label className="text-base font-medium 2xl:text-lg">
                                        Departamento/Estado
                                    </label>
                                    {/* <input
                                        value={input.departamento}
                                        onChange={handleInputChange}
                                        name="departamento"
                                        type={"text"}
                                        className="w-full p-3 mt-1 text-black border rounded-lg shadow-md placeholder:italic"
                                        placeholder="ej: Ancash"
                                    /> */}
                                    <input
                                        placeholder="ej: Ancash"
                                        value={input.departamento}
                                        name="departamento"
                                        onChange={handleInputChange}
                                        className="w-full p-3 mt-1 text-black border rounded-lg shadow-md placeholder:italic"
                                    />

                                    {errors.departamento && (
                                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                                            {errors.departamento}
                                        </p>
                                    )}
                                </div>
                                <div className="flex flex-col items-start w-full">
                                    <label className="text-base font-medium md:text-lg">
                                        Ciudad
                                    </label>
                                    <input
                                        value={input.ciudad}
                                        onChange={handleInputChange}
                                        name="ciudad"
                                        type={"text"}
                                        className="w-full p-3 mt-1 text-black border rounded-lg shadow-md placeholder:italic"
                                        placeholder="ej: Lima"
                                    />
                                    {errors.ciudad && (
                                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                                            {errors.ciudad}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col items-start w-full mt-2">
                                <label className="text-base font-medium md:text-lg">
                                    Dirección
                                </label>
                                <input
                                    value={input.direccion}
                                    onChange={handleInputChange}
                                    name="direccion"
                                    type={"text"}
                                    className="w-full p-3 mt-1 text-black border rounded-lg shadow-md placeholder:italic"
                                    placeholder="ej: Av. Aviación 2340"
                                />
                                {errors.direccion && (
                                    <p className="my-1 text-sm font-bold text-red-500 text-md">
                                        {errors.direccion}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto md:w-9/12">
                        <ButtonSiguiente onSubmit={() => handleSubmit()} />
                    </div>
                </div>
            </div>
        </div>
    );
};
