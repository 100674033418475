import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../../components/ResponsiveModalAbsolute";
import { CalendarioReserva } from "../../../../designs/calendario-reserva/CalendarioReserva";
import { fetchAxiosNoToken } from "../../../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { set_landing_house } from "../../../../redux/reducers/landingReducer";
import { io, Socket } from "socket.io-client";

interface Props {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    toggleButton: boolean;
    datosPromo?: DatosPromo;
    socket?: Socket;
}

interface DatosPromo {
    id_chat: number;
    id_usuario: number;
    id_cliente: number;
}

const ModalReserva = ({
    modalVisible,
    setModalVisible,
    toggleButton,
    datosPromo,
    socket,
}: Props) => {
    const {
        banner,
        whatsapp: whatsappRedux,
        id: casa_id,
        id_propietario,
        nombre,
    } = useAppSelector((state) => state.landing_house.house);
    const dispatch = useAppDispatch();
    const [whatsapp, setWhatsapp] = useState<number | string>(whatsappRedux);
    const [ediWhatsapp, setEdiWhatsapp] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const getHouseFromTenant = async () => {
        try {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/dominio`,
            });

            dispatch(set_landing_house(data));
        } catch (error) {
            console.log(error);
            dispatch(set_landing_house(null));
        }
    };

    useEffect(() => {
        getHouseFromTenant();
    }, []);

    const putEvents = async () => {
        if (Number(whatsapp) < 1) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Para actualizar el whatsapp debe tener un numero",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }
        setSaving(true);
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putWhatsapp/${casa_id}`,
                method: "put",
                body: {
                    whatsapp: whatsapp,
                },
            });
            setTimeout(() => {
                setEdiWhatsapp(false);
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Actualizado",
                    text: "Numero actualizado con exito",
                    icon: "success",
                    confirmButtonText: "Ok",
                });

                setSaving(false);
            }, 2500);
        } catch (error: any) {
            console.log(error.response);
        }
    };
    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            max_width={30}>
            <header className="px-4 my-3 text-lg font-medium text-left">
                Reserva {nombre}
            </header>
            <div className="flex flex-col items-center mt-10">
                <div className="font-medium">
                    Selecciona una fecha y consigue una reserva!
                </div>
                <CalendarioReserva
                    toggleButton={toggleButton}
                    className="flex justify-center p-3"
                    promo={true}
                    datosPromo={datosPromo}
                    setModalVisible={setModalVisible}
                    socket={socket}
                />
            </div>
        </ResponsiveModalAbsolute>
    );
};

export default ModalReserva;
