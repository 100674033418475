import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useNavigate } from "react-router-dom";
import { io, Socket } from "socket.io-client";

export interface Notificacion {
    id: number;
    tipo: string;
    id_chat: number;
    id_pedido: number;
    fecha_registro: string;
    visto: string;
    id_usuario: number;
    pedido: Pedido;
    chat: Chat;
    id_resena: number;
    resena: Resena;
}

export interface Resena {
    id: number;
    pedido: Pedido;
}

export interface Pedido {
    id: number;
    fecha_inicio: string;
    fecha_final: string;
    Cliente: Cliente;
}

export interface Cliente {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

export interface Chat {
    id: number;
    titulo: string;
    mensajes_no_vistos: number;
    chat_mensajes_vistos: ChatMensajesVisto[];
}

export interface ChatMensajesVisto {
    id: number;
    chat_mensaje: ChatMensaje;
}

export interface ChatMensaje {
    id: number;
    usuario: Usuario;
}

export interface Usuario {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

interface Props {
    landing?: boolean;
}

export const Notificaiones = ({ landing = true }: Props) => {
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const casa = useAppSelector((state) => state.landing_house);
    const navigate = useNavigate();
    const [showOptions, setShowOptions] = useState(false);
    const [contador, setContador] = useState(0);
    const [notificaciones, setNotificaciones] = useState<Notificacion[]>([]);
    const { socket } = useAppSelector((state) => state.activarSocket);

    const getAll = async () => {
        const data = await fetchAxiosToken({
            url: `/notificaciones/getAll/${id}`,
        });

        setNotificaciones(data.result);
        setContador(data.count | 0);
    };

    const navegar = (idPedido: number, tipo: string, idr: number) => {
        const updatedNotificaciones = notificaciones.map((notificacion) =>
            notificacion.id === idr
                ? { ...notificacion, visto: "Si" }
                : notificacion
        );

        setNotificaciones(updatedNotificaciones);

        let nuevoContador = contador - 1;

        setContador(nuevoContador);

        navigate(
            `${tipo_usuario === 5 ? "/dashboard-cliente/" : "/dashboard/"}${
                tipo === "pedido"
                    ? "reservas"
                    : tipo === "chat"
                    ? "chat"
                    : "reviews"
            }/${idPedido}`
        );
    };
    const eliminarNotificacion = async (id: number) => {
        const updatedNotificaciones = notificaciones.filter(
            (notificacion) => notificacion.id !== id
        );
        setNotificaciones(updatedNotificaciones);

        const data = await fetchAxiosToken({
            url: `/notificaciones/delete/${id}`,
            method: "delete",
        });

        getAll();
    };

    useEffect(() => {
        if (id) {
            getAll();
        }
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on("notificacion-recieve", ({ id_cliente }) => {
                getAll();
            });
        }
    }, [socket]);

    return (
        <>
            <div
                onMouseOver={() => setShowOptions(true)}
                onMouseOut={() => setShowOptions(false)}
                className={
                    id ? "relative flex flex-col items-center " : " hidden"
                }>
                {landing ? (
                    <>
                        <svg
                            className="w-8 h-8 cursor-pointer xs:w-10 xs:h-10"
                            id="Capa_2"
                            data-name="Capa 2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 70.41">
                            <g id="Testimonios">
                                <g>
                                    <path
                                        d="m0,7.1c.19-.52.38-1.04.57-1.55.21-.56.52-.6.92-.17.18.19.37.37.55.55,9.43,9.47,18.84,18.94,28.29,28.38.7.7.71,1.08,0,1.78-5.84,5.79-11.64,11.62-17.44,17.45-3.69,3.71-7.37,7.43-11.06,11.14-.92.93-1.03.9-1.47-.37C.26,63.97.12,63.64,0,63.31c0-18.74,0-37.47,0-56.21Z"
                                        fill="white"
                                    />
                                    <path
                                        d="m49.95,0c13.59,0,27.19,0,40.78,0,1.12,0,2.21.09,3.26.46.89.31.94.47.26,1.15-3.79,3.8-7.58,7.6-11.37,11.4-9.98,10.02-19.96,20.05-29.94,30.07-1.98,1.99-3.95,1.99-5.91.02C33.29,29.33,19.57,15.55,5.84,1.78c-.9-.91-.87-1,.35-1.39C7.13.08,8.09,0,9.07,0c13.63,0,27.25,0,40.88,0Z"
                                        fill="white"
                                    />
                                    <path
                                        d="m49.92,70.4c-13.56,0-27.12,0-40.68,0-1.01,0-2.01-.06-2.98-.37-1.29-.4-1.33-.5-.4-1.42,6.38-6.4,12.77-12.79,19.15-19.2,3.12-3.13,6.24-6.27,9.33-9.43.61-.63.99-.68,1.63-.02,2.4,2.48,4.85,4.9,7.29,7.33,3.91,3.9,9.48,3.91,13.39.01,2.47-2.46,4.93-4.91,7.36-7.4.56-.58.92-.58,1.49,0,9.58,9.65,19.17,19.27,28.76,28.91.66.66.61.84-.3,1.14-1.06.35-2.15.46-3.27.45-13.59-.01-27.18,0-40.78,0Z"
                                        fill="white"
                                    />
                                    <path
                                        d="m99.99,35.18c0,8.75,0,17.5,0,26.25,0,1.16-.21,2.25-.61,3.33-.25.68-.55.69-1.03.2-1.76-1.78-3.54-3.55-5.3-5.32-7.78-7.81-15.55-15.63-23.33-23.44q-.99-.99-.03-1.95c6.89-6.91,13.79-13.81,20.67-20.72,2.62-2.63,5.22-5.27,7.83-7.9.78-.78.89-.75,1.27.27.39,1.02.53,2.06.53,3.15-.01,8.72,0,17.43,0,26.15Z"
                                        fill="white"
                                    />
                                </g>
                            </g>
                        </svg>

                        <div
                            className={
                                contador > 0
                                    ? " absolute w-4  h-4 rounded-full  -top-1 -right-1 inline-flex cursor-pointer "
                                    : "hidden"
                            }>
                            <span className="absolute inline-flex w-full h-full bg-[#1C4CBB]/95 rounded-full opacity-75 animate-ping"></span>
                            <span className="relative inline-flex w-4 h-4 pl-1 text-xs text-white bg-[#1C4CAA] rounded-full  drop-shadow-2xl">
                                {contador}
                            </span>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="hover:scale-105 select-none 2xl:w-12 2xl:h-12 w-10 h-10 border-2 border-[#808080]  rounded-full cursor-pointer flex justify-center items-center">
                            <svg
                                className="w-6 h-6"
                                id="Capa_2"
                                data-name="Capa 2"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 85.91 100">
                                <g id="Testimonios">
                                    <g>
                                        <path
                                            fill="#808080"
                                            d="m42.96,82.19c-11.55,0-23.09.01-34.64,0-3.91,0-7.17-2.56-8.06-6.27-.57-2.36-.18-4.59,1.29-6.54,1.05-1.39,2.16-2.74,3.28-4.08,3.59-4.32,5.05-9.42,5.54-14.89.34-3.85.31-7.71.48-11.56.3-7.12,2.05-13.79,6.72-19.42,2.92-3.53,6.58-6.08,10.75-7.92.87-.38,1.4-.88,1.76-1.82,1.43-3.78,3.86-6.77,7.59-8.5,5.71-2.65,12.22-.77,16.07,4.55.88,1.21,1.64,2.5,2.1,3.91.32.99.92,1.5,1.83,1.9,5.18,2.29,9.5,5.63,12.56,10.46,2.78,4.39,4.15,9.25,4.67,14.37.4,3.88.15,7.79.45,11.67.38,4.91,1.01,9.75,3.58,14.11,1.39,2.35,3.17,4.39,4.9,6.48,2.2,2.66,2.74,5.59,1.31,8.72-1.46,3.21-4.12,4.81-7.63,4.82-11.51.03-23.02.01-34.53.01Z"
                                        />
                                        <path
                                            fill="#808080"
                                            d="m43.06,89.17c4.71,0,9.42,0,14.13,0,1.66,0,2.07.65,1.2,2.04-3.57,5.73-8.71,8.81-15.5,8.79-6.73-.02-11.85-3.1-15.39-8.79-.87-1.39-.47-2.03,1.2-2.04,4.78,0,9.57,0,14.35,0Z"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </div>

                        <div
                            className={
                                contador > 0
                                    ? " absolute w-4  h-4 rounded-full  bottom-1 -right-1 inline-flex cursor-pointer "
                                    : "hidden"
                            }>
                            <span className="absolute inline-flex w-full h-full bg-[#029BFC]/95 rounded-full opacity-75 animate-ping"></span>
                            <span className="relative inline-flex w-4 h-4 pl-1 text-xs text-white bg-[#029BFC] rounded-full  drop-shadow-2xl">
                                {contador}
                            </span>
                        </div>
                    </>
                )}

                <div
                    className={`${
                        showOptions
                            ? "h-max opacity-100 visible"
                            : "h-0 opacity-0 invisible"
                    }  absolute flex flex-col  ml-4  ${
                        landing ? " top-[2rem] xs:top-[2.3rem]" : "top-[3.2rem]"
                    }   w-64 right-0 bg-white transition-all duration-100 delay-200 ease-out rounded-md z-10`}>
                    <div className="flex flex-col p-1 px-5 overflow-y-auto bg-white border rounded-md max-h-56">
                        <p className="text-[#535353] font-bold px-2">
                            Notificaciones
                        </p>
                        {notificaciones.map((notificacion) => {
                            return (
                                <div className="" key={notificacion.id}>
                                    <hr className="my-2" />
                                    <div
                                        className={` relative flex items-center gap-2   px-2 py-1 rounded-md ${
                                            notificacion.visto === "No"
                                                ? "bg-blue-50 bg-opacity-50"
                                                : ""
                                        }  `}>
                                        <button
                                            onClick={() =>
                                                eliminarNotificacion(
                                                    notificacion.id
                                                )
                                            }
                                            className="absolute z-20 text-gray-800 dark:text-gray-400 top-1 right-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 ">
                                            <svg
                                                className="w-3 h-3 text-white bg-gray-600 rounded-full"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M18 6L6 18"
                                                    stroke="currentColor"
                                                    strokeWidth="1.66667"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M6 6L18 18"
                                                    stroke="currentColor"
                                                    strokeWidth="1.66667"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </button>
                                        <button
                                            onClick={() =>
                                                navegar(
                                                    notificacion.tipo ===
                                                        "pedido"
                                                        ? notificacion.id_pedido
                                                        : notificacion.tipo ===
                                                          "chat"
                                                        ? notificacion.id_chat
                                                        : notificacion.id_resena,
                                                    notificacion.tipo,
                                                    notificacion.id
                                                )
                                            }
                                            className={`absolute  w-[13px] h-[13px] border rounded-full bottom-1 right-1 border-gray-300 ${
                                                notificacion.visto === "No"
                                                    ? "bg-[#029BFC]"
                                                    : "bg-white"
                                            }`}></button>
                                        <img
                                            onClick={() =>
                                                navegar(
                                                    notificacion.tipo ===
                                                        "pedido"
                                                        ? notificacion.id_pedido
                                                        : notificacion.tipo ===
                                                          "chat"
                                                        ? notificacion.id_chat
                                                        : notificacion.id_resena,
                                                    notificacion.tipo,
                                                    notificacion.id
                                                )
                                            }
                                            src={`${
                                                process.env.REACT_APP_PROXY
                                            }/usuarios/getImage/${
                                                notificacion.tipo === "pedido"
                                                    ? notificacion.pedido
                                                          .Cliente?.avatar
                                                    : notificacion.tipo ===
                                                      "chat"
                                                    ? notificacion.chat
                                                          .chat_mensajes_vistos[0]
                                                          ?.chat_mensaje.usuario
                                                          ?.avatar
                                                    : notificacion.resena.pedido
                                                          .Cliente.avatar
                                            }`}
                                            className="z-40 object-cover border border-gray-600 rounded-full cursor-pointer w-9 h-9"
                                            alt="foto de perfil"
                                        />
                                        <div
                                            className="cursor-pointer text-[#535353] hover:text-blue-blueFrom"
                                            onClick={() =>
                                                navegar(
                                                    notificacion.tipo ===
                                                        "pedido"
                                                        ? notificacion.id_pedido
                                                        : notificacion.tipo ===
                                                          "chat"
                                                        ? notificacion.id_chat
                                                        : notificacion.id_resena,
                                                    notificacion.tipo,
                                                    notificacion.id
                                                )
                                            }>
                                            <p
                                                className={`text-xs   ${
                                                    notificacion.visto === "No"
                                                        ? "font-bold"
                                                        : ""
                                                }`}>
                                                {notificacion.tipo === "pedido"
                                                    ? "Nueva reserva"
                                                    : notificacion.tipo ===
                                                      "chat"
                                                    ? "Nuevo mensaje"
                                                    : "Nueva reseña"}
                                            </p>

                                            <p className="text-xs ">
                                                De:{" "}
                                                {notificacion.tipo === "pedido"
                                                    ? `${notificacion.pedido.Cliente?.nombre} ${notificacion.pedido.Cliente?.apellido}`
                                                    : notificacion.tipo ===
                                                      "chat"
                                                    ? notificacion.chat.titulo
                                                    : `${notificacion.resena.pedido.Cliente.nombre} ${notificacion.resena.pedido.Cliente.apellido}`}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};
