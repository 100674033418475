import React from "react";
import { useAppSelector } from "./hooks";
import { usePriceToSelectedCurrency } from "./usePriceToSelectedCurrency";

interface PrecioPromo {
    precioPromo?: number;
}

export const useReservaFinalPrice = ({ precioPromo }: PrecioPromo) => {
    const { price_to_currency } = usePriceToSelectedCurrency();
    let reservaFinal = 0;

    const {
        precio_alquiler,
        garantia,
        precio_servicios_adicionales,
        descuento_estadia,
        precio_personas_adicionales,
    } = useAppSelector((state) => state.precios_reserva);

    const descuentoEstadia = descuento_estadia
        ? descuento_estadia["descuento"]
        : 0;

    reservaFinal = precioPromo
        ? precioPromo +
          precio_servicios_adicionales -
          descuentoEstadia +
          garantia
        : precio_alquiler +
          garantia +
          precio_servicios_adicionales -
          descuentoEstadia +
          precio_personas_adicionales;

    return {
        reservaFinal: price_to_currency(reservaFinal),
    };
};
