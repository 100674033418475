import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import Spinner from "../../../components/helpers/Spinner";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { verifyToken } from "../../../redux/reducers/authReducer";
import { getHouse } from "../../../redux/reducers/onboardingReducer";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { useParams } from "react-router-dom";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";

interface EstadiaMinima {
    id: number;
    id_casa: number;
    fecha_inicio: string;
    fecha_final: string;
    estadia_minima: number | string;
}

export const AvailabilityMaximo = () => {
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);

    const {
        id: id_casa,
        estadia_minima,
        estadia_maxima,
        id_propietario,
        casa_estadias_minimas,
    } = useAppSelector((state) => state.landing_house.house);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const [disponibilidadMinima, setDisponibilidadMinima] = useState<
        number | null
    >(estadia_minima);
    const [disponibilidadMaxima, setDisponibilidadMaxima] = useState<
        number | null
    >(estadia_maxima);
    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);
    const putMaximaBase = async () => {
        setLoading(true);
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putEstadia/${id_casa}`,
                method: "put",
                body: {
                    estadia_minima: disponibilidadMinima,
                    estadia_maxima: disponibilidadMaxima,
                },
            });

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Actualizado con exito",

                icon: "success",
                confirmButtonText: "Ok",
            });

            dispatchDominio();

            setLoading(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        if (estadia_maxima || estadia_minima) {
            setDisponibilidadMaxima(estadia_maxima);
            setDisponibilidadMinima(estadia_minima);
        }
    }, [estadia_maxima, estadia_minima]);

    const render = () => {
        return (
            <div className="flex">
                <div className="flex flex-col items-center justify-end w-full mt-12 sm:mb-0">
                    <div className="grid w-11/12 gap-4 mx-auto max-w-7xl lg:grid-cols-2 right-2">
                        <div className="  text-[#4D4D4D]">
                            <ButtonRegresar
                                // url={`/dashboard/onboarding/alojamiento`}
                                className="-ml-2 "
                                arrowColor="black"
                                textColor="black"
                            />
                            <p className="text-xl font-bold 2xl:text-2xl ">
                                Disponibilidad máxima
                            </p>
                            <p className="mt-6 text-sm 2xl:text-base">
                                Máximo de noches por alquilar. Establezca reglas
                                precisas para adaptarse a sus necesidades.
                            </p>
                        </div>
                        <div>
                            <div className="w-full p-4   border border-[#CCCCCC] rounded-lg shadow-lg mb-6">
                                <div>
                                    <p className="text-[#4D4D4D] font-light text-center 2xl:text-base text-sm">
                                        {" "}
                                        Máxima disponibilidad
                                    </p>
                                </div>
                                <div className="flex items-center justify-between mt-2 2xl:text-base text-sm font-light text-[#4D4D4D]">
                                    <div>
                                        <p>Noches máximas para reservar: </p>
                                    </div>
                                    <div className="flex flex-col items-center justify-center gap-2 xs:flex-row lg:flex-col xl:flex-row ">
                                        <input
                                            type="number"
                                            placeholder="Dias"
                                            onChange={(e) =>
                                                setDisponibilidadMaxima(
                                                    Number(e.target.value)
                                                )
                                            }
                                            className=" w-16 mx-2 px-2 rounded-lg py-2 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400"
                                            value={disponibilidadMaxima || ""}
                                        />
                                        <button
                                            disabled={loading}
                                            onClick={() => putMaximaBase()}
                                            className="py-2 font-medium text-white rounded-lg uppercase px-6 bg-[#029BFC] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return tipo_usuario === 3 || tipo_usuario === 4 ? (
        <Navbar titulo="Máxima disponibilidad">
            {render()}
            <NavbarMobile />
        </Navbar>
    ) : tipo_usuario === 6 ? (
        <NavbarBroker titulo="Máxima disponibilidad">
            {render()}
            <NavbarMobileBroker />
        </NavbarBroker>
    ) : null;
};
