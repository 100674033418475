import React, { useState } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { ChatCliente } from "../../pages/dashboardClientes/chat/ChatCliente";
import { ModalChatInvitado } from "./ModalChatInvitado";
import iconoMensaje from "../../img/editar_landing/Recurso-8.webp";

export const ModalChat = ({ bg }: { bg: string }) => {
    const [abrirChat, setAbrirChat] = useState(false);
    const { id: id_usuario, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const casa = useAppSelector((state) => state.landing_house);
    console.log(casa.house);
    return (
        <div
            className={
                tipo_usuario === 3 ||
                tipo_usuario === 4 ||
                casa.house?.plan === 1 ||
                casa.house.tipo_usuario === 6
                    ? "hidden"
                    : "fixed z-20 right-2 bottom-0 sm:right-5"
            }
        >
            <div>
                {abrirChat && id_usuario ? (
                    <div className=" w-80">
                        {
                            <ChatCliente
                                landing={abrirChat}
                                setLanding={setAbrirChat}
                            />
                        }
                    </div>
                ) : abrirChat && !id_usuario ? (
                    <ModalChatInvitado
                        setAbrirChat={setAbrirChat}
                        abrirChat={abrirChat}
                    />
                ) : (
                    <div
                        onClick={() => setAbrirChat(true)}
                        className={`flex items-center justify-center w-20 h-20  rounded-full cursor-pointer animate-bounce`}
                    >
                        <img
                            src={iconoMensaje}
                            className="object-cover w-full h-full "
                            alt={`modal chat`}
                        />
                        {/* <svg className="text-white w-14 h-14"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />  <line x1="12" y1="12" x2="12" y2="12.01" />  <line x1="8" y1="12" x2="8" y2="12.01" />  <line x1="16" y1="12" x2="16" y2="12.01" /></svg> */}
                    </div>
                )}
            </div>
        </div>
    );
};
