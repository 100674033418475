import React, { useEffect } from "react";

import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { CalendarioListado } from "./CalendarioListado";
import { useAppSelector } from "../../../hooks/hooks";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { useParams } from "react-router-dom";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { useDispatch } from "react-redux";
import { set_landing_house } from "../../../redux/reducers/landingReducer";
import Spinner from "../../../components/helpers/Spinner";

export const Calendario = () => {
    const dispatch = useDispatch();
    const { id_casa } = useParams();

    useEffect(() => {
        getCasa();
    }, [id_casa]);

    const getCasa = async () => {
        if (id_casa) {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/id/${id_casa}`,
            });

            dispatch(set_landing_house(data));
        }
    };

    const { id, tipo_usuario, nombre } = useAppSelector(
        (state) => state.landing_house.house
    );

    const NavbarComponent =
        tipo_usuario === 6 ? NavbarBroker : tipo_usuario === 3 ? Navbar : null;

    return (
        <div>
            {(id_casa && tipo_usuario === 6) || tipo_usuario === 3 ? (
                <div>
                    {NavbarComponent && (
                        <NavbarComponent
                            titulo={`Calendario ${
                                tipo_usuario === 6 ? nombre : ""
                            }`}>
                            <div className="w-full mx-auto">
                                <CalendarioListado />
                            </div>
                        </NavbarComponent>
                    )}
                    {tipo_usuario === 6 ? (
                        <NavbarMobileBroker />
                    ) : (
                        <NavbarMobile />
                    )}
                </div>
            ) : (
                <div className="flex-col items-center justify-center">
                    <div className="flex items-center justify-center mx-auto w-28 h-28">
                        <Spinner width={28} />
                    </div>
                    <div className="mt-5 text-xl text-center">
                        Por favor, aguarde mientras cargamos los datos.
                    </div>
                </div>
            )}
        </div>
    );
};
